import Gender from "../enums/gender.enum"
import moment from 'moment'
import { message } from 'antd'
import copy from 'copy-to-clipboard'
import { addImpersonation } from '../services/impersonation.service.js';
import MembershipHelper from "./membership.helper.js";
import MembershipTypeCode from "../enums/membershipTypeCode.enum.js";
import { listCards } from '../services/card.service.js';
const PatientHelper = {
  getAge: (patient) => {
    if (!patient) return null
    const { dob } = patient
    if (!dob) return null
    return moment().diff(moment(dob, 'MM/DD/YYYY'), 'years')
  },
  isFemale: (patient) => {
    return patient?.gender === Gender.FEMALE
  },
  isMale: (patient) => {
    return patient?.gender === Gender.MALE
  },
  isShort: (patient) => {
    return patient && patient.height < 60
  },
  isAthlete: (patient) => {
    return patient && patient.isAthlete
  },
  onBirthControl: (patient) => {
    return patient?.birthControl !== undefined && patient?.birthControl.length>0
  },
  getFirstAppointment: (user) => {
    if (!user) return null
    let { appointments } = user
    if (!appointments?.length) return null
    const appointmentsByStart = appointments.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime()
    })
    return appointmentsByStart[0]
  },
  onImpersonate:  async (user) => {
    try {
      const impersonate = await addImpersonation({ user: user })
      copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
      message.success('Copied login link')
    } catch (err) {
      message.error('Failed to generate login link')   
    }
  },
  getWeight: async (user) => {
    const { weight } = user
    return weight
  },
  isInstalabMember: async(patient) => {
    return MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.LONGEVITY)
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.ULTIMATE)
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.PREMIUM)
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.PREMIUM_12MO)
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.LONGEVITY_12MO)
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.HEART_HEALTH_V2)
  },
  isHeartHealthMember: async(patient) => {
    return MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.HEART_HEALTH) 
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.HEART_HEALTH_V2)
      || MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.ULTIMATE)
  },

  isUltimateMember: async(patient) => {
    return MembershipHelper.getActiveMembership(patient.memberships, MembershipTypeCode.ULTIMATE)
  },
  hasCredit: (patient, productTypeCode) => {
    if (!patient) return false
    const { credits } = patient
    if (!credits?.length) return false
    const hasCredit = credits.find(credit => credit === productTypeCode)
    return hasCredit
  },
  hasActiveCard: async (patient) => {
    const cards = await listCards({
      filter: { isRemoved: false }
    })
    return cards.length > 0
  }
}

export default PatientHelper
