import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Form, 
  Input, 
  Button, 
  Card, 
  Result, 
  Typography, 
  Spin, 
  Alert,
  Radio,
  Empty,
  Steps,
} from 'antd';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { 
  ScanOutlined, 
  SelectOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { UserContext } from '../../contexts/user.context';
import { activateKit, listKits } from '../../services/kit.service';
import ProductStatus from '../../enums/productStatus.enum.js';
import './activate.scss';

const { Title, Text, Paragraph } = Typography;

export const Activate = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [kitDetails, setKitDetails] = useState(null);
  const { token, isLoading } = useContext(UserContext);
  const [kits, setKits] = useState([]);
  const [loadingKits, setLoadingKits] = useState(true);
  const [selectedKit, setSelectedKit] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!isLoading && !token) {
      localStorage.setItem('redirectPath', window.location.pathname);
      navigate('/login?redirect=' + window.location.pathname);
    }
  }, [isLoading, token, navigate]);

  // Fetch available kits for the user
  useEffect(() => {
    const fetchKits = async () => {
      setLoadingKits(true);
      try {
        const filter = {
          hasKit: true,
          empowerdxId: { $exists: false },
          status: { $in: [ProductStatus.FULFILLING, ProductStatus.SHIPPED, ProductStatus.DELIVERED] } 
        };

        const populate = [{
          path: 'productType',
          select: 'title code isBundle productTypeCodes'
        }]

        const response = await listKits({filter: filter, populate: populate});
        const filteredKits = response.filter(kit => kit.productType.code === ProductTypeCode.CHOLESTEROL_BALANCE);
        setKits(filteredKits);
      } catch (err) {
        console.error("Error fetching kits:", err);
        setError("Failed to load your test kits. Please refresh the page or contact support.");
      } finally {
        setLoadingKits(false);
      }
    };
    
    if (token) {
      fetchKits();
    }
  }, [token]);

  // Handle kit selection
  const handleKitSelect = (kitId) => {
    const kit = kits.find(k => k._id === kitId);
    setSelectedKit(kit);
    setError(null);
    setCurrentStep(1);
  };

  // Handle form submission for activation code
  const handleSubmit = async (values) => {
    setLoading(true);
    setError(null);
    
    try {
      // Include the selected kit ID with the activation code
      const response = await activateKit({ 
        activationCode: values.activationCode,
        productId: selectedKit._id
      });
      
      setKitDetails(response.kit);
      setSuccess(true);
      setCurrentStep(2);
    } catch (err) {
      setError(err.message || 'Failed to activate kit. Please check your code and try again.');
    } finally {
      setLoading(false);
    }
  };

  // Go back to kit selection
  const handleBack = () => {
    setCurrentStep(0);
    setError(null);
    setSelectedKit(null);
    form.resetFields();
  };


  // Show loading while checking auth status
  if (isLoading) {
    return (
      <div className="kit-activation-loading">
        <Spin size="large" />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="kit-activation-container">
      <Card 
        className="kit-activation-card no-hover-effect"
        style={{ transition: 'none' }}
      >
        <Title level={2} className="page-title">
          Test Kit Activation
        </Title>

        <Steps current={currentStep} className="activation-steps custom-steps" navB>
          <Steps.Step 
            title="Select Kit" 
            icon={
              <SelectOutlined 
                className={currentStep >= 0 ? 'success-icon' : ''} 
              />
            } 
          />
          <Steps.Step 
            title="Enter Code" 
            icon={
              <ScanOutlined 
                className={currentStep >= 1 ? 'success-icon' : ''} 
              />
            } 
          />
          <Steps.Step 
            title="Activated" 
            icon={
              <CheckCircleOutlined 
                className={currentStep >= 2 ? 'success-icon' : ''} 
              />
            } 
          />
        </Steps>
        
        {/* Step 1: Select Kit */}
        {currentStep === 0 && (
          <div className="kit-selection">
            {loadingKits ? (
              <div className="loading-kits">
                <Spin />
                <Text>Loading your test kits...</Text>
              </div>
            ) : kits.length === 0 ? (
              <Empty 
                description="You don't have any test kits available for activation." 
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="empty-message"
              />
            ) : (
              <>
                <Paragraph className="instructions">
                  Please select the test kit you'd like to activate:
                </Paragraph>
                <Radio.Group className="kit-radio-group" onChange={(e) => handleKitSelect(e.target.value)}>
                {kits.map(kit => (
                  <div key={kit._id} className="kit-option">
                    <Radio value={kit._id} className="hidden-radio">
                      <div className="kit-details-container">
                        <div className="kit-name">
                          {kit.productType.title || 'Test Kit'}
                        </div>
                        <div className="kit-meta">
                          {kit.createdAt && (
                            <div className="kit-date">
                              Ordered: {new Date(kit.createdAt).toLocaleDateString()}
                            </div>
                          )}
                        </div>
                      </div>
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
              </>
            )}
          </div>
        )}
        
        {/* Step 2: Enter Activation Code */}
        {currentStep === 1 && selectedKit && (
          <div className="activation-form">            
            <Paragraph className="instructions">
              Please enter the activation code found on your test kit for the {selectedKit.productType.title}. This unique code is usually 10-12 characters and links the kit to your account.
            </Paragraph>
            
            {error && (
              <Alert 
                message="Activation Error" 
                description={error} 
                type="error" 
                showIcon 
                className="error-message"
              />
            )}
            
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="activationCode"
                label="Activation Code"
                rules={[
                  { required: true, message: 'Please enter your kit activation code' },
                ]}
              >
                <Input 
                  prefix={<ScanOutlined />} 
                  placeholder="Enter code (e.g., BH3-1234-568)"
                  size="large"
                  autoFocus
                />
              </Form.Item>
              
              <Form.Item className="form-buttons">
                <Button 
                  size="large" 
                  onClick={handleBack} 
                  style={{ marginRight: 12 }}
                >
                  Back
                </Button>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  size="large" 
                  loading={loading}
                >
                  Activate Kit
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        
        {/* Step 3: Success */}
        {currentStep === 2 && success && (
          <div className="success-content">
            <Result
              status="success"
              title="Test Kit Activated Successfully!"
              subTitle={selectedKit ? selectedKit.productType.title : kitDetails?.testName}
              extra={[
                <Button 
                  type="primary" 
                  key="dashboard" 
                  onClick={() => navigate('/')}
                >
                  Go to Dashboard
                </Button>,
                // <Button 
                //   key="instructions" 
                //   onClick={() => navigate(`/test-instructions/${kitDetails?.id}`)}
                // >
                //   View Test Instructions
                // </Button>,
              ]}
            />
            {/* <div className="kit-details">
              <Title level={4}>Kit Details</Title>
              <div className="detail-item">
                <Text strong>Test Type:</Text>
                <Text>{selectedKit ? selectedKit.productType.title : kitDetails?.testName || 'Boston Heart Cholesterol Balance Test'}</Text>
              </div>
              <div className="detail-item">
                <Text strong>Activation Date:</Text>
                <Text>{new Date().toLocaleDateString()}</Text>
              </div>
              <div className="detail-item">
                <Text strong>Associated Account:</Text>
                <Text>{currentUser?.email}</Text>
              </div>
            </div> */}
          </div>
        )}
        
        <div className="help-section">
          <Text className="help-text">
            Need any help? Email us at <a className="help-link" href="mailto:concierge@instalab.com">concierge@instalab.com</a> or <a className="help-link" onClick={() => window.FrontChat("show")}>start chat with us</a>.
          </Text>
        </div>
      </Card>
    </div>
  );
};

export default Activate;
