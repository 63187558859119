import { useContext } from "react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import BlogId from "../../../enums/blogId.enum";
import { BlogData } from "../../../data/blog.data";
import "./plaqueReversalPost.scss";

const { Paragraph } = Typography;

export const PlaqueReversalPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "plaque-reversal-post")}>
      <div className="blog-image-wrapper bordered">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image padded" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          LDL Cholesterol: A Key Player in Plaque Buildup
        </Paragraph>
        <Paragraph className="blog-description">
          <a onClick={() => window.open('https://pmc.ncbi.nlm.nih.gov/articles/PMC6629749/', '_blank')} className="blog-link">Intravascular ultrasound (IVUS) trials</a>—a collection of clinical studies—have provided compelling evidence linking LDL cholesterol reduction to the reversal of arterial plaque. These studies used IVUS to monitor plaque volume over time in patients undergoing treatment with medications such as statins and <a onClick={() => navigate(`/research/${BlogId.PCSK9I}`)} className="blog-link">PCSK9 inhibitors</a>.
        </Paragraph>
        <Paragraph className="blog-description">
          The results were striking. For instance, in the COSMOS trial, participants who reduced their LDL cholesterol from an at-risk level of <b>140 mg/dL to 83 mg/dL</b> experienced a <b>5.1% reduction in arterial plaque volume</b>. This finding underscores the power of aggressive LDL cholesterol management in reversing the early stages of heart disease.
        </Paragraph>

        <Paragraph className="blog-title">
          How Low Should You Go?
        </Paragraph>
        <Paragraph className="blog-description">
          Most health organizations, including the <i>American Heart Association (AHA)</i> and <i>World Health Organization (WHO)</i>, recommend maintaining LDL cholesterol levels below <b>100 mg/dL</b> for the general population. While this target is effective for preventing plaque buildup, the IVUS trials reveal that pushing LDL levels even lower can lead to further plaque reduction.
        </Paragraph>
        <Paragraph className="blog-description">
          For example, in one study, lowering LDL cholesterol from <b>93 mg/dL to 37 mg/dL</b> resulted in an additional <b>0.95% reduction in plaque volume</b>. This reinforces a popular mantra in lipidology: "Lower is better."
        </Paragraph>

        <Paragraph className="blog-title">
          How Does Plaque Shrink?
        </Paragraph>
        <Paragraph className="blog-description">
          The mechanics behind plaque regression involve two critical processes:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Cholesterol Clearance:</strong> Lowering LDL cholesterol levels prompts the removal of cholesterol from foam cells (a type of inflammatory cell that contributes to plaque formation) and its transport back to the liver for excretion. Over time, this reduces the overall plaque volume.
          </li>
          <li>
            <strong>Reduced Inflammation:</strong> Lower LDL levels also dampen inflammation, which stabilizes existing plaque. Stable plaques are less prone to rupture, reducing the risk of heart attacks and strokes.
          </li>
        </ul>

        <Paragraph className="blog-title">
          The Takeaway
        </Paragraph>
        <Paragraph className="blog-description">
          By significantly lowering LDL cholesterol, you can not only stabilize but actively shrink the plaques in your arteries. However, this doesn't mean rushing to start medications, especially if your LDL cholesterol is already at an optimal level. That's not the point.
        </Paragraph>
        <Paragraph className="blog-description">
          Instead, the goal is to shift your thinking: achieving heart health isn't just about reaching a predefined "optimal" LDL level like 100 mg/dL. Even when you hit that target, continuing to lower your LDL can further reduce plaque buildup and deliver additional benefits for your cardiovascular health. It's about embracing the mindset that lower is better.
        </Paragraph>

        <Paragraph className="blog-title">
          How Can You Measure Plaque Buildup?
        </Paragraph>
        <Paragraph className="blog-description">
          One of the most accessible ways to measure plaque buildup in your coronary arteries is through a <a onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: schedule scan`, true)
            navigate('/cac')
          }} className="blog-link">coronary calcium scan</a>. This non-invasive test provides a snapshot of calcified plaque levels and offers a reliable indicator of your cardiovascular health.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Daida, H., Dohi, T., Fukushima, Y., Ohmura, H., & Miyauchi, K. (2019). The goal of achieving atherosclerotic plaque regression with lipid-lowering therapy: Insights from IVUS trials. Journal of Atherosclerosis and Thrombosis, 26(7), 592–600. <a onClick={() => window.open('https://pmc.ncbi.nlm.nih.gov/articles/PMC6629749/', '_blank')}>https://pmc.ncbi.nlm.nih.gov/articles/PMC6629749/</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
