import React, { useState, useEffect, useRef, useContext } from 'react';
import './dexaRecommender.scss';
import { QuestionTemplateCode, FlowType, RiskLevel, TestCode } from '../../enums/index.enum';
import { listAnnotatedResults } from '../../services/result.service';
import { listTests, getTestByCode } from "../../services/test.service"
import { Affix, Card, Spin, Button, Input, Select, Form, InputNumber } from 'antd';
import { QuestionCircleOutlined,  ArrowRightOutlined, ExportOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/user.context';
import { listQuestionTemplates } from '../../services/question.service';
import { buildUser } from '../../services/user.service';
import { addFlow } from '../../services/flow.service';
import RecommendationHelper from '../../helpers/recommendation.helper';
import PhotoHelper from '../../helpers/photo.helper';
import UtilHelper from '../../helpers/util.helper';
import ResultHelper from '../../helpers/result.helper';
import { TestLink } from "../testLink/testLink.component";
import { addSlack } from '../../services/slack.service';
import MobileResultsDrawer from '../mobileResultsDrawer/mobileResultsDrawer.component';
const { Item } = Form   

const RECOMMENDATIONS = {
    [`${RiskLevel.HIGH}_${RiskLevel.HIGH}_${RiskLevel.HIGH}`]: {
        description: 'Your suboptimal muscle mass, elevated visceral fat, and low bone density increase risks for frailty, fractures, insulin resistance, and chronic disease.',
        nutrition: [
            'Restrict total calories while maintaining at least 1 g protein/lb body weight per day to support muscle growth.',
            'Focus on calcium- and vitamin D-rich foods (e.g., dairy, leafy greens, fortified foods).',
            'Avoid highly processed foods and refined carbohydrates.'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) focusing on compound movements like squats, deadlifts, presses, and rows to build lean muscle mass, which improves metabolic health and aids in fat reduction.',
            'Include weight-bearing cardio (e.g., walking, cycling) but limit excessive cardio to preserve muscle gains.',
            'Incorporate mobility and flexibility training (e.g. yoga or pilates) to reduce risk of falls and fractures.'
        ],
        supplements: [
            'Creatine monohydrate 3–5 grams/day to support muscle growth and strength.',
            'Calcium 1,200mg/day and vitamin D3 (if levels <40 ng/mL) for bone health and fracture prevention.',
            'Protein supplements if you\'re struggling to meet protein targets through diet alone.'
        ],
        testing: [
            'Schedule a follow-up DEXA scan in 6 months to track progress in your muscle mass, visceral fat, and bone density.'
        ]
    },
    [`${RiskLevel.HIGH}_${RiskLevel.HIGH}_${RiskLevel.OPTIMAL}`]: {
        description: 'Your suboptimal muscle mass and elevated visceral fat increase your risk of metabolic issues. Building more muscle and reducing visceral fat will lower your long-term health risks.',
        nutrition: [
            'Restrict total calories while maintaining at least 1 g protein/lb body weight per day to support muscle growth.',
            'Focus on nutrient-dense foods, e.g. lean proteins whole grains and unsaturated fats.',
            'Avoid highly processed foods and refined carbohydrates.'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) focusing on compound movements like squats, deadlifts, presses, and rows to build lean muscle mass, which improves metabolic health and aids in fat reduction.',
            'Include weight-bearing cardio (e.g., walking, cycling) but limit excessive cardio to preserve muscle gains.'
        ],
        supplements: [
            'Creatine monohydrate 3–5 grams/day to support muscle growth and strength.',
            'Protein supplements if you\'re struggling to meet protein targets through diet alone.'
        ]
    },
    [`${RiskLevel.HIGH}_${RiskLevel.OPTIMAL}_${RiskLevel.HIGH}`]: {
        description: 'You have good muscle mass but elevated visceral fat and low bone density, which increase your risk of fractures and metabolic conditions like type 2 diabetes.',
        nutrition: [
            'Restrict total calories while maintaining at least 1 g protein/lb body weight per day to support muscle growth.',
            'Focus on calcium- and vitamin D-rich foods (e.g., dairy, leafy greens, fortified foods).',
            'Incorporate more fiber (e.g., vegetables, legumes, whole grains) to improve satiety and metabolic health.',
            'Avoid highly processed foods and refined carbohydrates.'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) focusing on compound movements like squats, deadlifts, presses, and rows to build lean muscle mass, which improves metabolic health and aids in fat reduction.',
            'Add HIIT sessions (2–3 times/week) for VAT reduction.',
            'Incorporate mobility and flexibility training (e.g. yoga or pilates) to reduce risk of falls and fractures.'
        ],
        supplements: [
            'Creatine to support muscle growth.',
            'Calcium 1,200mg/day and vitamin D3 (if levels <40 ng/mL) for bone health and fracture prevention.'
        ]
    },
    [`${RiskLevel.HIGH}_${RiskLevel.OPTIMAL}_${RiskLevel.OPTIMAL}`]: {
        description: 'You have good muscle mass and normal bone density but carry excess visceral fat, which increases your risk of chronic diseases. Reducing visceral fat will improve metabolic health.',
        nutrition: [
            'Restrict total calories while maintaining current protein/lb of body weight per day.',
            'Incorporate more fiber (e.g., vegetables, legumes, whole grains) to improve satiety and metabolic health.',
            'Avoid highly processed foods and refined carbohydrates.'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) focusing on compound movements like squats, deadlifts, presses, and rows to build lean muscle mass, which improves metabolic health and aids in fat reduction.',
            'Add HIIT sessions (2–3 times/week) for VAT reduction.'
        ],
        supplements: []
    },
    [`${RiskLevel.OPTIMAL}_${RiskLevel.HIGH}_${RiskLevel.HIGH}`]: {
        description: 'Your suboptimal muscle mass and bone density increase risks for fractures and mobility issues. Improving muscle mass is critical for building strength and resilience.',
        nutrition: [
            'Prioritize protein intake of at least 1 g/lb body weight per day to maximize muscle protein synthesis.',
            '[If underweight] Increase overall caloric intake by 10–15% through nutrient-dense foods like nuts, seeds, avocados, and whole grains.',
            'Focus on calcium- and vitamin D-rich foods (e.g., dairy, leafy greens, fortified foods).',
            'Incorporate post-workout meals rich in protein and carbohydrates to optimize recovery.'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) focusing on progressive overload to stimulate muscle protein synthesis.',
            'Include compound lifts like squats, deadlifts, and presses.',
            'Include weight-bearing cardio (e.g., walking, cycling) but limit excessive cardio to preserve muscle gains.',
            'Incorporate mobility and flexibility training (e.g. yoga or pilates) to reduce risk of falls and fractures.'
        ],
        supplements: [
            'Creatine monohydrate 3–5 grams/day to support muscle growth and strength.',
            'Protein supplements if you\'re struggling to meet protein targets through diet alone.'
        ]
    },
    [`${RiskLevel.OPTIMAL}_${RiskLevel.HIGH}_${RiskLevel.OPTIMAL}`]: {
        description: 'You have suboptimal muscle mass and normal bone density. Building muscle will enhance your strength, mobility, and metabolic health.',
        nutrition: [
            'Prioritize protein intake of at least 1 g/lb body weight per day to maximize muscle protein synthesis.',
            'Maintain current caloric intake, focusing lean proteins, whole grains and unsaturated fats.'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) focusing on progressive overload to stimulate muscle protein synthesis.',
            'Include compound lifts like squats, deadlifts, and presses.',
            'Minimize excessive cardio to avoid calorie deficits that hinder muscle growth.'
        ],
        supplements: [
            'Creatine monohydrate 3–5 grams/day to support muscle growth and strength.',
            'Protein supplements if you\'re struggling to meet protein targets through diet alone.'
        ]
    },
    [`${RiskLevel.OPTIMAL}_${RiskLevel.OPTIMAL}_${RiskLevel.HIGH}`]: {
        description: 'You have balanced muscle and fat levels but low bone density, which increases your risk of fractures. Improving bone health is essential for long-term resilience.',
        nutrition: [
            'Maintain current caloric intake, focusing lean proteins, whole grains and unsaturated fats.',
            'Incorporate calcium- and vitamin D-rich foods (e.g., dairy, leafy greens, fortified foods).'
        ],
        exercise: [
            'Resistance training (3–5 sessions/week) with weight-bearing exercises (e.g., lunges, step-ups).',
            'Include weight-bearing cardio (e.g., walking, cycling) but limit excessive cardio to preserve muscle gains.',
            'Incorporate mobility and flexibility training (e.g. yoga or pilates) to reduce risk of falls and fractures.'
        ],
        supplements: [
            'Calcium 1,200mg/day and vitamin D3 (if levels <40 ng/mL) for bone health and fracture prevention.'
        ]
    },
    [`${RiskLevel.OPTIMAL}_${RiskLevel.OPTIMAL}_${RiskLevel.OPTIMAL}`]: {
        description: 'You have a well-balanced body composition with optimal muscle, fat, and bone density, positioning you in excellent health. Focus on refining fitness and nutrition strategies to optimize performance and maintain longevity.',
        nutrition: [
            'Maintain current caloric and macronutrient intake while fine-tuning based on activity levels.'
        ],
        exercise: [
            'Refine your training regimen with advanced techniques such as periodization, progressive overload, and specialized cycles (e.g., hypertrophy, strength, or endurance phases).'
        ],
        supplements: []
    }
};

const metricLabels = {
    [TestCode.DEXA_ALMI]: {
        title: 'Muscle Mass',
        description: 'ALMI',
        unit: 'kg/m²'
    },
    [TestCode.DEXA_VAT_MASS]: {
        title: 'Visceral Fat',
        description: 'VAT Mass',
        unit: 'g'
    },
    [TestCode.DEXA_BMD_Z_SCORE]: {
        title: 'Bone Density',
        description: 'BMD Z-Score',
        unit: 'SD'
    },
    [TestCode.DEXA_BMD_T_SCORE]: {
        title: 'Bone Density',
        description: 'BMD T-Score',
        unit: 'SD'
    }
};

export const DexaRecommender = () => {
    const [recommendations, setRecommendations] = useState([]);
    const [results, setResults] = useState([]);
    const ageInputRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [hasOverflow, setHasOverflow] = useState(false);
    const [isMobileResultsOpen, setIsMobileResultsOpen] = useState(false);
    const { currentUser, token, instalabMembership } = useContext(UserContext);
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [flow, setFlow] = useState(null)
    const [vatUnit, setVatUnit] = useState('g');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTestCode, setSelectedTestCode] = useState(null);
    const [allResults, setAllResults] = useState([]);
    const [allTests, setAllTests] = useState([]);

    useEffect(() => {

        const fetchQuestions = async () => {    
            const response = await listQuestionTemplates({
                filter: {
                    code: {
                        $in: [
                            QuestionTemplateCode.DOB,
                            QuestionTemplateCode.GENDER,
                            QuestionTemplateCode.SLEEP_QUALITY,
                        ]
                    }
                }
            })
            setQuestions(response)
        }

        const fetchFlow = async () => {
            const response = await addFlow({type: FlowType.QUIZ, forceNew: true})
            setFlow(response)
        }

        const fetchCurrentUser = async () => {
            try {
                const newAnswers = await RecommendationHelper.getAnswers(currentUser)
                setAnswers(newAnswers);
            } catch (error) {
                console.error('Error fetching current user:', error);
            } 
     
        }

        setAnswers({
            [getQuestionTemplate(QuestionTemplateCode.DOB)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.GENDER)?.field]: '',
            [getQuestionTemplate(QuestionTemplateCode.SLEEP_QUALITY)?.field]: '',
            vatMass: '',
            almi: '',
            bmdZScore: '',
            bmdTScore: '',
            height: '',
            weight: '',
        })
        
        fetchQuestions()
        fetchFlow()
        fetchCurrentUser()
        ageInputRef.current.focus();
    }, [])

    useEffect(() => {
        if (!token || !currentUser) return

        const fetchResults = async () => {
          const tests = await listTests({
            // select: '_id code',
            filter: { code: { $in: [TestCode.DEXA_ALMI, TestCode.DEXA_BMD_Z_SCORE, TestCode.DEXA_BMD_T_SCORE, TestCode.DEXA_VAT_MASS] } }
          });
          setAllTests(tests)
          
          const results = await listAnnotatedResults({
            // select: '_id values collectedAt',
            filter: { 
                patient: currentUser._id,
             },
          });

          setAllResults(results)

          const sortedResults = results.sort((a, b) => new Date(b.collectedAt) - new Date(a.collectedAt));

          const almiTest = tests.find(t => t.code === TestCode.DEXA_ALMI);
          const bmdZTest = tests.find(t => t.code === TestCode.DEXA_BMD_Z_SCORE);
          const bmdTTest = tests.find(t => t.code === TestCode.DEXA_BMD_T_SCORE);
          const vatTest = tests.find(t => t.code === TestCode.DEXA_VAT_MASS);

          const almi = sortedResults.find(({ values }) =>
            values.some(value => value.test.toString() === almiTest?._id?.toString())
          );

          const bmdZScore = sortedResults.find(({ values }) =>
            values.some(value => value.test.toString() === bmdZTest?._id?.toString())
          );

          const bmdTScore = sortedResults.find(({ values }) =>
            values.some(value => value.test.toString() === bmdTTest?._id?.toString())
          );

          const vatMass = sortedResults.find(({ values }) =>
            values.some(value => value.test.toString() === vatTest?._id?.toString())
          );

          setAnswers(prev => ({
            ...prev,
            almi: almi ? { 
                value: almi.values.find(value => value.test.toString() === almiTest?._id?.toString())?.value,
                _id: almiTest._id
            } : null,
            bmdZScore: bmdZScore ? { 
                value: bmdZScore.values.find(value => value.test.toString() === bmdZTest?._id?.toString())?.value,
                _id: bmdZTest._id
            } : null,
            bmdTScore: bmdTScore ? { 
                value: bmdTScore.values.find(value => value.test.toString() === bmdTTest?._id?.toString())?.value,
                _id: bmdTScore._id
            } : null,
            vatMass: vatMass ? { 
                value: vatMass.values.find(value => value.test.toString() === vatTest?._id?.toString())?.value,
                _id: vatTest._id
            } : null
          }));

        };
      
        // loggined 
        if (token) {
            try {   
                fetchResults();
            } catch (error) {
                console.error('Error fetching results:', error);
            } 
        }
      }, [currentUser, token]);


    useEffect(() => {
        
        const getRiskLevel = async (testCode, value, gender, dob) => {
            const test = await getTestByCode(testCode, {patient: {gender: gender, dob: dob}})
            const testRisk = ResultHelper.getTestValueRisk(value, test.risk)
            return testRisk
        };

        const fetchResults = async () => {
            const currentAnswers = answers
            
            if (!currentAnswers?.gender || !currentAnswers?.dob || !currentAnswers?.vatMass?.value || !currentAnswers?.almi?.value || (isNaN(currentAnswers?.bmdZScore?.value) && isNaN(currentAnswers?.bmdTScore?.value))) {
                setResults([])
                return
            }
            const results = [
                {
                    code: TestCode.DEXA_VAT_MASS,
                    _id: currentAnswers.vatMass?._id,
                    value: currentAnswers.vatMass?.value,
                    risk: currentAnswers.vatMass?.risk 
                        ? currentAnswers.vatMass?.risk 
                        : await getRiskLevel(
                            TestCode.DEXA_VAT_MASS, 
                            currentAnswers.vatMass?.value, 
                            currentAnswers.gender,
                            currentAnswers.dob
                        ),
                    percentile: currentAnswers.vatMass?.percentile 
                },
                {
                    code: TestCode.DEXA_ALMI,
                    _id: currentAnswers.almi?._id,
                    value: currentAnswers.almi?.value,
                    risk: await getRiskLevel(
                            TestCode.DEXA_ALMI, 
                            currentAnswers.almi?.value, 
                            currentAnswers.gender,
                            currentAnswers.dob
                        ),
                    percentile: currentAnswers.almi?.percentile    
                },
                {
                    code: TestCode.DEXA_BMD_Z_SCORE,
                    _id: currentAnswers.bmdZScore?._id,
                    value: currentAnswers.bmdZScore?.value,
                    risk: await getRiskLevel(
                            TestCode.DEXA_BMD_Z_SCORE, 
                            currentAnswers.bmdZScore?.value, 
                            currentAnswers.gender,
                            currentAnswers.dob
                        ),
                    percentile: currentAnswers.bmdZScore?.percentile 
                },
                {
                    code: TestCode.DEXA_BMD_T_SCORE,
                    _id: currentAnswers.bmdTScore?._id,
                    value: currentAnswers.bmdTScore?.value,
                    risk: await getRiskLevel(
                            TestCode.DEXA_BMD_T_SCORE, 
                            currentAnswers.bmdTScore?.value, 
                            currentAnswers.gender,
                            currentAnswers.dob
                        ),
                    percentile: currentAnswers.bmdTScore?.percentile 
                }
            ]

            
            setResults(results)
        }

        fetchResults()
    }, [answers, currentUser])

    // set new recommendations when results change
    useEffect(() => {
        const fetchRecommendations = () => {
            if (results.length === 0) {
                setRecommendations(null)
                return
            }
    
            // Convert MODERATE to HIGH risk when creating the key
            const getRiskLevel = (risk) => {
                return risk === RiskLevel.MODERATE ? RiskLevel.HIGH : risk;
            };

            // Get BMD score, preferring Z-score but falling back to T-score
            const bmdResult = results.find(result => result.code === TestCode.DEXA_BMD_Z_SCORE)?.risk 
                ? results.find(result => result.code === TestCode.DEXA_BMD_Z_SCORE)
                : results.find(result => result.code === TestCode.DEXA_BMD_T_SCORE);

            // Create key from existing risk levels in the answers
            const recommendationKey = `${getRiskLevel(results.find(result => result.code === TestCode.DEXA_VAT_MASS).risk)}_${getRiskLevel(results.find(result => result.code === TestCode.DEXA_ALMI).risk)}_${getRiskLevel(bmdResult?.risk)}`;
            
            const recommendations = RECOMMENDATIONS[recommendationKey];
            setRecommendations(recommendations)
        };

        try {
            fetchRecommendations()    
        } catch (error) {
            console.error('Error fetching recommendations:', error);
        } 
        finally {
            setIsLoading(false)
        }
    }, [results])


    const getQuestionTemplate = (code) => {
        return questions.find(question => question.code === code)
    }

    useEffect(() => {
        const checkOverflow = () => {
            if (scrollContainerRef.current) {
                const hasVerticalOverflow = scrollContainerRef.current.scrollHeight > scrollContainerRef.current.clientHeight;
                setHasOverflow(hasVerticalOverflow);
            }
        };
        
        checkOverflow();
        // Recheck when recommendations change
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [recommendations]);

    const handleChange = async (e, name) => {
        // Check if e is an event object with a target, otherwise assume it's a direct value
        const isEvent = e && e.target;
        const value = isEvent ? e.target.value : e;
        const fieldName = name || (isEvent ? e.target.name : null);

        if (!fieldName) {
            console.error('Field name is required for handleChange');
            return;
        }

        // Handle the new value based on input type and field name
        let newValue;
        if (['vatMass', 'almi', 'bmdZScore', 'bmdTScore'].includes(fieldName)) {
            newValue = { value: value };
        } else {
            const type = isEvent ? e.target.type : null;
            const checked = isEvent ? e.target.checked : null;
            newValue = type === 'checkbox'
                ? checked
                    ? [...(answers[fieldName] || []), value]
                    : answers[fieldName].filter(item => item !== value)
                : value;
        }

        // Update state
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [fieldName]: newValue
        }));

        // Update user in system
        try {
            await buildUser({
                fields: { [fieldName]: newValue.value ? newValue.value : newValue },
                flow: flow._id,
            });
        } catch (error) {
            console.error('Error building user:', fieldName, error);
        }
    };

    const handleDateChange = (e, name) => {

        const isEvent = e && e.target;
        const rawInput = isEvent ? e.target.value : e;
        const fieldName = name || (isEvent ? e.target.name : null);

        if (!fieldName) {
            console.error('Field name is required for handleChange');
            return;
        }
        const formattedDate = UtilHelper.formatDateInput(rawInput);

        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [getQuestionTemplate(QuestionTemplateCode.DOB)?.field]: formattedDate
        }));


        // Update state with formatted value
        if (UtilHelper.isValidDate(formattedDate)) {
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                [getQuestionTemplate(QuestionTemplateCode.DOB)?.field]: formattedDate
            }));

            handleChange({ 
                target: { 
                    name: getQuestionTemplate(QuestionTemplateCode.DOB)?.field, 
                    value: formattedDate 
                } 
            });
        }
    };

    const handleDateBlur = (e) => {
        const currentValue = e.target.value;
        
        if (currentValue && !UtilHelper.isValidDate(currentValue)) {
            // Show error message
            e.target.setCustomValidity('Please enter a valid date in MM/DD/YYYY format');
            e.target.reportValidity();
        } else {
            e.target.setCustomValidity('');
            
            // Only update the system if it's a valid date
            if (currentValue && UtilHelper.isValidDate(currentValue)) {
                handleChange({ 
                    target: { 
                        name: getQuestionTemplate(QuestionTemplateCode.DOB)?.field, 
                        value: currentValue 
                    } 
                });
            }
        }
    };

    const onScheduleClick = async () => {
        try {
            await addSlack({
              message: currentUser.firstName ? `[${currentUser.firstName} ${currentUser.lastName}] DEXA Scan Schedule Click:` : `DEXA Scan Schedule Click:`,
              channel: process.env.REACT_APP_FLOW_SLACK
            });
          } catch (error) {
            console.error('Failed to log message to Slack:', error);
          }
        window.open(`/flow/${FlowType.DEXA_BODYCOMP}`, '_blank');
    }

    const EmptyState = () => (
        <div className="empty-state">
            <div className="arrow-up mobile-only">↑</div>
            <p>Fill in your DEXA results to get your recommendations.</p>
            <div className="arrow-left desktop-only">&larr;</div>
            {!token && <p>If you're already an Instalab patient with DEXA results, <a href="/login?redirect=/tools/dexa" className="cta-link">log in</a> to preload all your data for your recommendations.</p>} 
        </div>
    )

    const ResultsPanel = ({ hideTitle = false }) => (
        <div className="results-panel">
            {!hideTitle && (
                <>
                    <h2>
                        Your DEXA Analysis
                    </h2>
                    {recommendations && (
                        <>
                            <p className="results-subtitle" style={{ marginTop: -20 }}>
                                Based on your profile and results, here are your personalized recommendations to stay on top of your health:
                            </p>
                            
                          
                        </>
                    )}
                </>
            )}
            <div 
                ref={scrollContainerRef}
                className={`results-scroll-container ${hasOverflow ? 'has-overflow' : ''}`}
            >
            {isLoading ? (
                <div className="loading-state">
                    <Spin size="large" />
                    <p>Loading your health profile...</p>
                </div>
            ) : results.length === 0 && !recommendations ? (<EmptyState /> ) 
            : (
                <>
                    {recommendations && (
                        <div className="overview-section">
                            <h3 className="section-title">Overview</h3>
                                <Card className="recommendation-card flat">
                                    <div className="recommendation-content">
                                        <div className="recommendation-section">
                                            <p>{recommendations.description}</p>
                                        </div>
                                    </div>
                                </Card>
                        </div>
                    )}

                    {results.length > 0 && (
                        <div className="results-section">
                            <h3 className="section-title">Your Key Metrics</h3>
                            <div className="metrics-grid">
                                {results.map((result, index) => {

                                    if (isNaN(result.value)) {
                                        return null
                                    }

                                    const levelClass = result.risk === RiskLevel.HIGH ? 'needs-attention' 
                                        : result.risk === RiskLevel.MODERATE ? 'borderline' 
                                        : 'optimal';
                                    
                                    const levelDisplay = result.risk === RiskLevel.HIGH ? 'Needs Attention'
                                        : result.risk === RiskLevel.MODERATE ? 'Borderline'
                                        : 'Optimal';
                                    
                                    const metric = metricLabels[result.code];
                                    
                                    return (
                                        <div key={index} className={`metric-card ${levelClass}`}>
                                       
                                            <div className="metric-header">
                                                <div className="metric-title-wrapper">
                                                    <h4 className="metric-title">{metric.title}</h4>
                                    
                                                </div>
                                                <div className="metric-details">
                                                    <span className="metric-subtitle">
                                                        <TestLink
                                                        testCode={result.code}
                                                        content={
                                                            <>
                                                                {metric.description}
                                                                <ExportOutlined className="link-icon" />
                                                            </>
                                                        }                                                          
                                                        tests={allTests}
                                                        results={allResults}
                                                        className="test-link"
                                                            // reports={allReports}
                                                        />
                                            

                                                    </span>
                                                    <div className="metric-value-container">
                                                        <span className="value">{result.value}</span>
                                                        <span className="unit">{metric.unit}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="metric-footer">
                                                <div className="status-wrapper">
                                                    <span className="status-text">{levelDisplay}</span>
                                                </div>
                                                {result.percentile && (
                                                    <div className="percentile-wrapper">
                                                        <div className="percentile-value">
                                                            {result.percentile}{UtilHelper.getOrdinalSuffix(result.percentile)}
                                                        </div>
                                                        <div className="percentile-label">percentile</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {recommendations && (
                        <div className="recommendations-section">
                            <h3 className="section-title">Your Recommendations</h3>
                                <Card className="recommendation-card flat">
                                    <div className="recommendation-content">
                          
                                        
                                        {recommendations.nutrition && recommendations.nutrition.length > 0 && (
                                            <div className="recommendation-section">
                                                <h4 className="recommendation-heading">Nutrition</h4>
                                            {Array.isArray(recommendations.nutrition) ? (
                                                <ul>
                                                    {recommendations.nutrition.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>{recommendations.nutrition}</p>
                                                )}
                                            </div>
                                        )}
                                        
                                        {recommendations.exercise && recommendations.exercise.length > 0 && (
                                            <div className="recommendation-section">
                                                <h4 className="recommendation-heading">Physical Activity</h4>
                                            {Array.isArray(recommendations.exercise) ? (
                                                <ul>
                                                    {recommendations.exercise.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>{recommendations.exercise}</p>
                                                )}
                                            </div>
                                        )}
                                        
                                        {recommendations.supplements && recommendations.supplements.length > 0 && (
                                            <div className="recommendation-section">
                                                <h4 className="recommendation-heading">Supplements</h4>
                                                {Array.isArray(recommendations.supplements) ? (
                                                    <ul>
                                                    {recommendations.supplements.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>{recommendations.supplements}</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Card>
                           
                        </div>
                    )}
                </>
            )}
            </div>
        </div>
    );

    const UpsellUnit = () => (
        <div className="upsell-panel">
            <div className="upsell-content">
                <div className="card-title">
                    <QuestionCircleOutlined style={{ fontSize: '20px', color: '#0ca37f' }} />
                    <div className="title-text">
                        <h4>Need a DEXA scan?</h4>
                    </div>

                    <Button 
                        type="default" 
                        className="schedule-btn"
                        onClick={onScheduleClick}
                        style={{ marginLeft: 'auto' }}
                    >
                        Schedule Scan <ArrowRightOutlined />
                    </Button>
                </div>
            </div>
        </div>
    )

    // const MobileResultsDrawer = () => (


    //     // <>
    //     //     <div 
    //     //         className={`mobile-drawer-trigger ${recommendations ? 'has-results' : ''}`}
    //     //         onClick={() => setIsMobileResultsOpen(true)}
    //     //     >
    //     //         <div className="drawer-trigger-content">
    //     //             <div className="trigger-header">
    //     //                 <div className="header-left">
    //     //                     <h2>Your DEXA Analysis</h2>
                           
               
                          
    //     //                 </div>
    //     //                 <div className="pull-up-indicator">
    //     //                     {recommendations ? (
    //     //                         <>
    //     //                         <div className="results-preview">
    //     //                             <CheckCircleOutlined className="results-icon" />
    //     //                             <span>Results Ready</span>
    //     //                         </div>

    //     //                         <div className="pull-icons">
    //     //                             <UpOutlined className="pull-icon" />
    //     //                             <UpOutlined className="pull-icon delayed" />
    //     //                         </div>
    //     //                         </>
    //     //                     ) : (
    //     //                         <div className="results-preview">
    //     //                             <CloseCircleOutlined className="results-icon" />
    //     //                             <span>Input your results</span>
    //     //                         </div>
    //     //                     )}

    //     //                 </div>
    //     //             </div>
    //     //         </div>
    //     //     </div>

    //     //     <Drawer
    //     //         placement="bottom"
    //     //         height="75vh"
    //     //         open={isMobileResultsOpen}
    //     //         onClose={() => setIsMobileResultsOpen(false)}
    //     //         title={
    //     //             <div 
    //     //                 className="drawer-header"
    //     //                 onClick={() => setIsMobileResultsOpen(false)}
    //     //             >
    //     //                 <h2>Your Dexa Analysis<DownOutlined className="close-icon" />
    //     //                 </h2>
    //     //             </div>
    //     //         }
    //     //         className="mobile-results-drawer"
    //     //         styles={{
    //     //             body: { padding: '24px 16px 16px' },
    //     //         }}
    //     //     >
    //     //         <ResultsPanel hideTitle={true} />
    //     //         <UpsellUnit />
    //     //     </Drawer>
    //     // </>
    // );

    const RadioCard = ({ name, value, label, checked, onChange }) => (
        <div 
            className={`radio-card ${checked ? 'selected' : ''}`}
            onClick={() => onChange({ target: { name, value } })}
        >
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span>{label}</span>
        </div>
    );

    const convertVatMass = (value, fromUnit, toUnit) => {
        if (!value) return '';
        if (fromUnit === toUnit) return value;
        // Convert lbs to g and round to 2 decimal places
        return fromUnit === 'lbs' 
            ? Number((value * 453.592).toFixed(3))
            : Number((value / 453.592).toFixed(2));
    };

    // DEBUG - comment out for production
    // console.log("questions", questions)
    // console.log("answers", answers?.dob, answers?.vatMass, answers?.almi, answers?.bmdZScore, answers?.bmdTScore)
    // console.log("results", results)
    // console.log("recommendations", recommendations)

    const handleOpenModal = (testCode) => {
        setSelectedTestCode(testCode);
        setIsModalOpen(true);
    };

    return (
        questions && <div className="dexa-recommender-container">

            {PhotoHelper.format({
                jpg: "/img/green.jpg",
                webp: "/img/green.webp",
                className: "intro-bg",
            })}

            <div className="panels-container">
                <div className="questions-panel">
                    <h2>Input Your DEXA Results</h2>
                    <p className="description">All fields are required to get your personalized recommendations.</p>
                    <Form layout="vertical" className="questions-form">

                        <div className="form-group">
                            <Item
                                label={getQuestionTemplate(QuestionTemplateCode.DOB)?.content}
                                // name="dobItem"
                                rules={[{ required: true, message: 'Please input your date of birth!' }]}
                                extra="Enter in the format MM/DD/YYYY."
                            >
                            <Input
                                name={getQuestionTemplate(QuestionTemplateCode.DOB)?.field}
                                placeholder="MM/DD/YYYY"
                                value={answers?.[getQuestionTemplate(QuestionTemplateCode.DOB)?.field]}
                                onChange={(e) => handleDateChange(e, 'dob')}
                                onBlur={handleDateBlur}
                                className="form-input"
                                maxLength="10"
                                ref={ageInputRef}
                                defaultValue={answers?.[getQuestionTemplate(QuestionTemplateCode.DOB)?.field]}
                            />
                            </Item>
                        </div>

                        <div className="form-group">
                                <Item
                                label={getQuestionTemplate(QuestionTemplateCode.GENDER)?.content}
                                // name="genderItem"
                                rules={[{ required: true, message: 'Please select your biological sex!' }]}
                            >
                            <div className="radio-cards-group">
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.GENDER)?.field}
                                    value="Male"
                                    label="Male"
                                    checked={answers?.[getQuestionTemplate(QuestionTemplateCode.GENDER)?.field] === 'Male'}
                                    onChange={handleChange}
                                />
                                <RadioCard
                                    name={getQuestionTemplate(QuestionTemplateCode.GENDER)?.field}
                                    value="Female"
                                    label="Female"
                                    checked={answers?.[getQuestionTemplate(QuestionTemplateCode.GENDER)?.field] === 'Female'}
                                    onChange={handleChange}
                                />
                            </div>
                            </Item>
                        </div>
                        <div className="form-group">
                        <Item
                            label="Visceral Adipose Tissue (VAT) Mass"
                            // name="vatMassItem"
                            rules={[{ required: true, message: 'Please input your VAT mass' }]}
                            extra="Please note whether the unit is grams or lbs."
                        >
                            <InputNumber
                                type="number"
                                placeholder="Enter VAT Mass"
                                name="vatMass"
                                value={vatUnit === 'g' 
                                    ? (answers?.vatMass?.value !== undefined ? Number(answers?.vatMass?.value) : null)
                                    : (answers?.vatMass?.value !== undefined ? convertVatMass(answers?.vatMass?.value, 'g', 'lbs') : null)
                                }
                                onChange={(value) => {
                                    const valueInGrams = vatUnit === 'lbs' ? convertVatMass(value, 'lbs', 'g') : value;
                                    handleChange(valueInGrams, 'vatMass');
                                }}
                                className="form-input"
                                addonAfter={
                                    <Select 
                                        value={vatUnit}
                                        onChange={setVatUnit}
                                        className="vat-unit-select"
                                    >
                                        <Select.Option value="g">g</Select.Option>
                                        <Select.Option value="lbs">lbs</Select.Option>
                                    </Select>
                                }
                            />
                        </Item>
                        </div>


                        <div className="form-group">
                        <Item   
                            label="Appendicular Lean Mass Index (ALMI)"
                            // name="almiItem"
                            extra="If not in your report, calculate: (arm + leg lean mass in kg) ÷ (height in meters)²"
                            rules={[{ required: true, message: 'Please input your ALMI!' }]}
                        >
                            <InputNumber
                                type="number"
                                placeholder="Enter ALMI"
                                name="almi"
                                value={answers?.almi?.value}
                                onChange={(value) => handleChange(value, 'almi')}
                                className="form-input"
                                addonAfter="kg/m²"
                            />

                            
                        </Item>
                        </div>



                        <div className="form-group">
                        <Item
                            label="Bone Mineral Density (BMD) Score"
                            rules={[{ required: true, message: 'Please input your BMD score!' }]}
                            extra="Enter either Z-Score or T-Score from your DEXA report"
                        >
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <Item
                                    label="T-Score"
                                    style={{ flex: 1 }}
                                >
                                    <InputNumber
                                        type="number"
                                        placeholder="Enter T-Score"
                                        name="bmdTScore"
                                        value={answers?.bmdTScore?.value}
                                        onChange={(value) => handleChange(value, 'bmdTScore')}
                                        className="form-input"
                                    />
                                </Item>
                                <Item
                                    // name="bmdZScore"
                                    label="Z-Score"
                                    style={{ flex: 1 }}
                                >
                                    <InputNumber
                                        type="number"
                                        placeholder="Enter Z-Score"
                                        name="bmdZScore"
                                        value={answers?.bmdZScore?.value}
                                        onChange={(value) => handleChange(value, 'bmdZScore')}
                                        className="form-input"
                                    />
                                </Item>

                            </div>
                        </Item>
                        </div>
                    </Form>
                </div>

                <div className="results-wrapper">
                    <Affix offsetTop={24} className="desktop-only">
                        <ResultsPanel />
                        <UpsellUnit/>
                    </Affix>
                </div>
            </div>
            
            <div className="mobile-only">
                <MobileResultsDrawer
                    headerText="Your DEXA Analysis"
                    hasResults={recommendations}
                    children={<ResultsPanel hideTitle={true} />}
                />
      
            </div>
        </div>
    );
}

