import { Typography } from "antd";
import classNames from "classnames";
import { useParams, useNavigate } from "react-router-dom";
import FlowType from "../../../enums/flowType.enum";
import PosthogHelper from "../../../helpers/posthog.helper";
import { BlogData } from "../../../data/blog.data";
import { useContext } from "react";
import { UserContext } from "../../../contexts/user.context";
import BlogId from "../../../enums/blogId.enum";

const { Paragraph } = Typography

export const WeightLossPost = () => {
  const { blogId } = useParams()
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)

  return (
    <div className={classNames("blog-post", "weight-loss-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image bottom" />
      </div>
      
      <div className="blog-body">
        <Paragraph className="blog-title">
          Measure Progress with a DEXA Scan
        </Paragraph>
        <Paragraph className="blog-description">
          Since a standard scale won't tell you whether you're losing fat or muscle, a DEXA scan is the best way to track real progress. This test provides a detailed breakdown of your body composition, showing how much of your weight comes from fat, muscle, and bone. This allows you to target the right kind of fat loss while ensuring you're maintaining or even gaining muscle mass.
        </Paragraph>

        <Paragraph className="blog-description">
          A DEXA scan is quick and non-invasive, taking about 15 minutes while you remain fully clothed. Results are typically available immediately after the scan, allowing you to see exactly how your body composition is changing. If you're interested, our team can help you <a className="blog-link" onClick={() => {
            navigate(`/flow/${FlowType.DEXA}`)
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: dexa scan`, true)
          }}>find a nearby imaging facility</a> and schedule an appointment at a convenient location.
        </Paragraph>

        <Paragraph className="blog-title">
          Lose the Right Kind of Weight
        </Paragraph>
        <Paragraph className="blog-description">
          One of the biggest mistakes people make when losing weight is losing muscle along with fat. Since muscle plays a crucial role in metabolism, mobility, and even longevity, muscle loss can be a serious setback to your health.
        </Paragraph>

        <Paragraph className="blog-description">
          Not all fat is the same, either. <b>Visceral fat</b>—the fat stored around your organs—is the most dangerous type, linked to an increased risk of heart disease, diabetes, and metabolic disorders. <b>Subcutaneous fat</b>—the fat just under your skin—is much less harmful and even provides some protective benefits.
        </Paragraph>

        <Paragraph className="blog-description">
          Tracking body composition with DEXA scans over time helps ensure that you're prioritizing visceral fat loss while preserving muscle mass.
        </Paragraph>

        <Paragraph className="blog-title">
          Why Crash Dieting Is a Mistake
        </Paragraph>
        <Paragraph className="blog-description">
          Even if you're targeting the right type of fat, <i>crash dieting is not the answer.</i> Severely restricting calories or eliminating entire food groups can lead to nutrient deficiencies and hormonal imbalances. Studies show that extreme dieting often results in muscle loss, a decrease in resting metabolic rate, and increased hunger hormones, making long-term weight maintenance nearly impossible.
        </Paragraph>

        <Paragraph className="blog-description">
          For sustainable fat loss, your body needs an approach it can maintain without triggering a starvation response. Slow, consistent fat loss ensures that you're improving your health—not just chasing a lower number on the scale.  We recommend aiming for about <b>1% of body weight per week</b>—a reasonable target that minimizes muscle loss while promoting steady fat reduction.
        </Paragraph>

        <Paragraph className="blog-title">
          Start With Exercise and Diet
        </Paragraph>
        <Paragraph className="blog-description">
          The best place to start your weight loss journey isn't with a fad diet or a trendy workout—it's with consistent, balanced nutrition and movement.
        </Paragraph>

        <ul className="blog-list">
          <li>
            <b>Exercise:</b> Research shows that 150 minutes of moderate-intensity exercise per week is the minimum for heart health, but more movement is always better. Strength training at least twice per week is also crucial for preserving muscle during weight loss.
          </li>
          <li>
            <b>Diet:</b> There's no single "perfect" diet, but most people intuitively know what an unhealthy diet looks like. Reducing processed foods, prioritizing lean protein, fiber, and healthy fats, and maintaining a moderate calorie deficit supports fat loss in a sustainable way.
          </li>
        </ul>

        <Paragraph className="blog-description">
          For most people, this approach alone is enough to lose significant weight.
        </Paragraph>

        <Paragraph className="blog-title">
          What About Ozempic?
        </Paragraph>
        <Paragraph className="blog-description">
          If you've truly committed to diet and exercise and still struggle with weight loss, <a className="blog-link" onClick={() => {
            navigate(`/research/${BlogId.OZEMPIC}`)
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: ozempic research article`, true)
          }}>Ozempic (semaglutide)</a> may be an option. This medication suppresses appetite and has been shown to provide significant cardiovascular benefits, including lowering ApoB and LDL cholesterol levels.
        </Paragraph>

        <Paragraph className="blog-description">
          While Ozempic is a relatively safe medication, we do not recommend it as a first-line solution. The best approach is to first adopt healthy habits through diet and exercise. If weight loss remains a struggle, we <a className="blog-link" onClick={() => {
            navigate(`/heart`)
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: heart health`, true)
          }}>work with patients</a> to prescribe Ozempic and track their metabolic and cardiovascular biomarkers to ensure the medication is improving overall health—not just reducing weight.
        </Paragraph>

        <Paragraph className="blog-title">
          Final Thoughts
        </Paragraph>
        <Paragraph className="blog-description">
          Your goal shouldn't be just to lose weight—it should be better health. And while there's nothing wrong with wanting to lose weight for appearance, it should never come at the cost of muscle mass, metabolic health, or longevity. If you're already on your weight loss journey, we encourage you to adopt a sustainable strategy and schedule a DEXA scan as soon as possible to assess your body composition.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Ibrahim, M. (2010). Subcutaneous and visceral adipose tissue: structural and functional differences. Obesity Reviews, 11. <a onClick={() => window.open('https://doi.org/10.1111/j.1467-789X.2009.00623.x', '_blank')}>https://doi.org/10.1111/j.1467-789X.2009.00623.x</a>
            </li>
            <li>
              Hall, K., Sacks, G., Chandramohan, D., Chow, C., & Swinburn, B. (2011). Quantification of the effect of energy imbalance on bodyweight. The Lancet, 378, 826-837. <a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(11)60812-X', '_blank')}>https://doi.org/10.1016/S0140-6736(11)60812-X</a>
            </li>
            <li>
              Jacquet, P., Schutz, Y., Montani, J., & Dulloo, A. (2020). How dieting might make some fatter: modeling weight cycling toward obesity from a perspective of body composition autoregulation. International Journal of Obesity (2005), 44, 1243 - 1253. <a onClick={() => window.open('https://doi.org/10.1038/s41366-020-0547-1', '_blank')}>https://doi.org/10.1038/s41366-020-0547-1</a>
            </li>
            <li>
              Stamatakis, E., Straker, L., Hamer, M., & Gebel, K. (2019). The 2018 Physical Activity Guidelines for Americans: What's New? Implications for Clinicians and the Public.. The Journal of orthopaedic and sports physical therapy, 49 7, 487-490. <a onClick={() => window.open('https://doi.org/10.2519/jospt.2019.0609', '_blank')}>https://doi.org/10.2519/jospt.2019.0609</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};