import { useContext, useEffect, useState, useRef, useLayoutEffect } from "react";
import { BlogList } from "../blogList/blogList.component";
import moment from "moment";
import { BlogData } from "../../data/blog.data";
import { Typography, Row, Col, Image, Tabs } from "antd";
import { UserContext } from "../../contexts/user.context";
import PosthogHelper from "../../helpers/posthog.helper";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownArrowSVG } from '../../assets/svg/down-arrow.svg'
import "./research.scss"
import { SubscribeButton } from "../subscribeButton/subscribeButton.component";
import useWidth from "../../hooks/useWidth.hook";

const { Paragraph, Text } = Typography

export const Research = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const blogs = BlogData.filter(({ img }) => img).sort((a, b) => b.publishedAt - a.publishedAt)
  const blog = blogs[0]
  const categories = BlogData.reduce((acc, blog) => {
    blog.categories.forEach(category => {
      acc[category] = (acc[category] || 0) + 1
    })
    return acc
  }, {})

  const sortedCategories = Object.entries(categories)
    .sort(([,a], [,b]) => b - a)
    .map(([category]) => category)

  const [activeTab, setActiveTab] = useState('all');
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const [visibleCategories, setVisibleCategories] = useState(5);
  const tabsContainerRef = useRef(null);
  const width = useWidth()

  useEffect(() => {
    document.title = 'Instalab | Research'
  }, [])

  useEffect(() => {
    if (currentUser) {
      PosthogHelper.track(currentUser, '[research] view list', true)
    }
  }, [currentUser])

  useLayoutEffect(() => {
    const updateVisibleTabs = () => {
      if (!tabsContainerRef.current) return;
      
      const containerWidth = tabsContainerRef.current.offsetWidth;
      // Adjusted width calculation: 
      // 80px for "All" tab + ~100px per category tab + some padding
      const approximateTabWidth = 100;
      const allTabWidth = 80;
      const availableWidth = containerWidth - allTabWidth;
      const possibleTabs = Math.floor(availableWidth / approximateTabWidth);
      setVisibleCategories(Math.max(1, Math.min(possibleTabs, sortedCategories.length)));
    };

    updateVisibleTabs();
    window.addEventListener('resize', updateVisibleTabs);
    return () => window.removeEventListener('resize', updateVisibleTabs);
  }, [sortedCategories.length]);

  const formatDate = (date) => {
    return moment(date).format('YYYY.MM.DD')
  }

  const viewBlog = (blog) => {
    PosthogHelper.track(currentUser, `[research] click on post: ${blog.id}`, true)
    navigate(`/research/${blog.id}`)
  }

  const scrollToList = () => {
    document.querySelector('.research-list').scrollIntoView({ 
      behavior: 'smooth'
    });
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key === 'all') {
      setFilteredBlogs(blogs);
    } else {
      setFilteredBlogs(blogs.filter(blog => blog.categories.includes(key)));
    }
  };

  const handleCategoryClick = (category) => {
    setActiveTab(category);
    setFilteredBlogs(blogs.filter(blog => blog.categories.includes(category)));
    document.querySelector('.research-list').scrollIntoView({ 
      behavior: 'smooth'
    });
    PosthogHelper.track(currentUser, `[research] filter research: ${category}`, true)
  };

  return (
    <div className="research">
      <div className="research-header">
        <Paragraph className="research-title">
          Research
        </Paragraph>

        <div className="blog-category-list">
          {sortedCategories.slice(0, 5).map((category) => (
            <div
              key={category} 
              className="category-item"
              onClick={() => handleCategoryClick(category)}
              style={{ cursor: 'pointer' }}
            >
              {category}
            </div>
          ))}

          <DownArrowSVG 
            className="down-arrow-icon" 
            onClick={scrollToList}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      
      {blog && (
        <div 
          className="highlight-blog"
          onClick={() => viewBlog(blog)}
        >
          <Row gutter={60}>
            <Col span={14}>
              <div>
                <Image 
                  src={blog.img} 
                  className="highlight-blog-image"
                  preview={false}
                />
              </div>
            </Col>
            <Col 
              span={10}
              className="highlight-blog-content"
            >
              <div>
                <Paragraph className="highlight-blog-title">
                  {blog.title}
                </Paragraph>
                <Paragraph className="highlight-blog-description">
                  {blog.description}
                </Paragraph>
                <Paragraph className="highlight-blog-catgory">
                  {blog.categories?.join(' \u00A0|\u00A0 ')}
                </Paragraph>
                <Paragraph className="highlight-blog-published">
                  {formatDate(blog.publishedAt)}
                </Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <div className="research-border" />

      <div className="research-list"> 
        <div className="research-list-header"> 
          <Paragraph className="research-title">
            {width <= 768 ? 'Research' : 'All Articles'}
          </Paragraph>

          <div className="research-filter" ref={tabsContainerRef}>
            <Text className="research-filter-label">
              [FILTER]
            </Text>

            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              className="research-filter-tabs"
              items={[
                {
                  key: 'all',
                  label: 'All',
                },
                ...sortedCategories.slice(0, visibleCategories).map(category => ({
                  key: category,
                  label: category,
                }))
              ]}
            />
          </div>
        </div>

        <BlogList blogs={filteredBlogs} />
      </div>

      <SubscribeButton />
    </div>
  )
}