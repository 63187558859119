import { Link } from 'react-router-dom';
import { Button, Typography, Input, Row, Col, message } from 'antd';
import { MessageOutlined,TwitterOutlined, LinkedinOutlined, InstagramOutlined } from '@ant-design/icons';
import logo from '../../assets/svg/logo.svg';
import classNames from 'classnames';
import './footerv2.scss';
import { useContext, useState } from 'react'
import PosthogHelper from '../../helpers/posthog.helper';
import { UserContext } from '../../contexts/user.context';

const { Text, Paragraph } = Typography;

export const FooterV2 = () => {
  const { token, currentUser } = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true)

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
    setIsValidEmail(true) // Reset validation on change
  }

  const handleSubmit = () => {
    if (!email) {
      setIsValidEmail(false)
      message.error('Please enter an email address')
      return
    }
    
    if (!validateEmail(email)) {
      setIsValidEmail(false)
      message.error('Please enter a valid email address')
      return
    }
    PosthogHelper.track({ email }, '[footer] message team', false)
    window.FrontChat("identity", { email });
    window.FrontChat("show");
    setEmail('')
  }

  const onChat = () => {
    window.FrontChat("show");
  };
  
  return (
    <footer className={classNames("footer-section", { "has-messaging": !token })}>
      {!token && <>
        <div className="messaging-container">
          <Paragraph className="messaging-h1">
            Have a question?
          </Paragraph>
          <Paragraph className="messaging-p1">
            We'd love to chat! Send us a message and one of the founders of Instalab will respond.
          </Paragraph>

          <div 
            className={classNames("footer-message-form", { "error": !isValidEmail })}
          >
            <Row gutter={16} align="bottom">
              <Col>
                <Text className="message-form-label">Email Address</Text>
                <Input  
                  className="message-form-email"
                  placeholder="Enter your email address..."
                  value={email}
                  onChange={handleEmailChange}
                  onPressEnter={handleSubmit}
                />
              </Col>
              <Col flex="auto">
                <Button 
                  type="primary"
                  className="message-btn"
                  icon={<MessageOutlined />}
                  onClick={handleSubmit}
                >
                  Message
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="messaging-border" />
      </>}

      <div className="max-width">
        <div className="footer-content">
          <div className="footer-column company-info">

          <div className="logo-container">
            <img src={logo} alt="logo" className="logo" /> 
            <Typography.Text className="logo-name">Instalab</Typography.Text>
          </div> 
            
            <p>Making proactive health easy.</p>
            <div className="social-links">
              <a href="https://twitter.com/getinstalab" target="_blank" rel="noopener noreferrer">
                <TwitterOutlined />
              </a>
              <a href="https://www.linkedin.com/company/instalab-inc" target="_blank" rel="noopener noreferrer">
                <LinkedinOutlined />
              </a>
              <a href="https://instagram.com/getinstalab" target="_blank" rel="noopener noreferrer">
                <InstagramOutlined />
              </a>
            </div>
          </div>


          <div className="footer-column">
            <h4>Products</h4>
            <ul>
              <li><Link to="/concierge">Concierge</Link></li>
              <li><Link to="/catalog">Diagnostic Tests</Link></li>
              <li><Link to="/heart">Heart Health Treatment</Link></li>
              <li><Link to="/flow/gift">Gift Instalab</Link></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li><Link to="/about">About Us</Link></li>                 
              <li><Link to="/research">Research</Link></li>
              <li><Link to="/terms">Terms</Link> & <Link to="/privacy">Privacy</Link></li>
            </ul>
          </div>


          <div className="footer-column">
            <h4>Contact</h4>
            <ul>
              <li>Email: <a href="mailto:hello@instalab.com">hello@instalab.com</a></li>
              <li>Web Chat: 
                <Button 
                  type="link" 
                  onClick={onChat} 
                  className="web-chat-link"
                >
                  Start Chat
                </Button>
              </li>
              <li>Text/SMS: <a href="sms:+19175409829">(917) 540-9829</a></li>
            </ul>
          </div>
        </div>

      </div>
    </footer>
  )
};

