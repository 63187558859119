
import { useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";
import { CheckOutlined } from "@ant-design/icons"

export const Biobeat = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    const response = await getProductTypeByCode(ProductTypeCode.BIOBEAT)
    setProductType(response)
  };

  // set product attributes
  useEffect(() => {
    if (!productType) return
    const faqs = [
      {
        question: "What vital signs does the Biobeat measure?",
        answer: "The Biobeat is a wearable device designed to provide continuous, cuffless monitoring of vital signs, including blood pressure, heart rate, respiratory rate, and oxygen saturation. It is intended for both clinical and home use, offering convenient and reliable health monitoring."
      },
      {
        question: "Why is the Biobeat better than regular arm cuff monitors?",
        answer: "The Biobeat offers continuous, cuffless monitoring, providing a more comprehensive view of your blood pressure and vital signs throughout the day. Unlike traditional arm cuffs, it reduces the chance of whitecoat hypertension and offers a more comfortable and convenient experience, seamlessly integrating into your daily routine without interrupting activities."
      },
      {
        question: "How does the Biobeat work?",
        answer: "The Biobeat uses photoplethysmography (PPG) and other advanced sensor technologies to measure vital signs directly from the skin. These measurements are analyzed using proprietary algorithms to deliver accurate and continuous monitoring of various health parameters."
      },
      {
        question: "Who can benefit from a Biobeat?",
        answer: "The Biobeat is ideal for individuals who may believe they experience unusually high readings with traditional arm cuffs or have whitecoat hypertension, a condition where blood pressure readings are elevated in clinical settings. It also benefits those requiring continuous monitoring for chronic health conditions."
      },
      {
        question: "How accurate is the Biobeat?",
        answer: "The Biobeat has been validated in clinical settings and provides accurate measurements comparable to traditional monitoring methods. It meets high standards for accuracy and reliability."
      },
      {
        question: "How long does it take to get my results?",
        answer: "It typically takes 1 business day for a physician to review your results and send you a detailed report and recommendations. You'll receive a notification once your report is ready."
      },
      {
        question: "Where is this available?",
        answer: "The Biobeat is available for purchase and use anywhere in the United States. Shipping is free and available nationwide."
      },
      {
        question: "How do I wear the Biobeat?",
        answer: "The Biobeat is designed to be worn on the chest, using a comfortable, medical-grade adhesive. Follow the user manual instructions to ensure proper positioning for accurate measurements."
      },
      {
        question: "Is the Biobeat easy to set up?",
        answer: "Yes, the Biobeat is designed for easy setup and use. Follow the instructions in the user manual to activate the device and connect it to the accompanying mobile app or monitoring platform."
      },
      {
        question: "Can I talk to an actual physician to review my results with me?",
        answer: "Yes. Once you receive your results, you can schedule a video consultation with a physician who will review your report and address any questions you may have."
      },
      {
        question: "Does Instalab assist with follow-up needs?",
        answer: "Yes. Instalab assists members with any additional testing and provides referrals to heart health specialists as needed."
      },
      {
        question: "Does my health insurance cover this?",
        answer: "For price transparency, Instalab does not accept insurance. However, you may be eligible for reimbursement if you have an HSA/FSA. We recommend using your HSA/FSA card for purchases. An itemized receipt can be generated for reimbursement purposes if needed."
      }
    ];

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType.title,
          memberPrice: ProductHelper.getMemberPrice(productType.cost),
          originalPrice: productType.cost,
          oneliner: "An FDA-cleared, single-use device that continuously tracks your blood pressure and others vital signs with precision.",
          cta: {
            text: "Buy Now",
            url: `/flow/${productType.flowType}`
          },
          hasKlarna: true,
          photos: [
              {
                original: "/img/biobeat.png", 
                thumbnail: "/img/biobeat.png"
              },

              {
                original: "/img/biobeat-ad.png",
                thumbnail:  "/img/biobeat-ad.png"
              },
              {
                original: "/img/hsa-fsa-eligible-hero.webp",
                thumbnail: "/img/hsa-fsa-eligible-hero.webp"
              }
          ],
          points: [
            <><CheckOutlined className="point-icon"/>Accurate detection of hypertension vs. single readings</>,
            <><CheckOutlined className="point-icon"/>Does not interfere with your daily routine</>,
            <><CheckOutlined className="point-icon"/>Comfortable wear and easy set-up</>,
            <><CheckOutlined className="point-icon"/>HSA / FSA cards accepted</>,
          ],
          faqs: faqs
          
        },
      },

      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
                title: "Order the Biobeat",
                description: "After you purchase, you'll receive your Biobeat Chest Monitor in the mail within one week. Shipping is free.",
                image: "/img/biobeat-kit.png"
            },
            {
                title: "Wear Biobeat for 24 hours",
                description: "The Biobeat Chest Monitor can be easily hidden under your clothes. You can go about your day as usual.",
                image: "/img/family.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 1 business day. A report and game plan will be available as well.",
                image: "/img/view-on-phone.png"
            },

        ],
      },

      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(4),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_HEART_HEALTH_TEST}
      />

    </div>
  )

}
