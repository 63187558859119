const BlogId = Object.freeze({
  OVEREXERCISE: 'overexercise',
  FORECAST: 'forecast',
  SOFT_PLAQUE: 'soft-plaque',
  INSURANCE: 'insurance',
  CAC: 'cac',
  CUMULATIVE_EXPOSURE: 'cumulative-exposure',
  GALLERI: 'galleri',
  PEAK: 'peak',
  SAUNA: 'sauna',
  FOUNDER: 'founder',
  PCSK9I: 'pcsk9i',
  APOB: 'apob',
  SUPPLEMENT: 'supplement',
  LPA: 'lpa',
  BLOOD_PRESSURE_DIET: 'blood-pressure-diet',
  PLAQUE_REVERSAL: 'plaque-reversal',
  STATIN: 'statin',
  TESTOSTERONE_AGE: 'testosterone-age',
  CALCULATOR: 'calculator',
  DEXA: 'dexa', 
  BMI:'bmi',
  SEED_OIL: 'seed-oil',
  SUGAR: 'sugar',
  VAPING: 'vaping',
  SLEEP: 'sleep',
  NAP: 'nap',
  SLEEP_TRAINING: 'sleep-training',
  EFFICIENT_EXERCISE: 'efficient-exercise',
  EXERCISE_CONSEQUENCES: 'exercise-consequences',
  EXERCISE_RECOVERY: 'exercise-recovery',
  STEROL_TEST: 'sterol-test',
  EVALUATE_MEDICATION: 'evaluate-medication',
  OZEMPIC: 'ozempic',
  WEIGHT_LOSS: 'weight-loss',
  IF: 'if',
})

export default BlogId
