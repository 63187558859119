import { Typography } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography

export const EfficientExercisePost = () => {
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "exercise-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          1. High-Intensity Interval Training (HIIT): The Best Bang for Your Buck
        </Paragraph>
        <Paragraph className="blog-description">
          If you only have 15-20 minutes, high-intensity interval training (HIIT) is your best option. HIIT alternates between short bursts of intense effort and brief recovery periods, creating a metabolic demand that extends beyond the workout itself. It's been shown to boost cardiovascular health, improve insulin sensitivity, and enhance mitochondrial function—key factors in aging well.
        </Paragraph>

        <Paragraph className="blog-title">
          Why It Matters
        </Paragraph>
        <Paragraph className="blog-description">
          During high-intensity phases, your body taps into anaerobic metabolism, quickly burning stored energy and producing lactate. This process increases your lactate threshold, meaning your body becomes more efficient at clearing it, allowing you to sustain higher intensities with less fatigue. When you recover between intervals, your body shifts back to aerobic metabolism, improving heart and lung efficiency.
        </Paragraph>

        <Paragraph className="blog-title">
          How to Do It Right
        </Paragraph>
        <Paragraph className="blog-description">
          Total Time: 15-20 minutes, 3-4 times per week
        </Paragraph>
        <Paragraph className="blog-description">
          Example Routine:
        </Paragraph>
        <ol className="blog-list">
          <li>30 seconds sprint</li>
          <li>30-45 seconds rest (slow pace)</li>
          <li>Repeat for 8-12 rounds</li>
        </ol>

        <Paragraph className="blog-title">
          2. Strength Training: Invest in Muscle
        </Paragraph>
        <Paragraph className="blog-description">
          Strength training isn't just about aesthetics—it's about maintaining function, metabolic health, and long-term resilience. As we age, muscle mass naturally declines, slowing metabolism and increasing the risk of chronic disease. But resistance training can reverse this process, keeping both your body and metabolism strong.
        </Paragraph>

        <Paragraph className="blog-title">
          Why It Matters
        </Paragraph>
        <Paragraph className="blog-description">
          Muscle is metabolically active, meaning it burns more calories at rest and acts as a glucose sink, improving insulin sensitivity. Strength training also stimulates the release of myokines, signaling molecules that help fight inflammation, enhance cardiovascular function, and support brain health.
        </Paragraph>
        <Paragraph className="blog-description">
          Additionally, lifting weights places controlled stress on bones, stimulating osteoblast activity, which strengthens bone density. Without strength training, muscle and bone deterioration accelerate, leading to frailty, loss of mobility, and increased fall risk in aging populations.
        </Paragraph>

        <Paragraph className="blog-title">
          How to Get Stronger Without Living in the Gym
        </Paragraph>
        <Paragraph className="blog-description">
          Total Time: 20-30 minutes, 2-3 times per week
        </Paragraph>
        <Paragraph className="blog-description">
          Key Compound Movements:
        </Paragraph>
        <ol className="blog-list">
          <li><b>Squats</b> – Works large muscle groups, boosting metabolism and joint health.</li>
          <li><b>Push-ups</b>– Improves functional pushing strength.</li>
          <li><b>Deadlifts</b> – One of the best full-body exercises for strength and stability.</li>
          <li><b>Pull-ups or Rows</b> – Supports back and shoulder health.</li>
          <li><b>Farmer's Carry</b> – Simple yet powerful for grip strength and core stability.</li>
        </ol>

        <Paragraph className="blog-title">
          3. Zone 2 Cardio: The Underrated Secret to Longevity
        </Paragraph>
        <Paragraph className="blog-description">
          Not all cardio has to be high-intensity to be effective. In fact, Zone 2 cardio (low-intensity, steady-state exercise at around 60-70% of your max heart rate) is one of the most powerful yet underappreciated tools for metabolic and cardiovascular health.
        </Paragraph>

        <Paragraph className="blog-title">
          Why It Matters
        </Paragraph>
        <Paragraph className="blog-description">
          Zone 2 training optimizes mitochondrial function, the process that allows your body to efficiently convert fuel into usable energy. Over time, this leads to improved endurance, reduced oxidative stress, and better fat oxidation, allowing you to use stored fat as a primary energy source rather than relying on glycogen.
        </Paragraph>
        <Paragraph className="blog-description">
          Skipping this type of training can lead to poor metabolic flexibility, meaning your body struggles to switch between fuel sources efficiently. The result? Fatigue, sluggish metabolism, and increased susceptibility to metabolic disorders.
        </Paragraph>
        <Paragraph className="blog-description">
          A study in Circulation found that engaging in <b>150 minutes</b> of moderate-intensity exercise per week significantly lowers the risk of heart disease and all-cause mortality. We recommend choosing an option you enjoy, whether that's cycling, swimming, or jogging.
        </Paragraph>

        <Paragraph className="blog-title">
          Final Thoughts
        </Paragraph>
        <Paragraph className="blog-description">
          At Instalab, we understand that not everyone has hours to dedicate to the gym—but that doesn't mean your health has to take a back seat. By focusing on efficient, high-impact workouts, you can maximize your results in minimal time. Make every minute count, and your future self will thank you.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Lee, D., Pate, R., Lavie, C., Sui, X., Church, T., & Blair, S. (2014). Leisure-time running reduces all-cause and cardiovascular mortality risk.. Journal of the American College of Cardiology, 64 5, 472-81. <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2014.04.058', '_blank')}>https://doi.org/10.1016/j.jacc.2014.04.058</a>
            </li>
            <li>
              Momma, H., Kawakami, R., Honda, T., & Sawada, S. (2022). Muscle-strengthening activities are associated with lower risk and mortality in major non-communicable diseases: a systematic review and meta-analysis of cohort studies. British Journal of Sports Medicine, 56, 755 - 763. <a onClick={() => window.open('https://doi.org/10.1136/bjsports-2021-105061', '_blank')}>https://doi.org/10.1136/bjsports-2021-105061</a>
            </li>
            <li>
              Weston, K., Wisløff, U., & Coombes, J. (2013). High-intensity interval training in patients with lifestyle-induced cardiometabolic disease: a systematic review and meta-analysis. British Journal of Sports Medicine, 48, 1227 - 1234. <a onClick={() => window.open('https://doi.org/10.1136/bjsports-2013-092576', '_blank')}>https://doi.org/10.1136/bjsports-2013-092576</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};