import { useContext, useEffect, useState } from "react";
import Color from "../../colors.scss";
import { RollbackOutlined, DeleteOutlined, BankOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { PageHeader } from "../pageHeader/pageHeader.component";
import "./billing.scss";
import { listCharges, removeCharge } from "../../services/charge.service";
import { Typography, Button, Modal, message, Card } from "antd";
import moment from "moment";
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import Breakpoint from '../../enums/breakpoint.enum'
import classNames from "classnames";
import ChargeStatus from "../../enums/chargeStatus.enum";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Filler,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { RefundModal } from "../refundModal/refundModal.component";
import useWidth from "../../hooks/useWidth.hook";
import Role from "../../enums/role.enum";
import UrlHelper from "../../helpers/url.helper";
import { FlexibleTable } from "../flexibleTable/flexibleTable.component";
import { UserContext } from "../../contexts/user.context.js";
import { useNavigate } from "react-router-dom";
import { cardLogos } from '../../utils/cardLogos'
import PaymentMethods from "../paymentMethods/paymentMethods.component";
import AddFundsModal from '../addFundsModal/addFundsModal.component';
import { getMe } from "../../services/user.service";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ChartTooltip,
  Filler,
  Legend
);

const { Text } = Typography

const select = '_id stripeCreatedAt createdAt amount status'
const populate = [{
  path: 'patient',
  select: '_id firstName lastName'
}, {
  path: 'items',
  select: 'price',
  populate: {
    path: 'productType',
    select: 'title'
  }
}, {
  path: 'products',
  select: 'productType',
  populate: {
    path: 'productType',
    select: 'title'
  }
}, {
  path: 'membership',
  select: 'membershipType',
  populate: {
    path: 'membershipType',
    select: 'title'
  }
}, {
  path: 'appointment',
  select: 'patients'
}, {
  path: 'card',
  select: 'brand last4 expMonth expYear walletType'
}, {
  path: 'refunds',
  select: 'amount'
}]

export const Billing = () => {
  const navigate = useNavigate()
  const [charges, setCharges] = useState([])
  const { currentUser, setCounts } = useContext(UserContext)
  const width = useWidth()
  const [isLoading, setIsLoading] = useState(true)
  const [chartOptions, setChartOptions] = useState();
  const [chartData, setChartData] = useState();
  const [chargeId, setChargeId] = useState()
  const [openRefund, setOpenRefund] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [purchaseTypes, setPurchaseTypes] = useState([])
  const [filteredCharges, setFilteredCharges] = useState([])
  const [addFundsModalOpen, setAddFundsModalOpen] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    document.title = 'Instalab | Billing'
    fetchCharges()
  }, [])

  useEffect(() => {

    const fetchCashBalance = async () => {
      const response = await getMe({
        select: 'cashBalance',
      });
      setBalance(response.cashBalance || 0);
    }

    fetchCashBalance()
  }, []);



  useEffect(() => {
    if (currentUser?.role === Role.ADMIN) {
      setChartOptions(getChartOptions());
      setChartData(getChartData());
    }
  }, [filteredCharges, currentUser]);



  const fetchCharges = async () => {
    setIsLoading(true)
    let params = {
      select,
      filter: {amount: {$gt: 0}},
      populate,
    }

    const fetchedCharges = await listCharges(params, {
      select,
      populate
    })
    setPurchaseTypes([...new Set(fetchedCharges.reduce((acc, charge) => [...acc, ...getPurchaseItems(charge)], []))].sort())
    setCharges(sortCharges(fetchedCharges))
    setFilteredCharges(fetchedCharges)
    setFilteredCount(fetchedCharges.length)
    setIsLoading(false)
  }

  const tooltipLabel = (context) => {
    const { dataIndex } = context;
    const value = getTotalByMonth(getChartMonths()[dataIndex])
    return `$${value.toLocaleString(undefined, {minimumFractionDigits: 2})}`;
  };

  const getChartOptions = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: tooltipLabel,
          },
        },
      },
      scales: {
        y: {
          min: 0,
          border: {
            dash: [2, 4],
          },
          afterTickToLabelConversion: function (scaleInstance) {
            scaleInstance.ticks[scaleInstance.ticks.length - 1].label = "";
          },
        },
        x: {
          grid: {
            color: "rgba(0,0,0,0)",
          },
          border: {
            color: Color.border,
          },
          ticks: {
            color: Color.secondary_text,
            font: {
              size: 13,
              family: "Source Sans 3",
              lineHeight: 5,
            },
          },
          offset: true,
        },
      },
    };
  };

  const getChartMonths = () => {
    const firstCharge = sortCharges(filteredCharges)[filteredCharges.length - 1]
    let month = moment(firstCharge.stripeCreatedAt || firstCharge.createdAt).startOf('month')
    let months = []
    while (month.toDate().getTime() < moment().toDate().getTime()) {
      months.push(month)
      month = moment(month).add(1, 'month')
    }
    return months
  }

  const getTotalByMonth = month => {
    const monthStart = month.toDate().getTime()
    const monthEnd = moment(month).endOf('month').toDate().getTime()
    return filteredCharges.reduce((acc, charge) => {
      let { stripeCreatedAt, amount, refunds } = charge
      const chargeDate = new Date(stripeCreatedAt).getTime()
      
      if (charge.status === ChargeStatus.PAID && chargeDate >= monthStart && chargeDate < monthEnd) {
        if (refunds?.length) {
          amount -= refunds.reduce((refundTotal, refund) => {
            refundTotal += refund.amount
            return refundTotal
          }, 0)
        }
        acc += amount
      }
      return acc
    }, 0)
  }

  const getChartData = () => {
    if (!filteredCharges?.length) return
    const months = getChartMonths()
    const labels = months.map(month => month.format(`MMM 'YY`))
    const data = months.map(month => getTotalByMonth(month))
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: Color.success,
          segment: {
            borderColor: (ctx) => Color.success
          },
        },
      ],
    };
  };

  const onRefundSuccess = (charge) => {
    setCharges(cachedCharges => {
      return sortCharges(cachedCharges.map(c => c._id === charge._id ? charge : c).sort((a, b) => new Date(b.stripeCreatedAt) - new Date(a.stripeCreatedAt)))
    })
  }

  const onAddFunds = async (amount) => {
    fetchCharges()
    setBalance(balance + amount)
  }


  const getCustomFilter = (charge, value) => {
    return {
      patient: () => charge.patient?.firstName && `${charge.patient.firstName.toLowerCase()} ${charge.patient.lastName.toLowerCase()}`.includes(value.toLowerCase()),
      purchase: () => getPurchase(charge)?.includes(value)
    }
  }

  const getPurchaseItems = charge => {
    const { items, products, membership } = charge
    if (items?.length) {
      return items.map(({ productType }) => productType.title)
    } else if (products?.length) {
      return products.map(({ productType }) => productType.title)
    } else if (membership) {
      return [membership.membershipType.title]
    }
    return []
  }

  const getPurchase = charge => {
    const purchaseItems = getPurchaseItems(charge)
    if (purchaseItems?.length) {
      return purchaseItems.join(', ')
    } else if (charge.appointment) {
      return `Blood Draw Invites: x${charge.appointment.patients.length - 1}`
    }
    return 'Deleted Purchase'
  }

  const sortCharges = unsortedCharges => {
    return unsortedCharges.sort((a,b) => {
      const aDate = a.stripeCreatedAt || a.createdAt
      const bDate = b.stripeCreatedAt || b.createdAt
      return new Date(bDate) - new Date(aDate)
    })
  }

  const getActionItems = charge => {
    let menuItems = []

    const { _id, status } = charge
    
    switch (status) {
      case ChargeStatus.PAID:
        menuItems.push({
          key: 'refund',
          label: (
            <Button onClick={() => {
              setChargeId(_id);
              setOpenRefund(true);
            }}>
              <RollbackOutlined /> Refund
            </Button>
          )
        })
        break
    }

    if (menuItems.length) {
      menuItems.push({
        type: 'divider'
      })
    }

    menuItems.push({
      key: 'remove',
      label: (
        <Button
          onClick={() => onRemove(_id)}
          className="remove-item"
        >
          <DeleteOutlined /> Remove
        </Button>
      )
    })

    return menuItems
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this charge?',
      content: 'This cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeCharge(_id);
          setCharges(charges.filter(charge => charge._id !== _id));
          message.info('Charge removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              charges: cachedCounts.charges - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove charge');
        }
      }
    });
  }
  
  const rowClassName = charge => {
    if (charge.status === ChargeStatus.PENDING) {
      return `pending-row`
    }
  }

  return (

    <div className="billing">

      {(currentUser?.role === Role.PATIENT || currentUser?.role === Role.PROVIDER) && (
        <>
          <PageHeader title='Payment Methods' />
          
          <div className="cash-container">
            <Card className="saved-card">
              <div className="card-content">
              <div className="card-info">
                <div className="card-brand">
                  <BankOutlined className="card-logo" />
                </div>
                <div className="card-details">
                  <Text strong className="card-title">Instalab Cash</Text>
                  <Text type="secondary" className="card-subtitle">${balance.toFixed(2)} available</Text>
                </div>
              </div>
              <div className="card-actions">
                <Button 
                  type="default"
                  icon={<PlusCircleOutlined />}
                  onClick={() => setAddFundsModalOpen(true)}
                >
                  Add Funds
                </Button>
              </div>
              </div>
            </Card>
          </div>

          <PaymentMethods />

          <AddFundsModal 
            open={addFundsModalOpen}
            setOpen={setAddFundsModalOpen}
            onSuccess={onAddFunds}
          />
        </>
      )}


      <PageHeader
        title='Payment History'
        count={filteredCount}
      />


      {currentUser?.role === Role.ADMIN && <>
        <RefundModal
          chargeId={chargeId}
          setChargeId={setChargeId}
          open={openRefund}
          setOpen={setOpenRefund}
          onSuccess={onRefundSuccess}
          select={select}
          populate={populate}
        />
      </>}

      {currentUser?.role === Role.ADMIN && chartData && chartOptions && (
        <Bar
          options={chartOptions}
          data={chartData}
          className="billing-chart"
          height={100}
        />
      )}


      <FlexibleTable
        isLoading={isLoading}
        records={charges}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        onFilterSuccess={setFilteredCharges}
        rowClassName={rowClassName}
        getActionItems={currentUser?.role === Role.ADMIN ? getActionItems : null}
        columns={[width > Breakpoint.SM && {
          title: 'Date',
          dataIndex: 'stripeCreatedAt',
          width: 100,
          render: (stripeCreatedAt, { createdAt }) => {
            return (
              <Text className="charge-created">
                {moment(stripeCreatedAt || createdAt).format('MM/DD/YYYY')}
              </Text>
            )
          }
        }, (currentUser?.role === Role.PROVIDER || currentUser?.role === Role.ADMIN) && {
          title: 'Patient',
          dataIndex: 'patient',
          filterDropdownType: FilterDropdownType.INPUT,
          render: patient => {
            if (!patient) return 'Deleted Patient'
            else if (patient?.firstName) {
              return <a className="patient-link" onClick={() => window.open(UrlHelper.getPatientProfile(patient._id), '_blank')}>{patient.firstName} {patient.lastName}</a>
            }

          }
        }, {
          title: 'Purchase',
          dataIndex: 'purchase',
          render: (_, charge) => getPurchase(charge),
          filterOptions: purchaseTypes,
          filterDropdownType: FilterDropdownType.CHECKBOX,
        }, 
                
        width > Breakpoint.SM && {
          title: 'Card',
          dataIndex: 'card',
          width: 125,
          render: card => {
            if (card?.brand) {
              return (
                <div className="card-history">
                  {(currentUser?.role === Role.ADMIN) && card?.walletType && cardLogos[card.walletType]}
                  {cardLogos[card.brand.toLowerCase().replace(/ /g, '_')] || card.brand}
                  <Text className="card-digits">
                    {card.last4}
                  </Text> 
                </div>
              )
            }
            return null
          }
        }, 
        {
          title: 'Amount',
          dataIndex: 'amount',
          render: amount => `$${amount.toLocaleString(undefined, {minimumFractionDigits: 2})}`
        }, {
          title: 'Status',
          dataIndex: 'status',
          width: 130,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(ChargeStatus),
          width: 100,
          render: (status, { refunds, _id }) => {
            if (currentUser?.role === Role.PATIENT && status === ChargeStatus.PENDING) {
              return (
                <a
                  onClick={() => navigate(`/payment/${_id}`)}
                  className="pay-link"
                >
                  Pay Now
                </a>
              )
            } else if (refunds?.length > 0 && status !== ChargeStatus.REFUNDED) {
              return (
                <Text className={classNames('status-text', `Refunded-status-text`)}>
                  Refunded ${refunds.reduce((acc, { amount }) => amount + acc, 0).toFixed(2)}
                </Text>
              )
            } else {
              return (
                <Text className={classNames('status-text', `${status}-status-text`)}>
                  {status}
                </Text>
              )
            }
          }
        }]}
      />
    </div>
  )
}