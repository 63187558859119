import { Button, Col, Row } from "antd";
import "./selfReportBox.scss";
import { EditOutlined } from "@ant-design/icons";
export const SelfReportBox = ({cta, text, title, url}) => {

  return (
   
      <div className="self-report-box">
        <Row align="middle" gutter={12} className="product-row">
          <Col>
            <div className="product-content">
              <div className="product-title">
                {title}
              </div>
              <div className="product-description">
                {text}
              </div>
              <div className="product-buy">
                <Button type="primary"
                  onClick={() => {
                    window.open(url, "_blank");
                  }}
                  className="product-buy-btn"
                  icon={<EditOutlined />}
                >
                  {cta}
                </Button>
              </div>
            </div>
          </Col>

  
        </Row>
      </div>
    )

};
