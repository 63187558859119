import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, message, Select, Tag } from 'antd'
import { getResult, updateResult } from '../../services/result.service'
import { listTests } from '../../services/test.service'
import './includedTestsModal.scss';

const { Item } = Form

export const IncludedTestsModal = ({ open, setOpen, onSuccess, resultId, select, populate }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [tests, setTests] = useState([])

  useEffect(() => {
    if (open) {
      if (resultId) setFormFields()
    } else {
      form.resetFields()
    }
  }, [open])

  useEffect(() => {
    fetchTests()
  }, [])

  useEffect(() => {
    const keyDownHandler = event => {
      if (open && event.key === 'Enter') {
        event.preventDefault();
        onOk()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };  
  }, [open]);

  const fetchTests = async () => {
    const response = await listTests({
      select: '_id name'
    })
    setTests(response)
  }

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  const setFormFields = async () => {
    if (!resultId) return
    const result = await getResult(resultId, {
      populate: [{
        path: 'tests',
        select: '_id name'
      }]
    })

    if (result?.tests) {
      const testIds = result.tests.map(({ _id }) => _id)
      form.setFieldsValue({
        tests: testIds
      })
    }
  }
 
  const onOk = async () => {
    setIsSubmitting(true)

    try {
      const hasError = await hasFormError()
      if (hasError) {
        message.error('Enter valid data')
        setIsSubmitting(false)
        return 
      }

      const updatedResult = await updateResult(resultId, {
        fields: form.getFieldsValue(),
        select,
        populate,
      })

      if (onSuccess) {
        onSuccess(updatedResult)
      }

      message.info('Updated included tests')
      onCancel()
    } catch (err) {
      message.error('Failed to update included tests')
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={'Edit Included Tests'}
      onCancel={onCancel}
      width={'90%'}
      onOk={onOk}
      okText='Save'
      className="included-tests-modal"
      footer={null}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
      >
        <Item 
          name='tests'
          validateTrigger='onSubmit'
        >
          <Select
            mode="tags"
            maxTagCount={Infinity}
            // dropdownRender={() => null} // Disable the dropdown entirely
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            tagRender={(props) => {
              const { label, closable, onClose } = props;
              return (
                <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                  {label.length > 10 ? `${label.slice(0, 10)}...` : label}
                </Tag>
              );
            }}
            options={tests.map(test => {
              return {
                label: test.name,
                value: test._id
              }
            })}
          />
        </Item>

        <Button
          className="submit-btn"
          type='primary'
          onClick={onOk}
          loading={isSubmitting}
        >
          Save
        </Button>
      </Form>
    </Modal>
  )
}