import { useEffect, useState } from "react";
import { Row, Col, Typography, Tooltip } from 'antd';
import { listTests } from "../../../services/test.service";
import { TestCode } from "../../../enums/index.enum";
import "./biomarkerPanel.scss";

const { Paragraph } = Typography;

export const BiomarkerPanel = ({panel, lookup=true}) => {
  const [tests, setTests] = useState([]);

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    const response = await listTests()
    setTests(response)
  };

  const getTest = code => {
    return tests.find(t => t.code === code);
  };

  return tests?.length > 0 && (
    <div className="product-details-biomarker-panel">
      <div className="category-list">
        {Object.keys(panel).map((category, categoryIndex) => (
          <div key={`category-${categoryIndex}`} className="category-item">
            <div className="category-item-content">
              <Row>
                <Col xs={24} lg={5}>
                  <Paragraph className="category-title">
                    {category}
                  </Paragraph>
                </Col>
                <Col xs={24} lg={19}>
                  <Row>
                    {lookup ? 
                      panel[category].map((testCode, index, arr) => (
                    
                        <Tooltip title={getTest(testCode)?.tagline} placement="topLeft">
                          <span className="category-test">
                            {getTest(testCode)?.name
                              .replace(/\(Right\)/g, '')
                              .replace(/\(Systolic\)/g, '')
                              .replace(/\(Calculated\)/g, '')}
                            {testCode === TestCode.PSA_TOTAL && " (male only)"}
                            {index !== arr.length - 1 && ", "}
                          </span>
                        </Tooltip>                
                      ))
                  
                      : 
                      
                      panel[category].map((test, index, arr) => (          
                          <span className="category-test">
                            {test}
                            {index !== arr.length - 1 && ", "}
                          </span>
                      ))
                    }
    
                    {category==="Popular Add-ons"}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
