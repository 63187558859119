import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useEffect, useRef, useContext } from "react"
import HeartHealthV2Confirmed from "./custom/heartHealthV2Confirmed/heartHealthV2Confirmed.component"
import {  listMembershipTypes } from "../../services/membershipType.service"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import MembershipHelper from "../../helpers/membership.helper"
import { UserContext } from "../../contexts/user.context"
import ProductHelper from "../../helpers/product.helper"
import Gender from "../../enums/gender.enum"
import { listProductTypes } from "../../services/productType.service"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { addMembership } from "../../services/membership.service"
import { addTransfer } from "../../services/transfer.service"
import { getMe } from "../../services/user.service"
import MembershipStatus from "../../enums/membershipStatus.enum"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  SELECT_DURATION: 'select-duration',
  PAY: 'pay',
  NAME: 'name',
  GENDER: 'gender',
  DOB: 'dob',
  PHONE: 'phone',
  PREFERENCE: 'preference',
  CONFIRM: 'confirm',
}

export const HeartHealthV2Flow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const [productTypes, setProductTypes] = useState()
  const { instalabMembership, currentUser, setCurrentUser, setMemberships, setInstalabMembership } = useContext(UserContext)

  useEffect(() => {
    console.log('.')
    fetchMembershipTypes()
    fetchProductTypes()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, flow, hasPass, initialUser, instalabMembership, currentUser])


  const getProductType = (code) => {
    if (!code) return null
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchMembershipTypes = async () => {
    const membershipTypes = await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.HEART_HEALTH_V2
          ]
        }
      }
    })
    setMembershipTypes(membershipTypes)
  }

  const fetchProductTypes = async () => {
    console.log('fetching product types')
    const productTypes = await listProductTypes({
      filter: {
        code: { $in: [
          ProductTypeCode.HEART_HEALTH_PROGRAM,
          ProductTypeCode.HEART_HEALTH_PANEL,
          ProductTypeCode.CONSULT_HEART_HEALTH,
        ] }
      }
    })
    setProductTypes(productTypes)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser  || !membershipTypes|| !productTypes) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.HEART_HEALTH_V2}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'
      },

  
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: ProductTypeCode.HEART_HEALTH_PROGRAM,
        title: () => { 
          const totalCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.HEART_HEALTH_PROGRAM)], 
            instalabMembership, 
            currentUser
          }) 
          return <>Pay <span className="true-price">${totalCost}</span> for the <span className="product-name">{getProductType(ProductTypeCode.HEART_HEALTH_PROGRAM)?.title}</span></>
        },
        skipIf: () => {
          return MembershipHelper.getActiveHHMembership(currentUser?.memberships)
        },
        onSuccess: async () => {

          // create membership 
          const months = 4
          const response = await addMembership({
            fields: {
              type: MembershipTypeCode.HEART_HEALTH_V2,
              flowId: flow?._id,
              patient: currentUser?._id,
              startAt: new Date(),
              endAt: new Date(Date.now() + months * 30 * 24 * 60 * 60 * 1000),
              status: MembershipStatus.CANCELED
            }
          })

          console.log("membership response", response.membership)
     
          // give them 4 heart health panels 
          await addTransfer({
            user: currentUser?._id,
            productTypeCode: ProductTypeCode.HEART_HEALTH_PANEL,
            quantity: 4
          })

          const patientPopulate = [{
            path: "memberships",
            populate: [{
              path: "membershipType"
            }]
          }]
          const userResponse = await getMe({populate: patientPopulate})
          setCurrentUser(userResponse)
          setMemberships(prevMemberships => [...prevMemberships, response.membership])
          setInstalabMembership(prevInstalabMembership => MembershipHelper.getActiveInstalabMembership([
            ...(Array.isArray(prevInstalabMembership) ? prevInstalabMembership : [prevInstalabMembership]).filter(Boolean),
            response.membership
          ]))
          
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this to set up your account.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          required: true,
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      

      [Step.CONFIRM]: {
        type: StepType.CUSTOM,
        content: ({ footer }) => <HeartHealthV2Confirmed footer={footer} instalabMembership={flow?.membership} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }



  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}