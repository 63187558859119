import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { TruageCompleteApproval } from "./truageCompleteApproval.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"
import { TassoPopover } from "../tassoPopover/tassoPopover.component"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  ACCOUNT: 'account',
  DRAW: 'draw',
  PAY: 'pay',
  NAME: 'name',
  DOB: 'dob',
  GENDER: 'gender',
  PHONE: 'phone',
  DELIVERY: 'delivery',
  CONFIRM: 'confirm',
}

export const TruageCompleteFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipType, setMembershipType] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchSkeleton()
  }, [membershipType, productTypes, instalabMembership, flow, hasPass, initialUser])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  const fetchMembershipType = async () => {
    const response = await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY)
    setMembershipType(response)
  }  
  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.TRUAGE_COMPLETE,
            ProductTypeCode.FINGER_PRICK,
            ProductTypeCode.TASSO,
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DRAW,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.TRUAGE_COMPLETE}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.DRAW]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your blood collection option.`,
        buildFlow: true,
        nextStep: Step.PAY,
        field: (() => {

          const tasso = getProductType(ProductTypeCode.TASSO) 
          const finger = getProductType(ProductTypeCode.FINGER_PRICK)

          return {
            name: 'collectionPreference',
            required: true,
            options: [
              {
                title: "Tasso+ Kit",
                value: 'tasso',
                description: <>Use the virtually painless <TassoPopover trigger={<a className="heart-description-link">Tasso+</a>} /> to self-collect from the comfort of your home and mail back to the lab.</>,
                price: ProductHelper.getTotalCostV2({
                  productTypes: [tasso],
                  instalabMembership,
                  currentUser,
                  ignoreCredits: true
                }),
              },   
              {
                title: "Finger Prick",
                value: 'finger',
                description: "Use traditional finger prick to collect your blood at home. Not recommended if your finger tips are senstitive to pain.",
                price: ProductHelper.getTotalCostV2({
                  productTypes: [finger],
                  instalabMembership,
                  currentUser,
                  ignoreCredits: true
                }),
              },
            ] 
          }
        })(),
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.TRUAGE_COMPLETE].includes(product.productType.code)).length > 0
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: [ProductTypeCode.TRUAGE_COMPLETE, flow?.collectionPreference === 'tasso' ? ProductTypeCode.TASSO : ProductTypeCode.FINGER_PRICK],
        title: () => {
          const tasso = getProductType(ProductTypeCode.TASSO)
          const finger = getProductType(ProductTypeCode.FINGER_PRICK)
          const truageComplete = getProductType(ProductTypeCode.TRUAGE_COMPLETE)

          const productTypes = [truageComplete, flow?.collectionPreference === 'tasso' ? tasso : finger]

          const totalCost = ProductHelper.getTotalCostV2({
            productTypes: productTypes,
            instalabMembership,
            currentUser
          })

          return <>Pay <span className="true-price">${totalCost}</span> for the <span className="product-name">{truageComplete.title}</span>.</>
        },
        description: `This includes a TruAge Complete test kit${flow?.collectionPreference === 'tasso' ? ' with a Tasso device' : ''} and results and reports, including your biological age, rate of aging and analyses of many aging biomarkers.`,
       },
      
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        description: "We need this for your lab order for the test.",
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your biological sex?`,
        description: "We need this for your lab order for the test.",
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `What's your delivery address?`,
        description: `This is where your TruAge Complete test kit will be sent.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>All set!</>,
        description: `Your collection kit will be shipped to you within 1 business day. Here's the summary of the rest of the process.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <TruageCompleteApproval/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}