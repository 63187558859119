import { useRef, useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { SelfReportConfirm } from "./selfReportConfirm.component"
import { completeFlow } from "../../services/flow.service"
import FlowType  from "../../enums/flowType.enum"

export const Step = {
  ACCOUNT: 'account',
  BLOOD_PRESSURE: 'blood-pressure',
  CONFIRM: 'confirm',
}

export const SelfReportFlow = () => {
  const [flow, setFlow] = useState()
  const [initialUser, setInitialUser] = useState()
  const [hasPass, setHasPass] = useState()
  const [skeleton, setSkeleton] = useState()
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, initialUser])

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return 

    setSkeleton({

      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.BLOOD_PRESSURE,
        buildUser: true,
        title: <>First, let's create your Instalab acccount.</>,
        description: <>Already have an account? <a href={`/login?redirect=/flow/${FlowType.SELF_REPORT}`}>Login</a>.</>,
        fields: [
          {
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: "Create Account"
      },

      [Step.BLOOD_PRESSURE]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.CONFIRM,
        buildResult: true,
        title: `Enter your blood pressure reading.`,
        description: `Blood pressure consists of two numbers. The systolic pressure (top number) represents the pressure when your heart beats, while the diastolic pressure (bottom number) represents the pressure when your heart rests.`,
        fields: [
          {
            name: 'testDate',
            label: 'Date of Reading',
            placeholder: 'MM/DD/YYYY',
            date: true,
            inputMode: 'numeric',
            required: true,
          },

          {
            name: 'systolic',
            label: 'Systolic (mmHg)',
            inputMode: 'numeric',
            placeholder: 'Type your systolic blood pressure here...',
            required: true,
          },
          {
            name: 'diastolic',
            label: 'Diastolic (mmHg)',
            inputMode: 'numeric',
            placeholder: 'Type your diastolic blood pressure here...',
            required: true,
          }
        ],

      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Results Submitted`,
        description: `Thank you for submitting your blood pressure reading. They've been added to your Instalab results.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <SelfReportConfirm />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }


  return  (
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      startIndex={1}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}