import { Typography } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography

export const SleepPost = () => {
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "sleep-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          What's Actually Going On Physiologically
        </Paragraph>
        <Paragraph className="blog-description">
          When you consistently deprive yourself of sleep, your sympathetic nervous system kicks into overdrive, ramping up the production of cortisol (the stress hormone). This hormonal shift interferes with your ability to regulate blood sugar, leading to elevated glucose levels.
        </Paragraph>
        <Paragraph className="blog-description">
          But it doesn't stop there. Sleep deprivation triggers systemic inflammation by increasing levels of pro-inflammatory markers such as C-reactive protein (CRP) and interleukin-6. This prolonged state of inflammation, coupled with elevated blood sugar, significantly raises your risk of chronic conditions like type 2 diabetes and heart disease.
        </Paragraph>
        <Paragraph className="blog-description">
          Statistically, the evidence is alarming. A 14-year follow-up study found that short sleep durations (less than 6 hours per night) increased the risk of coronary heart disease (CHD) by <b>48%</b>. Similarly, consistently sleeping less than 6 hours per night raises the risk of developing type 2 diabetes by <b>28–48%</b> in both men and women.
        </Paragraph>

        <Paragraph className="blog-title">
          How Much Sleep Should You Get?
        </Paragraph>
        <Paragraph className="blog-description">
          For most adults, 7–8 hours of high-quality sleep per night is the sweet spot for optimal health, cognitive performance, and emotional well-being. Research consistently shows that adults who get 7–8 hours of sleep perform better in areas like decision-making, attention, and productivity. Sleeping less than this also carries serious long-term health risks by compromising your metabolic and cardiovascular systems.
        </Paragraph>
        <Paragraph className="blog-description">
          For a deeper look into your long-term health, keep an eye on markers like high-sensitivity CRP and fasting glucose or insulin levels, which can signal whether your body is under strain from chronic sleep deprivation. These markers provide a broader picture and may be influenced by factors beyond sleep, so they should be interpreted with care.
        </Paragraph>

        <Paragraph className="blog-title">
          High-Quality Sleep
        </Paragraph>
        <Paragraph className="blog-description">
          When we talk about getting 7–8 hours of sleep, we mean 7–8 hours of actual, restorative sleep, not just lying in bed. Here's how to optimize for high-quality sleep:
        </Paragraph>

        <ol className="blog-list">
          <li>Go to bed and wake up at the same time every day to regulate your circadian rhythm.</li>
          <li>Your bedroom should be dark, cool (60–67°F), and quiet.</li>
          <li>Avoid caffeine, nicotine, or heavy meals for several hours before bed.</li>
          <li>Limit screen time, as blue light from devices suppresses melatonin production.</li>
          <li>Relax before bed by reading, meditating, or light stretching.</li>
        </ol>

        <Paragraph className="blog-title">
          Finding Your Routine
        </Paragraph>
        <Paragraph className="blog-description">
          For many of us in "go mode," the idea of devoting more time to sleep might feel counterintuitive. However, building a routine that prioritizes sleep is one of the most powerful ways to sustain peak performance over the long haul. Sleep is not a luxury—it's a critical investment in your ability to think clearly, solve problems creatively, and protect your long-term health.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Kronholm, E., Sallinen, M., Suutama, T., Sulkava, R., Era, P., & Partonen, T. (2009). Self‐reported sleep duration and cognitive functioning in the general population. Journal of Sleep Research, 18. <a onClick={() => window.open('https://doi.org/10.1111/j.1365-2869.2009.00765.x', '_blank')}>https://doi.org/10.1111/j.1365-2869.2009.00765.x</a>
            </li>
            <li>
              Bandyopadhyay, A., & Sigua, N. (2019). What Is Sleep Deprivation?. American journal of respiratory and critical care medicine, 199 6, P11-P12. <a onClick={() => window.open('https://doi.org/10.1164/rccm.1996P11', '_blank')}>https://doi.org/10.1164/rccm.1996P11</a>
            </li>
            <li>
              Eguchi, K., Hoshide, S., Ishikawa, S., Shimada, K., & Kario, K. (2012). Short sleep duration and type 2 diabetes enhance the risk of cardiovascular events in hypertensive patients. Diabetes research and clinical practice, 98 3, 518-23. <a onClick={() => window.open('https://doi.org/10.1016/j.diabres.2012.09.014', '_blank')}>https://doi.org/10.1016/j.diabres.2012.09.014</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};