import { Typography }  from 'antd'
import { useEffect, useState } from 'react'
import './memberIntro.scss'
import Breakpoint from "../../../../enums/breakpoint.enum";
import PhotoHelper from '../../../../helpers/photo.helper';
import useWidth from '../../../../hooks/useWidth.hook';
import { MembershipPoints } from '../../../membershipPoints/membershipPoints.component';
import MembershipTypeCode from '../../../../enums/membershipTypeCode.enum';
import { getMembershipTypeByCode } from '../../../../services/membershipType.service';
import UserHelper from '../../../../helpers/user.helper';
const { Paragraph } = Typography

const MemberIntro = ({ footer, initialMembershipType, currentUser }) => {
  const width = useWidth()
  const [membershipType, setMembershipType] = useState()
  const [prefix, setPrefix] = useState('Starting at')
  const [title, setTitle] = useState('Join Instalab Membership')

  const onChat = () => {
    window.FrontChat("show");
  }

  useEffect(() => {
    if (initialMembershipType) {
      setMembershipType(initialMembershipType)
      setPrefix('')
      setTitle(`Join ${initialMembershipType?.title}`)
    }
    else {
      const fetchMembershipType = async () => {
        const type = await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY_12MO);
        // type.features[0] = {
        //   title: "Your Own Longevity Physician",
        //   description: `Ongoing consultations with a physician who specializes in preventive medicine and longevity.`
        // };
        setMembershipType(type);
      };
      
      fetchMembershipType();
    }


  }, [initialMembershipType]);


  return membershipType && (
    <div 
      className="member-intro flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh)' : 'calc(100vh)',
      }}
    >

      {PhotoHelper.format({
        jpg: "/img/green.jpg",
        webp: "/img/green.webp",
        className: "intro-bg",
      })}

      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          {title}
        </Paragraph>

        <Paragraph className="member-subtitle">
          Get a partner dedicated to your long-term health<br/>
        </Paragraph>


        {!UserHelper.hasCredit(currentUser, membershipType.code) && (
          <div className="member-pricing-container">
            <div className="member-pricing">
            {prefix} ${membershipType?.isAnnual ? Math.round(membershipType?.cost/12) : membershipType?.cost}/month. Cancel anytime.
            </div>
          </div>
        )}

        <Paragraph className="member-description" style={{marginBottom: 50}}>
          <MembershipPoints membershipType={membershipType}/>
        </Paragraph>

        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>

        {/* <div className="question-link-container">
          <a 
            onClick={onChat}
            className="question-link"
          >
            I have a question.
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default MemberIntro