import DISCOUNT from '../enums/discount.enum'
import MembershipStatus from '../enums/membershipStatus.enum'
import MembershipTypeCode from '../enums/membershipTypeCode.enum'
import { getMembershipTypeByCode } from '../services/membershipType.service'

const INSTALAB_MEMBERSHIP_HIEARCHY = {
  [MembershipTypeCode.ULTIMATE]: 0,
  [MembershipTypeCode.PREMIUM_12MO]: 1,
  [MembershipTypeCode.PREMIUM]: 2,
  [MembershipTypeCode.LONGEVITY_12MO]: 3,
  [MembershipTypeCode.LONGEVITY]: 4,
  [MembershipTypeCode.HEART_HEALTH]: 5,
  [MembershipTypeCode.HEART_HEALTH_V2]: 6,

  // demo memberships - for testing on production
  [MembershipTypeCode.DEMO_PREMIUM_12MO]: 7,
  [MembershipTypeCode.DEMO_PREMIUM]: 8,
  [MembershipTypeCode.DEMO_12MO]: 9,
  [MembershipTypeCode.DEMO]: 10,
};


const MembershipHelper = {
  getActiveInstalabMembership: (memberships) => {
    if (!memberships?.length) return null;
    // Filter active memberships and sort by hierarchy
    const activeMemberships = memberships
      .filter(membership => 
        MembershipHelper.isActive(membership) && 
        INSTALAB_MEMBERSHIP_HIEARCHY.hasOwnProperty(membership.membershipType.code) 
      ) 
      .sort((a, b) => {
        const aRank = INSTALAB_MEMBERSHIP_HIEARCHY[a.membershipType.code];
        const bRank = INSTALAB_MEMBERSHIP_HIEARCHY[b.membershipType.code];
        return aRank - bRank; // Sort by hierarchy (lower is better)
      });

    return activeMemberships.length ? activeMemberships[0] : null;
  },
  getAllActiveMemberships: (memberships) => {
    if (!memberships?.length) return null;
    const activeMemberships = memberships.filter(membership => MembershipHelper.isActive(membership));
    return activeMemberships;
  },
  isExpiring: (membership) => {
    if (!membership) return;
    return membership && membership.status !== MembershipStatus.ACTIVE && new Date(membership.endAt) > new Date();
  },
  existingMembership: (currentMembership, membershipType) => {
    if (!currentMembership || !membershipType) return false;
    return currentMembership?.membershipType?.code === membershipType?.code;
  },

  isDowngrade: (membershipType, activeMembership) => {
    if (!activeMembership || !membershipType) return null;
    
    // Get the hierarchy ranks
    const newMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[membershipType?.code];
    const activeMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[activeMembership?.membershipType?.code];
  
    // Compare the ranks to determine if it's an upgrade or downgrade
    if (newMembershipRank === undefined || activeMembershipRank === undefined) {
      return null; // Invalid hierarchy code
    }
  
    return newMembershipRank > activeMembershipRank; // true if downgrade, false if upgrade
  },
  isUpgrade: (membershipType,  activeMembership) => {
    if (!activeMembership || !membershipType) return null;
  
    // Get the hierarchy ranks
    const newMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[membershipType?.code];
    const activeMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[activeMembership?.membershipType?.code];
  
    // Compare the ranks to determine if it's an upgrade or downgrade
    if (newMembershipRank === undefined || activeMembershipRank === undefined) {
      return null; // Invalid hierarchy code
    }
  
    return newMembershipRank < activeMembershipRank; // true if downgrade, false if upgrade
  },
  // isDowngrade: (membershipType, memberships) => {
  //   if (!memberships?.length || !membershipType) return null;
  
  //   // Get the active membership
  //   const activeMembership = MembershipHelper.getActiveInstalabMembership(memberships);
  //   if (!activeMembership) return null;
  
  //   // Get the hierarchy ranks
  //   const newMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[membershipType.code];
  //   const activeMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[activeMembership.membershipType.code];
  
  //   // Compare the ranks to determine if it's an upgrade or downgrade
  //   if (newMembershipRank === undefined || activeMembershipRank === undefined) {
  //     return null; // Invalid hierarchy code
  //   }
  
  //   return newMembershipRank > activeMembershipRank; // true if downgrade, false if upgrade
  // },
  // isUpgrade: (membershipType, memberships) => {
  //   if (!memberships?.length || !membershipType) return null;
  
  //   // Get the active membership
  //   const activeMembership = MembershipHelper.getActiveInstalabMembership(memberships);
  //   if (!activeMembership) return null;
  
  //   // Get the hierarchy ranks
  //   const newMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[membershipType.code];
  //   const activeMembershipRank = INSTALAB_MEMBERSHIP_HIEARCHY[activeMembership.membershipType.code];
  
  //   // Compare the ranks to determine if it's an upgrade or downgrade
  //   if (newMembershipRank === undefined || activeMembershipRank === undefined) {
  //     return null; // Invalid hierarchy code
  //   }
  
  //   return newMembershipRank < activeMembershipRank; // true if downgrade, false if upgrade
  // },
  getCost: async (membershipTypeCode, longevityMembership, isProvider) => {
    const { cost } = await getMembershipTypeByCode(membershipTypeCode)
    if (longevityMembership || isProvider) {
      return Math.round(cost*DISCOUNT*100)/100
    }
    return cost
  },
  getActiveMembership: (memberships, code) => {
    if (!memberships?.length) return null
    const membership = memberships?.find(membership => membership.membershipType.code === code && MembershipHelper.isActive(membership))
    return membership || null
  },
  getActiveHHMembership: (memberships) => {
    if (!memberships?.length) return null
    const membership = memberships?.find(membership => 
      ( membership.membershipType.code === MembershipTypeCode.HEART_HEALTH && MembershipHelper.isActive(membership)) ||
      ( membership.membershipType.code === MembershipTypeCode.HEART_HEALTH_V2 && MembershipHelper.isActive(membership))
    )
    return membership || null
  },
  hasActiveHHV2Membership: (memberships) => {
    if (!memberships?.length) return null
    const membership = memberships?.find(membership => 
      ( membership.membershipType.code === MembershipTypeCode.HEART_HEALTH_V2 && MembershipHelper.isActive(membership))
    )
    return membership || null  },
  isActive: (membership) => {
    if (!membership) return false
    const { status, endAt } = membership
    return status === MembershipStatus.ACTIVE || status === MembershipStatus.OVERDUE || new Date(endAt) > new Date()
  },
  isOverdue: (membership) => {
    if (!membership) return false
    const { status } = membership
    return status === MembershipStatus.OVERDUE
  },
  isUltimate: (membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.ULTIMATE
  },
  isPremium: (membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.PREMIUM || membership?.membershipType?.code === MembershipTypeCode.PREMIUM_12MO
  },
  isCore: (membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.LONGEVITY || membership?.membershipType?.code === MembershipTypeCode.LONGEVITY_12MO
  },
  isLipids: (membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.LIPIDS;
  },
  isHeartHealth:(membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.HEART_HEALTH || membership?.membershipType?.code === MembershipTypeCode.HEART_HEALTH_V2
  },
  isDemo: (membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.DEMO || membership?.membershipType?.code === MembershipTypeCode.DEMO_12MO || membership?.membershipType?.code === MembershipTypeCode.DEMO_PREMIUM || membership?.membershipType?.code === MembershipTypeCode.DEMO_PREMIUM_12MO
  },
  formatCost: (membershipType, hasDollarSign=true) => {
    if (!membershipType?.cost) return ''
    let { cost } = membershipType
    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
  formatDiscountCost: (membershipType, hasDollarSign=true) => {
    if (!membershipType?.cost) return ''
    let cost = Math.round(membershipType.cost * DISCOUNT * 100)/100 

    // Memberships don't have discount
    if ([MembershipTypeCode.LONGEVITY, MembershipTypeCode.ULTIMATE, MembershipTypeCode.HEART_HEALTH].includes(membershipType.code)) {
      cost = membershipType.cost
    }

    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
  isAnnual: (membership) => {
    return membership?.membershipType?.isAnnual 
  },

 
}

export default MembershipHelper