import { useRef, useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";
import FlowType from "../../enums/flowType.enum";
export const HeartHealthTest = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    const response = await getProductTypeByCode(ProductTypeCode.HEART_HEALTH_PANEL)
    setProductType(response)
  };


  const heartHealthPanel = [
    {
      name: "Apolipoprotein B",
      description: "A protein found on all lipid particles that have the potential to contribute to atherosclerosis, making it one of the most powerful predictors of heart disease risk."
    },
    {
      name: "Lipoprotein(a)",
      description: "A variant of LDL cholesterol that includes an additional protein called apolipoprotein(a). High levels of Lp(a) are a genetic risk factor for heart diseases."
    },
    {
      name: "Hemoglobin A1c",
      description: "Your average blood sugar levels over the past 2 to 3 months. High levels are associated with insulin resistance / diabetes and increased risk of heart disease."
    },
    {
      name: "High-Sensitivity C-Reactive Protein",
      description: "A marker of inflammation in the body. Elevated levels indicate increased heart disease risk as inflammation contributes to atherosclerosis."
    },
    {
      name: "Direct LDL Cholesterol",
      description: "The amount of cholesterol in harmful, atherogenic particles called LDL (low-density lipoproteins). Higher levels increase your risk for heart attack and stroke.",
    },
    {
      name: "Alamine Aminotransferase",
      description: "An enzyme found in the liver. Elevated levels are associated with insulin resistance and non-alcoholic fatty liver disease."
    },
    {
      name: "HDL Cholesterol",
      description: "The amount of cholesterol in particles called HDL, which helps remove cholesterol from the bloodstream and artery walls. Higher levels are associated with a reduced risk of heart disease."
    },
    {
      name: "Triglycerides",
      description: "A type of fat found in the blood and are an important source of energy. But elevated levels can contribute to an increased risk of heart disease."
    },
    {
      name: "VLDL Cholesterol",
      description: "The amount of cholesterol in particles called VLDL, which also transports triglycerides from the liver to tissues. High level are asociated with increased risk of heart disease."
    },
    {
      name: "Total Cholesterol",
      description: "The amount of cholesterol in your blood. It's a lipid crucial for proper functioning of cells and carried throughout your body in particles called lipoproteins."
    },

  ]
  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
        question: "How do I get this test done?",
        answer: <>You can either collect your sample at home or visit a local lab. For at-home testing, you'll receive a blood collection kit featuring the Tasso+ device for easy, virtually painless self-collection. After collecting your sample, simply drop it in the mail. If you prefer a lab visit, we'll help you schedule an appointment at a convenient location.</>
      },
      {
        question: "What biomarkers are included?",
        answer: <>The Heart Health Test is carefully curated by leading cardiovascular experts, providing a comprehensive assessment of your heart health. It includes ApoB, Lp(a), hbA1c, hs-CRP, Direct LDL cholesterol, and more. <a onClick={scrollToPanel} className="cta-link">Learn More</a></>
      },
      {
        question: "How is the blood collection virtually painless?",
        answer: "The Tasso+ device offers a more comfortable and convenient alternative to traditional finger pricks for blood collection. Unlike finger pricks, which can cause sharp pain by puncturing deeper into the skin, the Tasso+ uses microneedles and a gentle suction mechanism to draw blood from the skin's surface with minimal penetration. This reduces the discomfort and potential bruising associated with finger pricks."
      },
      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 1 week to get results after your sample arrives at our lab. We'll send you an email when your results and recommendations are ready!</>
      },
      {
        question: "How do I test my heart health?",
        answer: <>You can choose between at-home testing or a lab visit. For at-home testing, we'll send you everything you need, including the painless Tasso+ collection device and clear instructions. For lab visits, we'll help you schedule an appointment at a convenient location near you.</>,
      },
      {
        question: `Where is this available?`,
        answer: `Anywhere in the United States.`
      },
      {
        question: `Do I need to fast before I collect my blood?`,
        answer: `Yes, please fast 8 hours before your collection.`,
      }, 
      {
        question: `Where is my blood being sent?`,
        answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
        question: `Can I talk to an actual human physician to review my results with me?`,
        answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a clinical lipidologist. They'll be ready to address any of your questions.`,
      }, 
      {
        question: `Does Instalab help with any followup needs?`,
        answer: `Yes. Instalab helps members with any additional testing and referrals to heart health specialists.`
      },
      {
        question: "How often should I get this panel done?",
        answer: `If you're making changes to your diet, lifestyle or anything else in your life, it's good to check every 3 months during this period to make sure those changes are helping you. If you have optimal levels already, it's good to check this once a year to make sure everything remains optimal and not trending in the wrong way.`,
      },             
      {
        question: `Does my health insurance cover this test?`,
        answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don't have one, we can generate an itemized receipt for you to submit.`,
      }, 

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType?.title,
          memberPrice: ProductHelper.getMemberPrice(productType?.cost),
          originalPrice: productType?.cost,
          oneliner: productType?.tagline,
          panelRef: panelRef,
          cta: {
            text: "Get Started Now",
            url: `/flow/${productType?.flowType}`
          },
          hasKlarna: true,
          giftCta: {
            text: "Send as a gift",
            url: `/flow/${FlowType.GIFT}/details?code=${ProductTypeCode.GIFT_HEART_HEALTH_PANEL}`
          },
          photos: [
              {
                original: "/img/heart-health-box.png", 
                thumbnail: "/img/heart-health-box.png"
              },

              {
                original: "/img/tasso.png",
                thumbnail:  "/img/tasso.png"
              },

              {
                original: "/img/fertility-tasso-grid.png",
                thumbnail:  "/img/fertility-tasso-grid.png"
              },
              {
                original: "/img/hsa-fsa-eligible-hero.webp",
                thumbnail: "/img/hsa-fsa-eligible-hero.webp"
              }
          ],

          faqs: faqs
        },
      },
      {
        type: ProductDetailSection.SMALLPANEL,
        title: "Tests Included",
        data: {
          panel: heartHealthPanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
              title: "Order test & choose collection method",
              description: "After purchase, select either a lab visit or at-home collection. For at-home testing, you'll receive the Tasso+ collection kit in the mail.",
              image: "/img/tasso-box.webp"
          },
            {
                title: "Complete collection",
                description: "Either collect your sample at home with the Tasso+ device, or visit one of our partner labs for a traditional blood draw.",
                image: "/img/tasso-grid.png"
            },

            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 2-3 business days. Your report will include detailed insights and next steps.",
                image: "/img/receive-results-woman.png"
            },
        ],
      },

      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(3),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_HEART_HEALTH_TEST}
      />

    </div>
  )

}
