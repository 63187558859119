import './confirmAppointment.scss'
import AppointmentHelper from '../../helpers/appointment.helper'
import InviteStatus from '../../enums/inviteStatus.enum'
import { useEffect, useState } from 'react'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import Panels from "../panels/panels.component.js"
import ProductTypeCode from "../../enums/productTypeCode.enum.js"
import { Button, Row, Col, Typography } from 'antd'
import Breakpoint from '../../enums/breakpoint.enum'
import useWidth from '../../hooks/useWidth.hook'
import { TassoPopover } from '../tassoPopover/tassoPopover.component'
const { Text, Paragraph } = Typography

const ConfirmAppointment = ({ flow }) => {
  const [inviteCount, setInviteCount] = useState()
  const width = useWidth()
  useEffect(() => {
    fetchInviteCount()
  }, [flow])


  const productTypeCodes = [
    ProductTypeCode.CTCALCIUM,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.WATCHPAT_ONE
  ]

  const tassoInstructions = [{
    sections: [
      {
      title: 'Receive Collection Kit',
      description: <>Our lab partner, Boston Heart Diagnostics, will ship your blood collection kit via FedEx. It should arrive within one week of purchase.</>
      },   
      ]},
     {
    sections: [
        {
          title: 'Activate Your Kit',
          description: <>Once you receive your kit, you'll need to activate it by following the instructions in the kit.</>
        }
      ]
     },

      {
        sections: [{
          title: 'Self Collect Blood + Mail Back',
          description: <>You'll use the painless <TassoPopover trigger={<a className="tasso-popover-link">Tasso+ device</a>}/> to collect your blood yourself. It should take just 5 minutes for the whole process.</>
        }]
      }, 
      {
        sections: [{
          title: 'Review Test Results',
          description: <>Once your sample reaches the lab, your test results will be ready in about one week. We'll email you as soon as they're available.</>
        }]
  }]
  
  const fetchInviteCount = () => {
    if (!flow?.appointment) return
    setInviteCount(flow.appointment.patients.length - 1 + flow.appointment.invites.filter(({ status }) => status === InviteStatus.PENDING).length)
  }

  return (  
    <div className="cholesterol-balance-confirm">
      {flow?.appointment ? (
        <div className="confirm-appointment">
          <div className="detail-list" style={{ marginBottom: 20 }}>
            <div className="detail-row">
              <div className="detail-label">
                Location
              </div>
              <div className="detail-value">
                {AppointmentHelper.getLocation(flow.appointment)}
              </div>
            </div>
            
            <div className="detail-row">
              <div className="detail-label">
                Time
              </div>
              <div className="detail-value">
                {AppointmentHelper.getStartTime(flow.appointment).format("MMMM D")} at {
                  AppointmentHelper.isGetLabs(flow.appointment)
                    ? `${AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")} - ${AppointmentHelper.getStartTime(flow.appointment).add(2, 'hours').format("h:mm a")}`
                    : AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")
              }
              </div>
            </div>

            {flow?.appointment?.phlebotomist && (
              <div className="detail-row">
                <div className="detail-label">
                  Phlebotomist
                </div>
                <div className="detail-value">
                  {AppointmentHelper.getPhlebotomistName(flow.appointment)}
                </div>
              </div>
            )}

            {inviteCount > 0 &&
            <div className="detail-row">
              <div className="detail-label">
                Invites
              </div>
              <div className="detail-value">
                {inviteCount} {inviteCount === 1 ? 'invite' : 'invites'}
              </div>
            </div>}
          </div>

          <Button
            className="question-btn"
            onClick={() => window.FrontChat("show")}
          >
            I have a question?
          </Button>

          <Panels productTypeCodes={productTypeCodes} />
          <LatestPosts />
        </div>
      ) : (
        <div className="prescription-approval">
          <div className="instruction-list">
            {tassoInstructions.map(({ sections }, instructionIndex) => (
              <div 
                key={`instruction-${instructionIndex}`} 
                className="instruction-item"
              >
                <Row>
                  <Col
                    style={{
                      width: width > Breakpoint.SM ? 80 : '100%',
                      marginBottom: width > Breakpoint.SM ? 0 : 10
                    }}
                  >
                    <Text className="instruction-index">
                      Step {instructionIndex+1}
                    </Text>
                  </Col>

                  <Col
                    style={{
                      width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                    }}
                  >
                    {sections.map(({ title, description }, sectionIndex) => (
                      <div 
                        key={`section-${instructionIndex}-${sectionIndex}`} 
                        className="section-item"
                      >
                        <Text className="section-title">
                          {title}
                        </Text>
                        <Paragraph className="section-description">
                          {description}
                        </Paragraph>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            ))}  
          </div>
          
          <Panels productTypeCodes={productTypeCodes} />
          <LatestPosts />
        </div>
      )}
    </div>
  );
}

export default ConfirmAppointment