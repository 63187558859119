import { useState, useEffect } from 'react';
import { Button, Tooltip, message, Dropdown } from 'antd';
import { PlusOutlined, CalendarOutlined, SearchOutlined, BarcodeOutlined } from '@ant-design/icons';
import { addProduct } from '../../services/product.service';
import moment from 'moment';
import classNames from 'classnames';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import ProductStatus from '../../enums/productStatus.enum';
import TrackingHelper from '../../helpers/tracking.helper';
import { BudgeTestDate } from '../budgeTestDate/budgeTestDate.component';

export const LipidTests = ({ results, patient: defaultPatient, membershipType, appointments=[], kits: defaultKits=[], onUpdatePatient }) => {
  const [nextTestAt, setNextTestAt] = useState(null)
  const [nextTestClassName, setNextTestClassName] = useState('next-test-default-tag')
  const [kit, setKit] = useState()
  const [appointment, setAppointment] = useState()
  const [kits, setKits] = useState(defaultKits)
  const [isBudgeTestDateVisible, setIsBudgeTestDateVisible] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [patient, setPatient] = useState(defaultPatient)
  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    if (defaultPatient) {
      setPatient(defaultPatient)
    }
  }, [defaultPatient])

  useEffect(() => {
    fetchNextTestAt()
  }, [results,  membershipType, patient])

  useEffect(() => {
    fetchNextTestClassName()
  }, [nextTestAt, appointment, kit])

  const fetchNextTestAt = () => {

    let testAt = null
    const sortedResults = results.sort((a,b) => new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime())
    const lastResultCollectedAt = sortedResults?.length ? sortedResults[0].collectedAt : null
    testAt = lastResultCollectedAt ? moment(lastResultCollectedAt).add(5, 'weeks').toDate() : new Date()

    // Explicitly set next test date
    if (new Date(patient.nextTestAt) > testAt) {
      testAt = patient.nextTestAt
    }
    setNextTestAt(testAt)
  }

  const getFutureAppointment = () => {
    return appointments.find(appointment => {
      return moment(appointment.start).isAfter(moment())
    })
  }

  const getRecentAppointment = () => {
    return appointments.find(appointment => {
      return new Date(appointment.start) < new Date() && new Date(appointment.start) >= new Date(nextTestAt)
    })
  }

  const getPendingKit = () => {
    if (!kits) return
    return kits.filter(kit => {
      return ![
        ProductStatus.CANCELED,
        ProductStatus.DEACTIVATED,
        ProductStatus.FAILED,
        ProductStatus.COMPLETE,
      ].includes(kit.status)
    }).sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
  }

  const fetchNextTestClassName = () => {
    const futureAppointment = getFutureAppointment()
    const recentAppointment = getRecentAppointment()
    const pendingKit = getPendingKit()
    setKit(pendingKit)
    const patientAppointment = futureAppointment || recentAppointment
    setAppointment(patientAppointment)

    if (moment(nextTestAt).isAfter(moment()) && moment(nextTestAt).isBefore(moment().add(2, 'weeks'))) {
      setNextTestClassName('next-test-soon-tag')
    } else if  (new Date(nextTestAt) >= new Date()) {
      setNextTestClassName('next-test-future-tag')
    } else {
      if (patientAppointment) {
        setNextTestClassName('future-appointment-tag')
      } else if (pendingKit) {
        setNextTestClassName('pending-kit-tag')
      } else {
        setNextTestClassName('next-test-due-tag')
      }
    }

    if (futureAppointment) {
      setTooltipText(`Next Appointment: ${moment(futureAppointment.start).format('MMM DD, YYYY')}`)
    } else if (recentAppointment) {
      setTooltipText(`Recent Appointment: ${moment(recentAppointment.start).format('MMM DD, YYYY')}`)
    } else if (pendingKit) {
      const {
        spotSampleStatus,
        spotSampleEvents,
        status,
        spotKitEvents,
        spotKitStatus,
        createdAt
      } = pendingKit
      if (spotSampleStatus && spotSampleStatus !== 'awaiting_collection') {
        const lastSampleEvent = spotSampleEvents.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime())[0]
        setTooltipText(`Sample ${formatStatus(spotSampleStatus)}: ${moment(lastSampleEvent.created).format('MMM DD, YYYY')}  (${moment().diff(moment(lastSampleEvent.created), 'weeks')}w)`)
      } else if (spotKitStatus) {
        const lastKitEvent = spotKitEvents.sort((a,b) => new Date(b.created).getTime() - new Date(a.created).getTime())[0]
        setTooltipText(`Kit ${formatStatus(spotKitStatus)}: ${moment(lastKitEvent.created).format('MMM DD, YYYY')}  (${moment().diff(moment(lastKitEvent.created), 'weeks')}w)`)
      } else if (status) {
        setTooltipText(`${formatStatus(status)}: ${moment(createdAt).format('MMM DD, YYYY')}  (${moment().diff(moment(createdAt), 'weeks')}w)`)
      }
    }
  }

  const formatStatus = (status) => {
    if (!status) return
    return status
      // Split by underscore and capitalize each word
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const onSend = async () => {
    setIsSending(true)
    try {
      const params = {
        fields: {
          free: true,
          type: ProductTypeCode.HEART_HEALTH_TEST_KIT,
          facility: null,
          start: null,
          patient: patient._id
        },
      }
      const response = await addProduct(params)
      if (response) {
        setKit(response.product)
        setKits([
          response.product,
          ...kits
        ])
      }
      message.info('Test kit sent')
    } catch (err) {
      message.error('Failed to send test kit')
    }
    setIsSending(false)
  }

  const budgeTestDate = {
    key: 'budge',
    label: (
      <a onClick={() => {
        setIsBudgeTestDateVisible(true)
      }}>
        <CalendarOutlined style={{ marginRight: 5 }} /> Budge Test Date
      </a>
    ),
  }


  return (
    <div>
      {nextTestAt && <>
        <BudgeTestDate 
          patientId={patient._id}
          title={'Next Test Date'}
          open={isBudgeTestDateVisible}
          setOpen={setIsBudgeTestDateVisible}
          select={'firstName lastName email shippingLocation nextTestAt'}
          onSuccess={onUpdatePatient}
        />

        <Dropdown
          overlayStyle={{
            width: 150
          }}
          menu={{ items: appointment ? [budgeTestDate] :
          [budgeTestDate, kit?.spotId && {
            key: 'kit-spot',
            label: (
              <a onClick={() => {
                window.open(`https://tracking.instalab.com/manage/app/kit/?q=${kit.spotId}`, '_blank')
              }}>
                <SearchOutlined style={{ marginRight: 5 }} /> Open Spot
              </a>
            ),
          }, kit?.trackingNumber && {
            key: 'kit-tracking' ,
            label: (
              <a onClick={() => {
                window.open(TrackingHelper.getDeliveryService(kit.trackingNumber).url, '_blank')
              }}>
                <BarcodeOutlined style={{ marginRight: 5 }} /> Kit Tracking
              </a>
            ),
          }, (kit?.returnTrackingNumber && kit?.spotSampleStatus !== 'awaiting_collection') && {
            key: 'kit-return-tracking' ,
            label: (
              <a onClick={() => {
                window.open(TrackingHelper.getDeliveryService(kit.returnTrackingNumber).url, '_blank')
              }}>
                <BarcodeOutlined style={{ marginRight: 5 }} /> Sample Tracking
              </a>
            ),
            }]
          }}
          placement='bottom'
        >
          <div className="tag-container">
            <Tooltip title={tooltipText}>
              <div className={classNames("tag", nextTestClassName)}>
                {moment(nextTestAt).format('MMM D, YYYY')}
              </div>
            </Tooltip>
          </div>
        </Dropdown>

        <Button
          className="add-btn"
          loading={isSending}
          onClick={onSend}
          icon={<PlusOutlined />}
        />
      </>
      }
    </div>
  )
}