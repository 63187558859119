import { useState, useContext, useEffect, useRef } from "react"
import { WarningFilled } from '@ant-design/icons'
import { UserContext } from "../../../contexts/user.context"
import { getAvailability } from "../../../services/schedule.service"
import { getProductTypeByCode } from '../../../services/productType.service'
import { completeFlow } from "../../../services/flow.service"
import { listPatients, invitePatient } from "../../../services/patient.service"
import { listUserCounts } from "../../../services/user.service"
import ProductHelper from "../../../helpers/product.helper"
import { Gender, ProductTypeCode, Role } from "../../../enums/index.enum";
import StepType from "../../flow/enums/stepType.enum"
import { Flow } from "../../flow/flow.component"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import { confirmAppointment } from "../../../services/appointment.service"
import './proAthletePanelFlow.scss';
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { PatientForm } from '../../patientForm/patientForm.component'

// Initialize dayjs plugins
dayjs.extend(utc)
dayjs.extend(timezone)

export const Step = {
  PATIENT: 'patient',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  LOCATION: 'location',
  INTEREST: 'interest',
  SCHEDULE: 'schedule',
  RESCHEDULE: 'reschedule',
  PAY: 'pay',
  INVITE: 'invite',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation'
}

const TimerDisplay = ({ start, timeAllowed }) => {

 
  const calculateTimeRemaining = () => {
    if (!start) return timeAllowed;
    const remaining = timeAllowed - dayjs().diff(dayjs(start), 'seconds');
    return Math.max(remaining, 0);
  };
  
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    // Reset timer when start changes
    setTimeRemaining(calculateTimeRemaining());
    
    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);
      
      if (remaining <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [start, timeAllowed]); // Add timeAllowed to dependencies

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;
  
  return (
    <span>
      {minutes}:{seconds.toString().padStart(2, '0')}
    </span>
  );
};

export const ProAthletePanelFlow = () => {
  const { currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [phlebType, setPhlebType] = useState()
  const [productType, setProductType] = useState()
  const [bloodDraw, setBloodDraw] = useState()
  const [patients, setPatients] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    const fetchProductType = async () => {
      const response = await getProductTypeByCode(ProductTypeCode.ATHLETE_PANEL)
      setProductType(response)
    }

    fetchPatients()
    fetchProductType()
  
  }, [])

  useEffect(() => {
    fetchPhlebType()
  }, [flow])


  useEffect(() => {
    fetchSkeleton()
  }, [productType, flow, phlebType, initialUser, patients])


  const fetchPatients = async () => {
    const fetchedPatients = await listPatients({
      filter: {
        firstName: { $exists: true, $ne: null },
        lastName: { $exists: true, $ne: null},
        // email: { $exists: true, $ne: null }
      },  
      sort: 'firstName lastName'
    })
    setPatients(fetchedPatients)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchPhlebType = async () => {
    if (!flow?.user?.location) return
    const response = await getAvailability({
      startDate: new Date(),
      location: flow.user.location,
      flowId: flow?._id
    })
    setPhlebType(response.phlebType)
  }

  const onAddPatientSuccess = (response) => {
    // Add new patient to options
    setPatients([...patients, response])    
    setIsModalOpen(false);

    setFlow(prevFlow => ({
      ...prevFlow,
      user: response
    }))
  };
  

  const fetchSkeleton = () => {
    if (!initialUser || !productType) return

    setSkeleton({

      [Step.PATIENT]: {
        type: StepType.SINGLE_SELECT,
        dropdown: true,
        buildFlow: true,
        title: `Select client to order ${productType?.title}.`,
        description: <>If this is someone new to Instalab, please first <a onClick={() => setIsModalOpen(true)}>add client</a> first.</>,
        field: {
          name: 'user',
          placeholder: "Select Client",
          required: true,
          options: patients.map(patient => ({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient._id
          })),
        },
        onNextStep: (user, flow) => {
          if (!(flow?.user?.firstName && flow?.user?.lastName)) {
            return Step.NAME
          }

          if (!(flow?.user?.email)) {
            return Step.EMAIL
          }
          if (!(flow?.user?.phone)) {
            return Step.PHONE
          }
          if (!(flow?.user?.gender)) {
            return Step.GENDER
          }
          if (!(flow?.user?.dob)) {
            return Step.DOB
          }
          return Step.LOCATION
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.EMAIL,
        buildUser: true,
        title: `What is your client's full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return flow?.user?.firstName && flow?.user?.lastName
        }
      },
      [Step.EMAIL]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s email address?`,
        field: {
          name: 'email',
          placeholder: 'Type email address here...',
          email: true,
          required: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Email already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return flow?.user?.email
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s phone number?`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type phone number here...',
          required: true,
          unique: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Phone number already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return flow?.user?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return flow?.user?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `What's ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return flow?.user?.dob
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.SCHEDULE,
        title: () => flow?.user?.firstName ? `Where is ${flow?.user?.firstName} located?` : `Where is your client located?`,
        description: () => flow?.user?.firstName ? `The phlebotomist will meet ${flow?.user?.firstName} here to draw their blood.` : `The phlebotomist will meet your client here to draw their blood.`,
        field: {
          name: 'location'
        },
      },      
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.PAY,
        createPending: true,
        addPendingAppointment: true,
        title: () => flow?.user?.firstName ? `Schedule ${flow?.user?.firstName}'s blood draw appointment.` : `Schedule your client's blood draw appointment.`,
        description: `The appointment takes about 15 minutes. ${flow?.user?.firstName} must fast 8 hours before ${flow?.user?.gender === Gender.FEMALE ? 'her' : 'his'} appointment. Water and black coffee are fine.` , 
      },
      [Step.RESCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.INVITE,
        addAppointment: true,
        title: () => flow?.user?.firstName ? `Reschedule ${flow?.user?.firstName}'s blood draw appointment.` : `Reschedule your client's blood draw appointment.`,
        description: `The appointment takes about 15 minutes. ${flow?.user?.firstName} must fast 8 hours before ${flow?.user?.gender === Gender.FEMALE ? 'her' : 'his'} appointment. Water and black coffee are fine.` , 
      },
      
      
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.INVITE,
        addProduct: true,
        productType: bloodDraw ? [productType?.code, bloodDraw?.code] : productType?.code,
        title: (() => {
          const proMobileDrawCost = bloodDraw ? ProductHelper.getProviderCost(bloodDraw, [], currentUser) : 0;
          const proPrice = ProductHelper.getProviderCost(productType, [], currentUser) + proMobileDrawCost;
          return <>Pay <span className="true-price">${proPrice}</span> for the <span className="product-name">{productType?.title}</span>.</>
        })(),
        header: () => {
          return (
            <div className="timer-warning">
              <div className="timer-warning-content">
                Complete payment within <TimerDisplay start={flow?.appointment?.updatedAt} timeAllowed={10*60} />{" "}
                to secure the appointment for {flow?.user?.firstName}.
              </div>
            </div>
          )
        },
        description: () => {
          return (
              <p>
                {!currentUser?.chargePersonalCard && "Reminder: Your account is set up to use your client's card for this purchase."}
              </p>
          )
        },
        onSuccess: async () => {
          await confirmAppointment(flow?.appointment?._id, {flowId: flow?._id})
        },
      },
      [Step.INVITE]: {
        type: StepType.INVITE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addInvite: true,
        inviteOptions: patients.filter(patient => patient._id !== flow?.user?._id),
        title: `Add others to ${flow?.user?.firstName}'s appointment.`,
        description: <>
          Invite or add others to join {flow?.user?.firstName}'s appointment. No additional charges will be made to the card on file until after the appointment.
        </>,
      },

      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        title: `All set! 🎉`,
        description: <>
          {flow?.user?.firstName ? `${flow?.user?.firstName}'s blood draw appointment is confirmed! ` : `Your client's blood draw appointment is confirmed! `}
        
          We have sent a text confirmation and calendar invite to {flow?.user?.firstName} to confirm {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment.
        </>,
        buttonText: 'Back to Patient Profile',
        path: `/patients/${flow?.user?._id}?key=Appointments`,
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>

    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      startIndex={1}
      productTypeCode={ProductTypeCode.ATHLETE_PANEL}
    />

    <PatientForm 
      open={isModalOpen}
      setOpen={setIsModalOpen}
      onSuccess={onAddPatientSuccess}
      title="Add Client"
      requiredFields={['firstName', 'lastName', 'email', 'phone', 'gender', 'dob']}
    />

  </>
}