import { Typography } from "antd";
import classNames from "classnames";
import { BlogData } from "../../../data/blog.data";
import { useParams } from "react-router-dom";

const { Paragraph } = Typography


export const BmiPost = () => {
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "bmi-post")}>
      <div className="blog-border" /><br/><br/>

      <div className="blog-body">
        <Paragraph className="blog-description">
        Body Mass Index (BMI) was introduced in the 1830s by Adolphe Quetelet, a mathematician who sought to define statistical averages in human growth—not to diagnose individual health. Yet over time, BMI has become a staple in clinical practice for assessing weight and associated health risks. While it’s convenient and easy to calculate, BMI oversimplifies the complexities of body composition and can often lead to inaccurate or misleading conclusions about health. Its inability to distinguish between muscle and fat, account for fat distribution, or consider demographic factors like age, sex, and ethnicity makes it an increasingly outdated tool in the age of personalized medicine.

        </Paragraph>

        <Paragraph className="blog-title">
          BMI's Biggest Flaw: It Doesn't Differentiate Between Fat and Muscle
        </Paragraph>
        <Paragraph className="blog-description">
          BMI measures total body weight without distinguishing its composition. Muscle, fat, bone, and water all contribute to body weight, yet they have vastly different effects on health. For instance, visceral fat—fat stored around the organs—is strongly associated with inflammation, insulin resistance, and cardiometabolic diseases. In contrast, muscle mass improves insulin sensitivity, supports metabolic health, and lowers disease risk. Despite these differences, BMI treats them equally.
        </Paragraph>
        <Paragraph className="blog-description">
          Consider this: A 21-year longitudinal study of over 16,000 participants found that individuals with higher muscle mass had a 30% lower risk of mortality, while excess body fat significantly increased it. So a bodybuilder with low fat but high muscle could be classified as "obese," while someone with a normal BMI but high visceral fat could face greater health risks.
        </Paragraph>

        <Paragraph className="blog-title">
          Fat Distribution: A More Important Predictor
        </Paragraph>
        <Paragraph className="blog-description">
          Where fat is stored matters far more than total fat. Subcutaneous fat, stored just beneath the skin, is relatively benign. Visceral fat, located around the organs, is a key driver of inflammation and metabolic dysfunction, contributing to conditions like type 2 diabetes and cardiovascular disease.
        </Paragraph>
        <Paragraph className="blog-description">
          A study from the UK Biobank, involving over 350,000 participants, showed that individuals with high visceral fat had a 46% higher risk of cardiovascular death and a 20% higher risk of all-cause mortality, even with a normal BMI. This highlights a critical flaw: BMI doesn't capture fat distribution. Someone with a normal BMI but high visceral fat faces worse health outcomes than someone with a higher BMI and healthier fat distribution.
        </Paragraph>

        <Paragraph className="blog-title">
          Age, Sex, and Ethnicity Complicate BMI's Usefulness
        </Paragraph>
        <Paragraph className="blog-description">
          BMI also fails to account for the physiological and demographic factors that influence body composition:
        </Paragraph>

        <ul className="blog-list">
          <li><strong>Age:</strong> Muscle mass generally peaks in the mid-20s and declines with age, while fat mass often increases. Older adults with "normal" BMI may have low muscle mass and high fat levels, increasing their risk for frailty, falls, and metabolic disorders—a phenomenon masked by BMI's simplicity.</li>
          <li><strong>Sex:</strong> Women naturally have higher body fat percentages due to hormonal and reproductive factors, yet BMI does not adjust for these differences.</li>
          <li><strong>Ethnicity:</strong> BMI thresholds are based on White European populations and do not account for ethnic variations in body composition. For instance, South Asians face a higher risk of diabetes at lower BMI levels, with a BMI of 23.9 posing the same risk as a BMI of 30 in White populations. Moreover, Black individuals tend to have higher muscle and bone density, suggesting their BMI obesity thresholds could be set higher.</li>
        </ul>

        <Paragraph className="blog-title">
          The Disconnect Between BMI and Metabolic Health
        </Paragraph>
        <Paragraph className="blog-description">
          Metabolic health is determined by how well the body regulates key processes, not by weight alone. Markers such as insulin sensitivity, blood pressure, lipid profiles, and inflammatory markers provide a far clearer picture of health risks than BMI.
        </Paragraph>
        <Paragraph className="blog-description">
          Studies have shown that BMI frequently misclassified individuals:
        </Paragraph>
        <ul className="blog-list">
          <li>30–40% of those classified as obese by BMI are metabolically healthy.</li>
          <li>10–20% of individuals with normal BMI are metabolically unhealthy.</li>
        </ul>
        <Paragraph className="blog-description">
          This misclassification can lead to overtreatment of metabolically healthy individuals with high BMI and missed diagnoses for those with metabolic dysfunction but normal BMI.
        </Paragraph>

        <Paragraph className="blog-title">
          Better Tools for Assessing Health
        </Paragraph>
        <Paragraph className="blog-description">
          BMI's value lies in its simplicity, but it lacks the precision required for personalized health assessments. More advanced tools, like <a href="/research/dexa" className="blog-link">DEXA scans</a>, go beyond a single number by providing a detailed breakdown of fat mass, muscle mass, and bone density, offering insights into what truly drives health outcomes. Blood testing further refines this understanding, revealing critical markers like insulin sensitivity, lipid profiles, and inflammatory status. When combined with your health history, these tools allow for a more nuanced and actionable approach to identifying and addressing health risks.
        </Paragraph>

        <Paragraph className="blog-title">
          The Bottom Line
        </Paragraph>
        <Paragraph className="blog-description">
          BMI is a starting point—a quick and easy tool for initial screenings and population studies—but it's not the full story. Its limitations are clear when applied to individuals, often oversimplifying or misrepresenting health risks. To truly optimize your health, BMI should be used as one piece of a larger puzzle, complemented by tools that account for body composition, fat distribution, and metabolic function.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Sedlmeier, A. M., et al. (2021). Relation of body fat mass and fat-free mass to total mortality: Results from 7 prospective cohort studies. American Journal of Clinical Nutrition, 113(3), 639–646. <a href="https://pubmed.ncbi.nlm.nih.gov/33437985/" target="_blank" rel="noopener noreferrer">https://pubmed.ncbi.nlm.nih.gov/33437985/</a>
            </li>
            <li>
              He, Q., et al. (2022). Association between the visceral adiposity index and risks of all-cause and cause-specific mortalities in a large cohort: Findings from the UK Biobank. Nutritional Metabolism and Cardiovascular Diseases, 32(9), 2204–2215. <a href="https://pubmed.ncbi.nlm.nih.gov/35843793/" target="_blank" rel="noopener noreferrer">https://pubmed.ncbi.nlm.nih.gov/35843793/</a>
            </li>
            <li>
              Westerterp, K. R., et al. (2021). Physical activity and fat-free mass during growth and in later life. American Journal of Clinical Nutrition, 114(5), 1583–1589. <a href="https://pubmed.ncbi.nlm.nih.gov/34477824/" target="_blank" rel="noopener noreferrer">https://pubmed.ncbi.nlm.nih.gov/34477824/</a>
            </li>
            <li>
              Cleyachetty, R., et al. (2021). Ethnicity-specific BMI cutoffs for obesity based on type 2 diabetes risk in England: A population-based cohort study. The Lancet Diabetes & Endocrinology, 9(7), 419–426. <a href="https://pubmed.ncbi.nlm.nih.gov/33989535/" target="_blank" rel="noopener noreferrer">https://pubmed.ncbi.nlm.nih.gov/33989535/</a>
            </li>
            <li>
              Blüher, M. (2020). Metabolically healthy obesity. Endocrine Reviews, 41(3). <a href="https://doi.org/10.1210/endrev/bnaa004" target="_blank" rel="noopener noreferrer">https://doi.org/10.1210/endrev/bnaa004</a>
            </li>
            <li>
              Zembic, A., et al. (2021). An empirically derived definition of metabolically healthy obesity based on risk of cardiovascular and total mortality. <a href="https://doi.org/10.1001/jamanetworkopen.2021.8505" target="_blank" rel="noopener noreferrer">https://doi.org/10.1001/jamanetworkopen.2021.8505</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
