import { Typography } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography

export const SleepTrainingPost = () => {
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "sleep-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image bottom" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Myth of Sleep Adaptation
        </Paragraph>
        <Paragraph className="blog-description">
          Many high performers look to elite figures—entrepreneurs, athletes, military personnel—who seem to thrive on just a few hours of sleep. But research consistently shows that chronic sleep deprivation leads to cognitive decline, impaired decision-making, and increased risk of chronic disease.
        </Paragraph>
        <Paragraph className="blog-description">
          Studies on sleep restriction have found that while people may feel like they are adapting, their objective cognitive performance continues to decline. In a landmark study, participants who were restricted to six hours of sleep per night for two weeks experienced the same cognitive impairments as someone who had been awake for 24 hours straight. Even though they reported feeling "fine," their reaction times, memory, and problem-solving abilities deteriorated significantly.
        </Paragraph>

        <Paragraph className="blog-title">
          The Genetics of Sleep Needs
        </Paragraph>
        <Paragraph className="blog-description">
          Not everyone requires the same amount of sleep. A small percentage of people—about 1% of the population—carry a genetic mutation in the DEC2 gene, which allows them to function optimally on just 4-6 hours of sleep per night. However, unless you've won the genetic lottery, trying to force your body into this pattern can backfire.
        </Paragraph>
        <Paragraph className="blog-description">
          A 2021 study using sleep-tracking and performance tests found that individuals attempting to train themselves to need less sleep had significant increases in cortisol (stress hormone) levels and decreased reaction times, suggesting that they were merely accumulating sleep debt. In contrast, studies have shown that high performers who prioritize 7-9 hours of high-quality sleep outperform those who cut corners.
        </Paragraph>

        <Paragraph className="blog-title">
          Can You Improve Sleep Efficiency?
        </Paragraph>
        <Paragraph className="blog-description">
          While permanently reducing sleep needs is unlikely for most, you can train yourself to sleep more efficiently:
        </Paragraph>

        <ol className="blog-list">
          <li>Optimize sleep quality: Deep, restorative sleep matters more than total hours. Cutting out blue light before bed, maintaining a consistent schedule, and managing stress can help.</li>
          <li>Use strategic naps: Short naps (10-20 minutes) can offset mild sleep deficits without disrupting nighttime sleep.</li>
          <li>Prioritize deep sleep: High-quality slow-wave sleep (SWS) is essential for memory and performance. Cold rooms, magnesium supplementation, and relaxation techniques can enhance it.</li>
          <li>Leverage circadian rhythms: Aligning sleep with your natural rhythms (going to bed and waking up at the same time daily) improves efficiency.</li>
        </ol>

        <Paragraph className="blog-title">
          The Verdict: Can You Train Yourself to Need Less Sleep?
        </Paragraph>
        <Paragraph className="blog-description">
          For 99% of people, the answer is no. While you can become more efficient in the way you sleep—optimizing deep sleep, napping strategically, and reducing sleep latency—long-term sleep reduction without consequences is not a viable strategy. Instead of trying to "hack" sleep by cutting hours, the real key is to maximize sleep quality, align with natural rhythms, and use strategic naps when needed. If you want to sustain peak performance without wrecking your health, protecting your sleep should be non-negotiable.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Van Dongen, H. P. A., Maislin, G., Mullington, J. M., & Dinges, D. F. (2003). The cumulative cost of additional wakefulness: Dose-response effects on neurobehavioral functions and sleep physiology from chronic sleep restriction and total sleep deprivation. Sleep, 26(2), 117–126. <a onClick={() => window.open('https://doi.org/10.1093/sleep/26.2.117', '_blank')}>https://doi.org/10.1093/sleep/26.2.117</a>
            </li>
            <li>
              Lo, J. C., Ong, J. L., Leong, R. L., Gooley, J. J., & Chee, M. W. (2016). Cognitive performance, subjective sleepiness, and hormonal markers of stress during sleep restriction and recovery sleep. Sleep, 39(3), 687–698. <a onClick={() => window.open('https://doi.org/10.5665/sleep.5552', '_blank')}>https://doi.org/10.5665/sleep.5552</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};