import React, { useEffect, useState } from 'react';
import { Table, Button, Dropdown, Typography } from 'antd'
import { SettingOutlined, EditOutlined } from '@ant-design/icons'
import './membershipTypes.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { listMembershipTypes } from '../../services/membershipType.service.js';
import { MembershipTypeForm } from '../membershipTypeForm/membershipTypeForm.component.js';
import MembershipHelper from '../../helpers/membership.helper.js';

const { Column } = Table
const { Text } = Typography

export const MembershipTypes = () => {
  const [membershipTypes, setMembershipTypes] = useState([])
  const [membershipTypeId, setMembershipTypeId] = useState()
  const [openModal, setOpenModal] = useState()

  useEffect(() => {
    document.title = 'Instalab | Membership Types'
  }, [])

  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    const response = await listMembershipTypes()
    setMembershipTypes(sortMembershipTypes(response))
  }

  const onEdit = (_id) => {
    setMembershipTypeId(_id)
    setOpenModal(true)
  }

  const onEditSuccess = (membershipType) => {
    setMembershipTypes(membershipTypes.map(p => {
      return p._id === membershipType._id ? membershipType : p
    }))
  }

  const sortMembershipTypes = (unsortedMembershipTypes) => {
    return unsortedMembershipTypes.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
  
      if (titleA < titleB) {
          return -1;
      }
      if (titleA > titleB) {
          return 1;
      }
      return 0;
    })
  }

  return (
    <div className="membership-types">
      <PageHeader
        title='Membership Types'
        count={membershipTypes?.length}
      />

      <MembershipTypeForm
        open={openModal}
        setOpen={setOpenModal}
        onSuccess={onEditSuccess}
        membershipTypeId={membershipTypeId}
        setMembershipTypeId={setMembershipTypeId}
      />

      <Table
        loading={!membershipTypes?.length}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={membershipTypes}
      >
        <Column
          title='Title'
          dataIndex='title'
        />

        <Column
          title='Code'
          dataIndex='code'
        />

        <Column
          title='Normal Cost'
          dataIndex='cost'
          render={(_, membershipType) => (
            <Text className="membership-type-cost">
              {MembershipHelper.formatCost(membershipType)}
            </Text>
          )}
        />

        <Column
          title='Membership Cost'
          dataIndex='membershipCost'
          render={(_, membershipType) => (
            <Text className="membership-type-cost">
              {MembershipHelper.formatDiscountCost(membershipType)}
            </Text>
          )}
        />
        
        <Column
          dataIndex='remove'
          className="action-column"
          render={(_, { _id }) => (
            <Dropdown 
              menu={{ 
                items: [{
                  key: 'edit',
                  label: (
                    <a onClick={() => onEdit(_id)}>
                      <EditOutlined /> Edit
                    </a>
                  ),
                }]
              }}
              placement='bottomRight'
            >
              <Button>
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
    </div>
  )
}