import { Modal, Typography, Table } from 'antd'
import { useEffect, useState } from 'react'
import { listNotesByPatient } from '../../services/note.service'
import parse from 'html-react-parser';
import moment from 'moment'
import './adminNotesModal.scss'

const { Column } = Table
const { Paragraph } = Typography

// BE CAREFUL - DO NOT SHOW THIS IF NOT ADMIN OR PROVIDER

export const AdminNotesModal = ({ patientId, open, setOpen, title }) => {
  const [notes, setNotes] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const onCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    fetchNotes()
  }, [])

  const fetchNotes = async () => {
    const response = await listNotesByPatient(patientId)
    setNotes(response)
    setIsLoading(false)
  }

  const noteTypeFilters = [...new Set(notes?.map(note => note.noteType))]
    .filter(Boolean)
    .map(type => ({ text: type, value: type }))

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="admin-notes-modal"
      title={title}
      footer={null}
      width={950}
    >
      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={notes}
      >
        <Column
          title='Note'
          dataIndex='text'
          render={(note, record) => (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <div>
                <Paragraph className="noteText">{parse(note)}</Paragraph>
                {(record.startDate || record.endDate) && (
                  <div style={{ fontSize: '12px', color: '#666' }}>
                    {record.startDate && <span>Start: {moment(record.startDate).format('MM/DD/YYYY')}</span>}
                    {record.startDate && record.endDate && <span> | </span>}
                    {record.endDate && <span>End: {moment(record.endDate).format('MM/DD/YYYY')}</span>}
                  </div>
                )}
              </div>
            </div>
          )}
        />

        {!patientId && (
          <Column
            title='Patient'
            dataIndex='patient'
            render={patient => `${patient.firstName} ${patient.lastName}`}
          />
        )}


        <Column
          title='Type'
          dataIndex='noteType'
          filters={noteTypeFilters}
          onFilter={(value, record) => record.noteType === value}
          render={noteType => noteType}
        />


        <Column
          title='Author'
          dataIndex='author'
          render={author => `${author.firstName} ${author.lastName}`}
        />

        <Column
          title='Date'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
        />
      </Table>
    </Modal>
  )
}