import { Collapse, Dropdown, Menu } from 'antd';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { TextHelper } from "../../helpers/text.helper";
import {getProductTypeByCode} from "../../services/productType.service";
import { useState, useEffect, useContext } from 'react';
import ProductTypeCode  from "../../enums/productTypeCode.enum";
import "./recommendationItem.scss"
import { UserContext } from '../../contexts/user.context';
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
import { listTests } from '../../services/test.service';
import { listAnnotatedResults } from '../../services/result.service';


const RecommendationItem = ({ recommendation, viewByAction=true, tests, results, patient }) => {
  const [productTypes, setProductTypes] = useState({});
  const { currentUser} = useContext(UserContext);
  const [allTests, setAllTests] = useState([]);
  const [allResults, setAllResults] = useState([]);



  useEffect(() => {
    const fetchTests = async () => {
      setAllTests(tests || await listTests());
    }
    fetchTests();

    const fetchResults = async () => {
      setAllResults(results || await listAnnotatedResults({patient: (patient?._id || currentUser?._id)}));
    }

    fetchResults();
  }, [recommendation, patient, currentUser, tests, results]);
  
  


  useEffect(() => {
    const loadProductTypes = async () => {
      try {
        const blocks = recommendation.description.filter(block => block.type === "productTypeBox");
        const types = {};
        
        for (const block of blocks) {
          if (block.productTypeCode) {
            try {
              types[block.productTypeCode] = await getProductTypeByCode(block.productTypeCode);
            } catch (error) {
              console.error(`Failed to load product type for code ${block.productTypeCode}:`, error);
              // Set a default value or continue with partial data
              types[block.productTypeCode] = {
                title: block.title || block.cta || 'Unknown Product',
                flowType: block.flowType || 'default'
              };
            }
          }
        }
        
        setProductTypes(types);
      } catch (error) {
        console.error('Error loading product types:', error);
        // Set empty object to prevent continuous retries
        setProductTypes({});
      }
    };

    loadProductTypes();
  }, [recommendation]);

  const handleProductTypeClick = (block) => {
    if (!block) return;
    const productType = productTypes[block.productTypeCode];
    const path = block.url || productType.path || `/flow/${productType.flowType}`;
    window.open(path, "_blank");
    PosthogHelper.track(currentUser, `[${EventCode.GAMEPLAN_CLICK}] ${block.productTypeCode}`);
    return false;
  };

  const renderActionButtons = () => {
    const orderBlocks = recommendation.description.filter(block => block.type === "productTypeBox" && block.actionType === "order")
    let trackBlocks = recommendation.description.filter(block => ["productTypeBox", "selfReportBox"].includes(block.type) && block.actionType === "track")

    if (orderBlocks.length === 0 && trackBlocks.length === 0) return null;

    return (
      <div className="recommendation-actions">
        {orderBlocks.length > 0 && (
          orderBlocks.length === 1 ? (
            <button className="action-button" onClick={(event) => {
              event.stopPropagation();
              handleProductTypeClick(orderBlocks[0]);
            }}>
              {orderBlocks[0].productTypeCode === ProductTypeCode.CUSTOM_PANEL ? 
                orderBlocks[0].title || orderBlocks[0].cta
              : orderBlocks[0].cta}
            </button>
          ) : (
            <Dropdown
              overlay={
                <Menu>
                  {orderBlocks.map((block, index) => (
                    <Menu.Item 
                      key={`order-${index}`} 
                      onClick={() => handleProductTypeClick(block)}
                    >
                      {block.productTypeCode === ProductTypeCode.CUSTOM_PANEL ? 
                        block.title || block.cta
                      : productTypes[block.productTypeCode]?.title || block.title || block.cta}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <button className="action-button" onClick={e => e.stopPropagation()}>
                {orderBlocks[0].cta} <DownOutlined style={{ marginLeft: 5, fontSize: '12px' }} />
              </button>
            </Dropdown>
          )
        )}

        {trackBlocks.length > 0 && <Dropdown
          overlay={
            <Menu 
            >
              {trackBlocks.map((block, index) => (
                <Menu.Item 
                  key={`schedule-${index}`} 
                  onClick={() => {
                    handleProductTypeClick(block);
                  }}
                >
                  { block.productTypeCode === ProductTypeCode.CUSTOM_PANEL ? 
                   block.title || block.cta
                  :  productTypes[block.productTypeCode]?.title || block.title || block.cta}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <button className="action-button" onClick={e => e.stopPropagation()}>
            Track Progress <DownOutlined style={{ marginLeft: 5, fontSize: '12px' }} />
          </button>
        </Dropdown>}
      </div>
    );
  };

  return (
    <Collapse
      className="recommendation-collapse"
      defaultActiveKey={[]}
    >
      <Collapse.Panel 
        header={
          <div className="recommendation-header">
            <span className="recommendation-title">{recommendation.title}</span>
            {viewByAction && renderActionButtons()}
          </div>
        }
        key="1"
        expandIcon={({ isActive }) => (
          <div className={`recommendation-dot ${isActive ? 'expanded' : ''}`}>
            <CheckOutlined className="check-icon" />
          </div>
        )}
      >
        <div className="recommendation-content">
          {recommendation.description.map((block, index) => (
            <div key={index} className="description-block">
              {TextHelper.renderBlock(block, {tests: allTests, results: allResults, patient})}
            </div>
          ))}
      

        </div>  


      </Collapse.Panel>
    </Collapse>
  );
};

export default RecommendationItem