const FlowType = Object.freeze({
  SUBSCRIBE: 'subscribe',

  // Memberships
  ULTIMATE_MEMBERSHIP: 'ultimate',
  MEMBER: "member",
  BASIC_MEMBERSHIP: 'basic-member',
  HEART_HEALTH_UPGRADE: 'heart-upgrade',
  HEART_HEALTH_STRIPPED: 'heart-stripped',
  HEART_HEALTH_V2: 'heart-health',

  // Direct panel flows
  TESTOSTERONE_PANEL: 'testosterone-panel',
  CHOLESTEROL_BALANCE: 'cholesterol-balance',
  LONGEVITY_TEST: 'longevity-test',
  ATHLETE_TEST: 'athlete-test',
  COMPREHENSIVE_TEST: "comprehensive-test",
  FERTILITY: 'fertility',
  TESTOSTERONE: 'testosterone',
  HEART_HEALTH_TEST: 'heart-health-test',
  CUSTOM_TEST: 'custom-test',
  
  // Test flows
  GALLERI_TEST: "galleri-test",
  PRENUVO: "prenuvo",
  TRUAGE_COMPLETE: "truage-complete",
  TOXIC_METALS_TEST: 'toxic-metals-test',
  CAC: 'coronary-calcium-scan',
  DEXA: 'dexa',
  RMR: 'rmr',
  DEXA_BODYCOMP: 'dexa-bodycomp',
  VO2MAX: 'vo2max',
  MOLD_TOXINS: "toxic-molds-test",
  SLEEP_APNEA: "sleep-apnea-test",
  BIOBEAT: 'biobeat',
  CLEERLY: 'cleerly',
  CARDIAC_MRI: 'cardiac-mri',
  CGM: 'cgm',
  DIURNAL_CORTISOL_PROFILE: 'diurnal-cortisol-profile',
  HEART_HEALTH_GENETIC_TEST: 'genetic-test',
  PERFORMANCE_PANEL: 'performance-panel',
  FIT_STOOL: 'fit',
  LDCT: 'ldct',
  COLONOSCOPY: 'colonoscopy',
  MAMMOGRAM: 'mammogram',
  PAP_SMEAR: 'pap-smear',
  HPV_TEST: 'hpv-test',
  DRE: 'dre',
  SKIN_CANCER_EXAM: 'skin-cancer-exam',
  ENDOSCOPY: 'endoscopy',
  SPERM_TEST: 'sperm-test',
  PULMONARY_FUNCTION_TEST: 'pulmonary-function-test',
  BREAST_ULTRASOUND: 'breast-ultrasound',
  MODY_TEST: 'mody-test',
  SIBO_TEST: 'sibo-test',
  CANCER_GENETIC_TEST: 'cancer-genetic-test',
  
  // Consults
  HEART_MEMBER_CONSULT: "heart-consult",
  LONGEVITY_CONSULT: "longevity-consult",
  ATHLETE_CONSULT: 'athlete-consult',
  CARDIOLOGY_CONSULT: 'cardiology-consult',
  GASTROENTEROLOGY_CONSULT: 'gastroenterology-consult',
  DERMATOLOGY_CONSULT: 'dermatology-consult',
  NEUROLOGY_CONSULT: 'neurology-consult',
  UROLOGY_CONSULT: 'urology-consult',
  GYNECOLOGY_CONSULT: 'gynecology-consult',
  ENDOCRINOLOGY_CONSULT: 'endocrinology-consult',
  RHEUMATOLOGY_CONSULT: 'rheumatology-consult',
  ORTHOPEDICS_CONSULT: 'orthopedics-consult',
  DENTAL_CONSULT: 'dental-consult',
  OPTHALMOLOGY_CONSULT: 'ophthalmology-consult',
  ENT_CONSULT: 'ent-consult',
  UROGYNECOLOGY_CONSULT: 'urogynecology-consult',
  
  // Treatments
  LEQVIO: "leqvio",
  CHOLESTEROL: "cholesterol",

  // Misc
  HEART_HEALTH_INTAKE: 'heart-intake',
  SUPPLEMENTS: "supplements",
  INTAKE: "intake", 
  GIFT: "gift",
  QUIZ: 'quiz',
  UPGRADE: 'upgrade',
  INVITE: 'invite',
  REQUEST: 'request',
  REDEEM: 'redeem',
  PACKAGE: 'package',
  WAITLIST: 'waitlist',
  SPECIALIST: 'specialist',
  SELF_REPORT: 'self-report',


  // Pro flows
  PRO_SIGNUP: "pro-signup",
  PRO_CONSULT: "pro-consult",
  PRO_DRAW: 'pro-draw',
  PRO_LONGEVITY_PANEL: 'pro-longevity-panel',
  PRO_ATHLETE_PANEL: 'pro-athlete-panel',
  PRO_SLEEP_APNEA: 'pro-sleep-apnea',
  PRO_AVAILABILITY: 'pro-availability',
  PRO_MOBILE_BLOOD_DRAW: 'pro-mobile-blood-draw',


  // DEMO - FOR TESTING ONLY
  DEMO: 'demo'
})

export default FlowType