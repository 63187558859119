import { Table } from "antd";
import "./testTable.scss";
import { useEffect, useState } from "react";
import { ReadableRiskLevel, RiskLevel} from "../../../enums/index.enum";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const TestTable = ({codes = [], report, tests: initialTests, results}) => {
  const [processedTests, setProcessedTests] = useState();
  const [tableData, setTableData] = useState();

  useEffect(() => {
    if (codes && initialTests) {
      setProcessedTests(initialTests.filter(test => codes.includes(test.code)));
    }
  }, [codes, initialTests]);

  useEffect(() => {
    setTableData(getTableData());
  }, [processedTests, results]);

  const getTableData = () => {
    if (!processedTests || !results?.length) return null;

    // Pull test values from results
    const testResults = getTestResults();

    return testResults.map((result) => {
      const rowData = {
        date: dayjs(result.collectedAt, { dateFormat: 'YYYY-MM-DD' }).format('MM/DD/YYYY'),
        key: result._id,
      };

      // Add a column for each test
      processedTests.forEach(test => {
        const testResult = result.values.find((v) => v.test === test._id);
        if (testResult) {
          rowData[test.code] = {
            value: testResult.value,
            risk: testResult.risk,
            details: testResult.details
          };
        }
      });

      return rowData;
    });
  };

  const getTestResults = () => {
    return results
      .filter((result) => {
        return (
          !report ||
          new Date(report.result.collectedAt).getTime() >=
            new Date(result.collectedAt).getTime()
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
        );
      })
      .filter((result) => {
        return result.values.some((v) => {
          return processedTests.some(test => v.test === test._id && v.value && v.risk);
        });
      });
  };

  const columns = [
    {
      title: 'Date Collected',
      dataIndex: 'date',
      className: 'date-col',
      key: 'date',
    },
    ...processedTests?.map(test => ({
      title: test.name || test.code,
      dataIndex: test.code,
      key: test.code,
      className: 'value-col',
      render: (record) => {
        if (!record) return null;
        const { value, risk, details } = record;
        return (
          <>
            <div className={`risk-tag ${risk}-risk`}>
              {risk !== RiskLevel.UNKNOWN && 
                <>{ReadableRiskLevel[risk][0].toUpperCase()}{ReadableRiskLevel[risk].slice(1).toLowerCase()}:</>} {value}
            </div>
            <div className="test-details">{details}</div>
          </>
        );
      },
    })) || []
  ];

  return (
    processedTests &&
    tableData && (
      <div className="test-table">
        <Table
          pagination={false}
          rowKey="key"
          dataSource={tableData}
          columns={columns}
          className="primary-table"
          showHeader={true}
        />
      </div>
    )
  );
};
