import { Typography, Popover, Table } from 'antd';
import './biomarkerPopover.scss';
import useWidth from '../../hooks/useWidth.hook';
import Breakpoint from '../../enums/breakpoint.enum';
import { CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';

const { Paragraph } = Typography
const { Column } = Table

export const BiomarkerPopover = ({ trigger }) => {
  const width = useWidth()
  const [open, setOpen] = useState(false);

  const biomarkers = [{
    name: `ApoB`,
    description: `Tracks the number of atherogenic particles in your blood.`
  }, {
    name: `Lipoprotein(a)`,
    description: `A genetic marker that indicates your long-term risk of heart disease.`
  }, {
    name: `hs-CRP`,
    description: `A marker of inflammation that signals whether your arteries may be inflamed.`
  }, {
    name: `Total Cholesterol`,
    description: `Provides a general overview of the cholesterol in your blood.`
  }, {
    name: `HDL Cholesterol`,
    description: `Known as "good" cholesterol, high levels help remove bad cholesterol from your bloodstream.`
  }, {
    name: `LDL Cholesterol`,
    description: `Known as "bad" cholesterol, high levels contribute to plaque buildup in the arteries.`
  }, {
    name: `VLDL Cholesterol`,
    description: `Carries triglycerides through the bloodstream and contributes to arterial plaque buildup.`
  }, {
    name: `Triglycerides`,
    description: `High levels of these fats increase your risk of heart disease.`
  }, {
    name: `HbA1c`,
    description: `Measures your average blood sugar levels over the past few months.`
  }, {
    name: `Blood Pressure`,
    description: `High blood pressure strains your heart and arteries.`
  }, {
    name: `ALT`,
    description: `An enzyme that helps assess how well your liver is functioning.`
  }]

  // Prevent popover from closing on various touch/scroll events
  const handleTouchStart = (e) => {
    e.stopPropagation();
  };

  const handleTouchMove = (e) => {
    e.stopPropagation();
  };

  const handleWheel = (e) => {
    e.stopPropagation();
  };

  return (
    <Popover 
      overlayClassName="biomarker-layover"
      open={open}
      trigger="click"
      onOpenChange={setOpen}
      overlayStyle={{
        maxWidth: width < Breakpoint.SM ? '100vw' : 700,
        width: width < Breakpoint.SM ? '100vw' : 'auto',
        height: width < Breakpoint.SM ? '100vh' : 'auto',
        margin: width < Breakpoint.SM ? 0 : undefined,
        padding: width < Breakpoint.SM ? 0 : undefined,
      }}
      content={(
        <div 
          className="biomarker-popover"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onWheel={handleWheel}
        >
          <div className="biomarker-popover-header">
            <Paragraph className="biomarker-title">
              The Heart Health Panel
            </Paragraph>
            <CloseOutlined 
              onClick={() => setOpen(false)}
              className="close-popover-icon"
            />
          </div>

          <Table
            pagination={false}
            dataSource={biomarkers}
            size='small'
            rowKey='name'
            showHeader={false}
          >
            <Column
              dataIndex='name'
              render={name => <Paragraph className="biomarker-name">{name}</Paragraph>}
              width={150}
            />
            <Column
              dataIndex='description'
              render={name => <Paragraph className="biomarker-description">{name}</Paragraph>}
            />
          </Table>
        </div>
      )}
      placement='right'
    >
      {trigger}
    </Popover>
  )
}