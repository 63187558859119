import UtilHelper from "./util.helper.js"
import { RiskLevel } from "../enums/index.enum.js"

const ResultHelper = {
  getProgressData: (result, ignoreCalculated=false) => {
    if (!result) return
    const { tests, values } = result
    if (!tests?.length || !values?.length) return {
      incompleteTests: [],
      percentageComplete: 100
    }

    // Check if result is more than 1 month old
    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    
    if (new Date(result.collectedAt) < oneMonthAgo) {
      return {
        incompleteTests: [],
        percentageComplete: 100
      }
    }
  

    const targetTests = tests
    const completeTestIds = values?.filter(({ value }) => value).map(({ test }) => (test?._id || test)) || []
    const incompleteTests = targetTests
      ?.filter(test => !completeTestIds.includes(test?._id || test))
      ?.filter(test => !ignoreCalculated || !test.isCalculated)
    const percentageComplete = Math.round((1 - (incompleteTests?.length || 0) / (
      ignoreCalculated 
        ? targetTests?.filter(test => !test.isCalculated)?.length 
        : targetTests?.length
    )) * 100)

    return {
      incompleteTests,
      percentageComplete,
    }
  },
  getDirection: (testCode, results, tests) => {

  
    if (!results || !results.length || !tests || !tests.length) return null;
    
    const test = tests.find((t) => t.code === testCode);
    if (!test) return null; // Test code not found

    // Filter results by the testCode and sort them by date (assuming results have a 'date' field)
    const sortedResults = results
    .filter(({ values }) => values.some(({ test: valueTest }) => valueTest.toString() === test._id.toString()))
    .sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt)); 
    if (sortedResults.length < 2) return 'static'; // If there are not enough results to compare, return 'static'

    // Get the first and last result values for the specified test
    const firstResult = sortedResults[0].values.find(({ test: valueTest }) => valueTest.toString() === test._id.toString());
    const lastResult = sortedResults[sortedResults.length - 1].values.find(({ test: valueTest }) => valueTest.toString() === test._id.toString());
  
    if (!firstResult || !lastResult) return null; // Ensure the values exist for comparison
  
    const firstValue = parseFloat(firstResult.value);
    const lastValue = parseFloat(lastResult.value);
  
    // Check if firstValue and lastValue are valid numbers
    if (isNaN(firstValue) || isNaN(lastValue)) return null;


    // Determine direction based on comparison
    if (lastValue > firstValue) {
      return 'increasing';
    } else if (lastValue < firstValue) {
      return 'decreasing';
    } else {
      return 'static';
    }
  },
  hasCondition: (conditionCode, result) => {
    console.log(result)
    return result?.conditions?.find((code)=> {return code === conditionCode}) ? true : false
  },
  getDirectionV2: (testCode, results, tests) => {
    if (!results || !results.length || !tests || !tests.length || results.length < 2) {
      return { direction: null, improving: null };
    }
    
    const test = tests.find((t) => t.code === testCode);
    if (!test) {
      return { direction: null, improving: null };
    }
  
    const sortedResults = results
      .filter(({ values }) => values.some(({ test: valueTest }) => valueTest.toString() === test._id.toString()))
      .sort((a, b) => new Date(b.collectedAt) - new Date(a.collectedAt)); 
    if (sortedResults.length < 2) return { direction: 'static', improving: null }; 
  
    const firstResult = sortedResults[0].values.find(({ test: valueTest }) => valueTest.toString() === test._id.toString());
    const lastResult = sortedResults[1].values.find(({ test: valueTest }) => valueTest.toString() === test._id.toString());
  
    if (!firstResult || !lastResult) return { direction: null, improving: null };
  
    const firstValue = parseFloat(firstResult.value);
    const lastValue = parseFloat(lastResult.value);
  
    if (isNaN(firstValue) || isNaN(lastValue)) return { direction: null, improving: null };
  
    // Calculate percentage change
    const percentageChange = Math.abs((lastValue - firstValue) / firstValue) * 100;
    const threshold = firstValue < 5 ? 20 : 5; // 20% for small values, 10% for larger values

    // Determine direction with threshold
    let direction;
    if (percentageChange < threshold) {
      direction = 'static';
    } else {
      direction = firstValue > lastValue ? 'increasing' : 'decreasing';
    }

    // Replace the improving logic with the hasImproved function
    let improving = null;
    if (test.risk && Array.isArray(test.risk)) {
      improving = ResultHelper.hasImproved(test, firstValue, ResultHelper.getTestValueRisk(firstValue, test.risk), 
                                         lastValue, ResultHelper.getTestValueRisk(lastValue, test.risk));
    }
  
    return { direction, improving };
  },
  getTestValueRisk: (testValue, riskRange) => {

    let hasLessThanStr = false
    let hasMoreThanStr = false

    if (typeof testValue === "string" && testValue.includes("<")) {
      testValue = Number(testValue.replace(/</g, ""));
      hasLessThanStr = true // i.e. input is <300
    }
    else if (typeof testValue === "string" && testValue.includes(">")) {
      testValue = Number(testValue.replace(/>/g, ""));
      hasMoreThanStr = true
    }

    if (UtilHelper.isEmpty(testValue)) return null;

    const risks = riskRange?.filter(
      ({
        greaterThan,
        greaterThanOrEqual,
        lessThan,
        lessThanOrEqual,
        equal,
        level
      }) => {
        
        // Handle greaterThan comparisons
        if (greaterThan !== null && greaterThan !== undefined) {
          if (!hasMoreThanStr && testValue <= greaterThan) return false;
            if (hasMoreThanStr && testValue < greaterThan) return false;
        }
    
        // Handle greaterThanOrEqual comparisons
        if (greaterThanOrEqual !== null && greaterThanOrEqual !== undefined) {
          if (testValue < greaterThanOrEqual) return false;
        }
    
        // Handle lessThan comparisons
        if (lessThan !== null && lessThan !== undefined) {
          if (hasLessThanStr && testValue > lessThan) return false;
          if (!hasLessThanStr && testValue >= lessThan) return false;
        }
    
        // Handle lessThanOrEqual comparisons
        if (lessThanOrEqual !== null && lessThanOrEqual !== undefined) {
          if (testValue > lessThanOrEqual) return false;
        }
    
        // Handle equality check including when equal is 0
        if (equal !== null && equal !== undefined) {
          if (testValue.toString() === equal.toString()) return true;

          if (
              (typeof testValue === 'number' || typeof equal === 'number') && testValue !== equal
          ) return false;
          if (
            typeof testValue === 'string' &&
            typeof equal === 'string' &&
            equal.toLowerCase() !== testValue.toLowerCase()
          ) return false;
        }

        
        return true;
      }
    );

    if (!risks?.length) return RiskLevel.UNKNOWN;
    return risks[0].level;
  },
  getLatestTest: (testCode, sortedResults, tests) => {
    if (!sortedResults || !tests) return null
    const t =  tests.find(({ code }) => code === testCode)
    return sortedResults.find(result => result.values.some(({ test }) => test === t._id))
  },
  getLatestTestValue: (testCode, sortedResults, tests) => {
    if (!sortedResults || !tests) return null
    const t =  tests.find(({ code }) => code === testCode)
    const latestResult = sortedResults.find(result => {
      return result.values.some(({ test, value }) => value && test === t._id)
    })
    if (!latestResult) return null
    const { value } = latestResult.values.find(({ test }) => test === t._id)
    return value
  },
  getLatestTestRisk: (testCode, sortedResults, tests) => {
    if (!sortedResults || !tests) return null
    const t =  tests.find(({ code }) => code === testCode)
    const latestResult = sortedResults.find(result => {
      return result.values.some(({ test, value }) => value && test === t._id)
    })
    if (!latestResult) return null
    const { risk } = latestResult.values.find(({ test }) => test === t._id)
    return risk
  },
  hasLongevityScore: (result) => {
    if (!result) return null;
    const panelIds = "67b26d84898cb79db3b8fd76"
    return result?.order?.panels?.some(panel => panel._id.toString() === panelIds)
  },
  hasImproved: (test, currentValue, currentRisk, prevValue, prevRisk) => {
    // Risk Hierarchy
    const riskValue = {
      [RiskLevel.OPTIMAL]: 0,
      [RiskLevel.MODERATE]: 1,
      [RiskLevel.ABNORMAL]: 1,
      [RiskLevel.HIGH]: 2,
    };

    if (!prevValue) return null;
    if (currentRisk !== prevRisk)
      return riskValue[currentRisk] < riskValue[prevRisk];

    // Find the optimal range for the test
    const optimal = test.risk.find(({ level }) => level === RiskLevel.OPTIMAL);
    if (!optimal) return null;

    const { lessThan, lessThanOrEqual, greaterThan, greaterThanOrEqual } = optimal;

    // Define the optimal range (if it exists)
    const optimalMin = greaterThan || greaterThanOrEqual;
    const optimalMax = lessThan || lessThanOrEqual;

    // Handle the case where lower values are better (e.g., LDL)
    if (optimalMax !== undefined && optimalMin === undefined) {
      return currentValue < prevValue;
    }

    // Handle the case where higher values are better (e.g., HDL)
    if (optimalMin !== undefined && optimalMax === undefined) {
      return currentValue > prevValue;
    }

    // Case when both are in the optimal range
    if (currentRisk === RiskLevel.OPTIMAL && prevRisk === RiskLevel.OPTIMAL) {
      // Calculate the 25th and 75th percentiles within the optimal range
      const range25th = optimalMin + 0.25 * (optimalMax - optimalMin);
      const range75th = optimalMin + 0.75 * (optimalMax - optimalMin);

      // Check if the current and previous values are within the 25th-75th percentile range
      const isCurrentInRange = currentValue >= range25th && currentValue <= range75th;
      const isPrevInRange = prevValue >= range25th && prevValue <= range75th;

      // If both current and previous values are within the 25th-75th range, no improvement or worsening
      if (isCurrentInRange && isPrevInRange) {
        return true;
      }

      // If the current value is closer to the range, it's improving
      const currentDiff = currentValue < range25th
        ? range25th - currentValue
        : currentValue > range75th
          ? currentValue - range75th
          : 0;

      const prevDiff = prevValue < range25th
        ? range25th - prevValue
        : prevValue > range75th
          ? prevValue - range75th
          : 0;

      return currentDiff < prevDiff;
    }

    // For values outside the optimal range, default behavior
    const currentDiff = Math.abs(currentValue - optimalMin) < Math.abs(currentValue - optimalMax)
      ? Math.abs(currentValue - optimalMin)
      : Math.abs(currentValue - optimalMax);

    const prevDiff = Math.abs(prevValue - optimalMin) < Math.abs(prevValue - optimalMax)
      ? Math.abs(prevValue - optimalMin)
      : Math.abs(prevValue - optimalMax);

    return currentDiff < prevDiff;
  },
}

export default ResultHelper