import { useState, useContext, useEffect } from 'react';
import { Affix, Button, Menu, Typography, message, Radio } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { Role, RecommendationStatus } from '../../enums/index.enum';
import { updateRecommendations } from '../../services/recommendation.service';
import { UserContext } from '../../contexts/user.context';
import './tableOfContents.scss';
const { Text } = Typography;


export const TableOfContents = ({ 
  sections, 
  recs, 
  patientId, 
  onUpdate, 
  setIsLoading,
  groupByGoal,
  setGroupByGoal 
}) => {
    const visibleSections = sections?.filter(section => {
        const recsArray = recs[(section.kind !== undefined ? section.kind : section.goalCategory)];
        return recsArray !== undefined && recsArray.length > 0;
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedKey, setSelectedKey] = useState(visibleSections[0]?.id);
    const { currentUser } = useContext(UserContext)
    
    const showUpdateButton = process.env.NODE_ENV === 'development' || 
                           currentUser?.role === Role.ADMIN;


    useEffect(() => {
        const handleScroll = () => {
            const headerOffset = 100; // Adjust based on your header height
            const sectionElements = visibleSections.map(section => ({
                id: section.id,
                element: document.getElementById(section.id)
            })).filter(item => item.element);

            // Find the section that's currently in view
            const currentSection = sectionElements.find(({ element }) => {
                const rect = element.getBoundingClientRect();
                return rect.top <= headerOffset && rect.bottom > headerOffset;
            });

            if (currentSection) {
                setSelectedKey(currentSection.id);
            } else if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
                // If we're at the bottom of the page, select the last section
                setSelectedKey(sectionElements[sectionElements.length - 1]?.id);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check

        return () => window.removeEventListener('scroll', handleScroll);
    }, [visibleSections]);


    const menuItems = visibleSections.map((section) => ({
      key: section.id,
      label: section.tocTitle || section.title,
      onClick: () => {
        const element = document.getElementById(section.id);
        if (element) {
          const headerOffset = 80; // Adjust this value based on your header height
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
        return false;
      }
    }));


    const handleUpdate = async () => {
      setIsUpdating(true);
      setIsLoading(true);
      try {
        const filter = {patient: patientId, status: {$ne: RecommendationStatus.INACTIVE}}
        const updatedRecommendations = await updateRecommendations({filter: filter});
        onUpdate(updatedRecommendations);
      } catch (err) {
        const errorMessage = err.message === 'Network Error' 
          ? 'Issue updating game plan. Please try again.'
          : 'Failed to update game plan. Please try again.';
        message.error(errorMessage);
      } finally {
        setIsUpdating(false);
        setIsLoading(false);
      }
    }

    return (
        <div className="gameplan-table-of-contents">
            <Affix>
                <div className="toc-container">
                    <div className="toc-header">
                        <Text strong className="toc-title">Game Plan</Text>
                        {showUpdateButton && (
                            <Button 
                                onClick={handleUpdate}
                                loading={isUpdating}
                                icon={<ReloadOutlined />}
                                type="text"
                                size="small"
                                title="Update Plan"
                            />
                        )}
                    </div>
                    
                    <div className="view-toggle">
                        <Radio.Group 
                            value={groupByGoal}
                            onChange={(e) => setGroupByGoal(e.target.value)}
                            buttonStyle="solid"
                            style={{ 
                                marginBottom: '24px',
                                width: '100%'
                            }}
                        >
                            <Radio.Button 
                                value={true} 
                                style={{ width: '50%', textAlign: 'center' }}
                            >
                                Goal View
                            </Radio.Button>
                            <Radio.Button 
                                value={false}
                                style={{ width: '50%', textAlign: 'center' }}
                            >
                                Action View
                            </Radio.Button>
                        </Radio.Group>
                    </div>

                    <Menu
                        mode="inline"
                        items={menuItems}
                        className="gameplan-menu"
                        selectedKeys={[selectedKey]}
                    />
                </div>
            </Affix>
        </div>
    );
};
