import "./results.scss";
import { ResultsTable } from '../resultsTable/resultsTable.component';
import { useState, useEffect, useContext } from "react";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { useSearchParams, useParams } from "react-router-dom";
import { Button, message, Menu, Tooltip, Dropdown, Spin, Space } from 'antd';
import { DownloadOutlined, LoadingOutlined, FilterOutlined, EditOutlined, EyeInvisibleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/user.context';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { addReportForPatient } from '../../services/patient.service';
import { SingleResultsTable } from "../singleResultsTable/singleResultsTable.component";
import AbsoluteCategoryCode from "../../enums/absoluteCategoryCode.enum.js";
import TestCode from "../../enums/testCode.enum.js";
import Role from "../../enums/role.enum.js";
import { ResultStatus } from "../../enums/index.enum.js";
import { listAnnotatedTests } from "../../services/test.service";
import { listAnnotatedResults } from "../../services/result.service";
import { getPatient } from "../../services/patient.service.js";
import { getMe } from "../../services/user.service.js";
import { listReports } from "../../services/report.service.js";
import { listGoals } from "../../services/goal.service.js";
import { listAbsoluteCategories } from "../../services/absoluteCategory.service.js";
import { TestResultsModal } from "../testResultsModal/testResultsModal.component";

const BLACKLIST = [
  TestCode.WEIGHT,
  TestCode.ANION_GAP,
  TestCode.TESTOSTERONE_TOTAL,
  TestCode.MPV,
  TestCode.SD_LDL,
  TestCode.TG_HDL,
  TestCode.BUN_CREATINE,
  TestCode.GRIP,
  TestCode.DEXA_TOTAL_LEAN_MASS,
  TestCode.DEXA_LEAN_MASS_ARMS,
  TestCode.DEXA_LEAN_MASS_LEGS,
  TestCode.ARACHIDONIC,
  TestCode.EPA,
  TestCode.DHA,
  TestCode.DPA,
  TestCode.PSA_FREE,
  TestCode.FREE_TESTOSTERONE_DIRECT,
  TestCode.ALBUMIN_GLOBULIN,
  TestCode.HEIGHT,
  TestCode.LYMPHOCYTES_PERCENTAGE,
  TestCode.MONOCYTES_PERCENTAGE,
  TestCode.BASOPHILS_PERCENTAGE,
  TestCode.EOSINOPHILS_PERCENTAGE,
  TestCode.NEUTROPHILS_PERCENTAGE,
];

const TESTORDER = [
  {
    absoluteCategory: AbsoluteCategoryCode.NUTRIENTS,
    order: [
      TestCode.VITAMIN_D,
      TestCode.VITAMIN_B1,
      TestCode.VITAMIN_B2,
      TestCode.VITAMIN_B6,
      TestCode.FOLATE,
      TestCode.VITAMIN_B12,
      TestCode.FERRITIN,
      TestCode.IRON_TOTAL,
      TestCode.TIBC,
      TestCode.IRON_SATURATION,
      TestCode.ZINC,
      TestCode.OMEGA_3_INDEX,
      TestCode.OMEGA_6_3,
      TestCode.ARACHIDONIC_EPA,
      TestCode.TRANSFAT_INDEX
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.KIDNEY,
    order:[
      TestCode.EGFR,
      TestCode.CYSTATIN_C,
      TestCode.CREATININE,
      TestCode.BUN,
      TestCode.UACR,
      TestCode.URINE_CREATININE,
      TestCode.URINE_ALBUMIN
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.THYROID,
    order: [
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.FREET3,
      TestCode.TPO_ANTIBODY,
      TestCode.THYROGLOBULIN_ANTIBODY
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.BLOOD_CELLS,
    order: [
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.PLATELET,
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIPOPROTEINS,
    order: [
      TestCode.APO_B,
      TestCode.LIPOPROTEIN_A,
      TestCode.NMR_LDL_P,
      TestCode.NMR_HDL_P,
      TestCode.NMR_SMALL_LDL_P,
      TestCode.NMR_LARGE_VLDL_P,
      TestCode.NMR_LARGE_HDL_P,
      TestCode.NMR_LDL_SIZE,
      TestCode.NMR_VLDL_SIZE,      
      TestCode.NMR_HDL_SIZE
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIPIDS,
    order: [
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.LDL_CHOLESTEROL_CALC,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.MUSCULOSKELETAL,
    order: [
      TestCode.DEXA_ALMI,
      TestCode.DEXA_FFMI,
      TestCode.DEXA_BMD_T_SCORE,
      TestCode.DEXA_BMD_Z_SCORE
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.INFLAMMATION,
    order: [
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS,
      TestCode.IMMATURE_GRANULOCYTE_COUNT,
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.HORMONES,
    order: [
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIVER,
    order: [
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.GGT,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.INDIRECT_BILIRUBIN,
      TestCode.GLOBULIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.ALBUMIN_GLOBULIN
    ]
  },
  {
  absoluteCategory: AbsoluteCategoryCode.IMMUNOLOGIC,
    order: [
      TestCode.ANA_SCREEN,
      TestCode.ANA_TITER,
      TestCode.ANA_PATTERN,
      TestCode.COMPLEMENT_TOTAL,
      TestCode.COMPLEMENT_C3,
      TestCode.COMPLEMENT_C4
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.METABOLISM,
    order: [
      TestCode.NMR_LP_IR_SCORE,
      TestCode.HOMAIR,
      TestCode.TGI,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.CARDIAC,
    order: [
      TestCode.CCTA_TOTAL_PLAQUE,
      TestCode.CCTA_TOTAL_NONCALCIFIED_PLAQUE,
      TestCode.CCTA_TOTAL_CALCIFIED_PLAQUE,
      TestCode.CCTA_TOTAL_PAV,
      TestCode.MAX_NARROWING_RCA,
      TestCode.MAX_NARROWING_LM,
      TestCode.MAX_NARROWING_LAD,
      TestCode.MAX_NARROWING_CIRCUMFLEX,
      TestCode.CAC,
      TestCode.CAC_LM,
      TestCode.CAC_LAD,
      TestCode.CAC_CIRCUMFLEX,
      TestCode.CAC_RCA
    ]
  }
];

// Lookup to manage the test ordering
const TESTORDER_LOOKUP = TESTORDER.reduce((acc, item) => {
  acc[item.absoluteCategory] = item.order.reduce((orderMap, code, index) => {
    orderMap[code] = index;
    return orderMap;
  }, {});
  return acc;
}, {});

export const Results = () => {
  const [filterValue, setFilterValue] = useState('all');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isTableView, setIsTableView] = useState(false); // Default to list view
  const { currentUser } = useContext(UserContext);
  const [goal, setGoal] = useState()
  const [user, setUser] = useState()
  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  const [reports, setReports] = useState()
  const [absoluteCategories, setAbsoluteCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const { patientId } = useParams()
  const [resultId, setResultId] = useState(null);
  const [isTestResultsModalOpen, setIsTestResultsModalOpen] = useState(false);

  useEffect(() => {
    fetchAbsoluteCategories();
  }, []);

  useEffect(() => {
    const filter = searchParams.get('filter') || 'all';
    setFilterValue(filter);
  }, [searchParams]);

  useEffect(() => {
    fetchUser()
  }, [patientId, currentUser])

  useEffect(() => {
    fetchPatientData()
  }, [user])

  const fetchAbsoluteCategories = async () => {
    const response = await listAbsoluteCategories()
    setAbsoluteCategories(response)
  }

  const fetchUser = async () => {
    if (!currentUser) return

    let params = {
      select: 'location isAthlete height',
      populate: []
    }

    if (currentUser.role === Role.PATIENT) {
      const response = await getMe(params)
      setUser(response)
    }  else if (patientId) {
      const response = await getPatient(patientId, params)
      setUser(response)
    }
  }


  const fetchPatientData = async () => {
    if (!user) return;

    setIsLoading(true)
    const testsResponse = await listAnnotatedTests({ patient: user._id })
    setTests(testsResponse)
    const resultsResponse = await listAnnotatedResults({ patient: user._id})
    setResults(resultsResponse)

    const goalsResponse = await listGoals({ patient: user._id })
    if (goalsResponse?.length) {
      setGoal(goalsResponse[0])
    }
    const fetchedReports = await listReports({
      filter: {
        patient: user._id,
        result: {
          $ne: null
        },
      },
      select: 'status healthScore healthPercentile',
      populate: [{
        path: 'result',
        select: 'collectedAt',
        populate: {
          path: 'order',
          select: 'panels'
        }
      }],
      sort: '-createdAt'
    })
    setReports(fetchedReports?.filter(({ result }) => result))

    setIsLoading(false)
  }


  const handleFilterChange = (value) => {
    setFilterValue(value);
    let params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setSearchParams({ ...params, filter: value });
  };

  // Download report for the patient
  const onDownload = async () => {
    try {
      const userId = [Role.ADMIN, Role.PROVIDER, Role.STAFF].includes(currentUser.role) && patientId ? patientId : currentUser._id;

      setIsDownloading(true);

      const location = await addReportForPatient(userId);
      const filename = location.split('?')[0];

      const response = await axios.get(location, { responseType: 'blob' });
      fileDownload(response.data, filename);

      message.success('Results downloaded');
    } catch (error) {
      message.error('Failed to download results');
    } finally {
      setIsDownloading(false);
    }
  };



  const filterOptions = [
    { key: 'all', label: 'Show All' },
    { key: 'last', label: 'Last Test Date' },
    { key: 'high-risk', label: 'At Risk' },
    { key: 'moderate-risk', label: 'Borderline' },
    { key: 'low-risk', label: 'Optimal' },
  ];
  

  const menu = (
    <Menu
      onClick={(e) => handleFilterChange(e.key)}
      items={filterOptions.map(option => ({
        key: option.key,
        label: option.label,
      }))}
    />
  );
  
  const select = '_id status patient order hasRedraw fasting collectedAt values tests'
  const populate = [{
    path: 'patient',
    select: '_id firstName lastName'
  }, {
    path: 'order',
    select: 'panels'
  }, {
    path: 'tests',
    select: '_id name isCalculated'
  }]

  // Add this function to handle edit selection
  const handleEditResult = (resultId) => {
    setResultId(resultId);
    setIsTestResultsModalOpen(true);
  };

  // Create edit menu items from results
  const editMenuItems = [
    {
      key: 'new',
      icon: <PlusOutlined />,
      label: 'Add New Result',
      onClick: () => {
        setResultId(null);
        setIsTestResultsModalOpen(true);
      }
    },
    {
      type: 'divider'
    },
    ...results?.map(result => ({
      key: result._id,
      label: (
        <Space>
          {new Date(result.collectedAt).toLocaleDateString()}
          {result.status !== ResultStatus.APPROVED && <EyeInvisibleOutlined />}
        </Space>
      ),
      onClick: () => handleEditResult(result._id)
    })) || []
  ];

  const editMenu = (
    <Menu items={editMenuItems} />
  );

  return currentUser && (
    <div className="results">
      {isDownloading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div style={{
            padding: '20px',
            borderRadius: '8px',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            gap: '12px'
          }}>
            <LoadingOutlined />
            <span>Downloading results...</span>
          </div>
        </div>
      )}

      {[Role.ADMIN].includes(currentUser.role) && (
        <TestResultsModal
          patientId={patientId}
          resultId={resultId}
          setResultId={setResultId}
          open={isTestResultsModalOpen}
          setOpen={setIsTestResultsModalOpen}
        select={select}
        populate={populate}
        onSuccess={(result) => {
          // Update results state after successful addition/edit
          if (results.some(r => r._id === result._id)) {
            setResults(results.map((p) => (p._id === resultId ? result : p)));
          } else {
            setResults([result, ...results]);
          }
          }}
        />
      )}

      <PageHeader 
        title={
          <Space>
            {/* {getTitle()}   */}
            {results?.length > 1 ? (
              <Button.Group>
                <Button type={isTableView ? 'primary' : 'default'} onClick={() => setIsTableView(true)}>
                  All Results
                </Button>
                <Button type={!isTableView ? 'primary' : 'default'} onClick={() => setIsTableView(false)}>
                  Latest Values
                </Button>
              </Button.Group>
            ): "Test Results"}
          </Space>
        }
        actions={[
          <div className="actions-container" key="actions">
            <Space>
              {/* Dropdown for filtering results */}
              <Tooltip title={"Filter Results"}>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className={`filter-btn ${filterValue !== 'all' ? 'active-filter' : ''}`}>
                    <FilterOutlined />
                  </Button>
                </Dropdown>
              </Tooltip>
            </Space>

            <Tooltip title="Download your test results">
              <Button   
                type='default' 
                className="download-btn"
                onClick={onDownload}
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>



            {/* Add Edit Results dropdown button */}
            {[Role.ADMIN].includes(currentUser.role) && (
              <Tooltip title="Edit Existing Results">
                <Dropdown 
                  overlay={editMenu} 
                  trigger={['click']}
                >
                  <Button className="edit-results-btn" type="primary">
                    <EditOutlined />
                  </Button>
                </Dropdown>
              </Tooltip>
            )}

          </div>
        ]}
      />

      {/* Conditionally render either SingleResultsTable or ResultsTable */}
      {isLoading ? (
        <div className="loading">
          <div className="loading-text">
            <Spin /> &nbsp;&nbsp;Loading
          </div>
        </div>
      ) : isTableView ? (
        <ResultsTable 
          blacklist={BLACKLIST}
          testorderLookup = {TESTORDER_LOOKUP}
          filterValue={filterValue}
          tests={tests}
          results={results}
          reports={reports}
          patientId={patientId}
          goal={goal}
          user={user}
          absoluteCategories={absoluteCategories}
         />
      ) : (
        <SingleResultsTable 
          blacklist={BLACKLIST}
          testorderLookup = {TESTORDER_LOOKUP}
          filterValue={filterValue}
          tests={tests}
          results={results}
          reports={reports}
          patientId={patientId}
          absoluteCategories={absoluteCategories}
        />
      )}
    </div>
  );
};