import { TestChartDrawer }  from '../testChartDrawer/testChartDrawer.component';
import { useState, useEffect } from "react";
import LowercaseTest from "../../enums/lowercaseTest.enum";

export const TestLink = ({testCode, report, tests, results, reports, lowercase=false, content=null}) => {
    
    const [openModal, setOpenModal] = useState(false);
    const [testId, setTestId] = useState(null);
    const [testName, setTestName] = useState(null);

    const handleTestLink = (testCode) => {
        setTestId(tests.find((t) => t.code.toString() === testCode.toString())._id);
        setOpenModal(true);
    }

    useEffect(() => {
        setTestName(tests.find((t) => t.code.toString() === testCode.toString())?.name || '');
    }, [testCode]);
    

    return (
    testName && <>

        <TestChartDrawer
            open={openModal}
            setOpen={setOpenModal}
            test={tests.find(test => test?._id === testId)}
        />


        <a href="#" className="test-link" onClick={(e) => {
            e.preventDefault();
            handleTestLink(testCode);
        }}>{content ? content : (lowercase ? LowercaseTest[testCode] : testName)}</a>
      </>
    )
};