import { Typography } from "antd";
import classNames from "classnames";
const { Paragraph } = Typography

export const ExerciseRecoveryPost = () => {
  return (
    <div className={classNames("blog-post", "exercise-post")}>
      <div className="blog-border" /><br/><br/>

      <div className="blog-body">
        <Paragraph className="blog-title">
          Exercise Without Proper Nutrition: A Half-Built Foundation
        </Paragraph>
        <Paragraph className="blog-description">
          Think of exercise as the <i>trigger</i> for physiological change, but nutrition as the <i>fuel</i> that makes it possible. Without proper nutrient intake, the body cannot recover, adapt, or build resilience to aging. Here's what happens when diet fails to support exercise:
        </Paragraph>

        <Paragraph className="blog-title">
          Muscle Breakdown Instead of Growth
        </Paragraph>
        <Paragraph className="blog-description">
          Exercise, particularly strength training, induces microtears in muscle fibers. This is a natural process that leads to muscle growth—but only if the body has adequate protein and essential amino acids to rebuild those fibers.
        </Paragraph>
        <Paragraph className="blog-description">
          Studies show that failing to consume enough protein post-workout leads to muscle catabolism, where the body breaks down existing muscle for energy rather than repairing and strengthening it. Over time, inadequate protein intake can make strength training ineffective and even contribute to muscle loss, particularly in older adults.
        </Paragraph>

        <Paragraph className="blog-title">
          Reduced Mitochondrial Efficiency and Energy Production
        </Paragraph>
        <Paragraph className="blog-description">
          Mitochondria—the tiny powerhouses of cells—are responsible for converting food into usable energy (ATP). Exercise increases mitochondrial biogenesis, meaning the body builds more mitochondria to improve endurance and metabolic function. However, this process is heavily reliant on proper macronutrient and micronutrient intake.
        </Paragraph>
        <Paragraph className="blog-description">
          A study in Cell Metabolism found that individuals with poor dietary habits, such as insufficient omega-3s, vitamin D, and magnesium, had reduced mitochondrial function, making their exercise efforts significantly less effective. Without these essential nutrients, energy production becomes compromised, leading to chronic fatigue, slower recovery, and diminished endurance.
        </Paragraph>

        <Paragraph className="blog-title">
          Chronic Inflammation and Increased Oxidative Stress
        </Paragraph>
        <Paragraph className="blog-description">
          Exercise produces free radicals—unstable molecules that can cause oxidative damage. While some oxidative stress is beneficial for adaptation, excessive free radical production without adequate antioxidant intake can lead to chronic inflammation and accelerate cellular aging. Nutrient-dense foods like leafy greens, berries, and nuts help neutralize these effects, reducing inflammation and supporting long-term recovery.
        </Paragraph>

        <Paragraph className="blog-title">
          The Role of Sleep and Recovery: The Overlooked Pillar of Longevity
        </Paragraph>
        <Paragraph className="blog-description">
          Recovery isn't just about taking rest days—sleep is when the real work of repair and adaptation happens. Without adequate sleep, the body struggles to fully reap the benefits of exercise, leading to slower recovery, impaired metabolism, and even increased fat retention.
        </Paragraph>

        <Paragraph className="blog-title">
          Impaired Muscle Growth and Recovery
        </Paragraph>
        <Paragraph className="blog-description">
          Growth hormone and testosterone, both essential for muscle repair and metabolic health, are primarily released during deep sleep. Studies have shown that individuals who are sleep-deprived experience significantly lower muscle protein synthesis rates, making it harder to rebuild muscle after workouts. Even with regular exercise, inadequate sleep can prevent muscle recovery, leaving the body in a constant state of breakdown rather than growth.
        </Paragraph>

        <Paragraph className="blog-title">
          Higher Cortisol Levels and Metabolic Dysfunction
        </Paragraph>
        <Paragraph className="blog-description">
          Cortisol, the body's primary stress hormone, naturally rises during exercise. However, when sleep is inadequate, cortisol levels remain elevated, leading to increased fat storage, particularly around the abdomen. Elevated cortisol also suppresses immune function, leaving the body more susceptible to illness and reducing overall exercise benefits. Poor sleep combined with high cortisol levels can lead to metabolic dysfunction, making it harder to maintain a healthy weight and optimize physical performance.
        </Paragraph>

        <Paragraph className="blog-title">
          Building a Holistic Approach to Longevity
        </Paragraph>
        <Paragraph className="blog-description">
          At Instalab, we advocate for a balanced approach to longevity—where exercise, nutrition, and recovery work together for optimal health. While working out is crucial, failing to support it with proper diet and sleep can reduce or even reverse its benefits.
        </Paragraph>
        <Paragraph className="blog-description">
          To truly maximize longevity, focus on:
        </Paragraph>
        <ul className="blog-list">
          <li><b>Adequate protein intake</b> – To support muscle repair and growth.</li>
          <li><b>Micronutrient-rich foods</b> – To enhance mitochondrial function and energy production.</li>
          <li><b>Healthy fats and antioxidants</b> – To combat inflammation and oxidative stress.</li>
          <li><b>Prioritizing sleep</b> – To improve recovery, hormone regulation, and metabolic health.</li>
        </ul>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Roberts, J., Zinchenko, A., Suckling, C., Smith, L., Johnstone, J., & Henselmans, M. (2017). The short-term effect of high versus moderate protein intake on recovery after strength training in resistance-trained individuals. Journal of the International Society of Sports Nutrition, 14. <a onClick={() => window.open('https://doi.org/10.1186/s12970-017-0201-z', '_blank')}>https://doi.org/10.1186/s12970-017-0201-z</a>
            </li>
            <li>
              Mazzulla, M., Kato, H., Packer, J., Wooding, D., & Moore, D. (2017). Whole Body Net Protein Balance Plateaus in Response to Varying Protein Intakes During Post‐Exercise Recovery: Identification of a Maximal Anabolic Intake. The FASEB Journal, 31. <a onClick={() => window.open('https://doi.org/10.1096/fasebj.31.1_supplement.652.10', '_blank')}>https://doi.org/10.1096/fasebj.31.1_supplement.652.10</a>
            </li>
            <li>
              Cintineo, H., Arent, M., Antonio, J., & Arent, S. (2018). Effects of Protein Supplementation on Performance and Recovery in Resistance and Endurance Training. Frontiers in Nutrition, 5. <a onClick={() => window.open('https://doi.org/10.3389/fnut.2018.00083', '_blank')}>https://doi.org/10.3389/fnut.2018.00083</a>
            </li>
            <li>
              Reis, C., Loureiro, L., Roschel, H., & Da Costa, T. (2020). Effects of pre-sleep protein consumption on muscle-related outcomes - A systematic review. Journal of science and medicine in sport. <a onClick={() => window.open('https://doi.org/10.1016/j.jsams.2020.07.016', '_blank')}>https://doi.org/10.1016/j.jsams.2020.07.016</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};