import { Modal, List, Collapse, Row, Col, Tag } from 'antd';
import { Gender } from '../../enums/index.enum.js';
import "./testPanelModal.scss"

const TestPanelModal = ({ 
  visible, 
  onClose, 
  testDetails,
  patient,
  title="Biomarkers Included"
}) => {
  



  return (
    <Modal
      title={title}
      open={visible}
      onCancel={onClose}
      footer={null}
      className="test-panel-modal"

    >
      <List
        dataSource={testDetails}
        className="test-panel-list"
        rowKey={item => item?._id || Math.random().toString(36)}
        renderItem={item => {
          if (item.isPanel) {

            if (item.hasSexSpecificTests) {
              if (patient.gender === Gender.MALE) {
                item.tests = item.maleTests
              } else {
                item.tests = item.femaleTests
              }
            }
            return (
              <Collapse
                expandIconPosition="end"
              >
                <Collapse.Panel
                  header={
                   
                      <Row justify="space-between" align="middle">
                        <Col>
                          <div className="test-name">
                            {item.name}

                            <Tag color="default" style={{ marginLeft: 16 }}>

                            {item.tests.length} biomarker{item.tests.length > 1 ? 's' : ''}
                          </Tag>
                    

                          </div>
                          <div className='test-tagline'>
                            {item.tagline}
                          </div>
                        </Col>
                        <Col>
                        </Col>
                      </Row>
                
                  }
                  key={`collapse-${item?._id}`}
                  className="test-panel"
                >
                  <List
                    size="small"
                    dataSource={item.tests}
                    rowKey={test => test._id}
                    renderItem={test => (
                      <List.Item className="test-list-item">
                        <div>
                          <div className="test-name">
                            {test.name}
                          </div>
                          <div className="test-tagline">
                            {test.tagline}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              </Collapse>
            );
          }
          return (
            <List.Item 
                className="single-test-item"
                key={item._id}
            >
              <div>
                <div className="test-name">
                  {item.name}
                </div>
                <div className="test-tagline">
                  {item.tagline}
                </div>
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default TestPanelModal;