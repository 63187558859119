import { Typography } from "antd";
import "./anemiaAdvice.scss";
import { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { TestModal } from "../../testModal/testModal.component";
import { TestView } from "../testView/testView.component";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import QuestionTemplateCode from "../../../enums/questionTemplateCode.enum";
import { LowercaseTest, Supplement, Diet, Condition, ReportSection, TestCode } from "../../../enums/index.enum"
import { TestLink } from "../testLink/testLink.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

const primaryCodes = [
  TestCode.RBC,
  TestCode.HEMOGLOBIN,
  TestCode.HEMATOCRIT,
  TestCode.MCH,
  TestCode.MCHC,
  TestCode.MCV,
  TestCode.RDW,
];

const seriousConditions = [Condition.SEVERE_ANEMIA, Condition.MODERATE_ANEMIA];

export const AnemiaAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [anemiaTests, setAnemiaTests] = useState();
  const [openModal, setOpenModal] = useState();
  const [testId, setTestId] = useState();
  const [hasAbnormalTrend, setHasAbnormalTrend] = useState();
  const [primaryTests, setPrimaryTests] = useState();
  const [improvedTests, setImprovedTests] = useState();
  const [fixedTests, setFixedTests] = useState();
  const [staticTests, setStaticTests] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState()

  // baseline or followup?
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();
  const [status, setStatus] = useState();
  const [lowFerritin, setLowFerritin] = useState();


  // BASELINE content
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();


  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, description, secondaryDescription, recs])

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.ANEMIA_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.ANEMIA_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.ANEMIA_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.ANEMIA_ADVICE));
  }, [report]);

  useEffect(() => {
    setStatus((isBetter || isWorse || isFixed || isStatic) ? "followup" : "baseline")
  }, [isBetter, isFixed, isWorse, isStatic])

  useEffect(() => {
    categorizeTests();
  }, [anemiaTests]);

  useEffect(() => {
    setImprovedTests(getImprovedTests());
    setFixedTests(getFixedTests());
    setStaticTests(getStaticTests());
    setAnemiaTests(getAnemiaTests());
    setLowFerritin(getLowFerritin());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
  }, [report, isBetter, isFixed, isWorse, isStatic]);

  useEffect(() => {
    setDescription(getDescription());
    setSecondaryDescription(getSecondaryDescription());
  }, [primaryTests, improvedTests, hasAbnormalTrend, report, tests, results, status, lowFerritin]);


  useEffect(() => {
    setHasAbnormalTrend(getHasAbnormalTrend());
  }, [report, tests, results, primaryTests]);

  const getImprovedTests = () => {
    if (!report || !tests) return null;
    const iTests = ReportHelper.getSectionImprovedTests(report, ReportSection.ANEMIA_ADVICE, tests)|| []
    return iTests.filter(({code})=>primaryCodes.includes(code))
  };

  const getFixedTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionFixedTests(report, ReportSection.ANEMIA_ADVICE, tests)|| [];
  };

  const getStaticTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionStaticTests(report, ReportSection.ANEMIA_ADVICE, tests)|| [];    
  }

  const getLowFerritin = () => {
    if (!report || !tests) return null;
    return report?.result?.conditions?.includes(Condition.LOW_FERRITIN)
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    if (!report) return null;

    const hasThalassemia = ReportHelper.hasCondition(report, Condition.THALASSEMIA)
    const { result } = report

    if (isFixed || isBetter) {
      return (
        <>
          Your <span>anemia</span> condition has <span>improved</span>.
        </>
      );
    }

    else if (hasThalassemia) {
      return <>You still have <span>{!result.conditions?.some((condition) => seriousConditions.includes(condition)) && "mild "} anemia</span>, a lower-than-normal level of healthy red blood cells, which is a hallmark of <span>thalassemia</span>.</>
    }


    else if (isWorse) {
      return (
        <>
          You have{" "}
          <span>
            {!result.conditions?.some((condition) => seriousConditions.includes(condition)) && "mild "} anemia
          </span>
          , a lower-than-normal level of healthy red blood cells.
        </>
      );
    }

    else if (isStatic) {
      return (
        <>You still have <span>mild anemia</span>, a lower-than-normal level of healthy red blood cells.</>
      )
    }

    return (
      <>
        You have{" "}
        <span>
          {!result.conditions?.some((condition) =>
            seriousConditions.includes(condition)
          ) && "mild "}
          anemia
        </span>
        , a lower-than-normal level of healthy red blood cells.
      </>
    );
  }

  const categorizeTests = () => {
    if (!anemiaTests) return;
    setPrimaryTests(anemiaTests.filter(({ code }) => primaryCodes.includes(code)));

  }


  const getAnemiaTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report,ReportSection.ANEMIA_ADVICE,tests);
  }

  const getHasAbnormalTrend = () => {
    if (!primaryTests || !tests || !results || !report) return null;
    for (const test of primaryTests) {
      if (ReportHelper.hasAbnormalTrend(test.code, report, results, tests)) {
        return true;
      }
    }
    return false;
  }

  const getIsMeatEater = () => {
    if (!report) return null;
    const diet = ReportHelper.getAnswer(report, QuestionTemplateCode.DIET);
    return diet?.includes(Diet.VEGAN) && !diet?.includes(Diet.VEGETARIAN);
  }

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)

    if (!report || !tests || !results || !primaryTests) return null;

    if (isFixed && fixedTests?.length && lowFerritin) {
      return <>That said, your ferritin levels are still not optimal which means we need to continue working on your iron deficiency with supplementation so that you further lower your risk of getting iron-deficiency anemia again.</>
    } else if (isBetter && staticTests?.length>0) {
      return <>{staticTests?.length === 1
        ? <>That said, your {LowercaseTest[staticTests[0].code]} levels </>
        : <>That said, these {converter.toWords(staticTests?.length)} biomarkers </>}
        have experienced a slight shift, though not enough to be considered clinically significant. It's important to closely monitor {staticTests?.length > 1 ? "these levels" : "this"} despite the positive changes in your overall anemia condition.</>
    }
  }

  const getDescriptionContent = (report, hasAbnormalTrend) => {
    const hasLowFerritin = report.result?.conditions?.includes(Condition.LOW_FERRITIN);
    const hasB12DeficiencyAnemia = report.conditions?.includes(Condition.B12_DEFICIENCY_ANEMIA);
    const hasIronDeficiencyAnemiaMale = ReportHelper.hasCondition(report, Condition.IRON_DEFICIENCY_ANEMIA_MALE);
    const hasIronDeficiencyAnemia = report.result?.conditions?.includes(Condition.IRON_DEFICIENCY_ANEMIA_FEMALE) || hasIronDeficiencyAnemiaMale;
    const { patient } = report;
    const isMeatEater = getIsMeatEater();
    const hasFerritinSupplement = patient.currentSupplements?.includes(Supplement.IRON);
    const hasVitaminB12Supplement = patient.currentSupplements?.includes(Supplement.VITAMIN_B12);

    let content = null;

    if (hasIronDeficiencyAnemiaMale) {
      content = (
        <>
          This likely stems from iron deficiency as evidenced by your{" "}
          {renderTestLink(tests.find(({ code }) => code === TestCode.FERRITIN), "low ferritin")} levels. 
          Ferritin is essential for iron storage in cells
          {!isMeatEater && <>, and its deficiency could be related to your vegetarian diet, which can sometimes lead to insufficient iron intake</>}.
          
          {hasFerritinSupplement ? (
            ` Stick with taking your iron supplement until you reach an optimal level.`
          ) : (
            <>
              <br />
              <br />
              To address this, iron supplementation is recommended. If you experience symptoms such as dizziness or consistent fatigue, it's important to seek further guidance from your physician.
            </>
          )} 
          
          <br/><br/>Moreover, given your age, we also recommend undergoing a fecal test, which would rule out gastrointestinal bleeding as a potential underlying cause.
        </>
      );
    } 
  

    else if (hasIronDeficiencyAnemia) {
      content = (
        <>
          This likely stems from iron deficiency as evidenced by your{" "}
          {renderTestLink(tests.find(({ code }) => code === TestCode.FERRITIN), "low ferritin")}, a
          protein that stores iron in your cells and helps maintain healthy
          cells.
          {hasFerritinSupplement ? (
            ` Stick with taking your iron supplement until you reach an optimal level.`
          ) : (
            <>
              <br />
              <br />
              It's recommended you supplement with iron. And if you ever feel
              dizzy or consistently tired or fatigued, consult your primary care
              provider for next steps.
            </>
          )}
        </>
      );
    } 

    else if (hasB12DeficiencyAnemia) {
      content = (
        <>
          Your anemia may be caused by your{" "}
          {renderTestLink(tests.find(({ code }) => code === TestCode.VITAMIN_B12), "vitamin B12")} deficiency, a vital nutrient that helps convert food into energy for
          your body and sustain healthy nerve cells.
          {isMeatEater &&
            ` It's common to have a low vitamin B12 level if you don't consume animal products.`}
          <br />
          <br />
          {hasVitaminB12Supplement
            ? `Keep up the momentum with your supplement regimen and check how your levels change in 3 months. `
            : `Luckily, you can easily increase your levels by taking a supplement. `}
          If you feel tired or dizzy, we recommend discussing this condition
          with your primary care provider.
        </>
      );
    } else if (!hasLowFerritin) {
      content = (
        <>
          Anemia is commonly caused by iron deficiency but your{" "}
          {renderTestLink(tests.find(({ code }) => code === TestCode.FERRITIN), "ferritin")} is not
          low.{" "}
        </>
      );
      if (hasAbnormalTrend) {
        content = (
          <>
            {content} Since this is a recurring issue, it's advisable to consult your primary care provider or hematologist to identify the underlying cause.
            
            {/* , as it may stem from thalassemia, a genetic
            disorder causing your body to not produce enough hemoglobin. */}
          </>
        );
      } else {
        content = (
          <>
            {content} It's recommended you schedule a follow-up blood test to
            monitor how this condition evolves. If you feel tired or dizzy, get
            in touch with your primary care provider.
          </>
        );
      }
    } else {
      content = (
        <>
          At the moment, the root cause of your anemia is uncertain. We will
          monitor how this condition evolves with a follow-up blood draw. If you
          feel tired or dizzy, you should check in with your primary care
          provider.
        </>
      );
    }

    return content
  }

  const renderTestLink = (test, content) => {
    if (!test) return null;
    return (
      <TestLink
        test={test}
        content={content}
        report={report}
        tests={tests}
        results={results}
        reports={reports} 
      />
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !tests || !results || !primaryTests) return null;

    const hasThalassemia = ReportHelper.hasCondition(report, Condition.THALASSEMIA)

    if (isFixed && fixedTests?.length) {
      return <>
          In your previous test, you had a lower-than-normal level of red blood
          cells. However,{" "}
          {improvedTests?.length === 1
            ? `your ${improvedTests[0].name} has`
            : `these ${converter.toWords(
                improvedTests?.length
              )} biomarkers have`}{" "}
          returned to an optimal level, suggesting your red blood cells have
          stabilized.
      </>
    }

    else if (hasThalassemia) {
      return <>
        In thalassemia, red blood cells are destroyed faster than made, causing anemia. Folic acid (vitamin B9) supplementation is recommended to help produce red blood cells. Symptoms like fatigue, weakness, pale or yellow skin, and shortness of breath should prompt a consultation with your physician for appropriate management.
      </>
    }

    else if (isBetter) {
      return (
        <>
          Anemia is when you have a lower-than-normal level of healthy red blood cells. Symptoms can include fatigue, weakness and shortness of breath.{" "}
          {improvedTests?.length === 1
            ? `Your ${LowercaseTest[improvedTests[0].code]} levels have `
            : `These ${converter.toWords(improvedTests?.length)} biomarkers have `}{" "}
          gotten better thus improving your anemia condition.
        </>
      )
    }
    
    return getDescriptionContent(report, hasAbnormalTrend)
    
  }

  return (
    user &&
    primaryTests && 
    status &&  (
      <div className="anemia-advice">
        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>

        {(isFixed && fixedTests?.length) ? (
          <TestView 
            user={user}
            report={report} 
            tests={fixedTests}
            results={results}
            reports={reports}
          />
        ) : (ReportHelper.hasCondition(report, Condition.THALASSEMIA)) ? (
          <TestView 
            user={user}
            report={report} 
            tests={anemiaTests}
            results={results}
            reports={reports}
          />
        ) : (isBetter) ? (
          <TestView 
            user={user}
            report={report} 
            tests={improvedTests}
            results={results}
            reports={reports}
          />  
        ) : ((isFixed || isBetter) && improvedTests?.length>0) ? (
          <TestView 
            user={user}
            report={report} 
            tests={improvedTests}
            results={results}
            reports={reports}
          />      
        ) : (!(isFixed || isBetter) && primaryTests?.length>0) ? (
          <TestView 
            user={user}
            report={report} 
            tests={primaryTests}
            results={results}
            reports={reports}
          />
        ) : null}

        {secondaryDescription && <>
          <Paragraph className="report-template-description">{secondaryDescription}</Paragraph>

          {(isFixed && fixedTests?.length && lowFerritin) ? (
            <TestView 
              user={user}
              report={report} 
              tests={anemiaTests.filter(({code})=> code === TestCode.FERRITIN)}
              results={results}
              reports={reports}
            />
          ) : (isBetter && staticTests?.length > 0) ? (
            <TestView 
              user={user}
              report={report} 
              tests={staticTests}
              results={results}
              reports={reports}
            />
          )  : null}
        </>}
      
        <AdviceSection 
          section={section}
          report={report}
          results={results}
          tests={tests}
          setTestId={setTestId}
          setOpenModal={setOpenModal}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
