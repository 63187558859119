import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import HeartHealthTestApproval from "./static/heartHealthTestApproval/heartHealthTestApproval.component"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow, updateFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"
import ProductHelper from "../../helpers/product.helper"
import { TassoPopover } from "../tassoPopover/tassoPopover.component.js"
import ConfirmAppointment from "./confirmAppointment.component.js"
import { getAvailability } from "../../services/schedule.service"
import { confirmAppointment, setShippingLocation,  releasePendingAppointment } from "../../services/appointment.service"
import PhlebType from "../../enums/phlebType.enum"
import TimerDisplay from "../timerDisplay/timerDisplay.component.js"

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  DRAW: 'draw',
  LOCATION: 'location',
  PSC: 'psc',
  SHIPPING: 'shipping',
  PAY: 'pay',
  SHIPPING_DRAW: 'shipping-draw',
  SCHEDULE: 'schedule',
  DELIVERY: 'delivery',
  RESCHEDULE: 'reschedule',
  CONFIRM: 'confirm',
}

export const HeartHealthTestFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [skeleton, setSkeleton] = useState()
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const [phlebType, setPhlebType] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, instalabMembership, flow, hasPass, initialUser, currentUser])

  useEffect(() => {

    const fetchMembershipTypes = async () => {
      const response = await listMembershipTypes({
        filter: {
          code: {
            $in: [
              MembershipTypeCode.LONGEVITY,
            ]
          }
        }
      })
      setMembershipTypes(response)
    }
    
    const fetchProductTypes = async () => {
      const response = await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.HEART_HEALTH_PANEL,
              ProductTypeCode.LAB_VISIT,
              ProductTypeCode.TASSO,
              ProductTypeCode.MOBILE_BLOOD_DRAW
            ]
          }
        }
      })
      setProductTypes(response)
    }

    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    const fetchPhlebType = async () => {
      if (!currentUser?.location || !flow) return
      const response = await getAvailability({
        startDate: new Date(),
        location: currentUser.location,
        flowId: flow._id
      })
      setPhlebType(response.phlebType)
    }
  
    fetchPhlebType()
  }, [currentUser, flow])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.HEART_HEALTH_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: "Create Account"
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is your biological sex?`,
        description: `We need this for your lab order for the test.`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },      
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DRAW,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.DRAW]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your blood collection option for the Heart Health Panel.`,
        buildFlow: true,
        onNextStep: (_, flow) => {
          if (['lab', 'mobile'].includes(flow?.collectionPreference)) {
            return Step.LOCATION;
          }
          else return Step.DELIVERY;
        },
        field: (() => {

          const tasso = getProductType(ProductTypeCode.TASSO)
          const labVisit = getProductType(ProductTypeCode.LAB_VISIT)
          const phleb = getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)

          return {
            name: 'collectionPreference',
            required: true,
            options: [
              {
                title: "Lab Visit",
                value: 'lab',
                description: "Visit a nearby patient service center to get your blood drawn. Note: Not available in AZ, HI, NJ, or RI.",
                price: ProductHelper.getTotalCostV2({
                  productTypes: [labVisit],
                  instalabMembership,
                  currentUser,
                  ignoreCredits: true
                }),
              },   
              {
                title: "At-Home Kit",
                value: 'tasso',
                description: <>Use the virtually painless <TassoPopover trigger={<a className="heart-description-link">Tasso+</a>} /> to self-collect from the comfort of your home and mail back to the lab.</>,
                price: ProductHelper.getTotalCostV2({
                  productTypes: [tasso],
                  instalabMembership,
                  currentUser,
                  ignoreCredits: true
                }),
              },   
              {
                title: "Mobile Draw",
                value: 'mobile',
                description: "We'll send a phlebotomist to your home or office to draw your blood.",
                price: ProductHelper.getTotalCostV2({
                  productTypes: [phleb],
                  instalabMembership,
                  currentUser,
                  ignoreCredits: true
                }),
                // disabled: phlebType === 'facility',
              },
            ] 
          }
        })(),
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.HEART_HEALTH_PANEL].includes(product.productType.code)).length > 0
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        onNextStep: () => {
          if (flow?.collectionPreference === 'lab') {
            return Step.PSC;
          }
          else return Step.SCHEDULE;
        },
        title: `Where are you located?`,
        description: () => {
          if (flow?.collectionPreference === 'lab') {
            return `We’ll find the nearest patient service center to this location.`
          }
          else return `A phlebotomist will come to this location to draw your blood.`
        },
        field: {
          name: 'location'
        },
        
      },

      [Step.PSC]: {
        type: StepType.FACILITY,
        nextStep: Step.SCHEDULE,
        buildFlow: true,
        multiple: false,
        productTypeCode: ProductTypeCode.LONGEVITY_PANEL, // HACK: just finds all facilities that can do blood draws
        title: `Select your preferred patient service center.`,
        description: `This is where you'll get your blood drawn.`,
        field: {
          name: 'facility',
          required: true,
        },
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY,
        title: `What's your delivery address?`,
        description: `We’ll send your test kit to this location.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.PAY,
        createPending: true,
        addPendingAppointment: true,
        title: `Schedule your blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.collectionPreference === 'mobile'
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })(),
      }, 

      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: (patient, flow) => {

          switch (flow?.collectionPreference) {
            case 'mobile':
              const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
              if (phlebType === PhlebType.GETLABS) {  
                return Step.SHIPPING_DRAW;
              }
              else return Step.CONFIRM;
            default:
              return Step.CONFIRM;
          }

        },
        addProduct: true,
        productType: [ProductTypeCode.HEART_HEALTH_PANEL, flow?.collectionPreference === 'tasso' ? ProductTypeCode.TASSO : flow?.collectionPreference === 'lab' ? ProductTypeCode.LAB_VISIT : ProductTypeCode.MOBILE_BLOOD_DRAW],
        title: () => {
          const tasso = getProductType(ProductTypeCode.TASSO)
          const labVisit = getProductType(ProductTypeCode.LAB_VISIT)
          const mobileDraw = getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)
          const heartHealthPanel = getProductType(ProductTypeCode.HEART_HEALTH_PANEL)

          const productTypes = [heartHealthPanel, flow?.collectionPreference === 'tasso' ? tasso : flow?.collectionPreference === 'mobile' ? mobileDraw : labVisit]

          const totalCost = ProductHelper.getTotalCostV2({
            productTypes: productTypes,
            instalabMembership,
            currentUser
          })

          return <>Pay <span className="true-price">${totalCost}</span> for the <span className="product-name">{heartHealthPanel.title}</span>.</>
        },
        description: `This includes the ${flow?.collectionPreference === 'tasso' ? 'Tasso+' : flow?.collectionPreference === 'lab' ? 'lab visit' : 'mobile draw'} for the blood collection and results within 1 week.`,
        header: () => {

          if (flow?.collectionPreference === 'mobile' || flow?.collectionPreference === 'lab') {
          
            const productTypeCodes = flow?.collectionPreference === 'mobile' ? [ProductTypeCode.HEART_HEALTH_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.HEART_HEALTH_PANEL, ProductTypeCode.LAB_VISIT]
            const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))
      
            return !hasPaid && (
              <div>
                <div className="timer-warning">
                  <div className="timer-warning-content">
                  Complete payment within{" "} 
                    <TimerDisplay 
                      start={flow?.appointment?.updatedAt} 
                      timeAllowed={5*60} 
                      onExpire={async () => {
                        await releasePendingAppointment(flow?.appointment?._id)
                        await updateFlow(flow?._id, {appointment: null})
                        setFlow(prevFlow => ({...prevFlow, appointment: null}))
                        navigate(`/flow/${FlowType.HEART_HEALTH_TEST}/${Step.SCHEDULE}/${flow?._id}`)
                      }} 
                    />{" "}
                  
                    to secure your appointment. 
                </div>  
                </div>
              </div>
            )
          }
          else return null
        },

        onSuccess: async () => {
          if (flow?.appointment) {  
            await confirmAppointment(flow?.appointment?._id, {flowId: flow?._id})
          }
        },
      },

      [Step.SHIPPING_DRAW]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `Where should we ship your test supplies kit?`,
        description: `You will receive a kit in the mail containing all the supplies needed for your phlebotomist to complete your blood draw.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient, flow) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }

            await setShippingLocation(flow?.appointment?._id, {shippingLocation: patient.shippingLocation})
            
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.RESCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.CONFIRM,
        addAppointment: true,
        title: `Reschedule your blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.collectionPreference === 'mobile'
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })(),
      }, 
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set!`,
        description: flow?.collectionPreference === 'tasso' ? `Your test kit will be shipped within 1 business day. Tracking info will be available shortly in your account.` : `Your appointment for your blood draw is confirmed.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: flow?.collectionPreference === 'tasso' ? <HeartHealthTestApproval/> : <ConfirmAppointment flow={flow}/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return  (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={0}
      // productTypeCode={flow?.collectionPreference === 'tasso' ? [ProductTypeCode.HEART_HEALTH_PANEL, ProductTypeCode.TASSO] : [ProductTypeCode.HEART_HEALTH_PANEL, ProductTypeCode.LAB_VISIT]}
    />
  )
}