import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import FlowType from "../../enums/flowType.enum"
import ConfirmInterest from "./confirmInterest.component"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { addSlack } from "../../services/slack.service"
import ConfirmAppointment from "./confirmAppointment.component"
import { getAvailability } from "../../services/schedule.service"
import PhlebType from "../../enums/phlebType.enum"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow, updateFlow } from "../../services/flow.service"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"
import { releasePendingAppointment, confirmAppointment } from "../../services/appointment.service"
import ProductHelper from "../../helpers/product.helper"
import { listTests } from "../../services/test.service"
import { listPanels } from "../../services/panel.service"
import PanelCode from "../../enums/panelCode.enum"
import TestPanelModal from "../testPanelModal/testPanelModal.component"
import TimerDisplay from "../timerDisplay/timerDisplay.component"
const PANELS = [
  PanelCode.CMP,
  PanelCode.LIPID,
  PanelCode.THYROID,
  PanelCode.ANEMIA,
  PanelCode.FERTILITY,
  PanelCode.HEART_HEALTH,
  PanelCode.TESTOSTERONE,
  PanelCode.OMEGA,
  PanelCode.NMR_LIPOPROFILE,
  PanelCode.CELIAC,
  PanelCode.CBC_DIFF,
  PanelCode.BLOOD_TYPE,
  PanelCode.HEAVY_METALS,
  PanelCode.LYME_AB_SCREEN,
  PanelCode.HEPATIC,
  PanelCode.INSULIN_RESISTANCE,
  PanelCode.THYROID_ANTIBODIES,
  PanelCode.ADVANCED_FOLLOWUP,
  PanelCode.ANA_SCREEN_REFLEX
]

export const Step = {
  ACCOUNT: 'account',
  TESTS: 'tests',
  LOCATION: 'location',
  INTEREST: 'interest',
  INTEREST_CONFIRMATION: 'interest-confirmation',
  DRAW: 'draw',
  PSC: 'psc',
  PAY: 'pay',
  SHIPPING: 'shipping',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  SCHEDULE: 'schedule',
  RESCHEDULE: 'reschedule',
  INVITE: 'invite',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation'
}

export const CustomTestFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [phlebType, setPhlebType] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const [tests, setTests] = useState()
  const [panels, setPanels] = useState()
  const [isTestModalOpen, setIsTestModalOpen] = useState(false)
  const [selectedTests, setSelectedTests] = useState([])


  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, flow, currentUser, phlebType, hasPass, initialUser, tests, panels])

  useEffect(() => {
    fetchPhlebType()
  }, [currentUser, flow])

  useEffect(() => {
    const fetchTests = async () => {
      const filter = {
        // cost: { $exists: true },
        name: { $exists: true }
      }
      const tests = await listTests({filter})
      setTests(tests)
    }

    const fetchPanels = async () => {
      const panels = await listPanels({filter: {code: { $in: PANELS}}})
      setPanels(panels)
    }

    const fetchProductTypes = async () => {
      const response = await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.CUSTOM_PANEL,
              ProductTypeCode.MOBILE_BLOOD_DRAW,
              ProductTypeCode.PRO_MOBILE_BLOOD_DRAW,
              ProductTypeCode.LAB_VISIT
            ]
          }
        }
      })
      setProductTypes(response)
    }


    fetchProductTypes()
    fetchTests()
    fetchPanels()
  }, [])


  const fetchPhlebType = async () => {
    if (!currentUser?.location || !flow) return
    const response = await getAvailability({
      startDate: new Date(),
      location: currentUser.location,
      flowId: flow._id
    })
    setPhlebType(response.phlebType)
  }


  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes || !tests || !panels) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.TESTS,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.CUSTOM_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'
      },


      [Step.TESTS]: {
        type: StepType.TEST_PICKER,
        title: (() => {
          if (flow?.testAddOns?.length) {
            const testCount = flow.testAddOns.length;
            return `You've selected ${testCount} test${testCount > 1 ? 's' : ''}. Would you like to add more?`;
          }
          return `What tests would you like to get done?`;
        })(),
        description: "Select tests below. Email concierge@instalab.com if you don't see what you want.",
        buildFlow: true,
        multiple: true,
        hasSkip: false,
        tests: tests,
        panels: panels,
        nextStep: Step.NAME,
        field: {
          name: 'testAddOns',
          required: true,
          defaultValue: flow?.testAddOns || [],
        },
        isLocked: () => {
          return flow?.products.length > 0
        },
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
    
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildFlow: true,
        onNextStep: async (patient, flow) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType;  
          return phlebType ? Step.DRAW : Step.INTEREST
        },
        title: `Where are you located?`,
        description: `You'll either visit a nearby patient service center, or your phlebotomist will come to this location your blood draw.`,
        field: {
          name: 'location'
        },
      },
      [Step.INTEREST]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INTEREST_CONFIRMATION,
        buildUser: true,
        title: `Apologies, we're not in your area yet...`,
        description: <>But if you leave your email, we'll notify you as soon as we are. You can also reach out to us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>.</>,
        field: {
          name: 'email',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        },
        onSuccess: async (patient) => {
          await addSlack({
            message: `${patient.email} interested: longevity test`,
            channel: process.env.REACT_APP_PATIENT_SLACK
          })
        },
      },
      [Step.INTEREST_CONFIRMATION]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You joined the waitlist.`,
        description: `When we launch in your area, we'll send you an email.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmInterest />
      },
      [Step.DRAW]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select your blood draw option.`,
        buildFlow: true,
        onNextStep: (patient, flow) => {
          if (flow?.prefersMobileDraw) {
            return Step.SCHEDULE;
          }
          else return Step.PSC;
        },
        field: (() => {
          const patient = flow?.user
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType;
          const noLabVisit = ['AZ','HI','NJ','RI'].includes(patient?.location?.state)

          const labCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.LAB_VISIT)],
            instalabMembership,
            currentUser,
          })

          const mobileCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)],
            instalabMembership,
            currentUser,
          })

          return {
            name: 'prefersMobileDraw',
            required: true,
            options: [
              {
                title: "Lab Visit",
                value: false,
                description: "Visit a nearby patient service center (select location in next step).",
                price: labCost,
                tag: noLabVisit && `Unavailable in ${currentUser?.location?.state}, but you can go to a neighboring state.`
              },             
              {
                title: "At-Home Draw",
                value: true,
                description: "A licensed phlebotomist will come to your home or office.",
                price: mobileCost,
                disabled: phlebType === PhlebType.FACILITY,
                tag: phlebType === PhlebType.FACILITY && "Unavailable in your area"
              },             
          ] 
        }})(),
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.MOBILE_BLOOD_DRAW].includes(product.productType.code)).length > 0
        },


      },
      [Step.PSC]: {
        type: StepType.FACILITY,
        nextStep: Step.SCHEDULE,
        buildFlow: true,
        multiple: false,
        productTypeCode: ProductTypeCode.CUSTOM_PANEL,
        title: `Select your preferred patient service center.`,
        description: `This is where you'll get your blood drawn.`,
        field: {
          name: 'facility',
          required: true,
        },
        skipIf: () => {
          return flow?.prefersMobileDraw
        }
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.PAY,
        createPending: true,
        addPendingAppointment: true,
        title: `Schedule your blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.prefersMobileDraw 
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })()
      },  
      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: ((patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          return phlebType === (flow?.prefersMobileDraw && PhlebType.GETLABS) ? Step.SHIPPING : Step.APPOINTMENT_CONFIRMATION          
        }),
        addProduct: true,
        productType: (() => { 
          const isMobile = flow?.prefersMobileDraw;
          return isMobile ? [ProductTypeCode.MOBILE_BLOOD_DRAW, ProductTypeCode.CUSTOM_PANEL] : [ProductTypeCode.CUSTOM_PANEL];
        })(),
        tests: tests,
        panels: panels,
        title: (() => {
          const panelTitle = getProductType(ProductTypeCode.CUSTOM_PANEL)?.title;
          const isMobile = flow?.prefersMobileDraw;

          const trueCost = ProductHelper.getTotalCostV2({
            productTypes: isMobile ? [getProductType(ProductTypeCode.CUSTOM_PANEL), getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)] : [getProductType(ProductTypeCode.CUSTOM_PANEL)],
            instalabMembership,
            currentUser,
            testAddOns: flow?.testAddOns,
            tests: tests,
            panels: panels
          })

          return (
            <>
              Pay <span className="true-price">{ProductHelper.formatPrice(trueCost)}</span> for your <span className="product-name">{panelTitle}</span>.
            </>
          )

        })(),
        description: (() => {
          const selectedTests = flow?.testAddOns?.map(testId => 
            tests?.find(t => t._id === testId) || 
            {
              _id: testId,
              name: panels?.find(p => p._id === testId)?.name,
              tagline: panels?.find(p => p._id === testId)?.tagline,
              isPanel: true,
              // TO DO: sex-based tess
              tests: panels?.find(p => p._id === testId)?.tests.map(testId => tests?.find(t => t._id === testId))
            }
          ).filter(Boolean);

          return <>
          This includes your <a 
            href="#" 
            className="secondary-link"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTests(selectedTests);
              setIsTestModalOpen(true);
            }}
          >
            {selectedTests.length} selected test{selectedTests.length > 1 ? 's' : ''}
          </a>, blood draw, and results report.
        </>
        })(),
        header: () => {
          const productTypeCodes = flow?.prefersMobileDraw ? [ProductTypeCode.CUSTOM_PANEL, ProductTypeCode.MOBILE_BLOOD_DRAW] : [ProductTypeCode.CUSTOM_PANEL]
          const hasPaid = flow?.products.length > 0 && productTypeCodes.every(productTypeCode => flow?.products.some(p => p.productType.code === productTypeCode))
    
          return !hasPaid && (
            <div>
              <div className="timer-warning">
                <div className="timer-warning-content">
                Complete payment within{" "} 
                  <TimerDisplay 
                    start={flow?.appointment?.updatedAt} 
                    timeAllowed={8*60} 
                    onExpire={async () => {
                      await releasePendingAppointment(flow?.appointment?._id)
                      await updateFlow(flow?._id, {appointment: null})
                      setFlow(prevFlow => ({...prevFlow, appointment: null}))
                      navigate(`/flow/${FlowType.LONGEVITY_TEST}/${Step.SCHEDULE}/${flow?._id}`)
                    }} 
                  />{" "}
                
                  to secure your appointment. 
              </div>  
              </div>
            </div>
          )
        },
        onSuccess: async () => {
          await confirmAppointment(flow?.appointment?._id, {flowId: flow?._id})
        },
      },

      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        title: `Where should we ship your test supplies kit?`,
        description: `You will receive a kit in the mail containing all the supplies needed for your phlebotomist to complete your blood draw.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },

   
      [Step.RESCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: `Reschedule your blood draw appointment.`,
        description: (() => { 
          const isMobile = flow?.prefersMobileDraw 
          return isMobile && (flow?.appointment?.phlebType  || flow?.user?.phlebType) === PhlebType.GETLABS ? 
              `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. ` :
              `The appointment takes about 10 minutes. You must fast 8 hours beforehand (water and black coffee are fine).`
            })()
      }, 

      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
        title: `Your blood draw appointment is confirmed!`,
        description: ((patient) => {
          const isMobile = flow?.prefersMobileDraw;
          return isMobile && flow?.appointment?.phlebType === PhlebType.GETLABS ? 
            <><u>You will receive the test supplies kit in the mail</u> containing all the supplies needed to complete your blood draw. A phlebotomist from our partner (Getlabs) will call or text you upon arrival on the day of your appointment.<br/><br/>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations.</> :
            <>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations.</>;
        })(),
        buttonText: 'Continue',
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }


  return <>
    <TestPanelModal
      visible={isTestModalOpen}
      onClose={() => setIsTestModalOpen(false)}
      testDetails={selectedTests}
    />
    
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={1}
    />
  </>
}