import { Modal, Form, InputNumber, message } from 'antd'
import { useState, useContext } from 'react'
import { CardForm } from '../cardForm/cardForm.component'
import { addProduct } from '../../services/product.service'
import ProductTypeCode from '../../enums/productTypeCode.enum'
import { UserContext } from '../../contexts/user.context'
import UserHelper from '../../helpers/user.helper'
import './addFundsModal.scss';

const AddFundsModal = ({ open, setOpen, onSuccess }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [activeCard, setActiveCard] = useState(null)
  const {setCurrentUser} = useContext(UserContext)

  const handleSuccess = async (card) => {
    try {
      const amount = form.getFieldValue('amount')
      if (!amount) {
        message.error('Please enter an amount')
        return
      }
      
      const fields = {
        priceOverride: amount,
        type: ProductTypeCode.CASH,
        stripeId: card.stripeId
      }

      const order = await addProduct({fields})

      if (order) {
        message.success('Funds added successfully')
        setOpen(false)
        form.resetFields()
        onSuccess(amount)
        const newUser = await UserHelper.refreshUser()
        setCurrentUser(newUser)
      
      }
    } catch (error) {
      message.error('Failed to add funds')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      destroyOnClose
    >
      <h2>Add Funds</h2>
      <Form form={form} layout="vertical" className="add-funds-form">
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: 'Please enter an amount' }]}
          className="single-input-item"
        >
          <InputNumber
            prefix="$"
            min={1}
            precision={2}
            className="single-input-field"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <CardForm
          onSuccess={handleSuccess}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          buttonText="Add Funds"
        />
      </Form>
    </Modal>
  )
}

export default AddFundsModal
