import React, { useState, useContext } from 'react';
import { Drawer, Button, message, Space } from 'antd';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/user.context';
import Role from '../../enums/role.enum';
import './testChartDrawer.scss';
import { TestChart } from '../testChart/testChart.component';
import AnnotationForm from '../annotationForm/annotationForm.component';
import { AnnotationList } from '../annotationList/annotationList.component';
import { removeAnnotation } from '../../services/annotation.service';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PanelDetail from '../panelDetail/panelDetail.component';

export const TestChartDrawer = ({ 
  open,
  setOpen,
  test,
  setTest,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [annotations, setAnnotations] = useState([]);
  const isAdminOrProvider = currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER;
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [drawerWidth, setDrawerWidth] = useState('80%');
  const [isMobileView, setIsMobileView] = useState(false);
  
  // Check if the item is a panel
  const isPanel = test?.itemType === 'panel';

  // Handle responsive drawer width
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
      
      if (window.innerWidth <= 768) {
        setDrawerWidth('100%');
      } 
      else if (window.innerWidth <= 1200 && (test?.cost > 0 || test?.productType)) {
        setDrawerWidth('90%');
      }
      else {
        setDrawerWidth('80%');
      }
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, [test]);

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedAnnotation(null);
    }
  }, [isModalVisible]);

  const handleAnnotationCreate = (annotation) => {
    setAnnotations([...annotations, annotation]);
  };

  const handleAnnotationUpdate = (annotation) => {
    setAnnotations(annotations.map(a => a._id === annotation._id ? annotation : a));
  };

  const handleAnnotationDelete = async (annotation) => {
    try {
      await removeAnnotation(annotation._id);
      setAnnotations(annotations.filter(a => a._id !== annotation._id));
      message.success('Annotation removed');
    } catch (error) {
      console.error('Failed to delete annotation:', error);
      message.error('Failed to delete annotation');
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedAnnotation(null);
    searchParams.delete('test');
    setSearchParams(searchParams);
  };

  // Custom close icon with left arrow
  const closeIcon = (
    <Button 
      className="drawer-back-button" 
      icon={<LeftOutlined />} 
      type="text"
      onClick={onClose}
    >
    </Button>
  );

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        open={open}
        width={drawerWidth}
        closeIcon={closeIcon}
        push={false}
        className={`test-chart-drawer ${test?.cost > 0 ? 'has-order-button' : ''} ${isPanel ? 'panel-drawer' : ''}`}
        extra={isAdminOrProvider && !isPanel ? (
          <Space>
            <AnnotationList
              test={test}
              annotations={annotations}
              onAnnotationUpdate={(a) => {
                setSelectedAnnotation(a);
                setIsModalVisible(true);
              }}
              onAnnotationDelete={handleAnnotationDelete}
            />
            <Button 
              type="primary" 
              icon={<PlusOutlined />}
              onClick={() => {
                setSelectedAnnotation(null);
                setIsModalVisible(true);
              }}
            >
              Add Annotation
            </Button>
          </Space>
        ) : null}
      >
        {isPanel ? (
          // Use the new PanelDetail component for panels
          <PanelDetail panel={test} isMobileView={isMobileView} />
        ) : (
          // Render regular test content with TestChart
          <TestChart
            id={test?._id}
            annotations={annotations}
            setAnnotations={setAnnotations}
          />
        )}
      </Drawer>

      {isAdminOrProvider && !isPanel && (
        <AnnotationForm
          annotationId={selectedAnnotation?._id}
          open={isModalVisible}
          setOpen={setIsModalVisible}
          test={test}
          onUpdate={handleAnnotationUpdate}
          onCreate={handleAnnotationCreate}
          onSuccess={() => {
            setIsModalVisible(false);
            setSelectedAnnotation(null);
          }}
        />
      )}
    </>
  );
};