import { Outlet } from 'react-router-dom';
import { Header } from '../header/header.component';
import { FooterV2 } from '../footerv2/footerv2.component';
import './defaultLayout.scss'

const DefaultLayout = () => {
  return (
    <>
      <Header hasSearch={true} />
      <Outlet />
      <FooterV2 />
    </>
  )
};

export default DefaultLayout