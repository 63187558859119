import { useContext } from "react";
import { Typography, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import { BlogData } from "../../../data/blog.data";
const { Paragraph } = Typography;

export const PCSK9InhibitorsPost = () => {
  const { currentUser } = useContext(UserContext)
  const navigate = useNavigate();
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "pcsk9i-post")}>
      <div className="blog-image-wrapper tall">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          How PCSK9 Inhibitors Work
        </Paragraph>

        <Paragraph className="blog-description">
          Liver cells utilize LDL receptors to clear LDL cholesterol from the bloodstream. However, the PCSK9 protein can bind to these receptors, marking them for destruction and reducing the number available to clear cholesterol. PCSK9 acts like a wrench thrown into the machinery of cholesterol clearance.
        </Paragraph>
        <Paragraph className="blog-description">
          Meanwhile, PCSK9 inhibitors effectively remove this "wrench," allowing LDL receptors to operate at full capacity. This enhances the liver's ability to clear LDL cholesterol from the blood, leading to the impressive results seen in clinical trials.
        </Paragraph>

        <Paragraph className="blog-title">
          A New Era in Cardiovascular Medicine
        </Paragraph>

        <Paragraph className="blog-description">
          This isn't just about effectiveness in lowering LDL cholesterol levels; it's about significantly reducing cardiovascular risks, and PCSK9 inhibitors excel in this regard.
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[{
            medication: 'Alirocumab (PCSK9-i)',
            trial: 'ODYSSEY LONG TERM',
            dosage: '150 mg every 2 weeks',
            reduction: '-61.9%',
            studyUrl: 'https://doi.org/10.1056/NEJMoa1501031'
          }, {
            medication: 'Evolocumab (PCSK9-i)',
            trial: 'FOURIER',
            dosage: '140 mg every 2 weeks',
            reduction: '-59%',
            studyUrl: 'https://doi.org/10.1056/NEJMoa1615664'
          }, {
            medication: 'Rosuvastatin',
            trial: 'JUPITER',
            dosage: '20 mg daily',
            reduction: '-50%',
            studyUrl: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC2999035'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column 
            title="Trial" 
            dataIndex="trial"
            render={(value, { studyUrl }) => (
              <a 
                onClick={() => window.open(studyUrl, '_blank')}
                className="blog-link"
              >
                {value}
              </a>
            )}
          />
          <Table.Column 
            title="Medication" 
            dataIndex="medication"
          />
          <Table.Column 
            title="Dosage" 
            dataIndex="dosage"
          />
          <Table.Column 
            title="Mean % Reduction in LDL Cholesterol" 
            dataIndex="reduction"
          />
        </Table>

        <Paragraph className="blog-description">
          These injectable medications have revealed a jaw-dropping effect on LDL cholesterol, achieving reductions of <b>more than 59%</b>. In clinical studies like the <a onClick={() => window.open('https://www.nejm.org/doi/10.1056/NEJMoa1801174', '_blank')} className="blog-link"><i>ODYSSEY OUTCOMES trial</i></a>, patients treated with PCSK9-i experienced a dramatic decline in median LDL cholesterol <b>from 92 mg/dL to an astonishing 30 mg/dL</b>, making it the most effective cholesterol-lowering medication available.
        </Paragraph>
        <Paragraph className="blog-description">
          The <a onClick={() => window.open('https://www.nejm.org/doi/10.1056/NEJMoa1801174', '_blank')} className="blog-link"><i>ODYSSEY OUTCOMES trial</i></a> revealed that alirocumab reduced the risk of major cardiovascular events—such as heart attacks and strokes—by <b>15%</b>, particularly in patients with LDL levels of 100 mg/dL or higher. Similarly, the <a onClick={() => window.open('https://doi.org/10.1056/NEJMoa1615664', '_blank')} className="blog-link"><i>FOURIER trial</i></a> demonstrated that evolocumab achieved a <b>15%</b> reduction in major cardiovascular risks and a <b>20%</b> reduction in cardiovascular death, heart attack, or stroke.
        </Paragraph>
        <Paragraph className="blog-description">
          These findings underscore a crucial insight: lowering LDL cholesterol below current targets, achievable with PCSK9 inhibitors, can lead to substantial reductions in life-threatening cardiovascular events.
        </Paragraph>

        <Paragraph className="blog-title">
          Who Stands to Benefit?
        </Paragraph>
        <Paragraph className="blog-description">
          PCSK9 inhibitors aren't just another treatment option; they represent a critical lifeline for several groups of patients:
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>Individuals with established cardiovascular disease:</strong> Those who have already experienced a heart attack or stroke may benefit significantly from these medications.
          </li>
          <li>
            <strong>Patients with familial hypercholesterolemia:</strong> This genetic condition leads to extremely high cholesterol levels that often don't respond well to statins alone.
          </li>
          <li>
            <strong>Patients intolerant to statins:</strong> For individuals who cannot tolerate statins due to side effects, PCSK9 inhibitors provide a viable alternative.
          </li>
        </ul>

        <Paragraph className="blog-title">
          How Instalab Can Help
        </Paragraph>
        <Paragraph className="blog-description">
          At Instalab, we understand that navigating the health insurance process can be daunting, especially when seeking coverage for new medications like PCSK9 inhibitors. Our team is here to simplify the process for patients who <a onClick={() => {
            navigate('/heart')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: heart health program`, true)
          }} className="blog-link">join our <i>Heart Health Program</i></a>:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Understand Your Coverage:</strong> We'll review your health insurance plan to determine what medications are covered and any associated costs.
          </li>
          <li>
            <strong>Complete Necessary Paperwork:</strong> We can guide you through the insurance forms and documentation needed for prior authorization, ensuring everything is submitted accurately.
          </li>
          <li>
            <strong>Communicate with Your Healthcare Provider:</strong> If necessary, we'll collaborate with your doctor to provide your health insurance company with the information they need to approve your treatment.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Safety and Tolerability
        </Paragraph>
        <Paragraph className="blog-description">
          As with any new medication, safety is a critical consideration. Fortunately, studies indicate that PCSK9 inhibitors are generally well tolerated. Most side effects reported have been mild, such as injection site reactions and muscle aches, which were comparable to placebo rates. Moreover, the risk of new-onset diabetes did not significantly increase with the use of these medications, alleviating concerns that have been associated with statins.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Robinson, J. G., et al. (2015). Efficacy and Safety of Alirocumab in Reducing Lipids and Cardiovascular Events. New England Journal of Medicine, 372(16), 1489-1499. <a onClick={() => window.open('https://doi.org/10.1056/NEJMoa1501031', '_blank')}>https://doi.org/10.1056/NEJMoa1501031</a>
            </li>
            <li>
              Sabatine, M. S., et al. (2017). Evolocumab and Clinical Outcomes in Patients with Cardiovascular Disease. New England Journal of Medicine, 376(18), 1713-1722. <a onClick={() => window.open('https://doi.org/10.1056/NEJMoa1615664', '_blank')}>https://doi.org/10.1056/NEJMoa1615664</a>
            </li>
            <li>
              Schwartz, G. G., et al. (2018). Alirocumab and Cardiovascular Outcomes after Acute Coronary Syndrome. New England Journal of Medicine, 379(22), 2097-2107. <a onClick={() => window.open('https://doi.org/10.1056/NEJMoa1801174', '_blank')}>https://doi.org/10.1056/NEJMoa1801174</a>
            </li>
            <li>
              Rashid, S. (2009). Key questions resulting from the JUPITER trial assessing cardiovascular disease intervention with rosuvastatin. World Journal of Cardiology, 1(1), 41-45. <a onClick={() => window.open('https://pmc.ncbi.nlm.nih.gov/articles/PMC2999035', '_blank')}>https://pmc.ncbi.nlm.nih.gov/articles/PMC2999035</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};