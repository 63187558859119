import { MedicalCondition, ProductTypeCode, Gender, SleepQuality, Medication, TestCode } from '../enums/index.enum';
import dayjs from 'dayjs';
import { getMe } from '../services/user.service';

// Helper functions
const getAge = (dob) => dayjs().diff(dayjs(dob), 'year');
const hasCondition = (conditions, condition) => conditions?.includes(condition);
const hasAnyCondition = (conditions, conditionList) => 
    conditionList.some(condition => conditions?.includes(condition));
const isInAgeRange = (age, min, max) => age >= min && (!max || age <= max);


// Common condition groups
const METABOLIC_CONDITIONS = [
    MedicalCondition.OBESITY,
    MedicalCondition.PREDIABETES,
    MedicalCondition.DIABETES
];

const cardiovascularConditions = [
    MedicalCondition.HIGH_CHOLESTEROL,
    MedicalCondition.HIGH_BLOOD_PRESSURE,
    MedicalCondition.HEART_DISEASE,
    MedicalCondition.STROKE,
    MedicalCondition.HEART_ATTACK
];

// Base recommendations based on just age and sex
const getBaseRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    
    if (AGE >= 18) {
        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: 1,
            yearFrequency: 1,
            testCodes: [TestCode.APO_B]
        });
    }


    // colorectal cancer screening
    if (AGE >= 35) {
        recommendations.push({
            test: ProductTypeCode.FIT_STOOL_TEST_KIT,
            weight: 0.75,
            yearFrequency: 1,
            testCodes: [TestCode.FECAL_OCCULT]
        });
    }

    if (AGE >= 40) {
        recommendations.push({
            test: ProductTypeCode.COLONOSCOPY,
            weight: 1,
            yearFrequency: 5
        });
    }

    // breast cancer screening
    if (answers.gender === Gender.FEMALE && AGE >= 40) {
        recommendations.push({
            test: ProductTypeCode.MAMMOGRAM,
            weight: 1,
            yearFrequency: 1
        });
    }

    // cervical cancer screening
    if (answers.gender === Gender.FEMALE) {
        if (isInAgeRange(AGE, 21, 29)) {
            recommendations.push({
                test: ProductTypeCode.PAP_SMEAR,
                weight: 0.75,
                yearFrequency: 3
            });
        } else if (isInAgeRange(AGE, 30, 65)) {
            
            recommendations.push({
                test: ProductTypeCode.HPV_TEST,
                weight: 0.75,
                yearFrequency: 5
            });
        }
    }

    // prostate cancer screening
    if (answers.gender === Gender.MALE && AGE >= 50) {
        recommendations.push({
            test: ProductTypeCode.DRE,
            weight: 1,
            yearFrequency: 1
        });
    }

    if (answers.gender === Gender.MALE && AGE >= 30) {
        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: 1,
            yearFrequency: 1,
            reason: "Includes PSA Test",
            testCodes: [TestCode.PSA_TOTAL]
        });
    }

    // skin cancer screening
    if (AGE >= 21) {
        recommendations.push({
            test: ProductTypeCode.SKIN_CANCER_EXAM,
            weight: 0.5,
            yearFrequency: 1
        });
    }

    if (AGE >= 21) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BODYCOMP,
            weight: 0.25,
            yearFrequency: 1,
            testCodes: [TestCode.DEXA_VAT_MASS]
        });
    }
  
    if (AGE >= 40) {

        // general cancer screening
        recommendations.push({
            test: ProductTypeCode.GALLERI,
            weight: 0.5,
            yearFrequency: 2,
            testCodes: [TestCode.GALLERI]
        });

        // heart disease screening
        if (answers.currentMedications?.includes(Medication.STATIN) || answers.currentMedications?.includes(Medication.CHOLESTEROL)) {
            recommendations.push({
                test: ProductTypeCode.CLEERLY,
                weight: 1,
                yearFrequency: 3,
                testCodes: [TestCode.CCTA_TOTAL_PLAQUE]
            });
        }
        else {
            recommendations.push({
                test: ProductTypeCode.CTCALCIUM,
                weight: 1,
                yearFrequency: 3,
                testCodes: [TestCode.CAC]
            });

        }

        if (answers.gender === Gender.FEMALE) {
            // bone density screening
            recommendations.push({
                test: ProductTypeCode.DEXA_BONE,
                weight: 1,
                yearFrequency: 3
            });

        }
    }

    if (AGE >= 50 && answers.gender === Gender.MALE) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BONE,
            weight: 1,
            yearFrequency: 3
        });
    }

    return recommendations;
};

const getLifestyleRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    if (answers.hasPreviousSmoking && AGE >= 40) {
        recommendations.push({
            test: ProductTypeCode.LDCT,
            weight: 1,
            reason: 'Smoking',
            yearFrequency: 1
        });
    }

    return recommendations;
}

// Metabolic health recommendations
const getMetabolicRecommendations = (answers) => {
    const recommendations = [];
    
    if (hasAnyCondition(answers.currentMedicalConditions, METABOLIC_CONDITIONS)) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BODYCOMP,
            weight: 0.5,
            yearFrequency: 1,
            testCodes: [TestCode.DEXA_VAT_MASS]
        });
        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: 1,
            reason: 'Includes Metabolic Health Tests',
            yearFrequency: 0.5,
            testCodes: [TestCode.HBA1C]
        });
    }

    if (answers.currentMedications?.includes(Medication.GLP1) || answers.currentMedications?.includes(Medication.OZEMPIC) || answers.currentMedications?.includes(Medication.WEGOVY)) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BODYCOMP,
            weight: 1,
            reason: "Check on muscle and fast mass.",
            yearFrequency: 0.5,
            testCodes: [TestCode.DEXA_VAT_MASS]
        });
    }

    return recommendations;
};

// Cardiovascular health recommendations
const getCardiovascularRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    
    const hasCardiovascularRisk = hasAnyCondition(answers.currentMedicalConditions, cardiovascularConditions);
    const conditionCount = cardiovascularConditions.filter(condition => 
        hasCondition(answers.currentMedicalConditions, condition)
    ).length;

    if (hasCardiovascularRisk) {
        if (AGE >= 40 && !answers.currentMedications?.includes(Medication.STATIN) && !answers.currentMedications?.includes(Medication.CHOLESTEROL) ) {
            recommendations.push({
                test: ProductTypeCode.CTCALCIUM,
                weight: 1,
                yearFrequency: 3,
                testCodes: [TestCode.CAC]
            });
        } else if (AGE >= 35) {
            recommendations.push({
                test: ProductTypeCode.CLEERLY,
                weight: 1,
                yearFrequency: 3,
                testCodes: [TestCode.CCTA_TOTAL_PLAQUE]
            });
        }

        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: conditionCount,
            yearFrequency: 0.5,
            reason: 'Includes Advanced Heart Health Tests',
            testCodes: [TestCode.APO_B]
        });
    }

    return recommendations;
};

// Bone health recommendations
const getBoneHealthRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    if ((hasCondition(answers.currentMedicalConditions, MedicalCondition.OSTEOPOROSIS) ||
        hasCondition(answers.currentMedicalConditions, MedicalCondition.CELIAC)) && AGE >= 30) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BONE,
            weight: 1,
            yearFrequency: 3,
            testCodes: [TestCode.DEXA_BMD_Z_SCORE]
        });
    }

    if (hasCondition(answers.currentMedicalConditions, MedicalCondition.CELIAC)) {
        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: 1,
            reason: 'Nutrient Deficiency',
            yearFrequency: 0.5,
            testCodes: [TestCode.VITAMIN_D]
        });
    }

    return recommendations;
};


// Non-Cancer Family history recommendations
const getFamilyHistoryRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    if (hasCondition(answers.familyHistory, MedicalCondition.OSTEOPOROSIS)) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BONE,
            weight: 1,
            yearFrequency: 1,
            testCodes: [TestCode.DEXA_BMD_Z_SCORE]
        });
    }

    if (hasCondition(answers.familyHistory, MedicalCondition.DIABETES)) {
        recommendations.push({
            test: ProductTypeCode.DEXA_BODYCOMP,
            weight: 1,
            yearFrequency: 1,
            testCodes: [TestCode.DEXA_VAT_MASS]
        });
        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: 1,
            reason: 'Metabolic Issues',
            yearFrequency: 0.5,
            testCodes: [TestCode.HBA1C]
        });
    }

    if (hasAnyCondition(answers.familyHistory, cardiovascularConditions)) {
        if (AGE >= 40  && !answers.currentMedications?.includes(Medication.STATIN) && !answers.currentMedications?.includes(Medication.CHOLESTEROL) ) {
            recommendations.push({
                test: ProductTypeCode.CTCALCIUM,
                weight: 1,
                yearFrequency: 3,
                testCodes: [TestCode.CAC]
            });
        } else if (AGE >= 30) {
            recommendations.push({
                test: ProductTypeCode.CLEERLY,
                weight: 1,
                yearFrequency: 3,
                testCodes: [TestCode.CCTA_TOTAL_PLAQUE]
            });
        }
        recommendations.push({
            test: ProductTypeCode.LONGEVITY_PANEL,
            weight: 1,
            reason: 'Heart Disease',
            yearFrequency: 0.5,
            testCodes: [TestCode.APO_B]
        });
    }


    return recommendations;
};

// Cancer family history recommendations
const getCancerRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    if (hasCondition(answers.familyHistory, MedicalCondition.CANCER)) {
       
        recommendations.push({
            test: ProductTypeCode.GALLERI,
            weight: 0.5,
            yearFrequency: 1,
            testCodes: [TestCode.GALLERI]
        });

        // Breast cancer
        if (hasCondition(answers.familyHistory, MedicalCondition.CANCER_BREAST) && AGE >= 30 && answers.gender === Gender.FEMALE) {
            recommendations.push({
                test: ProductTypeCode.MAMMOGRAM,
                weight: 2,
                yearFrequency: 1
            });
        }

        // colorectal cancer screening
        if (hasCondition(answers.familyHistory, MedicalCondition.CANCER_COLORECTAL)) {
            if (AGE >= 40) {
                recommendations.push({
                    test: ProductTypeCode.COLONOSCOPY,
                    weight: 2,
                    yearFrequency: 3
                });
            } else if (AGE >= 30) {
                recommendations.push({
                    test: ProductTypeCode.FIT_STOOL_TEST_KIT,
                    weight: 2,
                    yearFrequency: 1,
                    testCodes: [TestCode.FECAL_OCCULT]
                });
            }
        }

        // Skin Cancer
        if (hasCondition(answers.familyHistory, MedicalCondition.CANCER_SKIN) && AGE >= 18) {
            recommendations.push({
                test: ProductTypeCode.SKIN_CANCER_EXAM,
                weight: 1,
                yearFrequency: 1
            });
        }

        // Prostate Cacncer
        if (hasCondition(answers.familyHistory, MedicalCondition.CANCER_PROSTATE) && answers.gender === 'male' && AGE >= 40) {
            recommendations.push({
                test: ProductTypeCode.DRE,
                weight: 1,
                yearFrequency: 1
            });
        }

        if (hasCondition(answers.familyHistory, MedicalCondition.CANCER_OTHER)) {
            recommendations.push({
                test: ProductTypeCode.GALLERI,
                weight: 1,
                yearFrequency: 1,
                testCodes: [TestCode.GALLERI]
            });
        }
    }


    return recommendations;
};


// Sleep-related recommendations
const getSleepRecommendations = (answers) => {
    const recommendations = [];

    if (answers.sleepQuality === SleepQuality.POOR && !answers.currentMedicalConditions?.includes(MedicalCondition.SLEEP_APNEA)) {
        if (hasAnyCondition(answers.currentMedicalConditions, METABOLIC_CONDITIONS)) {
            recommendations.push({
                test: ProductTypeCode.WATCHPAT_ONE,
                weight: 2,
                testCodes: [TestCode.REM_SLEEP]
            });
        } else {
            recommendations.push({
                test: ProductTypeCode.WATCHPAT_ONE,
                weight: 1,
                testCodes: [TestCode.REM_SLEEP]
            });
        }
    }

    return recommendations;
};

// Fertility recommendations
const getFertilityRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    const GENDER = answers.gender;

    if (answers.planningChildren) {

        if (GENDER === Gender.FEMALE && AGE >= 30) { 
            recommendations.push({
                test: ProductTypeCode.FERTILITY,
                weight: AGE >= 40 ? 2 : AGE >=30 ? 1 : 0.5,
                yearFrequency: 1,
                testCodes: [TestCode.AMH]
            });
        }

        if (GENDER === Gender.MALE && AGE >= 30) {
            recommendations.push({
                test: ProductTypeCode.SPERM_TEST_KIT,
                weight: 1,
            });
        }
    }

    return recommendations;
};

// Environmental exposure recommendations
const getEnvironmentalRecommendations = (answers) => {
    const recommendations = [];
    const AGE = getAge(answers.dob) || answers.age;
    if (answers.possibleToxicExposure) {
        recommendations.push({
            test: ProductTypeCode.TOXIC_METALS_URINE_TEST,
            weight: 1,
        });
    }

    if (answers.hazardousExposure && AGE >= 40) {
        recommendations.push({
            test: ProductTypeCode.LDCT,
            weight: 1,
            yearFrequency: 1
        });
    }



    return recommendations;
};

const getTestDate = (testCode, results, tests) => {
    const test = tests.find(test => test.code === testCode);
    if (!test) return null;
    const result = results.find(result => result.values.some(value => value.test === test._id));
    return result ? result.collectedAt : null;
}


const RecommendationHelper = {


    getAnswers: async () => {
        const userData = await getMe({select: 'dob gender familyHistory currentMedicalConditions hasPreviousSmoking sleepQuality planningChildren hazardousExposure possibleToxicExposure currentMedications'});

        return {
            dob: userData.dob,
            gender: userData.gender,
            familyHistory: userData.familyHistory || [],
            currentMedicalConditions: userData.currentMedicalConditions || [],
            hasPreviousSmoking: userData.hasPreviousSmoking,
            sleepQuality: userData.sleepQuality,
            planningChildren: userData.planningChildren,
            hazardousExposure: userData.hazardousExposure,
            possibleToxicExposure: userData.possibleToxicExposure,
            currentMedications: userData.currentMedications || [],
            almi: null,
            vatMass: null,
            bmdZScore: null,
            bmdTScore: null,
        }
    },

    hasEnougAnswers: (answers) => {
        return answers.dob && answers.gender && answers.familyHistory && answers.currentMedicalConditions
    },

    getRecommendations: (answers, results=[], tests=[]) => {
        const recommendations = [
            ...getBaseRecommendations(answers),
            ...getLifestyleRecommendations(answers),
            ...getMetabolicRecommendations(answers),
            ...getCardiovascularRecommendations(answers),
            ...getBoneHealthRecommendations(answers),
            ...getCancerRecommendations(answers),
            ...getFamilyHistoryRecommendations(answers),
            ...getSleepRecommendations(answers),
            ...getFertilityRecommendations(answers),
            ...getEnvironmentalRecommendations(answers),
        ];


        // Remove duplicate tests + combine weights
        let newTests = [];
        const addTest = (test) => {
           
            const existingTest = newTests.find(t => t.test === test.test);
            if (existingTest) {
                newTests = newTests.map(t => 
                    t.test === test.test 
                        ? { 
                            ...t, 
                            weight: t.weight + test.weight,
                            yearFrequency: test.yearFrequency && t.yearFrequency 
                                ? Math.min(t.yearFrequency, test.yearFrequency)
                            : (t.yearFrequency || test.yearFrequency)
                        }
                        : t
                );
            } else {
                // determine if tests are already in the user's history - tech buggy with min frequency

                if (test?.testCodes?.length > 0) {
                    const lastTestDate = getTestDate(test.testCodes[0], results, tests);
                    if (lastTestDate) {
                        const timeUnit = test.yearFrequency < 1 ? 'month' : 'year';
                        const timeValue = test.yearFrequency < 1 ? test.yearFrequency * 12 : test.yearFrequency;
                        const testAgain = dayjs(lastTestDate).add(timeValue, timeUnit).isBefore(dayjs());
                        if (testAgain) {
                            newTests = [...newTests, test];
                        }
                    }
                    else {
                        newTests = [...newTests, test];
                    }
                } else {
                    newTests = [...newTests, test];
                }
                //newTests = [...newTests, test];
            }

        };

        // Get recommendations from separate module
        if (recommendations.length > 0) {
            recommendations.forEach(addTest);
        }
        return newTests
        
    },

    getDexaRecommendations: (answers) => {
        return []
    }

}

export default RecommendationHelper;
