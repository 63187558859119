import { useContext } from "react";
import { Table, Typography, Image } from "antd";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import { BlogData } from "../../../data/blog.data";
import PosthogHelper from "../../../helpers/posthog.helper";
import { useNavigate, useParams } from "react-router-dom";

const { Paragraph, Text } = Typography

export const DexaPost = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "dexa-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-description">
          When optimizing health, precision in the data you collect is crucial. A DEXA scan (Dual-Energy X-ray Absorptiometry) is one of the most advanced tools available for assessing body composition and bone health. It's quick, non-invasive, and offers insights far beyond what scales or body fat calculators can provide.
        </Paragraph>

        <Paragraph className="blog-description">
          While a DEXA scan measures various aspects of your body, the three most critical metrics it delivers are visceral fat, muscle mass, and bone density. These metrics aren't just numbers—they reflect your metabolic health, physical resilience, and long-term risk for chronic disease. By focusing on these key areas, a DEXA scan provides the actionable data needed to tailor health strategies effectively.
        </Paragraph>

        <Paragraph className="blog-title">
          Key Metric #1: VAT Mass
        </Paragraph>
        <Paragraph className="blog-description">
          Visceral adipose tissue (VAT) is among the most dangerous forms of fat due to its metabolic activity and location. Stored deep within the abdominal cavity, VAT wraps around critical organs such as the liver, pancreas, and intestines. Unlike subcutaneous fat, VAT releases pro-inflammatory molecules like cytokines and adipokines, which disrupt insulin signaling, increase systemic inflammation, and contribute to conditions such as type 2 diabetes, cardiovascular disease, and non-alcoholic fatty liver disease.
        </Paragraph>
        <Paragraph className="blog-description">
          VAT often doesn't correlate with outward appearance. Lean individuals may carry significant VAT, while some with higher BMI may have relatively low levels. This variability is driven by genetics and how the body partitions excess energy. When subcutaneous fat storage capacity is exceeded, excess energy is deposited as VAT, compounding health risks.
        </Paragraph>
        <Paragraph className="blog-description">
          The ideal VAT level is below the 10th percentile for your age and sex, though any reduction improves metabolic outcomes. While there's no direct way to specifically target VAT, interventions that reduce overall fat—such as caloric restriction, resistance training, and high-intensity interval training (HIIT)—are effective. Regular DEXA scans provide the precision needed to track progress and refine strategies.
        </Paragraph>
        <div className="blog-figure">
          <Image 
            src="/img/research/vat-mass-curves.png" 
            alt="VAT mass percentile curves"
          />
          <Text className="blog-caption">
          Figure: Age-specific and sex-specific percentile curves for VAT mass in grams. (European Journal of Clinical Nutrition, 2020).   </Text>
        </div>


        <Paragraph className="blog-title">
          Key Metric #2: Appendicular Lean Mass Index
        </Paragraph>
        <Paragraph className="blog-description">
          Skeletal muscle is not just for movement—it's a critical metabolic organ that enhances insulin sensitivity, regulates blood sugar, and maintains energy balance. The Appendicular Lean Mass Index (ALMI) measures the lean mass in your arms and legs relative to height, focusing exclusively on skeletal muscle, the tissue most relevant to strength and mobility.
        </Paragraph>
        <Paragraph className="blog-description">
          Muscle mass peaks in your 20s and starts to decline in your 30s, with the rate of loss accelerating after 40. Without intervention, this decline leads to sarcopenia (muscle loss), frailty, and an increased risk of falls, fractures, and metabolic dysfunction. ALMI allows us to quantify muscle health and intervene early to preserve it.
        </Paragraph>
        <Paragraph className="blog-description">
          Your goal should be to maintain your ALMI in the top 25% for your age and sex. Falling below the 50th percentile signals a need for targeted action. Strategies to build and maintain muscle include regular resistance training, adequate protein intake (0.7-1 g/lb body weight per day), and consistent physical activity. These interventions don't just preserve strength; they enhance metabolic health, mobility, and independence.
        </Paragraph>

        <div className="blog-figure">
          <Image 
            src="/img/research/ALMI-curves.png" 
            alt="ALMI percentile curves"
          />
          <Text className="blog-caption">
          Figure: Age-specific and sex-specific percentile curves for ALMI (Journal of Cachexia, Sarcopenia and Muscle 2021).      </Text>
        </div>

        <Paragraph className="blog-title">
          Key Metric #3: Bone Mineral Density
        </Paragraph>
        <Paragraph className="blog-description">
        Your bones are more than structural—they play a dynamic role in metabolism, acting as reservoirs for calcium and phosphate. Bone mineral density (BMD), measured during a DEXA scan, provides two key metrics:
        </Paragraph>
        <ul className="blog-list">
          <li><strong>T-Score:</strong> Compares your BMD to that of a healthy young adult.</li>
          <li><strong>Z-Score:</strong> Compares your BMD to peers of the same age and sex.</li>
        </ul>
        <Paragraph className="blog-description">
          Bone loss begins earlier than most realize, typically in your 30s, and accelerates with age. Postmenopausal women and men over 50 are particularly vulnerable. Declining bone density increases the risk of fractures, which can cascade into reduced mobility, prolonged recovery, and further health decline.
        </Paragraph>
        <Paragraph className="blog-description">
          Maintaining T- and Z-Scores above 0 is optimal for bone health, while scores below -2 indicate a need for further assessment and possible pharmacologic intervention. Preventing bone loss involves weight-bearing exercise, adequate calcium intake, and maintaining vitamin D levels in the optimal range of 40–80 ng/mL. Lifestyle factors like avoiding smoking and excessive alcohol also play a role.
        </Paragraph>

        <Paragraph className="blog-title">
          How a DEXA Scan Works
        </Paragraph>
        <Paragraph className="blog-description">
          The DEXA scan utilizes advanced imaging technology to distinguish between different tissues in your body with remarkable precision. The scanner consists of two primary components:
        </Paragraph>
        <ul className="blog-list">
          <li><strong>X-ray Generator:</strong> Located beneath the table, it emits two low-energy X-ray beams at different frequencies.</li>
          <li><strong>Arm-like Detector:</strong> Moves slowly over your body, measuring the absorption of the X-rays as they pass through various tissues.</li>
        </ul>

        <Paragraph className="blog-description">
          During the scan, as the X-ray beams pass through your body, each tissue absorbs them at a unique rate:
        </Paragraph>
        <ul className="blog-list">
          <li><strong>Bone (the densest tissue):</strong> Absorbs the most X-rays, enabling accurate assessment of bone mineral density.</li>
          <li><strong>Fat Tissue:</strong> Absorbs fewer X-rays, allowing for precise quantification of total body fat and specific deposits, such as visceral adipose tissue.</li>
          <li><strong>Lean Tissue (muscle and organs):</strong> Absorbs X-rays at intermediate levels, offering detailed insights into muscle mass.</li>
        </ul>

        <Paragraph className="blog-description">
          The scan takes approximately 10–20 minutes. Remaining still during the process is crucial for obtaining accurate results.
        </Paragraph>

        <Paragraph className="blog-title">
          Is DEXA Scan Safe? Understanding Radiation Exposure
        </Paragraph>
        <Paragraph className="blog-description">
          One of the most reassuring aspects of a DEXA scan is its safety, particularly in terms of radiation exposure. DEXA scans use minimal radiation, making them suitable for routine use, even for repeated measurements over time.
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[
            { source: 'DEXA Scan', exposure: '~0.001-0.03 mSv' },
            { source: 'Cross-Country Flight', exposure: '~0.03 mSv' },
            { source: 'Chest X-Ray', exposure: '~0.1 mSv' },
            { source: 'Mammogram', exposure: '~0.21 mSv' },
            { source: 'Annual Background (Sea Level)', exposure: '~3 mSv' },
            { source: 'Annual Background (Mile-High)', exposure: '~4.5 mSv' }
          ]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="Source" dataIndex="source" />
          <Table.Column title="Radiation Exposure" dataIndex="exposure" />
        </Table>

        <Paragraph className="blog-description">
        For most individuals, a DEXA scan once a year is sufficient to track body composition and bone density changes. If you are focused on optimizing specific metrics or undergoing targeted interventions, you might consider a scan twice a year for more detailed monitoring.

        </Paragraph>

        <Paragraph className="blog-title">
          Who Should Get a DEXA Scan?
        </Paragraph>
        <Paragraph className="blog-description">
          DEXA scans are valuable at any age. In your 20s and 30s, they establish baselines for fat distribution, muscle mass, and bone density, providing a foundation for proactive health management. This is the time to maximize bone strength, build muscle, and maintain healthy fat levels.
        </Paragraph>
        <Paragraph className="blog-description">
          In your 40s, 50s, and beyond, DEXA scans become essential for tracking changes. Visceral fat accumulation, muscle loss, and declining bone density often accelerate during these years, increasing risks for chronic disease and reduced physical function. Regular scans enable targeted interventions to mitigate these changes and maintain long-term health.
        </Paragraph>


        <Paragraph className="blog-title">
          How To Get a DEXA Scan
        </Paragraph>
        <Paragraph className="blog-description">
          DEXA scans are not typically covered by insurance but remain affordable, usually costing $50–$150. At Instalab, we help you locate trusted facilities and interpret your results, ensuring you gain actionable insights from this powerful diagnostic tool.
        </Paragraph>
        <Paragraph className="blog-description">
          <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: schedule`, true)
            navigate(`/flow/dexa-bodycomp`)
          }}>Click here to find the closest place and cost to get a DEXA scan.</a>
        </Paragraph>

        <Paragraph className="blog-title">
          How To Interpret My DEXA Results
        </Paragraph>
        <Paragraph className="blog-description">
          Interpreting DEXA results is straightforward when focusing on these key metrics:
        </Paragraph>
        <ul className="blog-list">
          <li><strong>VAT Mass:</strong> Aim for levels below the 10th percentile for your age and sex. Reducing VAT improves metabolic outcomes and reduces your risk of chronic disease.</li>
          <li><strong>ALMI:</strong> Maintain lean mass in the top 25% for your age and sex to preserve strength, mobility, and metabolic health.</li>
          <li><strong>BMD (Bone Mineral Density):</strong> Keep your T-Score above 0 to minimize fracture risk. Scores below -2 require further assessment and possible pharmacologic intervention.</li>
        </ul>
        <Paragraph className="blog-description">
          For actionable insights on how to improve these metrics, pair your results with Instalab's <a className="blog-link" onClick={() => {
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: dexa-recommender`, true)
            navigate(`/tools/dexa`)
          }}>DEXA recommendation tool</a>, which provides tailored guidance on nutrition, exercise, and supplementation based on your unique body composition.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
            Ofenheimer, A., et al. (2020). Reference values of body composition parameters and visceral adipose tissue (VAT) by DXA in adults aged 18-81 years—results from the LEAD cohort. European Journal of Clinical Nutrition, 74(8), 1181–1191. <a 
                href="https://pubmed.ncbi.nlm.nih.gov/32123345/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => PosthogHelper.track(currentUser, `[research] click ${blogId} reference: ofenheimer-2020`, true)}
              >
               https://pubmed.ncbi.nlm.nih.gov/32123345/
              </a>
            </li>
            <li>
              Kirk, B., et al. (2021). Body composition reference ranges in community-dwelling adults using dual-energy X-ray absorptiometry: The Australian Body Composition (ABC) Study. Journal of Cachexia, Sarcopenia and Muscle, 12(4), 880–890. <a 
                href="https://pubmed.ncbi.nlm.nih.gov/33991068/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => PosthogHelper.track(currentUser, `[research] click ${blogId} reference: kirk-2021`, true)}
              >
                https://pubmed.ncbi.nlm.nih.gov/33991068/
              </a>
            </li>
            <li>
              Siris, E. S., et al. (2004). Bone mineral density thresholds for pharmacological intervention to prevent fractures. The Journal of Clinical Endocrinology & Metabolism, 89(9), 4061–4067. <a 
                href="https://pubmed.ncbi.nlm.nih.gov/15159268/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => PosthogHelper.track(currentUser, `[research] click ${blogId} reference: siris-2004`, true)}
              >
                https://pubmed.ncbi.nlm.nih.gov/15159268/
              </a>
            </li>
            <li>
              Després, J.-P. (2012). Body fat distribution and risk of cardiovascular disease: An update. Circulation, 126(10), 1301–1313. <a 
                href="https://doi.org/10.1161/circulationaha.111.067264" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => PosthogHelper.track(currentUser, `[research] click ${blogId} reference: després-2012`, true)}
              >
                https://doi.org/10.1161/circulationaha.111.067264
              </a>
            </li>
            <li>
              Atkins, J. L., et al. (2014). Sarcopenic obesity and risk of cardiovascular disease and mortality: A population-based cohort study of older men. Journal of the American Geriatrics Society, 62(2), 253–260. <a 
                href="https://pubmed.ncbi.nlm.nih.gov/24428349/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => PosthogHelper.track(currentUser, `[research] click ${blogId} reference: atkins-2014`, true)}
              >
                https://pubmed.ncbi.nlm.nih.gov/24428349/
              </a>
            </li>
            <li>
              Radiation Dose. <a 
                href="https://www.radiologyinfo.org/en/info/safety-xray/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                onClick={() => PosthogHelper.track(currentUser, `[research] click ${blogId} reference: radiation-dose`, true)}
              >
                https://www.radiologyinfo.org/en/info/safety-xray/
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}