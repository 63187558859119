import React, { useContext, useEffect } from 'react';
import './patientCatalog.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { TestCatalog } from '../testCatalog/testCatalog.component'
import { UserContext } from '../../contexts/user.context';
import { PgFooter } from '../pgFooter/pgFooter.component';
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
export const PatientCatalog = () => {
  const { currentUser, token } = useContext(UserContext)

  useEffect(() => {
    document.title = `Test Catalog | Instalab`
  }, [])

  useEffect(() => {
    const trackPosthog = () => {
      if (currentUser) {
        PosthogHelper.track(currentUser, EventCode.TEST_CATALOG_VIEW);
      }
    };
    
    if (currentUser) {
      trackPosthog();
    }
  }, [currentUser]);


  return  currentUser && <>
    <div className="patient-catalog">
        <PageHeader
          title={<>Choose Your Test</>}
          description={!token && <>Need sure which test you need? <a href="/start" className="cta-link">Find out</a>.</>}
        />
        <div className="test-catalog-container">
          <TestCatalog xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}  showAll={true} />
        </div>
    </div>
    {!token && <PgFooter />}

    </>
}