import { Gender, Panel, TestCode } from '../enums/index.enum.js'

const PanelData = {
  [Panel.ADVANCED_BASELINE]: {
    name: "Instalab Panel (Baseline)",
    tubes: {SST: 2, EDTA: 2},
    testCodes: [
      TestCode.APO_B,
      TestCode.LIPOPROTEIN_A,
      TestCode.APO_E,
      TestCode.TC,
      TestCode.LDL_CHOLESTEROL_CALC,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG,
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
      TestCode.HOMAIR,
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.VITAMIN_D,
      TestCode.FERRITIN,
      TestCode.VITAMIN_B12,
      TestCode.FOLATE,
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.GGT,
      TestCode.BUN,
      TestCode.CREATININE,
      TestCode.CYSTATIN_C,
      TestCode.EGFR,
      TestCode.CALCIUM,
      TestCode.CHLORIDE,
      TestCode.SODIUM,
      TestCode.POTASSIUM,
      TestCode.BICARBONATE,
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS,
      TestCode.PLATELET,
      TestCode.PSA_TOTAL,
    ],
    excludeTests: (patient) => {
      let testCodes = []

      if (patient.gender === Gender.FEMALE) {
        testCodes.push(TestCode.PSA_TOTAL)
      }

      return testCodes
    }
  },
  [Panel.ADVANCED_FOLLOWUP]: {
    name: "Main Panel",
    testCodes: [
      TestCode.APO_B,
      TestCode.LIPOPROTEIN_A,
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG,
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
      TestCode.HOMAIR,
      TestCode.TGI,
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.FREET3,
      TestCode.TPO_ANTIBODY,
      TestCode.VITAMIN_D,
      TestCode.FERRITIN,
      TestCode.VITAMIN_B12,
      TestCode.FOLATE,
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.GGT,
      TestCode.BUN,
      TestCode.CREATININE,
      TestCode.CYSTATIN_C,
      TestCode.EGFR,
      TestCode.CALCIUM,
      TestCode.CHLORIDE,
      TestCode.SODIUM,
      TestCode.POTASSIUM,
      TestCode.BICARBONATE,
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS,
      TestCode.PLATELET,
      TestCode.PSA_TOTAL,
    ],
    excludeTests: (patient) => {
      let testCodes = []

      if (patient.gender === Gender.FEMALE) {
        testCodes.push(TestCode.PSA_TOTAL)
      }

      return testCodes
    }
  },
  [Panel.SLEEP_PANEL]: {
    name: "Sleep Panel",
    testCodes: [
      TestCode.PAHI,
      TestCode.PRDI,
      TestCode.ODI,
      TestCode.TST,
      TestCode.SLEEP_EFFICIENCY,
      TestCode.SLEEP_PULSE_RATE,
      TestCode.REM_SLEEP,
      TestCode.DEEP_SLEEP,
    ]
  },
  [Panel.CAC]: {
    name: "CAC",
    testCodes: [
      TestCode.CAC,
    ]
  },
  [Panel.THYROID]: {
    name: "Thyroid Panel",
    testCodes: [
      TestCode.TSH,
      TestCode.FREET3,
      TestCode.FREET4,
      TestCode.TPO_ANTIBODY,
    ],
  },
  [Panel.GALLERI]: {
    name: "Galleri",
    testCodes: [TestCode.GALLERI]
  },
  [Panel.CHOLESTEROL_THERAPY]: {
    name: "Cholesterol Therapy",
    testCodes: [
      TestCode.APO_B,
      TestCode.DIRECT_LDL,
      TestCode.TG,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.ALT
    ]
  },
  [Panel.OMEGA]: {
    name: "Omege 3 Index",
    testCodes: [
      TestCode.OMEGA_3_INDEX,
      TestCode.OMEGA_6_3,
      TestCode.ARACHIDONIC_EPA,
    ]
  },
  [Panel.ATHLETE]: {
    name: "Athlete Panel",
    testCodes: [
      TestCode.APO_B,
      TestCode.LIPOPROTEIN_A,
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG,
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.CORTISOL,
      TestCode.DHEA_S,
      TestCode.IGFI,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
      TestCode.HOMAIR,
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.VITAMIN_D,
      TestCode.VITAMIN_B12,
      TestCode.FOLATE,
      TestCode.ZINC,
      TestCode.IRON_TOTAL,
      TestCode.IRON_SATURATION,
      TestCode.TIBC,
      TestCode.FERRITIN,
      TestCode.OMEGA_3_INDEX,
      TestCode.OMEGA_6_3,
      TestCode.ARACHIDONIC_EPA,
      TestCode.TRANSFAT_INDEX,
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
      TestCode.ESTRADIOL,
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.GGT,
      TestCode.BUN,
      TestCode.CREATININE,
      TestCode.CYSTATIN_C,
      TestCode.EGFR,
      TestCode.CALCIUM,
      TestCode.CHLORIDE,
      TestCode.SODIUM,
      TestCode.POTASSIUM,
      TestCode.BICARBONATE,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.LYMPHOCYTES,
      TestCode.BASOPHILS,
      TestCode.MONOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.PLATELET,
      TestCode.PSA_TOTAL,
      TestCode.MERCURY,
      TestCode.TPO_ANTIBODY,
      TestCode.FREET3
    ]
  },
  [Panel.LIPID]: {
    name: "Lipid Panel",
    testCodes: [
      TestCode.LDL_CHOLESTEROL_CALC,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG,
      TestCode.TC
    ]
  },
  [Panel.CUSTOM]: {
    name: "Custom Panel",
    testCodes: []
  },
  [Panel.CMP]: {
    name: "Comprehensive Metabolic Panel",
    testCodes: [
      TestCode.GLUCOSE,
      TestCode.ALT,
      TestCode.AST,
      TestCode.BUN,
      TestCode.CREATININE,
      TestCode.ALP,
      TestCode.BILIRUBIN_TOTAL,
      TestCode.BILIRUBIN_DIRECT,
      TestCode.BICARBONATE,
      TestCode.CHLORIDE,
      TestCode.SODIUM,
      TestCode.POTASSIUM,
      TestCode.CALCIUM,
      TestCode.TOTAL_PROTEIN,
    ]
  }
  
}

export default PanelData