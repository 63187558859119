import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service.js"
import ProductHelper from "../../helpers/product.helper.js"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listMembershipTypes } from "../../services/membershipType.service"

export const Step = {
  ACCOUNT: 'account',
  PAY: 'pay',
  SELECT_MEMBERSHIP: 'select-membership',
  SELECT_DURATION: 'select-duration',
  PAY_MEMBERSHIP: 'pay-membership',
  CONFIRM: 'confirm',
}

export const DemoFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, initialUser, productTypes, instalabMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])
  
  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.DEMO_PRODUCT,
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const fetchMembershipTypes = async () => {
    const response = await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.DEMO,
            MembershipTypeCode.DEMO_12MO,
            MembershipTypeCode.DEMO_PREMIUM,
            MembershipTypeCode.DEMO_PREMIUM_12MO,
          ]
        }
      }
    })
    setMembershipTypes(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const getDurationOptions = (isPremium) => {
    const baseType = isPremium ? MembershipTypeCode.DEMO_PREMIUM : MembershipTypeCode.DEMO;
    const annualType = isPremium ? MembershipTypeCode.DEMO_PREMIUM_12MO : MembershipTypeCode.DEMO_12MO;
    return [
      {
        title: 'Monthly',
        value: baseType,
        description: "Ideal if you're just curious about the benefits and want to dabble. Billed monthly with the flexibility to cancel anytime.",
        price: `$${getMembershipType(baseType)?.cost}/month`
      },
      {
        title: 'Annual',
        value: annualType,
        description: `Perfect for those dedicated to long-term health. Enjoy 2 months free with an annual commitment of $${getMembershipType(annualType)?.cost}.`,
        price: `$${Math.round(getMembershipType(annualType)?.cost/12)}/month`
      },
    ];
  };


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.DEMO_PRODUCT}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: "Create Account"
      },
      
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.SELECT_MEMBERSHIP,
        addProduct: true,
        productType: ProductTypeCode.DEMO_PRODUCT,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.DEMO_PRODUCT)?.cost}</span> for the {getProductType(ProductTypeCode.DEMO_PRODUCT)?.title}.</>,
        description: `This includes an at-home blood draw and results and report within 2 weeks.`,
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.DEMO_PRODUCT].includes(product.productType.code)).length > 0
        },
      },

      [Step.SELECT_MEMBERSHIP]: {
        type: StepType.PRODUCT_SELECT,
        title: `Which Instalab membership is right for you?`,
        description: <>Each membership includes access to a longevity physician, exclusive test pricing, specialist referrals, and concierge care coordination.<p>The main difference: <i>Core</i> offers pay-per-visit physician consults, while <i>Premium</i> includes unlimited consults at no extra charge.</p></>,
        nextStep: Step.SELECT_DURATION,
        buildFlow: true,
        field: {
          name: 'membershipTypeCode',
          required: true,
          options: [
            {
              title: getMembershipType(MembershipTypeCode.DEMO_12MO)?.title,
              value: MembershipTypeCode.DEMO_12MO,
              description: "Includes your own longevity physician ($99/session).",
              // description: getMembershipType(MembershipTypeCode.DEMO)?.tagline,
              price: `$${Math.round(getMembershipType(MembershipTypeCode.DEMO_12MO)?.cost/12)}/month`
            },
            {
              title: getMembershipType(MembershipTypeCode.DEMO_PREMIUM_12MO)?.title,
              value: MembershipTypeCode.DEMO_PREMIUM_12MO,
              description: "Includes unlimited longevity physician access.",
              // description: getMembershipType(MembershipTypeCode.DEMO_PREMIUM)?.tagline,
              price: `$${Math.round(getMembershipType(MembershipTypeCode.DEMO_PREMIUM_12MO)?.cost/12)}/month`
            },
          ],
        },
      },
      [Step.SELECT_DURATION]: {
        type: StepType.PRODUCT_SELECT,
        title: `Choose your commitment level for ${getMembershipType(flow?.membershipTypeCode)?.title}.`,
        nextStep: Step.PAY_MEMBERSHIP,
        buildFlow: true,
        field: {
          name: 'membershipTypeCode',
          required: true,
          options: getDurationOptions([MembershipTypeCode.DEMO_PREMIUM_12MO, MembershipTypeCode.DEMO_PREMIUM].includes(flow?.membershipTypeCode))
        },
      },
      [Step.PAY_MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addSubscription: true,
        subscriptionType: flow?.membershipTypeCode,
        title: () => { 
          const selectedMembershipType = getMembershipType(flow?.membershipTypeCode);
          if (selectedMembershipType) {
            const isAnnual = [MembershipTypeCode.DEMO_PREMIUM_12MO, MembershipTypeCode.DEMO_12MO].includes(selectedMembershipType.code);
            return <>Activate your <span>{selectedMembershipType?.title}</span> for <span className="true-price">${selectedMembershipType?.cost}{isAnnual ? '/year' : '/month'}</span>.</>
          }
          return 'Activate your membership'
        },
        description: () => {
          const selectedMembershipType = getMembershipType(flow?.membershipTypeCode);
          const isAnnual = selectedMembershipType?.isAnnual
          
          return isAnnual ? <>You’ll be billed annually, but if plans change, cancellation is easy and always available before renewal.</> :
            <>You'll be billed monthly, but if it's not the right fit, cancellation is easy and always available before renewal.</> ;
        }
      },

    

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You're all set!`,
        description: `DEMO_PRODUCT will send you a kit for your blood draw within 1 business day. Below are next steps after you receive it.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
  
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      productTypeCode={ProductTypeCode.DEMO_PRODUCT}
    />
  )
}