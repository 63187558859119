import { Typography } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography

export const NapPost = () => {
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "sleep-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          When Naps Are Beneficial
        </Paragraph>
        <Paragraph className="blog-description">
          When done correctly, naps can be a powerful tool for improving both health and performance. Studies show that short naps, typically lasting between 10 and 30 minutes, can enhance alertness, boost mood, and improve cognitive function without interfering with nighttime sleep. For those who have experienced a poor night's rest, a nap can serve as a recovery mechanism, helping to counteract cognitive deficits and restore mental sharpness.
        </Paragraph>
        <Paragraph className="blog-description">
          Additionally, occasional naps have been linked to better cardiovascular health, with some epidemiological studies suggesting they may help reduce the risk of heart disease, though the underlying mechanisms are not yet fully understood. When used strategically, naps can be a secret weapon for maintaining productivity and overall well-being.
        </Paragraph>

        <Paragraph className="blog-title">
          When Naps Might Be Harmful
        </Paragraph>
        <Paragraph className="blog-description">
          Despite their benefits, naps aren't always a good thing. For some, they can disrupt nighttime sleep patterns, particularly if taken too frequently or too late in the day. Research suggests that frequent nappers and those who nap in the late afternoon or evening often experience increased nighttime sleep fragmentation and longer sleep onset times.
        </Paragraph>
        <Paragraph className="blog-description">
          Moreover, long and frequent naps have been associated with metabolic disorders, including an increased risk of type 2 diabetes. In older adults, excessive daytime napping has even been linked to cognitive decline and higher mortality rates. This doesn't mean that napping is inherently bad, but frequent, prolonged naps could indicate underlying health conditions.
        </Paragraph>

        <Paragraph className="blog-title">
          The Takeaway: How to Nap Smart
        </Paragraph>
        <Paragraph className="blog-description">
          The key to healthy napping lies in strategy. To maximize benefits and minimize risks, aim for short naps—ideally under 30 minutes—to avoid grogginess and sleep inertia. Timing also matters; napping earlier in the afternoon (before 3 PM) helps ensure it doesn't interfere with nighttime sleep. Lastly, listen to your body—if you find yourself constantly needing long naps, it may be worth reevaluating your overall sleep habits and health.
        </Paragraph>
        <Paragraph className="blog-description">
          Ultimately, napping can be a valuable tool for boosting performance and well-being, but only when done wisely. For most people, a short, well-timed nap is beneficial, but if you rely on long naps regularly, it could be a sign that your nighttime sleep needs attention.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Faraut, B., Nakib, S., Drogou, C., Elbaz, M., Sauvet, F., De Bandt, J., & Léger, D. (2015). Napping reverses the salivary interleukin-6 and urinary norepinephrine changes induced by sleep restriction. The Journal of Clinical Endocrinology and Metabolism, 100(3), E416-26. <a onClick={() => window.open('https://doi.org/10.1210/jc.2014-2566', '_blank')}>https://doi.org/10.1210/jc.2014-2566</a>
            </li>
            <li>
              Vgontzas, A., Pejovic, S., Zoumakis, E., Lin, H., Bixler, E., Basta, M., Fang, J., Sarrigiannidis, A., & Chrousos, G. (2007). Daytime napping after a night of sleep loss decreases sleepiness, improves performance, and causes beneficial changes in cortisol and interleukin-6 secretion. American Journal of Physiology - Endocrinology and Metabolism, 292(1), E253-61. <a onClick={() => window.open('https://doi.org/10.1152/AJPENDO.00651.2005', '_blank')}>https://doi.org/10.1152/AJPENDO.00651.2005</a>
            </li>
            <li>
              Mograss, M., Abi-Jaoude, J., Frimpong, E., Chalati, D., Moretto, U., Tarelli, L., Lim, A., & Dang-Vu, T. (2022). The effects of napping on night-time sleep in healthy young adults. Journal of Sleep Research, 31. <a onClick={() => window.open('https://doi.org/10.1111/jsr.13578', '_blank')}>https://doi.org/10.1111/jsr.13578</a>
            </li>
            <li>
              Lam, K., Jiang, C., Thomas, G. N., Arora, T., Zhang, W., Taheri, S., Adab, P., Lam, T. H., & Cheng, K. K. (2010). Napping is associated with increased risk of type 2 diabetes: The Guangzhou Biobank Cohort Study. Sleep, 33(3), 402-7. <a onClick={() => window.open('https://doi.org/10.1093/SLEEP/33.3.402', '_blank')}>https://doi.org/10.1093/SLEEP/33.3.402</a>
            </li>
            <li>
              Dijk, D. (2015). Napping: When sleep is bad for you? Journal of Sleep Research, 24. <a onClick={() => window.open('https://doi.org/10.1111/jsr.12352', '_blank')}>https://doi.org/10.1111/jsr.12352</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};