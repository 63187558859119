import { useNavigate, useParams, useSearchParams, createSearchParams, useLocation } from "react-router-dom"
import { useState, useEffect, useContext } from "react"
import FlowHelper from "../../helpers/flow.helper"
import { addFlow, addProxyFlow, getFlow } from "../../services/flow.service"
import { Spin, Typography } from 'antd'
import "./flowSplit.scss";
import { UserContext } from "../../contexts/user.context"
import FlowType from "../../enums/flowType.enum"

const { Text } = Typography

const FlowSplit = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { flowId, flowType, code } = useParams()
  const { currentUser } = useContext(UserContext)
  const [flowComponent, setFlowComponent] = useState()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)
  const [persistedParams, setPersistedParams] = useState({})

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams.entries())
    if (Object.keys(currentParams).length > 0) {
      setPersistedParams(currentParams)
    }
  }, [searchParams])

  useEffect(() => {
    fetchFlow()
  }, [flowId, flowType, currentUser, code])

  useEffect(() => {
    const protectedFlowTypes = [
      FlowType.HEART_HEALTH_UPGRADE,
      FlowType.HEART_MEMBER_CONSULT,
      FlowType.HEART_HEALTH_STRIPPED,
    ]
    if (!token && flowType && protectedFlowTypes.includes(flowType)) {
      navigate(`/login?redirect=${location.pathname}`)
    }
  }, [token, flowType])

  const fetchFlow = async () => {
    if (!flowType || !currentUser) {
      setFlowComponent(null)
      return
    }

    const queryParams = getQueryParams()
    const urlBase = getUrlBase()
    const flow = await getFlowById()
    const targetCode = code || 'step' // step = find first step in the flow component

    const url = `${urlBase}/${flowType}/${targetCode}/${flow._id}`

    const { component } = FlowHelper.getFlow(flowType)
    setFlowComponent(component)

    if (url !== location.pathname) {
      const search = `?${createSearchParams(queryParams)}`
      navigate(`${url}${search}`, {
        replace: true
      })
    }
  }

  const getFlowById = async () => {
    let { forceNew } = FlowHelper.getFlow(flowType)
    const params = getQueryParams()
    console.log('params', params)
    if (flowId) {
      return await getFlow(flowId)
    } else if (location.pathname.includes('pro-flow')) {
      return await addProxyFlow({  type: flowType,  forceNew, ...params })
    } else {
      return await addFlow({  type: flowType,  forceNew, ...params })
    }
  }

  const getQueryParams = () => {
    const currentParams = Object.fromEntries(searchParams.entries())
    return Object.keys(currentParams).length > 0 ? currentParams : persistedParams
  }

  const getUrlBase = () => {
    if (location.pathname.includes('/provider-flow/')) {
      return `/provider-flow`
    } else if (location.pathname.includes('/pro-flow/')) {
      return `/pro-flow`
    }
    return `/flow`
  }

  return flowComponent ? (
    <div className="flow-split">
      {flowComponent}
    </div>
  ) : (
    <div className="loading-flow">
      <Spin size="small" /> <Text className="loading-flow-text">Loading...</Text>
    </div>
  )
}

export default FlowSplit