import React, { useEffect, useState, useRef, useContext } from "react";
import { Typography, Modal, Form, Input, message } from "antd";
import { Selector } from 'antd-mobile'
import { ProgressBar } from "antd-mobile";
import MaskedInput from 'antd-mask-input'
import { UserContext } from "../../contexts/user.context.js";
import "./intakeModal.scss";
import Color from "../../colors.scss"
import moment from 'moment'
import { BookAppointmentNav } from "../bookAppointmentNav/bookAppointmentNav.component";
import UserHelper from "../../helpers/user.helper";
import InputType from "../../enums/inputType.enum";
import { addAnswer } from "../../services/answer.service";
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames'
import { listQuestions } from "../../services/question.service.js";
import Role from "../../enums/role.enum.js";
import { getPatient } from "../../services/patient.service.js";
import useWidth from "../../hooks/useWidth.hook.js";
import Breakpoint from "../../enums/breakpoint.enum.js";
import { listAppointments } from "../../services/appointment.service.js";


const { Text, Paragraph } = Typography;
const { Item } = Form
const { TextArea } = Input

export const FieldType = Object.freeze({
  INPUT: "input",
  RADIO: "radio",
  CHECKBOX: "checkbox",
  TEXT: "text",
  DATE: "date",
  NUMBER: "number",
  BOOLEAN: "boolean",
  PASSWORD: "password",
});

export const IntakeModal = ({ hasFlow=true, appointment, questionId, setQuestionId, autoOpen = true, onSuccess }) => {
  const inputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState();
  const [showIntro, setShowIntro] = useState(undefined);
  const [open, setOpen] = useState();
  const [question, setQuestion] = useState();
  const [questions, setQuestions] = useState()
  const { patientId } = useParams()
  const [allQuestions, setAllQuestions] = useState()
  const [user, setUser] = useState()
  const width = useWidth()
  const { currentUser } = useContext(UserContext);
  const [appointments, setAppointments] = useState()
  const [intro, setIntro] = useState();
  const [form] = Form.useForm();
  const [followupQuestions, setFollowupQuestions] = useState()
  const location = useLocation()

  useEffect(() => {
    fetchShowIntro()
  }, [questionId, questions]);

  useEffect(() => {
    fetchQuestions();
  }, [open, user, allQuestions, appointment, appointments, followupQuestions]);

  useEffect(() => {
    fetchCurrentQuestion();
  }, [questionId, questions]);

  useEffect(() => {
    fetchPatientData()
  }, [open, patientId, currentUser])

  useEffect(() => {
    setIntro(getIntro());
  }, [user, questions, hasFlow, appointment, appointments]);

  useEffect(() => {
    fetchFormValue();
  }, [question, allQuestions]);

  const fetchPatientData = async () => {
    if (!currentUser) return
    const isPatient = currentUser.role === Role.PATIENT
    const userId = isPatient ? currentUser._id : patientId
    if (isPatient) {
      setUser(currentUser)
    } else {
      const response = await getPatient(userId, {
        select: '_id firstName lastName'
      })
      setUser(response)
    }
    const questionsResponse = await listQuestions({ 
      filter: {
        patient: userId
      }
    })
    setAllQuestions(questionsResponse)
    const appointmentsResponse = await listAppointments({
      filter: {
        patients: {
          $in: [userId]
        }
      }
    })
    setAppointments(appointmentsResponse)
  }

  const fetchCurrentQuestion = () => {
    if (!questions || question) return;

    let newQuestion = null;

    if (questionId) {
      newQuestion = questions.find(({ _id }) => questionId === _id);
    } else if (questions.some(({ isAnswered }) => !isAnswered)) {
      newQuestion = questions.find(({ isAnswered }) => !isAnswered);
    }

    if (newQuestion && ((currentUser?.role !== Role.ADMIN && currentUser?.role !== Role.PROVIDER && autoOpen)) || questionId)  {
      setQuestion(newQuestion)
      setOpen(true);
    }
  };

  const hasPreviousAppointment = () => {
    return appointments?.length > 1
  }

  const getIntro = () => {
    if (!questions || !user || !appointments) {
      return null;
    }
    const unansweredCount = questions.filter(({ isAnswered }) => !isAnswered).length

    if (hasPreviousAppointment()) {
      return (
        <>
          Hey {user.firstName}, we need to check if anything
          changed in your lifestyle since your last blood draw. Please
          answer the following {unansweredCount}&nbsp;
          {unansweredCount === 1 ? "question" : "questions"}.
        </>
      );
    }

    return (
      <>
        Hi {user.firstName}, we need a little more data to help make your report more personalized. Please answer the
        following {unansweredCount}&nbsp;
        {unansweredCount === 1 ? "question" : "questions"} and then we'll show you your report.
      </>
    );
  };

  const isFollowupAppointment = () => {
    if (!appointment || !appointments?.length) return false
    return appointments.some(a => {
      return moment(a.start) < moment(appointment.start)
    })
  }

  const fetchFollowupQuestions = () => {
    if (isFollowupAppointment() && !followupQuestions?.length) {
      setFollowupQuestions(allQuestions.filter(({ isAnswered }) => !isAnswered))
    }
  }

  const filterFollowupQuestions = (data) => {
    if (!followupQuestions?.length) return data
    return data.filter(({ _id }) => followupQuestions.some(q => q._id === _id))
  }

  const fetchQuestions = async () => {
    if (!appointments || !allQuestions) return;
    if (!user && !patientId) return;
    fetchFollowupQuestions()
    const filteredQuestions = filterFollowupQuestions(allQuestions)

    if (filteredQuestions?.length > 0) {
      setQuestions(filteredQuestions)
    }
  };

  const fetchFormValue = () => {
    if (!question || !allQuestions) return;

    const targetQuestion = allQuestions.find(({ _id }) => _id === question._id)

    const sortedAnswers = targetQuestion?.answers?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
    let value = sortedAnswers?.length ? sortedAnswers[0].content : undefined

    if (
      value === undefined &&
      question.questionTemplate.inputType === InputType.CHECKBOX
    ) {
      value = [];
    }
    if (typeof value === "boolean") {
      value = value ? "true" : "false";
    }
    form.setFieldsValue({ [question._id]: value });
  };

  useEffect(() => {
    if (question?.questionTemplate.inputType === InputType.TEXT) {
      inputRef.current.focus();
    }
  }, [question?.questionTemplate.inputType])

  const FieldComponent = () => {
    switch (question?.questionTemplate.inputType) {
      case InputType.RADIO:
        return (
          <div 
            className={classNames(
              "radio-field",
              width <= Breakpoint.SM ? "mobile-radio-field" : "desktop-radio-field"
            )}
          >
            <Item 
              name={question._id}
              rules={[{ 
                required: true, 
                message: 'Select option'
              }]}
            >
              <Selector
                options={question.questionTemplate.options.map(option => {
                  return {
                    label: option,
                    value: option
                  }
                })}
              />
            </Item>
          </div>
        )
      case InputType.CHECKBOX:
        return (
          <div 
            className={classNames(
              "checkbox-field",
              width <= Breakpoint.SM ? "mobile-checkbox-field" : "desktop checkbox-field"
            )}
          >
            <Item name={question._id}>
              <Selector
                options={question.questionTemplate.options.map(option => {
                  return {
                    label: option,
                    value: option
                  }
                })}
                multiple
              />
            </Item>
          </div>
        )
      case InputType.DATE:
        return (
          <div className="date-field">
            <Item 
              name={question._id}
              rules={[{ 
                required: true, 
                message: 'Enter date'
              }]}
            >
              <MaskedInput 
                placeholder='MM/DD/YYYY'
                mask="00/00/0000"
                inputMode="decimal"
                onPressEnter={form.submit}
              />
            </Item>
          </div>
        )
      case InputType.BOOLEAN:
        return (
          <div className="boolean-field">
            <Item 
              name={question._id}
              rules={[{ 
                required: true, 
                message: 'Select option'
              }]}
            >
              <Selector
                options={[{
                  label: 'Yes',
                  value: true
                }, {
                  label: 'No',
                  value: false
                }]}
              />
            </Item>
          </div>
        )
      case InputType.TEXTAREA:
        return (
          <div 
            className={classNames(
              "text-field",
              width <= Breakpoint.SM ? "mobile-text-field" : "desktop-text-field"
            )}
          >
            <Item 
              name={question._id}
              rules={[{ 
                // required: true, 
                message: 'Enter answer'
              }]}
            >
              <TextArea ref={inputRef} placeholder='Enter answer here:' autoSize={{minRows: 3, maxRows: 6}}/>
            </Item>
          </div>
        )
      case InputType.TEXT:
        return (
          <div 
            className={classNames(
              "text-field",
              width <= Breakpoint.SM ? "mobile-text-field" : "desktop-text-field"
            )}
          >
            <Item 
              name={question._id}
              rules={[{ 
                required: true, 
                message: 'Enter answer'
              }]}
            >
              <Input ref={inputRef} placeholder='Enter answer here:'/>
            </Item>
          </div>
        )
    }
  };

  const onSave = async () => {
    setIsSubmitting(true);
    try {
      const value = formatValue(form.getFieldsValue()[question._id])
      const response = await addAnswer({
        question: question._id,
        content: value,
      })
      if (onSuccess) {
        onSuccess(response)
      }
      onNextStep(response)
    } catch (err) {
      onFinishError()
    }
    setIsSubmitting(false)
  }

  const onNextStep = (response) => {
    if (questionId) {
      onCancel()
      return
    }

    setUser(response.patient)
    const filteredQuestions = filterFollowupQuestions(response.questions)
    setQuestions(filteredQuestions)
    setAllQuestions(response.questions)

    const index = filteredQuestions.findIndex(
      ({ _id }) => _id === question._id
    );

    if (hasFlow && filteredQuestions.some(({ isAnswered }) => !isAnswered)) {
      setQuestion(filteredQuestions[index + 1]);
      message.success("Saved");
    } else {
      onCancel();
      message.success("Medical information complete");
    }
  };

  const onPrevStep = () => {
    const filteredQuestions = filterFollowupQuestions(questions)
    const index = filteredQuestions.findIndex(
      ({ _id }) => _id === question._id
    );
    if (index > 0) {
      setQuestion(filteredQuestions[index - 1]);
    }
  };

  const formatValue = (value) => {
    switch (question?.questionTemplate.inputType) {
      case InputType.BOOLEAN:
        value = typeof value === "object" ? value[0] : value
        if (value === "true") {
          value = true
        }
        if (value === "false") {
          value = false
        }
        return value
      case InputType.RADIO:
        return typeof value === "object" ? value[0] : value;
      case InputType.CHECKBOX:
      case InputType.TEXT:
      case InputType.DATE:
      case InputType.TEXTAREA:
        return value;
    }
  };

  const onFinishError = () => {
    message.error("Failed to save");
  };

  const onOk = async () => {
    if (showIntro) {
      setShowIntro(false);
    } else {
      form.submit();
    }
  };

  const onCancel = () => {
    if (setQuestionId) {
      setQuestionId(null)
    }
    setQuestion(null)
    setFollowupQuestions(null)
    setQuestions(null)
    setShowIntro(null)
    setIntro(null)
    setOpen(false)
  };

  const fetchShowIntro = () => {
    if (questionId) setShowIntro(false)
    if (!questions?.length || showIntro !== undefined) return
    return setShowIntro(questions.some(({ isAnswered }) => !isAnswered))
  };

  return (
  UserHelper.isPatient(user) &&
  question && 
  !location.pathname.includes('share') && (
    <Modal
      open={open}
      title={!questionId && <>Complete Medical Information</>}
      onCancel={onCancel}
      onOk={onOk}
      width={650}
      className="intake-modal"
      footer={[
        <BookAppointmentNav
          previous={!showIntro && !questionId && hasFlow && questions.findIndex(({ _id }) => question._id === _id) !== 0}
          onPrevStep={onPrevStep}
          isSubmitting={isSubmitting}
          onContinue={onOk}
          continueText={showIntro ? "Continue" : "Save"}
          key={question._id}
        />,
      ]}
    >
    <div className="intake-template">
      {(!questionId && showIntro && hasFlow) ? (
        <Paragraph className="intake-description">{intro}</Paragraph>
      ) : (
      <>
        <div className="intake-header">
          {hasFlow && !questionId && <>
            <div className="progress-bar">
              <ProgressBar
                percent={questions.filter(({ isAnswered }) => isAnswered).length/questions.length*100}
                style={{
                  "--track-width": "8px",
                  "--fill-color": Color.success,
                  "--track-color": Color.secondary_bg,
                }}
              />
          </div>

            <Text className="step-counter">
              Question&nbsp;
              {questions.findIndex(
              ({ _id }) => question._id === _id
              ) + 1}
              /{questions.length}
            </Text>
          </>}

          <Paragraph className="step-title">{question.content} {question?.questionTemplate?.caption && <span>{question.questionTemplate.caption}</span>}</Paragraph>
        </div>

        <Form
          form={form}
          onFinish={onSave}
          onFinishError={onFinishError}
          className="intake-form"
        >
          <FieldComponent />
        </Form>
      </>
      )}
    </div>
    </Modal>
  )
  );
};
