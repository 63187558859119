const MedicalCondition = Object.freeze({

  // CANCER
  CANCER: "Cancer",
  CANCER_BLADDER: "Bladder Cancer",
  CANCER_BREAST: "Breast Cancer",
  CANCER_OVARIAN: "Ovarian Cancer",
  CANCER_CERVICAL: "Cervical Cancer",
  CANCER_COLORECTAL: "Colorectal Cancer",
  CANCER_PROSTATE: "Prostate Cancer",
  CANCER_SKIN: "Skin Cancer",
  CANCER_PANCREATIC: "Pancreatic Cancer",
  CANCER_THYROID: "Thyroid Cancer",
  CANCER_ENDOMETRIAL: "Endometrial Cancer",
  CANCER_KIDNEY: "Kidney Cancer",
  CANCER_LUNG: "Lung Cancer",
  CANCER_UTERINE: "Endometrial (Uterine) Cancer",
  CANCER_OTHER: "Other Cancer",
  CANCER_STOMACH: "Stomach (Gastric) Cancer",
  NON_HODGKIN_LYMPHOMA: "Non-Hodgkin Lymphoma",
  LEUKEMIA: "Leukemia",

  // METABOLIC
  DIABETES: "Diabetes",
  TYPE_1_DIABETES: "Type 1 Diabetes",
  TYPE_2_DIABETES: "Type 2 Diabetes",
  NAFLD: "NAFLD",
  GESTATIONAL_DIABETES: "Gestational Diabetes",
  HYPERTHYROIDISM: "Hyperthyroidism",
  HYPOTHYROIDISM: "Hypothyroidism",
  OBESITY: "Obesity",
  PCOS: "PCOS",
  GOUT: "Gout",
  METABOLIC_SYNDROME: "Metabolic Syndrome",
  PREDIABETES: "Prediabetes",
  // AUTOIMMUNE
  RHEUMATOID_ARTHRITIS: "Rheumatoid Arthritis", 
  LUPUS: "Lupus", 
  MULTIPLE_SCLEROSIS: "Multiple Sclerosis",
  PSORIASIS: "Psoriasis",
  HASHIMOTO: "Hashimoto's Thyroiditis",
  GRAVES: "Graves' Disease",
  CELIAC: "Celiac Disease",
  SJOGREN: "Sjogren's Syndrome",
  
  // CVD - current
  FAMILIAL_HYPERCHOLESTEROLEMIA: "Familial Hypercholesterolemia",
  FAMILIAL_HYPERTRIGLYCERIDEMIA: "Familial Hypertriglyceridemia",
  HIGH_BLOOD_PRESSURE: "High Blood Pressure",
  HEART_DISEASE: "Heart Disease",
  HYPERTIGLYCERIDEMIA: "Hypertriglyceridemia",
  HIGH_CHOLESTEROL: "High Cholesterol",
  ARRHYTHMIA: "Arrhythmia",
  CAD: "Coronary Artery Disease",
  PERIPHERAL_ARTERY_DISEASE: "Peripheral Artery Disease (PAD)",
  ATRIAL_FIBRILATION: "Atrial Fibrillation",
  VALVULAR_HEART_DISEASE: "Valvular Heart Disease",
  
  // CVD - past
  HEART_ATTACK: "Heart Attack",
  ANGINA: "Angina",
  AORTIC_ANEURYSM: "Aortic Aneurysm",
  CAROTID_ARTERY_BLOCKAGE: "Carotid Artery >50% Blockage",
  HEART_DISEASE_REQUIRING_STENT_OR_SURGERY: "Heart Disease: Stent or Bypass Surgery Needed",
  TIA: "Transient Ischemic Attack (TIA)",
  BLOCKED_ARTERY: "Blocked Artery",
  STROKE: "Stroke",

  // NON-CVD
  NON_ATHERO_AORTIC_ANUERYSM: "Non-atherosclerotic Aortic Aneurysm",
  
  // BONE & MUSCLE
  HYPERPARATHYROIDISM: "Hyperparathyroidism",
  OSTEOARTHRITIS: "Osteoarthritis",
  OSTEOPENIA: "Osteopenia",
  OSTEOPOROSIS: "Osteoporosis",
  ARTHRITIS: "Arthritis",
  FIBROMYALGIA: "Fibromyalgia",
  MUSCULAR_DYSTROPHY: "Muscular Dystrophy",
  TENDONITIS: "Tendonitis",
  PAGETS: "Paget's Disease",
  SCOLIOSIS: "Scoliosis", 
  HERNIATED_DISC: "Herniated Disc",
  LOW_BACK_PAIN: "Low Back Pain",
  CARPAL_TUNNEL_SYNDROME: "Carpal Tunnel Syndrome",
  
  // DIGESTIVE
  IBS: "IBS",
  IBD: "IBD",
  SIBO: "SIBO",
  ACID_REFLUX: "Acid Reflux",
  CROHNS: "Crohn's Disease",
  ULCERATIVE_COLITIS: "Ulcerative Colitis",
  DIVERTICULITIS: "Diverticulitis",
  STOMACH_ULCERS: "Stomach Ulcers",
  GALLSTONES: "Gallstones",
  LACOTSE_INTOLERANCE: "Lactose Intolerance",
  HEPATITIS_C: "Hepatitis C",
  LIVER_DISEASE: "Liver Disease",

  // KIDNEY
  CHRONIC_KIDNEY_DISEASE: "Chronic Kidney Disease",
  UTI: "UTI",
  KIDNEY_STONES: "Kidney Stones",
  OVERACTIVE_BLADDER: "Overactive Bladder",
  DIABETIC_NEPHROPATHY: "Diabetic Nephropathy",

  // INFECTIOUS
  HIV_AIDS: "HIV/AIDS",
  HEPATITIS_B: "Hepatitis B",
  TUBERCULOSIS: "Tuberculosis",
  LYME: "Lyme Disease",
  HPV: "HPV",
  SYPHILIS: "Syphilis",
  GONORRHEA: "Gonorrhea",
  HERPES: "Herpes",
  EBV: "EBV",

  // MENTAL
  DEPRESSION: "Depression",
  ANXIETY: "Anxiety",
  BIPOLAR: "Bipolar Disorder",
  SCHIZOPHRENIA: "Schizophrenia",
  PTSD: "PTSD",
  OCD: "OCD",
  PANIC_DISORDER: "Panic Disorder",
  SOCIAL_ANXIETY_DISORDER: "Social Anxiety Disorder",
  ADHD: "ADHD",
  EATING_DISORDER: "Eating Disorder",

  // OTHER
  AUTOIMMUNE_DISEASE: "Autoimmune Disease",
  ASTHMA: "Asthma",
  DEMENTIA: "Dementia / Alzheimer's",
  ANXIETY_DEPRESSION: "Anxiety / Depression",
  COPD: "COPD",
  ANEMIA: "Anemia",
  GILBERTS: "Gilbert's Syndrome",
  THALASSEMIA: "Thalassemia",
  SLEEP_APNEA: "Sleep Apnea"
});
  
export default MedicalCondition;