import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const removeSelfReportedResult = async (resultId) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/results/${resultId}/self-reported`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const addSelfReportedResults = async (params) => {
  console.log("params",params)
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/results/self-reported`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listAnnotatedResults = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/results/annotated`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listResults = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/results`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const removeResult = async (resultId) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/results/${resultId}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const getResult = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/results/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const updateResult = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/results/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addResult = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/results`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const extractTestData = async (resultId, formData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/results/${resultId}/extract`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...ServiceHelper.getHeaders(), // Include any authentication headers
        },
      }
    );
    return response.data;
  } catch (error) {
    // Log the full error details for debugging
    console.error('Error uploading PDF:', error.response?.data || error.message);
    throw error;
  }
}

export const uploadCsvFile = async (resultId, formData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/results/${resultId}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...ServiceHelper.getHeaders(), // Include any authentication headers
        },
      }
    );
    return response.data;
  } catch (error) {
    // Log the full error details for debugging
    console.error('Error uploading CSV:', error.response?.data || error.message);
    throw error;
  }
};