import { Modal, Form, Input, Row, Col, Spin, Button, message } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { addPharmacy, updatePharmacy } from '../../services/pharmacy.service';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useLoadScript } from "@react-google-maps/api";
import { fromAddress, setDefaults } from "react-geocode";
import './adminPharmacyForm.scss'

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en",
  region: "es",
});

export const PharmacyFormModal = ({ open, onCancel, onSuccess, pharmacyId, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasAttempt, setHasAttempt] = useState(false);
  const inputRef = useRef(null);
  const hasAttemptRef = useRef(null);
  hasAttemptRef.current = hasAttempt;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['places']
  });

  // Add effect to set initial values when editing
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  const handlePlacesChange = address => {
    form.setFieldsValue({
      streetAddress: address
    });
  };

  const getAddressValueByType = (results, type) => {
    const component = results[0].address_components.filter(component => component.types.includes(type));
    return component.length ? component[0].short_name : null;
  };

  const handlePlacesSelect = address => {
    geocodeByAddress(address)
      .then(async (results) => {
        const streetNumber = getAddressValueByType(results, 'street_number');
        const route = getAddressValueByType(results, 'route');
        const formattedAddress = [streetNumber, route].filter(Boolean).join(' ');

        form.setFieldsValue({
          streetAddress: formattedAddress,
          postalCode: getAddressValueByType(results, 'postal_code'),
          city: getAddressValueByType(results, 'locality') || getAddressValueByType(results, 'sublocality_level_1'),
          state: getAddressValueByType(results, 'administrative_area_level_1'),
        });
      });
  };

  const onFail = () => {
    setHasAttempt(true);
    message.error('Enter valid pharmacy data');
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      
      // Get coordinates from address
      const fullAddress = `${values.streetAddress}, ${values.city}, ${values.state}, ${values.postalCode}, USA`;
      const { results } = await fromAddress(fullAddress);
      const { lat: latitude, lng: longitude } = results[0].geometry.location;

      const pharmacyData = {
        ...values,
        location: {
          streetAddress: values.streetAddress,
          streetAddress2: values.streetAddress2,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode,
          latitude,
          longitude,
        }
      };
      
      if (pharmacyId) {
        const updatedPharmacy = await updatePharmacy(pharmacyId, pharmacyData);
        form.resetFields();
        setHasAttempt(false);
        onSuccess(updatedPharmacy);
        message.success('Updated pharmacy successfully');
        onCancel();
      } else {
        const newPharmacy = await addPharmacy(pharmacyData);
        form.resetFields();
        setHasAttempt(false);
        onSuccess(newPharmacy);
        message.success('Added pharmacy successfully');
        onCancel();
      }
    } catch (error) {
      console.error(`Failed to ${pharmacyId ? 'update' : 'create'} pharmacy:`, error);
      message.error(`Failed to ${pharmacyId ? 'update' : 'create'} pharmacy`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={pharmacyId ? "Edit Pharmacy" : "Add New Pharmacy"}
      open={open}
      onCancel={onCancel}
      footer={null}
      className="admin-pharmacy-modal"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={onFail}
        className="admin-pharmacy-form"
      >
        <Form.Item
          name="name"
          label="Pharmacy Name"
          rules={[{ required: true, message: 'Enter pharmacy name' }]}
        >
          <Input placeholder="GenRX Pharmacy" />
        </Form.Item>

        <Form.Item
          name="ncpdp"
          label="NCPDP"
          rules={[{ required: true, message: 'Enter NCPDP' }]}
        >
          <Input placeholder="0358653" />
        </Form.Item>

        <Form.Item
          label="Street Address"
          name="streetAddress"
          rules={[{ required: true, message: 'Enter street address' }]}
        >
          {isLoaded && <PlacesAutocomplete
            onChange={handlePlacesChange}
            onSelect={handlePlacesSelect}
            value={form.getFieldValue('streetAddress') || ''}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="street-address-container">
                <Input
                  {...getInputProps({
                    placeholder: "17250 North Hartford Drive",
                    className: 'street-address-input',
                  })}
                  ref={inputRef}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="address-spinner"><Spin size='small' /> Loading...</div>}
                  {suggestions.map(suggestion => (
                    <div {...getSuggestionItemProps(suggestion, { className: 'suggestion-item' })}>
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>}
        </Form.Item>

        <Form.Item name="streetAddress2" label="Suite/Unit">
          <Input placeholder="Suite 115" />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: 'Enter city' }]}
            >
              <Input placeholder="Scottsdale" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: 'Enter state' }]}
            >
              <Input placeholder="Arizona" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="postalCode"
              label="Postal Code"
              rules={[{ required: true, message: 'Enter postal code' }]}
            >
              <Input placeholder="85255" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            className="submit-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {pharmacyId ? 'Update Pharmacy' : 'Create Pharmacy'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}; 