import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { SpecialistIntro } from "./specialistIntro.component"
import { ConfirmSpecialist } from "./confirmSpecialist.component"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { getMembershipTypeByCode} from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"
import ProductHelper from "../../helpers/product.helper"
import { FileTypeCode } from "../../enums/index.enum.js"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  SPECIALIST: 'specialist',
  REASON: 'reason',
  LOCATION: 'location',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  INSURANCE: 'insurance',
  PHONE: 'phone',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const SpecialistFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [membershipType, setMembershipType] = useState()
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, membershipType, productTypes, initialUser, instalabMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])


  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.CONSULTATION_RHEUMATOLOGY,
            ProductTypeCode.CONSULTATION_CARDIOLOGY,
            ProductTypeCode.CONSULTATION_GYNECOLOGY,
            ProductTypeCode.CONSULTATION_NEUROLOGY,
            ProductTypeCode.CONSULTATION_UROLOGY,
            ProductTypeCode.CONSULTATION_GASTROENTEROLOGY,
            ProductTypeCode.CONSULTATION_DERMATOLOGY,
            ProductTypeCode.CONSULTATION_ENDOCRINOLOGY,
            ProductTypeCode.CONSULTATION_ORTHOPEDICS,
            ProductTypeCode.CONSULTATION_DENTAL,
            ProductTypeCode.CONSULTATION_OPTHALMOLOGY,
            ProductTypeCode.CONSULTATION_ENT,
            ProductTypeCode.CONSULTATION_UROGYNECOLOGY,
            ProductTypeCode.CONSULTATION_HEMATOLOGY,
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const fetchMembershipType = async () => {
    const response = await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY)
    setMembershipType(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const instructions = [
    {
      sections: [{
        title: 'Request a Referral',
        description: <>Let us know what type of specialist you need.</>
      }]
    },
    {
      sections: [{
        title: "Review Your Options",
        description: <>We'll research the best options and provide a shortlist of providers to select from.</>
      }]
    },
    {
      sections: [{
        title: 'Schedule Appointment',
        description: <>Our concierge team will you book an appointment with the specialist you choose.</>
      }]
    },
    {
      sections: [{
        title: 'Follow Up Care',
        description: <>We’ll check in to help coordinate any next steps or additional tests.</>
      }]
    }
  ];


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return

    const specialistOptions = productTypes
      .map(productType => ({
        title: productType.title,
        value: productType.code,
        tag: null,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))

    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <SpecialistIntro  footer={footer} instructions={instructions}/>
        
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.SPECIALIST,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.SPECIALIST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.SPECIALIST]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select the specialist you'd like to be referred to.`,
        description: `If you'd like the specialist is not listed here, please email concierge@instalab.com.`,
        buildFlow: true,
        multiple: false,
        hasSkip: false,
        nextStep: Step.REASON,
        field: {
          name: 'addOns',
          required: true,
          options: [
            ...specialistOptions,
            // {
            //   title: 'Other',
            //   value: ProductTypeCode.CUSTOM_PANEL,
            //   description: 'If you have a test in mind that is not listed here, please select this option and we will be in touch to discuss availabiliy and pricing.',
            //   tag: null,
            //   price: "TBD"
            // }

          ].filter(Boolean) 
        },
        isLocked: () => {
          return flow?.products.length > 0
        },
      },
      [Step.REASON]: {
        type: StepType.TEXTAREA,
        nextStep: Step.LOCATION,
        buildFlow: true,
        title: `What's the reason for your referral?`,
        description: `Please provide a brief description of your needs.`,
        field: {
          name: 'consultReason',
          placeholder: 'Type your reason here...',
          required: true,
        },
      },

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildProduct:true,
        nextStep: Step.NAME,
        title: `What's your current address?`,
        description: `We'll search for providers near this address.`,
        field: {
          name: 'location'
        },
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },

      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INSURANCE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },

      [Step.INSURANCE]: {
        type: StepType.INSURANCE_UPLOAD,
        nextStep: Step.PHONE,
        buildUser: true,
        title: 'Upload your insurance card (optional)',
        description: 'Health insurance often covers most of the cost of specialist appointments. Upload your card if you want us to prioritize providers that accept your insurance. If not covered, we\'ll find the best price and quality option for you.',
        hasSkip: true,
        fields: [
          {
            name: 'frontInsuranceCard',
            fileTypeCode: FileTypeCode.INSURANCE_CARD_FRONT,
            upload: true,
            title: 'Front of your insurance card',
          },
          {
            name: 'backInsuranceCard',
            fileTypeCode: FileTypeCode.INSURANCE_CARD_BACK,
            upload: true,
            title: 'Back of your insurance card',
          }
        ],
        skipIf: () => {
          return initialUser?.frontInsuranceCard && initialUser?.backInsuranceCard
        }
      },


 
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },

      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: true,
        productType: flow?.addOns?.[0],
        title:() => {
          const selectedProduct = flow?.addOns?.[0] ? getProductType(flow.addOns[0]) : null;
          const totalCost = selectedProduct ? ProductHelper.getTotalCost([selectedProduct], instalabMembership, currentUser) : 0;
          return <>Pay <span className="true-price">${totalCost}</span> for the Instalab service fee.</>
        },
        description: () => <>This fee covers researching the best provider for your needs, scheduling your appointment and handling any follow ups.<p><i>Note: This doesn't include any insurance co-pay or additional costs the provider may directly charge you .</i></p></>,
        skipIf: () => {
          return instalabMembership 
        }
      },



      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set!`,
        description: `We'll email you within 1 business day.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmSpecialist instructions={instructions} productType={getProductType(flow?.addOns?.[0])}/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return <>
    <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  </>
}