import { Button, Image, Col, Row } from "antd";
import { useState, useEffect } from "react";
import { EventType, Breakpoint } from "../../enums/index.enum";
import { addEvent } from "../../services/event.service";
import { ShoppingOutlined, AmazonCircleFilled, CalendarOutlined, EyeOutlined } from "@ant-design/icons";
import {getProductTypeByCode} from "../../services/productType.service";
import {getMembershipTypeByCode} from "../../services/membershipType.service";
import "./productTypeBox.scss";

export const ProductTypeBox = ({productTypeCode, membershipTypeCode, cta, text, title, url}) => {

    const [productType, setProductType] = useState()

    useEffect(() => {
        if (productTypeCode) {
            getProductTypeByCode(productTypeCode).then((productType) => {
                setProductType(productType)
            })
        } else if (membershipTypeCode) {
            getMembershipTypeByCode(membershipTypeCode).then((membershipType) => {
                setProductType(membershipType)
            })
        }

      
    }, [productTypeCode])

  const width=800
  const onAmazon = async (url) => {
    await addEvent({ eventType: EventType.CLICK_PRODUCT, meta: {url: url} });
    window.open(url, "_blank");
  };


  const BuyButton = ({ buyUrl, buyText }) => (
    <Button
      type="default"
      icon={(width > Breakpoint.SM) ? buyUrl.includes("amazon.com")?<AmazonCircleFilled /> : buyText.toLowerCase().includes("schedule") ? <CalendarOutlined /> : buyText.toLowerCase().includes("order") ? <ShoppingOutlined /> : <EyeOutlined /> : null}
      onClick={() => onAmazon(buyUrl)}
      className="advice-buy-btn"
      size="middle"
      style={{paddingRight: width <= Breakpoint.SM && 20, paddingLeft: width <= Breakpoint.SM && 20}}
    >
      {buyText}
    </Button>
  );


  return (
    productType && (
      <div className="producttype-box">
        <Row align="middle" gutter={12} className="product-row">
          <Col xs={24} sm={productType.photos?.length > 0 && cta?.toLowerCase().includes("order") ? 18 : 24}>
            <div className="product-content">
              <div className="product-title">
                {title || productType.title}
              </div>
              <div className="product-description">
                {text || productType.tagline}
              </div>
              <div className="product-buy">
                {url ? (
                  <BuyButton buyUrl={url} buyText={cta || "Get Now"} />
                ) : productType.path ? (
                  <BuyButton buyUrl={productType.path} buyText={cta || "Get Now"} />
                ) : productType.flowType ? (
                  <BuyButton buyUrl={`/flow/${productType.flowType}`} buyText={cta || "Get Now"} />
                ) : (
                  <></>
                )}
          
              </div>
            </div>
          </Col>

          {productType.photos?.length > 0 && cta?.toLowerCase().includes("order") && (
            <Col xs={24} sm={6} className="product-image-col">
              <Image 
                src={productType.photos[0].png || productType.photos[0].webp} 
                preview={false}
                className="product-image"
              />    
            </Col>
          )}
        </Row>
      </div>
    )
  );
};
