import { Outlet } from 'react-router-dom';
import { Col, Affix, Layout, Row } from 'antd';
import "./patientLayout.scss"
import SidebarLayout from './sidebar.layout';
import Breakpoint from '../../enums/breakpoint.enum'
import useWidth from "../../hooks/useWidth.hook.js";
import { Header } from "../header/header.component.js";
import { Footer } from '../footer/footer.component.js';

const PatientLayout = () => {
  const width = useWidth()

  return  <>
    <Header />
    <Layout className="patient-layout">
        {width >= Breakpoint.LG ?
        <Row className="patient-row">
            <Outlet />
          {/* <Col 
            xs={{ span: 6 }} 
            sm={{ span: 6 }} 
            md={{ span: 6 }} 
            lg={{ span: 6 }} 
            xl={{ span: 5 }} 
            xxl={{ span: 5 }} 
            className="sidebar-column">
            <Affix className="affix-sidebar">
              <SidebarLayout />
            </Affix>
          </Col>
          <Col 
            xs={{ span: 18 }} 
            sm={{ span: 18 }} 
            md={{ span: 18}}           
            lg={{ span: 18 }} 
            xl={{ span: 19 }} 
            xxl={{ span: 19 }}>
            <Outlet />
          </Col> */}
        </Row>
        : 
        <Row className="patient-row" style={{paddingBottom: 70}}><Col span={24}><Outlet /></Col></Row>}

    </Layout>
    <Footer hasMobile={width<Breakpoint.LG}/> 

  </>
}

export default PatientLayout