import BlogId from "../enums/blogId.enum"

export const BlogData = [
  {
    id: BlogId.IF,
    title: `Intermittent Fasting Without the Typical Mistakes`,
    description: <>
      Intermittent fasting (IF) has skyrocketed in popularity, widely embraced as a straightforward yet effective approach to weight management. Unlike traditional diets that dictate <i>what</i> to eat, IF focuses on <i>when</i> you eat, making it an appealing option for many. Patients often tell us they love its simplicity—but does the science truly back up the hype?
    </>,
    publishedAt: new Date('2025-03-09'),
    authorPhoto: "/img/alex-square.jpg",
    authorName: "Alex Cheung",
    categories: ["Weight Loss", "Nutrition"],
    img: "/img/research/if.jpg",
  },
  {
    id: BlogId.WEIGHT_LOSS,
    title: `Losing Weight the Right Way: What Really Matters`,
    description: <>
      The first rule of weight loss is to stop obsessing over how much you weigh. Your total body weight consists of essential tissues like muscle and bone, and losing weight without understanding what you're losing can be counterproductive. A lower number doesn't necessarily mean better health—<i>it's what makes up that number that truly matters.</i>
    </>, 
    publishedAt: new Date('2025-03-08'),
    authorPhoto: "/img/alex-square.jpg",
    authorName: "Alex Cheung",
    categories: ["Weight Loss", "Health Basics"],
    img: "/img/research/weight.jpg",
  },
  {
    id: BlogId.OZEMPIC,
    title: `Ozempic's Hidden Benefits Beyond Weight Loss`,
    description: <>
      Ozempic (semaglutide) has taken the world by storm as a trendy weight-loss solution. And while celebrities praise its ability to help shed pounds quickly, its most fascinating effect goes beyond weight loss. <i>Ozempic has demonstrated real potential in reducing cardiovascular risks.</i> This isn't just about looking better in the mirror—it's about adding healthy years to your life and significantly lowering your risk of a heart attack or stroke.
    </>, 
    img: "/img/research/ozempic.jpg",
    categories: ["Weight Loss", "Medication"],
    publishedAt: new Date('2025-03-07'),
    authorPhoto: "/img/alex-square.jpg",
    authorName: "Alex Cheung",
  },
  {
    id: BlogId.STEROL_TEST,
    title: `A Smarter Way to Choose Cholesterol Medication`,
    description: <>
      When it comes to lowering cholesterol, most doctors start with a statin. These medications have been used for decades, are backed by research, and work well for many people. But not everyone responds the same way. Some people see great results, while others experience little improvement. So how do you know which medication will work best for you?
    </>,
    publishedAt: new Date('2025-03-06'),
    authorPhoto: "/img/alex-square.jpg",
    authorName: "Alex Cheung",
    categories: ["Heart Health", "Medication", "Lab Tests"],
    img: "/img/research/sterol-test.jpg",
  },
  {
    id: BlogId.EVALUATE_MEDICATION,
    title: `Making Smarter Medication Choices: Risks vs. Benefits`,
    description: <>
      Medications for chronic conditions, such as high cholesterol, often provoke strong opinions. Statins, widely used to lower cholesterol, are hailed as lifesaving by some and vilified by others. But how do you decide whether taking a medication is right for you? Instead of relying on anecdotes or media narratives, we advocate cutting through the noise with a structured, data-driven approach.
    </>,
    publishedAt: new Date('2025-03-05'),
    authorPhoto: "/img/alex-square.jpg",
    authorName: "Alex Cheung",
    categories: ["Medication", "Health Basics"],
    img: "/img/research/evaluate-medication.jpg",
  },
  {
    id: BlogId.EXERCISE_RECOVERY,
    title: `Why Recovery Matters: The Hidden Risks of Overtraining`,
    description: <>While regular physical activity is essential for a longer, healthier life, its full benefits are only realized when combined with proper nutrition, sleep, and recovery. Ignoring these factors can not only reduce the effectiveness of exercise but may even contribute to negative health outcomes.</>,
    publishedAt: new Date('2025-02-03'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Exercise", "Recovery"],
    img: "/img/research/recovery.jpg",
  },
  { 
    id: BlogId.EXERCISE_CONSEQUENCES,
    title: `What Happens to Your Body When You Stop Exercising`,
    description: <>The reality is that the human body is designed to move, and even short periods of inactivity can trigger a cascade of physiological changes that affect your metabolism, cardiovascular system, brain function, and overall longevity. In many cases, it's surprising how fast different critical health systems begin to decline.</>,
    publishedAt: new Date('2025-02-02'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Exercise", "Health Basics"],
    img: "/img/research/no-exercise.jpg",
  },
  {
    id: BlogId.EFFICIENT_EXERCISE,
    title: `The 3 Most Time-Efficient Workouts for a Longer Life`,
    description: <>At Instalab, we believe nothing beats a consistently active lifestyle. Movement is medicine, and the more you do it, the better. But if you're strapped for time this week, you don't have to throw in the towel. Research shows that even short, strategic workouts can provide serious health benefits. Here's how to make every minute of exercise count.</>,
    publishedAt: new Date('2025-02-01'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Exercise", "Longevity"],
    img: "/img/research/hiit.jpg",
  },
  {
    id: BlogId.NAP,
    title: `How to Nap Smarter: When It Helps and When It Hurts`,
    description: <>Naps have long been recognized as a countermeasure to sleep deprivation. And for many patients at Instalab who burn the midnight oil regularly, a nap can feel essential just to make it through the day. However, the science on whether napping is truly beneficial for sleep health and longevity is more nuanced than it might seem.</>,
    publishedAt: new Date('2025-01-28'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Sleep", "Recovery"],
    img: "/img/research/nap.jpg",
  },
  {
    id: BlogId.SLEEP_TRAINING,
    title: `Can You Train Yourself to Need Less Sleep Without Consequences?`,
    description: <>Sleep is one of the most crucial pillars of high performance—yet in the relentless pursuit of success, many patients at Instalab wonder: Can you train your body to need less sleep while maintaining peak health and cognitive function? Some claim that adaptation is possible, but research tells a much different story. While short-term sleep restriction can be managed, long-term reduction without consequences is largely a myth.</>,
    publishedAt: new Date('2025-01-27'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Sleep", "Performance"],
    img: "/img/research/sleep-training.jpg",
  },
  {
    id: BlogId.SLEEP,
    title: `The Truth About Sleep: How Much Do You Really Need?`,
    description: <>In the startup world, sacrificing short-term comfort for performance has become a badge of honor. Among the first sacrifices is often sleep. While most of us understand how lack of sleep impacts short-term cognitive function—making it harder to focus and solve problems—few realize the deeper, long-term damage we may be doing to ourselves.</>,
    publishedAt: new Date('2025-01-26'),
    img: "/img/research/sleep.jpg",
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Sleep", "Health Basics"],
  },
  {
    id: BlogId.VAPING,
    title: `The Risks of Vaping and How It Compares to Cigarettes`,
    description: <>As vaping becomes increasingly popular, particularly among younger populations, a pervasive myth continues to spread: the belief that there isn't enough evidence to confirm vaping's harm. But nothing could be further from the truth. Extensive research paints a very different picture, highlighting significant health risks associated with vaping.</>,
    publishedAt: new Date('2025-01-22'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Health Risks", "Prevention"],
    img: "/img/research/vape.jpg",
  },
  {
    id: BlogId.SUGAR,
    title: `Say Goodbye to Sugar Spikes: Alternative Options`,
    description: <>In today's world of ultra-addictive foods, the average American is practically overdosing on sugar. While the damage may not be immediately obvious, these sugar spikes cause inflammation and eventually snowball into serious conditions like insulin resistance, diabetes, and heart disease.<br/><br/>For most patients at Instalab, this isn't news. We all know sugar is bad for us. But willpower is finite, and swearing off all sweet-tasting foods forever isn't a sustainable plan. Instead, we recommend finding an alternative that gives you the sweetness you crave without wreaking havoc on your metabolism and cardiovascular system.</>,
    publishedAt: new Date('2025-01-21'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Nutrition", "Metabolism"],
    img: "/img/research/sugar.jpg",
  },
  {
    id: BlogId.SEED_OIL,
    title: `Are Seed Oils Really That Bad for Your Health?`,
    description: <>With the rising interest in longevity, the internet has become oversaturated with both helpful advice and rampant misinformation about nutrition. Among the more heated topics is the debate over seed oils, with social influencers labeling them as "toxic". But let's take a step back and look at the science.</>,
    publishedAt: new Date('2025-01-20'),
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Nutrition", "Seed Oil"],
    img: "/img/research/seed-oil.jpg",
  },
  {
    id: BlogId.DEXA,
    title: `The Top 3 Metrics to Track with a DEXA Scan`,
    description: <>Most people think of DEXA scans as just a tool for measuring body fat precisely, but their true value lies in the deeper insights they provide. Visceral fat, muscle mass, and bone density are the three metrics that matter most. Together, they paint a clearer picture of your metabolic health, strength, and long-term risk for conditions like insulin resistance, fractures, and frailty.</>,
    publishedAt: new Date('2025-01-06'),
    img: "/img/dexa-scan.jpg",
    authorName: "Instalab Research Team",
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Lab Tests", "Body Composition"],
  },
  {
    id: BlogId.BMI,
    title: `BMI Is a BS Metric: What You Should Really Track`,
    description: <>BMI has been the go-to metric for assessing and tracking health for decades, but can also be a bit of a BS metric. It oversimplifies your body's complexities by treating muscle and fat as interchangeable, ignoring fat distribution, and overlooking key factors like your unique genetics, age, and lifestyle. This one-size-fits-all approach often misclassifies health, masking real risks like visceral fat or metabolic dysfunction while mislabeling healthy individuals as overweight.</>,
    publishedAt: new Date('2024-12-31'),
    authorName: "Instalab Research Team", 
    authorPhoto: "/img/logo_green_bg.jpg",
    categories: ["Body Composition", "Health Basics"],
    img: "/img/research/bmi.jpg",
  },
  {
  id: BlogId.CALCULATOR,
  title: `The Problem With Cardiovascular Risk Calculators`,
  description: <>
    Risk calculators like the <i>Framingham Risk Score</i> have long been a cornerstone of cardiovascular medicine, estimating your chances of a heart attack or stroke over the next 10 years.
    But here's the problem: cardiovascular disease (CVD) is a slow, silent process that often begins in your teens and unfolds over decades. Limiting the assessment to just 10 years is far too narrow to fully capture the long-term damage accumulating in your arteries over a lifetime.
  </>,
  img: "/img/research/calculator.jpg",
  publishedAt: new Date('2024-12-19'),
  authorPhoto: "/img/alex-square.jpg",
  authorName: "Alex Cheung",
  categories: ["Heart Health", "Prevention"],
}, {
  id: BlogId.TESTOSTERONE_AGE,
  title: `How Fast Do Testosterone Levels Decline with Age?`,
  description: <>
    Despite its reputation as a "male hormone", testosterone's role goes far beyond defining male traits like facial hair and a deeper voice. It's a critical regulator of metabolism, a key supporter of muscle and bone health, and a significant driver of mood, energy and libido. <br/><br/> Testosterone production ramps up from birth into adulthood, peaking in your 20s. But after age 30, levels begin to decline. This isn't just a minor shift—it's a biological change that can impact athletic performance, energy, and overall vitality. Left unchecked, falling testosterone can result in reduced muscle mass and a higher risk of cardiovascular issues.
  </>,
  img: "/img/testosterone-age.jpg",
  publishedAt: new Date('2024-12-02'),
  authorPhoto: "/img/alex-square.jpg",
  authorName: "Alex Cheung",
  categories: ["Hormones", "Aging"],
}, {
  id: BlogId.STATIN,
  title: `Statins and Heart Health: Are They Worth the Risk?`,
  description: <>
    Statins are among the most commonly prescribed medications worldwide for managing cholesterol levels. While their effectiveness in reducing cardiovascular risks is well-established, concerns about potential side effects often deter people from taking them.  Here, we'll explore how statins work, assess their benefits, and weigh them against their risks to help you make an informed decision.
  </>,
  img: "/img/rosuvastatin.jpg",
  publishedAt: new Date('2024-11-21'),
  categories: ["Heart Health", "Medication"],
  authorName: "Alex Cheung",
  authorPhoto: "/img/alex-square.jpg",
},  {
  id: BlogId.PLAQUE_REVERSAL,
  title: `Reversing Plaque Buildup in Your Arteries: Is It Possible?`,
  description: <>
    Plaque buildup in your arteries is one of the primary culprits behind heart attacks and strokes. A common misconception is that this buildup is inevitable—a one-way street where you can only hope to slow its progression. However, emerging research paints a more hopeful picture. <br/><br/> Studies show that by lowering LDL cholesterol levels significantly, it is not only possible to halt the progression of plaque buildup but also to reverse it. In other words, the plaques in your arteries can actually shrink, opening the door to reduced heart disease risk.
  </>,
  img: "/img/atherosclerosis.jpg",
  publishedAt: new Date('2024-11-20'),
  authorPhoto: "/img/alex-square.jpg",
  authorName: "Alex Cheung",
  categories: ["Heart Health", "Prevention"],
}, {
  id: BlogId.LPA,
  title: `How Genetics and Lp(a) Shape Your Heart Attack Risk`,
  description: <>
    Lp(a), or Lipoprotein(a), can be thought of as a tiny package of cholesterol in your blood, wrapped in a unique protein called apolipoprotein(a). It's a wildcard in heart health— Lp(a) levels are mostly determined by genes and don't change much with diet or exercise. <br/><br/> High levels of Lp(a) can increase your risk of heart attack, but there's a catch: the amount of risk associated with high levels of Lp(a)  depends on your ethnicity.
  </>,
  publishedAt: new Date('2024-11-18'),
  img: "/img/research/lpa.jpg",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Genetics"],
}, {
  id: BlogId.BLOOD_PRESSURE_DIET,
  title: `Can You Lower Blood Pressure with Diet Changes?`,
  description: <>
    For years, most people have accepted high blood pressure as a problem that needs pills to fix. And while medication is crucial for some, what if changing your diet could naturally lower your blood pressure?
  </>,
  publishedAt: new Date('2024-11-10'),
  img: "/img/research/bp-diet.jpg",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Nutrition"],
}, {
  id: BlogId.SUPPLEMENT,
  title: `What Is the Most Effective Supplement to Lower LDL Cholesterol?`,
  description: <>
    At Instalab, we're all about giving you safe, effective options for improving your health. While prescription medications for high cholesterol are necessary for some people, several over-the-counter supplements can also reduce LDL cholesterol—sometimes achieving results that rival those of prescribed medications.
  </>,
  publishedAt: new Date('2024-11-09'),
  img: "/img/research/berberine.jpg",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Supplements"],
}, {
  id: BlogId.APOB,
  title: `Beyond Cholesterol: Why ApoB Matters for Heart Health`,
  description: <>
    ApoB, short for Apolipoprotein B, measures the number of atherogenic particles carrying cholesterol in your bloodstream. Think of these particles, or lipoproteins, as delivery trucks. Whether a truck is large or small, it has one ApoB, making it a direct count of how many trucks are on the road. <br /><br /> Here's the catch: even if your cholesterol levels look normal, too many ApoB particles can cause a traffic jam in your arteries, significantly increasing your cardiovascular risk.
  </>,
  publishedAt: new Date('2024-11-08'),
  img: "/img/heart-health-box.png",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Lab Tests"],
}, {
  id: BlogId.PCSK9I,
  title: `How Far Can PCSK9 Inhibitors Take You in the Fight Against Heart Disease?`,
  description: <>
    It's an exciting time to be involved in cardiovascular medicine. For years, statins have been the cornerstone of cholesterol management, effectively lowering low-density lipoprotein (LDL) cholesterol levels. However, many individuals still struggle to reach their cholesterol goals through lifestyle changes and statin therapy alone. <br/><br/> That's where PCSK9 inhibitors come into play.
  </>,
  publishedAt: new Date('2024-11-07'),
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Medication"],
  img: "/img/research/repatha.jpg",
}, {
  id: BlogId.OVEREXERCISE,
  title: `Exercising Too Much: Understanding the Tradeoffs`,
  description: `We all get it—exercise isn't just good, it's foundational for longevity. But what about those of us pushing the limits, marathon after marathon, triathlon after triathlon? Can training for years at high intensity come with its own risks?`,
  publishedAt: new Date('2024-11-05'),
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Exercise", "Health Risks"],
  img: "/img/research/overtrain.jpg",
}, {
  id: BlogId.FORECAST,
  title: `The Growing Burden of Heart Disease in the United States: A Forecast for the Future`,
  description: <>
    Heart disease remains one of the most significant health challenges in the U.S., affecting millions of lives each year. According to the latest data from the American Heart Association's (AHA) 2024 Heart Disease and Stroke Statistics report, heart disease continues to be the leading cause of death in the country.<br/><br/> And the outlook is alarming: projections indicate that by 2050, more than half of the U.S. adult population will have some form of cardiovascular disease (CVD), highlighting the need for urgent action to address this growing epidemic.
  </>,
  publishedAt: new Date('2024-10-23'),
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Statistics"],
  img: "/img/research/forecast.jpg",
}, {
  id: BlogId.SOFT_PLAQUE,
  title: `Soft Plaque: The Hidden Danger Lurking in Your Arteries`,
  description: `When we think about heart health, many of us are familiar with the idea of "hardening" arteries or the buildup of calcium in the blood vessels. But there's another, more dangerous culprit lurking within your arteries that often goes unnoticed: soft plaque. Unlike its calcified counterpart, soft plaque can be more unpredictable and potentially life-threatening, even when you feel perfectly healthy.`,
  publishedAt: new Date('2024-10-19'),
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Prevention"],
  img: "/img/research/soft-plaque.jpg",
}, {
  id: BlogId.INSURANCE,
  title: `Maximize Your Health Savings with Instalab: Using HSA and FSA to Stay Proactive`,
  description: <>
    Health Savings Accounts (HSAs) and Flexible Spending Accounts (FSAs) are powerful tools to help you manage healthcare costs. By understanding how these accounts work and how you can leverage them for services like those provided by Instalab, you can make significant strides in your health journey—without breaking the bank.
  </>,
  publishedAt: new Date('2024-10-15'),
  img: "/img/hsa-fsa.jpg",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Healthcare", "Insurance"],
}, {
  id: BlogId.CAC,
  title: `The CAC Test: A Cost-Effective Tool That Could Save Your Life`,
  description: <>
    The Coronary Artery Calcium (CAC) test is a powerful, non-invasive tool for assessing your risk of heart disease. It's a quick scan that measures the calcium buildup in the arteries of your heart, an early indicator of atherosclerosis—a condition that leads to heart disease.<br/><br/> By providing a CAC score, the test offers a clear picture of your future risk of cardiovascular events. The higher your CAC score, the more calcified plaque you have in your arteries, increasing your heart disease risk. On the other hand, a score of zero indicates a low risk of heart disease.
  </>,
  publishedAt: new Date('2024-09-06'),
  img: "/img/cac.jpg",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Heart Health", "Lab Tests"],
}, {
  id: BlogId.CUMULATIVE_EXPOSURE,
  title: `Lowering Cholesterol Aggressively: Is It the Right Move?`,
  description: `Most doctors will tell you a LDL Cholesterol of 100 mg/dL is "good", leading many of us to believe that we have eliminated the risk of this disease if we hit this number. But this one-size-fits-all approach grossly oversimplifies the complexity of cardiovascular risk and leaves many of us unknowingly at high risk for heart-related events.`,
  publishedAt: new Date('2024-02-13'),
  categories: ["Heart Health", "Prevention"],
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  img: "/img/research/cumulative-exposure.jpg",
}, {
  id: BlogId.GALLERI,
  title: `The Galleri Test: Is This Cancer-Screening Test Worth Its Price Tag?`,
  description: <>
    Early detection of cancer significantly improves survival rates. The Galleri test requires only a simple blood draw and scans for over 50 types of cancer. Known for its high specificity, the test does, however, have limitations in sensitivity, particularly in the early stages of cancer. Nevertheless, it offers invaluable information into potential cancer threats, especially for those genetically predisposed to the disease.
  </>,
  publishedAt: new Date('2023-12-20'),
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  img: "/img/research/galleri.jpg",
  categories: ["Cancer", "Lab Tests"],
}, {
  id: BlogId.PEAK,
  title: `Peak Health Ranges: A Data-Driven Approach to Longevity`,
  description: <>
    Understanding your lab results is crucial to making well-informed health decisions. Traditional reference ranges, based on average values of a healthy population, define normal upper and lower limits for lab tests. <br/><br/> While useful, these ranges can fall short in actually detecting the early stages of disease and extending lifespan and healthspan. This gap is addressed by the concept of 'peak health' ranges, which provide a more nuanced, individualized, and proactive approach to health management.
  </>,
  publishedAt: new Date('2023-12-18'),
  categories: ["Lab Tests", "Health Basics"],
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  img: "/img/research/peak.jpg",
}, {
  id: BlogId.SAUNA,
  title: `Can Saunas Actually Extend Your Lifespan?`,
  description: <>
    Sure, your favorite podcaster loves saunas. Peter Attia, Andrew Huberman, Rhonda Patrick, take your pick. But if you trace their advice back to the original research papers, it all stems from two Finnish scientists: Tanjaniina Laukkanen (researcher) and Jari Laukkanen (professor, cardiologist). <br/><br/> The participants in their studies were predominantly middle-aged Finns (42-60 years old), hailing from a small Finnish town called Kuopio. As a culture, Finland is obsessed with saunas with around 60% of Finnish households having a sauna on-site. It's not surprising this is where most of the research originates.
  </>,
  publishedAt: new Date('2023-11-27'),
  img: "/img/research/sauna.jpg",
  authorName: "Instalab Research Team",
  authorPhoto: "/img/logo_green_bg.jpg",
  categories: ["Longevity", "Recovery"],
}, {
  id: BlogId.FOUNDER,
  title: `Founder Health: The Silent Sacrifice in the Pursuit of Success`,
  description: <>
    Starting a startup often means pushing health to the back burner. In the race to success, it's easy to think "I'll deal with my health when my startup succeeds." But "success" turns out to be a moving goal post. From raising the next big round, to making that pivotal hire, to achieving a growth milestone, to navigating a board issue. It's never ending. Your health is perpetually sidelined.
  </>,
  publishedAt: new Date('2023-11-05'),
  img: "/img/research/founder.jpg",
  authorPhoto: "/img/adora-square.jpg",
  authorName: "Adora Cheung",
  categories: ["Performance", "Longevity"],
},
]
