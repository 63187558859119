import React, { useEffect, useState, useCallback } from 'react';
import 'react-quill/dist/quill.snow.css';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { Table, Tooltip as AntTooltip, Dropdown, Spin, message, Button } from 'antd'
import { EditOutlined, BarcodeOutlined, SyncOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons'
import './adminHeartHealth.scss';
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import { PageHeader } from '../pageHeader/pageHeader.component';
import utc from 'dayjs/plugin/utc'
import TrackingHelper from '../../helpers/tracking.helper.js';
import timezone from 'dayjs/plugin/timezone'
import { updatePrescriptionStatus } from '../../services/prescription.service.js';
import { getData } from '../../services/heart.service.js';
import classNames from 'classnames';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';
import { PrescriptionForm } from '../prescriptionForm/prescriptionForm.component.js';
import { LipidTests } from './lipidTests.component.js';

dayjs.extend(utc)
dayjs.extend(timezone)

const lipidResultsOptions = {
  LAGGING: 'Lagging'
}

export const AdminHeartHealth = () => {
  const [memberships, setMemberships] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [filteredCount, setFilteredCount] = useState();

  useEffect(() => {
    document.title = 'Instalab | Heart Health'
    fetchMemberships()
  }, [])

  const fetchMemberships = async () => {
    const data = await getData()
    setMemberships(data)
    setFilteredCount(data?.length)
    setIsLoading(false)
  }

  const getRefillDate = prescription => {
    let { daysToRefill, contents, deliveredAt, createdAt, tracking, hasRefill, refillAt } = prescription
    if (!hasRefill) return null

    if (refillAt) {
      return moment(refillAt).toDate()
    }

    if (contents && !daysToRefill) {
      const { doseQty=1, units } = contents
      daysToRefill = units / doseQty
    }
    
    if (!deliveredAt && tracking) {
      const trackingEvent = tracking.trackingEvents?.find(event => 
        event.eventType.toLowerCase().includes('delivered')
      )
      if (trackingEvent) {
        deliveredAt = trackingEvent.eventTimestamp
      }
    }

    if (daysToRefill) {
      daysToRefill -= 7
    
      return moment(deliveredAt || createdAt).add(daysToRefill, 'days').toDate()
    }
    return null
  }

  const PrescriptionList = React.memo(({ patient, prescriptions: defaultPrescriptions }) => {
    const [prescriptions, setPrescriptions] = useState()
    const [openPrescriptionForm, setOpenPrescriptionForm] = useState()

    useEffect(() => {
      setPrescriptions(defaultPrescriptions)
    }, [defaultPrescriptions])

    const onAddPrescription = useCallback((prescription) => {
      setPrescriptions(prev => [prescription, ...prev])
    }, [])

    return <>
      <PrescriptionForm
        open={openPrescriptionForm}
        setOpen={setOpenPrescriptionForm}
        onSuccess={onAddPrescription}
        patientId={patient._id}
      />

      {prescriptions?.map(prescription => (
        <PrescriptionItem 
          key={prescription._id} 
          prescription={prescription} 
        />
      ))}

      <Button
        className="add-btn"
        onClick={() => setOpenPrescriptionForm(true)}
        icon={<PlusOutlined />}
      />
    </>
  })

  const PrescriptionItem = ({ prescription: defaultPrescription }) => {
    const [refillDate, setRefillDate] = useState()
    const [status, setStatus] = useState()
    const [isProcessing, setIsProcessing] = useState()
    const [prescription, setPrescription] = useState()
    const [isPrescriptionItemEditOpen, setIsPrescriptionItemEditOpen] = useState()
    const [isRefill, setIsRefill] = useState()

    useEffect(() => {
      setPrescription(defaultPrescription)
    }, [defaultPrescription])

    useEffect(() => {
      fetchData()
    }, [prescription])

    const onPrescriptionItemEditSuccess = updatedPrescription => {
      setPrescription(updatedPrescription)
    }

    const fetchData = () => {
      if (!prescription) return

      const fetchedRefillDate = getRefillDate(prescription)
      setRefillDate(fetchedRefillDate)

      if (prescription.isSelfManaged) {
        setStatus('self-managed')
      } else if (prescription.hasRefill && moment(fetchedRefillDate).isAfter(moment()) && moment(fetchedRefillDate).isBefore(moment().add(1, 'weeks'))) {
        setStatus('soon')
      } else if (prescription.hasRefill && fetchedRefillDate < new Date()) {
        setStatus('empty')
      } else {
        setStatus(prescription.status)
      }
    }

    const onPause = async () => {
      setIsProcessing(true)
      try {
        await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.PAUSED })
        setPrescription(null)
        message.info(`Prescription paused`)
      } catch (err) {
        message.error('Failed to pause')
      }
      setIsProcessing(false)
    }

    const onRestart = async () => {
      setIsProcessing(true)
      try {
        const updatedPrescription = await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.SENT })
        setPrescription(updatedPrescription)
        message.info(`Prescription restarted`)
      } catch (err) {
        message.error('Failed to restart')
      }
      setIsProcessing(false)
    }

    return prescription ? <>
      <PrescriptionForm
        open={isPrescriptionItemEditOpen}
        setOpen={setIsPrescriptionItemEditOpen}
        onSuccess={onPrescriptionItemEditSuccess}
        prescriptionId={prescription?._id}
        isRefill={isRefill}
      />
      
      <Dropdown
        overlayStyle={{
          width: 170
        }}
        menu={{
          items: [{
            key: 'edit',
            label: (
              <a onClick={() => {
                setIsRefill(false)
                setIsPrescriptionItemEditOpen(true)
              }}>
                <EditOutlined style={{ marginRight: 5 }} /> Edit
              </a>
            )
          }, prescription?.hasRefill && {
            key: 'refill',
            label: (
              <a onClick={() => {
                setIsRefill(true)
                setIsPrescriptionItemEditOpen(true)
              }}>
                <SyncOutlined style={{ marginRight: 5 }} /> Refill
              </a>
            )
          }, status !== PrescriptionStatus.PAUSED && {
            key: 'pause',
            label: (
              <a onClick={onPause}>
                <PauseCircleOutlined style={{ marginRight: 5 }} /> Pause
              </a>
            )
          }, status === PrescriptionStatus.PAUSED && {
            key: 'restart',
            label: (
              <a onClick={onRestart}>
                <PlayCircleOutlined style={{ marginRight: 5 }} /> Restart
              </a>
            )
          }, prescription?.tracking?.trackingNumber && {
            key: 'tracking' ,
            label: (
              <a onClick={() => {
                window.open(TrackingHelper.getDeliveryService(prescription?.tracking?.trackingNumber).url, '_blank')
              }}>
                <BarcodeOutlined style={{ marginRight: 5 }} /> Medication Tracking
              </a>
            ),
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          title={(!prescription?.isSelfManaged && prescription?.hasRefill && refillDate) ? `Refill: ${moment(refillDate).format('MMM D, YYYY')}` : ''}
        >
          <div className="tag-container">
            <div className={classNames("tag", `${status}-prescription-tag`)}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {prescription?.type}
            </div>
          </div>
        </AntTooltip>    
      </Dropdown>
    </> : null
  }

  return (
    <div className="admin-heart-health">
      <PageHeader
        title='Heart Health'
        count={filteredCount}
      />

      <Table
        loading={isLoading}
        dataSource={memberships}
        rowKey='_id'
        size='small'
        pagination={false}
        // setFilteredCount={setFilteredCount}
        // getCustomFilter={getCustomFilter}
        // getActionItems={getActionItems}
        columns={[{
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            const url = `/patients/${patient._id}`
            return <a onClick={() => window.open(url, '_blank')}>{patient.firstName} {patient.lastName}</a>
          },
        }, 
        {
          title: 'Prescriptions',
          dataIndex: 'prescriptions',
          render: (prescriptions, { patient }) => (
            <PrescriptionList 
              prescriptions={prescriptions} 
              patient={patient}
            />
          ),
        }, 
        {
          title: 'Next Test',
          dataIndex: 'lipidResults',
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(lipidResultsOptions).map(value => {
            return {
              label: value,
              value
            }
          }),
          render: (lipidResults, { kits, appointments, patient, membershipType }) => (
            <LipidTests
              patient={patient}
              kits={kits}
              appointments={appointments}
              results={lipidResults}
              membershipType={membershipType}
              onUpdatePatient={updatedPatient => {
                setMemberships(prevMemberships => {
                  return prevMemberships.map(membership => {
                    if (membership.patient._id === updatedPatient._id) {
                      return {
                        ...membership,
                        patient: updatedPatient
                      };
                    }
                    return membership;
                  });
                });
              }}
            />
          )
        }, 
      ]}
      />
    </div>
  )
}