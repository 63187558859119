import { useRef, useState, useEffect } from 'react';
import { Row, Col, Typography, Button, Tag, Drawer } from 'antd';
import { PlayCircleOutlined, VideoCameraOutlined, CheckCircleOutlined } from '@ant-design/icons';
import "./ctaVideos.scss";
import classNames from 'classnames';
import ReactPlayer from 'react-player/vimeo';
import Breakpoint from '../../enums/breakpoint.enum';
import useWidth from '../../hooks/useWidth.hook';

const { Paragraph, Text } = Typography;

export const CtaVideos = ({ 
  visible = false, 
  onClose, 
  width = 720,
  title = "Coronary CT Angiogram Videos",
  placement = "right" 
}) => {
  const browserWidth = useWidth();
  
  // Calculate drawer width (at most 95% of screen width)
  const drawerWidth = Math.min(width, browserWidth * 0.95);
  
  const [selectedVideo, setSelectedVideo] = useState(null);
  const selectedPlayerRef = useRef(null);
  const [watchedVideos, setWatchedVideos] = useState({});
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [isInitialOpen, setIsInitialOpen] = useState(false);
  const drawerOpenTimeRef = useRef(null);
  
  const videos = [{
    question: `What is a coronary CT angiogram?`,
    answer: `A coronary CTA is a test that looks at both calcified and noncalcified plaque in your heart's arteries. If this plaque ruptures, it can lead to a heart attack or stroke. To perform the scan, you may be given medication to lower your heart rate and improve image clarity. Then, a contrast dye is injected into a vein to highlight the blood flow—blood appears white on the scan, while noncalcified plaque shows up as gray—allowing us to clearly distinguish areas of concern.`,
    url: `https://vimeo.com/1071274032`,
    duration: `2:01`
  }, {
    question: `At what age should you get this scan?`,
    answer: `If you have risk factors—like high cholesterol, diabetes, obesity, smoking, or a family history of heart disease—you should consider getting scanned at age 40 if you're a man, and 50 if you're a woman. And if a parent or close relative had a heart attack at a young age, it's wise to get screened even earlier, ideally 10–15 years before their event. For those without major risk factors, screening is recommended at age 50 for men and 60 for women. Women generally have about a 10-year protection advantage over men, yet heart disease remains the leading cause of death for both—affecting one in three men and one in three women. To put that into perspective, one in eight women die of breast cancer, yet heart disease is often underdiagnosed and undertreated in women, despite its greater impact.`,
    url: `https://vimeo.com/1071274294`,
    duration: `0:58`
  }, {
    question: `If you are actively treating heart disease, how often should you get a coronary CTA?`,
    answer: `We generally start to get concerned when there's about 50% blockage in an artery. At that point, we want to closely monitor whether things are improving or worsening. If the narrowing progresses to around 70%, that's typically when we begin considering stents. For someone with 50% stenosis, we'll usually recommend a follow-up scan in a year to see if lifestyle changes or medical therapy are making a difference. If the blockage is getting worse, we may need to be more aggressive with treatment. If it's improving, that's exactly what we want to see. For people with just a small amount of noncalcified plaque, a follow-up every three to five years is usually sufficient. But once it starts to become concerning, annual follow-up is the standard.`,
    url: `https://vimeo.com/1071274237`,
    duration: `1:03`
  }, {
    question: `How should you weigh the high price tag of a coronary CTA against alternative tests?`,     
    answer: `A carotid intimal medial thickness (CIMT) ultrasound is a relatively affordable test—typically a few hundred dollars—that gives a clear view of the carotid arteries. Since the carotids are close to the surface, it's easy to evaluate them with an ultrasound probe. If plaque is found, it should be treated, because what's happening in the carotids often reflects what's happening in the coronary arteries. The coronary calcium score is also a useful, widely available tool to detect calcified plaque. But it doesn't tell the whole story. Many people with a calcium score of zero still have noncalcified plaque—sometimes even a moderate amount—which can be dangerous. So a zero score doesn't always mean you're in the clear.  While a coronary CT angiogram may seem expensive compared to everyday costs, consider that this is about your long-term health. If your blood markers are normal and everyone in your family lives to 90, you may not need a coronary CTA in your 40s or even 50s. But in many cases, this scan provides life-saving insight. Yes, it's more expensive because it requires specialized equipment, technicians, and doctors—but the information it provides is incredibly powerful.`,
    url: `https://vimeo.com/1071274174`,
    duration: `2:43`
  }, {
    question: `Is there a better test for heart health than this scan?`,  
    answer: `Another way to assess plaque in the arteries is through endovascular ultrasound, where a catheter with an ultrasound probe is inserted directly into the carotid or coronary arteries. While this method may offer slightly more detail, it's invasive. In contrast, a coronary CT angiogram provides an exceptional, noninvasive view of the arteries and is currently the best test for early detection. Stress tests, on the other hand, often don't show anything abnormal until there's already 70% blockage—and by that point, it's late in the game. We want to identify and treat heart disease much earlier, before it becomes severe. The goal is proactive care, not waiting until symptoms appear. With the right treatments, we can clear out plaque and significantly improve quality and length of life. Ultimately, we want our patients to not only live to 100—but to feel good when they get there.`,
    url: `https://vimeo.com/1071274148`,
    duration: `1:00`
  }, {
    question: `If you're very proactive about longevity, at what point should you get a coronary CTA?`,
    answer: `Family history plays a major role in deciding when to get a coronary CTA. If you have a relative who had a heart attack in their 50s, it's wise to get scanned in your 40s—or even your 30s—because the goal is to catch disease far in advance, not after symptoms or events occur. As a general guideline, we recommend scanning at age 50 for men and 60 for women, or 10 years earlier if you have risk factors. But in cases of strong family history, especially if a parent had a heart attack at a young age, it's worth considering a scan 10 to 15 years before their first event. It's always better to identify risk early, when it's still preventable.`,
    url: `https://vimeo.com/1071274105`,
    duration: `1:09`
  }]

  // Handle drawer close
  const handleClose = () => {
    // Clear selected video
    setSelectedVideo(null);
    // Call parent onClose
    if (onClose) onClose();
  };

  useEffect(() => {
    if (visible) {
      drawerOpenTimeRef.current = Date.now();
      setIsInitialOpen(true);
      setSelectedVideo(videos[0].url);
      
      setTimeout(() => {
        const videoContainer = document.querySelector('.selected-video-container');
        if (videoContainer) {
          videoContainer.focus();
        }
      }, 100);
    } else {
      setIsInitialOpen(false);
    }
  }, [visible]);
  
  useEffect(() => {
    if (isInitialOpen && selectedVideo && selectedPlayerRef.current) {
      const playVideo = () => {
        try {
          const internalPlayer = selectedPlayerRef.current.getInternalPlayer();
          
          if (internalPlayer) {
            if (typeof internalPlayer.play === 'function') {
              internalPlayer.play();
            }
          }
          
          if (selectedPlayerRef.current && typeof selectedPlayerRef.current.play === 'function') {
            selectedPlayerRef.current.play();
          }
          
          setIsVideoPaused(false);
          
          setIsInitialOpen(false);
        } catch (e) {
          console.error('Error playing video after drawer open:', e);
        }
      };
      
      playVideo();
      
      const handleDocumentClick = () => {
        if (Date.now() - drawerOpenTimeRef.current < 5000) {
          playVideo();
        }
        document.removeEventListener('click', handleDocumentClick);
      };
      
      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [isInitialOpen, selectedVideo]);

  const onSelectVideo = (videoFile) => {
    setSelectedVideo(videoFile);
    setIsVideoPaused(false);
    
    setTimeout(() => {
      if (selectedPlayerRef.current) {
        try {
          if (typeof selectedPlayerRef.current.play === 'function') {
            selectedPlayerRef.current.play();
          }
          
          const internalPlayer = selectedPlayerRef.current.getInternalPlayer();
          if (internalPlayer) {
            if (typeof internalPlayer.play === 'function') {
              internalPlayer.play();
            }
          }
        } catch (e) {
          console.error('Error playing video after selection:', e);
        }
      }
      
      const selectedVideoElement = document.querySelector('.selected-video-container');
      if (selectedVideoElement) {
        selectedVideoElement.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
        
        selectedVideoElement.focus();
      }
    }, 100);
  };

  // Load watched videos from localStorage on component mount
  useEffect(() => {
    try {
      const savedWatchedVideos = localStorage.getItem('cleerly-watched-videos');
      if (savedWatchedVideos) {
        setWatchedVideos(JSON.parse(savedWatchedVideos));
      }
    } catch (error) {
      console.error('Error loading watched videos from localStorage:', error);
    }
  }, []);
  
  // Save watched videos to localStorage whenever they change
  useEffect(() => {
    try {
      localStorage.setItem('cleerly-watched-videos', JSON.stringify(watchedVideos));
    } catch (error) {
      console.error('Error saving watched videos to localStorage:', error);
    }
  }, [watchedVideos]);

  // Handle video end - mark as watched and handle next video logic
  const handleVideoEnd = () => {
    if (!selectedVideo) return;
    
    // Mark current video as watched
    setWatchedVideos(prev => ({
      ...prev,
      [selectedVideo]: true
    }));
    
    // Find the current index in the mp4Files array
    const currentIndex = videos.findIndex(({ url }) => url === selectedVideo);
    
    // Check if this is the last video in the list
    if (currentIndex === videos.length - 1) {
      // If it's the last video, just clear the selection
      setSelectedVideo(null);
    } else {
      // Otherwise, move to the next video
      setSelectedVideo(videos[currentIndex + 1].url);
    }
  };

  // Handle player state changes
  const handlePlayerStateChange = (state) => {
    setIsVideoPaused(!state.playing);
  };

  return (
    <Drawer
      title={title}
      placement={placement}
      width={drawerWidth}
      onClose={handleClose}
      open={visible}
      className="cta-videos-drawer"
      destroyOnClose={false}
    >
      <div className="cta-videos">
        {selectedVideo && (
          <div 
            className="selected-video-container"
            tabIndex="-1"
          >
            <div className="video-wrapper">
              <ReactPlayer
                ref={selectedPlayerRef}
                url={selectedVideo}
                controls={true}
                width="100%"
                height="100%"
                playing={true}
                muted={true}
                playsinline={true}
                volume={1}
                onError={(e) => console.error("Video playback error:", e)}
                onEnded={handleVideoEnd}
                onProgress={handlePlayerStateChange}
                onReady={() => {
                  if (selectedPlayerRef.current) {
                    setTimeout(() => {
                      try {
                        const internalPlayer = selectedPlayerRef.current.getInternalPlayer();
                        if (internalPlayer && typeof internalPlayer.play === 'function') {
                          internalPlayer.play();
                        }
                      } catch (e) {
                        console.error('Error playing on ready:', e);
                      }
                    }, 100);
                  }
                }}
                className="selected-ct-video"
                config={{
                  vimeo: {
                    playerOptions: {
                      responsive: true,
                      autopause: true,
                      autoplay: true,
                      playsinline: true,
                      dnt: true,
                      muted: true,
                      volume: 1,
                      background: false,
                      playerOptions: {
                        allow: "autoplay; fullscreen; picture-in-picture",
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        )}

        <div className="ct-videos-list">
          {videos.map((video, index) => (
            <div key={`ct-video-${index}`} className={classNames("ct-video-container", selectedVideo === video.url ? "selected-ct-video-container" : "")}>
              <Row gutter={24}>
                <Col 
                  xs={0}
                  sm={9}
                  className="ct-video-overlay-container"
                >
                  <div 
                    className="ct-video-overlay"
                    onClick={() => onSelectVideo(video.url)}
                  >
                    <PlayCircleOutlined className="ct-video-icon" />
                    
                    <div 
                      className="vimeo-thumbnail" 
                      style={{
                        backgroundImage: `url(https://vumbnail.com/${video.url.split('/').pop()}.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        cursor: 'pointer'
                      }}
                    />

                    <Text className="video-duration">
                      {video.duration}
                    </Text>
                  </div>
                </Col>
                <Col 
                  xs={24}
                  sm={15}
                >
                  <div className="video-info">
                    <Paragraph 
                      className="video-title"
                      ellipsis={{
                        rows: browserWidth < Breakpoint.MD ? 5 : 1
                      }}
                    >
                      {video.question}
                    </Paragraph>
                    <Paragraph 
                      className="video-caption"
                      ellipsis={{
                        rows: 2
                      }}
                    >
                      {video.answer}
                    </Paragraph>

                    <div className="video-controls">
                      <Button
                        type="default"
                        className="watch-ct-btn"
                        icon={<VideoCameraOutlined />}
                        onClick={() => onSelectVideo(video.url)}
                      >
                        Watch Video
                      </Button>
                      
                      {watchedVideos[video.url] && (
                        <Tag 
                          color="success" 
                          icon={<CheckCircleOutlined />}
                          className="watched-tag"
                        >
                          Watched
                        </Tag>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
} 