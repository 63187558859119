import { Button }  from 'antd'
import LatestPosts from '../../../latestPosts/latestPosts.component.js'

const ConfirmInterest = () => {
  return (
    <div className="confirm-interest">
      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

export default ConfirmInterest