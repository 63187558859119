const PanelCode = Object.freeze({
  // BASELINE: '4325',
  // FOLLOWUP: '5325', 
  // BASIC: 'Basic',
  CUSTOM: 0,
  ADVANCED_BASELINE: 1,
  ADVANCED_FOLLOWUP: 2,
  ATHLETE: 3,
  TESTOSTERONE: 4,
  BIOMETRICS: 5,
  ALLERGY: 6,
  THYROID: 7,
  GALLERI: 8,
  CHOLESTEROL_THERAPY: 9,
  HEART_HEALTH: 10,
  CHOLESTEROL_BALANCE: 11,
  FERTILITY: 12,
  OMEGA: 13,
  NMR_LIPOPROFILE: 14,  
  DEXA_BODYCOMP: 15,
  CAC: 16,
  SLEEP: 17,
  VO2MAX: 18,
  PERFORMANCE: 19,
  CCTA: 20,
  MYCOTOX: 21,
  FIT_STOOL: 22,
  ECG: 23,
  STRESS_TEST: 24,
  CMP: 25,
  LIPID: 26,
  ANEMIA: 27,
  CBC_DIFF: 28,
  DEXA_BONE: 29,
  SPERM: 30,
  URINALYSIS: 31,
  FATIGUE: 32,
  CELIAC: 33,
  BLOOD_TYPE: 34,
  HEAVY_METALS: 35,
  LYME_AB_SCREEN: 36,
  HEPATIC: 37,
  INSULIN_RESISTANCE: 38,
  ATTIA: 39,
  THYROID_ANTIBODIES: 40,
  STEROLS: 41,
  ANA_SCREEN_REFLEX: 42, 
  AMYLASE_ISOENZYMES: 43,
  OMEGACHECK: 44,
})

export default PanelCode