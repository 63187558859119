import { Outlet } from 'react-router-dom';
import { Header } from '../header/header.component';
import './noFooterLayout.scss'

const NoFootertLayout = () => {
  return (
    <>
      <Header hasSearch={true} />
      <Outlet />
    </>
  )
};

export default NoFootertLayout