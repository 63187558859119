import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'
import QueryStringHelper from '../helpers/queryString.helper'

export const getCardiovascularAnalysis = async (id, params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/cardiovascular-analysis?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getPatientDataCounts = async (id, params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/counts?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getPrescriptionStats = async (id, params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/prescription-stats?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const listPatients = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const listMembers = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/members?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const listHHMembers = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/hhmembers?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const acceptInvite = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/accept-invite`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addReportForPatient = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/reports`, ServiceHelper.getParams(), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getPatient = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addPatient = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const uploadFile = async (id, file, fileType) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/upload-file/${fileType}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  });
  return response.data;
};

export const getFileLink = async (id, fileType) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/file/${fileType}`);
  return response.data;
};

export const invitePatient = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/invite`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updatePatient = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removePatient = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listProviders = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/providers`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}

export const addProviderToPatient = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/providers`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  });
  return response.data;
};

export const removeProviderFromPatient = async (id, providerId) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/providers/${providerId}`, {
    headers: ServiceHelper.getHeaders()
  });
  return response.data;
};

// export const listRecommendations = async (id) => {
//   console.log("listRecommendations", id)
//   const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/recommendations`, {
//     headers: ServiceHelper.getHeaders(),
//   })
//   return response.data
// }
