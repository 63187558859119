import { Typography } from "antd";
import classNames from "classnames";

const { Paragraph } = Typography

export const EvaluateMedicationPost = () => {
  return (
    <div className={classNames("blog-post", "evaluate-medication-post")}>
      <div className="blog-border" /><br/><br/>

      <div className="blog-body">
        <Paragraph className="blog-title">
          Step 1: Identify the Key Risk Factors That Matter
        </Paragraph>
        <Paragraph className="blog-description">
          To make an informed decision, it's crucial to determine which variables actually influence your risk of a heart attack or stroke. Among all available markers, ApoB (Apolipoprotein B) is one of the most accessible and powerful biomarker for assessing cardiovascular risk. ApoB reflects the number of atherogenic (plaque-forming) particles in your blood, making it a strong predictor of cardiovascular events.
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>Example:</strong> If your ApoB is 120 mg/dL, your risk of a major cardiac event (heart attack or stroke) within the next 10 years might be around 15-20% (depending on other risk factors like age, smoking, and hypertension).  
          </li>
          <li>
            <strong>If untreated:</strong> That means out of 100 people with similar risk, about 15-20 will experience a heart attack or stroke in the next decade.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Step 2: Assess How Much the Medication Lowers That Risk
        </Paragraph>
        <Paragraph className="blog-description">
          Once you've identified your risk, the next step is to determine how much a medication like a statin can lower it. The best way to do this is by looking at well-conducted research. Use platforms like <a onClick={() => window.open("https://consensus.app/search/")} className="blog-link">Consensus</a> to find large-scale, randomized controlled trials and meta-analyses that provide reliable data.
        </Paragraph>

        <Paragraph className="blog-description">
          It's important to read the research yourself. Relying on secondhand interpretations—whether from news articles, social media, or even doctors—can lead to misunderstandings, as information is often cherry-picked or misrepresented. Scientific studies include key details like participant demographics, effect sizes, and statistical significance that can completely change the interpretation of the findings. Without these details, you may not get an accurate picture of the true risk and benefit.
        </Paragraph>

        <Paragraph className="blog-description">
          To see how research applies to real-world decisions, consider the following example:
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>Meta-analysis:</strong> A meta-analysis of 26 trials with over 170,000 participants found that every 39 mg/dL reduction in LDL cholesterol lowers major vascular events by 21% (<a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(14)61368-4', '_blank')} className="blog-link">Baigent et al., 2005</a>).
          </li>
          <li>
            If your baseline LDL is 140 mg/dL and a statin reduces it to 100 mg/dL, your 10-year risk of a heart attack or stroke might drop from 20% to 16% or lower.
          </li>
          <li>
            More aggressive LDL lowering (to around 70 mg/dL) could push that risk down to 10-12%, meaning 8-10 fewer people out of 100 would have a heart attack or stroke over 10 years.
          </li>
        </ul>

        <Paragraph className="blog-description">
          Knowing your baseline risk and understanding the expected risk reduction from medication allows you to make a well-informed decision about whether the benefits outweigh potential risks.
        </Paragraph>

        <Paragraph className="blog-title">
          Step 3: Evaluate the Risks of the Medication
        </Paragraph>
        <Paragraph className="blog-description">
          All medications have potential side effects, but the question is: how often do they occur, and how severe are they?
        </Paragraph>

        <ul className="blog-list">
          <li><strong>Muscle pain (myalgia):</strong> Reported by ~10% of statin users, but placebo-controlled trials suggest the actual rate of statin-induced muscle pain is much lower (1-2%) (<a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(16)31357-5', '_blank')} className="blog-link">Collins et al., 2016</a>).</li>
          <li><strong>Serious muscle damage (rhabdomyolysis):</strong> Extremely rare, occurring in fewer than 1 in 10,000 people.</li>
          <li><strong>Diabetes risk:</strong> Statins may slightly increase diabetes risk, with a 9-12% relative increase in new-onset diabetes, primarily in those with pre-existing risk factors (<a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(09)61965-6', '_blank')} className="blog-link">Sattar et al., 2010</a>). However, the absolute increase in risk is small (~1 additional case per 200 statin users over five years).</li>
        </ul>

        <Paragraph className="blog-description"> 
          Even if you fall into one of these small risk categories, it's also important to remember that side effects aren't an all-or-nothing scenario—if one medication causes issues, there are always alternatives to try.
        </Paragraph>

        <Paragraph className="blog-title">
          Step 4: Compare Risk Reduction vs. Side Effects
        </Paragraph>
        <Paragraph className="blog-description">
          Let's assume you're debating whether to take a statin and you have a 20% risk of heart attack or stroke in 10 years. A statin might reduce that to 12%, meaning 8 fewer heart attacks or strokes per 100 people.
        </Paragraph>

        <ul className="blog-list">
          <li><strong>Serious statin side effects</strong> (rhabdomyolysis, severe liver issues): Less than 0.1% chance.</li>
          <li><strong>Mild side effects</strong> (muscle pain, mild blood sugar changes): 1-10% chance.</li>
          <li><strong>Prevented heart attacks/strokes:</strong> 8% absolute reduction in risk.</li>
        </ul>

        <Paragraph className="blog-description">
          For most people at high cardiovascular risk, the benefit-to-risk ratio strongly favors taking a statin.
        </Paragraph>

        <Paragraph className="blog-title">
          Step 5: Consider Your Personal Context
        </Paragraph>
        <Paragraph className="blog-description">
          Medications aren't one-size-fits-all. Factors like family history, lifestyle, and personal health goals matter. For example:
        </Paragraph>

        <ul className="blog-list">
          <li>If your ApoB is only slightly elevated and you have no other risk factors, lifestyle changes alone (diet, exercise) might be sufficient.</li>
          <li>If your ApoB is high and you've had a prior cardiovascular event, the evidence overwhelmingly supports statin use.</li>
          <li>If you're at borderline risk and concerned about side effects, you might try a lower dose.</li>
        </ul>

        <Paragraph className="blog-title">
          The Bottom Line
        </Paragraph>
        <Paragraph className="blog-description">
          Instead of fearing medications or blindly accepting them, evaluate them with a risk-reward framework. For statins, the numbers show that for those at moderate-to-high cardiovascular risk, the benefits far outweigh the risks. And this same logical approach applies to any medication decision—evaluate the data, weigh your personal risks, and make an informed choice.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Fulcher, J., O'Connell, R., Voysey, M., et al. (2015). Efficacy and safety of LDL-lowering therapy among men and women: meta-analysis of individual data from 174 000 participants in 27 randomised trials. The Lancet, 385, 1397-1405. <a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(14)61368-4', '_blank')}>https://doi.org/10.1016/S0140-6736(14)61368-4</a>
            </li>
            <li>
              Collins, R., Reith, C., Emberson, J., et al. (2016). Interpretation of the evidence for the efficacy and safety of statin therapy. The Lancet, 388, 2532-2561. <a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(16)31357-5', '_blank')}>https://doi.org/10.1016/S0140-6736(16)31357-5</a>
            </li>
            <li>
              Sattar, N., Preiss, D., & Murray, H. (2010). Statins and risk of incident diabetes: a collaborative meta-analysis of randomised statin trials. The Lancet, 375, 735-742. <a onClick={() => window.open('https://doi.org/10.1016/S0140-6736(09)61965-6', '_blank')}>https://doi.org/10.1016/S0140-6736(09)61965-6</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};