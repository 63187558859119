import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Dropdown, message, Modal, Typography, Tooltip, Select } from 'antd'
import { 
  listAppointmentParents, 
  removeAppointmentParent 
} from "../../services/appointment.service"
import { SettingOutlined, DeleteOutlined, EditOutlined, EnvironmentOutlined, LinkOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons'
import './adminAppointmenParents.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AppointmentParentForm } from '../appointmentParentForm/appointmentParentForm.component.js';
import ObjectType from '../../enums/objectType.enum';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import AppointmentParentHelper from '../../helpers/appointmentParent.helper.js';
import classNames from 'classnames';
import { UserContext } from '../../contexts/user.context.js';
import { PatientSelect } from '../patientSelect/patientSelect.component.js';
import { joinAppointmentParent, removeAppointment } from '../../services/appointment.service.js';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import Role from '../../enums/role.enum';
import AppointmentStatus from '../../enums/appointmentStatus.enum';
const { Column } = Table
const { Text } = Typography
dayjs.extend(utc)
dayjs.extend(timezone)

const select = 'organizer status start end title location appointments'
const populate = [{
  path: 'organizer',
  select: 'firstName lastName'
}, {
  path: 'phlebotomist',
  select: 'firstName lastName'
}, 
{
  path: 'productTypes',
  select: 'title'
},
{
  path: 'appointments',
  populate: [{
    path: 'patients',
    select: 'firstName lastName dob'
  }, {
    path: 'products',
    populate: [{
      path: 'productType',
      select: 'title'
    }]
  }]
}]

export const AdminAppointmentParents = () => {
  const [appointmentParents, setAppointmentParents] = useState([])
  const [appointmentParentId, setAppointmentParentId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isEditModalOpen, setIsEditModalOpen] = useState()
  const [isAdminLocationModalOpen, setIsLocalModalOpen] = useState()
  const { currentUser, setCounts } = useContext(UserContext)
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState(null)
  const [selectedProductType, setSelectedProductType] = useState(null)
  const [isAppointmentsModalOpen, setIsAppointmentsModalOpen] = useState(false)
  const [selectedAppointments, setSelectedAppointments] = useState([])

  useEffect(() => {
    document.title = 'Instalab | Events'
    fetchAppointmentParents()
  }, [])

  const fetchAppointmentParents = async () => {
    let filter = {}
    if (currentUser?.role === Role.PROVIDER) {
      filter = {
        organizer: currentUser._id
      }
    }

    setAppointmentParents(await listAppointmentParents({
      select,
      populate,
      filter: filter
    }))
    setIsLoading(false)
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this event?',
      content: 'This action cannot be undone and will permanently remove the event from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeAppointmentParent(_id);
          setAppointmentParents(appointmentParents.filter(appointmentParent => appointmentParent._id !== _id));
          message.info('AppointmentParent removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              events: cachedCounts.events - 1
            }
          })
        } catch (err) {
          message.error('Failed to remove appointmentParent');
        }
      }
    });
  }

  const items = (_id) => [
    {
      key: 'add',
      label: (
        <a onClick={() => {
          setAppointmentParentId(_id)
          setIsAppointmentModalOpen(true)
        }}>
          <PlusOutlined style={{ marginRight: 5 }} /> Add Attendee
        </a>
      )
    },
    { 
      key: 'view',
      label: (
        <a onClick={() => {
          setAppointmentParentId(_id)
          setSelectedAppointments(appointmentParents.find(appointmentParent => appointmentParent._id === _id).appointments)
          setIsAppointmentsModalOpen(true)
        }}>
          <EyeOutlined style={{ marginRight: 5 }} /> View Attendees
        </a>
      )
    },
    {
      type: 'divider'
    }, 
    {
      key: '0',
      label: (
        <a onClick={() => AppointmentParentHelper.copyJoinLink(_id, message)}>
          <LinkOutlined style={{ marginRight: 5 }} /> Copy Join Link
        </a>
      ),
      adminOnly: true
    },
    currentUser?.role === Role.ADMIN && {
    key: '1',
    label: (
      <a onClick={() => {
        setAppointmentParentId(_id)
        setIsEditModalOpen(true)
      }}>
        <EditOutlined style={{ marginRight: 5 }} /> Edit
      </a>
    )
  },{
    key: '2',
    label: (
      <a onClick={() => {
        setAppointmentParentId(_id)
        setIsLocalModalOpen(true)
      }}>
        <EnvironmentOutlined style={{ marginRight: 5 }} /> Set Location
      </a>
    )
  }, 
  currentUser?.role === Role.ADMIN && {
    type: 'divider'
  }, 
  currentUser?.role === Role.ADMIN && {
    key: '4',
    label: (
      <a 
        onClick={() => onRemove(_id)}
        className="remove-item"
      >
        <DeleteOutlined style={{ marginRight: 5 }} /> Remove
      </a>
    )
  }]

  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }

  const handleAddPatient = async () => {
    try {
      await joinAppointmentParent(appointmentParentId, {
        productTypeId: selectedProductType,
        userId: selectedPatientId,
        payLater: true
      })

      message.success('Patient added to event')
      setIsAppointmentModalOpen(false)
      setSelectedPatientId(null)
      setSelectedProductType(null)
      // Optionally refresh the appointment parents list
      await fetchAppointmentParents()
    } catch (error) {
      message.error('Failed to add patient to event')
    }
  }

  const handleRemoveAppointment = async (appointmentId) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this patient from this event?',
      content: 'This action cannot be undone.',
      okText: 'Yes, remove it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeAppointment(appointmentId)
          setAppointmentParents(appointmentParents.map(p => p._id === appointmentParentId ? {
            ...p,
            appointments: p.appointments.filter(a => a._id !== appointmentId)
          } : p))

          setSelectedAppointments(selectedAppointments.filter(appointment => appointment._id !== appointmentId))
          message.success('Attendee removed successfully')
        } catch (error) {
          message.error('Failed to remove attendee')
        }
      }
    });
  }

  return (
    <div className="admin-events">
      <PageHeader
        title='Events'
        count={appointmentParents.length}
        actions={(
          currentUser?.role === Role.ADMIN && (
          <Button
            type='primary'
            onClick={() => {
              setAppointmentParentId(null);
              setIsEditModalOpen(true)
            }}
          >
            + Add New Event
          </Button>
          ))}
      />

      <AdminLocationModal
        objectId={appointmentParentId}
        objectType={ObjectType.APPOINTMENT_PARENT}
        open={isAdminLocationModalOpen}
        setOpen={setIsLocalModalOpen}
        select={select}
        populate={populate}
        onSuccess={appointmentParent => {
          setAppointmentParents(appointmentParents.map(p => p._id === appointmentParentId ? appointmentParent : p))
        }}
      />

      <AppointmentParentForm
        appointmentParentId={appointmentParentId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        select={select}
        populate={populate}
        onSuccess={appointmentParent => {
          if (appointmentParentId) {
            setAppointmentParents(appointmentParents.map(p => p._id === appointmentParentId ? appointmentParent : p))
          } else {
            setAppointmentParents([
              appointmentParent,
              ...appointmentParents
            ])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                events: cachedCounts.events + 1
              }
            })
          }
        }}
      />
       <Modal
        title="Add Patient to Event"
        open={isAppointmentModalOpen}
        onCancel={() => {
          setIsAppointmentModalOpen(false)
          setSelectedPatientId(null)
          setSelectedProductType(null)
        }}
        footer={[
          <Button key="cancel" onClick={() => setIsAppointmentModalOpen(false)}>
            Cancel
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleAddPatient}
            disabled={!selectedPatientId || !selectedProductType}
          >
            Add Patient
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <PatientSelect
            value={selectedPatientId}
            onChange={setSelectedPatientId}
            placeholder="Select a patient"
            style={{ width: '100%' }}
          />
          <Select
            value={selectedProductType}
            onChange={setSelectedProductType}
            placeholder="Select panel"
            style={{ width: '100%' }}
          >
            {appointmentParents.find(appointmentParent => appointmentParent._id === appointmentParentId)?.productTypes?.map(productType => (
              <Select.Option value={productType._id}>{productType.title}</Select.Option>
            ))}
          </Select>
        </div>
      </Modal>

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={appointmentParents}
      >

        <Column
          title='Status'
          dataIndex='status'
          width={105}
          render={status => <Text className={classNames(`${status}-status`, "appointment-status")}>{status}</Text>}
        />

        <Column
          title='Event'
          dataIndex='title'
          render={(title, { location }) => {
            if (!title) return null
            
            if (location) {
              const { streetAddress, streetAddress2, city, state, postalCode } = location
              const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
              return <div>{title}<br /><span style={{ fontSize: '12px' }}>{fullAddress}</span></div>
            }
            return title
          }}
        />

        <Column
          title='Provider'
          dataIndex='phlebotomist'
          render={phlebotomist => phlebotomist && `${phlebotomist.firstName} ${phlebotomist.lastName}`}
        />

        {currentUser?.role === Role.ADMIN && <Column
          title="Organizer"
          dataIndex="organizer"
          render={organizer =>  organizer?  `${organizer.firstName} ${organizer.lastName}` : 'n/a'}
        />  
        }

        <Column
          title="#"
          dataIndex='appointments'
          render={(appointments) => appointments?.length}
        />
        
      
        <Column
          title='Start'
          dataIndex='start'
          render={(start, { location }) => start && convertTimeZone(start, location).format('MMM D, YYYY')}
        />

        <Column
          dataIndex='startTime'
          render={(_, { start, location }) => start && convertTimeZone(start, location).format('h:mma z')}
        />

        <Column
          title='End'
          dataIndex='end'
          render={(end, { location }) => end && convertTimeZone(end, location).format('MMM D, YYYY')}
        />

        <Column
          dataIndex='endTime'
          render={(_, { end, location }) => end && convertTimeZone(end, location).format('h:mma z')}
        />

        <Column
          dataIndex='action-items'
          width={80}
          className="action-column"
          render={(_, { _id, status }) => (
            <>
       
            {(status === AppointmentStatus.CONFIRMED || currentUser?.role === Role.ADMIN) && (
            <Dropdown 
              menu={{ items: items(_id) }}
              placement='bottomRight'
            >
              <Button icon={<SettingOutlined />} />
            </Dropdown>
            )}
            </>
          )}
        />
      </Table>

      <Modal
        title="Appointments"
        open={isAppointmentsModalOpen}
        onCancel={() => setIsAppointmentsModalOpen(false)}
        footer={null}
        width={800}
      >
        <Table
          dataSource={selectedAppointments}
          rowKey="_id"
          pagination={false}
        >
          <Column
            title="Patient"
            dataIndex="patients"
            render={patients => `${patients[0]?.firstName} ${patients[0]?.lastName}`}
          />
          <Column
            title="Appointment Type"
            dataIndex="products"
            render={products => products[0]?.productType?.title}
          />
        
        <Column
          width={80}
          render={(_, record) => (
            <>


            <Button
              type="link"
              danger
              onClick={() => handleRemoveAppointment(record._id)}
              icon={<DeleteOutlined />}
            >
           
            </Button>
            </>
          )}
          />

        </Table>
      </Modal>
    </div>
  )
}