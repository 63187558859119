import React, { useState, useContext, useEffect } from 'react';
import { Table, Modal, Button, Dropdown, message } from 'antd';
import { UserContext } from '../../contexts/user.context';
import { listFiles, downloadFile, deleteFile, renameFile, getSignedUrl, listFilesByPatient } from '../../services/gdrive.service';
import { listPatients, getPatient } from '../../services/patient.service';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { SettingOutlined, DeleteOutlined, EditOutlined, DownloadOutlined, SendOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SendFileModal } from '../sendFileModal/sendFileModal.component';
import { UploadFileModal } from '../uploadFileModal/uploadFileModal.component';
import './files.scss';
import UrlHelper from '../../helpers/url.helper';
import { useParams } from 'react-router-dom';
import Role from '../../enums/role.enum';

const { Column } = Table;

export const Files = () => {
  const { currentUser } = useContext(UserContext);
  const { patientId } = useParams()
  const [isLoading, setIsLoading] = useState(true);
  const [patients, setPatients] = useState([]);
  const [files, setFiles] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSendModalVisible, setIsSendModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchPatientsAndFiles();
  }, [patientId, currentUser]);

  const fetchPatientsAndFiles = async () => {
    try {
      setIsLoading(true);

      if (patientId) {
        const filesResponse = await listFilesByPatient(patientId);
        setFiles(filesResponse);
        const patientsResponse = [await getPatient(patientId)];
        setPatients(patientsResponse);
      } else if (currentUser?.role === Role.ADMIN) {
        const fetchedPatients = await listPatients();
        const patientIds = new Set(fetchedPatients.map(patient => patient._id));            
        const fetchedFiles = await listFiles();
        const filesResponse = fetchedFiles.filter(file => file.folderName && patientIds.has(file.folderName));
        setPatients(fetchedPatients);
        setFiles(filesResponse);  
      } else if (currentUser) {
        const filesResponse = await listFilesByPatient(currentUser._id);
        setFiles(filesResponse);
        const patientsResponse = [currentUser];
        setPatients(patientsResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFile = async (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this file?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          await deleteFile(id); 
          fetchPatientsAndFiles();
          message.success('File deleted successfully');
        } catch (error) {
          console.error('Failed to delete file:', error);
          message.error('Failed to delete file');
        }
      },
      onCancel() {
        console.log('Deletion cancelled');
      },
    });
  };

  const handleRenameFile = async (file) => {
    const newName = prompt('Enter the new name:', file.name);
    if (newName && newName.trim() !== '') {
      try {
        await renameFile(file.id, newName);
        message.info('File renamed successfully');
        fetchPatientsAndFiles();
      } catch (error) {
        console.error('Failed to rename file:', error);
        message.error('Failed to rename file');
      }
    }
  };

  const handleSendFileClick = (file) => {
    setSelectedFile(file);
    setIsSendModalVisible(true);
  };

  const viewFile = async (fileId) => {
    try {
      const signedUrl = await getSignedUrl(fileId);
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error("Error viewing file:", error);
      alert("Failed to view file");
    }
  };

  const FileListItem = ({ file }) => (
    <div>
      <img src={file.iconLink} style={{ width: 12, marginRight: 5, marginBottom: -1 }} />
      <a className="link" onClick={() => viewFile(file.id)} target="_blank" rel="noopener noreferrer">{file.name}</a>
    </div>
  );

  const items = (file) => [
    {
      key: 'download',
      label: (
        <a onClick={() => downloadFile(file.id, file.name)}>
          <DownloadOutlined style={{marginRight: 5}} /> Download 
        </a>
      ),
    },
    {
      key: 'send',
      label: (
        <a onClick={() => handleSendFileClick(file)}>
          <SendOutlined style={{marginRight: 5}} /> Fax
        </a>
      ),
    },
    {
      key: 'rename',
      label: (
        <a onClick={() => handleRenameFile(file)}>
          <EditOutlined style={{marginRight: 5}} /> Rename File
        </a>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'delete',
      label: (
        <a 
          onClick={() => handleDeleteFile(file.id)}
          className="remove-item"
        >
          <DeleteOutlined style={{marginRight: 5}} /> Delete
        </a>
      ),
    },
  ];

  return (
    <>  
      {patients && (
        <div className="files">
          <UploadFileModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            patients={patients}
            fetchPatientsAndFiles={fetchPatientsAndFiles}
          />
          <SendFileModal 
            selectedFile={selectedFile}
            isSendModalVisible={isSendModalVisible}
            setIsSendModalVisible={setIsSendModalVisible}
          />
          <PageHeader 
            title='Health Files' 
            description="Store any medical related documents here"
            actions={[<Button key="1" type="primary" onClick={() => setIsModalVisible(true)}>+ Add File</Button>]}
          />

          <Table
            loading={isLoading}
            size="small"
            className="primary-table"
            pagination={false}
            rowKey="id" 
            dataSource={files}
          >
            {!patientId && currentUser?.role === Role.ADMIN && 
            <Column
              title="Patient"
              dataIndex="folderName"
              key="folderName"
              render={folderName => {
                const matchingPatient = patients.find(patient => patient._id === folderName);
                return matchingPatient ? (
                  <a className="link" onClick={() => {
                    window.open(UrlHelper.getPatientProfile(matchingPatient._id, 'Files'), '_blank');
                  }}>
                    {matchingPatient.firstName} {matchingPatient.lastName}
                  </a>
                ) : 'N/A';
              }}
            />}
            <Column
              title="Filename"
              key="action"
              render={(text, file) => <FileListItem file={file} />}
              onCell={() => ({
                'data-label': 'Filename' // Adding data-label for mobile display
              })}
            />
            <Column
              title="Date added"
              key="createdTime"
              dataIndex="createdTime"
              render={(text) => moment(text).format('MM/DD/YY')}
              onCell={() => ({
                'data-label': 'Date added' // Adding data-label for mobile display
              })}
            />
            <Column
              dataIndex='remove'
              width={50}
              className="action-column"
              render={(_, file) => (
                <Dropdown 
                  menu={{ items: items(file) }}
                  placement='bottomRight'
                >
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              )}
              onCell={() => ({
                'data-label': 'Actions' // Adding data-label for mobile display
              })}
            />
          </Table>
        </div>
      )}
    </>
  );
};
