import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const getPrescriptionEffects = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/effects`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listPrescriptions = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}

export const addPrescription = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getContents = async (type) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/${type}/contents`, ServiceHelper.getParams(), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getPrescription = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const updatePrescription = async (id, params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updatePrescriptionStatus = async (id, params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/${id}/status`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removePrescription = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const listPrescriptionEvents = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/prescriptions/events`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}