import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabBar, ActionSheet } from 'antd-mobile';
import {
  HeartOutline,
  UnorderedListOutline,
  MessageOutline,
  GiftOutline,
  AddCircleOutline
} from 'antd-mobile-icons';
import { UserContext } from "../../contexts/user.context.js";
import FlowType  from "../../enums/flowType.enum.js";
import "./footer.scss";

export const Footer = ({  hasMobile = true }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(''); // Set no tab active by default
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const { token, counts, currentUser } = useContext(UserContext);
  const [actionSheetHandler, setActionSheetHandler] = useState(null);
  
  const showHealthActions = async () => {
    if (isActionSheetOpen) {
      actionSheetHandler?.close();
      setIsActionSheetOpen(false);
      return;
    }

 

    setSelectedTab(''); // Clear selected tab when showing action sheet
    const actions = [ 
      { text: 'Test Results', key: 'results', onClick: () => navigate('/patient/results') },
      { text: 'Latest Report', key: 'report', onClick: () => navigate('/patient/report') },
      { text: 'Game Plan', key: 'plan', onClick: () => navigate('/patient/plan') },
      { 
        text: (
          <span>
            Game Plan <span className="beta-badge">Beta</span>
          </span>
        ), 
        key: 'plan-beta', 
        onClick: () => navigate('/patient/plan-beta') 
      },
      { text: 'Health Profile', key: 'medical', onClick: () => navigate('/patient/medical') },
      { text: 'Health Files', key: 'files', onClick: () => navigate('/patient/files') },
    ].filter(action => {
      if (action.key === 'results') return counts.results > 0;
      if (action.key === 'report' || action.key === 'plan') return counts.reports > 0 && !counts.recommendations;
      if (action.key === 'report' || action.key === 'plan-beta') return counts.recommendations > 0;
      return true;
    });

    setIsActionSheetOpen(true);
    const handler = ActionSheet.show({
      actions,
      closeOnAction: true,
      onClose: () => setIsActionSheetOpen(false),
      safeArea: true,
      cancelText: 'Cancel',
      visible: true
    });
    setActionSheetHandler(handler);
  };

  const showOrdersActions = async () => {
    if (isActionSheetOpen) {
      actionSheetHandler?.close();
      setIsActionSheetOpen(false);
      return;
    }

    setSelectedTab(''); // Clear selected tab when showing action sheet
    const actions = [
      { text: 'Appointments', key: 'appointments', onClick: () => navigate('/patient/appointments') },
      { text: 'Test Kits', key: 'kits', onClick: () => navigate('/patient/kits') },
      { text: 'Prescriptions', key: 'treatments', onClick: () => navigate('/patient/treatments') },
    ];


    setIsActionSheetOpen(true);
    const handler = ActionSheet.show({
      actions,
      closeOnAction: true,
      onClose: () => setIsActionSheetOpen(false),
      cancelText: 'Cancel',
      safeArea: true,
      visible: true
    });
    setActionSheetHandler(handler);
  };


  const onTabChange = (key) => {
    if (key !== 'myHealth' && key !== 'orders') {
      setSelectedTab(key);
    }

    if (key === 'help') {
      navigate('/help');
    } else if (key === 'gift') {
      navigate(`/flow/${FlowType.GIFT}`);
    } else if (key === 'care') {
      navigate('/patient/catalog');
      setSelectedTab('care');
    } else if (key === 'orders') {
      showOrdersActions();
    } else if (key === 'myHealth') {
      showHealthActions();
    }
  };

  return token && hasMobile && (
    <TabBar activeKey={selectedTab} onChange={onTabChange} className="fixed-footer">
      <TabBar.Item
        key="orders"
        icon={<UnorderedListOutline />}
        title="My Orders"
      />
      <TabBar.Item
        key="myHealth"
        icon={<HeartOutline />}
        title="My Health"
      />
      <TabBar.Item
        key="care"
        icon={<AddCircleOutline />}
        title="Order Test"
      />
      <TabBar.Item
        key="gift"
        icon={<GiftOutline />}
        title="Gift"
      />
      <TabBar.Item
        key="help"
        icon={<MessageOutline />}
        title="Help"
      />
    </TabBar>
  );
};