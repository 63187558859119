import DISCOUNT from '../enums/discount.enum'
import { getProductTypeByCode } from '../services/productType.service'
import MembershipHelper from './membership.helper'
import ProductTypeCode from '../enums/productTypeCode.enum'
import { listTaskTemplates, addTask } from '../services/task.service'
import TaskCategory from '../enums/taskCategory.enum'
import Role from '../enums/role.enum'
import PanelData from '../data/panel.data'

const ProductHelper = {

  getTotalCost: (productTypes, instalabMembership, currentUser=null, ignoreCredits=false, priceOverride=null) => {

    if (!productTypes?.filter(Boolean).length || productTypes.length === 0) return null

    if (!Array.isArray(productTypes)) productTypes = [productTypes]


    if (currentUser?.role === Role.PROVIDER) {
      return productTypes.reduce((sum, type) => {
        // Check if provider has credits for this product type
        if (!ignoreCredits && Array.isArray(currentUser?.credits) && currentUser.credits.includes(type.code)) {
          return sum + 0
        }
        return sum + ProductHelper.getProviderCost(type)
      }, 0)
    }

    // Check credits regardless of membership status
    else if (Array.isArray(currentUser?.credits) && currentUser?.credits?.length > 0) {
      const subtotal = productTypes.reduce((sum, type) => {
        if (!ignoreCredits && currentUser.credits.includes(type.code)) {
          return sum + 0
        }

        // Use member price if membership is active, otherwise regular cost
        return sum + (MembershipHelper.isActive(instalabMembership) 
          ? Number(type.memberCost !== undefined && type.memberCost !== null ? priceOverride || type.memberCost : priceOverride || type.cost)
          : Number(priceOverride || type.cost))
      }, 0)
      
      // Deduct cash balance if available
      if (!ignoreCredits) {
        return Math.max(0, subtotal - (currentUser?.cashBalance || 0))
      }
      return subtotal
    }

    // No credits case but membership is active
    else if (MembershipHelper.isActive(instalabMembership)) {
      const subtotal = productTypes.reduce((sum, type) => 
        sum + Number(type.memberCost !== undefined ? priceOverride || type.memberCost : priceOverride || type.cost), 0)

      // Deduct cash balance if available
      if (!ignoreCredits) {
        return Math.max(0, subtotal - (currentUser?.cashBalance || 0))
      }
      return subtotal
    }

    const subtotal = productTypes.reduce((sum, type) => sum + Number(priceOverride || type.cost), 0)
    if (!ignoreCredits) {
      return Math.max(0, subtotal - (currentUser?.cashBalance || 0))
    }
    return subtotal
  },

  
  getTotalCostV2: ({
    productTypes,
    testAddOns = [],
    instalabMembership,
    currentUser = null,
    ignoreCredits = false,
    tests = [],
    panels = [],
    priceOverride = null
  }) => {

    // Handle base product types cost
    let baseCost = 0
    if (productTypes) {
      if (!Array.isArray(productTypes)) productTypes = [productTypes]
      if (!productTypes?.filter(Boolean).length) return null


      if (currentUser?.role === Role.PROVIDER) {
        baseCost = productTypes.reduce((sum, type) => {
          if (!ignoreCredits && Array.isArray(currentUser?.credits) && currentUser.credits.includes(type.code)) {
            return sum + 0
          }
          return sum + ProductHelper.getProviderCost(type)
        }, 0)
      }
      // Check credits regardless of membership status
      else if (Array.isArray(currentUser?.credits) && currentUser?.credits?.length > 0) {
        baseCost = productTypes.reduce((sum, type) => {
          if (!ignoreCredits && currentUser.credits.includes(type.code)) {
            return sum + 0
          }
          return sum + (MembershipHelper.isActive(instalabMembership)
            ? Number(type.memberCost !== undefined && type.memberCost !== null ? priceOverride || type.memberCost : priceOverride || type.cost)
            : Number(priceOverride || type.cost))
        }, 0)
      }
      // No credits case but membership is active
      else if (MembershipHelper.isActive(instalabMembership)) {
        baseCost = productTypes.reduce((sum, type) =>
          sum + Number(type.memberCost !== undefined ? priceOverride || type.memberCost : priceOverride || type.cost), 0)
      }
      else {
        baseCost = productTypes.reduce((sum, type) => sum + Number(priceOverride || type.cost), 0)
      }
    }


    // Add test add-ons cost if any
    let totalCost = baseCost
    if (testAddOns?.length > 0) {
      const testAddOnsCost = ProductHelper.getPanelCost({ addOns: testAddOns, tests, panels, instalabMembership, currentUser })
      totalCost += testAddOnsCost
    }


    // Apply cash balance if not ignoring credits
    if (!ignoreCredits && currentUser?.cashBalance) {
      totalCost = Math.max(0, parseFloat(totalCost - Number(currentUser.cashBalance)).toFixed(2))
    }

    return totalCost
  },

  getPanelCost: ({addOns, tests, panels, instalabMembership, currentUser}) => {
    // tests is an array of all test objects
    // panels is an array of all panel objects
    // addOns is an array of test ids

    const allItems = [...(tests || []), ...(panels || [])]

    const addOnsCost = addOns.reduce((total, testId) => 
      total + (allItems.find(t => t._id === testId)?.cost || 0), 0)

    return Number(addOnsCost.toFixed(2))
  },

  getMonthlyPayment: (price, months, apr) => {
    const P = Number(price)
    const n = Number(months)
    const r = Number(apr)/n
    return (P * r * Math.pow((1+r),n)) / (Math.pow((1+r),n)-1)
  },
  getCost: async (code, isProvider) => {
    const { cost, providerCost } = await getProductTypeByCode(code)

    if (isProvider) {
      return providerCost || cost
    }
    return cost
  },
  getProviderCost: (productType, memberships=[], currentUser) => {
    return productType?.providerCost || productType?.cost
  },
  getMemberPrice: (cost) => {
    if (!cost) return null

    return Math.floor(cost)
  },
  formatCost: (productType, hasDollarSign=true) => {
    if (!productType?.cost) return ''
    let { cost } = productType
    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
  formatPrice: (price, hasDollarSign=true) => {
    if (price === null || price === undefined) return ''
    const hasDecimal = price - Math.round(price) !== 0
    if (hasDecimal) {
      price = price.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      price = `$${price.toLocaleString()}`
    }
    return price
  },
  formatDiscountCost: (productType, instalabMembership, isProvider, hasDollarSign=true) => {
    if (!productType?.cost) return '';
  
    // Calculate the discounted cost
    let cost = productType.cost; //Math.floor(productType.cost * DISCOUNT);

    if (MembershipHelper.isUltimate(instalabMembership) && [ProductTypeCode.CONSULT_LONGEVITY, ProductTypeCode.CONSULT_LONGEVITY_60, ProductTypeCode.LONGEVITY_PANEL].includes(productType.code)) {
      cost = 0
    }

    else if (isProvider) {
      cost = Math.floor(productType.cost * DISCOUNT)
    }
  
    if (hasDollarSign) {
      cost = `$${cost}`;
    }
    
    return cost;
  },
  createTask: async (product) => {

    // create task for when admin adds product
    // note: when patient purchases product, this is triggered on the completeflow flow.controller
    const { patient, productType } = product
    let taskCategory;

    switch (productType.code) {
      case ProductTypeCode.CTCALCIUM:
        taskCategory = TaskCategory.CORONARY_CALCIUM_SCAN
        break;
      case ProductTypeCode.CLEERLY:
        taskCategory = TaskCategory.CCTA
        break;    
      case ProductTypeCode.GALLERI:
        taskCategory = TaskCategory.GALLERI
        break;
      case ProductTypeCode.TOXIC_METALS_URINE_TEST:
        taskCategory = TaskCategory.HEAVY_METALS_TEST
        break;
      case ProductTypeCode.MYCOTOX_PROFILE:
        taskCategory = TaskCategory.MOLD_TOXINS
        break;
      case ProductTypeCode.WATCHPAT_ONE:
        taskCategory = TaskCategory.SLEEP_APNEA_TEST
        break;
      case ProductTypeCode.DIURNAL_CORTISOL_PROFILE:
        taskCategory = TaskCategory.DIURNAL_CORTISOL_PROFILE
        break;
      default:
        break;
    }

    if (taskCategory) {
      let params = {
        select: '',
        filter: {
          category: taskCategory,
          isActive: true,
          isDefault: true
        },
      }
  
      const taskTemplates = await listTaskTemplates(params)
  
      for (const taskTemplate of taskTemplates) {
        const params = {      
          patient,
          assignee: taskTemplate.assignee,
          title: taskTemplate.title,
          subtasks: taskTemplate.subtasks,
          description: taskTemplate.description,
          category: taskTemplate.category,
          timeEstimate: taskTemplate.timeEstimate
        }
        return await addTask(params)
        
      }
  
    }


    return 
    

  },
  getAddOns: (panel, tests) => {
    // Only proceed if there are tests
    if (!tests || tests.length === 0 || !panel) return [];

    const addOns = []
    const panelTestCodes = PanelData[panel]?.testCodes || []    

    // Filter out tests that are not in the panel
    for (const test of tests) {
      if (!panelTestCodes.includes(test.code)) {
        addOns.push(test)
      }
    }


    return addOns
  }
}

export default ProductHelper
