import { useRef, useEffect, useState, useContext } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import { CheckOutlined } from "@ant-design/icons"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { advancedPanel } from "../../data/advancedPanel.data";
import { listProductTypes } from "../../services/productType.service";
import { UserContext } from "../../contexts/user.context";
import FlowType from "../../enums/flowType.enum";
export const Testing = () => {
  const { instalabMembership } = useContext(UserContext)
  const [productTypes, setProductTypes] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductTypes(); 
  }, []);


  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.MOBILE_BLOOD_DRAW,
            ProductTypeCode.CONSULT_LONGEVITY
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  
  // set product attributes
  useEffect(() => {
    if (!productTypes) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
        question: `What’s included in the ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}?`,
        answer: <> 
        You'll receive:
          <ul className="checkmark-list">
            <li><CheckOutlined/><p>Testing of  <a onClick={scrollToPanel}  className="cta-link">60+ biomarkers</a> to assess your overall health.</p></li>
            <li><CheckOutlined/><p>Blood drawn either at a nearby patient service center or at your home with a mobile phlebotomist.</p></li>
	          <li><CheckOutlined/><p>Personalized recommendations to improve your health.</p></li>
          </ul>
      The {getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}, curated by leading medical experts, provides a detailed and holistic view of your health, helping with early detection and actionable guidance for long-term health optimization. 
        </>
      },
      {
        question: "How is the blood collection process handled?",
        answer: `You can visit a patient service center for your blood draw, or for an additional cost of $${getProductType(ProductTypeCode.MOBILE_BLOOD_DRAW)?.cost}, you can schedule a mobile phlebotomist to come to your location. All phlebotomists are highly trained to ensure a smooth and stress-free experience.`
      },
      {
        question: `Can I add other biomarkers to the ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}?`,
        answer: <>
            Yes! You can select from hundreds of other tests to add to your {getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}. Popular choices include Cortisol, DHEA-S, Estradiol, IGF-1, Omega-3 Index, and more.
        </>
    },   
      {
        question: `Can I talk to a physician after I get my results?`,
        answer: <>Yes, members can schedule a video consultation with a physician who specializes in preventive medicine and longevity. They’ll review the results with you and answer any questions you have.</>
      }, 
      {
        question: "How will this improve my health?",
        answer: `The ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title} gives insights into 60+ biomarkers, covering all areas of health including metabolism, cardiovascular risk, inflammation, hormone levels and much more. By identifying imbalances or potential issues early, you can make informed lifestyle changes or start treatments to improve your overall health and prevent future complications.`
      },
    
      {
        question: `How much blood is drawn for the ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}?`,
        answer: "Just 5 small vials of blood are needed."
      },
    
      {
        question: "How long does it take to get results?",
        answer: `Results are typically available 1 week after the lab receives your sample. You'll receive an email notification when your results and personalized report are ready.`
      },
      // {
      //   question: `What is longevity medicine?`,
      //   answer: <>Longevity medicine focuses on enhancing quality of life as you age. It aims to manage the effects of aging through medical interventions and lifestyle changes, helping you maintain health and vitality for longer.</>
      // },  
    
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Can friends and family join my appointment?`,
          answer: `Yes! Instalab is a fantastic experience to share with loved ones. If you book an at-home blood draw, you'll be able to easily invite others to your appointment after scheduling.`,
      }, 
      {
          question: `Do I need to fast before my blood draw?`,
          answer: `Yes, please fast for at least 8 hours before your draw. Water and black coffee are permissible. Failure to fast may impact several of your results, including insulin, glucose, triglycerides, and more.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is properly disposed.`,
      }, 
      {
          question: `What is the efficacy of the given recommendations?`,
          answer:  `Nearly everyone who adhered to their recommendations and subsequently followed up with a blood panel after 3 months demonstrated improvement in their results. The clear and simple guidance is key to this success.`
      }, 

      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to specialists.`
      },
      {
          question: "How often should I get this panel done?",
          answer: `If you’re in good health, annual testing is recommended. If you’re making lifestyle changes—through diet, supplements, or medications—quarterly testing is advised to track progress and ensure your efforts are yielding positive results.`,
      },             
      {
          question: `If I already feel healthy, how will this data be useful?`,
          answer: `Many people who come to Instalab feel healthy but still discover a hidden health risk. Most are able to fix it by doing something super simple. This is the power of early detection testing - addressing issues while they're still molehills, rather than waiting for them to become mountains.`,
      }, 
      {
          question: `Does my health insurance cover the ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}?`,
          answer: `Instalab does not accept insurance for this test for price transparency reasons, but HSA/FSA cards can be used. We can also provide an itemized receipt if needed for reimbursement.`,
      }, 
  

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: `${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}`,
          memberPrice: getProductType(ProductTypeCode.LONGEVITY_PANEL)?.memberCost,
          originalPrice: getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost,
          oneliner: `${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.tagline}`,
          panelRef: panelRef,
          cta: {
            text: "Get Started Now",
            url: "/flow/longevity-test"
          },
          giftCta: {
            text: "Gift to Someone",
            url: `/flow/${FlowType.GIFT}/gift-mobile-draw`
          },
          hasKlarna: true,
          photos: [
            {
              original: "/img/longevitypanel-2.webp",
              thumbnail:  "/img/longevitypanel-2.webp"
            },
            {
              original: "/img/instalab-blood-panel.webp", 
              thumbnail: "/img/instalab-blood-panel.webp"
            },
              {
                original: "/img/report-phone.webp", 
                thumbnail: "/img/report-phone.webp"
              },

          
                {
                    original: "/img/hsa-fsa-eligible-hero.webp",
                    thumbnail: "/img/hsa-fsa-eligible-hero.webp"
                }
          ],
          faqs: faqs.slice(0, 3)
        },
      },

      { type: ProductDetailSection.DIRECTIONS,
        data: [


            {
                title: "Schedule blood draw",
                description: "After you order, you'll schedule an appointment at one of 2,000 patient service centers or with a phlebotomist to come to your home.",
                image: "/img/longevitypanel-step-1.webp"
            },
            {
                title: "Have blood drawn",
                description: "Whether at a patient service center or at your home, your phlebotomist will draw your blood and send to Instalab's CLIA and CAP-certified clinical lab.",
                image: "/img/longevitypanel-step-2.webp"
            },
            {
                title: "Receive results + report",
                description: "Results are reviewed by a physician and available in 1 week. You'll also get a personalized report and game plan on how to optimize your long-term health.",
                image: "/img/results-laptop.webp"
            },


        ],
      },
      { type: ProductDetailSection.PANEL,
        data: {
          panel: advancedPanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.TESTIMONIALS,
        title: "Hear it from real patients",
        data: [
          {
              name: "Geoff Abraham",
              title: "Founder @ Spoken",
              image: "/img/geoff.jpg",
              text: "I didn’t know my body until Instalab. A lovely phlebotomist came to me on my schedule - shout out to Layla! It was very frustrating trying to get the same tests from my PCP. In 3 months, I have dramatically improved my biomarkers."
          },
          {
            name: "Ed Suh",
            title: "MD @ Alpine Ventures ",
            image: "/img/ed.jpg", 
            webp: "/img/ed.webp",
            text: "Instalab’s comprehensive approach blew me away. The reports focus on the issues that matter most to me, with intuitive summaries and clear explanations of where I stand on the risk curve."
        },
          {
            name: "Kat Manalac",
            title: "Partner @ Y Combinator",
            image: "/img/kat.jpg",
            webp: "/img/kat.webp",
            text: "As a working parent, I struggle to make time for my health. Instalab made it simple. Their labs gave me clear, actionable insights and a game plan that fits into my busy life. I finally feel proactive about my health."
        },
          {
              name: "Jason Goldman",
              title: "Filmmaker @ Wild Growth",
              image: "/img/jason.jpg",
              text: "Instalab's totally changed the way I look after myself. After making changes based on the results, I saw improvements pretty quickly. It’s great for getting a clear view of my health without the hassle of visiting a clinic."
  
          }
        ],
      },
      { type: ProductDetailSection.FAQ,
        title: `More on ${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.title}`,
        data: faqs.slice(4),
      },

    ])
  
  }, [productTypes, instalabMembership])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_BLOOD_TEST}
      />

    </div>
  )

}
