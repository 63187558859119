import React, { useEffect, useState, useContext} from 'react';
import { Row, Col, Spin, Typography, Anchor, Button, message, Popover, Segmented, Modal, Badge } from 'antd'
import './gameplan-v2.scss';
import { UserContext } from '../../contexts/user.context';
import { GoalCategory, RecommendationKind,  Role } from '../../enums/index.enum';
import { useParams } from 'react-router-dom';
import { listAnnotatedResults } from '../../services/result.service';
import { listAnnotatedTests } from '../../services/test.service';
import { MenuOutlined, AimOutlined, UnorderedListOutlined, EyeOutlined } from '@ant-design/icons';
import { getPatient } from '../../services/patient.service';
import { listRecommendations } from '../../services/recommendation.service';
import { TableOfContents } from './tableOfContents.component';
import { getMe } from '../../services/user.service';
import { RecommendationStatus } from '../../enums/index.enum';
import { listReports } from '../../services/report.service';
import RecommendationItem from './recommendationItem.component';
import { TestItemList } from './testItemLlist/testItemList.component';
import SectionData from '../../data/section.data.js';
import { TextHelper } from '../../helpers/text.helper';
import PosthogHelper from '../../helpers/posthog.helper';
import { TestCode, EventCode } from '../../enums/index.enum';

const patientPopulate = [{
  path: "memberships",
  populate: [{
    path: "membershipType"
  }]
}]

export const GameplanV2 = () => {
  const { currentUser } = useContext(UserContext)
  const { patientId } = useParams()
  const [isLoading, setIsLoading] = useState()

  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  const [reports, setReports] = useState()
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const [recommendations, setRecommendations] = useState()
  const [patient, setPatient] = useState()
  const [groupByGoal, setGroupByGoal] = useState(true);
  const [goalSections, setGoalSections] = useState()
  const [kindSections, setKindSections] = useState()
  const [secondaryModalVisible, setSecondaryModalVisible] = useState(false);
  const [secondaryTestCodes, setSecondaryTestCodes] = useState()
  
  useEffect(() => {
    document.title =`Game Plan Beta | Instalab`
  }, [])


  useEffect(() => {
    const trackPosthog = () => {
      if (currentUser) {
        PosthogHelper.track(currentUser, EventCode.GAMEPLAN_VIEW);
      }
    };
    
    if (currentUser) {
      trackPosthog();
    }
  }, [currentUser]);

  useEffect(() => {

    const fetchPatientData = async () => {
      if (!currentUser) return
      setIsLoading(true)
      const isPatient = currentUser.role === Role.PATIENT
      const userId = isPatient ? currentUser._id : patientId
      const testsResponse = await listAnnotatedTests({ patient: userId })
      const resultsResponse = await listAnnotatedResults({ patient: userId })
      const reportsResponse = await listReports({ patient: userId })
      setTests(testsResponse)
      setResults(resultsResponse)
      setReports(reportsResponse)
      setPatient(isPatient ? await getMe({populate: patientPopulate}) : await getPatient(userId, {populate: patientPopulate}))
      setIsLoading(false)
    }

    const fetchRecommendations = async () => {
      if (!currentUser) return
  
      try {
        const userId = currentUser.role === Role.PATIENT ? currentUser._id : patientId
        const recs = await listRecommendations({filter: {patient: userId, status: {$ne: RecommendationStatus.INACTIVE}}})

        // Sort recommendations by weight (higher weights first)
        const sortedRecs = recs.sort((a, b) => (b.weight || 0) - (a.weight || 0));

        // Group recommendations by kind (default grouping)
        const groupedByKind = sortedRecs.reduce((acc, rec) => {
          if (!acc[rec.kind]) {
            acc[rec.kind] = [];
          }
          acc[rec.kind].push(rec);
          return acc;
        }, {});

        // Group recommendations by goal categories
        const groupedByGoals = sortedRecs.reduce((acc, rec) => {
          if (rec.goalCategories && rec.goalCategories.length > 0) {
            rec.goalCategories.forEach(goal => {
              if (!acc[goal]) {
                acc[goal] = [];
              }
              acc[goal].push(rec);
            });
          } else {
            // Handle recommendations without goals
            if (!acc['Uncategorized']) {
              acc['Uncategorized'] = [];
            }
            acc['Uncategorized'].push(rec);
          }
          return acc;
        }, {});

        setRecommendations({ byKind: groupedByKind, byGoals: groupedByGoals });
      } catch (error) {
        console.error("Error fetching recommendations", error)
      }
    }

    fetchPatientData()
    fetchRecommendations()

  }, [patientId, currentUser])

  useEffect(() => {

    const sectionDataMap = Object.values(SectionData).reduce((acc, section) => {
      if (section.kindCategory !== undefined) acc.kind[section.kindCategory] = section;
      if (section.goalCategory !== undefined) acc.goal[section.goalCategory] = section;
      return acc;
    }, { kind: {}, goal: {} });
  
    const kindSections = Object.entries(RecommendationKind).map(([key, value]) => {
      const section = sectionDataMap.kind[value];
      if (!section) return null;
      return {
        id: section?.code,
        title: section?.title,
        tocTitle: section?.tocTitle,
        kind: value
      };
    }).filter(Boolean);
  
    const goalSections = Object.entries(GoalCategory).map(([key, value], index) => {
      const section = sectionDataMap.goal[value];
      if (!section) return null;
      return {
        id: section?.code,
        title: `${section?.title}`,
        tocTitle: section?.tocTitle,
        goalCategory: value,
        weight: typeof section?.weight === 'function' ? section?.weight({patient, results, tests}) : 0,
        description: typeof section?.description === 'function' ? section?.description({patient, results, tests}) : section?.description,
        recommendations: recommendations?.byGoals?.[value] || []
      };
    }).filter(Boolean).sort((a, b) => (b.weight || 0) - (a.weight || 0));
  

    if (results && tests && patient) {
      setGoalSections(goalSections)
      setKindSections(kindSections)
    }
  }, [results, tests, patient, recommendations])

  const onUpdate = (updatedRecommendations) => {
    try {
      // Group by kind
      const groupedByKind = updatedRecommendations.reduce((acc, rec) => {
        if (!acc[rec.kind]) {
          acc[rec.kind] = [];
        }
        acc[rec.kind].push(rec);
        return acc;
      }, {});

      // Group by goals
      const groupedByGoals = updatedRecommendations.reduce((acc, rec) => {
        if (rec.goalCategories && rec.goalCategories.length > 0) {
          rec.goalCategories.forEach(goal => {
            if (!acc[goal]) {
              acc[goal] = [];
            }
            acc[goal].push(rec);
          });
        } else {
          if (!acc['Uncategorized']) {
            acc['Uncategorized'] = [];
          }
          acc['Uncategorized'].push(rec);
        }
        return acc;
      }, {});

      setRecommendations({
        byKind: groupedByKind,
        byGoals: groupedByGoals
      });
    } catch (error) {
      console.error("Error updating recommendations", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      message.success("Game plan updated");
    }
  };


  const getGroupedRecommendations = () => {
    if (!recommendations) return {};
    return groupByGoal ? recommendations.byGoals || {} : recommendations.byKind || {};
  };

  const MobileTOCButton = () => (
    <Popover
      content={
        <div className="mobile-toc-menu">
          <div className="view-toggle-container">
            <Segmented
              options={['View by Goals', 'View by Actions']}
              value={groupByGoal ? 'View by Goals' : 'View by Actions'}
              onChange={(value) => setGroupByGoal(value === 'View by Goals')}
              className="view-toggle-segment"
            />
          </div>
          <Anchor
            onClick={() => setMobileDrawerVisible(false)}
            items={(groupByGoal ? 
              Object.entries(getGroupedRecommendations())
                .filter(([goal, recs]) => recs?.length > 0)
                .map(([goal, recs]) => {
                  const sectionData = Object.values(SectionData).find(section => String(section.goalCategory) === String(goal));
                  return {
                    key: sectionData?.code || `goal-${goal}`,
                    href: `#${sectionData?.code || `goal-${goal}`}`,
                    title: sectionData?.tocTitle || sectionData?.title || goal,
                    weight: typeof sectionData?.weight === 'function' ? sectionData?.weight({patient, results, tests}) : 0
                  };
                })
                .sort((a, b) => (b.weight || 0) - (a.weight || 0)) :
              sections
                .filter(section => getGroupedRecommendations()[section.kind]?.length > 0)
                .map((section) => ({
                  key: section.id,
                  href: `#${section.id}`,
                  title: section.title
                }))
            )}
          />
        </div>
      }
      trigger="click"
      placement="topRight"
      open={mobileDrawerVisible}
      onOpenChange={setMobileDrawerVisible}
      overlayClassName="mobile-toc-popover"
    >
      <Button
        type="primary"
        shape="circle"
        icon={<MenuOutlined />}
        size="large"
        className="mobile-toc-button"
      />
    </Popover>
  );

  const SectionRecs = ({recs, sections}) => {
    const items = sections?.map((section) => recs?.[section] || []).flat().filter(Boolean);
    
    const primaryTestCodes = [...new Set(
      (items || [])
        .filter(item => item?.primaryTestCodes)
        .flatMap(item => item.primaryTestCodes)
    )];


    const secondaryTestCodes = [...new Set(
      (items || [])
        .filter(item => item?.secondaryTestCodes)
        .flatMap(item => item.secondaryTestCodes)
    )].filter(code => !primaryTestCodes.includes(code) 

            // hack to no only show blood pressure once 
            && !(code === String(TestCode.BP_D) && primaryTestCodes.includes(String(TestCode.BP_S)))
            && !(code === String(TestCode.BP_S) && primaryTestCodes.includes(String(TestCode.BP_D))));

    return (
      <div className="recommendation-section">
        {groupByGoal && primaryTestCodes.length > 0 && (
          <div className="biomarker-list">
            <Typography.Text strong className="action-items-header section-title">
              <AimOutlined /> Target Biomarkers:
            </Typography.Text>

            <div className="biomarker-list-items">
              <TestItemList 
                testIds={(tests || []).filter(test => primaryTestCodes.includes(String(test?.code)))?.map(test => test?._id)} 
                tests={tests || []} 
                results={results || []} 
                user={currentUser}
                viewAll={true}  
              />
            </div>

            {secondaryTestCodes.length > 0 && (
              <div className="secondary-biomarkers-button-container">
              <Button 
                type="link" 
                onClick={() => {
                  setSecondaryTestCodes(secondaryTestCodes)
                  setSecondaryModalVisible(true)
                }}
                className="secondary-biomarkers-button"
              >
                View Other Biomarker{secondaryTestCodes.length > 1 ? 's' : ''} to Watch
                <Badge 
                  className="secondary-biomarkers-badge" 
                  count={secondaryTestCodes.length}
                />
              </Button>
              </div>
            )}
            

          </div>
        )}

        <div className="recommendation-list">
          {groupByGoal && <Typography.Text strong className="action-items-header section-title">
            <UnorderedListOutlined /> {groupByGoal ? "Action Items:" : "Key Actions:"}
          </Typography.Text>}

          <div className="recommendation-list-items">
            {(items || []).map((item) => (
              <RecommendationItem 
                key={`rec-${item?.code}`} 
                recommendation={item} 
                viewByAction={true} 
                tests={tests} 
                results={results} 
                patient={patient}/>
            ))}
          </div>
        </div>
      </div>
    )
  }

 
  const sections = groupByGoal ? goalSections : kindSections;


  const renderSection = ({ title, kind, goalCategory, id, recommendations = [], icon, description }, index) => {
    if (recommendations.length === 0) return null;

    // Calculate visible goal number based on previous visible sections
    const visibleGoalNumber = groupByGoal ? 
      sections
        .slice(0, index + 1)
        .filter(section => section.recommendations?.length > 0)
        .length
      : null;

    return (
      <div key={id} id={id} className="gameplan-section" aria-label={title}>
        <h2 className="section-title">
          {groupByGoal ? `Goal #${visibleGoalNumber}: ${title}` : title}
        </h2>
        {description && <p className="section-description">{description.map((block, index) => (
            <div key={index} className="description-block">
              {TextHelper.renderBlock(block, {patient, results, tests})}
            </div>
          ))}</p>}
        <SectionRecs 
          recs={{ [groupByGoal ? goalCategory : kind]: recommendations }} 
          sections={[groupByGoal ? goalCategory : kind]} 
        />
      </div>
    );
  };

  return  <>

    {isLoading ? <>
      <div className="loading">
        <div className="loading-text">
          <Spin /> &nbsp;&nbsp;Loading
        </div>
      </div>
    </> : <>
      {recommendations && sections &&   
        <div className="gameplan-v2">


      
          <Modal
              title={<span><EyeOutlined /> Additional Biomarker{secondaryTestCodes?.length > 1 ? 's' : ''} to Watch</span>}
              visible={secondaryModalVisible}
              onCancel={() => setSecondaryModalVisible(false)}
              footer={null}
              width={900}
              className="secondary-biomarkers-modal"
              
            >
              <div className="secondary-test-codes">
  
                <Typography.Text className="secondary-test-codes-description" style={{marginBottom: 12, fontSize: 14}}>
                  {secondaryTestCodes?.length === 1 
                    ? "This biomarker is expected to improve as you address the target biomarkers. To stay focused, we recommend prioritizing target biomarkers. Once those are well-managed, then shift attention to optimizing this biomarker if not yet optimized."
                    : "These biomarkers are expected to improve as you address the target biomarkers. To stay focused, we recommend prioritizing target biomarkers. Once those are well-managed, then shift attention to optimizing these biomarkers if not yet optimized."
                  }
                </Typography.Text>

                <div className="secondary-test-codes-items" style={{marginTop: 18}}>
                  <TestItemList 
                    testIds={(tests || []).filter(test => secondaryTestCodes?.includes(String(test?.code)))?.map(test => test?._id)} 
                    tests={tests || []} 
                    results={results || []} 
                    user={currentUser}
                    viewAll={true}  
                  />
                </div>
              </div>
            </Modal>

          <Row>
            <Col 
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 0 }}
              lg={{ span: 6 }}
              xl={{ span: 5 }}
              xxl={{ span: 5 }}
              className="gameplan-toc"
            >
              <TableOfContents 
                recs={getGroupedRecommendations()} 
                sections={sections}
                patientId={patientId} 
                onUpdate={onUpdate} 
                setIsLoading={setIsLoading}
                groupByGoal={groupByGoal}
                setGroupByGoal={setGroupByGoal}
              />
            </Col>
            
            <Col 
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 18 }}
              xl={{ span: 19 }}
              xxl={{ span: 19 }}
              className="gameplan-main"
            >
              {groupByGoal 
                ? goalSections.sort((a, b) => (b.weight || 0) - (a.weight || 0)).map((section, index) => renderSection(section, index))
                : sections.map((section, index) => renderSection({
                    ...section,
                    recommendations: getGroupedRecommendations()[section.kind] || []
                  }, index))
              }
            </Col>
          </Row>

          <div className="mobile-only">
            <MobileTOCButton />
          </div>
        </div>
      }
    </>}
    </>
}