import { Tabs } from "antd"
import { useState, useEffect, useMemo, useContext } from "react"
import { CardForm } from "../cardForm/cardForm.component"
import { KlarnaForm } from "../klarnaForm/klarnaForm.component"
import { ExpressCheckoutForm } from "../applePayForm/applePayForm.component"
import "./paymentForm.scss"
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from "../../contexts/user.context"
import UserHelper from "../../helpers/user.helper"
import { CreditCardOutlined, LockOutlined } from '@ant-design/icons'
import { cardLogos } from "../../utils/cardLogos"
import React from "react"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const PaymentForm = ({
  onSuccess,
  onContinue,
  onNextStep,
  buttonText,
  disclaimerText,
  activeCard,
  setActiveCard,
  flow,
  hasSkip = false,
  hideButton = false,
  productTypeCode,
  step,
  isLoading,
  setIsLoading,
  setFlow
}) => {
    const [activeTab, setActiveTab] = useState('card');
    const [availablePaymentTypes, setAvailablePaymentTypes] = useState(['card']);
    const { currentUser } = useContext(UserContext);
    const onlyCard = UserHelper.isProvider(currentUser);

    // Initial setup of payment types
    useEffect(() => {
        if (onlyCard) {
            setAvailablePaymentTypes(['card']);
            return;
        }

        const canUseApplePay = window.ApplePaySession && window.ApplePaySession.canMakePayments();
        const configuredTypes = step?.paymentTypes || ['card', 'applePay', 'klarna'];
        const filteredTypes = configuredTypes.filter(type => 
            type !== 'applePay' || (type === 'applePay' && canUseApplePay)
        );
        setAvailablePaymentTypes(filteredTypes);
    }, [onlyCard, step?.paymentTypes]);

    // URL parameter check
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paymentMethod = urlParams.get('payment_type');
        if (paymentMethod) setActiveTab(paymentMethod);
    }, []);


    const paymentComponents = useMemo(() => ({
        card: {
          key: 'card',
          label: <span className="logo card"><CreditCardOutlined /> Card</span>,
          children: (
            <div className="card-container">
              <CardForm
                onSuccess={onContinue}
                onNextStep={onNextStep}
                buttonText={buttonText}
                disclaimerText={disclaimerText}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                flow={flow}
                hasSkip={hasSkip}
                hideButton={hideButton}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
          ),
        },
        ...(availablePaymentTypes.includes('applePay') ? {
          applePay: {
            key: 'applePay',
            label: <span>
              <span className="logo">{cardLogos.apple_pay && React.cloneElement(cardLogos.apple_pay)}</span> 
              Apple Pay
            </span>,
            children: 
              <div className="apple-pay-container">
                 <div className="apple-pay-form">
                   <p className="apple-pay-instructions">
                     When you click the Apple Pay button below, a secure payment screen will appear to complete your purchase using your saved cards and billing information.
                   </p>
                </div>
                <ExpressCheckoutForm
                      onNextStep={onNextStep}
                      flow={flow}
                      productTypeCode={productTypeCode}
                      onSuccess={onSuccess}
                      step={step}
                  />

              <div className="secure-payment-indicator">
                <LockOutlined className="lock-icon" />
                <span>Secure Payment</span>
              </div>
              </div>
          }
        } : {}),
        
        ...(availablePaymentTypes.includes('klarna') ? {
          klarna: {
            key: 'klarna',
            label: <span>
              <span className="logo">{cardLogos.klarna && React.cloneElement(cardLogos.klarna)}</span> 
            Klarna
          </span>,
          children: (
            <div className="klarna-container">
              <KlarnaForm
                onNextStep={onNextStep}
                flow={flow}
                step={step}
                productTypeCode={productTypeCode}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setFlow={setFlow}
                onSuccess={onSuccess}
              />

              
            </div>
          ),
        }
        } : {})
    }), [onSuccess, onNextStep, buttonText, disclaimerText, activeCard, setActiveCard, flow, hasSkip, hideButton, step, productTypeCode, isLoading, setIsLoading, availablePaymentTypes]);
    


  return (
    <div className="payment-form">
        
        {/* <div className="apple-pay-container" style={{display: paymentTypes.includes('applePay') && canUseApplePay ? 'block' : 'none'}}>
          <ExpressCheckoutForm
              onNextStep={onNextStep}
              flow={flow}
              productTypeCode={productTypeCode}
              onSuccess={onSuccess}
              step={step}
          />
            <div className="separator">
                <span>Or pay using</span>
            </div>
        </div> */}

       { availablePaymentTypes.length === 1 ? 
            paymentComponents[availablePaymentTypes[0]].children : 
            <Tabs 
                activeKey={activeTab}
                onChange={setActiveTab}
                items={availablePaymentTypes.map(type => paymentComponents[type])}
            />
        }
    </div>
  )
} 