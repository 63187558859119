import { useState, useEffect, useContext, useRef } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { listMembershipTypes } from "../../services/membershipType.service"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { UserContext } from "../../contexts/user.context"
import { completeFlow } from "../../services/flow.service"

export const Step = {
  PAY: 'pay',
  CONFIRM: 'confirm'
}

export const HeartHealthStrippedFlow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const { currentUser, instalabMembership } = useContext(UserContext)
  const [initialUser, setInitialUser] = useState()
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [initialUser, membershipTypes, flow, instalabMembership])

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchMembershipTypes = async () => {
    const response = await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.HEART_HEALTH,
            MembershipTypeCode.LONGEVITY,
            MembershipTypeCode.PREMIUM,
            MembershipTypeCode.PREMIUM_12MO,
            MembershipTypeCode.LONGEVITY_12MO
          ]
        }
      }
    })
    setMembershipTypes(response)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.HEART_HEALTH,
        paymentTypes: ['card'],
        title: instalabMembership ? <>
          To get started, it's just <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost}/month</span>.
        </> : <>
          Get started today for just <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost + getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>.
        </>,
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Congratulations, you have joined the Heart Health Program.`,
        description: <>
          If you have any questions, please <a onClick={onChat}>send us a message.</a>
        </>,
        content: null,
        buttonText: 'Back to Dashboard',
        path: `/`,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true;
            await completeFlow(flow._id);
          }
        }
      }
    })
  }

  return (
    <div className="heart-health-intake">
      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        startIndex={1}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
      />
    </div>
  )
} 