
import { useEffect, useState, useRef } from "react";
import { EventCode, FlowType } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as HomeSVG} from '../../assets/svg/house.svg'; 
import ProductDetailSection from "../../enums/productDetailSection.enum";
import './galleri.scss'
import { getProductTypeByCode } from "../../services/productType.service";
import { galleriPanel } from "../../data/galleriPanel.data";

export const Galleri = () => {
  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType()
  }, []);
  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}

    const faqs = [
      {
        question: "How do I get the Galleri test done?",
        answer: `Once you order, a Galleri test kit will be shipped to you, containing all the supplies needed for the blood draw. You can choose to schedule an appointment for a licensed phlebotomist to come to your home or visit one of Quest Diagnostics’ 2,000 patient service centers to have your blood drawn. Results are usually available within 2 weeks.`,
      },
      {
        question: "How does the Galleri test look for cancer?",
        answer: "Every time you take the test, Galleri checks more than 100,000 DNA regions and over a million specific DNA sites to screen for a signal shared by multiple cancers. If a cancer signal is detected, results from the test predict the tissue type or organ associated with the cancer signal to help your healthcare provider determine next steps."
      },
      {
        question: "Which cancers does Galleri screen for?",
        answer: <>Galleri detects a signal shared by over 50 types of cancer — including some fast-spreading and aggressive cancers responsible for approximately two-thirds of cancer death. <a onClick={scrollToPanel}  className="cta-link">View All Cancers Detected​</a>.</>
      },
      {
        question: "How accurate is the Galleri test?",
        answer: `A “Cancer Signal Detected” result is expected in approximately 1 out of 100 Galleri tests. In a recent clinical study, on average, 4 out of 10 people with a "Cancer Signal Detected" result received a cancer diagnosis with diagnostic testing, and 1 out of 200 people without cancer received a "Cancer Signal Detected" result. (Positive Predictive Value was 43% and False Positive rate was 0.5%)`
      },
      {
        question: "Who is drawing my blood?",
        answer: `A licensed phlebotomist will come to your desired location to perform your blood draw. Every phlebotomist undergoes extensive background checks and training. And they are experts in making your experience painless and stress-free.`,
      },

      {
        question: "How often should I get this test done?",
        answer: `If you have a family history of cancer, it's recommended to do this test annually if you're able to.`,
      },
      {
        question: "I'm not at risk for cancer nor do I have family history, is the Galleri test really for me?",
        answer: "Yes, cancer risk increases for everyone as they age regardless of family history - only 5% to 10% of cancers are inherited."
      },
      {
        question: "How many vials of bloow are drawn?",
        answer: "It's just 2 small tubes, which amounts to approximately 1.5 tablespoons (or about 20 mL) of blood."
      },
      {
        question: "Who is drawing my blood?",
        answer: `A licensed phlebotomist will come to your desired location to perform your blood draw. Every phlebotomist undergoes extensive background checks and training. And they are experts in making your experience painless and stress-free.`,
      },
      {
        question: `Do I need to fast before my blood draw?`,
        answer: `No, you do not need to fast before the Galleri test.`,
      }, 
      {
        question: `Does my health insurance cover this test?`,
        answer: `Insurance does not cover Galleri test. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card for this purpose. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 
      {
        question: `Where is the Galleri test available?`,
        answer: `Anywhere in the United States.`
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a longevity physician. For Instalab members, we can also help find and refer you to an oncologist, if needed.`,
      }, 
      {
        question: `What does it mean if I recieve a "Cancer Signal Detected" result?`,
        answer: "The Galleri test detected a signal often associated with cancer. This result will also include one or two predictions of the tissue type or organ associated with the cancer signal, called ​“Cancer Signal Origin. This test result is not a cancer diagnosis and requires follow-up diagnostic testing which may include lab work or imaging ordered by your healthcare provider to confirm cancer."
      },          
      {
        question: `What does it mean if I recieve a "No Cancer Signal Detected" result?`,
        answer: "The Galleri test looked for a cancer signal in your blood sample and did not find one. This result does not completely rule out the possibility of cancer. The Galleri test does not detect a signal for all cancers and not all cancers can be detected in the blood. Continue with routine cancer screenings your healthcare provider recommends. This result does not predict whether you will develop cancer in the future."
      }
    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: "Galleri Multi-Cancer Early Detection Test",
          memberPrice: productType?.memberCost,
          originalPrice: productType?.cost,
          hasKlarna: true,
          oneliner: "Improve your chances for early detection by testing for 50+ types of cancer with a single blood draw.",
          points: [
              <><BloodSVG className="point-icon"/>Test for 50+ types of cancer at once</>,
              <><HomeSVG className="point-icon"/>Blood drawn from comfort of your home</>,
              <><CardSVG className="point-icon" />HSA / FSA cards accepted</>,
          ],
          cta: {
            text: "Get Started Now",
            url: `/flow/${FlowType.GALLERI_TEST}`
          },
          giftCta: {
            text: "Gift to Someone",
            url: `/flow/${FlowType.GIFT}/details?code=${ProductTypeCode.GIFT_GALLERI}`
          },
          photos: [
            {
                original: "/img/galleri.webp", 
                thumbnail: "/img/galleri.webp"
            },
            {
                original: "/img/galleri-report.webp", 
                thumbnail: "/img/galleri-report.webp"
            },
            {
                original: "/img/galleri-draw.webp",
                thumbnail:  "/img/galleri-draw.webp"
            },
            {
                original: "/img/hsa-fsa-eligible-hero.webp",
                thumbnail: "/img/hsa-fsa-eligible-hero.webp"
            }
        ],
          faqs: faqs
        }
      },
      { type: ProductDetailSection.XPANEL,
        title: "Early detection is best",
        description: "Get ahead of cancer when it's most treatable. Early diagnosis leads to a survival rate four times higher than late diagnosis.",
        data: [
          {
            image: "/img/galleri-panel-1.png",
            text: "Approximately 70% of deaths due to cancer are caused by cancers without recommended screenings."
          },
          {
            image: "/img/galleri-panel-2.png",
            text: "Today, only 5 cancer screenings are recommended. Adding Galleri detects many more without standard screenings."
          },
          {
            image: "/img/galleri-panel-3.png",
            text: "In a recent clinical study, Galleri more than doubled the number of cancers detected with recommended screening."
          }
        ]
      },
      { type: ProductDetailSection.PANEL,
        data: {
          title: "Types of Cancers Detected",
          panel: galleriPanel,
          panelRef: panelRef,
          lookup: false
        }
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
          {
              title: "Receive test kit",
              description: "After you order, you'll get the Galleri kit in the mail, which contains all the materials needed for the blood draw.",
              image: "./img/galleri-step1.webp"
          },
          {
              title: "Have blood drawn",
              description: "Either schedule to have a phlebotomist come to you or visit any of Galleri's 2,000+ patient service centers.",
              image: "./img/galleri-step2.webp"
          },
          {
              title: "Receive your results",
              description: "Test results are ready and sent approximately two weeks after your sample arrives at Galleri's lab.",
              image: "./img/galleri-step3.webp"
          }
      ],
      },
      {
        type: ProductDetailSection.FAQ,
        data: faqs.slice(4)
      }
    ])

  }, [productType])

  const fetchProductType = async () => {
    const response = await getProductTypeByCode(ProductTypeCode.GALLERI)  
    setProductType(response)
  };


  return (
    sections && <ProductDetail className="galleri" sections={sections} trackCode={EventCode.VISIT_GALLERI}/>
  )
}
