import { useNavigate, useParams } from "react-router-dom";
import "./paymentLinkRegister.scss";
import { Button, Form, Select, Input, message, Typography, Row, Col } from "antd";
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import Gender from '../../enums/gender.enum'
import StorageKey from "../../enums/storageKey.enum";
import { useContext, useRef, useState, useEffect } from "react";
import { registerPaymentLink } from "../../services/charge.service";
import { UserContext } from "../../contexts/user.context";

const { Item } = Form
const { Paragraph } = Typography

export const PaymentLinkRegister = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const { setToken } = useContext(UserContext)

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };  
  }, []);

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch ({ errors, values }) {
      form.setFieldsValue(values)
      return true
    }
    return false
  }

  const onSubmit = async () => {
    setHasAttempt(true)
    setIsLoading(true)
    try {
      const hasFormError = await hasFormError()
      if (hasFormError) {
        message.error('Correct form errors before proceeding')
        setIsLoading(false)
        return 
      }

      const {
        firstName,
        lastName,
        email,
        phone,
        dob,
        gender,
        password,
      } = form.getFieldsValue()
      
      const response = await registerPaymentLink(id, {
        fields: {
          firstName,
          lastName,
          email,
          phone,
          dob,
          gender,
          password,
        },
        select: '_id',
      })
      setToken(response.token)
      localStorage.setItem(StorageKey.TOKEN, response.token)
      message.success("Instalab account registered")
      navigate(`/payment/${id}`)
    } catch (err) {
      let msg = 'Failed to register Instalab account'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsLoading(false)
  }

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `${match[1] ? '(' + match[1] : ''}${match[2] ? ') ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatPhoneNumber(value);
    form.setFieldsValue({ phone: formattedValue });
    if (hasAttemptRef.current) {
      FormHelper.fetchHasError(form)
    }
  }

  const formatDob = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 8);
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return `${match[1] ? match[1] : ''}${match[2] ? '/' + match[2] : ''}${match[3] ? '/' + match[3] : ''}`;
    }
    return value;
  };

  const handleDobChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatDob(value);
    form.setFieldsValue({ dob: formattedValue });
    if (hasAttemptRef.current) {
      FormHelper.fetchHasError(form)
    }
  };


  const onChat = () => {
    window.FrontChat("show");
  }

  return (
    <div className="payment-link-register">
      <Paragraph className="register-title">
        Register your Instalab account.
      </Paragraph>
      <Paragraph className="register-description">
        You will be redirected to your payment form after you register. If you have any questions, <a onClick={onChat} className="message-link">send us a message.</a>
      </Paragraph>
      <Form
        form={form}
        layout='vertical'
        className="register-form"
      >
        <Row gutter={12}>
          <Col span={12}>
            <Item 
              label="Email Address"
              name="email"
              onInput={e => e.target.value = e.target.value.toLowerCase()}
              rules={[
                RuleHelper.isRequired,
                RuleHelper.isEmail,
                RuleHelper.isUniqueEmail({
                  fieldName: 'email',
                })
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder="Email Address" 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Phone Number"
              name="phone"
              rules={[
                RuleHelper.isRequired,
                RuleHelper.isPhone,
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder={'(XXX) XXX-XXXX'}
                inputMode="decimal"
                onChange={handlePhoneChange}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="First Name"
              name="firstName"
              rules={[
                RuleHelper.isRequired
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder="First Name" 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Last Name"
              name="lastName"
              rules={[
                RuleHelper.isRequired
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder="Last Name" 
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Sex at Birth"
              name="gender"
              rules={[
                RuleHelper.isRequired
              ]}
              validateTrigger='onSubmit'
            >
              <Select
                placeholder="Select Sex"
                showSearch
                options={Object.values(Gender).map(gender => {
                  return {
                    label: gender,
                    value: gender,
                  }
                })}
                onSelect={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Date of Birth"
              name="dob"
              rules={[
                RuleHelper.isRequired,
                RuleHelper.isDate,
              ]}
              validateTrigger='onSubmit'
            >
              <Input 
                placeholder={'MM/DD/YYYY'}
                inputMode="decimal"
                onChange={handleDobChange}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Password"
              name="password"
              rules={[
                RuleHelper.isRequired
              ]}
              validateTrigger='onSubmit'
            >
              <Input.Password
                placeholder="Password" 
                type="password"
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>

          <Col span={12}>
            <Item 
              label="Password Confirmation"
              name="passwordConfirmation"
              rules={[
                RuleHelper.isRequired,
                RuleHelper.hasCustomValidation({
                  message: 'Must match password',
                  validator:(_, value='') => {
                    value = value.trim()
                    if (!value || form.getFieldsValue().password === value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  }
                })
              ]}
              validateTrigger='onSubmit'
            >
              <Input.Password
                placeholder="Password" 
                type="password"
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
              />
            </Item>
          </Col>
        </Row>

        <Item>
          <Button
            type='primary'
            loading={isLoading}
            onClick={onSubmit}
            className="submit-btn"
          >
            Create Instalab Account
          </Button>
        </Item>
      </Form>
    </div>
  )
}