import FlowType from "../../enums/flowType.enum"
import StepType from "../flow/enums/stepType.enum"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import HeartHealthConsultIntro from "./custom/heartHealthConsultIntro/heartHealthConsultIntro.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  INTRO: 'intro',
  PAY: 'pay',
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  CONSULT: 'schedule',
  CONFIRM: 'confirm',
}

export const HeartHealthConsultFlow = () => {
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, hasPass, instalabMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    const response = await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    })
    setMembershipTypes(response)
  }
  
  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.CONSULT_HEART_HEALTH,
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.PAY,
        content: ({ footer }) => <HeartHealthConsultIntro footer={footer} />
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.ACCOUNT,
        addProduct: true,
        addLongevityMembership: true,
        productType: ProductTypeCode.CONSULT_HEART_HEALTH,
        title: (flow?.addLongevityMembership || instalabMembership) ?
          <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_HEART_HEALTH))}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_HEART_HEALTH)?.cost}</span> for your heart health consultation{instalabMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
          <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_HEART_HEALTH)?.cost}</span> for your heart health consultation.</>,
        description: `This is for a 30 minute video consult with an Instalab physician specializing in lipidology and heart health. You'll schedule your appointment in the next step.`,
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `Create your account to manage your orders and health information.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this to create your account.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONSULT,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or sessions.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },

      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule your heart health consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in longevity medicine and lipidology. It'll be a 30 minute video call where you can ask questions.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/3r9-gjk-xjv',
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Appointment confirmed!`,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.GENDER}`,
        buttonText: 'Continue',
        description: `You'll receive an email with a calendar invite and a Zoom link for your session. Now we just need a little more information to help your physician prepare for your appointment.`,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}