import { Table, Switch, message } from "antd";
import { useState, useEffect } from "react";
import { getMe, updateMe } from "../../services/user.service.js";
import KlaviyoService from "../../services/klaviyo.service.js";
import './notifications.scss';

export const Notifications = () => {

    const [notifications, setNotifications] = useState([])

    useEffect(() => {   
        const fetchNotifications = async () => {
            const data = await getMe({select: 'email_marketing email_newsletter'})
            setNotifications([
                {
                    key: 'marketing',
                    title: 'Instalab News Emails',
                    description: 'Receive emails about new features, updates, and other Instalab news.',
                    value: data.email_marketing
                },
                {
                    key: 'newsletter',
                    title: 'Instalab Brief Emails',
                    description: 'Receive emails about latest health research with actionable insights.',
                    value: data.email_newsletter
                }
            ])
        }
        fetchNotifications()
    }, [])

    const handleToggle = async (checked, record) => {
        try {
            // Update the backend
            const userData = await updateMe({ fields: { [record.key === 'marketing' ? 'email_marketing' : 'email_newsletter']: checked } });
            
            // Sync with Klaviyo
            try {   
                if (checked) {
                    await KlaviyoService.addToKlaviyo({ email: userData.email, firstName: userData.firstName, lastName: userData.lastName, listType: record.key });
                } else {
                    await KlaviyoService.removeFromKlaviyo({ email: userData.email, listType: record.key });
                }
            } catch (error) {
                console.error('Failed to sync with Klaviyo:', error);
            }

            // Update local state
            setNotifications(notifications.map(notif => 
                notif.key === record.key ? { ...notif, value: checked } : notif
            ));

            // Show success message
            message.success(`Successfully ${checked ? 'subscribed to' : 'unsubscribed from'} ${record.title}`);
        } catch (error) {
            console.error('Failed to update:', error);
            message.error('Failed to update. Please try again.');
            
            // Revert the switch state
            setNotifications(notifications.map(notif => 
                notif.key === record.key ? { ...notif, value: !checked } : notif
            ));
        }
    };

    return (
        <div className="notifications">
            <Table
                className='primary-table'
                pagination={false}
                rowKey='key'
                dataSource={notifications}
                showHeader={false}
                columns={[
                    {
                        title: "Type",
                        dataIndex: 'title',
                        render: (text, record) => (
                            <div className="notification-content">
                                <div className="notification-row">
                                    <div className="notification-title">{text}</div>
                                    <div className="notification-description">{record.description}</div>
                                </div>
                            </div>
                        )
                    },
                    {
                        title: "Status",
                        dataIndex: 'value',
                        className: 'switch-column',
                        render: (value, record) => (
                            <div className="switch-wrapper">
                                <Switch
                                    checked={value}
                                    checkedChildren="Subscribed"
                                    unCheckedChildren="Unsubscribed"
                                    onChange={(checked) => handleToggle(checked, record)}
                                />
                            </div>
                        )
                    }
                ]}
            />
        </div>
    )
}