import { Button } from 'antd'
import { PlusSquareOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons'
import './sidebarScheduleBtnLayout.scss';
import { useNavigate } from 'react-router-dom';
import { EventType, Role } from '../../enums/index.enum';
import { addEvent } from '../../services/event.service';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';

const SidebarScheduleBtnLayout = () => {
  const navigate = useNavigate()
  const { currentUser, instalabMembership, token } = useContext(UserContext)

  const onMember = async () => {
    await addEvent({eventType: EventType.CLICK_SIGNUP_MEMBERSHIP_SIDEBAR})
    navigate('patient/memberships')  
  }

  const onLogin = async () => {
    navigate('login')
  }

  const onSignUp = async () => {
    const url = '/start'
    await addEvent({
      eventType: EventType.CLICK_SIGNUP_SIDEBAR,
      meta: {
        url: url
      }
    })
    navigate(url)  
  }

  return <>
  {!token && currentUser?.role === Role.PATIENT && (
          <Button 
            id={'member-cta-sidebar'}
            className="sidebar-schedule-btn-layout"
            onClick={onLogin}
            icon={<LoginOutlined />}
            type='default'
            size='large'
          >
            Login
            </Button>
    //     <Button 
    //   id={'member-cta-sidebar'}
    //   className="sidebar-schedule-btn-layout"
    //   onClick={onSignUp}
    //   icon={<PlusSquareOutlined />}
    //   type='primary'
    // >
    //   Get Started
    //   </Button>
  )}
  {token && !instalabMembership && currentUser?.role === Role.PATIENT && <Button 
      id={'member-cta-sidebar'}
      className="sidebar-schedule-btn-layout"
      onClick={onMember}
      icon={<PlusSquareOutlined />}
      type='primary'
    >
      Become Member
    </Button>}
  </>
}

export default SidebarScheduleBtnLayout