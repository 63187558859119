import { Typography } from "antd";
import classNames from "classnames";
import { useParams, useNavigate } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";
import BlogId from "../../../enums/blogId.enum";
const { Paragraph } = Typography

export const ExerciseConsequencesPost = () => {
  const { blogId } = useParams()
  const navigate = useNavigate()
  return (
    <div className={classNames("blog-post", "exercise-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          Metabolic Decline and Fat Accumulation
        </Paragraph>
        <Paragraph className="blog-description">
          One of the first noticeable effects of inactivity is a sluggish metabolism. Without regular movement, muscles become less efficient at absorbing glucose, leading to higher blood sugar levels and an increased risk of insulin resistance.
        </Paragraph>
        <Paragraph className="blog-description">
          Researchers studying people who drastically reduced their daily step count for just two weeks found that insulin sensitivity dropped by 30%, and fat storage—especially around the abdomen—became more pronounced.
        </Paragraph>

        <Paragraph className="blog-title">
          Loss of Cardiovascular Fitness
        </Paragraph>
        <Paragraph className="blog-description">
          Skipping workouts for a few days won't harm your heart, but after a couple of weeks, your cardiovascular system starts to suffer. VO2 max, which measures how well your body uses oxygen, can decline by as much as 10% after two weeks of inactivity. Those who regularly train for endurance notice this decline even faster.
        </Paragraph>
        <Paragraph className="blog-description">
          In one study, participants who stopped exercising for just 14 days experienced an increase in resting heart rate and a noticeable dip in their stamina during routine activities. Over time, inactivity stiffens arteries and raises blood pressure, increasing long-term risks for heart disease.
        </Paragraph>

        <Paragraph className="blog-title">
          Muscle Loss and Strength Decline
        </Paragraph>
        <Paragraph className="blog-description">
          Muscle atrophy doesn't happen overnight, but it starts sooner than most people think. Studies show that just two weeks of cutting back on movement—even without full-blown inactivity—can lead to measurable losses in strength.
        </Paragraph>
        <Paragraph className="blog-description">
          One experiment had active adults significantly reduce their workouts, and by the end of two weeks, their leg strength had declined by nearly 8%. This process happens even faster in older adults, who may find everyday activities like climbing stairs or carrying groceries noticeably harder after a few weeks of little to no exercise.
        </Paragraph>

        <Paragraph className="blog-title">
          Energy Decline and Mitochondrial Dysfunction
        </Paragraph>
        <Paragraph className="blog-description">
          Feeling sluggish after skipping a few workouts isn't just in your head. Mitochondria, the tiny powerhouses in your cells responsible for energy production, begin to work less efficiently when you stop exercising.
        </Paragraph>
        <Paragraph className="blog-description">
          A study that monitored physically active individuals who became sedentary for 10 days found that their daily energy expenditure dropped significantly, leading to increased fatigue. Over time, this decline in mitochondrial function contributes to a slower metabolism and increased oxidative stress, making it harder to get back into an active routine.
        </Paragraph>

        <Paragraph className="blog-title">
          Cognitive Function and Mood Decline
        </Paragraph>
        <Paragraph className="blog-description">
          Exercise doesn't just keep the body in shape; it keeps the brain sharp, too. Physical activity boosts levels of brain-derived neurotrophic factor (BDNF), a protein that supports learning, memory, and overall brain function.
        </Paragraph>
        <Paragraph className="blog-description">
          But when people cut out exercise, BDNF levels drop, and cognitive performance suffers. One study found that after just 10 days of significantly reduced movement, blood flow to the hippocampus—a region of the brain critical for memory—decreased. This lack of movement can also contribute to increased stress, anxiety, and difficulty concentrating.
        </Paragraph>

        <Paragraph className="blog-title">
          The Good News
        </Paragraph>
        <Paragraph className="blog-description">
          If you've been inactive for a while, these changes can be reversed—just get moving again. Even after extended breaks, regular movement can quickly restore metabolic health, muscle function, and cardiovascular fitness.
        </Paragraph>
        <Paragraph className="blog-description">
          At Instalab, we regularly work with patients who claim they don't have enough time to spend hours at the gym each week, making it difficult to maintain a consistent exercise routine. For those who are incredibly busy, we encourage you to read our <a className="blog-link" onClick={() => {
            navigate(`/research/${BlogId.EFFICIENT_EXERCISE}`)
          }}>guide on the most time-efficient workouts for longevity</a>.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Booth, F., Roberts, C., & Laye, M. (2012). Lack of exercise is a major cause of chronic diseases.. Comprehensive Physiology, 2 2, 1143-211. <a onClick={() => window.open('https://doi.org/10.1002/cphy.c110025', '_blank')}>https://doi.org/10.1002/cphy.c110025</a>
            </li>
            <li>
              Davies, K., Pickles, S., Sprung, V., Kemp, G., Alam, U., Moore, D., Tahrani, A., & Cuthbertson, D. (2019). Reduced physical activity in young and older adults: metabolic and musculoskeletal implications. Therapeutic Advances in Endocrinology and Metabolism, 10. <a onClick={() => window.open('https://doi.org/10.1177/2042018819888824', '_blank')}>https://doi.org/10.1177/2042018819888824</a>
            </li>
            <li>
              Biswas, A., Oh, P., Faulkner, G., Bajaj, R., Silver, M., Mitchell, M., & Alter, D. (2015). Sedentary Time and Its Association With Risk for Disease Incidence, Mortality, and Hospitalization in Adults. Annals of Internal Medicine, 162, 123-132. <a onClick={() => window.open('https://doi.org/10.7326/M14-1651', '_blank')}>https://doi.org/10.7326/M14-1651</a>
            </li>
            <li>
              Thyfault, J., Du, M., Kraus, W., Levine, J., & Booth, F. (2015). Physiology of sedentary behavior and its relationship to health outcomes.. Medicine and science in sports and exercise, 47 6, 1301-5. <a onClick={() => window.open('https://doi.org/10.1249/MSS.0000000000000518', '_blank')}>https://doi.org/10.1249/MSS.0000000000000518</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};