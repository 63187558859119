import { useParams, useNavigate } from "react-router-dom";
import { 
  FacebookShareButton, 
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
} from 'react-share';
import moment from "moment";
import { LinkedinFilled, RedditSquareFilled, LinkOutlined, TwitterSquareFilled, FacebookFilled } from "@ant-design/icons";
import { useContext, useState } from "react";
import { Typography, Spin, Avatar, message } from 'antd'
import { UserContext } from "../../contexts/user.context";
import { SugarPost } from "./posts/sugarPost.component";
import { OverexercisePost } from "./posts/overexercisePost.component";
import { CacPost } from "./posts/cacPost.component";
import { VapingPost } from "./posts/vapingPost.component";
import { FounderPost } from "./posts/founderPost.component";
import { WeightLossPost } from "./posts/weightLossPost.component";
import { SaunaPost } from "./posts/saunaPost.component";
import { InsurancePost } from "./posts/insurancePost.component";
import { CumulativeExposurePost } from "./posts/cumulativeExposurePost.component";
import { SoftPlaquePost } from "./posts/softPlaquePost.component";
import { OzempicPost } from "./posts/ozempicPost.component";
import { NapPost } from "./posts/napPost.component";
import { SleepTrainingPost } from "./posts/sleepTrainingPost.component";
import { PCSK9InhibitorsPost } from "./posts/pcsk9InhibitorsPost.component";
import { StatinPost } from "./posts/statinPost.component";
import { EfficientExercisePost } from "./posts/efficientExercisePost.component";
import { ExerciseConsequencesPost } from "./posts/exerciseConsequencesPost.component";
import { ExerciseRecoveryPost } from "./posts/exerciseRecoveryPost.component";
import { GalleriPost } from "./posts/galleriPost.component";
import { ApoBPost } from "./posts/apoBPost.component";
import { SupplementPost } from "./posts/supplementPost.component";
import { PeakPost } from "./posts/peakPost.component";
import { BloodPressureDietPost } from "./posts/bloodPressureDietPost.component";
import { ForecastPost } from "./posts/forecastPost.component";
import './blog.scss';
import { RecommendedBlogs } from "./shared/recommendedBlogs/recommendedBlogs.component";
import { useEffect } from "react";
import PosthogHelper from "../../helpers/posthog.helper";
import { IfPost } from "./posts/ifPost.component";
import BlogId from "../../enums/blogId.enum";
import { SeedOilPost } from "./posts/seedOilPost.component";
import { LpaPost } from "./posts/lpaPost.component";
import { BlogData } from "../../data/blog.data";
import { CalculatorPost } from "./posts/calculatorPost.component";
import { PlaqueReversalPost } from "./posts/plaqueReversalPost.component";
import { TestosteroneAgePost } from "./posts/testosteroneAgePost.component";
import { DexaPost } from "./posts/dexaPost.component";
import { SleepPost } from "./posts/sleepPost.component";
import { BmiPost } from "./posts/bmiPost.component";
import { SterolTestPost } from "./posts/sterolTestPost.component";
import { EvaluateMedicationPost } from "./posts/evaluateMedicationPost.component";
import { SubscribeButton } from "../subscribeButton/subscribeButton.component";

const { Paragraph, Text } = Typography

export const Blog = () => {
  const { blogId } = useParams()
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const [blogComponent, setBlogComponent] = useState(null)
  
  useEffect(() => {
    document.title = `Instalab | ${BlogData.find(b => b.id === blogId).title}`
  }, [blogId])

  useEffect(() => {
    if (currentUser) {
      PosthogHelper.track(currentUser, `[research] view post: ${blogId}`, true)
    }
  }, [currentUser])

  useEffect(() => {
    switch (blogId) {
      case BlogId.IF:
        setBlogComponent(<IfPost />)
        break
      case BlogId.WEIGHT_LOSS:
        setBlogComponent(<WeightLossPost />)
        break
      case BlogId.OZEMPIC:
        setBlogComponent(<OzempicPost />)
        break
      case BlogId.STEROL_TEST:
        setBlogComponent(<SterolTestPost />)
        break
      case BlogId.EFFICIENT_EXERCISE:
        setBlogComponent(<EfficientExercisePost />)
        break
      case BlogId.EXERCISE_CONSEQUENCES:
        setBlogComponent(<ExerciseConsequencesPost />)
        break
      case BlogId.EXERCISE_RECOVERY:
        setBlogComponent(<ExerciseRecoveryPost />)
        break
      case BlogId.NAP:
        setBlogComponent(<NapPost />)
        break
      case BlogId.SLEEP_TRAINING:
        setBlogComponent(<SleepTrainingPost />)
        break
      case BlogId.SLEEP:
        setBlogComponent(<SleepPost />)
        break
      case BlogId.VAPING:
        setBlogComponent(<VapingPost />)
        break
      case BlogId.SUGAR:
        setBlogComponent(<SugarPost />)
        break
      case BlogId.SEED_OIL:
        setBlogComponent(<SeedOilPost />)
        break
      case BlogId.CALCULATOR:
        setBlogComponent(<CalculatorPost />)
        break
      case BlogId.TESTOSTERONE_AGE:
        setBlogComponent(<TestosteroneAgePost />)
        break
      case BlogId.STATIN:
        setBlogComponent(<StatinPost />)
        break
      case BlogId.PLAQUE_REVERSAL:
        setBlogComponent(<PlaqueReversalPost />)
        break
      case BlogId.OVEREXERCISE:
        setBlogComponent(<OverexercisePost />)
        break
      case BlogId.CAC:
        setBlogComponent(<CacPost />)
        break
      case BlogId.FOUNDER:
        setBlogComponent(<FounderPost />)
        break
      case BlogId.SAUNA:
        setBlogComponent(<SaunaPost />)
        break
      case BlogId.INSURANCE:
        setBlogComponent(<InsurancePost />)
        break
      case BlogId.SOFT_PLAQUE:
        setBlogComponent(<SoftPlaquePost />)
        break
      case BlogId.GALLERI:
        setBlogComponent(<GalleriPost />)
        break
      case BlogId.PEAK:
        setBlogComponent(<PeakPost />)
        break
      case BlogId.CUMULATIVE_EXPOSURE:
        setBlogComponent(<CumulativeExposurePost />)
        break
      case BlogId.FORECAST:
        setBlogComponent(<ForecastPost />)
        break
      case BlogId.PCSK9I:
        setBlogComponent(<PCSK9InhibitorsPost />)
        break
      case BlogId.APOB:
        setBlogComponent(<ApoBPost />)
        break
      case BlogId.SUPPLEMENT:
        setBlogComponent(<SupplementPost />)
        break
      case BlogId.BLOOD_PRESSURE_DIET:
        setBlogComponent(<BloodPressureDietPost />)
        break
      case BlogId.LPA:
        setBlogComponent(<LpaPost />)
        break
      case BlogId.DEXA:
        setBlogComponent(<DexaPost />)
        break
      case BlogId.BMI:
        setBlogComponent(<BmiPost />)
        break
      case BlogId.EVALUATE_MEDICATION:
        setBlogComponent(<EvaluateMedicationPost />)
        break
    }
  }, [blogId])

  return (
    <div className="blog">
      {blogComponent ? <>
        <div className="blog-intro">
          <div className="blog-superscript">
            {moment(BlogData.find(b => b.id === blogId)?.publishedAt).format('YYYY.MM.DD')}&nbsp;&nbsp;|&nbsp;&nbsp;{BlogData.find(b => b.id === blogId)?.categories[0]}
          </div>
          <Paragraph className="blog-heading">
            {BlogData.find(b => b.id === blogId).title}
          </Paragraph>

          <Paragraph className="blog-subheading">
            {BlogData.find(b => b.id === blogId).description}
          </Paragraph>

          <Text className="blog-author">
            <Avatar src={BlogData.find(b => b.id === blogId).authorPhoto} className="blog-avatar" /> {BlogData.find(b => b.id === blogId).authorName}
          </Text>  
        </div>
        
        {blogComponent}

        <div className="share-footer">
          <div className="share-buttons">
            <Paragraph className="share-footer-title">
              Share Article
            </Paragraph>
            <LinkedinShareButton url={window.location.href}>
              <a className="share-button linkedin">
                <LinkedinFilled className="share-icon" />
                <span>LinkedIn</span>
              </a>
            </LinkedinShareButton>

            <TwitterShareButton url={window.location.href}>
              <a className="share-button twitter">
                <TwitterSquareFilled className="share-icon" />
                <span>Twitter</span>
              </a>
            </TwitterShareButton>

            <RedditShareButton url={window.location.href}>
              <a className="share-button reddit">
                <RedditSquareFilled className="share-icon" />
                <span>Reddit</span>
              </a>
            </RedditShareButton>

            <FacebookShareButton url={window.location.href}>
              <a className="share-button facebook">
                <FacebookFilled className="share-icon" />
                <span>Facebook</span>
              </a>
            </FacebookShareButton>
            
            <a className="share-button copy" onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              message.info('Link copied')
            }}>
              <LinkOutlined className="share-icon" />
              <span>Copy</span>
            </a>
          </div>
        </div>

        <div className="blog-border" />


        <div className="related-blogs">
          <div className="related-header">
            <Paragraph className="related-title-text">
              <span className="desktop-text">Related Articles</span>
              <span className="mobile-text">Related</span>
            </Paragraph>
            <a
              onClick={() => {
                PosthogHelper.track(currentUser, `[research] clicked "view all" articles`, true)
                navigate('/research')
              }}
              className="view-all-link"
            >
              View All
            </a>
          </div>

          {blogComponent && <RecommendedBlogs />}
        </div>

        <SubscribeButton />
      </> : <>
        <div className="loading-blog">
          <Paragraph className="loading-blog-text"><Spin className="loading-blog-spinner" /> Loading...</Paragraph>
        </div>
      </>}
    </div>
  )
}