import React, { useEffect, useState } from 'react'
import './testItemList.scss'
import { Typography, Button, Row, Col } from 'antd';
import { DownOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import classNames from "classnames";
import { TestModal } from '../../testModal/testModal.component';
import RiskLevel from '../../../enums/riskLevel.enum';
import ResultHelper from '../../../helpers/result.helper';
import TestCode from '../../../enums/testCode.enum';
import ReadableRiskLevel  from '../../../enums/readableRiskLevel.enum';
import { TestChartDrawer }  from '../../testChartDrawer/testChartDrawer.component';

const { Text, Paragraph } = Typography

export const TestItemList = ({ testIds, report, tests, results, user, reports, viewAll = false }) => {
  const [items, setItems] = useState()
  const [openModal, setOpenModal] = useState()
  const [testModalId, setTestModalId] = useState()
  const [showAll, setShowAll] = useState(viewAll)

  useEffect(() => {
    if (user && tests?.length && results?.length && testIds) {
      setItems(getItems())
    }
  }, [user, tests, results, report, testIds])

  const getItems = () => {

    return testIds.filter(testId => {
      return getRisk(testId)
    }).map(testId => {
      const {
        tagline,
        name,
        _id,
        goal,
        unit,
        code
      } = getTest(testId)
      const currentValue = getCurrentValue(testId)
      return {
        risk: getRisk(testId),
        tagline,
        name,
        _id,
        goal,
        currentValue,
        unit,
        code
      }
    }).sort((a, b) => {
      const priority = {
        [RiskLevel.HIGH]: 2,
        [RiskLevel.ABNORMAL]: 1,
        [RiskLevel.MODERATE]: 0,
      }
      return priority[getRisk(b._id)] - priority[getRisk(a._id)]
    })
  }

  const getRisk = (testId) => {
    let filteredResults = [...results]
    if (report) {
      filteredResults = filteredResults.filter(result => new Date(result.collectedAt).getTime() <= new Date(report.result.collectedAt).getTime())
    }
    return filteredResults.sort((a, b) => {
      return new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
    }).find(result => {
      return result.values.some(value => value.value && value.test === testId)
    })?.values.find(value => {
      return value.test === testId
    }).risk
  }

  const getCurrentValue = (testId) => {
    let filteredResults = [...results]
    if (report) {
      filteredResults = filteredResults.filter(result => new Date(result.collectedAt).getTime() <= new Date(report.result.collectedAt).getTime())
    }
    const latestResult = filteredResults.sort((a, b) => {
      return new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
    }).find(result => {
      return result.values.some(value => value.value && value.test === testId)
    })

    const test = tests.find(t => t._id.toString() === testId.toString());

    // Special handling for blood pressure systolic
    if (test?.code === TestCode.BP_S || test?.code === TestCode.BP_D) {
      // Find both systolic and diastolic tests
      const systolicTest = tests.find(t => t.code === TestCode.BP_S);
      const diastolicTest = tests.find(t => t.code === TestCode.BP_D);
      
      if (systolicTest && diastolicTest) {
        const systolicValue = filteredResults
          .sort((a, b) => new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime())
          .find(result => result.values.some(value => value.value && value.test === systolicTest._id))
          ?.values.find(value => value.test === systolicTest._id)?.value;
        
        const diastolicValue = filteredResults
          .sort((a, b) => new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime())
          .find(result => result.values.some(value => value.value && value.test === diastolicTest._id))
          ?.values.find(value => value.test === diastolicTest._id)?.value;
        
        if (systolicValue && diastolicValue) {
          return `${systolicValue}/${diastolicValue}`;
        }
      }
    }
    
    const value = latestResult?.values.find(value => value.test === testId)?.value;
    return value
  }

  const getTest = (testId) => {
    const test = tests.find(({ _id }) => _id === testId)
    const riskRanges = test?.risk || []
    let goalText = ''

    // Special handling for blood pressure systolic test name
    if (test?.code === TestCode.BP_S || test?.code === TestCode.BP_D) {

      const diastolicTest = tests.find(t => t.code === TestCode.BP_D)
      const systolicTest = tests.find(t => t.code === TestCode.BP_S)

      const diastolicRanges = diastolicTest?.risk || []
      const systolicRanges = systolicTest?.risk || []

      // Find optimal ranges for both systolic and diastolic
      const systolicOptimal = systolicRanges.find(r => r.level === RiskLevel.OPTIMAL) || systolicRanges[0]
      const diastolicOptimal = diastolicRanges.find(r => r.level === RiskLevel.OPTIMAL) || diastolicRanges[0]
      
      if (systolicOptimal && diastolicOptimal) {
        goalText = `${systolicOptimal.lessThan}/${diastolicOptimal.lessThan}`
      }

      return {
        ...test,
        name: 'Blood Pressure', // Override the name for systolic
        goal: goalText,
        unit: test?.unit || '',
      }
    }

    // Regular handling for other tests
    const optimalRange = riskRanges.find(r => r.level === RiskLevel.OPTIMAL) || riskRanges[0]
    if (optimalRange) {
      const currentValue = getCurrentValue(testId).toString().replace(/[^\d.]/g, '');

      if (optimalRange.lessThan && optimalRange.greaterThan) {
        // If current value is over the optimal range, show the upper limit
        if (currentValue && parseFloat(currentValue) >= optimalRange.lessThan) {
          goalText = `${optimalRange.lessThan}`;
        } else if (currentValue && parseFloat(currentValue) <= optimalRange.greaterThan) {
            goalText = `${optimalRange.greaterThan}`;
        } else {
          goalText = `${optimalRange.greaterThan} - ${optimalRange.lessThan}`;
        }
      } 
      else if (optimalRange.lessThanOrEqual && optimalRange.greaterThanOrEqual) {
        if (currentValue && parseFloat(currentValue) > optimalRange.lessThanOrEqual) {
          goalText = `${optimalRange.lessThanOrEqual}`;
        } else if (currentValue && parseFloat(currentValue) < optimalRange.greaterThanOrEqual) {
          goalText = `${optimalRange.greaterThanOrEqual}`;
        } else {
          goalText = `${optimalRange.greaterThanOrEqual} - ${optimalRange.lessThanOrEqual}`;
        }
      }
      else if (optimalRange.lessThan || optimalRange.lessThanOrEqual) {
        goalText = `${optimalRange.lessThan || optimalRange.lessThanOrEqual}`;
      } else if (optimalRange.greaterThan || optimalRange.greaterThanOrEqual) {
        goalText = `${optimalRange.greaterThan || optimalRange.greaterThanOrEqual}`;
      }
    }

    // console.log(test.name, optimalRange, goalText)

    return {
      ...test,
      goal: goalText,
      unit: test?.unit || ''
    }
  }

  return items && (
    <div className="test-item-list-v2">
      {/* <TestModal
        open={openModal}
        setOpen={setOpenModal}
        testId={testModalId}
        setTestId={setTestModalId}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      /> */}

      <TestChartDrawer
        open={openModal}
        setOpen={setOpenModal}
        test={tests.find(test => test?._id === testModalId)}
      />

      

      <Row>
        {items.slice(0, showAll ? 100 : 2).map(item => (
          <Col
            key={`test-item-${item._id}`}
            className="test-item-col"
          >
            <div 
              className={classNames(
                "test-item",
                `${item.risk}-risk-border`
              )}
              onClick={() => {
                setTestModalId(item._id)
                setOpenModal(true)
              }}
            >
              <div className="test-item-content">
                <div className="test-item-left">
                  <Text className="test-name">
                    {item.name}
                    <span className={`risk-tag ${item.risk}-risk-tag`}>
                      {ReadableRiskLevel[item.risk]}
                    </span>
                  </Text>

                  <Paragraph
                    className="test-details"
                    ellipsis={{
                      rows: 3
                    }}
                  >
                    {item.tagline}
                  </Paragraph>
                </div>

                {item.currentValue && (
                  <div className="test-item-right">
                    <div className="value-box">
                      <Text className="value-label">Current Value</Text>
                      <Text className="value-text">
                        {item.currentValue} 
                        <span className="value-unit">{item.unit}</span>
                        {(() => {
                          const result = ResultHelper.getDirectionV2(item.code, results, tests);
                          
                          if (!result || !result.direction || result.direction === 'static') return null;
                          
                          const { direction, improving } = result;
                          
                          return (
                            <span className={`trend-icon ${improving ? 'trend-improving' : 'trend-worsening'}`}>
                              {direction === 'increasing' ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            </span>
                          );
                        })()}
                      </Text>
                    </div>
                    {item.risk === RiskLevel.OPTIMAL ? (
                      <div className="value-box">
                        <Text className="value-label">Goal</Text>
                        <Text className="value-text achievement">Done!</Text>
                      </div>
                    ) : (item.goal !== null) && (
                      <div className="value-box">
                        <Text className="value-label">Goal</Text>
                        <Text className="value-text">{item.goal || 0} <span className="value-unit">{item.unit}</span></Text>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* <div className={classNames(
                "risk-tag",
                `${item.risk}-risk-tag`
              )}>
                {ReadableRiskLevel[item.risk]}
              </div> */}
            </div>
          </Col>
        ))}
      </Row>

        {!showAll && testIds?.length > 2 && (
          <Button
            icon={<DownOutlined />}
            type='primary'
            onClick={() => setShowAll(true)}
            className="show-all-button"
          >
            View All
          </Button>
        )}
    </div>
  )
}