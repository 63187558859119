import React, { useEffect, useState, useContext } from "react";
import { Button, Table, Modal, Input, Form, Select, message, Switch, Upload } from 'antd';
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import PatientHelper from "../../helpers/patient.helper";
import "./adminProfile.scss";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { AdminLocationModal } from "../adminLocationModal/adminLocationModal.component";
import ObjectType from "../../enums/objectType.enum";
import { updatePatient } from "../../services/patient.service";
import MaskedInput from 'antd-mask-input'
import UserHelper from "../../helpers/user.helper";
import { UserContext } from "../../contexts/user.context";
import FileLink from "../fileLink/fileLink.component";
import { TransferModal } from "../transferModal/transferModal.component";
import KlaviyoService from "../../services/klaviyo.service.js";
import { uploadFile } from "../../services/file.service";
import FileTypeCode from "../../enums/fileTypeCode.enum";
const { Column } = Table;

const select = ''
const populate = [{path:'memberships', populate: [{path:'membershipType'}]}]

export const PhoneEditModal = ({ open, setOpen, patient, onSuccess }) => {
  const [form] = Form.useForm(); // Create form instance

  useEffect(() => {
    // Reset phone number in form when the modal opens
    if (open) {
      form.setFieldsValue({ phone: patient?.phone || "" });
    }
  }, [open, patient, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPatient = await updatePatient(patient._id, {
        fields: { phone: values.phone },
        select,
        populate
      });
      setOpen(false);
      onSuccess(updatedPatient); // Ensure onSuccess is passed the updated patient
    } catch (error) {
      console.error("Failed to update phone number:", error);
      message.error(error.response?.data?.message || "Failed to update phone number");
    }
  };

  return (
    <Modal
      title="Edit Phone Number"
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter a phone number",
            },
            {
              pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              message:
                "Please enter a valid phone number format: (###) ###-####",
            },
          ]}
        >
          <MaskedInput mask="(000) 000-0000" placeholder="Enter new phone number" />
        </Form.Item>
      </Form>
    </Modal>
  );

};

export const BasicFieldEditModal = ({ open, setOpen, patient, fieldName, onSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      const initialValue = fieldName === 'name' 
        ? { firstName: patient?.firstName, lastName: patient?.lastName }
        : { [fieldName]: patient?.[fieldName] };
      form.setFieldsValue(initialValue);
    }
  }, [open, patient, fieldName, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updateData = fieldName === 'name'
        ? { fields: { firstName: values.firstName, lastName: values.lastName },
        select,
        populate }
        : { 
            fields: { 
              [fieldName]: values[fieldName] 
            },
            select,
            populate
          };
      
      const updatedPatient = await updatePatient(patient._id, updateData);

      setOpen(false);
      onSuccess(updatedPatient);
    } catch (error) {
      console.error("Failed to update field:", error);
      if (error.response?.data?.code === 11000) {
        message.error('Account already exists with this email')
      } else {
        message.error(`Failed to update ${fieldName}`);
      }
    }
  };


  const renderFormItem = () => {
    switch (fieldName) {
      case 'name':
        return (
          <>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input />
            </Form.Item>
          </>
        );
      case 'preferredName':
        return (
          <Form.Item
            name="preferredName"
            label="Preferred Name"
          >
            <Input />
          </Form.Item>
        );
      case 'gender':
        return (
          <Form.Item
            name="gender"
            label="Biological Sex"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
            </Select>
          </Form.Item>
        );
      case 'dob':
        return (
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[
              { required: true, message: "Please enter date of birth" },
              {
                pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
                message: "Please enter a valid date format: MM/DD/YYYY"
              }
            ]}
          >
            <MaskedInput 
              mask="00/00/0000" 
              placeholder="MM/DD/YYYY"
            />
          </Form.Item>
        );
      case 'email':
        return (
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input />
          </Form.Item>
        );
      case 'isAthlete':
        return (
          <Form.Item
            name="isAthlete"
            label="Professional Athlete"
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        );

      case 'insuranceName':
        return (
          <Form.Item
            name="insuranceName"
            label="Insurance Name"
          > 
            <Input />
          </Form.Item>
        );  
      case 'insuranceMemberId':
        return (
          <Form.Item
            name="insuranceMemberId"
            label="Insurance Member ID"
          > 
            <Input />
          </Form.Item>
        );
      case 'insuranceGroupId':
        return (
          <Form.Item
            name="insuranceGroupId"
            label="Insurance Group ID"
          > 
            <Input />
          </Form.Item>
        );        

      case 'rxBin':
        return (
          <Form.Item
            name="rxBin"
            label="RxBIN"
          > 
            <Input />
          </Form.Item>
        );
      case 'rxPcn':
        return (
          <Form.Item
            name="rxPcn"
            label="RxPCN"
          >
            <Input />
          </Form.Item>
        );  
      case 'rxGroup':
        return (
          <Form.Item
            name="rxGroup"
            label="RxGroup"
          >   
            <Input />
          </Form.Item>
        );
     
      default:
        return null;
    }
  };

  const getModalTitle = () => {
    if (!fieldName) return 'Edit Field';
    return `Edit ${fieldName === 'name' ? 'Name' : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        {renderFormItem()}
      </Form>
    </Modal>
  );
};

export const HeightEditModal = ({ open, setOpen, patient, onSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ height: patient?.height || "" });
    }
  }, [open, patient, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPatient = await updatePatient(patient._id, {
        fields: { height: values.height },
        select,
        populate
      });
      setOpen(false);
      onSuccess(updatedPatient);
    } catch (error) {
      console.error("Failed to update height:", error);
      message.error("Failed to update height");
    }
  };

  return (
    <Modal
      title="Edit Height"
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="height"
          label="Height (inches)"

        >
          <Input type="number" placeholder="Enter height in inches" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const CashBalanceEditModal = ({ open, setOpen, patient, onSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ cashBalance: patient?.cashBalance || 0 });
    }
  }, [open, patient, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPatient = await updatePatient(patient._id, {
        fields: { cashBalance: parseFloat(values.cashBalance) },
        select,
        populate
      });
      setOpen(false);
      onSuccess(updatedPatient);
    } catch (error) {
      console.error("Failed to update cash balance:", error);
      message.error("Failed to update cash balance");
    }
  };

  return (
    <Modal
      title="Edit Cash Balance"
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="cashBalance"
          label="Cash Balance ($)"
          rules={[
            {
              required: true,
              message: "Please enter a cash balance"
            },
            {
              type: 'number',
              transform: value => Number(value),
              message: "Please enter a valid number"
            }
          ]}
        >
          <Input type="number" step="0.01" placeholder="Enter cash balance" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const AdminProfile = ({ patient, setPatient }) => {
  const [fieldName, setFieldName] = useState(null);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isBasicFieldModalOpen, setIsBasicFieldModalOpen] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isHeightModalOpen, setIsHeightModalOpen] = useState(false);
  const [isCashBalanceModalOpen, setIsCashBalanceModalOpen] = useState(false);
  const [uploadingFields, setUploadingFields] = useState({
    frontInsuranceCard: false,
    backInsuranceCard: false
  });

  useEffect(() => {
    if (!patient) return;
    document.title = `${patient.firstName} ${patient.lastName} - Profile | Instalab`;

    const fetchProfileData = async () => {
      try {
        const weight = await PatientHelper.getWeight(patient);
        
        // Ensure we have location data before rendering
        const physicalLocation = patient.location ? getLocation(patient.location) : '-';
        const shippingLocation = patient.shippingLocation ? getLocation(patient.shippingLocation) : '-';

        let profileData = [
          { 
            key: 'Full Name', 
            value: (
              <>
                {`${patient.firstName || '-'} ${patient.lastName || ''}`}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('name');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          {
            key: 'Preferred Name',  
            value: (
              <>
                {patient.preferredName || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('preferredName');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Biological sex', 
            value: (
              <>
                {patient.gender || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('gender');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Date of birth', 
            value: (
              <>
                {patient.dob || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('dob');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Email', 
            value: (
              <>
                {patient.email || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('email');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Phone', 
            value: ( 
              <>
                {patient.phone || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('phone');
                    setIsPhoneModalOpen(true);
                  }}
                />
              </>
            ) 
          },
          { 
            key: 'Physical Address', 
            value: (
              <>
                {physicalLocation}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('location');
                    setIsLocationModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Shipping Address', 
            value: (
              <>
                {shippingLocation}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('shippingLocation');
                    setIsLocationModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Height', 
            value: (
              <>
                {patient.height ? `${patient.height} in` : '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('height');
                    setIsHeightModalOpen(true);
                  }}
                />
              </>
            )
          },
          { key: 'Weight', value: patient.weight ? `${weight} lbs` : '-' },

          { 
            key: 'Professional Athlete', 
            value: (
              <>
                {patient.isAthlete ? "Yes" : "No"}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('isAthlete');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },

        ];

        // Add credit and cash balance rows only for admins
        if (UserHelper.isAdmin(currentUser)) {
          profileData.push(
            { 
              key: 'Credits', 
              value: (
                <>
                  {patient.credits && patient.credits.length > 0 
                    ? (() => {
                        const groupedCredits = patient.credits.reduce((acc, credit) => {
                          if (acc[credit]) {
                            acc[credit] = acc[credit] + 1;
                          } else {
                            acc[credit] = 1;
                          }
                          return acc;
                        }, {});
                        
                        return Object.keys(groupedCredits)
                          .map(credit => `${credit} x ${groupedCredits[credit]}`)
                          .join(', ');
                      })()
                    : '-'
                  }
                  <Button
                    icon={<PlusOutlined />}
                    className="edit-btn"
                    onClick={() => {
                      setIsTransferModalOpen(true);
                    }}
                  />
                </>
              )
            },
            { 
              key: 'Cash Balance', 
              value: (
                <>
                  ${(patient.cashBalance || 0).toFixed(2)}
                  <Button
                    icon={<EditOutlined />}
                    className="edit-btn"
                    onClick={() => {
                      setFieldName('cashBalance');
                      setIsCashBalanceModalOpen(true);
                    }}
                  />
                </>
              )
            }
          );
        }

        if (UserHelper.isAdmin(currentUser) || UserHelper.isProvider(currentUser) || UserHelper.isStaff(currentUser)) {
          profileData.push({
            key: 'Insurance Card',
            value: (
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {patient.frontInsuranceCard && 
                    <FileLink title="View Front" fileId={patient.frontInsuranceCard} showLink />
                  }
                  <Upload
                    beforeUpload={() => false}
                    onChange={(info) => handleFileChange(info, 'frontInsuranceCard')}
                    accept="image/*,.pdf"
                    multiple={false}
                    showUploadList={false}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      loading={uploadingFields.frontInsuranceCard}
                    >Front</Button>
                  </Upload>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {patient.backInsuranceCard && 
                    <FileLink title="View Back" fileId={patient.backInsuranceCard} showLink />
                  }
                  <Upload
                    beforeUpload={() => false}
                    onChange={(info) => handleFileChange(info, 'backInsuranceCard')}
                    accept="image/*,.pdf"
                    multiple={false}
                    showUploadList={false}
                  >
                    <Button 
                      icon={<UploadOutlined />}
                      loading={uploadingFields.backInsuranceCard}
                    >Back</Button>
                  </Upload>
                </div>
              </div>
            )
          },
          {
            key: 'Insurance Name',
            value: (
              <>
                {patient.insuranceName || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('insuranceName');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          {
            key: 'Insurance Member ID',
            value: (
              <>
                {patient.insuranceMemberId || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('insuranceMemberId');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          {
            key: 'Insurance Group ID',
            value: (
              <>
                {patient.insuranceGroupId || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('insuranceGroupId');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          {
            key: 'RxBIN',
            value: (
              <>
                {patient.rxBin || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('rxBin');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          {
            key: 'RxPCN',
            value: (
              <>
                {patient.rxPcn || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('rxPcn');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          {
            key: 'RxGroup',
            value: (
              <>
                {patient.rxGroup || '-'}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('rxGroup');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Instalab News Emails', 
            value: <Switch
              checked={patient.email_marketing}
              onChange={(checked) => handleEmailToggle('email_marketing', checked)}
              checkedChildren="Subscribed"
              unCheckedChildren="Unsubscribed"
              size="small"
            />
          },
          { 
            key: 'Instalab Brief Emails', 
            value: <Switch
              checked={patient.email_newsletter}
              onChange={(checked) => handleEmailToggle('email_newsletter', checked)}
              checkedChildren="Subscribed"
              unCheckedChildren="Unsubscribed"
              size="small"
            />
          },
         
        )
        }

        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching profile data:', error);
        message.error('Failed to load profile data');
      }
    };

    fetchProfileData();
  }, [patient]);


  const refreshLocationData = async (updatedPatient) => {
    const physicalLocation = updatedPatient.location ? getLocation(updatedPatient.location) : 'No address set';
    const shippingLocation = updatedPatient.shippingLocation ? getLocation(updatedPatient.shippingLocation) : 'No address set';

    setProfile(prevProfile => prevProfile.map(item => {
      if (item.key === 'Physical Address') {
        return {
          ...item,
          value: (
            <>
              {physicalLocation}
              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('location');
                  setIsLocationModalOpen(true);
                }}
              />
            </>
          )
        };
      }
      if (item.key === 'Shipping Address') {
        return {
          ...item,
          value: (
            <>
              {shippingLocation}
              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('shippingLocation');
                  setIsLocationModalOpen(true);
                }}
              />
            </>
          )
        };
      }
      return item;
    }));
  };

  const handlePatientUpdate = (updatedPatient) => {
    if (fieldName === 'location' || fieldName === 'shippingLocation') {
      // For location updates, just refresh the display
      refreshLocationData(updatedPatient);
    } else {
      // For other fields, update both patient state and display
      setPatient(updatedPatient);
      refreshLocationData(updatedPatient);
    }
  };

  const onTransferSuccess = (transfer) => {
    setPatient(prevPatient => ({
      ...prevPatient,
      credits: [
        ...(prevPatient.credits || []),
        ...(transfer.productTypeCodes || [])
      ]
    }));
  };

  const getLocation = (location) => {
    if (!location) return 'No address set';
    const { streetAddress, streetAddress2, city, state, postalCode } = location;
    if (!streetAddress || !city || !state || !postalCode) return 'Incomplete address';
    
    let address = streetAddress;
    if (streetAddress2) address += ` ${streetAddress2}`;
    return `${address}, ${city}, ${state} ${postalCode}`;
  };

  const handleEmailToggle = async (field, checked) => {
    try {
      // Update the backend
      await updatePatient(patient._id, {
        fields: {
          [field]: checked
        },
      });
      
      // Update local state
      setPatient(prev => ({
        ...prev,
        [field]: checked
      }));

      // Sync with Klaviyo
      try {
        const listType = field === 'email_marketing' ? 'marketing' : 'newsletter';
        if (checked) {
          await KlaviyoService.addToKlaviyo({ 
            email: patient.email, 
            firstName: patient.firstName, 
            lastName: patient.lastName, 
            listType: listType 
          });
        } else {
          await KlaviyoService.removeFromKlaviyo({ 
            email: patient.email, 
            listType: listType 
          });
        }
      } catch (error) {
        console.error('Failed to sync with Klaviyo:', error);
      }

      // Show success message
      const fieldName = field === 'email_marketing' ? 'Marketing Emails' : 'Instalab Brief Emails';
      message.success(`Successfully ${checked ? 'subscribed to' : 'unsubscribed from'} ${fieldName}`);
    } catch (error) {
      console.error('Error updating email preferences:', error);
      message.error('Failed to update. Please try again.');
      
      // Revert the patient state on error
      setPatient(prev => ({
        ...prev,
        [field]: !checked
      }));
    }
  };

  const handleFileChange = async (info, cardType) => {
    const file = info.file;
    if (!file) return;

    setUploadingFields(prev => ({ ...prev, [cardType]: true }));
    try {
      // Upload the file and get the file ID
      const fileTypeCode = cardType === "frontInsuranceCard" ? FileTypeCode.INSURANCE_CARD_FRONT : 
                          cardType === "backInsuranceCard" ? FileTypeCode.INSURANCE_CARD_BACK : 
                          FileTypeCode.OTHER;
      const fileResponse = await uploadFile(file, {
        fileTypeCode: fileTypeCode, 
        patientId: patient._id
      });
      
      // Update the patient record with the new file ID
      const updatedPatient = await updatePatient(patient._id, {
        fields: {
          [cardType]: fileResponse.file._id
        },
        select,
        populate
      });

      setPatient(updatedPatient);
      message.success('Insurance card uploaded successfully');
    } catch (error) {
      console.error('Upload failed:', error);
      message.error('Failed to upload file');
    } finally {
      setUploadingFields(prev => ({ ...prev, [cardType]: false }));
    }
  };

  return (
    <div className="admin-profile">
      <PageHeader title='Profile' />

      <AdminLocationModal
        objectId={patient?._id}
        objectType={ObjectType.PATIENT}
        fieldName={fieldName}
        open={isLocationModalOpen}
        setOpen={setIsLocationModalOpen}
        onSuccess={handlePatientUpdate}
      />

      <PhoneEditModal
        open={isPhoneModalOpen}
        setOpen={setIsPhoneModalOpen}
        patient={patient}
        onSuccess={handlePatientUpdate}
      />

      <BasicFieldEditModal
        open={isBasicFieldModalOpen}
        setOpen={setIsBasicFieldModalOpen}
        patient={patient}
        fieldName={fieldName}
        onSuccess={handlePatientUpdate}
      />

      <HeightEditModal
        open={isHeightModalOpen}
        setOpen={setIsHeightModalOpen}
        patient={patient}
        onSuccess={handlePatientUpdate}
      />

      <CashBalanceEditModal
        open={isCashBalanceModalOpen}
        setOpen={setIsCashBalanceModalOpen}
        patient={patient}
        onSuccess={handlePatientUpdate}
      />

      <TransferModal
        userId={patient?._id}
        open={isTransferModalOpen}
        setOpen={setIsTransferModalOpen}
        onSuccess={onTransferSuccess}
      />

      {profile.length > 0 && (
        <div className="profile-section">
          <Table
            loading={!profile.length}
            className='primary-table'
            pagination={false}
            rowKey='key'
            dataSource={profile}
            showHeader={false}
          >
            <Column dataIndex='key' width={150} />
            <Column dataIndex='value' render={(value) => value} />
          </Table>
        </div>
      )}
    </div>
  );
};
