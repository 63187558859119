import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { BiobeatConfirm } from "./biobeatConfirm.component"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"
import ProductHelper from "../../helpers/product.helper"
export const Step = {
  ACCOUNT: 'account',
  MEMBERSHIP: 'membership',
  BP: 'bp',
  GET_BP: 'get-bp',
  PAY: 'pay',
  PAY_BP: 'pay-bp',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  BIOMETRICS: 'biometrics',
  DELIVERY: 'delivery',
  CONFIRM: 'confirm',
}

export const BiobeatFlow = () => {
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, instalabMembership, flow, hasPass, initialUser])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    const response = await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    })
    setMembershipTypes(response)
  }
  
  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.BIOBEAT,
            ProductTypeCode.BP_MONITOR
          ]
        }
      }
    })
    setProductTypes(response)
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || instalabMembership === undefined || !initialUser || !productTypes) return


    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.BP,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.BIOBEAT}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.BP]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          if (patient?.hasBPmonitor) {
            return Step.PAY
          } else {
            return Step.GET_BP
          }
        },
        title: `Do you have access to a blood pressure monitor with an arm-cuff?`,
        description: `You'll need three readings from one to calibrate your Biobeat Chest Monitor.`,
        field: {
          name: 'hasBPmonitor',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.GET_BP]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          if (patient?.sendBPmonitor) {
            return Step.PAY_BP
          } else {
            return Step.PAY
          }
        },
        title: `Would you like us to also send you a blood pressure monitor for calibration purposes?`,
        description: `It'll just be an additional ${ProductHelper.formatPrice(getProductType(ProductTypeCode.BP_MONITOR)?.cost)}.`,
        field: {
          name: 'sendBPmonitor',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: `No, I'll get one myself.`,
            value: false,
          }]
        }
      },
      
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: ProductTypeCode.BIOBEAT,
        title:() => {
          const totalCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.BIOBEAT)],
            instalabMembership,
            currentUser
          })
          return <>Pay <span className="true-price">${totalCost}</span> for the <span className="product-name">Biobeat</span>.</>
        },
      },
      [Step.PAY_BP]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: [ProductTypeCode.BIOBEAT, ProductTypeCode.BP_MONITOR],
        title: () => {
          const totalCost = ProductHelper.getTotalCostV2({
            productTypes: [getProductType(ProductTypeCode.BIOBEAT), getProductType(ProductTypeCode.BP_MONITOR)],
            instalabMembership,
            currentUser
          })
          return <>Pay <span className="true-price">${totalCost}</span> for the <span className="product-name">Biobeat + calibrating BP monitor</span>.</>
        },
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.BIOMETRICS,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.BIOMETRICS]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What's your current height and weight?`,
        description: `Please enter the reading of when you were last measured.`,
        fields: [{
          name: 'height',
          label: 'Height (inches)',
          placeholder: 'Type your height here...',
        }, {
          name: 'weight',
          label: 'Weight (lbs)',
          placeholder: 'Type your weight here...',
        }],
        skipIf: () => {
          return initialUser?.height &&  initialUser?.weight
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `What's your delivery address?`,
        description: `We'll send your ${flow?.user?.sendBPmonitor ? `Biobeat Chest Monitor + calibrating blood pressure monitor` : `Biobeat Chest Monitor`} to this location.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>You're all set! 🎉</>,
        description: `We'll send the  ${flow?.user?.sendBPmonitor ? `Biobeat Chest Monitor + calibrating blood pressure monitor` : `Biobeat Chest Monitor`} within 1 business day. Tracking number will be available in your account if you'd like to track its progress.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <BiobeatConfirm sendBPmonitor={flow?.user?.sendBPmonitor}/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={0}
      productTypeCode={flow?.user?.sendBPmonitor ? [ProductTypeCode.BIOBEAT, ProductTypeCode.BP_MONITOR] : [ProductTypeCode.BIOBEAT]}
    />
  )
}