import { Typography } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";
const { Paragraph } = Typography

export const IfPost = () => {
  const { blogId } = useParams()

  return (
    <div className={classNames("blog-post", "if-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-description">
          At its core, intermittent fasting cycles between periods of eating and fasting, typically for extended hours between meals. The most common version, <b>the 16:8 method</b>, involves fasting for 16 hours and eating within an 8-hour window, often skipping breakfast and consuming meals between noon and 8 PM.
        </Paragraph>

        <Paragraph className="blog-title">
          The Benefits of Intermittent Fasting
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>Weight Loss and Metabolic Health:</strong> Intermittent fasting isn't just about eating less—it shifts how the body fuels itself. In the fasting state, insulin levels drop, prompting the body to break down stored fat for energy. This metabolic switch has led to weight loss in numerous studies, with reductions ranging from <b>1–8% of baseline body weight</b>. Beyond the numbers on the scale, IF enhances insulin sensitivity and lowers fasting glucose, critical factors in preventing type 2 diabetes.
          </li>

          <li>
            <strong>Cardiovascular Benefits:</strong> Heart disease remains the leading cause of death worldwide, and intermittent fasting appears to offer meaningful protection. Studies show that IF lowers triglycerides and LDL cholesterol while improving blood pressure control. These aren't just numbers on a chart—lower triglycerides mean reduced arterial plaque buildup, and improved blood pressure reduces strain on the heart, lowering the risk of heart attacks and strokes.
          </li>

          <li>
            <strong>Longevity and Cellular Health:</strong> Intermittent fasting alters how cells repair and regenerate. The fasting state activates autophagy, a process where cells clear out damaged components and recycle them for energy. This process is linked to reduced risks of neurodegenerative diseases like Alzheimer's and Parkinson's and may be one reason fasting is correlated with longevity in animal studies.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Avoiding Muscle Loss and Nutrient Deficiencies
        </Paragraph>
        <Paragraph className="blog-description">
          Maintaining lean muscle mass is key to long-term health, and while IF supports fat loss, inadequate nutrition can lead to muscle breakdown. Protein intake needs to remain high—at least <b>1.2–2.0 grams per kilogram of body weight per day</b>—to ensure muscle preservation. Meals should include high-quality protein sources like lean meats, fish, eggs, dairy, and plant-based proteins.
        </Paragraph>

        <Paragraph className="blog-description">
          Strength training is equally important. Resistance exercises three to four times per week counteract muscle loss, signaling the body to maintain muscle tissue even in a caloric deficit. Hydration and electrolyte intake also matter, as prolonged fasting periods can lead to lower sodium, potassium, and magnesium levels, causing fatigue and muscle cramps.
        </Paragraph>

        <Paragraph className="blog-description">
          Whole, nutrient-dense foods should make up the bulk of meals. Dark leafy greens, nuts, seeds, and fatty fish help cover vitamin and mineral needs, while fiber-rich foods support gut health and digestion during eating windows.
        </Paragraph>

        <Paragraph className="blog-title">
          Who Should Avoid Intermittent Fasting?
        </Paragraph>
        <Paragraph className="blog-description">
          While many benefit from IF, there are groups for whom fasting poses significant risks.
        </Paragraph>

        <ul className="blog-list">
          <li><strong>People with high nutritional needs:</strong> This includes children, underweight individuals, and pregnant or breastfeeding women. Proper development is essential for these groups, and fasting can lead to nutrient deficiencies that impact growth and overall health.</li>
          <li><strong>Individuals with a history of eating disorders:</strong> Restrictive eating patterns can trigger or worsen disordered behaviors.</li>
          <li><strong>People with diabetes or requiring insulin therapy:</strong> While IF can improve insulin sensitivity, those on insulin therapy or certain diabetes medications are at high risk for dangerous blood sugar drops (hypoglycemia) when fasting. The absence of regular food intake can cause blood sugar to plummet, leading to dizziness, confusion, and even loss of consciousness.</li>
        </ul>

        <Paragraph className="blog-title">
          Getting It Right: Maximizing Benefits Without Risks
        </Paragraph>
        <Paragraph className="blog-description">
          Intermittent fasting has the potential to deliver significant benefits for heart health, weight management, and longevity. However, it's not a diet that can be done on autopilot. With a compressed eating window, there's less room for error—nutrient intake must be intentional, muscle preservation should be a priority, and overall health should be monitored. Approaching IF with this kind of structured plan is mandatory if you want the benefits to outweigh the risks.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Varady, K., Cienfuegos, S., Ezpeleta, M., & Gabel, K. (2021). Cardiometabolic Benefits of Intermittent Fasting. Annual review of nutrition, 41, 333-361. <a onClick={() => window.open('https://doi.org/10.1146/annurev-nutr-052020-041327', '_blank')}>https://doi.org/10.1146/annurev-nutr-052020-041327</a>
            </li>
            <li>
              Cheung, K., Chan, V., Chan, S., Wong, M., Chung, G., Cheng, W., Lo, K., & Zeng, F. (2024). Effect of Intermittent Fasting on Cardiometabolic Health in the Chinese Population: A Meta-Analysis of Randomized Controlled Trials. Nutrients, 16. <a onClick={() => window.open('https://doi.org/10.3390/nu16030357', '_blank')}>https://doi.org/10.3390/nu16030357</a>
            </li>
            <li>
              Dong, T., Sandesara, P., Dhindsa, D., Mehta, A., Arneson, L., Dollar, A., Taub, P., & Sperling, L. (2020). Intermittent Fasting: A Heart Healthy Dietary Pattern?. The American journal of medicine. <a onClick={() => window.open('https://doi.org/10.1016/j.amjmed.2020.03.030', '_blank')}>https://doi.org/10.1016/j.amjmed.2020.03.030</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};