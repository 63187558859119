import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'


export const listPanels = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/panels`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}


export const getPanel = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/panels/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  return response.data
}

export const getPanelByCode = async (code, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/panels/code/${code}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addPanel = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/panels`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removePanel = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/panels/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const updatePanel = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/panels/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}
