import Medication from "../enums/medication.enum"

const MedicationHelper = {

    isOnThyroidMedication: (medications) => {
        return medications.some(medication => [Medication.LEVOTHYROXINE, Medication.THYROID].includes(medication))
    }

}

export default MedicationHelper