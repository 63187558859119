import { Modal, Select, Input, Form, Checkbox, message, Row, Col, Divider, Button } from 'antd'
import { listPatients } from '../../services/patient.service';
import PrescriptionType from '../../enums/prescriptionType.enum';
import { useState, useEffect } from 'react'
import './prescriptionForm.scss'
import { addPrescription, getContents, getPrescription, updatePrescription } from '../../services/prescription.service';
import { ContinueButton } from '../continueButton/continueButton.component';
import Org from '../../enums/org.enum';
import DateInput from '../dateInput/dateInput.component';
import { PharmacyFormModal } from '../pharmacyForm/pharmacyFormModal.component';
import { listPharmacies } from '../../services/pharmacy.service';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PlusOutlined } from '@ant-design/icons';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const { Item } = Form

export const PrescriptionForm = ({ isRefill=false, open, setOpen, onSuccess, patientId, prescriptionId }) => {
  const [patients, setPatients] = useState([])
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [pharmacies, setPharmacies] = useState([])
  const [showPharmacyModal, setShowPharmacyModal] = useState(false)
  const [prescription, setPrescription] = useState(null)
  const [hasRefill, setHasRefill] = useState(true)

  useEffect(() => {
    if (!patientId) {
      fetchPatients()
    }
  }, [patientId])

  useEffect(() => {
    if (open && prescriptionId) {
      fetchPrescription()
    } else {
      setHasRefill(true)
    }
  }, [open, prescriptionId])

  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [open])

  useEffect(() => {
    if (open) {
      fetchPharmacies()
    }
  }, [open])

  useEffect(() => {
    const hasRefillValue = form.getFieldValue('hasRefill')
    setHasRefill(hasRefillValue)
  }, [form])

  const onCancel = () => {
    setOpen(false)
  }

  const fetchPharmacies = async () => {
    const response = await listPharmacies()
    setPharmacies(response)
  }

  const fetchPatients = async () => {
    const response = await listPatients({
      select: '_id firstName lastName',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        }
      },
    })
    setPatients(response.filter(({ firstName, lastName }) => firstName && lastName))
  }

  const fetchPrescription = async () => {
    try {
      const response = await getPrescription(prescriptionId)
      setPrescription(response)
      if (response.hasRefill) {
        setHasRefill(true)
      }
      form.setFieldsValue({
        patient: response.patient._id,
        type: response.type,
        contentsType: response.contents.type,
        units: response.contents.units,
        doseQty: response.contents.doseQty,
        sig: response.contents.sig,
        daysToRefill: response.daysToRefill,
        isSelfManaged: response.isSelfManaged,
        hasRefill: response.hasRefill,
        isPickupOrder: response.isPickupOrder,
        pharmacy: response.pharmacy?._id,
        refillAt: response.refillAt ? 
          dayjs(response.refillAt)
            .tz('America/New_York')
            .format('MM/DD/YYYY') 
          : null,
        deliveredAt: response.deliveredAt ? 
          dayjs(response.deliveredAt)
            .tz('America/New_York')
            .format('MM/DD/YYYY') 
          : null,
        orderedAt: response.orderedAt ? 
          dayjs(response.orderedAt)
            .tz('America/New_York')
            .format('MM/DD/YYYY') 
          : null
      })
    } catch (err) {
      console.log(err)
      message.error('Failed to load prescription')
    }
  }
  
  const onOk = async () => {
    setIsSubmitting(true)
    try {
      let values = form.getFieldsValue()
      values.contents = {
        type: values.contentsType,
        units: values.units,
        doseQty: values.doseQty,
        sig: values.sig,
      }
      if (prescription?.patient || patientId) {
        values.patient = prescription?.patient || patientId
      }
      if (values.deliveredAt) {
        values.deliveredAt = dayjs.tz(values.deliveredAt, 'MM/DD/YYYY', 'America/New_York').toDate()
      }
      if (values.refillAt) {
        values.refillAt = dayjs.tz(values.refillAt, 'MM/DD/YYYY', 'America/New_York').toDate()
      }
      if (values.orderedAt) {
        values.orderedAt = dayjs.tz(values.orderedAt, 'MM/DD/YYYY', 'America/New_York').toDate()
      }

      let updatedPrescription
      if (prescriptionId && !isRefill) {
        updatedPrescription = await updatePrescription(prescriptionId, values)
        message.success('Prescription updated')
      } else {
        updatedPrescription = await addPrescription(values)
        message.success('Prescription added')
      }

      onSuccess(updatedPrescription)
      setOpen(false)
      form.resetFields()
    } catch (err) {
      console.log(err)
      message.error((prescriptionId && !isRefill) ? 'Failed to update prescription' : 'Failed to add prescription')
    }
    setIsSubmitting(false)
  }

  const onPrescriptionTypeSelect = async (prescriptionType) => {
    const contents = await getContents(prescriptionType)
    if (contents) {
      const {
        units,
        doseQty,
        sig,
        type: contentsType,
        daysToRefill,
      } = contents
      form.setFieldsValue({
        units,
        doseQty,
        sig,
        daysToRefill,
        contentsType,
      })
    }
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="prescription-form"
      title={isRefill ? "Refill Prescription" : prescriptionId ? "Edit Prescription" : "Add New Prescription"}
      width={500}
      footer={null}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onOk}
      >
        {!patientId && !prescriptionId && (
          <Item
            label="Patient"
            name="patient"
          >
            <Select
              placeholder="Select Patient"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              options={patients?.map(({ _id, firstName, lastName }) => {
                return {
                  label: `${firstName} ${lastName}`,
                  value: _id,
                }
              })}
            />
          </Item>
        )}

        <Item
          name="pharmacy"
          label="Pharmacy"
        >
          <Select
            placeholder="Select Pharmacy"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  block
                  onClick={() => setShowPharmacyModal(true)}
                >
                  Add New Pharmacy
                </Button>
              </>
            )}
            options={pharmacies?.map(({ _id, name }) => {
              return {
                label: name,
                value: _id,
              }
            })}
            allowClear
          />
        </Item>
        
        <Item
          name="type"
          label="Formula"
        >
          <Select
            placeholder="Select Formula"
            showSearch
            optionFilterProp="children"
            onSelect={onPrescriptionTypeSelect}
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(PrescriptionType)
              .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
              .map(prescriptionType => {
                return {
                  label: prescriptionType,
                  value: prescriptionType,
                }
              })}
          />
        </Item>

        <div className="prescription-contents">
          <Row gutter={16}>
            <Col span={12}>
              <Item
                name="contentsType"
                label="Prescription Type"
              >
                <Select
                  placeholder="Select Prescription Type"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => 
                    option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                  }
                  options={Object.values(PrescriptionType)
                    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                    .map(prescriptionType => {
                      return {
                        label: prescriptionType,
                        value: prescriptionType,
                      }
                    })}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Units"
                name="units"
              >
                <Input
                  placeholder="Units"
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Dose Quantity"
                name="doseQty"
              >
                <Input
                  placeholder="Dose Quantity"
                />
              </Item>
            </Col>

            <Col span={12}>
              <Item
                label="Days to Refill"
                name="daysToRefill"
              >
                <Input
                  placeholder="Days to Refill"
                />
              </Item>
            </Col>
          </Row>

          <Item
            label="Directions"
            name="sig"
          >
            <Input
              placeholder="Directions"
            />
          </Item>
        </div>

        <Item 
          label="Has Refill" 
          name="hasRefill" 
          initialValue={prescriptionId ? null : true} 
          valuePropName="checked">
          <Checkbox onChange={(e) => setHasRefill(e.target.checked)} />
        </Item>

        {hasRefill && (
          <Item
            label="Refill Date"
            name="refillAt"
          >
            <DateInput format="MM/DD/YYYY" placeholder="MM/DD/YYYY" />
          </Item>
        )}

        <Item 
          label="Self-Managed" 
          name="isSelfManaged" 
          valuePropName="checked"
        >
          <Checkbox />
        </Item>

        <Item 
          label="Pickup Order" 
          name="isPickupOrder" 
          initialValue={false} 
          valuePropName="checked">
          <Checkbox />
        </Item>

        <Item
          label="Order Date"
          name="orderedAt"
        >
          <DateInput format="MM/DD/YYYY" placeholder="MM/DD/YYYY" />
        </Item>

        <ContinueButton
          text={(prescriptionId && !isRefill) ? 'Update Prescription' : 'Save Prescription'}
          isSubmitting={isSubmitting}
        />
      </Form>

      <PharmacyFormModal
        open={showPharmacyModal}
        onCancel={() => setShowPharmacyModal(false)}
        onSuccess={(newPharmacy) => {
          setPharmacies(prevPharmacies => {
            const existingIndex = prevPharmacies.findIndex(p => p._id === newPharmacy._id);
            if (existingIndex >= 0) {
              // Update existing pharmacy
              const updatedPharmacies = [...prevPharmacies];
              updatedPharmacies[existingIndex] = newPharmacy;
              return updatedPharmacies;
            } else {
              // Add new pharmacy
              return [...prevPharmacies, newPharmacy];
            }
          });
          form.setFieldsValue({
            pharmacy: newPharmacy._id
          });
          setShowPharmacyModal(false);
        }}
      />
    </Modal>
  )
}