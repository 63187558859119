import React from "react";
import "./hrtImpactAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition, TestCode, QuestionTemplateCode} from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import { AdviceSection }  from "../adviceSection/adviceSection.component";
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const HrtImpactAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [possibleSupplementation, setPossibleSupplementation ] = useState();
  const [hasElevatedHemoglobin, setHasElevatedHemoglobin] = useState();
  const [hormoneTests, setHormoneTests] = useState([]);
  const [improvedTests, setImprovedTests] = useState([]);
  const [worseTests, setWorseTests] = useState([]);
  const [staticTests, setStaticTests] = useState([]);
  const [isNaturalBirth, setIsNaturalBirth] = useState();
  const [recs, setRecs] = useState([]);
  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();
  
  useEffect(() => {
    categorizeTests();
    setPossibleSupplementation(report?.result?.conditions.includes(Condition.POSSIBLE_T_SUPPLEMENTATION))
    setIsNaturalBirth(ReportHelper.getAnswer(report, QuestionTemplateCode.PLANNING_CHILDREN))
  }, [report, tests]);

  useEffect(() => {
    if (!hormoneTests) return
    const hasElevatedHemoglobin = hormoneTests.find(hormoneTest => hormoneTest.code === TestCode.HEMOGLOBIN)
    setHasElevatedHemoglobin(hasElevatedHemoglobin)
  }, [hormoneTests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
    setSecondaryDescription(getSecondaryDescription())
  }, [report, hormoneTests, hasElevatedHemoglobin, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
          recs: recs
        }
      }
    })
  }, [title, primaryDescription, secondaryDescription, recs])

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setHormoneTests(ReportHelper.getSectionTests(report, ReportSection.HRT_IMPACT, tests) || []);
    setImprovedTests(ReportHelper.getSectionImprovedTests(report, ReportSection.HRT_IMPACT, tests) || [])
    setWorseTests(ReportHelper.getSectionWorseTests(report, ReportSection.HRT_IMPACT, tests) || [])
    setStaticTests(ReportHelper.getSectionStaticTests(report, ReportSection.HRT_IMPACT, tests) || [])
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !results || !tests || !hormoneTests) return null;
  
    if (possibleSupplementation) {
      return <>These <span>{converter.toWords(hormoneTests.length)} biomarkers</span> are commonly seen with <span>testosterone supplementation</span>.</>
    }

    let content = (
      <>
        {hormoneTests?.length === 1
            ? <>Your <span>{LowercaseTest[hormoneTests[0].code]}</span> level is <span>abnormal</span> </>
            : <>These <span>{converter.toWords(hormoneTests?.length)} biomarkers</span> are <span>abnormal</span></>
        }, likely due to HRT.         
      </>
    );

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)
    if (!report || !hormoneTests) return null;

    if (possibleSupplementation) {
      return (
        <div>
          External testosterone supplementation (e.g. shots, patches, or gels) typically results in increased testosterone levels and decreased LH and FSH. 
          This decrease is due to the body's perception of increased testosterone, which triggers a signal to the pituitary gland to reduce the production of LH and FSH, the hormones that naturally stimulate testosterone production
        </div>
      )
    }

    if (hasElevatedHemoglobin) {
      return (
        <div>
          Testosterone-based HRT can increase hemoglobin and hematocrit levels. It can also suppress LH and FSH production, as external testosterone reduces your body's need for these hormones that naturally stimulate testosterone production. This suppression can affect sperm production and fertility.
          <br/><br/>
          It's recommended you talk with the healthcare provider who prescribed the HRT about these possible side effects and how to manage them. If you're considering conceiving naturally, you may need to adjust or change your HRT to balance hormone levels and preserve fertility.
        </div>
      )
    }

    return (
      <div>
        Testosterone-based HRT can suppress LH and FSH production, as external testosterone reduces your body's need for these hormones that naturally stimulate testosterone production. This suppression can affect sperm production and fertility.
        {(isNaturalBirth || isNaturalBirth === undefined) && (
          <>
            <br/><br/>
            If you're considering conceiving naturally, it's recommended you talk with the healthcare provider who prescribed the HRT about adjusting or changing your HRT to balance hormone levels and preserve fertility.
          </>
        )}
     
      </div>
    )
  };

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)
    if (!report || !hormoneTests) return null;

    if (possibleSupplementation) {
      return (
        <>
          If you're supplementing, be aware that suppression of LH and FSH can impact sperm production and fertility. If you're considering conceiving naturally, consult with the healthcare provider who prescribed the supplementation about adjusting or changing your it to balance hormone levels and preserve fertility.
          <br/><br/>If you're not supplementing, these hormonal changes need further investigation for underlying causes. See your primary care provider for diagnosis and treatment options.
        </>
      )
    }

    return null
  };

  return (
    hormoneTests && (
      <div className="hrt-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={hormoneTests}
          results={results}
          reports={reports}
          user={user}
        />
        {secondaryDescription && (
          <Paragraph className="report-template-description">
            {secondaryDescription}
          </Paragraph>
        )}

        <AdviceSection
          report={report}  
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
