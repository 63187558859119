import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Button, Select, message, Checkbox } from 'antd'
import Gender from '../../enums/gender.enum'
import { addPatient, getPatient, updatePatient } from '../../services/patient.service';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import PhoneInput from '../phoneInput/phoneInput.component';
import moment from 'moment';
import DateInput from '../dateInput/dateInput.component';

const { Item } = Form

export const BudgeTestDate = ({ patientId, title, open, setOpen, onSuccess, select, populate=[], requiredFields=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch ({ errors, values }) {
      form.setFieldsValue(values)
      return true
    }
    return false
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid data')
  }

  const onOk = async (skipInvite) => {
    setIsSubmitting(true)

    try {
      const hasError = await hasFormError()
      if (hasError) {
        message.error('Enter valid data')
        setIsSubmitting(false)
        return 
      }

      const {
        nextTestAt
      } = form.getFieldsValue()
      
      const params = {
        fields: {
          nextTestAt: moment(nextTestAt, 'MM/DD/YYYY').toDate()  // Convert string to Date object
        },
        select,
        populate,
        skipInvite
      }

      const response = await updatePatient(patientId, params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(`Next test date set: ${moment(nextTestAt).format('MMM DD, YYYY')}`)
      setOpen(false)
      form.resetFields()
    } catch (err) {
      console.log(err)
      message.error('Failed to update patient')
    } finally {
      setIsSubmitting(false)
  
    }
  }

  const isFieldRequired = (fieldName) => {
    return requiredFields.includes(fieldName)
  }

  return (
    <Modal 
      open={open} 
      title={title ? title : patientId ? 'Edit Patient' : 'Add Patient'}
      onCancel={onCancel}
      footer={null}
      className="patient-form"
    >

      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Item 
          name="nextTestAt"
          rules={[
            ...(isFieldRequired('nextTestAt') ? [RuleHelper.isRequired] : []),
            RuleHelper.isDate,
          ]}
          validateTrigger='onSubmit'
        >
          <DateInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Button
          className="submit-btn"
          type='primary'
          loading={isSubmitting}
          onClick={() => {
            form.validateFields()
              .then(() => onOk(false))
              .catch(() => onFail())
          }}
        >
          Save
        </Button>
      </Form>
    </Modal>
  )
}