import './confirmAppointment.scss'
import AppointmentHelper from '../../../../helpers/appointment.helper'
import InviteStatus from '../../../../enums/inviteStatus.enum'
import { useEffect, useState } from 'react'

const ConfirmAppointment = ({ flow }) => {
  const [inviteCount, setInviteCount] = useState()
  const [patientCount, setPatientCount] = useState()

  useEffect(() => {
    fetchInviteCount()
    fetchPatientCount()
  }, [flow])

  const fetchInviteCount = () => {
    if (!flow?.appointment) return
    setInviteCount(flow.appointment.invites.filter(({ status, phone }) => status === InviteStatus.PENDING && !flow.appointment.patients.some(patient => patient.phone === phone)).length)
  }

  const fetchPatientCount = () => {
    if (!flow?.appointment) return
    setPatientCount(flow.appointment.patients.length)
  }

  return flow?.appointment && (
    <div className="confirm-appointment">
      <div className="detail-list">
        <div className="detail-row">
          <div className="detail-label">
            Location
          </div>
          <div className="detail-value">
            {AppointmentHelper.getLocation(flow.appointment)}
          </div>
        </div>
        
        <div className="detail-row">
          <div className="detail-label">
            Time
          </div>
          <div className="detail-value">
            {AppointmentHelper.getStartTime(flow.appointment).format("MMMM D")} at {
              AppointmentHelper.isGetLabs(flow.appointment)
                ? `${AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")} - ${AppointmentHelper.getStartTime(flow.appointment).add(2, 'hours').format("h:mm a")}`
                : AppointmentHelper.getStartTime(flow.appointment).format("h:mm a")
            }
          </div>
        </div>

        {flow?.appointment?.phlebotomist && (
          <div className="detail-row">
            <div className="detail-label">
              Phlebotomist
            </div>
            <div className="detail-value">
              {AppointmentHelper.getPhlebotomistName(flow.appointment)}
            </div>
          </div>
        )}

        {patientCount > 1 && <div className="detail-row">
          <div className="detail-label">
            Confirmed Attendees
          </div>
          <div className="detail-value">
            {flow.appointment.patients.map(patient => `${patient.firstName} ${patient.lastName}`).join(', ')}
          </div>
        </div>}

        

        {inviteCount > 0 &&
        <div className="detail-row">
          <div className="detail-label">
            Pending Invites
          </div>
          <div className="detail-value">
            {inviteCount}
          </div>
        </div>}

        {flow.appointment.questShortQRToken && 
        <div className="detail-row">
          <div className="detail-label">
            Quest Confirmation Code
          </div>
          <div className="detail-value">
            {flow.appointment.questShortQRToken}
          </div>
        </div>}



      </div>
    </div>
  )
}

export default ConfirmAppointment