import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import { Typography, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import PosthogHelper from "../../helpers/posthog.helper";
import './blogList.scss';
import useWidth from "../../hooks/useWidth.hook";

const { Paragraph } = Typography

export const BlogList = ({ blogs, isSingleRow=false }) => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const width = useWidth()

  // Add function to determine number of columns based on window width
  const getNumberOfColumns = () => {
    if (width > 1200) return 4
    if (width > 900) return 3
    if (width > 600) return 2
    return 1
  }

  // Add state to track number of columns
  const [numColumns, setNumColumns] = useState(getNumberOfColumns())

  // Add effect to update columns on window resize
  useEffect(() => {
    setNumColumns(getNumberOfColumns())
  }, [width])

  const viewBlog = (blog) => {
    PosthogHelper.track(currentUser, `[research] click on post: ${blog.id}`, true)
    navigate(`/research/${blog.id}`)
  }

  const formatDate = (date) => {
    return moment(date).format('YYYY.MM.DD')
  }

  return (
    <motion.div
      layout
      className="blog-grid"
    >
      <AnimatePresence>
        {(isSingleRow ? blogs.slice(0, numColumns) : blogs).map(blog => (
          <motion.div
            layout
            key={blog.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="blog-grid-item"
            onClick={() => viewBlog(blog)}
          >
            <Col 
              className="blog-item"
            >
              <div className="image-wrapper">
                <img 
                  src={blog.img} 
                  className="blog-item-image"
                />
              </div>
              <Paragraph className="blog-item-title" ellipsis={{ rows: 2 }}>
                {blog.title}
              </Paragraph>
              <Paragraph className="blog-item-description" ellipsis={{ rows: 3 }}>
                {blog.description}
              </Paragraph>
              <Paragraph className="blog-item-category">
                {blog.categories?.join(' \u00A0|\u00A0 ')}
              </Paragraph>
              <Paragraph className="blog-item-published">
                {formatDate(blog.publishedAt)}
              </Paragraph>
            </Col>
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};