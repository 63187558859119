import { useContext } from "react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import BlogId from "../../../enums/blogId.enum";
import { BlogData } from "../../../data/blog.data";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

const { Paragraph } = Typography;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ApoBPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "apob-post")}>
      <div className="blog-image-wrapper tall">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          Why Traditional Cholesterol Tests Aren't Enough
        </Paragraph>
        <Paragraph className="blog-description">
          For decades, LDL cholesterol (LDL-C) has been the gold standard for assessing heart health. But it has a major limitation: many people with normal LDL-C levels still have an elevated number of atherogenic particles carrying cholesterol in their bloodstream, entering artery walls and driving plaque buildup.
        </Paragraph>
        <Paragraph className="blog-description">
          A pivotal <a onClick={() => window.open('https://www.ahajournals.org/doi/10.1161/CIRCOUTCOMES.110.959247', '_blank')} className="blog-link">meta-analysis</a> from 2011 demonstrated the gaps in LDL-C's predictive power, finding it to be the least accurate measure of cardiovascular risk:
        </Paragraph>

        <div style={{ maxWidth: '600px', margin: '20px auto' }}>
          <Bar
            data={{
              labels: ['ApoB', 'Non-HDL Cholesterol', 'LDL Cholesterol'],
              datasets: [{
                data: [43, 34, 25],
                backgroundColor: ['#FF6B6B', '#4ECDC4', '#45B7D1'],
                borderColor: ['#FF6B6B', '#4ECDC4', '#45B7D1'],
                borderWidth: 1
              }]
            }}
            options={{
              indexAxis: 'y',
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      return ` +${context.raw}%`;
                    }
                  }
                }
              },
              scales: {
                x: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Increased Cardiovascular Risk Per Standard Deviation'
                  }
                },
                y: {
                  ticks: {
                    font: {
                      weight: 'bold'
                    }
                  }
                }
              }
            }}
          />
        </div>
  
        <Paragraph className="blog-title">
          Making the Switch to ApoB
        </Paragraph>
        <Paragraph className="blog-description">
          ApoB testing gives you a more complete picture of your cardiovascular risk, helping you uncover issues traditional cholesterol tests might miss. If your ApoB levels are elevated, even a small reduction can significantly improve your heart health—<a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/22906895/', '_blank')} className="blog-link">lowering ApoB by just 10 mg/dL reduces heart disease risk by 9% and overall cardiovascular events by 6%</a>.
        </Paragraph>
        <Paragraph className="blog-description">
          At Instalab, we make it easy with an <a onClick={() => {
            navigate('/heart-health-test')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: request test`, true)
          }} className="blog-link">at-home test kit</a> that measures both ApoB and the standard lipid panel, including LDL cholesterol. This dual approach ensures you have the full picture of your cardiovascular risk.
        </Paragraph>

        <Paragraph className="blog-title">
          What Can You Do if Your ApoB Levels Are High?
        </Paragraph>
        <Paragraph className="blog-description">
          Finding out you have high ApoB levels can be a wake-up call, but the good news is there are many  effective ways to lower them:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Diet:</strong> Focus on whole, unprocessed foods like fruits, vegetables, whole grains, and lean proteins while cutting back on saturated fats, trans fats, and processed sugars. Fiber-rich foods, in particular, can help bring ApoB levels down.
          </li>
          <li>
            <strong>Exercise:</strong> Aim for at least 150 minutes of moderate physical activity per week.
          </li>
          <li>
            <strong>Weight Management:</strong> Reduce visceral fat, the fat stored around your organs.
          </li>
          <li>
            <strong>Supplements:</strong> Natural options like <a onClick={() => navigate(`/research/${BlogId.SUPPLEMENT}`)} className="blog-link">berberine and red yeast rice</a> have been shown to reduce ApoB levels, sometimes achieving results similar to prescription medications.
          </li>
          <li>
            <strong>Medications:</strong> If lifestyle changes aren't enough, medications like statins, ezetimibe, or <a onClick={() => navigate(`/research/${BlogId.PCSK9I}`)} className="blog-link">PCSK9 inhibitors</a> can effectively lower ApoB by reducing cholesterol production or improving its clearance from the bloodstream.
          </li>
          <li>
            <strong>Heart Health Program:</strong> For those looking for extra guidance, our <a onClick={() => {
              navigate('/heart')
              PosthogHelper.track(currentUser, `[research] click ${blogId} cta: heart health program`, true)
            }} className="blog-link">Heart Health Program</a> offers a personalized approach combining all these strategies. With regular testing every six weeks and tailored coaching with an Instalab physician, most patients achieve optimal ApoB levels within six months or less.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Finding What Works for You
        </Paragraph>
        <Paragraph className="blog-description">
          Lowering your ApoB levels is a personal journey—what works best will depend on your unique body and lifestyle. The key is to stick with changes long enough to see results.
        </Paragraph>
        <Paragraph className="blog-description">
          Over time, you'll be able to fine-tune your approach and discover the combination of strategies that works best for you. Keep testing, keep learning, and trust that every step you take brings you closer to better heart health.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Sniderman, A. D., Williams, K., Contois, J. H., Monroe, H. M., McQueen, M. J., de Graaf, J., & Furberg, C. D. (2011). A meta-analysis of low-density lipoprotein cholesterol, non–high-density lipoprotein cholesterol, and apolipoprotein B as markers of cardiovascular risk. Circulation: Cardiovascular Quality and Outcomes, 4(3), 337–345. <a onClick={() => window.open('https://doi.org/10.1161/CIRCOUTCOMES.110.959247', '_blank')}>https://doi.org/10.1161/CIRCOUTCOMES.110.959247</a>
            </li>
            <li>
              Robinson, J. G., Wang, S., & Jacobson, T. A. (2012). Meta-analysis of the comparison of effectiveness of lowering apolipoprotein B versus low-density lipoprotein cholesterol and nonhigh-density lipoprotein cholesterol for cardiovascular risk reduction in randomized trials. American Journal of Cardiology, 110(10), 1468-1476. <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/22906895/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/22906895/</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};