import { useState, useEffect } from 'react'
import { Select } from 'antd'
import { listProviders } from '../../services/provider.service.js'

export const ProviderSelect = ({ value, onChange, placeholder, style }) => {
  const [providers, setProviders] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const sortUsers = (users) => {
    return users.sort((a, b) => {
      const nameA = a.firstName.toLowerCase();
      const nameB = b.firstName.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    
      // If first names are equal, compare last names
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();
      if (lastNameA < lastNameB) return -1;
      if (lastNameA > lastNameB) return 1;
    
      return 0; // Names are equal
    })
  }


  useEffect(() => {
    const fetchProviders = async () => {
      setIsLoading(true)
      try {
        const providers = await listProviders({
          select: '_id firstName lastName location',
          filter: {
            firstName: {
              $ne: null
            },
            lastName: {
              $ne: null
            },
            email: {
              $ne: null
            },

          },
          sort: '-createdAt'
        })
        setProviders(sortUsers(providers))
      } catch (error) {
        console.error('Error fetching providers:', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchProviders()
  }, [])


  return (
    <Select 
      placeholder={placeholder || "Select provider..."}
      value={value}
      options={providers?.map(provider => ({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider._id
      }))}
      onChange={onChange}
      optionFilterProp="children"
      filterOption={(input, option) => 
        option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
      }
      className="single-select"
      showSearch
      loading={isLoading}
      style={style}
    />
  )
}       