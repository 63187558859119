
import { useRef, useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";
import { CheckOutlined } from "@ant-design/icons"
import FlowType from "../../enums/flowType.enum";
export const Truage = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    const response = await getProductTypeByCode(ProductTypeCode.TRUAGE_COMPLETE)
    setProductType(response)
  };

  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const faqs = [
      {
        question: "How do I get this test done?",
        answer: <>You’ll receive a blood collection kit in the mail. You can choose between a finger prick or a Tasso+ device for collection. After collecting your sample, simply drop it in the mail, and your results will be ready in about a week.</>
      },
      {
          question: "What will this test tell me about my health?",
          answer: <>This test will answer the following questions for you:<ul>
          <li>My birthday makes me _ years old, but how old is my body, biologically? (My OMICm Age)</li>
          <li>What is my age-related risk of developing various diseases?</li>
          <li>What is my age-related risk of death?</li>
          <li>Strictly looking at performance biomarkers, how old am I from a physical fitness standpoint?</li>
          <li>I have _ concentrations of various immune cells. How are those levels impacting my biological age?</li>
          <li>For each calendar year that passes, how many years is my body actually aging?</li>
          <li>How long are my telomeres?</li>
          <li>How does my relationship with alcohol consumption impact my DNA expression and aging processes?</li>
          <li>According to my DNA expression, am I likely to lose weight from restricting my caloric intake?</li>
          <li>How has my lifetime exposure to tobacco smoke impacted my DNA expression and aging processes?</li>
          <li>What can I do to improve my age-related, biological metrics?</li>
      </ul></>         
      },
      {
        question: "How is the blood collection virtually painless?",
        answer: "The Tasso+ device offers a more comfortable and convenient alternative to traditional finger pricks for blood collection. Unlike finger pricks, which can cause sharp pain by puncturing deeper into the skin, the Tasso+ uses microneedles and a gentle suction mechanism to draw blood from the skin’s surface with minimal penetration. This reduces the discomfort and potential bruising associated with finger pricks."
      },
      {
        question: "What is epigenetics?",
        answer: "Epigenetics refers to changes in gene activity that do not alter the DNA sequence but can affect gene expression. These changes are influenced by various factors such as lifestyle, environment, and age. Understanding epigenetics allows us to see how external factors can impact our genetic code and overall health."
      },
      {
        question: "What is a biological age test?",
        answer: "A biological age test measures the age of your cells and tissues, which can differ from your chronological age. It provides insights into your overall health and can help predict the risk of age-related diseases. By tracking your biological age, you can see how lifestyle changes affect your health over time."
      },

      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 2 weeks to get results after your sample arrives at TruDiagnostic's clinical lab. We’ll send you an email when your results and recommendations are ready to go!</>
      }, 

      {
        question: "How accurate are the test results?",
        answer: "These tests are developed in collaboration with leading academic institutions and are based on cutting-edge research. We measure over 1,000,000 CpG locations on your DNA to provide highly accurate and reliable results."
      },
      {
        question: "How is this different from other biological age tests?",
        answer: "TruAge tests are developed using the latest generation of aging algorithms and measure a comprehensive range of biomarkers. Our tests are backed by extensive research and collaborations with top academic institutions, providing highly accurate and actionable insights."
      },
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Do I need to fast before I collect my blood?`,
          answer: `No fasting is needed for this test.`,
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a clinical lipidologist. They'll be ready to address any of your questions.`,
      }, 
      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to specialists.`
      },
      {
        question: "How often should I get this test done?",
        answer: `By tracking your biological age, you can see how lifestyle changes affect your health over time. We recommend you retest every 3 months to most effectively monitor your progress`,
      },   
      {
        question: `Does my health insurance cover this?`,
        answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 
      

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: 'TruAge COMPLETE',
          memberPrice: productType.memberCost,
          originalPrice: productType.cost,
          oneliner: "Find out how old you really are, your rate of aging, and the age of 11 different organ systems with this comprehensive epigenetic test.",
          panelRef: panelRef,
          cta: {
            text: "Get Started Now",
            url: `/flow/${productType.flowType}`
          },
          hasKlarna: true,
          giftCta: {
            code: ProductTypeCode.GIFT_TRUAGE_COMPLETE,
            text: "Gift to someone",
            url: `/flow/${FlowType.GIFT}/details?code=${ProductTypeCode.GIFT_TRUAGE_COMPLETE}`
          },
          photos: [
              {
                original: "/img/truage-box.png", 
                thumbnail: "/img/truage-box.png"
              },
              {
                original: "/img/tasso-man.png",
                thumbnail:  "/img/tasso-man.png"
              },
              {
                original: "/img/fertility-tasso-grid.png",
                thumbnail:  "/img/fertility-tasso-grid.png"
              },
              {
                original: "/img/truage-omic.png", 
                thumbnail: "/img/truage-omic.png"
              },
              {
                original: "/img/truage-pace.png", 
                thumbnail: "/img/truage-pace.png"
              },              {
                original: "/img/truage-immune.png", 
                thumbnail: "/img/truage-immune.png"
              },
              {
                original: "/img/truage-telemore.png", 
                thumbnail: "/img/truage-telemore.png"
              },
              {
                original: "/img/truage-smoke.png", 
                thumbnail: "/img/truage-smoke.png"
              },
              {
                original: "/img/truage-weight.png", 
                thumbnail: "/img/truage-weight.png"
              },
              {
                original: "/img/truage-alcohol.png", 
                thumbnail: "/img/truage-alcohol.png"
              },
          ],
          points: [
              <><CheckOutlined className="point-icon"/>Easy and painless sample collection with Tasso+</>,
              <><CheckOutlined className="point-icon"/>Comprehensive analysis of your aging metrics.</>,
              <><CheckOutlined className="point-icon" />HSA / FSA cards accepted</>,
          ],
          faqs: faqs
        },
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
                title: "Order test",
                description: "After you purchase your test, you'll receive your sample collection kit in the mail. This includes the Tasso+ collection unit.",
                image: "/img/tasso-box.png"
            },
            {
                title: "Collect & mail sample",
                description: "Complete your sample collection with the Tasso+ and then just pop it the mail. It should take 5 minutes.",
                image: "/img/tasso-grid.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 2 weeks. A report and game plan will be available as well.",
                image: "/img/receive-results-man.png"
            },

        ],
      },

      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(4),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_TRUAGE_COMPLETE}
      />

    </div>
  )

}
