import { BlogData } from "../../../../data/blog.data"
import { BlogList } from "../../../blogList/blogList.component"
import { useParams } from "react-router-dom"
import './recommendedBlogs.scss'

export const RecommendedBlogs = () => {
  const { blogId } = useParams()

  // Simple word overlap similarity function
  function calculateSimilarity(text1, text2) {
    const words1 = new Set(text1.split(/\W+/))
    const words2 = new Set(text2.split(/\W+/))
    const intersection = new Set([...words1].filter(word => words2.has(word)))
    return intersection.size
  }

  const listRecommended = (blogs) => {
    const currentBlog = BlogData.find(({ id }) => id === blogId)
    const currentContent = (currentBlog?.title + ' ' + currentBlog?.description).toLowerCase()

    return blogs
      .filter(({ id }) => id !== blogId)
      .sort((a, b) => {
        const contentA = (a.title + ' ' + a.description).toLowerCase()
        const contentB = (b.title + ' ' + b.description).toLowerCase()
        
        // Calculate similarity scores
        const scoreA = calculateSimilarity(currentContent, contentA)
        const scoreB = calculateSimilarity(currentContent, contentB)
        
        return scoreB - scoreA // Sort descending
      })
      .slice(0, 4)
  }

  return <BlogList blogs={listRecommended(BlogData)} isSingleRow={true} />
}