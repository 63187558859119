import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useParams } from 'react-router-dom';
import './qrCode.scss';  

export const QRCode = () => {
  const { id } = useParams();
  
  return (
    <div className="qr-code-page">
      <div className="qr-code-content">
        <h1>Quest Check-In</h1>
        {id ? (
          <>
            <div className="qr-code-wrapper">
              <QRCodeSVG 
                value={id}
                size={280}
                level="H"
                className="qr-code"
              />
            </div>
            <div className="instructions">
              <p>Please scan this QR code on arrival to check in.</p>
              <p className="quest-id">Confirmation Code: {id}</p>
            </div>
          </>
        ) : (
          <div className="error-message">
            <p>No quest found</p>
            <p>Please check your quest link and try again</p>
          </div>
        )}
      </div>
    </div>
  );
};

