import { posthog } from "posthog-js";
import { addSlack } from "../services/slack.service";

const PosthogHelper = {
  track: async (user, eventCode, shouldSlack=true) => {

    console.log("posthog track: ", eventCode, user)
    if (!eventCode || window.location.hostname === "localhost") { 
      console.log("posthog track: ", eventCode, user)
    }
    else {
      posthog.capture(
        eventCode, 
        { 
          $set: { 
            email: user?.email,
            _id: user?._id,
            tracker: user?.tracker,
          },
        }
      )
    }

    if (shouldSlack) {
      // Get identity
      let identity = "New User"
      if (user?.firstName && user?.lastName) {
        identity = `${user.firstName} ${user.lastName}`
      } else if (user?.email) {
        identity = user.email
      }

      // Get patient profile URL if available
      if (user?._id) {
        identity = `<${process.env.REACT_APP_CLIENT_URL}/patients/${user._id}|${identity}>`
      }

      await addSlack({
        message: `${identity}: ${eventCode}`,
        channel: process.env.REACT_APP_FLOW_SLACK
      })
    }
  }
}

export default PosthogHelper
