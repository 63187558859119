import FlowType from "../enums/flowType.enum"
import { FaqCategory } from "../enums/faqCategory.enum"
import { Card, Row, Col } from "antd"

const FaqData = [

    // ABOUT US v2
    {
      question: "Who is behind Instalab?",
      answer: `Instalab was founded in 2022 by a team of physicians, technologists, and health experts driven by a shared passion: transforming the healthcare system to work for you. We began by offering streamlined access to advanced testing, empowering people to take a proactive approach to their health. In 2025, we expanded our mission by introducing a concierge service that partners with you to unlock the full potential of the healthcare system—tailored to your needs and within your budget.`,
      categories: [FaqCategory.ABOUT],
    },

    // OLD HOME FAQ

    {
      question: "Why choose Instalab for my health needs?",
      answer: <>Instalab simplifies proactive health care by combining expert-guided testing, tailored recommendations, and physician consultations.</>,
      categories: [FaqCategory.GENERAL],
    },  
    {
      question: "What makes Instalab different from traditional health services?",
      answer: `Unlike traditional healthcare, Instalab focuses on prevention and long-term health optimization. We offer advanced testing, expert-guided plans, and concierge-level support to keep you ahead of potential health risks.`,
      categories: [FaqCategory.GENERAL, FaqCategory.ABOUT],
    },
    {
      question: "What tests should I be doing to stay ahead of health risks?",
      answer: <>Your health is unique, so your tests should be too. Instalab uses your medical history, genetics, and lifestyle to recommend the most relevant tests. <a href={`/start`} className="cta-link">Take our quiz</a> to discover the right tests for you.</>,
      categories: [FaqCategory.GENERAL, FaqCategory.ABOUT],
    },   
    {
      question: "How does Instalab help me improve my health?",
      answer: `Instalab goes beyond routine testing by providing personalized insights, consultations with longevity physicians, and tailored game plans to address your specific health goals. It's about empowering you to take control of your health.`,
      categories: [FaqCategory.GENERAL],

    },
    {
      question: "What happens after I get my test results?",
      answer: `Your results are reviewed by our physicians, and you'll receive a detailed breakdown along with actionable steps to improve your health. You can also schedule a consultation to discuss the findings in-depth.`,
      categories: [FaqCategory.GENERAL],

    },
    {
      question: "How do I use my HSA/FSA with Instalab?",
      answer: `Instalab is fully eligible for HSA/FSA reimbursement. Use your HSA/FSA card at checkout, or if you prefer, we can provide an itemized receipt to submit for reimbursement.`,
      categories: [FaqCategory.GENERAL, FaqCategory.HEART],

    },
    {
      question: "Does my health insurance cover Instalab?",
      answer: `Insurance does not cover Instalab at this time. However, you can use your HSA/FSA for reimbursement, making it a flexible option to invest in your health.`,
      categories: [FaqCategory.GENERAL, FaqCategory.ABOUT, FaqCategory.HEART],
    },
    {
      question: `Can Instalab replace my primary care provider?`,
      answer: `Instalab complements, rather than replaces, your primary care provider. Our focus is on prevention and long-term health optimization. We encourage maintaining your existing provider relationship for routine and emergency care while we handle proactive health planning.`,
      categories: [FaqCategory.GENERAL, FaqCategory.ABOUT],
    }, 
    {
      question: "Can I request tests not currently in your catalog?",
      answer: <>Yes, we aim to meet your specific needs. Email us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a> with your request, and we'll work to make it happen.</>,
      categories: [FaqCategory.GENERAL],
    },
    {
      question: "Is Instalab available in my area?",
      answer: <>Instalab is available anywhere in the United States.</>,
      categories: [FaqCategory.GENERAL, FaqCategory.HEART],
    },
    {
      question: "Can my company cover the cost of Instalab?",
      answer: `Absolutely! Many companies view health services like Instalab as an investment in their team. If you'd like, we can help communicate the value of our services to your employer.`,
      categories: [FaqCategory.GENERAL, FaqCategory.HEART],
    },
    {
      question: "Is my health data secure?",
      answer: `Your privacy is our priority. We use state-of-the-art encryption and comply with strict data protection standards to keep your information safe. We will never sell your data to third parties.`,
      categories: [FaqCategory.GENERAL, FaqCategory.HEART],
    },
    {
      question: "Can I gift an Instalab to someone I care about?",
      answer: <>Absolutely! Give the gift of proactive health. When you purchase an Instalab gift, we'll help you create a personalized digital e-card delivered on your chosen date. <a className="cta-link" href={`/flow/${FlowType.GIFT}`}>Start your gift order</a> and make a meaningful impact on someone's wellbeing.</>,
      categories: [FaqCategory.GENERAL],
    },


    // CONCIERGE v2
    {
      question: "What does the concierge service include?",
      answer: (
        <>
          <p className="mb-4">
            Our personal health concierge service provides personalized, comprehensive support to simplify 
            and improve your healthcare experience across several key areas:
          </p>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={8}>
              <Card title="Testing & Results">
                <ul>
                  <li>Scheduling comprehensive lab work, imaging, and advanced diagnostics</li>
                  <li>Arranging convenient mobile or at-home testing options where possible</li>
                  <li>Delivering, reviewing, and clearly explaining results to help you take informed action</li>
                </ul>
              </Card>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Card title="Specialist Referrals">
                <ul>
                  <li>Identifying and securing appointments with highly-rated specialists</li>
                  <li>Preparing concise medical summaries to optimize specialist visits</li>
                  <li>Managing follow-up tests and coordinating seamless, ongoing care</li>
                </ul>
              </Card>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Card title="Proactive Health Planning">
                <ul>
                  <li>Designing personalized testing plans focused on prevention and health optimization</li>
                  <li>Monitoring biomarker trends and suggesting actionable lifestyle adjustments</li>
                  <li>Developing long-term strategies for chronic disease prevention and longevity goals</li>
                </ul>
              </Card>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Card title="Treatment Support">
                <ul>
                  <li>Assisting with insurance approvals for costly medications and treatments</li>
                  <li>Streamlining prescription refills, delivery, and new medication guidance</li>
                  <li>Exploring advanced treatment options tailored to your specific health needs</li>
                </ul>
              </Card>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Card title="Patient Advocacy">
                <ul>
                  <li>Resolving billing issues with providers or insurers</li>
                  <li>Acting as your advocate and liaison across multiple healthcare providers</li>
                  <li>Ensuring you receive appropriate care and resources for managing complex conditions</li>
                </ul>
              </Card>
            </Col>
          </Row>
        </>
      ),
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "How much does the concierge service cost?",
      answer: "The concierge service is a subscription service that costs $49/month or $499/year. If for any reason, it is not a good fit, you can easily cancel anytime before your next billing date.", 
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "Who is the 'concierge' answering all the questions?",
      answer: "Real humans! Scheduling and administrative questions are handled by a dedicated assistant who is an expert in navigating the healthcare system. Any medical related questions are referred to an Instalab physician familiar with you already and who will get back to you asap.", 
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "What types of tests can you help me get?",
      answer: <>We can help you get any tests done. The most common tests requested are already listed in our public <a href={`/catalog`} className="cta-link">test catalog</a>. For anything not listed, please contact your concierge and we get it done for you.</>,
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "Are lab test costs included in the membership?",
      answer: "Tests are billed separately, but we ensure you get the best pricing and insurance coverage where applicable.",
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "What happens after my results come in?",
      answer: "An Instalab physician will review your results and a summary report will be sent to you. Results are always available to view in your Instalab account.", 
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "Can I directly talk with an Instalab physician?",
      answer: "Yes, you're initially paired with a physician who specializes in longevity and preventative care. You can schedule a consultation with them anytime ($99/session).",
      categories: [FaqCategory.CONCIERGE],
    },
    
    {
      question: "Can I get medications through Instalab?",
      answer: `Instalab physicians do prescribe medications for commom chronic conditions. If you choose to get medications through Instalab, we require follow-up testing to ensure the medication is working as intended. However, for complex cases or for controlled substances, we will refer you to a specialist to get that done.`,
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "Can you help with getting second opinions?",
      answer: "Yes, we have a network of specialists who can provide second opinions on your tests and recommendations.", 
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "Do you help with urgent care or medical emergencies?",
      answer: "No, we focus primarily on preventative care and long-term health. If you are in a medical emergency, call 911, or if possible go to the nearest emergency room.", 
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "How do I use my HSA/FSA with Instalab?",
      answer: `Instalab is fully eligible for HSA/FSA reimbursement. Use your HSA/FSA card to pay for your membership or any tests or services you get through Instalab. If you prefer, we can provide an itemized receipt to submit for reimbursement.`,
      categories: [FaqCategory.CONCIERGE, FaqCategory.ABOUT],
    },

    {
      question: "Do you work with my insurance plan?",
      answer: `While insurance doesn't cover Instalab concierge services, we will figure out how to get the most of your insurance for any tests, medications, and doctor visits you get through Instalab.`,
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "I already have an assistant, why do I need another one for my health?",
      answer: `Mastering the navigation of your health and the healthcare system is a full-time job and we've made that ours. We frequently work with executive assistants on their client's health and wellness and take control of that part of the calendar in many cases.`,
      categories: [FaqCategory.CONCIERGE],
    },
    {
      question: "Is Instalab available nationwide or only in certain areas?",
      answer: `Instalab is available nationwide.`,
      categories: [FaqCategory.CONCIERGE, FaqCategory.ABOUT],
    },
    {
      question: "How do you protect my medical privacy?",
      answer: `Your medical privacy is our priority. We use state-of-the-art encryption and comply with strict data protection standards to keep your information safe. We will never sell your data to third parties.`,
      categories: [FaqCategory.CONCIERGE],
    }
]

export default FaqData;
