const MembershipTypeCode = Object.freeze({
 
  // ACTIVE
  LONGEVITY: 'Longevity', // aka core - $49/month
  PREMIUM: 'Premium', // $99/month
  ULTIMATE: 'ultimate', // $999/month
  HEART_HEALTH: 'heart-health', // $50/month
  HEART_HEALTH_V2: 'heart-health-v2', // $599 one-time
  LONGEVITY_12MO: 'longevity-12mo',
  PREMIUM_12MO: 'premium-12mo',
  ULTIMATE_12MO: 'ultimate-12mo',
  HEART_HEALTH_12MO: 'heart-health-12mo', 
  
  
  // DEMO
  DEMO: 'demo',
  DEMO_12MO: 'demo-12mo', 
  DEMO_PREMIUM: 'demo-premium',
  DEMO_PREMIUM_12MO: 'demo-premium-12mo',

  // TRANSITIONING OUT
  LIPIDS: 'Lipids', 
  LIPIDS_UPGRADE: 'lipids-upgrade', 

  // DEACTIVATED
  EZETIMIBE: 'ezetimibe',
  ROSUVASTATIN: 'rosuvastatin', 
  SUPPLEMENTS: 'supplements'
})

export default MembershipTypeCode;
