const ConditionCategoryCode = Object.freeze({
    OTHER: 0,
    AUTOIMMUNE: 1,
    MUSCULOSKELATAL: 2,
    CANCER: 3,
    CARDIOVASCULAR: 4,
    DIGESTIVE: 5,
    INFECTIOUS: 6,
    MENTAL_HEALTH: 7,
    METABOLIC: 8,
    NEUROLOGICAL: 9,
    REPRODUCTIVE: 10,
    RESPIRATORY: 11,
    SKIN: 12,
    KIDNEY: 13
  })
  
  export default ConditionCategoryCode;
  