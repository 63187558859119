import { useEffect, useState, useMemo } from 'react'
import { Form, Typography, Row, Col, Button, Input, Affix, Spin, Modal, Empty } from "antd"
import { Selector } from 'antd-mobile'
import { CheckOutlined, WarningFilled, SearchOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import "./testPicker.scss"
import { Tag } from 'antd'
import TestPanelModal from '../../../testPanelModal/testPanelModal.component'

const { Item } = Form
const { Text, Paragraph } = Typography

// Move outside component to avoid recreation
const normalizeString = (str) => str?.toLowerCase().trim() || '';

export const TestPicker = ({
  step,
  onSubmit,
  form,
  isSubmitting,
  onNextStep
}) => {
  const fieldWatch = Form.useWatch([step?.field.name], form)
  const [rules, setRules] = useState([])
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  const [tests, setTests] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [groupedTests, setGroupedTests] = useState({})
  const [selectedLetter, setSelectedLetter] = useState('A')
  const [isLoading, setIsLoading] = useState(false)
  const [showSelectedTests, setShowSelectedTests] = useState(false)


  const calculateTotalPrice = (selectedTestIds) => {
    if (!selectedTestIds) return 0;
    const selectedPrice = selectedTestIds.reduce((total, testId) => {
      const test = tests.find(t => t._id === testId);
      return total + (test?.cost || 0);
    }, 0);
    return selectedPrice + (step.forceShow?.reduce((total, test) => total + (test.cost || 0), 0) || 0);
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {

    if (step?.field?.defaultValue && !form.getFieldValue(step?.field?.name)) {
      form.setFieldValue(step?.field.name, step.field.defaultValue);
    }
    fetchRules();
  }, [step]);

  const fetchRules = () => {
    if (!step) return
    if (step.field.required) {
      setRules([{
        required: true,
        message: <><WarningFilled />&nbsp; Select at least one option</>,
      }])
    }
  }


  const processTests = useMemo(() => {
    const tests = step?.tests || []
    const panels = step?.panels || []

    
    const panelTests = panels.map(panel => ({
      ...panel,
      _id: panel._id || panel.id,
      name: panel.name,
      cost: panel.cost,
      tagline: panel.tagline,
      isPanel: true,
      tests: panel.tests.map(test => tests.find(t => t._id === test))
    }))

    console.log(panelTests)

    
    const allTests = [...tests, ...panelTests]
    
    const grouped = allTests.filter(test => test.cost).reduce((acc, test) => {
      const firstLetter = test?.name.charAt(0).toUpperCase()
      if (!acc[firstLetter]) {
        acc[firstLetter] = []
      }
      acc[firstLetter].push(test)
      return acc
    }, {})
    
    Object.keys(grouped).forEach(letter => {
      grouped[letter].sort((a, b) => a.name.localeCompare(b.name))
    })
    
    return { allTests, grouped }
  }, [step?.tests, step?.panels])

  useEffect(() => {
    setIsLoading(true)
    setTests(processTests.allTests.filter(test => test.cost))
    setGroupedTests(processTests.grouped)
    setIsLoading(false)
  }, [processTests])



  const filteredTests = searchTerm ? processTests.allTests.filter(test => {

    if (!test.cost) return false;

    const search = normalizeString(searchTerm);
    
    // Short-circuit evaluation - return early if we find a match
    return (
      normalizeString(test.name).includes(search) ||
      normalizeString(test.tagline).includes(search) ||
      (test.tests?.some(t => normalizeString(t.name).includes(search) || normalizeString(t.tagline).includes(search))) ||
      (test.altNames?.some(name => normalizeString(name).includes(search)))
    );
  }) : [];


  const scrollToLetter = (letter) => {
    setSelectedLetter(letter)
    const element = document.getElementById(`letter-section-${letter}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const selectedTests = useMemo(() => {
    const selectedIds = form.getFieldValue(step?.field.name) || [];
    const selectedFromList = tests.filter(test => selectedIds.includes(test._id));
    return step.forceShow ? [...step.forceShow.map(test => ({ ...test, isForceShow: true })), ...selectedFromList] : selectedFromList;
  }, [form.getFieldValue(step?.field.name), tests, step.forceShow]);

  const removeTest = (testId) => {
    const currentValue = form.getFieldValue(step?.field.name) || [];
    form.setFieldValue(
      step?.field.name,
      currentValue.filter(id => id !== testId)
    );
  };


  return (
    (groupedTests && Object.keys(groupedTests).length > 0) ? (
      <div className="test-picker">
        {isLoading ? (
          <Spin />
        ) : (
          <Form
            form={form}
            className="test-picker-form"
        >
          <div className="search-container">
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by test name ..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="test-search-input"
            />

          </div>

          <Item 
            key={`field-${step.field.name}`}
            name={step.field.name}
            className="test-picker-item"
            validateTrigger='onSubmit'
            rules={rules}
          >
            <div className="test-picker-content">
              <Affix className="letter-index">
                <div className="letter-list">
                  {letters.map(letter => (
                    <div
                      key={letter}
                      className={`letter-item ${selectedLetter === letter ? 'active' : ''} ${groupedTests[letter]?.length ? 'has-items' : 'no-items'}`}
                      onClick={() => groupedTests[letter]?.length && scrollToLetter(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
              </Affix>

              <div className="test-list">
                {searchTerm ? (
                  <Selector
                    value={form.getFieldValue(step?.field.name)}
                    onChange={value => {
                      form.setFieldValue(step?.field.name, value);
                      if (value?.length && !step.multiple) {
                        onSubmit()
                      }
                    }}
                    disabled={step.isLocked && step.isLocked()}
                    multiple={step.multiple}
                    options={filteredTests.map((test, index) => ({
                      value: test._id,
                      label: (
                        <TestOption test={test} index={index} letters={letters} />
                      )
                    }))}
                    className="test-picker-field"
                  />
                ) : (
                  letters.map(letter => (
                    groupedTests[letter]?.length ? (
                      <div key={letter} className="letter-section" id={`letter-section-${letter}`}>
                        <div className="letter-header">{letter}</div>
                        <Selector
                          value={form.getFieldValue(step?.field.name)}
                          onChange={value => {
                            form.setFieldValue(step?.field.name, value);
                            if (value?.length && !step.multiple) {
                              onSubmit()
                            }
                          }}
                          disabled={step.isLocked && step.isLocked()}
                          multiple={step.multiple}
                          options={groupedTests[letter].map((test, index) => ({
                            value: test._id,
                            label: (
                              <TestOption test={test} index={index} letters={letters} />
                            )
                          }))}
                          className="test-picker-field"
                        />
                      </div>
                    ) : null
                  ))
                )}
              </div>
            </div>
          </Item>


          <div className="floating-summary">
              <div className="summary-content flow-step-footer">
                <div className="selected-count" onClick={() => setShowSelectedTests(true)}>
                  <div className="count-group">
                    <span className="count-value">
                      {(fieldWatch?.length || 0) + (step.forceShow?.length || 0)}
                    </span>
                    <span className="count-label">
                      {` test${(fieldWatch?.length + (step.forceShow?.length || 0)) !== 1 ? 's' : ''} selected`}
                    </span>
                    <span className="price-value">
                      ${calculateTotalPrice(form.getFieldValue(step?.field.name)).toFixed(2)}
                    </span>
                  </div>
                </div>
                
                <div className="summary-actions" style={{display: 'flex', gap: 12}}>
                  {step.isLocked && step.isLocked() ? (
                      <div className="locked-status">
                        <Button
                          type='primary'
                          className="skip-btn"
                          onClick={onNextStep}
                          >
                            Continue
                            <CheckOutlined />
                        </Button>
                      </div>
                    ): 
                    
                    <div className="flow-step-actions" style={{ display: 'flex', gap: '12px' }}>
                      {step.hasSkip && <Button
                        type='default'
                        className="skip-btn"
                        onClick={onNextStep}
                      >
                        No thanks
                      </Button>}

                      <Button
                        className="flow-step-submit"
                        type='primary'
                        onClick={(e) => {
                          e.preventDefault();
                          if (onSubmit) onSubmit();
                        }}
                        loading={isSubmitting}
                        disabled={!step.hasSkip && !form.getFieldValue(step?.field.name)?.length}
                      >
                        Next Step
                        <CheckOutlined />
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </div>


        </Form>
        )}

        <Modal
          title="Selected Tests"
          open={showSelectedTests}
          onCancel={() => setShowSelectedTests(false)}
          footer={null}
          className="selected-tests-modal"
        >
          {selectedTests.length > 0 ? (
            <div className="selected-tests-list">
              {selectedTests.map(test => (
                <div 
                  key={test._id}
                  className="selected-test-item"
                >
                  <div className="test-info">
                    <span className="test-name">{test.name}</span>
                    <span className="test-price">${test.cost.toFixed(2)}</span>
                  </div>
                  {!test.isForceShow && (
                    <Button 
                      type="text" 
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => removeTest(test._id)}
                    />
                  )}
                </div>
              ))}
              <div className="total-section">
                <span>Total:</span>
                <span className="total-price">
                  ${calculateTotalPrice(form.getFieldValue(step?.field.name)).toFixed(2)}
                </span>
              </div>
            </div>
          ) : (
            <Empty description="No tests selected" />
          )}
        </Modal>
      </div>
    ) : null
  )
}


// Separate component for test option
const TestOption = ({ test }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="test-picker-label">
      <Row>
        <Col className="col-2">
          <Paragraph className="option-title">
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              {test.name}
              {test.isPanel && <Tag color="default" style={{ marginLeft: 8 }}>{test.tests?.length || 0} biomarkers</Tag>}
            </span>

            <span className="option-metadata" onClick={stopPropagation}>
              {test.tagline && (
                <InfoCircleOutlined 
                  className="info-icon" 
                  onClick={() => setIsModalVisible(true)}
                />
              )}
            </span>
            <CheckOutlined className="test-picker-check" />
          </Paragraph>
          <div className="description-price-container">
            {(test.cost || test.cost === 0) && (
              <Text className="option-price">
                ${Number.isInteger(test.cost) ? test.cost : test.cost.toFixed(2)}
              </Text>
            )}
          </div>
        </Col>
      </Row>

      <TestPanelModal
        title={"About Test"}
        testDetails={[test]}
        visible={isModalVisible}
        onClose={(e) => {
          e?.stopPropagation();
          setIsModalVisible(false);
        }}
      />
    </div>
  )
}
