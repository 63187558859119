import { MailOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useState, useEffect, useContext } from 'react';
import './subscribeButton.scss';
import { useNavigate } from 'react-router-dom';
import FlowType from '../../enums/flowType.enum';
import PosthogHelper from '../../helpers/posthog.helper';
import { UserContext } from '../../contexts/user.context';

export const SubscribeButton = () => {
  const { currentUser } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 500);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial scroll position

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return currentUser?.isSubscribed ? null : (
    <Tooltip title="Subscribe to our weekly newsletter for practical, research-backed insights on heart health and longevity.">
      <Button
        type="primary"
        className={`subscribe-button ${isVisible ? 'visible' : ''}`}
        onClick={() => {
          PosthogHelper.track(currentUser, `click subscribe button`, true)
          navigate(`/flow/${FlowType.SUBSCRIBE}`)
        }}
        icon={<MailOutlined />}
      >
        Subscribe
      </Button>
    </Tooltip>
  )
}