import { useRef, useEffect, useState } from "react";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum";
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";
import FlowType from "../../enums/flowType.enum";

export const SleepApneaTest = () => {
    const [productType, setProductType] = useState()
    const [sections, setSections] = useState(null)
    const panelRef = useRef(null);

    useEffect(() => {
        fetchProductType(); 
    }, []);

    const fetchProductType = async () => {
        const response = await getProductTypeByCode(ProductTypeCode.WATCHPAT_ONE)
        setProductType(response)
    };

    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}


    const sleepApneaPanel = [
        {
            "name": "Apnea Hypopnea Index (AHI)",
            "description": "Average number of breathing interruptions (apneas) and shallow breaths (hypopneas) per hour of sleep. This index is used to determine the severity of sleep apnea: mild (5-15 events/hour), moderate (15-30), or severe (over 30)."
        },
        {
            "name": "Respiratory Disturbance Index (RDI)",
            "description": "Total number of breathing irregularities, including apneas, hypopneas, and respiratory effort-related arousals, per hour of sleep. It provides a more comprehensive assessment of sleep-disordered breathing beyond the AHI."
        },
        {
            "name": "Oxygen Desaturation Index (ODI)",
            "description": "Frequency of significant drops in blood oxygen levels (typically a drop of 3-4% or more) during sleep. A higher ODI indicates more frequent episodes of reduced oxygen supply, which can strain the cardiovascular system."
        },
        {
            "name": "Snoring & Body Position",
            "description": "Analyzes snoring patterns, intensity, and body position throughout the night. Identifies positional sleep apnea, where breathing interruptions may be worsened or alleviated depending on sleep position (e.g., back vs. side)."
        },
        {
            "name": "Sleep Efficiency",
            "description": "Assesses the percentage of time spent asleep compared to the total time spent in bed. A higher sleep efficiency score indicates better sleep quality, while a lower score may suggest insomnia or frequent awakenings."
        },
        {
            "name": "Sleep Stages",
            "description": "In-depth breakdown of time spent in each sleep stage (light, deep, and REM). Understanding the distribution of these stages helps evaluate sleep quality, restorative value, and the impact of sleep apnea on sleep architecture."
        },
        {
            "name": "Oxygen & Heart Rate",
            "description": "Continuously tracks blood oxygen saturation (SpO2) and heart rate throughout the night. Significant drops in oxygen levels or spikes in heart rate may indicate episodes of sleep apnea or other respiratory issues."
        },
    ]        
    
    const faqs = [

        {
            question: "How does the at-home sleep study work?",
            answer: "The WatchPAT One device is a FDA-cleared device that features a wrist sensor, pulse oximeter, and chest sensor, using three points of contact – the wrist, finger, and neck. It measures central and obstructive sleep apnea events, true sleep time, snoring, breathing rates, oxygen levels, heart rate, body position, and sleep stages. The device's sensors send data directly to Instalab via your cell phone's Bluetooth for comprehensive results and informed diagnosis."
        },
        {
            question: "What does the test measure?",
            answer: <>The Sleep Apnea Test monitors your breathing patterns, oxygen levels, and sleep position throughout the night. <a onClick={scrollToPanel} className="cta-link">Learn More</a></>
        },
        {
            question: "Is the device comfortable to wear?",
            answer: "The device is lightweight and designed for comfort. It includes small sensors that attach to your finger and chest, and most people report they barely notice it while sleeping."
        },
        
        {
            question: "How long does it take to get my results?",
            answer: "Your results will be available within 1 business day after you finish the test. We'll notify you once they're ready via email and on your Instalab dashboard."
        },
        {
            question: "Will the sleep test cause sleep problems?",
            answer: "Some people who take our at-home sleep test experience anxiety during the night, which can make it hard to fall asleep. However, you'll be more comfortable in your own bed than at a sleep center. For most people, the test can gather enough information after one night – even with sleep disruptions. If you sleep 2.5 hours or less, you should consider retaking the test."
        },

        {
            question: "Do I need to stop taking any medications before the test?",
            answer: "No, you can continue your regular medications. However, if you have concerns, consult with your physician before taking the test."
        },
        {
            question: "Can I use my FSA/HSA for this test?",
            answer: "Yes, the sleep apnea test is FSA/HSA eligible. You can use your flexible spending account or health savings account to cover the cost of this test."
        },
        {
            question: "Can I use this test if I have a pacemaker or other medical devices?",
            answer: "Yes, the device is generally safe to use with most medical implants, but please consult with your physician if you have any concerns."
        },
        {
            question: "Can this test diagnose conditions other than sleep apnea?",
            answer: "While the test primarily detects sleep apnea, it can also reveal other sleep-related issues like low oxygen levels and abnormal breathing patterns."
        },
        {
            question: "What should I do if my results indicate sleep apnea?",
            answer: "If your results show signs of sleep apnea, our medical team will guide you on next steps, which may include lifestyle changes, CPAP therapy, or further consultation with a sleep specialist."
        },
        {
            question: "Is the test accurate for detecting sleep apnea in children?",
            answer: "The test is designed for adults. If you’re concerned about a child's sleep patterns, please consult a pediatric sleep specialist."
        },

    ]


    useEffect(() => {
        if (!productType) return

        setSections([
        { 
            type: ProductDetailSection.HERO,
            data: {
            title: productType?.title,
            memberPrice: productType?.memberCost,
            originalPrice: productType?.cost,
            oneliner: "A convenient at-home sleep test that measures your sleep quality and diagnoses sleep apnea from the comfort of your own bed.",
            panelRef: panelRef,
            cta: {
                text: "Buy Now",
                url: `/flow/${FlowType.SLEEP_APNEA}`
            },
            hasKlarna: true,
            giftCta: {
                text: "Gift to Someone",
                url: `/flow/${FlowType.GIFT}/details?code=${ProductTypeCode.GIFT_SLEEP_APNEA_TEST}`
            },
            photos: [
                {
                original: "/img/sleep-apnea-bed.webp",
                thumbnail: "/img/sleep-apnea-bed.webp"
                },
                {
                    original: "/img/sleep-apnea-hand.webp",
                    thumbnail: "/img/sleep-apnea-hand.webp"
                },
                {
                    original: "/img/sleep-apnea-report.webp",
                    thumbnail: "/img/sleep-apnea-report.webp"
                },
                {
                    original: "/img/hsa-fsa-eligible-hero.webp",
                    thumbnail: "/img/hsa-fsa-eligible-hero.webp"
                }
            ],
            faqs: faqs
            },
        },
        {
            type: ProductDetailSection.SMALLPANEL,
            title: "Key Measurements",
            data: {
                panel: sleepApneaPanel,
                panelRef: panelRef
            }
        },
        { 
            type: ProductDetailSection.DIRECTIONS,
            data: [
            {
                title: "Order test",
                description: "After purchase, you'll receive your sleep test device and detailed instructions in the mail within 1 week.",
                image: "/img/longevitypanel-step-1.webp"
            },
            {
                title: "Complete overnight test",
                description: "Wear the device for one night while you sleep in your own bed. The device will automatically record your sleep data.",
                image: "/img/sleep-apnea-bed-2.webp"
            },
            {
                title: "Get test results",
                description: "An Instalab physician will review your sleep data and provide a detailed personal sleep report with recommendations.",
                image: "/img/sleep-apnea-report.webp"
            },
            ],
        },
        { type: ProductDetailSection.FAQ,
            title: 'Frequently Asked Questions',
            data: faqs.slice(3),
        },
        ])
    
    }, [productType])

    return (
        <div className="sleep-apnea-test">
        {sections && <ProductDetail sections={sections} />}
        </div>
    );
} 