import React, { useEffect, useState } from 'react';
import { Button, message, Typography } from 'antd'
import { cancelMembership, listMemberships, resumeMembership } from "../../services/membership.service"
import './adminMembershipsTable.scss';
import moment from 'moment';
import MembershipStatus from '../../enums/membershipStatus.enum';
import UrlHelper from '../../helpers/url.helper';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import { useNavigate, useParams } from 'react-router-dom';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import classNames from 'classnames';

const { Text } = Typography

export const AdminMembershipsTable = ({ hasPatient=false }) => {
  const [resuming, setResuming] = useState([])
  const [canceling, setCanceling] = useState([])
  const [memberships, setMemberships] = useState([])
  const { patientId } = useParams()
  const [select, setSelect] = useState()
  const [populate, setPopulate] = useState()
  const [isLoading, setIsLoading] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    fetchProps()
  }, [hasPatient, patientId])

  useEffect(() => {
    fetchMemberships()
  }, [select, populate])

  const fetchProps = () => {
    if (hasPatient && !patientId) return

    let membershipSelect = '_id status trialEnd endAt membershipType total stripeId invoices createdAt monthlyPrice price'
    let membershipPopulate = [{
      path: 'membershipType',
      select: '_id code isAnnual'
    }]
    if (!hasPatient) {
      membershipSelect = `${membershipSelect} patient`
      membershipPopulate = [
        ...membershipPopulate,
        {
          path: 'patient',
          select: '_id firstName lastName'
        }
      ]
    }
    setSelect(membershipSelect)
    setPopulate(membershipPopulate)
  }

  const fetchMemberships = async () => {
    if (!select || !populate) return

    setIsLoading(true)
    
    let params = {
      select,
      filter: {},
      populate,
      sort: '-createdAt'
    }

    if (hasPatient) {
      params.filter = {
        patient: patientId
      }
    }

    const fetchedMemberships = await listMemberships(params)
    setMemberships(fetchedMemberships)
    setFilteredCount(fetchedMemberships.length)
    setIsLoading(false)
  }

  const onCancelMembership = async membership => {
    setCanceling([...canceling, membership._id])
    try {
      const canceledMembership = await cancelMembership(membership._id, {
        select,
        populate
      })
      setMemberships(memberships.map(m => m._id === membership._id ? canceledMembership : m))
      message.info('Membership canceled')
    } catch (err) {
      message.error('Failed to cancel membership')
    }
    setCanceling(canceling.filter(m => m._id !== membership._id))
  }

  const onResumeMembership = async membership => {
    setResuming([...resuming, membership._id])
    try {
      const resumedMembership = await resumeMembership(membership._id, {
        select,
        populate
      })
      setMemberships(memberships.map(m => m._id === membership._id ? resumedMembership : m))
      message.info('Membership reactivated')
    } catch (err) {
      message.error('Failed to reactivate membership')
    }
    setResuming(resuming.filter(m => m._id !== membership._id))
  }

  const getCustomFilter = (membership, value) => {
    return {
      membershipType: () => membership.membershipType && membership.membershipType.code.toLowerCase().includes(value.toLowerCase()),
      patient: () => membership.patient && `${membership.patient.firstName?.toLowerCase()} ${membership.patient.lastName?.toLowerCase()}`.includes(value.toLowerCase())
    }
  }

  return (
    <div className="admin-memberships-table">
      <PageHeader
        title='Memberships'
        count={filteredCount}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={memberships}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        columns={[{
          title: 'Status',
          dataIndex: 'status',
          render: (status, { trialEnd }) => {
            return (trialEnd && status !== MembershipStatus.CANCELED) ? (
              <Text className={classNames('status-label', 'pending-status')}>
                pending approval
              </Text>
            ) : (
              <Text className={classNames('status-label', `${status}-status`)}>
                {status.toLowerCase()}
              </Text>
            )
          },
          filterOptions: Object.values(MembershipStatus),
          filterDropdownType: FilterDropdownType.CHECKBOX,

        }, !hasPatient && {
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            if (patient) {
              return (
                <a 
                  onClick={() => navigate(UrlHelper.getPatientProfile(patient._id))}
                >
                  {patient.firstName} {patient.lastName}
                </a>
              )
            }
          },
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Type',
          dataIndex: 'membershipType',
          render: ({ code }) => code,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(MembershipTypeCode)
        },
        // {
        //   title: 'Monthly Price',
        //   dataIndex: 'monthlyPrice',
        //   render: monthlyPrice => monthlyPrice && `$${monthlyPrice}`
        // },
        {
          title: 'Price Paid',
          dataIndex: 'price',
          render: (price, { monthlyPrice }) => price || monthlyPrice ? `$${price || monthlyPrice}` : null
        },
        
        {
          title: 'Stripe',
          dataIndex: 'stripeId',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Total',
          dataIndex: 'total',
          render: total => total ? `$${total}` : null
        }, {
          title: 'Invoices',
          dataIndex: 'invoices',
          render: invoices => invoices?.length ? `${invoices?.length}x` : null
        }, {
          title: 'Created',
          dataIndex: 'createdAt',
          render: createdAt => `${moment(createdAt).format('MMM D, YYYY')}`
        }, {
          dataIndex: 'actions',
          className: "action-cell",
          render: (_, membership) => {
            if (membership.status === MembershipStatus.ACTIVE) {
              return (
                <Button
                  type='default'
                  onClick={() => onCancelMembership(membership)}
                  className='status-btn'
                  danger
                  loading={canceling.includes(membership._id)}
                >
                  Cancel
                </Button>
              )
            } else if (new Date(membership.endAt) > new Date()) {
              return (
                <Button
                  type='primary'
                  onClick={() => onResumeMembership(membership)}
                  className='status-btn'
                  loading={resuming.includes(membership._id)}
                >
                  Reactivate
                </Button>
              )
            }
          }
        }]}
      />
    </div>
  )
}