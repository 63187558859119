import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd'
import { listLabs, addLab } from "../../services/lab.service"
import './adminLabs.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Column } = Table
const { Item } = Form

export const AdminLabs = () => {
  const navigate = useNavigate()
  const [labs, setLabs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    fetchLabs()
  }, [])

  const fetchLabs = async () => {
    const response = await listLabs()
    setLabs(response)
    setIsLoading(false)
  }

  const handleAddLab = () => {
    setIsModalOpen(true)
  }

  const handleModalCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleModalSubmit = async () => {
    try {
      const values = await form.validateFields()
      await addLab(values)
      message.success('Lab added successfully')
      form.resetFields()
      setIsModalOpen(false)
      fetchLabs()
    } catch (error) {
      console.error(error)
      message.error('Failed to add lab')
    }
  }

  return (
    <div className="admin-labs">
      <PageHeader
        title='Labs'
        count={labs.length}
        actions={
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={handleAddLab}
          >
            Add Lab
          </Button>
        }
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={labs}
      >

        <Column
          title='Abbreviation'
          dataIndex='abbreviation'
        />

        <Column
          title='Lab'
          dataIndex='name'
        />

        <Column
          title='CLIA #'
          dataIndex='clia'
        />

        <Column
          title='CAP #'
          dataIndex='cap'
        />

      <Column
          title='Director'
          dataIndex='labDirector'
        />

      </Table>

      <Modal
        title="Add New Lab"
        open={isModalOpen}
        onCancel={handleModalCancel}
        onOk={handleModalSubmit}
        okText="Save"
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Item
            label="Lab Name"
            name="name"
            rules={[{ required: true, message: 'Please enter lab name' }]}
          >
            <Input placeholder="Enter lab name" />
          </Item>

          <Item
            label="Abbreviation"
            name="abbreviation"
            rules={[{ required: true, message: 'Please enter abbreviation' }]}
          >
            <Input placeholder="Enter abbreviation" />
          </Item>

          <Item
            label="CLIA Number"
            name="clia"
          >
            <Input placeholder="Enter CLIA number" />
          </Item>

          <Item
            label="CAP Number"
            name="cap"
          >
            <Input placeholder="Enter CAP number" />
          </Item>

          <Item
            label="Lab Director"
            name="labDirector"
          >
            <Input placeholder="Enter lab director name" />
          </Item>
        </Form>
      </Modal>
    </div>
  )
}