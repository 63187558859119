import { Typography } from "antd";
import "./criticalResult.scss";
import { TestChart } from "../testChart/testChart.component";
import { useState, useEffect } from "react";
import TestCode from "../../../enums/testCode.enum";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import { TestView } from "../testView/testView.component";
const { Paragraph } = Typography;

export const CriticalResult = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [criticalTests, setCriticalTests] = useState([])
  
  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    const criticalTests = ReportHelper.getSectionSuboptimalTests(report, ReportSection.CRITICAL_RESULTS, tests)
    setCriticalTests(criticalTests)
  }, [tests, report])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
      
    if (!user || !tests || !report) return null;

    return <>Your <span>potassium</span> is <span>unusually high</span>, and needs to be re-checked.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !tests || !report) return null;

    return <>High potassium (hyperkalemia) can be serious, as potassium is essential for heart and muscle function. Elevated levels can increase the risk of heart rhythm issues and other complications. To ensure accuracy and address any concerns, let’s arrange a follow-up test soon.</>
  }

  return (
    user &&
    tests?.length > 0 && (
      <div className="critical-result">
        <Paragraph className="report-template-title">
          {title}
        </Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          report={report} 
          tests={criticalTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
        
      </div>
    )
  );
};
