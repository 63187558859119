import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'


export const listRecommendations = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/recommendations`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const updateRecommendations = async (params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/recommendations`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}