import { Popover, Button }  from 'antd'
import Breakpoint from "../../../../enums/breakpoint.enum";
import useWidth from '../../../../hooks/useWidth.hook';
import FlowType from '../../../../enums/flowType.enum';
import { UploadOutlined, CalendarOutlined, CheckCircleOutlined, EditOutlined, LineChartOutlined } from '@ant-design/icons';
import { useState, useContext, useEffect } from 'react'
import { UploadFileModal } from '../../../uploadFileModal/uploadFileModal.component';
import { UserContext } from '../../../../contexts/user.context';
import { listResults } from '../../../../services/result.service';
import { listTests } from '../../../../services/test.service';
import './heartHealthV2Confirmed.scss'
import ResultHelper from '../../../../helpers/result.helper';
import TestCode  from '../../../../enums/testCode.enum';
import moment from 'moment';
 
const HeartHealthV2Confirmed = () => {
  const width = useWidth()
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
  const [hasUploadedFiles, setHasUploadedFiles] = useState(false)
  const { currentUser } = useContext(UserContext)
  const [hasRecentResult, setHasRecentResult] = useState(false)

  useEffect(() => {
    const fetchRecentResults = async () => {
      const results = await listResults({
        select: '_id collectedAt values',
        filter: {
          patient: currentUser._id,
          collectedAt: {
            $gte: moment().subtract(3, 'months').toDate()
          }
        }
      })

      if (!results || !results.length) {
        setHasRecentResult(false)
      
      }

      else {
        const tests = await listTests()

        const recentResult = ResultHelper.getLatestTestValue(TestCode.APO_B, results, tests) || ResultHelper.getLatestTestValue(TestCode.LDL_CHOLESTEROL_CALC, results, tests)

        setHasRecentResult(recentResult)
      }
    }

    fetchRecentResults()
  }, [currentUser])

  const drWiniarskiContent = (
    <div style={{ 
      padding: '24px',
      maxWidth: '300px'
    }}>
      <img 
        src="/img/steven.webp" 
        alt="Dr. Steven Winiarski" 
        style={{ 
          width: '140px', 
          display: 'block',
          margin: '0 auto',
          borderRadius: '50%', 
          marginBottom: '16px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
        }} 
      />
      <h3 style={{ 
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        marginBottom: '12px',
        color: '#2c3e50'
      }}>Dr. Steven Winiarski</h3>
      <p style={{ 
        fontSize: '14px', 
        lineHeight: '1.6', 
        color: '#5a6c7d',
        textAlign: 'center' 
      }}>
        Dr. Steven Winiarski is a board-certified clinical lipidologist with over 25 years of experience helping patients optimize their cardiovascular health through evidence-based treatments.
      </p>
    </div>
  );

  return (
    <div 
      className="heart-health-v2-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <div className="member-title">
          Welcome to Your Heart Health Program
        </div>
        
        <div className="welcome-message">
        
            Thank you for joining! Our team is here to support you. If you ever have any questions, please contact us anytime at{' '}
            <a style={{color: `gray`}} href="mailto:concierge@instalab.com">concierge@instalab.com</a>.
        
        </div>

        <div className="next-steps-container">
          <h2 className="next-steps-title">Here Are Your Next Steps:</h2>

          <div className="step-card">
            <div className="step-number">1</div>
            <div className="step-content">
              <h4>Complete Your Medical Intake Form</h4>
              <p>Please fill out your medical history and current health information so we can provide you with the most appropriate care.</p>
              <Button
                type="primary"
                className="step-button"
                icon={<EditOutlined />}
                onClick={() => window.open(`/flow/${FlowType.INTAKE}`, '_blank')}
              >
                {'Complete Intake'}
              </Button> 
            </div>
          </div>
          
          <div className={`step-card ${hasRecentResult ? 'completed' : ''}`}>
            <div className="step-number">2</div>
            <div className="step-content">
              <h4>
                Get Baseline Heart Health Test Done
                {hasRecentResult && <span className="completed-tag"><CheckCircleOutlined /> COMPLETED</span>}
              </h4>
              <p>Before we can initiate treatment, we need to measure your baseline. Please take the heart health test ASAP. You can skip this step if you've tested your ApoB or LDL cholesterol within the last 3 months (just upload the results to continue).</p>
              <Button
                type="primary"
                className="step-button"
                icon={<LineChartOutlined />}
                onClick={() => window.open(`/flow/${FlowType.HEART_HEALTH_TEST}`, '_blank')}
              >   
                {'Get Test Done'}
              </Button>

              <Button
                type="default"
                className="step-button"
                icon={<UploadOutlined />}
                onClick={() => setIsUploadModalVisible(true)}
              >
                Upload Lab Results
              </Button>

              <UploadFileModal
                isModalVisible={isUploadModalVisible}
                setIsModalVisible={setIsUploadModalVisible}
                patients={[currentUser]}
                title="Upload Lab Results"
                fetchPatientsAndFiles={() => setHasUploadedFiles(true)}
              />

            </div>
          </div>

          <div className={`step-card ${(!hasUploadedFiles && !hasRecentResult) ? 'disabled' : ''}`}>
            <div className="step-number">3</div>
            <div className="step-content">
              <h4>Consultation with Dr. Winiarski</h4>
              <p>
                Book your initial consultation with your heart health physician,{' '}
                <Popover content={drWiniarskiContent}>
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Dr. Steven Winiarski</span>, 
                </Popover>{' '}
                to discuss your test results and create a personalized treatment plan.
              </p>
               <Button
                type="primary"
                className="step-button"
                icon={<CalendarOutlined />}
                disabled={!hasUploadedFiles && !hasRecentResult}
                onClick={() => window.open(`/flow/${FlowType.HEART_MEMBER_CONSULT}`, '_blank')}
              >
                Schedule Consult
              </Button>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default HeartHealthV2Confirmed