import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listPharmacies = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pharmacies`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addPharmacy = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/pharmacies`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const updatePharmacy = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/pharmacies/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const deletePharmacy = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/pharmacies/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const getPharmacy = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pharmacies/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}
