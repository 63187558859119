import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from 'chart.js';
import { UserContext } from "../../../contexts/user.context";
import { useContext } from "react";
import { Typography, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";


const { Paragraph } = Typography;

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

export const TestosteroneAgePost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  const femaleOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return ` ${context.dataset.label}: ${context.raw.toFixed(2)} ng/dL`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Free Testosterone (ng/dL)'
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(2);
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Age Range'
        }
      }
    }
  };

  const femaleTestosteroneData = {
    labels: ['20-29', '30-39', '40-49', '50-59', '60-69', '70-80'],
    datasets: [
      {
        label: 'Abnormal Range',
        data: Array(6).fill(0.20),
        borderColor: 'rgba(255, 0, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        fill: true,
        tension: 0,
        pointRadius: 0,
        order: 4  // Ensure it's drawn first (behind other datasets)
      },
      {
        label: 'Median',
        data: [0.346, 0.248, 0.219, 0.222, 0.228, 0.196],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1)',
        borderWidth: 2,
        tension: 0.4,
        fill: false
      },
      {
        label: '25th Percentile',
        data: [0.222, 0.176, 0.159, 0.159, 0.150, 0.138],
        borderColor: 'rgba(53, 162, 235, 0.2)',
        borderWidth: 1,
        tension: 0.4,
        fill: false
      },
      {
        label: '75th Percentile',
        data: [0.542, 0.387, 0.294, 0.329, 0.323, 0.294],
        borderColor: 'rgba(53, 162, 235, 0.2)',
        borderWidth: 1,
        tension: 0.4,
        fill: '-1',
        backgroundColor: 'rgba(53, 162, 235, 0.1)'
      }
    ]
  };

  const maleTestosteroneData = {
    labels: ['20-29', '30-39', '40-49', '50-59', '60-69', '70-89'],
    datasets: [
      {
        label: 'Abnormal Range',
        data: Array(6).fill(4.00),
        borderColor: 'rgba(255, 0, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        fill: true,
        tension: 0,
        pointRadius: 0,
        order: 4  // Ensure it's drawn first (behind other datasets)
      },
      {
        label: 'Median',
        data: [10.20, 9.03, 6.79, 4.61, 3.39, 2.54],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1)',
        borderWidth: 2,
        tension: 0.4,
        fill: false
      },
      {
        label: '-2 Standard Deviations',
        data: [5.53, 4.68, 3.54, 2.00, 1.22, 0.83],
        borderColor: 'rgba(53, 162, 235, 0.2)',
        borderWidth: 1,
        tension: 0.4,
        fill: false
      },
      {
        label: '+2 Standard Deviations',
        data: [14.87, 13.37, 10.03, 7.22, 5.56, 4.24],
        borderColor: 'rgba(53, 162, 235, 0.2)',
        borderWidth: 1,
        tension: 0.4,
        fill: '-1',
        backgroundColor: 'rgba(53, 162, 235, 0.1)'
      }
    ]
  };

  const maleOptions = {
    ...femaleOptions,  // Base options
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Free Testosterone (ng/dL)'
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(2);
          }
        }
      },
      x: {
        title: {
          display: true,
          text: 'Age Range'
        }
      }
    }
  };

  const lastTestValue = 13.69;
  const mockProjections = {
    ages: [32, 45, 55, 65, 80],
    median: [
      lastTestValue,                                          // Current: 13.69
      lastTestValue * (6.79/9.03),                           // Age 45: ~10.29
      lastTestValue * (6.79/9.03) * (4.61/6.79),            // Age 55: ~6.98
      lastTestValue * (6.79/9.03) * (4.61/6.79) * (3.39/4.61), // Age 65: ~5.13
      lastTestValue * (6.79/9.03) * (4.61/6.79) * (3.39/4.61) * (2.54/3.39) // Age 80: ~3.84
    ].map(val => Number(val.toFixed(2)))
  };
  
  const lastResultDate = new Date('2024-02-29T18:00:00+0100'); // Last result before improvements

  const mockChartData = {
    datasets: [
      {
        label: 'Free Testosterone',
        data: [
          { x: new Date('2022-12-12T17:00:00+0100'), y: 15.03 },
          { x: new Date('2023-04-11T22:00:00+0200'), y: 14.97 },
          { x: new Date('2024-01-03T19:00:00+0100'), y: 16.44 },
          { x: new Date('2024-02-29T18:00:00+0100'), y: 13.69 }
        ],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.4
      },
      {
        label: 'Projected',
        data: mockProjections.ages.map((age, i) => ({
          x: new Date(lastResultDate.getTime() + (age - 32) * 365 * 24 * 60 * 60 * 1000),
          y: mockProjections.median[i]
        })),
        borderColor: 'rgb(53, 162, 235)',
        borderDash: [5, 5],
        tension: 0.4
      },
      {
        label: 'Abnormal Range',
        data: [
          { x: new Date('2022-12-12T17:00:00+0100'), y: 4 }, // Start from earliest result
          { x: new Date(lastResultDate.getTime() + (80 - 32) * 365 * 24 * 60 * 60 * 1000), y: 4 }
        ],
        borderColor: 'rgba(255, 0, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        fill: {
          target: {
            value: 0
          }
        },
        pointRadius: 0,
        tension: 0
      }
    ]
  };

  const commonAxisConfig = {
    x: {
      type: 'time',
      time: {
        unit: 'year',
      },
      title: {
        display: true,
        text: 'Age'
      },
      min: new Date('2022-12-12T17:00:00+0100').getTime(), // Start from first data point
      ticks: {
        autoSkip: false,
        callback: function(value) {
          const date = new Date(value);
          const age = Math.floor((date - new Date('1992-03-12')) / (365.25 * 24 * 60 * 60 * 1000));
          // Only show ages that are multiples of 10 (30, 40, 50, 60, 70, 80)
          return age % 10 === 0 ? age : '';
        },
        maxRotation: 0
      }
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Free Testosterone (ng/dL)'
      },
      ticks: {
        callback: function(value) {
          return value.toFixed(2);
        }
      }
    }
  };

  const mockOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'My Original Projection'
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].raw.x).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            });
            const age = Math.floor((new Date(context[0].raw.x) - new Date('1992-02-05')) / (365.25 * 24 * 60 * 60 * 1000));
            return `${age} years old (${date})`;
          },
          label: function(context) {
            const isProjected = context.dataset.label === 'Projected';
            return isProjected 
              ? ` Projected: ${context.raw.y} ng/dL`
              : ` Free Testosterone: ${context.raw.y} ng/dL`;
          }
        }
      }
    },
    scales: commonAxisConfig,
    clip: false
  };

  // Second chart (After Exercise + Diet Improvements)
  const lastTestValueAfter = 20.20;
  const mockProjectionsAfter = {
    ages: [32, 45, 55, 65, 80],
    median: [
      lastTestValueAfter,                                          // Current: 20.20
      lastTestValueAfter * (6.79/9.03),                           // Age 45: ~15.19
      lastTestValueAfter * (6.79/9.03) * (4.61/6.79),            // Age 55: ~10.30
      lastTestValueAfter * (6.79/9.03) * (4.61/6.79) * (3.39/4.61), // Age 65: ~7.57
      lastTestValueAfter * (6.79/9.03) * (4.61/6.79) * (3.39/4.61) * (2.54/3.39) // Age 80: ~5.67
    ].map(val => Number(val.toFixed(2)))
  };

  const lastResultDateAfter = new Date('2024-10-03T14:18:00+0200'); // Latest result after improvements
  const mockChartDataAfter = {
    datasets: [
      {
        label: 'Free Testosterone',
        data: [
          { x: new Date('2022-12-12T17:00:00+0100'), y: 15.03 },
          { x: new Date('2023-04-11T22:00:00+0200'), y: 14.97 },
          { x: new Date('2024-01-03T19:00:00+0100'), y: 16.44 },
          { x: new Date('2024-02-29T18:00:00+0100'), y: 13.69 },
          { x: new Date('2024-10-03T14:18:00+0200'), y: 20.20 }
        ],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.4
      },
      {
        label: 'Projected',
        data: mockProjectionsAfter.ages.map((age, i) => ({
          x: new Date(lastResultDateAfter.getTime() + (age - 32) * 365 * 24 * 60 * 60 * 1000),
          y: mockProjectionsAfter.median[i]
        })),
        borderColor: 'rgb(53, 162, 235)',
        borderDash: [5, 5],
        tension: 0.4
      },
      {
        label: 'Abnormal Range',
        data: [
          { x: new Date('2022-12-12T17:00:00+0100'), y: 4 },
          { x: new Date('2024-10-03T14:18:00+0200').getTime() + (80 - 32) * 365 * 24 * 60 * 60 * 1000, y: 4 }
        ],
        borderColor: 'rgba(255, 0, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        fill: {
          target: {
            value: 0
          }
        },
        pointRadius: 0,
        tension: 0
      }
    ]
  };

  const mockOptionsAfter = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '7 Months Later: After Exercise + Diet Improvements'
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].raw.x).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            });
            const age = Math.floor((new Date(context[0].raw.x) - new Date('1992-02-05')) / (365.25 * 24 * 60 * 60 * 1000));
            return `${age} years old (${date})`;
          },
          label: function(context) {
            const isProjected = context.dataset.label === 'Projected';
            return isProjected 
              ? ` Projection: ${context.raw.y} ng/dL`
              : ` Free Testosterone: ${context.raw.y} ng/dL`;
          }
        }
      }
    },
    scales: commonAxisConfig,
    clip: false
  };

  return (
    <div className={classNames("blog-post", "lpa-post")}>
      <div className="blog-border" /><br/><br/>

      <div className="blog-body">

        <Paragraph className="blog-title">
          It's Not How Much You Have; It's How Much You Can Use
        </Paragraph>
        <Paragraph className="blog-description">
          When it comes to testosterone, it's not enough to look at how much you have in total—what really matters is how much is biologically available for your body to use. Most of the testosterone in your bloodstream is bound to protein and cannot enter cells to perform its critical functions:
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[{
            state: 'Bound to Sex Hormone-Binding Globulin (SHBG)',
            percentage: '60-70%',
            usability: 'Unavailable for cellular use'
          }, {
            state: 'Bound to Albumin',
            percentage: '30-40%',
            usability: 'Readily accessible to be converted into active state'
          }, {
            state: 'Free Testosterone',
            percentage: '1-2%',
            usability: 'Immediately available to enter cells'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column title="State" dataIndex="state" />
          <Table.Column title="Percentage" dataIndex="percentage" />
          <Table.Column title="Usability" dataIndex="usability" />
        </Table>

        <Paragraph className="blog-description">
          Of these states, free testosterone is the most important for driving the hormone's effects, including muscle growth, energy levels, and overall metabolic health. Even if your total testosterone levels are normal, a low level of free testosterone still means your body does not have enough testosterone available to function properly. It's the difference between owning a car and having gas in the tank.
        </Paragraph>

        <Paragraph className="blog-title">
          How Free Testosterone Changes With Age
        </Paragraph>

        <Paragraph className="blog-description">
          Testosterone levels rise rapidly during puberty, fueling key developmental changes in both males and females. However, these levels don't stay high forever. In men, free testosterone begins a steady decline from its peak starting in their 30s, dropping by an average of 1.2% per year. By comparison, free testosterone levels in women decline rapidly during menopause and then stabilize after.
        </Paragraph>

        <Paragraph className="blog-title">
          Men
        </Paragraph>

        <div className="blog-chart">
          <Line data={maleTestosteroneData} options={maleOptions} />
        </div>

        <Paragraph className="blog-title">
          Women
        </Paragraph>

        <div className="blog-chart">
          <Line data={femaleTestosteroneData} options={femaleOptions} />
        </div>

        <Paragraph className="blog-title">
          Low Testosterone Hits Harder With Age in Men
        </Paragraph>

        <Paragraph className="blog-description">
          By the time men reach their 60s and 70s, about <b>30%</b> have low free testosterone levels. By their 70s and 80s, that figure climbs to a staggering <b>70%</b>. The chart above, showing average free testosterone levels for men across age groups, underscores the scale of this issue. For men in their 70s and older, the majority fall into non-optimal ranges—even when accounting for natural variability within two standard deviations.
        </Paragraph>

        <Paragraph className="blog-description">
          This widespread decline in testosterone isn't just a statistical trend—it represents a significant health consideration for aging men, influencing everything from energy and mood to muscle mass and overall vitality.
        </Paragraph>

        <Paragraph className="blog-title">
          Are You at Risk for Low Testosterone in the Future?
        </Paragraph>

        <Paragraph className="blog-description">
          At Instalab, we've developed a <a className="blog-link" onClick={() => {
            navigate('/testosterone-projection')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: testosterone projection`, true)
          }}>simple, user-friendly tool</a> to help you assess your testosterone trajectory over time. By comparing your current testosterone levels against the average age-related decline, the tool generates a clear visual projection of your future levels.
        </Paragraph>

        <Paragraph className="blog-description">
          To illustrate, take a look at my personal free testosterone data below. At 32 years old, my levels were objectively within the normal range—not low by any standard. While that's great news now, the projection also revealed that by my late 70s, I was on track to experience low testosterone levels. Since I have no interest in losing muscle mass or increasing my risk of cardiovascular issues, that was a wake-up call.
        </Paragraph>

        <div className="blog-chart">
          <Line data={mockChartData} options={mockOptions} />
        </div>

        <Paragraph className="blog-description">
          Rather than immediately turning to medication, I decided to focus on two areas where I had significant room for improvement: exercise and sleep quality. These lifestyle changes had a dramatic impact, boosting my free testosterone levels and bringing them into a much safer range. Now, I'm far less likely to face low testosterone as I age.
        </Paragraph>

        <div className="blog-chart">
          <Line data={mockChartDataAfter} options={mockOptionsAfter} />
        </div>

        <Paragraph className="blog-description">
          I'm sharing this data not because of what it means for my own health, but because it highlights an important message: small, intentional lifestyle adjustments can have a profound impact on your future well-being. If you're curious about your own trajectory, this <a className="blog-link" onClick={() => {
            navigate('/testosterone-projection')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: testosterone projection`, true)
          }}>tool</a> is a great place to start.
        </Paragraph>

        <Paragraph className="blog-title">
          What Can You Do?
        </Paragraph>

        <Paragraph className="blog-description">
          Weight management is one of the most effective ways to influence testosterone levels. Research shows a strong link between excess body fat and reduced testosterone production. Maintaining a healthy weight not only improves testosterone levels but also gives you immediate, tangible feedback on your progress.
        </Paragraph>

        <Paragraph className="blog-description">
          That said, crash diets or extreme eating plans aren't the solution. These approaches are often unsustainable and can lead to yo-yo effects that are ultimately counterproductive. Instead, the key lies in finding a balanced diet and exercise routine that fits your lifestyle—something enjoyable and sustainable in the long term.
        </Paragraph>

        <Paragraph className="blog-description">
          It's also important to acknowledge that tools like BMI, while commonly used, have their limitations. For instance, someone with a high muscle mass might be labeled "overweight" on the BMI scale despite being in excellent health. That's why it's crucial to look beyond numbers on a chart. Ultimately, you're the best judge of your body—whether it's carrying excess fat that could be affecting your testosterone levels or finding a balanced approach to shed it sustainably.
        </Paragraph>

        <Paragraph className="blog-title">
          Skimping on Sleep Tanks Your Testosterone
        </Paragraph>

        <Paragraph className="blog-description">
          Sleep plays a crucial role in maintaining healthy testosterone levels too. Research indicates that for every hour of sleep lost, total testosterone levels drop by an average of <b>5.85 ng/dL</b> in men. For many patients at Instalab—particularly high achievers balancing demanding careers and lifestyles—this becomes a significant issue. Beyond its impact on testosterone, sleep deprivation carries a host of other health risks, but its direct effect on hormonal balance is particularly concerning.
        </Paragraph>

        <Paragraph className="blog-title">
          Measuring Testosterone Levels
        </Paragraph>

        <Paragraph className="blog-description">
          At Instalab, we've simplified testosterone monitoring with our <a onClick={() => {
            navigate('/testosterone')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: request test`, true)
          }} className="blog-link">at-home test kit</a> . It measures both total and free testosterone, along with key related proteins like SHBG and albumin. These biomarkers provide a detailed picture of your body's free testosterone levels and whether you will be in an optimal range as you age.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Gray, A., Feldman, H., Mckinlay, J., & Longcope, C. (1991). Age, disease, and changing sex hormone levels in middle-aged men: results of the Massachusetts Male Aging Study. The Journal of clinical endocrinology and metabolism, 73(5), 1016-25. <a onClick={() => window.open('https://doi.org/10.1210/JCEM-73-5-1016', '_blank')}>https://doi.org/10.1210/JCEM-73-5-1016</a>
            </li>
            <li>
              Handelsman, D., Yeap, B., Flicker, L., Martin, S., Wittert, G., & Ly, L. (2015). Age-specific population centiles for androgen status in men. European journal of endocrinology, 173(6), 809-17. <a onClick={() => window.open('https://doi.org/10.1530/EJE-15-0380', '_blank')}>https://doi.org/10.1530/EJE-15-0380</a>
            </li>
            <li>
              Haring, R., Hannemann, A., John, U., Radke, D., Nauck, M., Wallaschofski, H., Owen, L., Adaway, J., Keevil, B., & Brabant, G. (2012). Age-specific reference ranges for serum testosterone and androstenedione concentrations in women measured by liquid chromatography-tandem mass spectrometry. The Journal of clinical endocrinology and metabolism, 97(2), 408-15. <a onClick={() => window.open('https://doi.org/10.1210/jc.2011-2134', '_blank')}>https://doi.org/10.1210/jc.2011-2134</a>
            </li>
            <li>
              Hijazi, R., & Cunningham, G. (2005). Andropause: is androgen replacement therapy indicated for the aging male?. Annual review of medicine, 56, 117-37. <a onClick={() => window.open('https://doi.org/10.1146/ANNUREV.MED.56.082103.104518', '_blank')}>https://doi.org/10.1146/ANNUREV.MED.56.082103.104518</a>
            </li>
            <li>
              Nanba, A., Rege, J., Ren, J., Auchus, R., Rainey, W., & Turcu, A. (2019). 11-Oxygenated C19 Steroids Do Not Decline With Age in Women. The Journal of clinical endocrinology and metabolism, 104(7), 2615-2622. <a onClick={() => window.open('https://doi.org/10.1210/jc.2018-02527', '_blank')}>https://doi.org/10.1210/jc.2018-02527</a>
            </li>
            <li>
              Patel, P., Shiff, B., Kohn, T., & Ramasamy, R. (2018). Impaired sleep is associated with low testosterone in US adult males: results from the National Health and Nutrition Examination Survey. World Journal of Urology, 37, 1449-1453. <a onClick={() => window.open('https://doi.org/10.1007/s00345-018-2485-2', '_blank')}>https://doi.org/10.1007/s00345-018-2485-2</a>
            </li>
            <li>
              Schatzl, G., Madersbacher, S., Temml, C., Krenn-Schinkel, K., Nader, A., Sregi, G., Lapin, A., Hermann, M., Berger, P., & Marberger, M. (2003). Serum androgen levels in men: impact of health status and age. Urology, 61(3), 629-33. <a onClick={() => window.open('https://doi.org/10.1016/S0090-4295(02)02252-5', '_blank')}>https://doi.org/10.1016/S0090-4295(02)02252-5</a>
            </li>
            <li>
              Travison, T., Araujo, A., Kupelian, V., O'Donnell, A., & Mckinlay, J. (2007). The relative contributions of aging, health, and lifestyle factors to serum testosterone decline in men. The Journal of clinical endocrinology and metabolism, 92(2), 549-55. <a onClick={() => window.open('https://doi.org/10.1210/JC.2006-1859', '_blank')}>https://doi.org/10.1210/JC.2006-1859</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
