import { GoalCategory, ReportSection, RecommendationKind, MedicalCondition, TestCode, RiskLevel, Condition } from "../enums/index.enum"
import ResultHelper from "../helpers/result.helper"

const cardioEvents = [
    MedicalCondition.HEART_DISEASE,
    MedicalCondition.HEART_ATTACK,
    MedicalCondition.BLOCKED_ARTERY,
    MedicalCondition.STROKE,
];

const neuroEvents = [
    MedicalCondition.DEMENTIA,
];

const metabolicEvents = [
    MedicalCondition.DIABETES,
];

const cancerEvents = [
    MedicalCondition.CANCER,
    MedicalCondition.CANCER_BREAST,
    MedicalCondition.CANCER_COLORECTAL,
    MedicalCondition.CANCER_OVARIAN,
    MedicalCondition.CANCER_CERVICAL,
    MedicalCondition.CANCER_PROSTATE,
    MedicalCondition.CANCER_SKIN,
    MedicalCondition.CANCER_PANCREATIC,
    MedicalCondition.CANCER_OTHER,
];

const SectionData = {
    [ReportSection.CVD]: {
        code: ReportSection.CVD,
        goalCategory: GoalCategory.CVD,
        title: "Prevent Heart Disease",
        tocTitle: "Cardiovascular Health",
        weight: ({patient, results, tests}) => {
            if (!patient || !results || !tests) return 0
            const hasFamilyHistory = patient?.familyHistory?.some(condition => cardioEvents.includes(condition))
            const apoeRisk = ResultHelper.getLatestTestRisk(TestCode.APO_E, results, tests)
            const lpaRisk = ResultHelper.getLatestTestRisk(TestCode.LIPOPROTEIN_A, results, tests)
            const cacRisk = ResultHelper.getLatestTestRisk(TestCode.CAC, results, tests)
            const apobRisk = ResultHelper.getLatestTestRisk(TestCode.APO_B, results, tests)
            const homocysteineRisk = ResultHelper.getLatestTestRisk(TestCode.HOMOCYSTEINE, results, tests)

            let score = 0.5 // base score
            if (hasFamilyHistory) score += 1
            if (apoeRisk === RiskLevel.HIGH) score += 1
            else if (apoeRisk === RiskLevel.MODERATE) score += 0.5
            if (lpaRisk === RiskLevel.HIGH) score += 1
            else if (lpaRisk === RiskLevel.MODERATE) score += 0.5
            if (cacRisk === RiskLevel.HIGH) score += 1
            else if (cacRisk === RiskLevel.MODERATE) score += 0.5
            if (apobRisk === RiskLevel.HIGH) score += 1
            else if (apobRisk === RiskLevel.MODERATE) score += 0.5
            if (homocysteineRisk === RiskLevel.HIGH) score += 0.5
            return score

        },

        description: ({patient, results, tests}) => {
            const riskFactors = [];
            const hasFamilyHistory = patient?.familyHistory?.some(condition => cardioEvents.includes(condition))
            const lpaRisk = ResultHelper.getLatestTestRisk(TestCode.LIPOPROTEIN_A, results, tests)
            const apoeRisk = ResultHelper.getLatestTestRisk(TestCode.APO_E, results, tests)
            const cacScore = ResultHelper.getLatestTestValue(TestCode.CAC, results, tests)

            if ([RiskLevel.HIGH, RiskLevel.MODERATE].includes(lpaRisk)) 
                riskFactors.push(`{{${TestCode.LIPOPROTEIN_A}:elevated Lp(a) levels}}`);
            if ([RiskLevel.HIGH, RiskLevel.MODERATE].includes(apoeRisk)) 
                riskFactors.push(`{{${TestCode.APO_E}:APOE4 carrier status}}`);
            // if ([RiskLevel.HIGH, RiskLevel.MODERATE].includes(cacRisk)) 
            //     riskFactors.push(`elevated {{${TestCode.CAC}:CAC}} levels`);
            if (hasFamilyHistory) 
                riskFactors.push("a family history of heart disease");
            
            let riskText = '';

            if (cacScore !== undefined && cacScore !== null) {
                if (Number(cacScore) === 0) {
                    riskText += `Your {{${TestCode.CAC}:CAC score of 0}} is excellent news and means you are at near zero risk for a cardiac event in the next 5-10 years. ${riskFactors.length ? ` It's important to maintain this advantage through continued focus on cardiovascular health, especially since your ${riskFactors.length > 1 ? riskFactors.slice(0, -1).join(', ') + ' and ' + riskFactors.slice(-1) : riskFactors[0]} put you at higher risk.` : `However, it's important to maintain this advantage through continued focus on cardiovascular health.`}`;
                } else if (Number(cacScore) > 0 && Number(cacScore) <= 100) {
                    riskText += `Your {{${TestCode.CAC}:CAC score of ${cacScore}}} indicates mild plaque buildup, which puts you at a moderate risk for a cardiac event within the next 5-10 years. It's essential to adopt lifestyle changes, and medication should be considered to slow disease progression and manage this risk effectively.`;
                } else if (Number(cacScore) > 100 && Number(cacScore) < 300) {
                    riskText += `Your {{${TestCode.CAC}:CAC score of ${cacScore}}} indicates moderate plaque buildup, indicating a moderate to high risk of a cardiac event within the next 5-10 years. To lower your risk of heart attack, intensive lifestyle modifications and medications are needed to manage plaque progression and reduce your overall risk. Regular monitoring and proactive treatment are critical to improving heart health.`;
                } else if (Number(cacScore) >= 300) {
                    riskText += `Your {{${TestCode.CAC}:CAC score of ${cacScore}}} indicates extensive plaque buildup, indicating a high risk of a cardiac event within the next 5-10 years. Intensive treatment, including both lifestyle changes and medications, is critical to reduce this risk. Close monitoring and regular follow-ups with your healthcare provider are essential to manage plaque progression and protect your heart health.`;
                }
            }

            else if (!(Number(cacScore) > 0) && riskFactors.length) {
        
                const riskList = riskFactors.length > 1 
                    ? riskFactors.slice(0, -1).join(', ') + ' and ' + riskFactors.slice(-1) 
                    : riskFactors[0];
                
                riskText += ` Your ${riskList} means your long-term risk is ${cacScore && Number(cacScore) === 0 ? 'still elevated' : 'higher'}, but proactive steps will make a difference.`;
                
                if (lpaRisk === RiskLevel.HIGH || lpaRisk === RiskLevel.MODERATE) {
                    riskText += ` Since Lp(a) levels are largely genetic and currently lack effective treatments to mitigate the risk it imparts, focusing on other cardiovascular risk factors is crucial for reducing your overall risk.`;
                }

                // else {
                //     riskText += ` Tracking and optimizing key biomarkers like lipids, inflammation, and arterial health will help you stay ahead of potential issues.`;
                // }
                
       
            }
            
            return [{
                type: "paragraph",
                text: "Heart disease develops silently over time, often with no warning signs until a serious event like a heart attack or stroke occurs. This makes making regular heart health monitoring essential."
            }, riskText ? {
                type: "paragraph",
                text: riskText,
            } : {
                type: "paragraph",
                text: `Tracking and optimizing key biomarkers like lipids, inflammation, and arterial health will help you stay ahead of potential issues.`,
            }]
        },
    },
    [ReportSection.NEURO]: {
        code: ReportSection.NEURO,
        goalCategory: GoalCategory.NEURO,
        title: "Protect Against Cognitive Decline",
        tocTitle: "Neurodegenerative Health",
        weight: ({patient, results, tests}) => { 
            if (!patient || !results || !tests) return 0
            const apoeRisk = ResultHelper.getLatestTestRisk(TestCode.APO_E, results, tests)
            const hasFamilyHistory = patient?.familyHistory?.some(condition => neuroEvents.includes(condition))
 
            let score = 0.5 // base score
            if (hasFamilyHistory) score += 0.5
            if (apoeRisk === RiskLevel.HIGH) score += 1
            else if (apoeRisk === RiskLevel.MODERATE) score += 0.5
            return score
        },
        description: ({patient, results, tests}) => {
            const riskFactors = [];
            const hasFamilyHistory = patient?.familyHistory?.some(condition => neuroEvents.includes(condition))
            const apoeRisk = ResultHelper.getLatestTestRisk(TestCode.APO_E, results, tests)
            const hasAPOE4 = apoeRisk === RiskLevel.HIGH || apoeRisk === RiskLevel.MODERATE
            if (hasFamilyHistory) riskFactors.push("a family history of dementia");
            if (hasAPOE4) riskFactors.push(`{{${TestCode.APO_E}:APOE4 carrier status}}`);
            
            return [{
                type: "paragraph",
                text: `Neurodegenerative diseases like Alzheimer's and Parkinson's start developing decades before symptoms appear. Taking proactive steps now supports long-term cognitive function, memory retention, and mental clarity.`,
            },
            riskFactors.length > 0 && {
                type: "paragraph",
                text: `Having ${riskFactors.length > 1 
                    ? riskFactors.slice(0, -1).join(', ') + ' and ' + riskFactors.slice(-1) 
                    : riskFactors[0]} may increase your genetic predisposition but it does not determine your fate. Lifestyle choices play a significant role, and there are evidence-based strategies to help lower your risk and support brain health over time.` 
            }]
        },
    },
    [ReportSection.METABOLIC]: {
        code: ReportSection.METABOLIC,
        goalCategory: GoalCategory.METABOLIC,
        title: "Prevent Metabolic Decline",
        tocTitle: "Metabolic Health",
        weight: ({patient, results, tests}) => {
            if (!patient || !results || !tests) return 0
            const hasFamilyHistory = patient?.familyHistory?.some(condition => metabolicEvents.includes(condition))
            const homaIRRisk = ResultHelper.getLatestTestRisk(TestCode.HOMAIR, results, tests)
            const homaIRValue = ResultHelper.getLatestTestValue(TestCode.HOMAIR, results, tests)
            const obesityRisk = ResultHelper.getLatestTestRisk(TestCode.BMI, results, tests) 
            const hasDiabetesRisk = ResultHelper.getLatestTestRisk(TestCode.HBA1C, results, tests) 

            let score = 0.5 // base score
            if (hasFamilyHistory) score += 0.5
            if (homaIRRisk === RiskLevel.HIGH) score += 2
            else if (homaIRRisk === RiskLevel.MODERATE && homaIRValue > 2) score += 0.5
            if (obesityRisk === RiskLevel.HIGH) score += 2
            else if (obesityRisk === RiskLevel.MODERATE) score += 0.5
            if (hasDiabetesRisk === RiskLevel.HIGH) score += 2
            else if (hasDiabetesRisk === RiskLevel.MODERATE) score += 1
            return score
        },
        description: ({patient, results, tests}) => {
            const riskFactors = [];
            const hasFamilyHistory = patient?.familyHistory?.some(condition => metabolicEvents.includes(condition))
            const homaIRValue = ResultHelper.getLatestTestValue(TestCode.HOMAIR, results, tests)
            const obesityRisk = ResultHelper.getLatestTestRisk(TestCode.BMI, results, tests)
            const diabetesRisk = ResultHelper.getLatestTestRisk(TestCode.HBA1C, results, tests) 

            if (hasFamilyHistory) riskFactors.push("a family history of metabolic dysfunction");
            if (homaIRValue > 2) riskFactors.push("insulin resistance");
            if ([RiskLevel.HIGH, RiskLevel.MODERATE].includes(obesityRisk) || patient?.currentMedicalConditions?.includes(MedicalCondition.OBESITY)) riskFactors.push("elevated BMI");
            if (diabetesRisk === RiskLevel.HIGH || diabetesRisk === RiskLevel.MODERATE) riskFactors.push("elevated HbA1c");

            return [{
                type: "paragraph",
                text: `Metabolic health reflects how efficiently your body converts food into energy, maintains muscle, and regulates key processes like blood sugar balance and inflammation. It directly impacts energy levels, body composition, and long-term disease risk.`,
            },

            (diabetesRisk === RiskLevel.HIGH || diabetesRisk === RiskLevel.MODERATE) ? {
                type: "paragraph",
                text: `Your elevated HbA1c ${homaIRValue > 2 ? 'and HOMA-IR' : ''} is consistent with insulin resistance at the level of ${diabetesRisk === RiskLevel.HIGH ? 'diabetes' : 'prediabetes'}, which means the tissues in your body are having trouble utilizing glucose correctly for daily function.  Left unaddressed, this can lead to further metabolic dysfunction. However, a few targeted changes can significantly improve your metabolic health and reduce long-term risks.`,
            } : (homaIRValue > 2) ? {
                type: "paragraph",
                text: "Your elevated HOMA-IR is consistent with insulin resistance, which means the tissues in your body are having trouble utilizing glucose correctly for daily function. Left unaddressed, this can lead to metabolic dysfunction. However, a few targeted changes can significantly improve your metabolic health and reduce long-term risks.",
            } : "Optimizing this area reduces your likelihood of weight issues, diabetes, and heart disease.",
            ]
        },
    },
    [ReportSection.CANCER]: {
        code: ReportSection.CANCER,
        goalCategory: GoalCategory.CANCER,
        title: "Prevent Cancer",
        tocTitle: "Cancer Prevention",
        weight: ({patient, results, tests}) => {
            if (!patient || !results || !tests) return 0
            const hasFamilyHistory = patient?.familyHistory?.some(condition => cancerEvents.includes(condition))

            let score = 0.5 // base score
            if (hasFamilyHistory) score += 1
            return score
        },
        description: ({patient, results, tests}) => {
            const hasFamilyHistory = patient?.familyHistory?.some(condition => cancerEvents.includes(condition))
            
            return [{
                type: "paragraph",
                text: `Cancer is a leading cause of premature death, with risks increasing as we age. ${hasFamilyHistory ? `Because of your family history, you are at higher risk, making early screening and proactive prevention critical.` : 'While genetics play a role, most cancers develop due to chronic inflammation, immune dysfunction, and environmental factors. Fortunately, early detection and risk-reduction strategies can significantly lower your chances of developing aggressive disease.'}`,
            }]
        },
    },
    [ReportSection.OTHER]: {
        code: ReportSection.OTHER,
        goalCategory: GoalCategory.OTHER,
        title: "Watchlist",
        tocTitle: "Watchlist",
        description: ({patient, results, tests}) => {
            return [{
                type: "paragraph",
                text: "These are additional considerations we think you should be aware of based on your test results, health profile and preferences.",
            }]
        },
    },
    [ReportSection.NUTRITION]: {
        code: ReportSection.NUTRITION,
        kindCategory: RecommendationKind.NUTRITION,
        title: "Nutrition",
        tocTitle: "Nutrition",
        description: ({patient, conditions}) => {
            return [{
                type: "paragraph",
                text: "Nutrition is the foundation of health. It provides the building blocks for your body to function at its best.",
            }]
        },
    },
    [ReportSection.EXERCISE]: {
        code: ReportSection.EXERCISE,
        kindCategory: RecommendationKind.EXERCISE,
        title: "Movement",
        tocTitle: "Movement",
        description: ({patient, conditions}) => {
            return [{
                type: "paragraph",
                text: "Exercise is the key to a healthy body. It helps you stay fit, strong, and active.",
            }]
        },
    },
    [ReportSection.TESTING]: {      
        code: ReportSection.TESTING,
        kindCategory: RecommendationKind.TESTING,
        title: "Testing",
        tocTitle: "Testing",
        description: ({patient, conditions}) => {
            return [{
                type: "paragraph",
                text: "Testing keeps you proactive and in control of your health.",
            }]
        },
    },
    [ReportSection.MEDICATION]: {
        code: ReportSection.MEDICATION,
        kindCategory: RecommendationKind.MEDICATION,
        title: "Medication",
        tocTitle: "Medication",
        description: ({patient, conditions}) => {
            return [{
                type: "paragraph",
                text: "Medication ...",
            }]
        },
    },
    [ReportSection.LIFESTYLE]: {
        code: ReportSection.LIFESTYLE,
        kindCategory: RecommendationKind.LIFESTYLE,
        title: "Lifestyle",
        tocTitle: "Lifestyle",
        description: ({patient, conditions}) => {
            return [{
                type: "paragraph",
                text: "Lifestyle ...",
            }]
        },
    },
    [ReportSection.PROVIDER]: {
        code: ReportSection.PROVIDER,
        kindCategory: RecommendationKind.PROVIDER,
        title: "Physician Followup",
        tocTitle: "Physician Followup",
        description: ({patient, conditions}) => {
            return [{
                type: "paragraph",
                text: "Physician followup ... ",
            }]
        },
    },
        
}

export default SectionData