import StepType from "../../flow/enums/stepType.enum"
import { Flow } from "../../flow/flow.component"
import Gender from "../../../enums/gender.enum"
import { useState, useContext, useEffect, useRef } from "react"
import { UserContext } from "../../../contexts/user.context"
import { FlowType, ProductTypeCode, MembershipTypeCode, Role } from "../../../enums/index.enum";
import { ProSleepApneaTestConfirm } from "./proSleepApneaTestConfirm.component"
import { getProductTypeByCode } from '../../../services/productType.service'
import { listMemberships } from "../../../services/membership.service"
import { addProxyFlow, completeFlow } from "../../../services/flow.service"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../../services/product.service.js"
import { listPatients } from "../../../services/patient.service.js"
import MembershipHelper from "../../../helpers/membership.helper.js"
import ProductHelper from "../../../helpers/product.helper.js"

export const Step = {
  PAY: 'pay',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  DELIVERY: 'delivery',
  CONFIRM: 'confirm',
}

export const ProSleepApneaTestFlow = () => {
  const { currentUser, setCounts } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productType, setProductType] = useState()
  const [initialUser, setInitialUser] = useState()
  const [instalabMembership, setLongevityMembereship] = useState()
  const [payDescription, setPayDescription] = useState()
  const [patients, setPatients] = useState([])
  const hasCompletedFlowRef = useRef(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchPatients()
    fetchProductType()
    fetchLongevityMembership()
  }, [flow])

  useEffect(() => {
    fetchPayDescription()
  }, [productType, currentUser])

  useEffect(() => {
    fetchSkeleton()
  }, [productType, instalabMembership, payDescription, flow, initialUser, patients])


  const fetchPatients = async () => {
    if (!flow) return
    const fetchedPatients = await listPatients({
      filter: {
        firstName: { $exists: true, $ne: null },
        lastName: { $exists: true, $ne: null},
        email: { $exists: true, $ne: null }
      },  
      sort: 'firstName lastName'
    })
    setPatients(fetchedPatients)
  }

  const fetchLongevityMembership = async () => {
    if (!flow?.user) return
    const memberships = await listMemberships({
      filter: {
        patient: flow.user._id
      },
      select: 'status endAt',
      populate: {
        path: 'membershipType',
        satus: 'code'
      }
    })
    setLongevityMembereship(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY))
  }


  const fetchProductType = async () => {
    const response = await getProductTypeByCode(ProductTypeCode.WATCHPAT_ONE)
    setProductType(response)
  }

  const fetchPayDescription = () => {
    if (!productType || !currentUser) return

    let panelDescription, payDescription
    panelDescription = <>This includes the WatchPat One device and results and report on your client's sleep apnea status and sleep quality metrics.</>
     
    if (currentUser.chargePersonalCard) {
      payDescription = <>Reminder: Use your own personal card for this purchase.</>
    } else {
      payDescription = <>Reminder: Use your client's card for this purchase.</>
    }

    setPayDescription(<>{panelDescription}<br/><br/>{payDescription}</>)
  }


  const fetchSkeleton = () => {
    if (!initialUser) return

    setSkeleton({
      [Step.PATIENT]: {
        type: StepType.SINGLE_SELECT,
        multiple: false,
        dropdown: true,
        buildFlow: true,
        title: `Select patient to order ${productType?.title}.`,
        description: <>If this is a new patient, please first <a href="/provider/patients">add patient</a> to system first.</>,
        field: {
          name: 'user',
          placeholder: 'Select Patient',
          options: patients.map(patient => ({
            label: `${patient.firstName} ${patient.lastName}`,
            value: patient._id
          })),
        },
        nextStep: Step.PAY,
        onSucces: async () => {
          const response = await addProxyFlow({
            user: flow?.user,
            type: FlowType.PRO_DRAW
          })
          navigate(`/pro-flow/${FlowType.PRO_SLEEP_APNEA}/${Object.values(Step)[1]}/${response._id}`)
        },
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addProduct: true,
        productType: ProductTypeCode.WATCHPAT_ONE,
        title: (() => {

          const proPrice = ProductHelper.getProviderCost(productType, instalabMembership, currentUser);
          const originalPrice = productType?.cost

          return (currentUser?.role === Role.PROVIDER || instalabMembership) ? 
          <>Pay <span className="true-price">${proPrice}</span> <span className="original-price">${originalPrice}</span> for the {productType?.title}.</> :
          <>Pay <span className="true-price">${originalPrice}</span> for the {productType?.title}.</>;
        })(),        description: payDescription,
      },


      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your client's full name?`,
        description: `We need this for the lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return flow?.user?.firstName && flow?.user?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your client's phone number?`,
        description: `We'll only text them urgent notifications about their account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return flow?.user?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is your client's biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return flow?.user?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What is your client's date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return flow?.user?.dob
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `What's your client's delivery address?`,
        description: `We’ll send the test kit to this location.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `You're all set! 🎉`,
        description: `We'll send the sleep apnea test kit within 1 business day. Tracking number will be available in your account if you'd like to track its progress.`,
        buttonText: 'Back to Patient Profile',
        path: `/patients/${flow?.user?._id}?key=Kits`,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }
  return <>

    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      startIndex={1}
    />
  </>
}