const ProductTypeCode = Object.freeze({

  // Test Panels - standalone pages
  LONGEVITY_PANEL: 'longevity panel',  
  ATHLETE_PANEL: 'athlete panel',
  CUSTOM_PANEL: 'custom panel',
  PERFORMANCE_PANEL: 'performance panel',
  HEART_HEALTH_PANEL: 'heart health panel',
  FERTILITY_PANEL: 'fertility panel',
  TESTOSTERONE_PANEL: 'testosterone panel',
  CHOLESTEROL_BALANCE: 'cholesterol balance',
  PANEL_ADDONS: 'panel addons',

  // Consults
  CONSULT_LONGEVITY: 'consult - longevity',
  CONSULT_ATHLETE: 'consult - athlete',
  CONSULT_HEART_HEALTH: 'consult - heart health',
  CONSULTATION_CARDIOLOGY: 'consultation - cardiology',
  CONSULTATION_GASTROENTEROLOGY: 'consultation - gastroenterology',
  CONSULTATION_DERMATOLOGY: 'consultation - dermatology',
  CONSULTATION_NEUROLOGY: 'consultation - neurology',
  CONSULTATION_UROLOGY: 'consultation - urology',
  CONSULTATION_GYNECOLOGY: 'consultation - gynecology',
  CONSULTATION_ENDOCRINOLOGY: 'consultation - endocrinology',
  CONSULTATION_RHEUMATOLOGY: 'consultation - rheumatology',
  CONSULTATION_ORTHOPEDICS: 'consultation - orthopedics',
  CONSULTATION_DENTAL: 'consultation - dental',
  CONSULTATION_OPTHALMOLOGY: 'consultation - ophthalmology',
  CONSULTATION_ENT: 'consultation - ent',
  CONSULTATION_UROGYNECOLOGY: 'consultation - urogynecology',
  CONSULTATION_HEMATOLOGY: 'consultation - hematology',

  // Phlebotomy
  MOBILE_BLOOD_DRAW: 'mobile blood draw', // $99
  PRO_MOBILE_BLOOD_DRAW: 'pro mobile blood draw', // $99
  TASSO: 'tasso', // $25
  LAB_VISIT: 'lab visit', //$0 
  FINGER_PRICK: 'finger prick', // $0
  
  // Testing
  HEART_BASELINE: 'heart heatlh baseline',
  GALLERI: 'galleri test',
  PRENUVO: 'prenuvo',
  CTCALCIUM: 'ct calcium scan',
  DEXA_BODYCOMP: 'dexa - body composition',
  DEXA_BONE: 'dexa - bone density',
  VO2MAX: 'vo2max',
  FERTILITY: 'fertility',
  CLEERLY: 'cleerly',
  RMR: 'rmr',
  CARDIAC_MRI: 'cardiac mri',
  PLASMA_DONATION: 'plasma donation',
  PULMONARY_FUNCTION_TEST: 'pulmonary function test',
  BREAST_ULTRASOUND: 'breast ultrasound',

  // At-home self collection tests
  HEART_HEALTH_TEST_KIT: 'heart health - test kit',
  HEART_HEALTH_GENETIC_TEST: 'heart health genetic test',
  CHOLESTEROL_TREATMENT_TEST_KIT: 'cholesterol treatment - test kit',
  FIT_STOOL: 'fit stool test',
  LONGEVITY_GENETIC_TEST: 'longevity genetic test',
  CGM: 'cgm',
  FREESTYLE_LIBRE_3: 'freestyle libre 3',
  BP_MONITOR: 'bp monitor',
  TESTOSTERONE_PANEL_KIT: 'testosterone panel - test kit',
  MYCOTOX_PROFILE: 'mycotox profile',
  WATCHPAT_ONE: 'watchpat one',
  BIOBEAT: 'biobeat',
  TRUAGE_COMPLETE: 'truage - complete',
  TOXIC_METALS_URINE_TEST: 'toxic metals - urine test',
  DIURNAL_CORTISOL_PROFILE: 'diurnal cortisol profile',
  TOTAL_TESTOSTERONE_KIT: 'total testosterone - test kit',
  FIT_STOOL_TEST_KIT: 'fit stool test kit',
  APOE: 'apoe',
  SPERM_TEST_KIT: 'sperm test kit',
  MODY_TEST: 'mody test',
  SIBO_TEST: 'sibo test - 2 hour',
  AMBRY_CANCERNEXT_EXPANDED: 'ambry - cancernext expanded',

  // TREATMENTS
  LEQVIO: 'leqvio',
  METFORMIN: 'Metformin',
  PRALUENT: 'praluent',
  // BUNDLES
  BUNDLE_LONGEVITY: 'bundle - longevity',
  BUNDLE_ESSENTIALS: 'bundle - essentials',
  BUNDLE_ESSENTIALS_JR: 'bundle - essentials jr',

  // GIFTS
  GIFT_LONGEVITY_PANEL: 'gift - longevity panel',
  GIFT_LONGEVITY_PANEL_MOBILE_DRAW: 'gift - longevity panel + mobile draw',
  GIFT_MOBILE_BLOOD_DRAW: 'gift - mobile blood draw',
  GIFT_GALLERI: 'gift - galleri',
  GIFT_TESTOSTERONE_PANEL: 'gift - testosterone panel',
  GIFT_FERTILITY_PANEL: 'gift - fertility panel',
  GIFT_CORE_MEMBERSHIP: 'gift - core membership',
  GIFT_PREMIUM_MEMBERSHIP: 'gift - premium membership',
  GIFT_HEART_HEALTH_PANEL: 'gift - heart health panel',
  GIFT_SLEEP_APNEA_TEST: 'gift - sleep apnea test',
  GIFT_TRUAGE_COMPLETE: 'gift - truage complete',
  GIFT_CARD: 'gift card',
  GIFT_BUNDLE_LONGEVITY: 'gift - bundle - longevity',
  GIFT_BUNDLE_ESSENTIALS: 'gift - bundle - essentials',
  GIFT_BUNDLE_ESSENTIALS_JR: 'gift - bundle - essentials jr',
  GIFT_CARD_50: 'gift card $50',
  GIFT_CARD_100: 'gift card $100',
  GIFT_CARD_250: 'gift card $250',
  GIFT_CARD_500: 'gift card $500',
  GIFT_CARD_1000: 'gift card $1000',

  // MISC
  INSURANCE_COPAY: 'insurance copay',
  DEMO_PRODUCT: 'demo product',
  REFERRAL: 'referral',
  CASH: 'cash',
  HEART_HEALTH_PROGRAM: 'heart health program',

  // SERVICES WE DON'T OFFER YET
  LDCT: 'ldct', 
  COLONOSCOPY: 'colonoscopy',
  MAMMOGRAM: 'mammogram',
  PAP_SMEAR: 'pap smear',
  HPV_TEST: 'hpv test',
  DRE: 'digital rectal exam',
  SKIN_CANCER_EXAM: 'skin cancer exam',
  ENDOSCOPY: 'endoscopy',

  // SUPPLEMENTS
  SUPPLEMENT_PACK: 'supplement pack',
  BERBERINE: 'Berberine',
  RED_YEAST_RICE: 'Red Yeast Rice',
  VITAMIN_B9_400: 'Vitamin B9 400',
  VITAMIN_B9_400_B12_1000: 'Vitamin B9 400 + B12 1000',
  VITAMIN_B9_800_B12_5000: 'Vitamin B9 800 + B12 5000',
  VITAMIN_D3_1000: 'Vitamin D3 1000',
  VITAMIN_D3_5000_ATHLETE: 'Vitamin D3 5000 Athlete',
  VITAMIN_B9_1000: 'Vitamin B9 1000',
  VITAMIN_B9_1333_B12_800_ATHLETE: 'Vitamin B9 1333 + B12 800 Athlete',
  VITAMIN_B12_1000: 'Vitamin B12 1000',
  VITAMIN_B12_5000: 'Vitamin B12 5000',
  PEA_PROTEIN: 'Pea Protein',
  VISCOUS_FIBER: 'Viscous Fiber',
  OMEGA_3S: 'Omega-3s',
  IRON_SUPPLEMENT: 'Iron Supplement',

  // INACTIVE
  TEST_KIT: 'test kit',
  CALCIFEDIOL: 'Calcifediol',
  VITAMIN_B12: 'Vitamin B12',
  VITAMIN_B9_B12: 'Vitamin B9 + B12',
  CONSULT_LONGEVITY_60: 'consult - longevity - 60 minutes',
  CONSULT_WEIGHT_MANAGEMENT: 'consult - weight management',
  CONSULT_METABOLIC_HEALTH: 'consult - metabolic health',
  CONSULT_METABOLIC_HEALTH_60: 'consult - metabolic health - 60 minutes',
  CONSULT_LIPIDOLOGIST: 'consult - lipidologist',
  OMEGAQUANT_COMPLETE: 'omegaquant - complete',
  OMEGAQUANT_BASIC: 'omegaquant - basic',
  DEXA_BODYCOMP_REFERRAL: 'dexa - body composition - referral',
  DEXA_BONE_REFERRAL: 'dexa - bone density - referral',
  CTCALCIUM_REFERRAL: 'ct calcium - referral',
  HEART_HEALTH_LAB_VISIT: 'heart health - lab visit', 
  TOXIC_HAIR_TEST: 'toxic hair test',
  TRUAGE_PACE: 'truage - pace',
  DEXA_BOTH: 'dexa - body composition + bone density',
  LONGEVITY_PANEL_PSC: 'longevity panel psc',
  ELITE_PANEL: 'elite panel',
  ALLERGY_PANEL: 'allergy panel',
  ADDONS: 'addons',
  APOE_ADDON: 'apoe',
  CORTISOL: 'cortisol',
  CPEPTIDE: 'c-peptide',
  FRUCTOSAMINE: 'fructosamine',
  IODINE: 'iodine',
  NMR_LIPOPROFILE: 'nmr lipoprofile',
  OMEGA_3_ADDON: 'omega 3',
  OMEGACHECK: 'omegacheck',
  SELENIUM: 'selenium',
  TSH: 'tsh',
  THYROID_PANEL: 'thyroid panel',
  HEAVY_METALS: 'heavy metals',
  ANA_SCREEN: 'ana screen',
  BIOMETRICS: 'biometrics',
  IRON_PANEL: 'iron panel',
  IGF1: 'igf-1',
  DHEAS: 'dhea-s',
  IL6: 'il-6',
  ATHLETE_ADDON: 'athlete addon',
  PROGESTERONE: 'progesterone',
  HCG: 'hcg',
  BLOOD_TYPE: 'blood type',
  INFLAMMATION_PANEL: 'inflammation panel',
  ANEMIA_PANEL: 'anemia panel',
  VITAMIN_D_TEST: 'vitamin d test',
  VITAMIN_B12_TEST: 'vitamin b12 test',
  VITAMIN_B9_TEST: 'vitamin b9 test',
  HUMAN_GROWTH_HORMONE: 'human growth hormone',
  CMP: 'cmp test',
  TEST_MATRIX: 'testing matrx',


})

export default ProductTypeCode;