import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Select, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { 
    addAppointmentParent, 
    getAppointmentParent, 
    updateAppointmentParent 
} from '../../services/appointment.service';
import MaskedInput from "antd-mask-input";
import moment from 'moment'
import AppointmentStatus from '../../enums/appointmentStatus.enum'
import { listPhlebotomists } from "../../services/phlebotomist.service"
import { ProviderSelect } from '../providerSelect/providerSelect.component';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { listProductTypes } from '../../services/productType.service';
const { Item } = Form

export const AppointmentParentForm = ({ open, setOpen, onSuccess, appointmentParentId, select, populate }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [phlebotomists, setPhlebotomists] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {

    const fetchPhlebotomists = async () => {
      const data = await listPhlebotomists();
      setPhlebotomists(data);
    };

    const fetchProductTypes = async () => {
      const data = await listProductTypes({
        select: '_id title',
        filter: {
          code: {
            $in: [
              ProductTypeCode.ATHLETE_PANEL, 
              ProductTypeCode.LONGEVITY_PANEL,
              ProductTypeCode.CUSTOM_PANEL
            ]
          }
        }
      });

      setProductTypes(data);
    };
    fetchPhlebotomists();
    fetchProductTypes();
  }, []);

  useEffect(() => {
    if (open) {
      if (appointmentParentId) setAppointmentParentFields()
    } else {
      form.resetFields()
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const setAppointmentParentFields = async () => {
    const {
      title,
      description,
      status,
      start,
      end,
      phlebotomist,
      organizer,
      productTypes  
    } = await getAppointmentParent(appointmentParentId, {
      select: 'title description status start end phlebotomist organizer',
      populate: []
    })

    form.setFieldsValue({
      title,
      description,
      status,
      start: start ? moment(start).format("MM/DD/YYYY HH:mm Z") : null,
      end: end ? moment(end).format("MM/DD/YYYY HH:mm Z") : null,
      phlebotomist,
      organizer,
      productTypes
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {

        const values = await form.validateFields();
        // Convert times to the required format before saving
        if (values.start) {
          values.start = moment(values.start, "MM/DD/YYYY HH:mm Z").toISOString();
        }
        if (values.end) {
          values.end = moment(values.end, "MM/DD/YYYY HH:mm Z").toISOString();
        }
        const params = {
          fields: values,
          select,
          populate
        }
        const response = appointmentParentId ? await updateAppointmentParent(appointmentParentId, params) : await addAppointmentParent(params)
        if (onSuccess) {
            onSuccess(response)
        }
        message.success(appointmentParentId ? 'Appointment parent updated' : 'Appointment parent added')
        setOpen(false)
        if (!appointmentParentId) {
            form.resetFields()
      }
    } catch (err) {
        console.log(err)
      let msg = appointmentParentId ? 'Failed to update appointment parent' : 'Failed to add appointment parent'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return productTypes.length > 0 && (
    <div className="event-form">
       <Modal 
        open={open} 
        title={appointmentParentId ? 'Edit Event' : 'Add Event'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        footer={[
          <ContinueButton
            onClick={onOk}
            text={appointmentParentId ? 'Save Event' : 'Add Event'}
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >

          <Item 
            label="Title"
            name="title"
            rules={[{ 
              required: true, 
              message: 'Enter title'
            }]}
          >
            <Input placeholder="Title" />
          </Item>

          <Item 
            label="Description"
            name="description"
            rules={[{ 
              message: 'Enter description'
            }]}
          >
            <Input placeholder="Description" />
          </Item>

          <Item label="Organizer" name="organizer">
            <ProviderSelect />
          </Item>

          <Item label="Panels" name="productTypes">
            <Select
              placeholder="Select panels..."
              mode="multiple"
              options={productTypes.map(({ _id, title }) => ({key: _id, label: title, value: _id}))}
            />
          </Item>

          <Item label="Start time" name="start">
            <MaskedInput
              placeholder={"MM/DD/YYYY HH:mm Z"}
              mask="00/00/0000 00:00 -00:00"
              className="test-value-input"
            />
          </Item>

          <Item label="End time" name="end">
            <MaskedInput
              placeholder={"MM/DD/YYYY HH:mm Z"}
              mask="00/00/0000 00:00 -00:00"
              className="test-value-input"
            />
          </Item>


          <Item label="Phlebotomist" name="phlebotomist" rules={[{ message: 'Select phlebotomist' }]}>
            <Select
              placeholder="Select phlebotomist..."
              options={phlebotomists.map(phlebotomist => ({ label: `${phlebotomist.firstName} ${phlebotomist.lastName}`, value: phlebotomist._id }))}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              className="phlebotomist-select"
              showSearch
            />
          </Item>

          <Item 
          label="Status" 
          name="status"
        >
          <Select 
            placeholder="Select status..."

            options={Object.values(AppointmentStatus).map(value => {
              return {
                label: `${value}`,
                value: `${value}`
              }
            })}
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            className="status-select"
            showSearch
          />
        </Item>


        </Form>
      </Modal>
      
    </div>
  )
}