import FlowType from "../../enums/flowType.enum"
import StepType from "../flow/enums/stepType.enum"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import AthleteConsultIntro from "./athleteConsultIntro.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  // PAY: 'pay',
  NAME: 'name',
  PHONE: 'phone',
  REASON: 'reason',
  CONSULT: 'schedule',
  CONFIRM: 'confirm',
}

export const AthleteConsultFlow = () => {
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, instalabMembership, flow, hasPass, initialUser])

  useEffect(() => {
    const fetchMembershipTypes = async () => {
      const response = await listMembershipTypes({
        filter: {
          code: {
            $in: [
              MembershipTypeCode.LONGEVITY,
            ]
          }
        }
      })
      setMembershipTypes(response)
    }
    
    const fetchProductTypes = async () => {
      const response = await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.CONSULT_ATHLETE,
            ]
          }
        }
      })
      setProductTypes(response)
    }

    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return
    
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <AthleteConsultIntro footer={footer} productType={getProductType(ProductTypeCode.CONSULT_ATHLETE)} />
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `Create your account to manage your sessions.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      // [Step.PAY]: {
      //   type: StepType.PAY,
      //   nextStep: Step.NAME,
      //   addProduct: true,
      //   addAthleteMembership: true,
      //   productType: ProductTypeCode.CONSULT_LONGEVITY,
      //   // title:<>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY))}</span> for your athlete consultation.</>,
      //   title: (flow?.addAthleteMembership || instalabMembership) 
      //     ?
      //       <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.memberCost}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span> for your athlete consultation{instalabMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> 
      //     :
      //       <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span> for your athlete consultation.</>,
      //   description: `This is for a 30-minute video consultation with a athlete physician. You'll schedule your appointment in the next step.`,
      //   membershipCTA: `Save $${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost-getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.memberCost-getMembershipType(MembershipTypeCode.LONGEVITY)?.cost} today with Instalab Membership`,



      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.REASON,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or sessions.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.REASON]: {
        type: StepType.TEXTAREA,
        onNextStep: async (patient) => {
          switch (patient?.sessionPreference) {
            case ProductTypeCode.CONSULT_LONGEVITY_60:
              return Step.CONSULT_60
            default:
              return Step.CONSULT
          }
        },
        buildUser: true,
        title: `What are topics do you want to focus on during your session?`,
        description: 'This will help your physician prepare for your consultation.',
        field: {
          name: 'consultReason',
          placeholder: 'Type here ...',
        }
      },

      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule your consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in working professional athletes. It'll be a 30 minute video call where you can ask any questions.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/cp32-v2b-dsx',
      },


      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Appointment confirmed!`,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.GENDER}`,
        buttonText: 'Continue',
        description: <>You'll receive an email with a calendar invite and a Zoom link for your session. <br/><br/>If you recently made any changes to your medications/supplements or lifestyle, please follow the next steps to help us prepare for your appointment.`</>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}