import { Typography } from "antd";
import classNames from "classnames";
import { useParams, useNavigate } from "react-router-dom";
import { BlogData } from "../../../data/blog.data";
import PosthogHelper from "../../../helpers/posthog.helper";
import { useContext } from "react";
import { UserContext } from "../../../contexts/user.context";
const { Paragraph } = Typography

export const SterolTestPost = () => {
  const { blogId } = useParams()
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)

  return (
    <div className={classNames("blog-post", "sterol-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Usual Process: Guess and Adjust
        </Paragraph>
        <Paragraph className="blog-description">
          Doctors typically prescribe a standard dose of a statin, like atorvastatin (Lipitor), and check cholesterol levels after a few months. If it's working, great. If not, they may increase the dose or try another drug. This cycle continues until they find the right fit. Meanwhile, you might deal with side effects, uncertainty, and wasted time.
        </Paragraph>

        <Paragraph className="blog-description">
          Sterol testing offers a shortcut. It identifies whether your cholesterol problem is due to absorbing too much from food or making too much in your liver. With this information, your doctor can choose the right medication from the beginning.
        </Paragraph>

        <Paragraph className="blog-title">
          What Is Sterol Testing?
        </Paragraph>
        <Paragraph className="blog-description">
          Sterols are natural substances in your body that help process cholesterol. A sterol test measures them to see if your cholesterol issue comes from absorption or production.
        </Paragraph>

        <div className="blog-section">
          <Paragraph className="blog-title">
            Plant Sterols (Sitosterol & Campesterol)
          </Paragraph>
          <ul className="blog-list">
            <li>
              Come from foods like nuts, seeds, and whole grains.
            </li>
            <li>
              If levels are high, it means your body is absorbing too much cholesterol from food.
            </li>
          </ul>

          <Paragraph className="blog-title">
            Cholesterol Precursors (Lathosterol & Desmosterol)
          </Paragraph>
          <ul className="blog-list">
            <li>
              Made by your liver when it produces cholesterol.
            </li>
            <li>
              If these are high, your liver is making too much cholesterol on its own.
            </li>
          </ul>
        </div>

        <Paragraph className="blog-title">
          How This Guides Medication Choice
        </Paragraph>
        <Paragraph className="blog-description">
          Knowing whether your cholesterol comes from absorption or production helps pick the best treatment:
        </Paragraph>

        <div className="blog-section">
          <Paragraph className="blog-title">
            If You Absorb Too Much (High Sitosterol & Campesterol)
          </Paragraph>
          <ul className="blog-list">
            <li>
              <strong>Problem:</strong> Your intestines let in too much cholesterol from food.
            </li>
            <li>
              <strong>Best Medication:</strong> Ezetimibe (Zetia), which blocks cholesterol absorption.
            </li>
          </ul>

          <Paragraph className="blog-title">
            If Your Liver Makes Too Much (High Lathosterol & Desmosterol)
          </Paragraph>
          <ul className="blog-list">
            <li>
              <strong>Problem:</strong> Your body is producing too much cholesterol internally.
            </li>
            <li>
              <strong>Best Medication:</strong> Statins like rosuvastatin, which slow down cholesterol production.
            </li>
          </ul>


          <Paragraph className="blog-title">
            If Both Absorption & Production Are High
          </Paragraph>
          <ul className="blog-list">
            <li>
              <strong>Problem:</strong> Your cholesterol levels are high for both reasons.
            </li>
            <li>
              <strong>Best Treatment:</strong> A combination of a statin and ezetimibe to target both sources.
            </li>
          </ul>
        </div>

        <Paragraph className="blog-title">
          Why This Matters
        </Paragraph>
        <Paragraph className="blog-description">
          The typical approach to cholesterol treatment involves guessing, adjusting, and waiting months to see results. Sterol testing eliminates much of that uncertainty. It helps doctors personalize treatment so you start on the right medication immediately, reducing trial and error, side effects, and frustration.
        </Paragraph>
        <Paragraph className="blog-description">
          By using sterol testing, cholesterol treatment becomes more precise—helping you get the best results as quickly and effectively as possible.
        </Paragraph>

        <Paragraph className="blog-title">
          Get Professional Help with Instalab
        </Paragraph>
        <Paragraph className="blog-description">
          If you're serious about lowering your cholesterol and optimizing your heart health, our <a className="blog-link" onClick={() => {
            navigate('/heart')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: heart health program`, true)
          }}>Heart Health Program</a> at Instalab provides expert guidance to do just that. With the help of a lipid specialist, we create a personalized treatment plan tailored to your unique cholesterol profile. Sterol testing is just one of the advanced tools we use to pinpoint the most effective medication for you.
        </Paragraph>
        <Paragraph className="blog-description">
          Beyond just identifying the right medication, we handle prescription management, monitor your progress, and adjust your treatment as needed until you reach your ideal cholesterol levels. Instead of spending unnecessary time on trial and error, our program ensures you're on the most effective treatment from day one.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Nissinen, M., Miettinen, T., Gylling, H., & Miettinen, T. (2010). Applicability of non-cholesterol sterols in predicting response in cholesterol metabolism to simvastatin and fluvastatin treatment among hypercholesterolemic men.. Nutrition, metabolism, and cardiovascular diseases : NMCD, 20 5, 308-16. <a onClick={() => window.open('https://doi.org/10.1016/j.numecd.2009.04.014', '_blank')}>https://doi.org/10.1016/j.numecd.2009.04.014</a>
            </li>
            <li>
              Mackay, D., Gebauer, S., Eck, P., Baer, D., & Jones, P. (2015). Lathosterol-to-cholesterol ratio in serum predicts cholesterol-lowering response to plant sterol consumption in a dual-center, randomized, single-blind placebo-controlled trial.. The American journal of clinical nutrition, 101 3, 432-9. <a onClick={() => window.open('https://doi.org/10.3945/ajcn.114.095356', '_blank')}>https://doi.org/10.3945/ajcn.114.095356</a>
            </li>
            <li>
              Mashnafi, S., Plat, J., Mensink, R., & Baumgartner, S. (2019). Non-Cholesterol Sterol Concentrations as Biomarkers for Cholesterol Absorption and Synthesis in Different Metabolic Disorders: A Systematic Review. Nutrients, 11. <a onClick={() => window.open('https://doi.org/10.3390/nu11010124', '_blank')}>https://doi.org/10.3390/nu11010124</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};