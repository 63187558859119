import { Typography } from "antd";
import classNames from "classnames";
import { useParams, useNavigate } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import { useContext } from "react";
import { UserContext } from "../../../contexts/user.context";
import { BlogData } from "../../../data/blog.data";

const { Paragraph } = Typography

export const OzempicPost = () => {
  const { blogId } = useParams()
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)

  return (
    <div className={classNames("blog-post", "ozempic-post")}>
      <div className="blog-image-wrapper">
        <img src={BlogData.find(b => b.id === blogId).img} className="blog-image" />
      </div>

      <div className="blog-body">
        <Paragraph className="blog-title">
          What Does the Research Prove?
        </Paragraph>
        <Paragraph className="blog-description">
          The link between weight and heart health is well established: excess weight raises blood pressure, worsens cholesterol levels, and increases inflammation, all of which contribute to cardiovascular disease. But how much does Ozempic directly improve heart health?
        </Paragraph>

        <Paragraph className="blog-description">
          Over the last 10 years, research has shown that semaglutide is highly effective at reducing cardiovascular risks. Since Ozempic was initially developed to manage diabetes, early studies focused on this demographic. The <i>SUSTAIN-6 Trial (2016)</i> found that in people with type 2 diabetes, semaglutide lowered the risk of heart attacks and strokes by <b>26%</b>.
        </Paragraph>

        <Paragraph className="blog-description">
          <i>The SELECT Trial (2023)</i> expanded this research by studying overweight and obese individuals without diabetes, showing that semaglutide reduced the risk of major cardiovascular events—such as heart attacks and strokes—by <b>20%</b>. This was a groundbreaking discovery, proving that Ozempic's benefits extend beyond diabetes management.
        </Paragraph>

        <Paragraph className="blog-title">
          How Does Ozempic Benefit Heart Health?
        </Paragraph>
        <Paragraph className="blog-description">
          Ozempic's impact on heart health goes beyond weight loss. It affects several key physiological pathways that influence cardiovascular risk:
        </Paragraph>

        <ul className="blog-list">
          <li><strong>Reduced Inflammation:</strong> Chronic inflammation damages blood vessels and promotes arterial plaque buildup. When arteries become inflamed, they narrow and stiffen, increasing the likelihood of blockages that can lead to heart attacks or strokes. Ozempic has been shown to lower inflammatory markers like C-reactive protein (CRP), which helps reduce arterial damage.</li>
          <li><strong>Lower Blood Pressure:</strong> High blood pressure forces the heart to work harder, increasing the risk of heart failure and stroke. GLP-1 receptor agonists like Ozempic have been shown to reduce systolic blood pressure by an average of <b>5 mmHg</b>—a meaningful drop that can improve long-term cardiovascular outcomes.</li>
          <li><strong>Improved Cholesterol and Triglycerides:</strong> Elevated LDL cholesterol and triglycerides contribute to plaque buildup in arteries, restricting blood flow and increasing heart disease risk. Studies suggest Ozempic can lower LDL cholesterol by <b>7-10%</b> and triglycerides by <b>20%</b>, both of which are significant improvements.</li>
          <li><strong>Enhanced Insulin Sensitivity:</strong> Insulin regulates blood sugar levels. When cells become resistant to insulin—a condition known as insulin resistance—the body compensates by producing more insulin, leading to higher blood sugar levels, increased fat storage, and chronic inflammation, all of which harm the heart. Ozempic improves insulin sensitivity, meaning the body responds better to insulin, keeping blood sugar and fat accumulation in check</li>
        </ul>

        <Paragraph className="blog-title">
          Side Effects: What You Need to Know
        </Paragraph>
        <Paragraph className="blog-description">
          Like any medication, Ozempic comes with potential risks. Understanding them is key to making an informed decision. Some of the most common side effects include nausea, vomiting, diarrhea, and constipation. While these symptoms are often mild and improve over time, some people may find them disruptive.
        </Paragraph>

        <Paragraph className="blog-description">
          More serious side effects, though less common, include gastroparesis (delayed stomach emptying), pancreatitis, and a potential risk for thyroid cancer, which has been observed in animal studies but not confirmed in humans. If you have a history of medullary thyroid cancer or pancreatitis, this medication may not be suitable for you.
        </Paragraph>

        <Paragraph className="blog-description">
          <i>It's critical not to underestimate or overlook these side effects.</i> While most are not life-altering, you should fully understand your risks before starting Ozempic. Some people tolerate it well, while others find the side effects challenging to manage.
        </Paragraph>

        <Paragraph className="blog-title">
          Getting the Right Support for Ozempic and Heart Health
        </Paragraph>
        <Paragraph className="blog-description">
          Ozempic is a powerful tool for heart health, but it's not a one-size-fits-all solution. Before starting, consider whether you've explored other options like diet and exercise, how well you tolerate medications, and whether the benefits outweigh the side effects for you. The best approach to cardiovascular health is one that is sustainable, backed by evidence, and personalized to your needs. If Ozempic fits into that plan, it could be a meaningful step toward long-term heart protection.
        </Paragraph>

        <Paragraph className="blog-description">
          If you're considering Ozempic for heart health, it's important to track more than just your weight. The <a className="blog-link" onClick={() => {
            navigate('/heart')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: heart health program`, true)
          }}>Heart Health Program</a> provides a structured way to do this by allowing you to work with an Instalab physician—an expert in lipidology and longevity—to monitor your metabolic and lipid biomarkers over a four-month period while taking the medication. Regular testing ensures that you're not just losing weight but actually improving your cardiovascular health in measurable ways.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Marso, S., Bain, S., Consoli, A., et al. (2016). Semaglutide and Cardiovascular Outcomes in Patients with Type 2 Diabetes. The New England journal of medicine, 375 19, 1834-1844. <a onClick={() => window.open('https://doi.org/10.1056/NEJMOA1607141', '_blank')}>https://doi.org/10.1056/NEJMOA1607141</a>
            </li>
            <li>
              Müller, T., Finan, B., Bloom, S., et al. (2019). Glucagon-like peptide 1 (GLP-1). Molecular Metabolism, 30, 72-130. <a onClick={() => window.open('https://doi.org/10.1016/j.molmet.2019.09.010', '_blank')}>https://doi.org/10.1016/j.molmet.2019.09.010</a>
            </li>
            <li>
              Sattar, N., Lee, M., Kristensen, S., et al. (2021). Cardiovascular, mortality, and kidney outcomes with GLP-1 receptor agonists in patients with type 2 diabetes: a systematic review and meta-analysis of randomised trials. The lancet. Diabetes & endocrinology. <a onClick={() => window.open('https://doi.org/10.1016/S2213-8587(21)00203-5', '_blank')}>https://doi.org/10.1016/S2213-8587(21)00203-5</a>
            </li>
            <li>
              Sun, F., Wu, S., Wang, J., et al. (2015). Effect of glucagon-like peptide-1 receptor agonists on lipid profiles among type 2 diabetes: a systematic review and network meta-analysis. Clinical therapeutics, 37 1, 225-241.e8. <a onClick={() => window.open('https://doi.org/10.1016/j.clinthera.2014.11.008', '_blank')}>https://doi.org/10.1016/j.clinthera.2014.11.008</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};