import React, { useContext, useEffect, useState } from 'react';
import './packages.scss';
import { UserContext } from '../../contexts/user.context';
import {listProductTypes} from '../../services/productType.service';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import FlowType  from '../../enums/flowType.enum';
import { useNavigate } from 'react-router-dom'; 
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
export const Packages = () => {
  const navigate = useNavigate();

  const [productTypes, setProductTypes] = useState([]);
  const [packages, setPackages] = useState([]);
  const { currentUser } = useContext(UserContext);
  useEffect(() => {
    const fetchProductTypes = async () => {
      const productTypes = await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.BUNDLE_ESSENTIALS_JR,
              ProductTypeCode.BUNDLE_ESSENTIALS,
              ProductTypeCode.LONGEVITY_PANEL,
              ProductTypeCode.CTCALCIUM,
              ProductTypeCode.DEXA_BODYCOMP,
              ProductTypeCode.FIT_STOOL_TEST_KIT,
              ProductTypeCode.CONSULT_LONGEVITY,
              ProductTypeCode.MOBILE_BLOOD_DRAW,
              ProductTypeCode.HEART_HEALTH_PROGRAM,
            ]
          }
        }
      });
      setProductTypes(productTypes);
    };

    fetchProductTypes();
  }, []);

  useEffect(() => {

    if (!productTypes || productTypes.length === 0) return;
    const checkup = productTypes.find(productType => productType.code === ProductTypeCode.BUNDLE_ESSENTIALS_JR)
    const checkupPlus = productTypes.find(productType => productType.code === ProductTypeCode.BUNDLE_ESSENTIALS)
    const heartHealthTreatment = productTypes.find(productType => productType.code === ProductTypeCode.HEART_HEALTH_PROGRAM)

    setPackages([
      {
        title: "Instalab Checkup",
        prices: {
          standard: checkup?.cost
        },
        description: checkup?.tagline,
        includes: checkup?.productTypeCodes.map(code => getProductTypeWithInfo(code)),
        url: `/flow/${FlowType.PACKAGE}/pay?code=${ProductTypeCode.BUNDLE_ESSENTIALS_JR}`
      },
      {
        title: "Instalab Checkup Plus",
        prices: {
          standard: checkupPlus?.cost
        },
        description: checkupPlus?.tagline,
        includes: checkupPlus?.productTypeCodes.map(code => getProductTypeWithInfo(code)),
        url: `/flow/${FlowType.PACKAGE}/pay?code=${ProductTypeCode.BUNDLE_ESSENTIALS}`
      },
      {     
        title: "Heart Health Treatment",
        prices: {
          standard: heartHealthTreatment?.cost
        },
        description: heartHealthTreatment?.tagline,
        includes: [
          { title: "Personalized treatment plan", tagline: "Personalized treatment plan based on your test results" },
          { title: "Prescriptions if needed", tagline: "Choose between home delivery or pharmacy pickup of prescribed medications" },
          { title: "Heart health blood tests", tagline: "Regular monitoring of your progress every 6 weeks" },
          { title: "Unlimited check-ins with your Heart Health physician", tagline: "Continuous support throughout your treatment" }
        ],
        url: `/heart`
      }
    ])
  }, [productTypes]);


  const getProductTypeWithInfo = (code) => {
    const productType = productTypes.find(productType => productType.code === code);
    return {
      title: productType?.title,
      tagline: productType?.tagline
    };
  }

  useEffect(() => {
    document.title = `Packages | Instalab`;
  }, []);

  useEffect(() => {
    const trackPosthog = () => {
      if (currentUser) {
        PosthogHelper.track(currentUser, EventCode.PACKAGES_VIEW);
      }
    };
    
    if (currentUser) {
      trackPosthog();
    }
  }, [currentUser]);
  return (
    packages.length > 0 && <div className="packages">
      <div className="packages-header">
        <h1>Instalab Packages</h1>
        <p>Get everything you need in one click.</p> 
        {/* <p>No membership required.</p> */}
      </div>
      
      <div className="packages-container">
        {packages.map((pkg, index) => (
          <div key={index} className="package-card">
            <h2 className="package-title">{pkg.title}</h2>
            <div className="package-description">{pkg.description}</div>
            <div className="price-display">
              ${pkg.prices.standard}
            </div>
            <Button size="large" type="primary" className="book-now-btn" onClick={() => navigate(pkg.url)}>Select Package</Button>


            <div className="package-includes">
              {/* <h3>Includes:</h3> */}
              <ul>
                {pkg.includes.map((item, idx) => (
                  <li key={idx}>
                    {item.title}
                    <Tooltip title={item.tagline}>
                      <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};