import { useRef, useState, useEffect, useContext } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { completeFlow } from "../../services/flow.service"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"
import { UserContext } from "../../contexts/user.context"
import "./subscribeFlow.scss"

export const Step = {
  EMAIL: 'email',
  REASON: 'reason',
  TOPICS: 'topics',
  CONFIRM: 'confirm',
}

export const SubscribeFlow = () => {
  const [flow, setFlow] = useState()
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const navigate = useNavigate()
  const [initialUser, setInitialUser] = useState()
  const hasCompletedFlowRef = useRef(false)

  const [hasPass, setHasPass] = useState()
  const [skeleton, setSkeleton] = useState()
  
  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, initialUser, flow])

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchSkeleton = () => {
    if (!initialUser || hasPass===undefined) return
    setSkeleton({
      [Step.EMAIL]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.REASON,
        buildUser: true,
        title: `What's your email address?`,
        description: `You’ll receive a weekly email with curated research from our medical team—insightful, actionable, and designed to help you enhance your health and longevity.`,
        field: {
          name: 'email',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        },
        hiddenFields: [{
          name: 'waitlist',
          value: ['concierge'],
        }],
        skipIf: () => {
          return initialUser?.email
        }
      },
      [Step.REASON]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.TOPICS,
        buildUser: true,
        title: `What inspired you to subscribe?`,
        description: 'Select all that apply.',
        field: {
          name: 'subscribeReason',
          options: [
            `I want health advice from medical experts`,
            `I have a family history of heart disease`,
            `I want to be the healthiest version of myself`,
            `I want to perform at my best`,
            `I feel unhealthy and want to make a change`,
          ].map(option => {
            return {
              label: option,
              value: option,
            }
          })
        },
      },
      [Step.TOPICS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What topics are you interested in?`,
        description: 'Select all that apply.',
        field: {
          name: 'topicsOfInterest',
          options: [
            `Heart Health`,
            `Lab Tests`,
            `Medication`,
            `Prevention`,
            `Nutrition`,
            `Exercise`,
            `Weight Loss`,
            `Sleep`,
            `Performance`,
            `Genetics`,
          ].map(category => {
            return {
              label: category,
              value: category,
            }
          })
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You're subscribed!`,
        description: <>
          You’ll receive our weekly newsletter every Monday morning. We love sharing content that matters to our members, so if there’s a topic you’d like us to cover, just <a onClick={onChat}>let us know</a>—we’d love to write about it.
        </>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: (
          <Button
            type='primary'
            onClick={() => {
              navigate('/research')
            }}
            className="subscribe-return-btn"
          >
            Return to Research
          </Button>
        ),
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
            setCurrentUser({
              ...currentUser,
              isSubscribed: true,
            })
          }
        }
      },
    })
  }


  return (
    <div className="subscribe-flow">
      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    </div>
  )
}