import React, { useState, useEffect } from 'react';
import { Form, Button, Upload, message, Card, Space, Typography, Modal } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'; 
import { uploadFile, getFileById } from '../../../../services/file.service';
import { updateMe } from '../../../../services/user.service';
import "./insuranceUpload.scss";
import FileLink from '../../../fileLink/fileLink.component';

const { Item } = Form;
const { Title } = Typography;

// TODO: Fully abstract this to a generic upload component / combine with multiple upload which is just for orders right now

export const InsuranceUpload = ({
  onSubmit,
  form,
  isSubmitting,
  flow,
  step
}) => {
  const [uploadingFields, setUploadingFields] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});

  useEffect(() => {
    fetchUploadedFiles();
  }, []);

  const fetchUploadedFiles = async () => {
    try {
      // Get the current user data which should include the file IDs
      const fileIds = step.fields.reduce((acc, field) => {

        if (flow?.user?.[field.name]) {
          acc[field.name] = flow.user[field.name];
        }
        return acc;
      }, {});


      // Fetch file details for each uploaded file
      const filesPromises = Object.entries(fileIds).map(async ([fieldName, fileId]) => {
        try {
          const fileResponse = await getFileById(fileId); 
          return [fieldName, { _id: fileId, ...fileResponse.data }];
        } catch (error) {
          console.error(`Failed to fetch file:`, error);
          return null;
        }
      });

      const files = await Promise.all(filesPromises);
      const validFiles = Object.fromEntries(files.filter(Boolean));
      setUploadedFiles(validFiles);
    } catch (error) {
      console.error('Failed to fetch uploaded files:', error);
      message.error('Failed to load existing files');
    }
  };

  const handleFileChange = async ({ file }, field) => {
    setUploadingFields(prev => ({ ...prev, [field.name]: true }));
    try {

      const params = {
        patientId: flow?.user?._id,
        fileTypeCode: field.fileTypeCode
      }

      const uploadedResponse = await uploadFile(file, params);
      
      if (!uploadedResponse.success) {
        message.error(uploadedResponse.message);
        return;
      }

      // save the file name to patient object 
      await updateMe({ fields: { [field.name]: uploadedResponse.file._id } });

      setUploadedFiles(prev => ({
        ...prev,
        [field.name]: uploadedResponse.file
      }));
      
      message.success('File uploaded successfully');
    } catch (error) {
      console.error('Upload failed:', error);
      message.error('Upload failed');
    } finally {
      setUploadingFields(prev => ({ ...prev, [field.name]: false }));
    }
  };

  const handleRemoveFile = async (field) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this file?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await updateMe({ fields: { [field.name]: null } });
          setUploadedFiles(prev => {
            const updated = { ...prev };
            delete updated[field.name];
            return updated;
          });
          message.success('File removed successfully');
        } catch (error) {
          console.error('Remove failed:', error);
          message.error('Failed to remove file');
        }
      },
    });
  };


  return (
    <div className="upload" style={{ maxWidth: 800, margin: '0 auto' }}>
      
        <Form
          form={form}
          className="upload-form"
          layout="vertical"
          onFinish={() => form.submit()}
        >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {step.fields.map((field) => (
              <Card 
                key={field.name}
                className="patient-requisition flat" 
                title={
                  <Title level={5}>
                    {field.title}
                  </Title>
                }
              >
    
                <Item>
                    {uploadedFiles[field.name] ? (
                      <Space>
                        <FileLink fileId={uploadedFiles[field.name]?._id} showLink />
                       
                        <Button 
                          icon={<DeleteOutlined />} 
                          onClick={() => handleRemoveFile(field)}
                          danger
                          type="link"
                        >
                        </Button>
                      </Space>
                    ) : (
                      <Upload
                        beforeUpload={() => false}
                        onChange={(info) => handleFileChange(info, field)}
                        accept="image/*,.pdf"
                        multiple={false}
                        showUploadList={false}
                      >
                        <Button 
                          icon={<UploadOutlined />}
                          loading={uploadingFields[field.name]}
                        >
                          Upload File
                        </Button>
                      </Upload>
                    )}
                </Item>
              </Card>
            ))}
          </Space>

          <div style={{ marginTop: 24 }}>
            <FlowStepFooter 
              onSubmit={() => onSubmit()}
              isSubmitting={isSubmitting}
              buttonText="Continue"
            />
          </div>
        </Form>
 
    </div>
  );
};
