import { useState, useContext, useEffect, useCallback, useRef } from 'react'
import Org from '../../enums/org.enum'
import { Row, Col, Select, Layout, Tree, Typography, Button, Slider, Tooltip, Card, Empty, Table, Tag, Timeline, Affix } from 'antd'
import PosthogHelper from '../../helpers/posthog.helper'
import ReductionStatus from '../../enums/reductionStatus.enum'
import { Line } from 'react-chartjs-2'
import { UserContext } from '../../contexts/user.context'
import { ReactComponent as SpinnerIcon } from '../../assets/svg/spinner.svg'
import { listAnnotatedResults } from '../../services/result.service'
import { listAnnotatedTests } from '../../services/test.service'
import TestCode from '../../enums/testCode.enum'
import 'chartjs-adapter-date-fns'
import { listPrescriptions, getPrescriptionEffects } from '../../services/prescription.service'
import PrescriptionHelper from '../../helpers/prescription.helper'
import "./totalExposure.scss"
import moment from 'moment'
import Role from '../../enums/role.enum'
import { getPatient, listPatients, getPrescriptionStats } from '../../services/patient.service'
import annotationPlugin from 'chartjs-plugin-annotation'
import { Chart } from 'chart.js'
import { useSearchParams, useNavigate } from 'react-router-dom'
import PrescriptionType from '../../enums/prescriptionType.enum'
import { LockOutlined, ArrowDownOutlined, ArrowUpOutlined, ArrowRightOutlined, ArrowLeftOutlined, SafetyCertificateOutlined,
  CheckCircleOutlined,
  FullscreenExitOutlined,
  FieldTimeOutlined,
  TrophyOutlined,
  ExperimentOutlined,
  LineChartOutlined,
  TeamOutlined,
  CompressOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import PrescriptionStatus from '../../enums/prescriptionStatus.enum'
import CountUp from 'react-countup'
import zoomPlugin from 'chartjs-plugin-zoom'
import useWidth from '../../hooks/useWidth.hook'
Chart.register(annotationPlugin)
Chart.register(zoomPlugin)

const { Title } = Typography
const { Sider } = Layout

const sidebarWidth = 250

/*
 * Ultra-Precise LDL to ApoB Conversion
 * 
 * After analyzing the data points:
 *   LDL (mg/dL)  |  ApoB (mg/dL)  |  Ratio
 *   -------------|----------------|--------
 *      55        |     45         |  0.818
 *      70        |     55         |  0.786
 *      85        |     65         |  0.765
 *      100       |     75         |  0.750
 *      120       |     90         |  0.750
 *      140       |    105         |  0.750
 *      160       |    120         |  0.750
 *      180       |    130         |  0.722
 * 
 * We can see the ratio stabilizes around 0.75 in the middle range,
 * with slightly different ratios at the lower and upper ends.
 */
function ldlToApoB(ldl) {
  // For values below 70
  if (ldl < 70) {
    return Math.round(0.818 * ldl - 0.01 * (70 - ldl));
  }
  // For values between 70 and 160
  else if (ldl <= 160) {
    return Math.round(0.75 * ldl);
  }
  // For values above 160
  else {
    return Math.round(0.75 * ldl - 0.14 * (ldl - 160));
  }
}

// Organize medications by type and dosage

const MEDICATIONS_TREE = [
  {
    title: 'Berberine',
    key: 'berberine',
    checkable: false,
    selectable: false,
    children: [
      { title: '500 mg', key: PrescriptionType.BERBERINE_500MG },
    ]
  },
  {
    title: 'Red Yeast Rice',
    key: 'red-yeast-rice',
    checkable: false,
    selectable: false,
    children: [
      { title: '600 mg', key: PrescriptionType.RED_YEAST_RICE_600MG },
    ]
  },
  {
    title: 'Rosuvastatin',
    key: 'rosuvastatin',
    checkable: false,
    selectable: false,
    children: [
      { title: '2.5 mg', key: PrescriptionType.ROSUVASTATIN_2500MCG },
      { title: '5 mg', key: PrescriptionType.ROSUVASTATIN_5MG },
      { title: '10 mg', key: PrescriptionType.ROSUVASTATIN_10MG },
      { title: '20 mg', key: PrescriptionType.ROSUVASTATIN_20MG },
      { title: '40 mg', key: PrescriptionType.ROSUVASTATIN_40MG },
    ]
  },
  {
    title: 'Ezetimibe',
    key: 'ezetimibe',
    checkable: false,
    selectable: false,
    children: [
      { title: '10 mg', key: PrescriptionType.EZETIMIBE_10MG },
    ]
  },
  {
    title: 'Evolocumab',
    key: 'evolocumab',
    checkable: false,
    selectable: false,
    children: [
      { title: '140 mg', key: PrescriptionType.EVOLOCUMAB_140MG },
    ]
  },
  {
    title: 'Alirocumab',
    key: 'alirocumab',
    checkable: false,
    selectable: false,
    children: [
      { title: '75 mg', key: PrescriptionType.ALIROCUMAB_75MG },
      { title: '150 mg', key: PrescriptionType.ALIROCUMAB_150MG },
    ]
  },
  {
    title: 'Leqvio',
    key: 'leqvio',
    checkable: false,
    selectable: false,
    children: [
      { title: '284 mg', key: PrescriptionType.LEQVIO_284MG },
    ]
  },
  {
    title: 'Atorvastatin',
    key: 'atorvastatin',
    checkable: false,
    selectable: false,
    children: [
      { title: '10 mg', key: PrescriptionType.ATORVASTATIN_10MG },
      { title: '20 mg', key: PrescriptionType.ATORVASTATIN_20MG },
    ]
  },
  {
    title: 'Pitavastatin',
    key: 'pitavastatin',
    checkable: false,
    selectable: false,
    children: [
      { title: '1 mg', key: PrescriptionType.PITAVASTATIN_1MG },
      { title: '2 mg', key: PrescriptionType.PITAVASTATIN_2MG },
      { title: '4 mg', key: PrescriptionType.PITAVASTATIN_4MG },
    ]
  },
  {
    title: 'Bempedoic Acid',
    key: 'bempedoic-acid',
    checkable: false,
    selectable: false,
    children: [
      { title: '180 mg', key: PrescriptionType.BEMPEDOIC_ACID_180MG },
    ]
  },
];

export const TotalExposure = () => {
  const { currentUser } = useContext(UserContext)
  const isTeammate = currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER
  const navigate = useNavigate()
  const [results, setResults] = useState(null)
  const hasApoB = results?.length ? results.some(result => 
    result.values.some(value => 
      value.test === '621d2a9d5a43a99402ccce12' && value.value
    )
  ) : undefined
  const [test, setTest] = useState()
  const [filteredResults, setFilteredResults] = useState([])
  const [userDOB, setUserDOB] = useState(null)
  const chartRef = useRef(null);
  const [patients, setPatients] = useState([])
  const [isManagedPatient, setIsManagedPatient] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const width = useWidth()
  const [searchParams, setSearchParams] = useSearchParams()
  const [checkedKeys, setCheckedKeys] = useState([])
  const [cutoffAge, setCutoffAge] = useState()
  const lastUpdateRef = useRef(0);
  const currentValueRef = useRef(100);
  const [lockedKeys, setLockedKeys] = useState(new Set());
  const [prescriptionStats, setPrescriptionStats] = useState(null)
  const [prescriptionEffects, setPrescriptionEffects] = useState(null)
  const [chartGridWidth, setChartGridWidth] = useState(0);

  const [prevValues, setPrevValues] = useState({
    ldlChange: 0,
    targetLDL: 0,
    currentLDL: 0,
    requiredReduction: 0,
    reductionPercent: 0,
    firstLDL: 0,
    lastLDL: 0
  });
  const [expandedKeys, setExpandedKeys] = useState([]);
  const patientId = searchParams.get('patientId')

  const ageCutoff = useCallback((value) => {
    currentValueRef.current = value;
  }, []);

  useEffect(() => {
    document.title = `Instalab | Your LDL Exposure Over Time`

    const ageInterval = setInterval(() => {
      if (currentValueRef.current !== lastUpdateRef.current) {
        setCutoffAge(currentValueRef.current);
        lastUpdateRef.current = currentValueRef.current;
      }
    }, 1000);

    return () => {
      clearInterval(ageInterval);
    };
  }, [])

  useEffect(() => {
    if (hasApoB !== null) {
      fetchPrescriptionEffects()
    }
  }, [hasApoB])

  useEffect(() => {
    if (!chartRef.current) return;
    
    const resizeObserver = new ResizeObserver(entries => {
      const chart = chartRef.current;
      if (!chart) return;
      
      // Get the actual chart area width
      const chartArea = chart.chartArea;
      if (chartArea) {
        setChartGridWidth(chartArea.right - chartArea.left);
      }
    });
  
    // Observe the chart canvas
    if (chartRef.current.canvas) {
      resizeObserver.observe(chartRef.current.canvas);
    }
  
    return () => resizeObserver.disconnect();
  }, [chartRef.current]);

  useEffect(() => {
    if (currentUser) {
      fetchResultData()

      const fetchUserData = async () => {
        const userData = isTeammate && patientId
          ? await getPatient(patientId)
          : currentUser
        console.log('userData', userData)
        setUserDOB(moment(userData.dob, 'MM/DD/YYYY').toDate())
      }
      fetchUserData()

      const fetchCurrentPrescriptions = async () => {
        if (!patientId) return;
        
        try {
          const params = {
            select: '_id status type',
            filter: {
              patient: patientId || currentUser._id,
              status: { $in: [PrescriptionStatus.SENT, PrescriptionStatus.PENDING] } // Only get active prescriptions
            }
          };
          
          const prescriptions = await listPrescriptions(params);
          
          // Get prescription types from active prescriptions
          const activePrescriptionTypes = new Set(
            prescriptions
              .filter(p => p.type)
              .map(p => p.type)
          );
          
          // Update checked and locked keys
          setCheckedKeys(activePrescriptionTypes)
          setLockedKeys(activePrescriptionTypes);
  
          const defaultKeys = prescriptions.map(({ type }) => {
            const label = Object.values(MEDICATIONS_TREE).find(({ key }) => type.toLowerCase().replace(/ /g, '-').includes(key))?.key
            return label
          })
          setExpandedKeys([...new Set(defaultKeys)]);
        } catch (error) {
          console.error('Error fetching prescriptions:', error);
        }
      };
      fetchCurrentPrescriptions();
    }
  }, [currentUser, patientId])


  useEffect(() => {
    const hasPatient = currentUser?.role === Role.PATIENT || (currentUser && hasApoB !== undefined)
    if (hasPatient) { 
      fetchTestData()
      fetchPrescriptionStats()
    }
  }, [currentUser, patientId, hasApoB])

  useEffect(() => {
    if (!test || !results?.length) return

    const validResults = results?.filter(result => {
      return result.values.some(value => value.test === test._id && value.value)
    }).sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt));
    if (!validResults?.length) return
  
    const filteredByAge = validResults.filter((result, index) => {
      if (index === 0) return true;
      if (!cutoffAge || !userDOB) return true;
      
      const age = moment(result.collectedAt).diff(moment(userDOB), 'years', true);
      return Math.floor(age) <= cutoffAge;
    });

    setFilteredResults(filteredByAge);

  }, [test, results, cutoffAge, userDOB]);

  useEffect(() => {
    const fetchPatients = async () => {
      if (isTeammate) {
        try {
          const fetchedPatients = await listPatients({
            select: 'dob _id firstName lastName email',
            filter: {
              org: Org.INSTALAB,
              firstName: {
                $exists: true
              },
              lastName: {
                $exists: true
              },
              email: {
                $exists: true
              }
            }
          });
          setPatients(fetchedPatients);

          // If we have a patientId in URL but no selectedPatient, set it
          if (patientId && !selectedPatient) {
            setSelectedPatient(patientId);
          }
        } catch (error) {
          console.error('Error fetching patients:', error)
        }
      }
    }
    fetchPatients()
  }, [currentUser, searchParams, selectedPatient])

  useEffect(() => {
    if (userDOB && filteredResults.length) {
      // Temporarily clear checkedKeys to get baseline calculation
      const savedCheckedKeys = [...checkedKeys];
      setCheckedKeys([]);

      // Restore checkedKeys
      setCheckedKeys(savedCheckedKeys);
    }
  }, [userDOB, filteredResults]);

  const fetchPrescriptionStats = async () => {
    const { stats, isManaged } = await getPrescriptionStats(patientId, { hasApoB })
    setIsManagedPatient(isManaged)
    setPrescriptionStats(stats)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  }

  // Convert static riskLevels to a function
  const getRiskLevels = (hasApoB) => {
    const baseLDLThresholds = [
      { threshold: 5000, risk: 1 },
      { threshold: 6250, risk: 2 },
      { threshold: 7500, risk: 4 },
      { threshold: 8750, risk: 8 },
      { threshold: 10000, risk: 16 },
    ];

    if (!hasApoB) {
      return baseLDLThresholds.sort((a, b) => a.threshold - b.threshold);
    }

    // Convert LDL thresholds to ApoB thresholds using the conversion function
    return baseLDLThresholds.map(level => ({
      threshold: ldlToApoB(level.threshold),
      risk: level.risk
    })).sort((a, b) => a.threshold - b.threshold);
  };

  const fetchTestData = async () => {
    if (!hasApoB) return
    const fetchedTests = await listAnnotatedTests()
    setTest(fetchedTests.find(test => test.code === (hasApoB ? TestCode.APO_B : TestCode.DIRECT_LDL)))
  }
  
  const fetchResultData = async () => {
    const response = await listAnnotatedResults({ 
      patient: searchParams.get('patientId') 
    })
    setResults(response)
  }

  // Add state variable
  const [selectedEffects, setSelectedEffects] = useState([]);
  const [totalReduction, setTotalReduction] = useState(1);

  // Add useEffect to compute and update selectedEffects
  useEffect(() => {
    if (!prescriptionEffects) return;

    const calculateSelectedEffects = () => {
      const checkedMeds = [...checkedKeys].filter(key => !lockedKeys.has(key));
      
      // First try to find combination matches
      for (let i = 0; i < prescriptionEffects.length; i++) {
        const effect = prescriptionEffects[i];
        if (effect.prescriptionTypes.length > 1) { // This is a combination
          // Check if all medications in this combination are selected
          const isMatch = effect.prescriptionTypes.every(type => 
            checkedMeds.includes(type)
          );
          
          if (isMatch) {
            // Remove these medications from further consideration
            effect.prescriptionTypes.forEach(type => {
              const index = checkedMeds.indexOf(type);
              if (index > -1) {
                checkedMeds.splice(index, 1);
              }
            });
            
            // Return array with combination effect plus any remaining individual medications
            return [
              effect,
              ...checkedMeds.map(key => 
                prescriptionEffects.find(effect => 
                  effect.prescriptionTypes.length === 1 && 
                  effect.prescriptionTypes[0] === key
                )
              ).filter(Boolean)
            ];
          }
        }
      }
      
      // If no combinations found, fall back to individual medications
      return checkedMeds
        .map(key => 
          prescriptionEffects.find(effect => 
            effect.prescriptionTypes.length === 1 && 
            effect.prescriptionTypes[0] === key
          )
        )
        .filter(Boolean);
    };
    const effects = calculateSelectedEffects()
    setSelectedEffects(effects);
    setTotalReduction(effects.reduce((total, effect) => {
      return total * (1 - effect.reduction);
    }, 1));
  }, [checkedKeys, lockedKeys, prescriptionEffects]); // Dependencies that affect the calculation

  const interpolateYValue = (targetDate) => {
    // Early validation
    if (!filteredResults?.length || !test?._id) {
      return null;
    }

    // Get valid test results and sort them chronologically
    const validResults = filteredResults
      .filter(result => {
        const value = result.values.find(v => v.test === test._id)?.value;
        return value !== null && value !== undefined;
      })
      .map(result => ({
        x: new Date(result.collectedAt).getTime(),
        y: Math.round(Number(result.values.find(v => v.test === test._id).value)) // Round here
      }))
      .sort((a, b) => a.x - b.x);

    if (!validResults) {
      return null;
    }

    const targetTime = moment(targetDate).startOf('day').toDate().getTime();

    // Handle edge cases
    if (targetTime <= validResults[0].x) {
      return validResults[0].y;
    }
    if (targetTime >= validResults[validResults.length - 1].x) {
      return validResults[validResults.length - 1].y;
    }

    // Find surrounding points
    for (let i = 0; i < validResults.length - 1; i++) {
      const point1 = validResults[i];
      const point2 = validResults[i + 1];
      
      if (targetTime >= point1.x && targetTime <= point2.x) {
        // Linear interpolation with consistent rounding
        const timeFraction = (targetTime - point1.x) / (point2.x - point1.x);
        const interpolatedValue = point1.y + (point2.y - point1.y) * timeFraction;
        
        // Use consistent rounding method
        return Math.round(interpolatedValue * 100) / 100; // Round to 2 decimal places
      }
    }

    return null;
  };

  const getHistoricalProjections = () => {
    if (!userDOB || !filteredResults.length) return []

    const firstResult = filteredResults[0]
    const firstResultDate = new Date(firstResult.collectedAt)
    const firstLDLValue = firstResult.values.find(v => v.test === test?._id)?.value

    const projectedPoints = []
    const startLDL = 40 // Starting LDL at birth
    
    // Calculate years between birth and first result
    const yearsBetween = moment(firstResultDate).diff(moment(userDOB), 'years', true)
    
    // Calculate rate of change per year
    const ldlChangePerYear = (firstLDLValue - startLDL) / yearsBetween

    // Generate yearly points from birth to first result
    for (let year = 0; year <= yearsBetween; year++) {
      const pointDate = moment(userDOB).add(year, 'years').toDate()
      const projectedLDL = startLDL + (ldlChangePerYear * year)
      
      projectedPoints.push({
        x: pointDate,
        y: Number(projectedLDL.toFixed(1))
      })
    }

    return projectedPoints
  }

  const fetchPrescriptionEffects = async () => {
    const fetchedEffects = await getPrescriptionEffects({ hasApoB })
    setPrescriptionEffects(fetchedEffects)
  }

  const getRiskLevel = (mgYears) => {
    if (mgYears < getRiskLevels(hasApoB)[0].threshold) return '<1';
    const risk = getRiskLevels(hasApoB)
      .filter(level => mgYears >= level.threshold)
      .reduce((highest, current) => 
        current.threshold > highest.threshold ? current : highest, 
        { threshold: 0, risk: 0 }
      );
    return risk ? risk.risk : '<1';
  };

  const getCumulativeExposure = (targetDate) => {
    if (!userDOB || !filteredResults.length) return 0;
    if (moment(targetDate).isSameOrBefore(userDOB)) return 0;

    // Get all points in chronological order, ensuring LDL values are numbers
    const allPoints = [
      // Birth point
      { x: userDOB, y: 0 },
      // Historical projections
      ...getHistoricalProjections(),
      // Actual readings - convert to number
      ...filteredResults.map(result => ({
        x: new Date(result.collectedAt),
        y: Number(result.values.find(v => v.test === test?._id)?.value)
      })),
      // Future projections
      ...getFutureProjections()
    ].sort((a, b) => a.x - b.x)
     .filter(point => moment(point.x).isSameOrBefore(targetDate));

    let totalMgYears = 0;

    // Calculate mg-years between each consecutive pair of points
    for (let i = 0; i < allPoints.length - 1; i++) {
      const point1 = allPoints[i];
      const point2 = allPoints[i + 1];
      
      // Time period in years between these points (precise)
      const yearsPeriod = moment(point2.x).diff(moment(point1.x), 'years', true);
      
      // Ensure we're working with numbers
      const ldl1 = Number(point1.y);
      const ldl2 = Number(point2.y);
      
      let periodMgYears;
      if (ldl1 === ldl2) {
        periodMgYears = yearsPeriod * ldl1;
      } else {
        periodMgYears = yearsPeriod * (ldl1 + ldl2) / 2;
      }
      
      totalMgYears += periodMgYears;
    }

    return Math.round(totalMgYears);
  };

  const getLastValidResult = () => {
    if (!filteredResults.length) return null;
    if (!cutoffAge || !userDOB) return filteredResults[filteredResults.length - 1];
  
    // Filter results by age and get the last one
    const validResults = filteredResults.filter((result, index) => {
      if (index === 0) return true; // Always include first result
      
      const age = moment(result.collectedAt).diff(moment(userDOB), 'years', true);
      return Math.floor(age) <= cutoffAge;
    });
  
    return validResults[validResults.length - 1];
  };

  const getFutureProjections = () => {
    if (!filteredResults.length || !userDOB) return [];

    const lastResult = getLastValidResult();
    const currentDate = moment();
    const lastLDLValue = Number(lastResult.values.find(v => v.test === test?._id)?.value || 0);

    if (selectedEffects.length === 0) {
      const points = [];
      
      // First point at current date
      points.push({
        x: new Date(lastResult.collectedAt),
        y: lastLDLValue
      });

      // Calculate next birthday after last result
      const nextBirthday = moment(userDOB).add(
        Math.floor(moment(lastResult.collectedAt).diff(moment(userDOB), 'years', true)),
        'years'
      );
      
      // Add next birthday point if it's after current date
      if (nextBirthday.isAfter(currentDate)) {
        points.push({
          x: nextBirthday.toDate(),
          y: lastLDLValue
        });
      }

      // Generate remaining yearly points
      let pointDate = moment(nextBirthday);
      const endDate = moment(userDOB).add(101, 'years');
      
      while (pointDate.isBefore(endDate)) {
        pointDate.add(1, 'year');
        if (pointDate.isAfter(endDate)) break;
        
        points.push({
          x: pointDate.toDate(),
          y: lastLDLValue
        });
      }
      
      return points;
    }

    const finalLDL = Math.round(lastLDLValue * totalReduction);
    const maxTimeToEffect = Math.max(...selectedEffects.map(effect => effect.timeToEffect));
    const effectDate = moment(currentDate).add(maxTimeToEffect, 'weeks');
    
    const points = [];
    let pointDate = moment(currentDate);
    const endDate = moment(userDOB).add(102, 'years');

    // Add initial point at current date
    points.push({
      x: pointDate.toDate(),
      y: lastLDLValue,
      isSelectedEffect: true
    });

    // Add reduction period points if there is a reduction period
    if (maxTimeToEffect > 0) {
      while (pointDate.isBefore(effectDate)) {
        pointDate.add(maxTimeToEffect / 4, 'weeks');
        if (pointDate.isAfter(effectDate)) break;
        
        const progressToEffect = pointDate.diff(currentDate) / effectDate.diff(currentDate);
        const currentReduction = progressToEffect * (1 - totalReduction);
        points.push({
          x: pointDate.toDate(),
          y: Math.round(lastLDLValue * (1 - currentReduction))
        });
      }

      // Add point at full effect only if it's different from the last point
      const lastPoint = points[points.length - 1];
      if (!lastPoint || lastPoint.y !== finalLDL) {
        points.push({
          x: effectDate.toDate(),
          y: finalLDL
        });
      }
    }

    // Calculate next birthday after effect date
    const nextBirthdayAfterEffect = moment(userDOB).add(
      Math.ceil(moment(effectDate).diff(moment(userDOB), 'years', true)),
      'years'
    );

    // Only add next birthday point if it's significantly different from the last point
    const lastPoint = points[points.length - 1];
    if (lastPoint && 
        nextBirthdayAfterEffect.diff(moment(lastPoint.x), 'days') > 7 && 
        nextBirthdayAfterEffect.isBefore(endDate)) {
      points.push({
        x: nextBirthdayAfterEffect.toDate(),
        y: finalLDL
      });
    }

    // Generate yearly points after next birthday
    pointDate = moment(nextBirthdayAfterEffect);
    while (pointDate.isBefore(endDate)) {
      pointDate.add(1, 'year');
      if (pointDate.isAfter(endDate)) break;
      
      points.push({
        x: pointDate.toDate(),
        y: finalLDL
      });
    }

    return points;
  };

  const getAllProjections = () => {
    if (!userDOB || !filteredResults.length) return [];
    
    const firstResult = filteredResults[0];
    const firstResultValue = firstResult.values.find(v => v.test === test?._id)?.value;
    
    // Get the last valid result based on age cutoff
    const lastValidResult = getLastValidResult();
    const lastValidValue = lastValidResult.values.find(v => v.test === test?._id)?.value;
    
    const pastProjections = getHistoricalProjections();
    const futureProjections = getFutureProjections();

    return [
      ...pastProjections,
      { x: new Date(firstResult.collectedAt), y: firstResultValue }, // First point
      { x: new Date(firstResult.collectedAt), y: null }, // Break the line
      { x: new Date(lastValidResult.collectedAt), y: null }, // Break at last valid point
      { x: new Date(lastValidResult.collectedAt), y: lastValidValue }, // Connect to last valid point
      ...futureProjections
    ];
  }

  // Update the getRiskColor function to include darker shades for higher risks
  const getRiskColor = (risk) => {
    const riskShades = {
      1: 'rgba(237, 110, 127, 0.1)',
      2: 'rgba(237, 110, 127, 0.3)',
      4: 'rgba(237, 110, 127, 0.5)',
      8: 'rgba(237, 110, 127, 0.7)',
      16: 'rgba(237, 110, 127, 0.9)',
    };
    
    return riskShades[risk] || 'rgba(237, 110, 127, 0.1)';
  };

  // Update the getRiskThresholdDates to include next threshold
  const getRiskThresholdDates = () => {
    if (!userDOB || !filteredResults.length) return [];
    
    // Pre-calculate exposures for all points
    const allPoints = [
      { x: userDOB, y: 0 },
      ...getHistoricalProjections(),
      ...filteredResults.map(result => ({
        x: new Date(result.collectedAt),
        y: Number(result.values.find(v => v.test === test?._id)?.value)
      })),
      ...getFutureProjections()
    ].sort((a, b) => a.x - b.x);

    // Calculate cumulative exposure once for each point
    const pointsWithExposure = allPoints.map(point => ({
      ...point,
      exposure: getCumulativeExposure(point.x)
    }));

    return getRiskLevels(hasApoB).map((level, index) => {
      // Find threshold crossing
      for (let i = 0; i < pointsWithExposure.length - 1; i++) {
        const point1 = pointsWithExposure[i];
        const point2 = pointsWithExposure[i + 1];
        
        if (point1.exposure < level.threshold && point2.exposure >= level.threshold) {
          const ratio = (level.threshold - point1.exposure) / (point2.exposure - point1.exposure);
          const date = new Date(
            point1.x.getTime() + 
            ratio * (point2.x.getTime() - point1.x.getTime())
          );
          
          // Find next threshold
          const nextLevel = getRiskLevels(hasApoB)[index + 1];
          let endDate = null;
          
          if (nextLevel) {
            const nextCrossing = pointsWithExposure.find((p, idx) => 
              idx > i && p.exposure >= nextLevel.threshold
            );
            if (nextCrossing) {
              const prevPoint = pointsWithExposure[pointsWithExposure.indexOf(nextCrossing) - 1];
              const nextRatio = (nextLevel.threshold - prevPoint.exposure) / 
                              (nextCrossing.exposure - prevPoint.exposure);
              endDate = new Date(
                prevPoint.x.getTime() + 
                nextRatio * (nextCrossing.x.getTime() - prevPoint.x.getTime())
              );
            }
          }
          
          return {
            date,
            endDate: endDate || allPoints[allPoints.length - 1].x,
            risk: level.risk,
            exposure: level.threshold,
            color: getRiskColor(level.risk)
          };
        }
      }
      return null;
    }).filter(Boolean);
  };

  // Add this helper function to calculate the maximum y value
  const getMaxYValue = () => {
    if (!filteredResults.length) return 300; // fallback default

    // Get all y values from actual results and projections
    const allPoints = [
      ...getHistoricalProjections(),
      ...filteredResults.map(result => ({
        y: Number(result.values.find(v => v.test === test._id)?.value)
      })),
      ...getFutureProjections()
    ];

    // Find the maximum y value, add 20% padding, and round up to nearest 50
    const maxY = Math.max(...allPoints.map(point => point.y));
    return Math.ceil(maxY / 50) * 50;
  };

  // Add this helper function to check if a date is before the cutoff
  const isBeforeCutoff = (date) => {
    if (!cutoffAge || !userDOB) return false;
    const age = moment(date).diff(moment(userDOB), 'years', true);
    return Math.floor(age) > Math.floor(cutoffAge)
  };


  // Initialize chartData with a default empty state
  const [chartData, setChartData] = useState({
    datasets: []
  });

  // Create a useEffect to update chartData when dependencies are available
  useEffect(() => {
    // Only update chartData when all required values are available
    if (!filteredResults?.length || !test?._id || !userDOB) {
      return;
    }

    setChartData({
      datasets: [
        {
          label: `${test.name} (Real)`,
          data: filteredResults
            .filter((result, index) => {
              if (index === 0) return true;
              if (!cutoffAge || !userDOB) return true;
              
              const resultAge = moment(result.collectedAt).diff(moment(userDOB), 'years', true);
              return Math.floor(resultAge) <= cutoffAge;
            })
            .map(result => ({
              x: new Date(result.collectedAt),
              y: result.values.find(v => v.test === test._id)?.value || null
            })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          pointRadius: 4,
          borderWidth: 2,
          tension: 0,
          order: -2, // Change this to a negative number to ensure it's always on top
          z: 100 // Increase z-index
        },
        {
          label: `${test?.name} (Projected)`,
          data: userDOB ? getAllProjections() : [],
          backgroundColor: 'rgb(255, 255, 255)',
          borderColor: 'rgb(53, 162, 235)',  // Solid blue color
          borderDash: [3, 3],
          pointRadius: 0,  // No points on the projected line
          borderWidth: 2,  // Slightly thicker line
          tension: 0,
          spanGaps: false,     // No fill
          order: 2,        // Try higher number for top layer
          z: 3,            // Additional z-index property
        },
        {
          label: `Prescriptions (${ReductionStatus.UNDETERMINED})`,
          data: Object.entries(prescriptionStats || {})
            .filter(([_, stats]) => {
              return stats.reduction?.status === ReductionStatus.UNDETERMINED &&
              !isBeforeCutoff(stats.deliveredAt)
            })
            .map(([medicationTitle, stats]) => {
              const interpolatedValue = interpolateYValue(new Date(stats.deliveredAt));
              if (interpolatedValue === null) return null;
              return {
                x: new Date(stats.deliveredAt),
                y: interpolatedValue,
                medicationTitle,
                isPrescription: true,
                stats,
              };
            })
            .filter(Boolean),
          pointBackgroundColor: 'rgba(140, 140, 140, 0.3)',
          pointBorderColor: '#8c8c8c', 
          pointBorderWidth: 2,
          pointRadius: 6, // Updated to match Selected
          pointStyle: 'rectRot',
          showLine: false,
        },
        {
          label: `Prescriptions (${ReductionStatus.OUTPERFORMED})`,
          data: Object.entries(prescriptionStats || {})
            .filter(([_, stats]) => {
              return stats.reduction?.status === ReductionStatus.OUTPERFORMED &&
              !isBeforeCutoff(stats.deliveredAt)
            })
            .map(([medicationTitle, stats]) => {
              const interpolatedValue = interpolateYValue(new Date(stats.deliveredAt));
              if (interpolatedValue === null) return null;
              return {
                x: new Date(stats.deliveredAt),
                y: interpolatedValue,
                medicationTitle,
                isPrescription: true,
                stats,
              };
            })
            .filter(Boolean),
          pointBackgroundColor: 'rgba(139, 92, 246, 0.3)',
          pointBorderColor: '#7c3aed',
          pointBorderWidth: 2,
          pointRadius: 6, // Updated to match Selected
          pointStyle: 'rectRot',
          showLine: false,
        },
        {
          label: `Prescriptions (${ReductionStatus.EFFECTIVE})`,
          data: Object.entries(prescriptionStats || {})
            .filter(([_, stats]) => 
              stats.reduction?.status === ReductionStatus.EFFECTIVE &&
              !isBeforeCutoff(stats.deliveredAt)
            )
            .map(([medicationTitle, stats]) => {
              const interpolatedValue = interpolateYValue(new Date(stats.deliveredAt));
              if (interpolatedValue === null) return null;
              return {
                x: new Date(stats.deliveredAt),
                y: interpolatedValue,
                medicationTitle,
                isPrescription: true,
                stats,
              };
            })
            .filter(Boolean),
          pointBackgroundColor: 'rgba(12, 163, 127, 0.3)',
          pointBorderColor: '#0ca37f',
          pointBorderWidth: 2,
          pointRadius: 6, // Updated to match Selected
          pointStyle: 'rectRot',
          showLine: false,
        },
        {
          label: `Prescriptions (${ReductionStatus.UNDERPERFORMED})`,
          data: Object.entries(prescriptionStats || {})
            .filter(([_, stats]) => {
              return stats.reduction?.status === ReductionStatus.UNDERPERFORMED && !isBeforeCutoff(stats.deliveredAt)
            })
            .map(([medicationTitle, stats]) => {
              const interpolatedValue = interpolateYValue(new Date(stats.deliveredAt));
              if (interpolatedValue === null) return null;
              return {
                x: new Date(stats.deliveredAt),
                y: interpolatedValue,
                medicationTitle,
                isPrescription: true,
                stats,
              };
            })
            .filter(Boolean),
          pointBackgroundColor: 'rgba(250, 173, 20, 0.3)',
          pointBorderColor: '#faad14',
          pointBorderWidth: 2,
          pointRadius: 6, // Updated to match Selected
          pointStyle: 'rectRot',
          showLine: false,
        },
        {
          label: `Prescriptions (${ReductionStatus.INEFFECTIVE})`,
          data: Object.entries(prescriptionStats || {})
            .filter(([_, stats]) => 
              stats.reduction?.status === ReductionStatus.INEFFECTIVE &&
              !isBeforeCutoff(stats.deliveredAt)
            )
            .map(([medicationTitle, stats]) => {
              const interpolatedValue = interpolateYValue(new Date(stats.deliveredAt));
              if (interpolatedValue === null) return null;
              return {
                x: new Date(stats.deliveredAt),
                y: interpolatedValue,
                medicationTitle,
                isPrescription: true,
                stats,
              };
            })
            .filter(Boolean),
          pointBackgroundColor: 'rgba(237, 110, 127, 0.3)',
          pointBorderColor: '#ed6e7f',
          pointBorderWidth: 2,
          pointRadius: 6, // Updated to match Selected
          pointStyle: 'rectRot',
          showLine: false,
        },
        {
          label: `Prescriptions (Paused)`,
          data: Object.entries(prescriptionStats || {})
            .filter(([_, stats]) => stats.pausedPrescriptions?.length > 0)
            .reduce((acc, [medicationTitle, stats]) => {
              let pausedData = stats.pausedPrescriptions.map(prescription => {
                const pausedAt = PrescriptionHelper.getPausedAt(prescription)
                const interpolatedValue = interpolateYValue(new Date(pausedAt));
                return {
                  x: new Date(pausedAt),
                  y: interpolatedValue,
                  prescription,
                  medicationTitle,
                  isPrescription: true,
                  stats,
                };
              })

              return [
                ...pausedData,
                ...acc
              ]
            }, [])
            .filter(Boolean),
          pointBackgroundColor: 'rgba(250, 219, 20, 0.3)',  // Light yellow background
          pointBorderColor: '#fad714',                       // Yellow border
          pointBorderWidth: 2,
          pointRadius: 6,
          pointStyle: 'rectRot',
          showLine: false,
        },
        // Only show risk levels that are reached
        ...getRiskLevels(hasApoB)
          .filter(level => {
            // Get the threshold dates to check if this risk level is reached
            const thresholdDates = getRiskThresholdDates();
            return thresholdDates.some(t => t.risk === level.risk);
          })
          .map((level, index) => ({
            label: `${level.risk}% Risk of Heart Attack`,
            data: [],
            backgroundColor: getRiskColor(level.risk, 0.1),
            borderColor: getRiskColor(level.risk),
            borderWidth: 2,
            fill: true,
            showLine: false,
            hidden: false,
            order: index + 3
          })),
        // Add this new dataset before the risk level datasets
        {
          label: 'Prescriptions (Selected)',
          data: userDOB ? getFutureProjections()
            .filter(point => point.isSelectedEffect)
            .map(point => ({
              x: point.x,
              y: point.y,
              isSelectedEffect: true
            })) : [],
          pointBackgroundColor: 'rgba(24, 144, 255, 0.2)', // Light bright blue background
          pointBorderColor: '#1890ff', // Bright blue border
          pointBorderWidth: 2,
          pointRadius: 6,
          pointStyle: 'rectRot',
          showLine: false,
          order: 0,
        },
      ]
    });
  }, [filteredResults, test, userDOB, cutoffAge, prescriptionStats, hasApoB, selectedEffects]);

  const options = {
    clip: true,
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: width < 768 ? 1 : 1.5, // Adjust aspect ratio for mobile
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    plugins: {
      legend: {
        position: width < 768 ? 'bottom' : 'right', // Change position based on screen width
        align: width < 768 ? 'start' : 'center', // Center on desktop, start-aligned on mobile
        padding: width < 768 ? { top: 10 } : { top: 50 }, // Adjust padding based on position
        onClick: null, // Disable legend item clicking
        labels: {
          boxWidth: 13,
          padding: width < 768 ? 10 : 15, // Reduce padding on mobile
          font: {
            size: width < 768 ? 10 : 12 // Smaller font on mobile
          },
          filter: (legendItem, data) => {
            // Hide legend items for prescription datasets with no data
            if (legendItem.text.startsWith('Prescriptions (')) {
              const dataset = data.datasets.find(d => d.label === legendItem.text);
              return dataset.data.length > 0;
            }
            return true;
          },
          generateLabels: (chart) => {
            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
            
            return labels.map(label => {
              const dataset = chart.data.datasets[label.datasetIndex];
              
              // Match colors for prescription datasets
              if (dataset.label.startsWith('Prescriptions (')) {
                return {
                  ...label,
                  fillStyle: dataset.pointBackgroundColor,
                  strokeStyle: dataset.pointBorderColor
                };
              }
              
              return label;
            });
          }
        }
      },
      tooltip: {
        titleFont: {
          family: '"Hack", monospace', // Added monospace fallback
          size: 12
        },
        bodyFont: {
          family: '"Hack", monospace', // Added monospace fallback
          size: 12
        },
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].raw.x);
            const age = Math.floor(moment(date).diff(moment(userDOB), 'years', true));
            
            return `${date.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })} (Age ${age})\n`;
          },
          label: function(context) {
            const dataPoint = context.raw;
    
            // Skip if this is a hidden point or the connecting point
            if (!dataPoint.y) return null;
            
            // Skip the connecting point at the last valid result
            const lastValidResult = getLastValidResult();
            if (dataPoint.x.getTime() === new Date(lastValidResult.collectedAt).getTime() && context.dataset.label.includes('Projected')) {
              return null;
            }

            // Handle prescription delivery points and selected effects first
            if (dataPoint.isPrescription) {
              const stats = dataPoint.stats;

              if (context.dataset.label.includes('Paused')) {
                return [
                  ` Paused Medication: ${dataPoint.prescription.type}  `,
                ];
              }
              
              // Calculate actual reduction percentage
              const projectedReduction = (stats.reduction.projected * 100).toFixed(1);
              
              let tooltipLines = [
                ` Medication:         ${dataPoint.medicationTitle}  `,
                ` Status:             ${stats.reduction.status}  `,
              ];

              if (stats.baseline?.value) {
                tooltipLines.push(` Expected Reduction: ${Math.round(stats.baseline.value)} mg/dL → ${Math.round(stats.baseline.value * (1 - stats.reduction.projected))} mg/dL  (${projectedReduction}%)  `)
                if (stats.followup?.value) {
                  const actualReduction = ((stats.baseline.value - stats.followup.value) / stats.baseline.value * 100).toFixed(1)
                  tooltipLines.push(` Actual Reduction:   ${Math.round(stats.baseline.value)} mg/dL → ${Math.round(stats.followup.value)} mg/dL  (${actualReduction}%)  `,)
                }
              } else {
                tooltipLines.push(` Expected Reduction: ${projectedReduction}% `)
              }

              

              tooltipLines.push(``);

              // Add note if prescription is paused
              if (stats.pausedPrescriptions?.length) {
                let pausedMsg = '* This medication was paused.'
                if (stats.pausedPrescriptions.length > 1) {
                  pausedMsg = `* ${stats.pausedPrescriptions.map(({ type }) => type).join(' and ')} were paused.`
                }
                tooltipLines.push(pausedMsg);
              } else if (stats.reduction.notes) {
                tooltipLines.push(stats.reduction.notes)
              } else if (!stats.baseline?.value) {
                tooltipLines.push(`* Missing baseline ${test?.name} value.`)
              }

              return tooltipLines;
            }
            
            // Handle selected effects points
            if (dataPoint.isSelectedEffect) {
              let tooltipLines = [
                ` Selected Medication:`,
                ...selectedEffects.reduce((acc, effect) => {
                  acc = [
                    ...acc,
                    ...effect.prescriptionTypes
                  ]
                  return acc
                }, []).map(prescriptionType => 
                  `  • ${prescriptionType}`
                ),
                ``,
                ` Current Value:${selectedEffects?.length > 1 ? '       ' : '   '}${Math.round(dataPoint.y)} mg/dL  `,
                ` Projected Value:${selectedEffects?.length > 1 ? '     ' : ' '}${Math.round(dataPoint.y * totalReduction)} mg/dL  `,
              ];

              // Add combined reduction info if there are effects
              if (selectedEffects.length > 0) {
                const totalReduction = selectedEffects.reduce((total, effect) => {
                  return total * (1 - effect.reduction);
                }, 1);
                const reductionPercent = ((1 - totalReduction) * 100).toFixed(1);
                tooltipLines.push(
                  ` ${selectedEffects.length > 1 ? 'Combined ' : ''}Reduction:${selectedEffects.length > 1 ? ' ' : '      '} ${reductionPercent}%  `, 
                  ` Time to Effect:${selectedEffects.length > 1 ? '      ' : '  '}${Math.max(...selectedEffects.map(e => e.timeToEffect))} weeks  `
                );
              }

              tooltipLines.push(``);
              return tooltipLines;
            }
            
            // For regular points, determine if it's measured or estimated
            let prefix = 'Measured';
            if (context.dataset.label.includes('Projected')) {
              const date = new Date(dataPoint.x);
              const firstResultDate = filteredResults[0]?.collectedAt;
              const lastValidDate = lastValidResult?.collectedAt;
              
              // If this point is from historical projection and overlaps with first result,
              // don't show the tooltip
              if (date.getTime() === new Date(firstResultDate).getTime()) {
                return null;
              }
              
              if (date < new Date(firstResultDate)) {
                prefix = 'Estimated';
              } else if (date > new Date(lastValidDate)) {
                prefix = 'Projected';
              }
            }

            const mgYears = getCumulativeExposure(dataPoint.x);
            const risk = getRiskLevel(mgYears);

            const firstItemLabel = `${prefix} ${test?.name}`
            const padding = ' '.repeat(17 - Math.max(1, firstItemLabel.length))
            
            return [
              ` ${firstItemLabel}:${padding}${' '.repeat(Math.max(1, 8 - prefix.length))}${dataPoint.y} ${test?.unit}`,
              ` Total Exposure:    ${Math.round(mgYears).toLocaleString()} mg-years`,
              ` Heart Attack Risk: ${risk}%`,
              ``
            ];
          },
          footer: function(context) {
            const isProjected = context[0].dataset.label === 'Historical Projection';
            return isProjected ? 'This is a projected value based on typical LDL progression from birth.' : '';
          },
          // Add this filter function to the tooltip options
          filter: function(tooltipItem) {
            // Get the dataset index and datapoint index
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.dataIndex;
            
            // Get the current point's x value (date)
            const currentPoint = tooltipItem.raw;
            
            // If this is from the projected dataset (historical or future)
            if (datasetIndex === 1) { // Assuming index 1 is your projected dataset
              // Find any real data points at the same x value
              const realDataset = tooltipItem.chart.data.datasets[0]; // Real data is dataset 0
              const hasOverlappingRealPoint = realDataset.data.some(point => 
                point.x && currentPoint.x && 
                new Date(point.x).getTime() === new Date(currentPoint.x).getTime()
              );
              
              // Filter out projected points that overlap with real data
              return !hasOverlappingRealPoint;
            }
            
            return true; // Show tooltip for all other datasets
          }
        }
      },
      annotation: {
        drawTime: 'beforeDatasetsDraw',
        annotations: [
          // Box annotations (unchanged)
          ...getRiskThresholdDates().map(threshold => ({
            type: 'box',
            xMin: threshold.date,
            xMax: threshold.endDate,
            yMin: 0,
            yMax: getMaxYValue() * 1.1, // Extend boxes to reach the top of chart
            backgroundColor: getRiskColor(threshold.risk, 0.1),
            borderWidth: 0,
            order: 1,
            z: 1
          })),
          // Bracket annotations
          ...getRiskThresholdDates().flatMap((threshold, index, array) => {
            const bracketColor = '#ff4d4f';
            const centerX = moment(threshold.date).add(moment(threshold.endDate).diff(threshold.date) / 2, 'milliseconds').toDate();
            const maxY = getMaxYValue();
            const baseY = maxY * 1.08; // Lower base position to 110% of maxY
            
            // Define consistent spacing as percentages of maxY
            const spacing = {
              labelOffset: maxY * 0.07,    // Label position above base
              verticalHeight: maxY * 0.02, // Height of side brackets
              centerHeight: maxY * 0.03,   // Height of center line
              bracketGap: maxY * 0.005     // Gap between components
            };

            return [
              // Label
              {
                type: 'label',
                xValue: centerX,
                yValue: baseY + spacing.labelOffset,
                content: `${threshold.risk}%`,
                color: '#000000',
                font: {
                  size: 12,
                  weight: 'bold'
                }
              },
              // Left vertical line
              {
                type: 'line',
                xMin: threshold.date,
                xMax: threshold.date,
                yMin: baseY,
                yMax: baseY + spacing.verticalHeight,
                borderColor: bracketColor,
                borderWidth: 2,
                borderDash: [3,3]
              },
              // Right vertical line
              {
                type: 'line',
                xMin: threshold.endDate,
                xMax: threshold.endDate,
                yMin: baseY,
                yMax: baseY + spacing.verticalHeight,
                borderColor: bracketColor,
                borderWidth: 2,
                borderDash: [3,3]
              },
              // Horizontal connecting line
              {
                type: 'line',
                xMin: threshold.date,
                xMax: threshold.endDate,
                yMin: baseY + spacing.verticalHeight,
                yMax: baseY + spacing.verticalHeight,
                borderColor: bracketColor,
                borderWidth: 2,
                borderDash: [3,3]
              },
              // Center vertical line
              {
                type: 'line',
                xMin: centerX,
                xMax: centerX,
                yMin: baseY + spacing.verticalHeight + spacing.bracketGap,
                yMax: baseY + spacing.verticalHeight + spacing.centerHeight,
                borderColor: bracketColor,
                borderWidth: 2,
                borderDash: [3,3]
              }
            ];
          })
        ]
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
          overScaleMode: 'x',
          scaleMode: 'x',
          overScroll: false,
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: 'ctrl', // Require ctrl key for wheel zoom
          },
          pinch: {
            enabled: true,
            overScaleMode: 'x',
          },
          mode: 'x',
          drag: {
            enabled: false // Disable drag-to-zoom
          }
        },
        limits: {
          x: {
            min: userDOB ? new Date(userDOB).getTime() : 'original',
            max: userDOB ? moment(userDOB).add(100, 'years').valueOf() : 'original',
            minRange: 1000 * 60 * 60 * 24 * 30 // Minimum 30 days range
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'year',
          displayFormats: {
            year: 'yyyy'
          }
        },
        title: {
          display: true,
          text: 'Age'
        },
        min: userDOB,
        max: moment(userDOB).add(100, 'years').valueOf(),
        ticks: {
          source: 'data', // Force Chart.js to use our exact values
          maxRotation: 0,
          autoSkip: false,
          callback: function(value) {
            const age = moment(value).diff(moment(userDOB), 'years', true);
            return age%10 === 0 && age >= 0 && age <= 100 ? age : '';
          },
          // Generate ticks at exact birthdays
          values: Array.from({ length: 21 }, (_, i) => i * 5)
            .concat([100])
            .map(age => {
              const exactDate = moment(userDOB).add(age, 'years').startOf('day');
              return exactDate.valueOf();
            })
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          tickLength: 10,
          color: (context) => {
            const age = moment(context.tick.value).diff(moment(userDOB), 'years', true);
            return age % 5 === 0 ? 'rgba(0, 0, 0, 0.1)' : 'transparent';
          },
          borderDash: [3, 3],
          lineWidth: 0.5
        }
      },
      y: {
        beginAtZero: true,
        max: getMaxYValue() * 1.2, // Increase max scale to make room
        title: {
          display: true,
          text: `${test?.name} (${test?.unit})`
        },
        ticks: {
          callback: function(value, index, values) {
            // Don't show the last tick label
            if (index === values.length - 1) return '';
            return value.toFixed(0);
          }
        },
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [3, 3],
          lineWidth: 0.5
        }
      }
    },
  }

  const handleDosageSelection = (newChecked) => {
    // Find the parent medication of this dosage
    const parent = MEDICATIONS_TREE.find(med => 
      med.children?.some(child => child.key === newChecked)
    );
  
    if (parent) {
      // Check if the new selection is part of a combination therapy
      const combinationEffect = prescriptionEffects?.find(effect => 
        effect.prescriptionTypes.length > 1 && 
        effect.prescriptionTypes.includes(newChecked)
      );
  
      if (combinationEffect) {
        // If it's part of a combination, add all prescription types
        const newCheckedKeys = new Set([
          ...checkedKeys.filter(key => 
            !parent.children.some(child => child.key === key)
          ),
          ...combinationEffect.prescriptionTypes
        ]);
        setCheckedKeys([...newCheckedKeys]);
      } else {
        // Original single medication logic
        const filteredChecked = checkedKeys.filter(key => 
          !parent.children.some(child => child.key === key)
        );
        setCheckedKeys([...filteredChecked, newChecked]);
      }
    }
  };

  // Add these helper functions
  const getStatsData = () => {
    if (!filteredResults.length || !userDOB) return null;

    const firstResult = filteredResults[0];
    const lastResult = getLastValidResult();
    const firstLDL = firstResult.values.find(v => v.test === test?._id)?.value;
    let lastLDL = lastResult.values.find(v => v.test === test?._id)?.value;

    // Apply medication effects to lastLDL
    const selectedEffects = [...checkedKeys]
      .filter(key => !lockedKeys.has(key)) // Filter out locked keys
      .map(key => {
        // Find effect(s) that include this prescription type
        return prescriptionEffects?.find(effect => 
          effect.prescriptionTypes.every(type => type === key)
        );
      })
      .filter(Boolean);



    const totalReduction = selectedEffects.reduce((total, effect) => {
      return total * (1 - effect.reduction);
    }, 1);

    // Adjust lastLDL based on selected medications
    const projectedLDL = Math.round(lastLDL * totalReduction);

    // Calculate ages and exposures using projected LDL
    const firstAge = moment(firstResult.collectedAt).diff(moment(userDOB), 'years', true);
    const lastAge = moment(lastResult.collectedAt).diff(moment(userDOB), 'years', true);
    const firstExposure = getCumulativeExposure(new Date(firstResult.collectedAt));
    const lastExposure = getCumulativeExposure(new Date(lastResult.collectedAt));

    // Calculate pushback for each threshold using projected LDL
    const thresholdPushbacks = getRiskLevels(hasApoB).map(level => {
      // Check if threshold was already passed at first result
      if (firstExposure >= level.threshold) {
        // Calculate backwards to find when threshold was crossed
        const excessExposure = firstExposure - level.threshold;
        const yearsBeforeFirst = excessExposure / firstLDL;
        const ageAtThresholdFirst = firstAge - yearsBeforeFirst;

        return {
          risk: level.risk,
          years: 0, // No pushback possible as threshold was already crossed
          originalAge: Math.ceil(ageAtThresholdFirst),
          newAge: Math.ceil(ageAtThresholdFirst), // Same as original since already crossed
          exposureThreshold: level.threshold
        };
      }

      // Original calculation for thresholds not yet crossed
      const remainingExposureAtFirst = level.threshold - firstExposure;
      const yearsToThresholdAtFirst = remainingExposureAtFirst / firstLDL;
      const ageAtThresholdFirst = firstAge + yearsToThresholdAtFirst;

      const remainingExposureAtLast = level.threshold - lastExposure;
      const yearsToThresholdAtLast = remainingExposureAtLast / projectedLDL;
      const ageAtThresholdLast = lastAge + yearsToThresholdAtLast;

      return {
        risk: level.risk,
        years: Math.ceil(ageAtThresholdLast) - Math.ceil(ageAtThresholdFirst),
        originalAge: Math.ceil(ageAtThresholdFirst),
        newAge: Math.ceil(ageAtThresholdLast),
        exposureThreshold: level.threshold
      };
    });

    // Calculate required LDL for <1% risk through age 100
    const yearsRemaining = 100 - lastAge;
    let maxAllowedExposure = getRiskLevels(hasApoB)[0].threshold; // Start with 1% risk threshold
    let remainingAllowedExposure = maxAllowedExposure - lastExposure;
    let requiredLDL = Math.round(remainingAllowedExposure / yearsRemaining);
    let riskThresholdUsed = 1;

    // If initial calculation shows negative remaining exposure, try higher risk thresholds
    if (remainingAllowedExposure < 0) {
      // Get all risk thresholds
      const riskLevels = getRiskLevels(hasApoB);
      
      // Try each threshold until finding one that works or running out of options
      for (let i = 1; i < riskLevels.length; i++) {
        maxAllowedExposure = riskLevels[i].threshold;
        remainingAllowedExposure = maxAllowedExposure - lastExposure;
        
        if (remainingAllowedExposure >= 0) {
          requiredLDL = Math.round(remainingAllowedExposure / yearsRemaining);
          riskThresholdUsed = riskLevels[i].risk;
          break;
        }
      }
    }

    // Calculate required reduction from projected LDL
    let requiredReduction = projectedLDL - requiredLDL;
    let requiredReductionPercent = ((requiredReduction / projectedLDL) * 100).toFixed(1);

    return {
      ldlChange: {
        first: firstLDL,
        last: projectedLDL, // Use projected LDL
        percentChange: ((projectedLDL - firstLDL) / firstLDL * 100).toFixed(1)
      },
      targetLDL: requiredLDL,
      riskThreshold: riskThresholdUsed,
      requiredReduction: {
        absolute: Math.round(requiredReduction),
        percent: requiredReductionPercent,
        current: projectedLDL, // Use projected LDL
        target: requiredLDL
      },
      thresholdPushbacks
    };
  };

  const getRiskSeverity = (risk) => {
    const severityMap = {
      1: "Low Risk Zone",
      2: "Early Warning Zone",
      4: "Danger Zone",
      8: "Critical Zone",
      16: "Severe Danger Zone",
    };
    return severityMap[risk] || "Unknown";
  };

  // Update the table columns for better readability
  const columns = [
    {
      title: 'Risk Category',
      key: 'riskLevel',
      render: (_, record) => (
        <div>
          <div style={{ 
            fontWeight: 'bold',
            color: record.risk >= 4 ? 'rgb(237, 110, 127)' : 'inherit'
          }}>
            {getRiskSeverity(record.risk)}
          </div>
          <div style={{ 
            fontSize: '12px', 
            color: 'rgba(0,0,0,0.65)'
          }}>
            {`${record.risk}% chance of heart attack`}
          </div>
        </div>
      ),
      width: '30%'
    },
    {
      title: 'Your Progress',
      key: 'impact',
      render: (_, record) => {
        return (
          <div>
            <span style={{ 
              color: record.years > 0 ? '#0ca37f' : record.years < 0 ? 'rgb(237, 110, 127)' : 'inherit',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}>
              {record.years > 0 ? (
                <>
                  <ArrowRightOutlined />
                  Pushed back by {Math.abs(record.years)} {Math.abs(record.years) === 1 ? 'year' : 'years'}
                </>
              ) : record.years < 0 ? (
                <>
                  <ArrowLeftOutlined />
                  Advanced by {Math.abs(record.years)} {Math.abs(record.years) === 1 ? 'year' : 'years'}
                </>
              ) : (
                'Unchanged'
              )}
            </span>
            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.45)' }}>
              {record.years > 0 
                ? `Delayed from age ${record.originalAge} to ${record.newAge}`
                : record.years < 0
                  ? `Risk moved closer: from age ${record.originalAge} to ${record.newAge}`
                  : `Risk remains at age ${record.originalAge}`
              }
            </div>
          </div>
        );
      },
      width: '40%'
    },
    {
      title: 'Lifetime Exposure',
      key: 'threshold',
      render: (_, record) => {
        // Format the exposure value to be more readable
        const formatExposure = (value) => {
          return value?.toLocaleString();
        };

        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>
              {`${formatExposure(record.exposureThreshold)} mg-years`}
            </div>
            <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.65)' }}>
              Total {hasApoB ? 'ApoB' : 'cholesterol'} burden
            </div>
          </div>
        );
      },
      width: '30%'
    }
  ];

  // Update previous values when stats change
  useEffect(() => {
    if (getStatsData()) {
      setPrevValues({
        ldlChange: Number(getStatsData().ldlChange.percentChange),
        targetLDL: getStatsData().targetLDL,
        currentLDL: getStatsData().ldlChange.last,
        requiredReduction: getStatsData().requiredReduction.absolute,
        reductionPercent: Number(getStatsData().requiredReduction.percent),
        firstLDL: getStatsData().ldlChange.first,
        lastLDL: getStatsData().ldlChange.last
      });
    }
  }, [cutoffAge]); // Only update when cutoffAge changes

  const focusOnResults = () => {
    const chart = chartRef.current;
    if (!chart || !filteredResults.length) return;
  
    const firstResult = filteredResults[0];
    const lastResult = getLastValidResult();
    
    // Get all prescription dates
    const prescriptionDates = Object.values(prescriptionStats || {})
      .map(stats => new Date(stats.deliveredAt));
    
    // Find the earliest date between first result and earliest prescription
    const startDate = prescriptionDates.length > 0 
      ? new Date(Math.min(
          new Date(firstResult.collectedAt).getTime(),
          Math.min(...prescriptionDates.map(d => d.getTime()))
        ))
      : new Date(firstResult.collectedAt);
  
    // Calculate the end date based on selected effects
    let endDate = new Date(lastResult.collectedAt);
    if (selectedEffects.length > 0) {
      // Find the maximum time to effect among selected medications
      const maxTimeToEffect = Math.max(...selectedEffects.map(effect => effect.timeToEffect));
      // Add the weeks to the last result date, plus an additional 4 weeks to show the stabilized value
      endDate = moment(lastResult.collectedAt)
        .add(maxTimeToEffect + 4, 'weeks') // Add 4 extra weeks to show stabilized value
        .toDate();
  
      // Find the next birthday after the effect date for better context
      const effectDate = moment(lastResult.collectedAt).add(maxTimeToEffect, 'weeks');
      const nextBirthdayAfterEffect = moment(userDOB).add(
        Math.ceil(effectDate.diff(moment(userDOB), 'years', true)),
        'years'
      )
  
      // If the next birthday is within 12 weeks of the effect date, extend to include it
      if (nextBirthdayAfterEffect.diff(effectDate, 'weeks') <= 12) {
        endDate = nextBirthdayAfterEffect.toDate();
      }
    }
  
    // Also consider the latest prescription date
    if (prescriptionDates.length > 0) {
      const latestPrescriptionDate = new Date(Math.max(...prescriptionDates.map(d => d.getTime())));
      endDate = new Date(Math.max(endDate.getTime(), latestPrescriptionDate.getTime()));
    }
    
    // Add 10% padding on each side
    const timeRange = endDate - startDate;
    const padding = timeRange * 0.1;
    
    chart.zoomScale('x', {
      min: startDate.getTime() - padding,
      max: endDate.getTime() + padding
    });
  };

  const resetZoom = () => {
    const chart = chartRef.current;
    if (!chart || !userDOB) return;
    
    chart.zoomScale('x', {
      min: new Date(userDOB).getTime(),
      max: moment(userDOB).add(100, 'years').valueOf()
    });
  };

  const RiskTimeline = ({ data }) => (
    <Timeline className="risk-timeline">
      {data.map((record) => (
        <Timeline.Item
          key={record.risk}
          dot={
            <div className="risk-badge">
              {record.risk}%
            </div>
          }
        >
          <div className="risk-card">
            <div className="risk-zone">
              {getRiskSeverity(record.risk)}
              <div className="risk-percentage">
                {record.risk}% lifetime heart attack risk
              </div>
            </div>

            <div className="risk-impact">
              <span style={{ 
                color: record.years > 0 ? '#0ca37f' : record.years < 0 ? 'rgb(237, 110, 127)' : 'inherit',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}>
                {record.years > 0 ? (
                  <>
                    <ArrowRightOutlined />
                    Pushed back from age {record.originalAge} to {record.newAge} ({Math.abs(record.years)} {Math.abs(record.years) === 1 ? 'year' : 'years'})
                  </>
                ) : record.years < 0 ? (
                  <>
                    <ArrowLeftOutlined />
                    Advanced from age {record.originalAge} to {record.newAge} ({Math.abs(record.years)} {Math.abs(record.years) === 1 ? 'year' : 'years'})
                  </>
                ) : (
                  'Unchanged'
                )}
              </span>
            </div>
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );

  const getImpactMessage = () => {
    const statsData = getStatsData()
    if (!statsData?.thresholdPushbacks || !statsData?.riskThreshold) return null;
    
    const riskData = statsData.thresholdPushbacks.find(t => t.risk === statsData.riskThreshold);
    if (!riskData) return null;

    // Calculate years pushed back
    const yearsPushedBack = riskData.years;
    const targetAge = riskData.newAge;

    if (yearsPushedBack > 0) {
      if (targetAge >= 100) {
        return `You've delayed reaching a ${statsData.riskThreshold}% heart attack risk beyond age 100! This is thanks to lowering your ${hasApoB ? 'ApoB' : 'LDL'} to ${statsData.ldlChange.last} mg/dL.`;
      }
      return `You've delayed reaching a ${statsData.riskThreshold}% heart attack risk by ${yearsPushedBack} ${yearsPushedBack === 1 ? 'year' : 'years'} since your first test. By further reducing your ${hasApoB ? 'ApoB' : 'LDL'} to ${statsData.targetLDL} mg/dL, you can push that past age 100.`;
    } else if (yearsPushedBack < 0) {
      return `Your ${statsData.riskThreshold}% heart attack risk has moved closer by ${Math.abs(yearsPushedBack)} ${Math.abs(yearsPushedBack) === 1 ? 'year' : 'years'}. Lowering your ${hasApoB ? 'ApoB' : 'LDL'} to ${statsData.targetLDL} mg/dL can help push this past age 100.`;
    }
    return `You'll reach ${statsData.riskThreshold}% heart attack risk by age ${targetAge}. By lowering your ${hasApoB ? 'ApoB' : 'LDL'} to ${statsData.targetLDL} mg/dL, you can push that past age 100.`;
  };

  // Add this helper function near the top of the component
  const getCurrentAge = () => {
    if (!userDOB) return 0;
    return moment().diff(moment(userDOB), 'years');
  };

  return (
    <Layout>
      {isTeammate && (
        <Affix>
          <Sider 
            width={sidebarWidth} 
            theme="light"
            style={{ 
              padding: '0px 20px 20px 20px',
              borderRight: '1px solid #f0f0f0',
              display: 'flex',
              flexDirection: 'column',
              height: '100vh'
            }}
          >
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <Title level={5} style={{ marginBottom: '20px' }}>Interventions</Title>
              <Tree
                className="medication-tree"
                checkable
                checkStrictly
                selectable={true}
                onExpand={(newExpandedKeys, info) => {
                  setExpandedKeys(newExpandedKeys);
                  
                  if (info.expanded && info.node.children) {
                    // Expanding: select lowest dosage if none selected
                    const hasSelectedChild = info.node.children.some(child => 
                      checkedKeys.includes(child.key)
                    );
                    if (!hasSelectedChild) {
                      const lowestDosageKey = info.node.children[0].key;
                      if (!lockedKeys.has(lowestDosageKey)) {
                        handleDosageSelection(lowestDosageKey);
                      }
                    }
                  } else if (!info.expanded && info.node.children) {
                    // Collapsing: uncheck all unlocked children
                    const childKeys = info.node.children.map(child => child.key);
                    const newCheckedKeys = checkedKeys.filter(key => 
                      !childKeys.includes(key) || lockedKeys.has(key)
                    );
                    setCheckedKeys(newCheckedKeys);
                  }
                }}
                checkedKeys={checkedKeys}
                expandedKeys={expandedKeys}
                titleRender={(nodeData) => (
                  <span 
                    onClick={(e) => {
                      if (nodeData.children) {
                        e.stopPropagation();
                        const isExpanding = !expandedKeys.includes(nodeData.key);
                        const newExpandedKeys = isExpanding
                          ? [...expandedKeys, nodeData.key]
                          : expandedKeys.filter(k => k !== nodeData.key);
                        setExpandedKeys(newExpandedKeys);
                        
                        if (isExpanding) {
                          // Expanding: select lowest dosage if none selected
                          const hasSelectedChild = nodeData.children.some(child => 
                            checkedKeys.includes(child.key)
                          );
                          if (!hasSelectedChild) {
                            const lowestDosageKey = nodeData.children[0].key;
                            if (!lockedKeys.has(lowestDosageKey)) {
                              handleDosageSelection(lowestDosageKey);
                            }
                          }
                        } else {
                          // Collapsing: uncheck all unlocked children
                          const childKeys = nodeData.children.map(child => child.key);
                          const newCheckedKeys = checkedKeys.filter(key => 
                            !childKeys.includes(key) || lockedKeys.has(key)
                          );
                          setCheckedKeys(newCheckedKeys);
                        }
                      } else {
                        // Handle dosage selection (unchanged)
                        e.stopPropagation();
                        if (!lockedKeys.has(nodeData.key)) {
                          const isCurrentlyChecked = checkedKeys.includes(nodeData.key);
                          if (!isCurrentlyChecked) {
                            handleDosageSelection(nodeData.key);
                          } else {
                            setCheckedKeys(checkedKeys.filter(key => key !== nodeData.key));
                          }
                        }
                      }
                    }}
                    style={{ cursor: lockedKeys.has(nodeData.key) ? 'not-allowed' : 'pointer' }}
                  >
                    {nodeData.title}
                  </span>
                )}
                onCheck={({ checked }, info) => {
                  // Prevent unchecking locked items
                  if (lockedKeys.has(info.node.key) && !checked.includes(info.node.key)) {
                    return;
                  }

                  // Find the newly checked key
                  const newChecked = checked.find(key => !checkedKeys.includes(key));
                  if (newChecked) {
                    handleDosageSelection(newChecked);
                  } else {
                    // Filter out any attempts to uncheck locked keys
                    const filteredChecked = checked.filter(key => 
                      !lockedKeys.has(key) || checked.includes(key));
                    setCheckedKeys(filteredChecked);
                  }
                }}
                treeData={MEDICATIONS_TREE.map(medication => {
                  // Check if any child of this medication is locked
                  const hasLockedChild = medication.children?.some(child => lockedKeys.has(child.key));
                  
                  return {
                    ...medication,
                    children: medication.children?.map(child => ({
                      ...child,
                      // Disable if this item is locked OR if any sibling is locked
                      disabled: hasLockedChild || lockedKeys.has(child.key),
                      title: (
                        <Tooltip title={lockedKeys.has(child.key) ? 'This medication is locked, because you are taking it already.' : ''}>
                          {child.title}
                          {lockedKeys.has(child.key) && (
                            <LockOutlined style={{ marginLeft: 8, color: '#999' }} />
                          )}
                        </Tooltip>
                      )
                    }))
                  };
                })}
                style={{ marginBottom: '24px' }}
              />
            </div>
            
            <div style={{ 
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: sidebarWidth,
              backgroundColor: '#fff', // match your sidebar background
              zIndex: 10
            }}>
              <Select
                className="sidebar-patient-select"
                value={searchParams.get('patientId') || undefined}
                onChange={(value) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  if (value) {
                    newSearchParams.set('patientId', value);
                  } else {
                    newSearchParams.delete('patientId');
                  }
                  setSearchParams(newSearchParams);
                }}
                placeholder="Select Patient"
                showSearch
                filterOption={(input, option) => {
                  const patient = patients.find(p => p._id === option.value);
                  if (!patient) return false;
                  
                  const fullName = `${patient.firstName}${patient.lastName}`
                    .toLowerCase()
                    .replace(/[^a-z]/g, ''); // Only keep letters
                  const email = patient.email
                    .toLowerCase()
                    .replace(/[^a-z]/g, ''); // Only keep letters
                  const searchTerm = input
                    .toLowerCase()
                    .replace(/[^a-z]/g, ''); // Only keep letters
                  
                  return fullName.includes(searchTerm) || email.includes(searchTerm);
                }}
              >
                {patients
                  ?.sort((a, b) => {
                    const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
                    const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                  })
                  .map(patient => (
                    <Select.Option 
                      key={patient._id} 
                      value={patient._id}
                    >
                      <span style={{ fontWeight: 500, fontSize: 12 }}>
                        {patient.firstName} {patient.lastName}
                      </span>
                      <span style={{ 
                        color: '#8c8c8c',
                        fontSize: '12px',
                        marginLeft: '8px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}>
                        {patient.email}
                      </span>
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
          </Sider>
        </Affix>
      )}
      <Layout style={{ padding: '24px' }}>
        <div className="total-exposure" style={{ position: 'relative' }}>
          {(!chartData || hasApoB === null || !getStatsData()) ? (
            <div className="preloader">
              <SpinnerIcon />
            </div>
          ) : <>
            <Row style={{ marginBottom: 24 }}>
              <Col span={24}>
                <Title level={2} style={{ marginBottom: 8, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  Your Total {test?.name} Exposure
                  <Tooltip 
                    title={
                      <div style={{ maxWidth: 300 }}>
                        <p><strong>Why Total Exposure Matters</strong></p>
                        <ul style={{ paddingLeft: 16, marginBottom: 8 }}>
                          <li>{hasApoB ? 'ApoB-carrying' : 'LDL'} particles gradually accumulate in your arteries over time, like compound interest</li>
                          <li>Lifetime exposure to {hasApoB ? 'ApoB' : 'LDL cholesterol'}, not just your current levels, determines your cardiovascular risk</li>
                          <li>Earlier intervention has a bigger impact on reducing lifetime risk</li>
                        </ul>
                        <p style={{ marginBottom: 0 }}>This approach helps you understand your true cardiovascular risk and the long-term impact of your treatment decisions.</p>
                        <br/>
                      </div>
                    }
                    placement="right"
                    overlayStyle={{ maxWidth: 400 }}
                  >
                    <InfoCircleOutlined style={{ fontSize: 20, color: '#8c8c8c', cursor: 'pointer' }} />
                  </Tooltip>
                </Title>
                <Typography.Text style={{ fontSize: 16 }}>
                  {getStatsData() ? getImpactMessage() : 'Track how your treatment plan affects your long-term heart attack risk.'}
                </Typography.Text>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div className="ldl-chart" style={{ 
                  height: width < 768 ? '400px' : '500px',
                  position: 'relative' 
                }}>
                  {/* Add a container for the controls that's fixed to the right */}
                  <div className="chart-controls" style={{ 
                    position: 'absolute',
                    top: 46,
                    left: 15,
                    bottom: 0,
                    width: '48px', // Fixed width for controls column
                    pointerEvents: 'none', // Allow clicking through to chart
                    display: width < 768 ? 'none' : 'block', // Hide on mobile
                  }}>
                    <div style={{ 
                      position: 'sticky',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      background: 'rgba(255, 255, 255, 0.9)',
                      padding: '8px',
                      borderRadius: '5px 0 0 5px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                      marginRight: '8px',
                      pointerEvents: 'auto', // Re-enable clicking for controls
                    }}>
                      <Tooltip title="Focus on your test results" placement="left">
                        <Button 
                          icon={<CompressOutlined />} 
                          onClick={focusOnResults}
                          size="small"
                        />
                      </Tooltip>
                      <Tooltip title="Show your complete lifetime timeline" placement="left">
                        <Button 
                          icon={<FullscreenExitOutlined />} 
                          onClick={resetZoom}
                          size="small"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  
                  <Line 
                    data={chartData} 
                    options={options}
                    ref={chartRef}
                  />
                </div>
                <div className="slider-container" style={{ 
                  display: width < 768 ? 'none' : 'block',
                  marginLeft: chartRef.current?.chartArea?.left-5 ?? 0,
                  width: chartGridWidth + 10 || 'calc(100% + 10px)'
                }}>
                  {userDOB && results?.length > 1 && (
                    <Tooltip 
                      title={
                        <>
                          <strong>Turn Back Time</strong>
                          <br/>
                          See what would've happened without intervention. 
                          Slide left to visualize your total {hasApoB ? 'ApoB' : 'LDL'} exposure if you hadn't started 
                          working on your heart health.
                        </>
                      }
                      placement="bottom"
                    >
                      <div className="slider-wrapper">
                        <Slider
                          min={0}
                          defaultValue={moment().diff(moment(userDOB), 'years')}
                          max={100}
                          marks={Object.fromEntries(
                            Array.from({ length: 11 }, (_, i) => [i * 10, i * 10])
                          )}
                          tooltip={{
                            formatter: value => `${value} years`
                          }}
                          step={1}
                          onChange={ageCutoff}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
                
              </Col>
            </Row>

            <div className="stats-container" style={{ marginTop: '24px', padding: '20px', background: '#f5f5f5', borderRadius: '8px' }}>
              {getStatsData() ? (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} lg={6}>
                    <Card size="small" className="stat-card">
                      <div className="stat-title">{test?.name} Change Since Your First Test</div>
                      <div className="stat-value">
                        <span style={{ 
                          color: getStatsData().ldlChange.percentChange < 0 ? '#0ca37f' : 
                                getStatsData().ldlChange.percentChange > 0 ? 'rgb(237, 110, 127)' : 
                                'inherit'
                        }}>
                          {getStatsData().ldlChange.percentChange < 0 ? <ArrowDownOutlined /> : 
                          getStatsData().ldlChange.percentChange > 0 ? <ArrowUpOutlined /> : null}
                          <CountUp 
                            start={prevValues.ldlChange}
                            end={Number(getStatsData().ldlChange.percentChange)} 
                            duration={3}
                            decimals={1}
                            suffix="%"
                            preserveValue={true}
                          />
                        </span>
                      </div>
                      <div className="stat-description">
                        From <CountUp end={getStatsData().ldlChange.first} duration={3} preserveValue={true} /> to{' '}
                        <CountUp end={getStatsData().ldlChange.last} duration={3} preserveValue={true} /> mg/dL
                      </div>
                    </Card>
                  </Col>

                  <Col xs={24} sm={12} lg={6}>
                    <Card size="small" className="stat-card">
                      <div className="stat-title">Required {test?.name} for &lt;{getStatsData().riskThreshold}% Risk</div>
                      <div className="stat-value">
                        <CountUp 
                          start={prevValues.targetLDL}
                          end={getStatsData().targetLDL} 
                          duration={3}
                          suffix=" mg/dL"
                          preserveValue={true}
                        />
                      </div>
                      <div className="stat-description">
                        To maintain &lt;{getStatsData().riskThreshold}% risk through age 100
                      </div>
                    </Card>
                  </Col>

                  <Col xs={24} sm={12} lg={6}>
                    <Card size="small" className="stat-card">
                      <div className="stat-title">Current {test?.name} Level</div>
                      <div className="stat-value">
                        <span style={{ 
                          color: getStatsData().ldlChange.last > getStatsData().targetLDL ? 
                            'rgb(237, 110, 127)' : '#0ca37f'
                        }}>
                          <CountUp 
                            start={prevValues.currentLDL}
                            end={getStatsData().ldlChange.last} 
                            duration={3}
                            suffix=" mg/dL"
                            preserveValue={true}
                          />
                        </span>
                      </div>
                      <div className="stat-description">
                        {getStatsData().ldlChange.last > getStatsData().targetLDL ? 
                          `${getStatsData().ldlChange.last - getStatsData().targetLDL} mg/dL above target` :
                          'At or below target'
                        }
                      </div>
                    </Card>
                  </Col>

                  <Col xs={24} sm={12} lg={6}>
                    <Card size="small" className="stat-card">
                      <div className="stat-title">Additional {test?.name} Reduction Needed</div>
                      <div className="stat-value">
                        {getStatsData().requiredReduction.absolute > 0 ? (
                          <span style={{ color: 'rgb(237, 110, 127)' }}>
                            <ArrowDownOutlined />
                            <CountUp 
                              start={prevValues.requiredReduction}
                              end={getStatsData().requiredReduction.absolute} 
                              duration={3}
                              suffix=" mg/dL"
                              preserveValue={true}
                            />
                            {` (`}
                            <CountUp 
                              start={prevValues.reductionPercent}
                              end={Number(getStatsData().requiredReduction.percent)} 
                              duration={3}
                              decimals={1}
                              suffix="%"
                              preserveValue={true}
                            />
                            {`)`}
                          </span>
                        ) : (
                          <span style={{ color: '#0ca37f' }}>
                            <CheckCircleOutlined /> Optimal
                          </span>
                        )}
                      </div>
                      <div className="stat-description">
                        {getStatsData().requiredReduction.absolute > 0 ?
                          `From current ${getStatsData().requiredReduction.current} to target ${getStatsData().requiredReduction.target} mg/dL` :
                          'Current LDL is already below target'
                        }
                      </div>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Card 
                      size="small"
                      title={
                        <div>
                          <div style={{ fontSize: '16px', fontWeight: 'bold', marginTop: 11 }}>
                            Your Heart Health Journey
                          </div>
                          <div style={{ 
                            fontSize: '12px', 
                            color: 'rgba(0,0,0,0.65)', 
                            marginTop: '4px', 
                            marginBottom: 12 
                          }}>
                            How your {hasApoB ? 'ApoB' : 'cholesterol'} management is affecting your future health
                          </div>
                        </div>
                      }
                    >
                      {window.innerWidth < 768 ? (
                        <RiskTimeline 
                          data={getStatsData().thresholdPushbacks.filter(threshold => 
                            (threshold.originalAge < 100 || threshold.newAge < 100) && 
                            threshold.originalAge >= getCurrentAge()
                          )} 
                        />
                      ) : (
                        <Table 
                          dataSource={getStatsData().thresholdPushbacks.filter(threshold => 
                            (threshold.originalAge < 100 || threshold.newAge < 100) && 
                            threshold.originalAge >= getCurrentAge()
                          )}
                          columns={columns}
                          pagination={false}
                          size="small"
                          rowKey="risk"
                          className="risk-table"
                          onRow={(record) => ({
                            style: {
                              borderLeft: `4px solid ${getRiskColor(record.risk)}`,
                              padding: '12px 8px'
                            }
                          })}
                        />
                      )}
                    </Card>
                  </Col>
                  {!isTeammate && (
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} md={16}>
                          <Card 
                            size="small" 
                            className="research-citation"
                          >
                            <Row gutter={[16, 16]}>
                              <Col xs={24}>
                                <div className="research-header">
                                  <div className="card-title">
                                    Landmark Cardiovascular Research
                                    <Tooltip title="Highly cited research in the Journal of the American College of Cardiology">
                                      <Tag color="blue" className="citation-tag">
                                        <TrophyOutlined /> 301 Citations
                                      </Tag>
                                    </Tooltip>
                                  </div>
                                  <div className="research-description">
                                    This tool is based on groundbreaking research demonstrating that lifetime exposure to {hasApoB ? 'ApoB and LDL cholesterol are critical determinants' : 'LDL cholesterol is a critical determinant'} of heart attack risk, not just current levels.
                                  </div>
                                </div>
                              </Col>
                              
                              <Col xs={24}>
                                <Row gutter={12} justify="space-between">
                                  <Col xs={24} sm={6}>
                                    <div className="study-highlight">
                                      <ExperimentOutlined className="highlight-icon purple" />
                                      <div>
                                        <div className="highlight-title">Biological Evidence</div>
                                        <div className="highlight-text">
                                          {hasApoB ? 'ApoB-carrying particles initiate arterial damage' : 'LDL particles initiate arterial damage and plaque buildup'}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={6}>
                                    <div className="study-highlight">
                                      <FieldTimeOutlined className="highlight-icon blue" />
                                      <div>
                                        <div className="highlight-title">Cumulative Impact</div>
                                        <div className="highlight-text">
                                          Total exposure to {hasApoB ? 'ApoB' : 'LDL cholesterol'} best predicts cardiovascular risk
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={6}>
                                    <div className="study-highlight">
                                      <LineChartOutlined className="highlight-icon green" />
                                      <div>
                                        <div className="highlight-title">Early Prevention</div>
                                        <div className="highlight-text">
                                          Earlier control of {hasApoB ? 'ApoB' : 'LDL cholesterol'} levels yields the best outcomes
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={6}>
                                    <div className="study-highlight">
                                      <TeamOutlined className="highlight-icon yellow" />
                                      <div>
                                        <div className="highlight-title">Clinical Evidence</div>
                                        <div className="highlight-text">
                                          Research validated by multiple large-scale trials
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs={24}>
                                <div style={{ 
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  borderTop: '1px solid #f0f0f0',
                                  marginTop: '5px',
                                  paddingTop: '50px'
                                }}>
                                  <Tooltip title="Read the complete research paper">
                                    <a
                                      href="https://doi.org/10.1016/j.jacc.2018.06.046"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="study-link"
                                    >
                                      View Full Research Paper <ArrowRightOutlined />
                                    </a>
                                  </Tooltip>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>

                        <Col xs={24} md={8}>
                          {isManagedPatient ? (
                            <Card 
                              className="managed-program-card"
                              style={{
                                background: 'linear-gradient(to right, #f0f7ff, #ffffff)',
                                border: '1px solid #91caff',
                                height: '100%'
                              }}
                            >
                              <div className="card-title">
                                <SafetyCertificateOutlined style={{ color: '#0ca37f' }} />
                                Your Heart Health Benefits
                              </div>

                              <div style={{ 
                                fontSize: '14px', 
                                color: 'rgba(0,0,0,0.85)', 
                                marginBottom: '24px' 
                              }}>
                                As a member of our Heart Health Program, you have access to comprehensive care and support to help you reach your goals.
                              </div>

                              <div style={{ marginBottom: '24px' }}>
                                <div className="benefit-item">
                                  <TrophyOutlined style={{ color: '#0ca37f' }} />
                                  <span>Dedicated medical team</span>
                                </div>
                                <div className="benefit-item">
                                  <TeamOutlined style={{ color: '#0ca37f' }} />
                                  <span>1-on-1 physician consultations</span>
                                </div>
                                <div className="benefit-item">
                                  <LineChartOutlined style={{ color: '#0ca37f' }} />
                                  <span>Ongoing progress tracking</span>
                                </div>
                                <div className="benefit-item">
                                  <ExperimentOutlined style={{ color: '#0ca37f' }} />
                                  <span>Regular monitoring and adjustments</span>
                                </div>
                              </div>

                              <Button 
                                type="primary"
                                size="large"
                                className="contact-concierge-btn"
                                onClick={() => {
                                  onChat()
                                  PosthogHelper.track(currentUser, `[exposure] contact concierge`, true)
                                }}
                                style={{
                                  height: 'auto',
                                  padding: '12px 24px',
                                  width: '100%',
                                  background: '#0ca37f',
                                  borderColor: '#0ca37f',
                                  marginBottom: '12px'
                                }}
                              >
                                Contact Your Concierge
                                <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                              </Button>

                              <div style={{ 
                                fontSize: '12px', 
                                color: 'rgba(0,0,0,0.45)',
                                textAlign: 'center' 
                              }}>
                                <SafetyCertificateOutlined style={{ marginRight: '4px' }} />
                                Premium Member Benefits
                              </div>
                            </Card>
                          ) : (
                            <Card 
                              className="join-program-card"
                              style={{
                                background: 'linear-gradient(to right, #f0f7ff, #ffffff)',
                                border: '1px solid #91caff',
                                height: '100%'
                              }}
                            >
                              <div className="card-title">
                                <SafetyCertificateOutlined style={{ color: '#1890ff' }} />
                                Take Action Today
                              </div>

                              <div style={{ 
                                fontSize: '14px', 
                                color: 'rgba(0,0,0,0.85)', 
                                marginBottom: '24px' 
                              }}>
                                Put this research into practice with our physician-led Heart Health Program. Create a personalized plan based on your LDL exposure data.
                              </div>

                              <div style={{ marginBottom: '24px' }}>
                                <div className="benefit-item">
                                  <TrophyOutlined style={{ color: '#1890ff' }} />
                                  <span>Personalized treatment plan</span>
                                </div>
                                <div className="benefit-item">
                                  <TeamOutlined style={{ color: '#1890ff' }} />
                                  <span>1-on-1 physician consultations</span>
                                </div>
                                <div className="benefit-item">
                                  <LineChartOutlined style={{ color: '#1890ff' }} />
                                  <span>Evidence-based interventions</span>
                                </div>
                                <div className="benefit-item">
                                  <ExperimentOutlined style={{ color: '#1890ff' }} />
                                  <span>Regular monitoring and adjustments</span>
                                </div>
                              </div>

                              <Button 
                                type="primary"
                                size="large"
                                className="join-program-btn"
                                onClick={() => {
                                  navigate('/heart')
                                  PosthogHelper.track(currentUser, `[exposure] click heart health upsell cta`, true)
                                }}
                                style={{
                                  height: 'auto',
                                  padding: '12px 24px',
                                  width: '100%',
                                  background: '#1890ff',
                                  borderColor: '#1890ff',
                                  marginBottom: '12px'
                                }}
                              >
                                Join Heart Health Program
                                <ArrowRightOutlined style={{ marginLeft: '8px' }} />
                              </Button>

                              <div style={{ 
                                fontSize: '12px', 
                                color: 'rgba(0,0,0,0.45)',
                                textAlign: 'center' 
                              }}>
                                <SafetyCertificateOutlined style={{ marginRight: '4px' }} />
                                HSA/FSA Eligible
                              </div>
                            </Card>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              ) : (
                <Empty description="No data available" />
              )}
            </div>
          </>}
        </div>
      </Layout>
    </Layout>
  )
}