import './test.scss';
import { TestChart } from '../testChart/testChart.component.js';
import { getTestByCode, getTestBySlug } from '../../services/test.service.js';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import UtilHelper  from '../../helpers/util.helper.js';


export const Test = () => {
  const { code } = useParams();
  const [test, setTest] = useState(null);

  useEffect(() => {
    document.title = `${test?.shortName && test?.longName ? `${test?.longName} (${test?.shortName})` : test?.name} | Instalab`;
    document.description = test?.tagline;
  }, [test]);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        if (UtilHelper.isNumber(code)) {
          const currentTest = await getTestByCode(code);
          if (currentTest) {
            setTest(currentTest);
            return;
          }
        }

        else {
          const currentTest = await getTestBySlug(code);
          if (currentTest) {
            setTest(currentTest);
            return;
          }
        }


 
      } catch (error) {
        console.error('Error fetching test:', error);
        message.error('Could not find test');
      } 
    };

    fetchTest();
  }, [code]); 

  

  return (
    <div className="test-page">
      <div className="test-page-chart-container">
        {test && <TestChart key={test._id} id={test._id} setAnnotations={() => {}} />}
      </div>
    </div>
  );
};

export default Test;