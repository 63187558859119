import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listConditionCategories = async (params) => {

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/conditions/categories`, {
        headers: ServiceHelper.getHeaders(),
        params: {
            ...params,
            ...ServiceHelper.getParams(),
        },
    })
    return response.data
}