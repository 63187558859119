import { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { Row, Col, Typography, Button, Collapse, Input, Spin } from 'antd';
import { PlayCircleOutlined, ArrowRightOutlined, ClockCircleOutlined, DownOutlined, UpOutlined, EnvironmentFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import "./cleerly.scss";
import {
  setDefaults,
  fromAddress,
} from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useLoadScript } from "@react-google-maps/api";
import classNames from 'classnames';
import { CtaVideos } from '../ctaVideos/ctaVideos.component';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { getNearbyFacilities } from '../../services/facility.service';
import LocationHelper from '../../helpers/location.helper';
import { updateMe } from '../../services/user.service';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FlowType from '../../enums/flowType.enum';
import useWidth from '../../hooks/useWidth.hook';
import Breakpoint from '../../enums/breakpoint.enum';
import PosthogHelper from '../../helpers/posthog.helper';

const { Panel } = Collapse;

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
})

const { Paragraph, Text } = Typography;

export const Cleerly = () => {
  const width = useWidth()
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const [expandedParagraphs, setExpandedParagraphs] = useState({});
  const [videosDrawerVisible, setVideosDrawerVisible] = useState(false);
  const [address, setAddress] = useState(currentUser?.location ? LocationHelper.getLocation(currentUser.location) : null);
  const [facilities, setFacilities] = useState([]);
  const [location, setLocation] = useState(currentUser?.location)
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    document.title = `Instalab | Cleerly: Coronary CT Angiogram`
    autoOpenVideos()
  }, [])

  useEffect(() => {
    if (currentUser?.location) {
      setLocation(currentUser.location)
      setAddress(LocationHelper.getLocation(currentUser.location))
      onFacilitySearch({
        defaultAddress: LocationHelper.getLocation(currentUser.location),
        defaultLocation: currentUser.location
      })
    }
  }, [currentUser])

  const autoOpenVideos = () => {
    const video = searchParams.get('video')
    if (video === 'true') {
      setVideosDrawerVisible(true)
    }
  }
  
  const showVideosDrawer = () => {
    setVideosDrawerVisible(true);
  };
  
  const closeVideosDrawer = () => {
    setVideosDrawerVisible(false);
  };
  
  // Toggle paragraph expansion
  const toggleParagraph = (id) => {
    setExpandedParagraphs(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const faqs = [{
    question: `What is a coronary CT angiogram?`,
    answer: (
      <>
        A coronary CTA is a test that looks at both calcified and noncalcified plaque in your heart's arteries. If this plaque ruptures, it can lead to a heart attack or stroke. To perform the scan, you may be given medication to lower your heart rate and improve image clarity. Then, a contrast dye is injected into a vein to highlight the blood flow—blood appears white on the scan, while noncalcified plaque shows up as gray—allowing us to clearly distinguish areas of concern.
      </>
    ),
    video: `https://vimeo.com/1071274032`,
  }, {
    question: `At what age should you get this scan?`,
    answer: <>
      If you have risk factors—like high cholesterol, diabetes, obesity, smoking, or a family history of heart disease—you should consider getting scanned at age 40. And if a parent or close relative had a heart attack at a young age, it’s wise to get screened even earlier, ideally 10–15 years before their event. For those without major risk factors, screening is recommended at age 50.
      <br/><br/>
      Women generally have about a 10-year protection advantage over men, yet heart disease remains the leading cause of death for both—affecting one in three men and one in three women. To put that into perspective, one in eight women die of breast cancer, yet heart disease is often underdiagnosed and undertreated in women, despite its greater impact.
    </>,
    video: `https://vimeo.com/1071274294`,
  }, {
    question: `If you are actively treating heart disease, how often should you get a coronary CTA?`,
    answer: <>
      We generally start to get concerned when there's about 50% blockage in an artery. At that point, we want to closely monitor whether things are improving or worsening. If the narrowing progresses to around 70%, that's typically when we begin considering stents.
      <br/><br/>
      For someone with 50% stenosis, we'll usually recommend a follow-up scan in a year to see if lifestyle changes or medical therapy are making a difference. If the blockage is getting worse, we may need to be more aggressive with treatment. If it's improving, that's exactly what we want to see. For people with just a small amount of noncalcified plaque, a follow-up every three to five years is usually sufficient. But once it starts to become concerning, annual follow-up is the standard.
    </>,
    video: `https://vimeo.com/1071274237`,
  }, {
    question: `How should you weigh the high price tag of a coronary CTA against alternative tests?`,
    answer: <>
      A carotid intimal medial thickness (CIMT) ultrasound is a relatively affordable test—typically a few hundred dollars—that gives a clear view of the carotid arteries. Since the carotids are close to the surface, it's easy to evaluate them with an ultrasound probe. If plaque is found, it should be treated, because what's happening in the carotids often reflects what's happening in the coronary arteries.
      <br/><br/>
      The <a className="faq-link" onClick={() => {
        PosthogHelper.track(currentUser, `[cleerly] clicked cac link`, true)
        navigate(`/cac`)
      }}>coronary calcium score</a> is also a useful, widely available tool to detect calcified plaque. But it doesn't tell the whole story. Many people with a calcium score of zero still have noncalcified plaque—sometimes even a moderate amount—which can be dangerous. So a zero score doesn't always mean you're in the clear. 
      <br/><br/>
      While a coronary CT angiogram may seem expensive compared to everyday costs, consider that this is about your long-term health. If your blood markers are normal and everyone in your family lives to 90, you may not need a coronary CTA in your 40s or even 50s. But in many cases, this scan provides life-saving insight. Yes, it's more expensive because it requires specialized equipment, technicians, and doctors—but the information it provides is incredibly powerful.
    </>,
    video: `https://vimeo.com/1071274174`,
  }, {
    question: `Is there a better test for heart health than this scan?`,
    answer: <>
      Another way to assess plaque in the arteries is through endovascular ultrasound, where a catheter with an ultrasound probe is inserted directly into the carotid or coronary arteries. While this method may offer slightly more detail, it's invasive. In contrast, a coronary CT angiogram provides an exceptional, noninvasive view of the arteries and is currently the best test for early detection.
      <br/><br/>
      Stress tests, on the other hand, often don't show anything abnormal until there's already 70% blockage—and by that point, it's late in the game. We want to identify and treat heart disease much earlier, before it becomes severe. The goal is proactive care, not waiting until symptoms appear. With the right treatments, we can clear out plaque and significantly improve quality and length of life. Ultimately, we want our patients to not only live to 100—but to feel good when they get there.
    </>,
    video: `https://vimeo.com/1071274148`,
  }, {
    question: `If you're very proactive about longevity, at what point should you get a coronary CTA?`,
    answer: <>
      Family history plays a major role in deciding when to get a coronary CTA. If you have a relative who had a heart attack in their 50s, it's wise to get scanned in your 40s—or even your 30s—because the goal is to catch disease far in advance, not after symptoms or events occur.
      <br/><br/>
      As a general guideline, we recommend scanning at age 50 for men and 60 for women, or 10 years earlier if you have risk factors. But in cases of strong family history, especially if a parent had a heart attack at a young age, it's worth considering a scan 10 to 15 years before their first event. It's always better to identify risk early, when it's still preventable.
    </>,
    video: `https://vimeo.com/1071274105`,
  }]
  
  // Define paragraph content with short and full versions
  const howContent = [
    {
      id: 'how-1',
      title: `The Scan: Capturing High-Resolution Images`,
      short: "To highlight the plaque in your heart's arteries, a contrast dye is injected into a vein, typically your arm. You may also receive medication to slow your heart rate and improve the clarity of the CT images.",
      full: <>
        To highlight the plaque in your coronary arteries, a contrast dye is injected into a vein, typically your arm. You may also receive medication to slow your heart rate and improve the clarity of the CT images.
        <br/><br/>
        Once ready, you'll lie on a motorized table that slides into the CT scanner. As the machine rotates around you, it captures hundreds of detailed cross-sectional images of your heart within seconds. While the scan itself is quick, expect to spend up to an hour at the imaging center for preparation and post-scan procedures.
      </>,
      img: "/img/ct/procedure.jpg"
    },
    {
      id: 'how-2',
      title: "Image Analysis: Detecting Plaque and Narrowing",
      short: "After your scan, Cleerly's advanced AI software reconstructs a detailed 3D view of your coronary arteries. The software analyzes these images to accurately identify the presence, amount, and type of plaque buildup, as well as areas of artery narrowing.",
      full: <>
        After your scan, Cleerly's advanced AI software reconstructs a detailed 3D view of your coronary arteries. The software analyzes these images to accurately identify the presence, amount, and type of plaque buildup, as well as areas of artery narrowing.
        <br/><br/>
        Cleerly's AI technology is built on data from over 10 million images across more than 30,000 patients collected in multiple clinical trials spanning 15 years. These proprietary algorithms are FDA-cleared and have demonstrated superior accuracy compared to traditional diagnostic methods.
      </>,
      video: `https://vimeo.com/1068358085`

    },
    // Add more paragraphs as needed
  ];

  const addressInputRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "AIzaSyBiXW-QXKQreN_9hQOW4VPunykKt9T3pts",
    libraries: ['places']
  });

  const focusAddressInput = () => {
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  const handleAddressChange = address => {
    setAddress(address);
  };

  const handleAddressSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const streetNumber = getAddressValueByType(results, 'street_number')
        const route = getAddressValueByType(results, 'route')
        let formattedAddress = ''
        if (streetNumber) {
          formattedAddress = streetNumber
        }
        if (route) {
          formattedAddress = `${formattedAddress} ${route}`
        }
        
        const fetchedLocation = {
          streetAddress: formattedAddress,
          postalCode: getAddressValueByType(results, 'postal_code'),
          city: getAddressValueByType(results, 'locality') || getAddressValueByType(results, 'sublocality_level_1') || getAddressValueByType(results, 'neighborhood'),
          state: getAddressValueByType(results, 'administrative_area_level_1'),
        }
        
        setLocation(fetchedLocation)
        setAddress(address);
      })
      .catch(error => console.error('Error selecting address', error));
  };

  const getAddressValueByType = (results, type) => {
    if (!results || !results[0] || !results[0].address_components) return null;
    
    const component = results[0].address_components.filter(component => 
      component.types.includes(type)
    );
    
    if (component.length) {
      return component[0].short_name;
    }
    return null;
  };

  const onFacilitySearch = async ({ defaultAddress=null, defaultLocation=null }) => {
    if (!defaultAddress && !address) return;

    setIsSearching(true)

    const { results } = await fromAddress(defaultAddress || address);
    
    const {
      lat: latitude, 
      lng: longitude,
    } = results[0].geometry.location;


    if (!currentUser?.location?.postalCode) {
      const userLocation = defaultLocation || location
      const updatedUser = await updateMe({
        fields: {
          location: {
            ...userLocation,
            latitude,
            longitude
          }
        }
      })
      setCurrentUser(updatedUser)
    }

    const fetchedFacilities = await getNearbyFacilities({
      latitude,
      longitude,
      productTypeCode: ProductTypeCode.CLEERLY,
      maxDuration: 120
    });

    const facilitiesWithProduct = fetchedFacilities?.filter(facility => 
      facility.menu?.some(item => 
        item.productType.code === ProductTypeCode.CLEERLY && 
        item.price != null && 
        item.price > 0
      )
    ).sort((a, b) => a.duration - b.duration)

    setFacilities(facilitiesWithProduct)

    setIsSearching(false)
  };

  return (
    <div className="cleerly">
      <CtaVideos
        visible={videosDrawerVisible} 
        onClose={closeVideosDrawer}
        width={900}
        placement="right"
        title={width <= Breakpoint.MD ? "Everything You Need to Know" : "Coronary CTA: Everything You Need to Know"}
      />

      <div className="cleerly-hero">
        <div className="cleerly-section">
          <Paragraph className="cleerly-h1">
            Cleerly: Coronary CT Angiogram
          </Paragraph>
          <Paragraph className="cleerly-p1">
            The most accurate heart health test available. This scan uses high-resolution, AI-enhanced imaging to detect blockages in your heart's arteries before symptoms appear.
          </Paragraph>
          <Button
            type="primary"
            className="schedule-cleerly-btn"
            onClick={() => {
              PosthogHelper.track(currentUser, `[cleerly] clicked schedule cleerly`, true)
              navigate(`/flow/${FlowType.CLEERLY}`)
            }}
          >
            <span className="schedule-cleerly-btn-text">Schedule</span> <ArrowRightOutlined className="schedule-cleerly-btn-icon" />
          </Button>

          <br />

          <Paragraph className="cleerly-quote">
            "Heart disease is the leading killer in the US—but it's treatable, decades before a heart attack. A coronary CTA gives the full picture, detecting both calcified and non-calcified plaque, which can lead to heart attacks or strokes if ruptured. <i>This is life-saving information.</i>"
          </Paragraph>

          <div 
            className="cleerly-hero-img-container"
            onClick={showVideosDrawer}
          >
            <img 
              src="/img/cleerly/hero.jpg" 
              className="cleerly-hero-img" 
            />

            <PlayCircleOutlined className="cleerly-play-icon" />

            <div className="cleerly-video-attribute">
              <Paragraph className="cleerly-video-attribute-name"> Dr. Kimberly Brockenbrough </Paragraph>
              <Paragraph className="cleerly-video-attribute-title"> Cardiac Radiologist </Paragraph>
            </div>
          </div>
        </div>
      </div>
      
      <div className="how-section">
        <div className="cleerly-section">
          <div className="divider-container">
            <Text className="cleerly-h1"> 
              How It Works
            </Text>
          </div>

          {howContent.map((how) => (
            <div key={how.id} className="paragraph-container">
              {how.title && (
                <Paragraph className={`cleerly-p1 bold`}>
                  {how.title}
                </Paragraph>
              )}
              
              <Paragraph className="cleerly-p2">
                {expandedParagraphs[how.id] ? how.full : how.short}
              </Paragraph>
              
              {how.short !== how.full && (
                <Button 
                  type="link" 
                  className="read-more-btn"
                  onClick={() => toggleParagraph(how.id)}
                  icon={expandedParagraphs[how.id] ? <UpOutlined /> : <DownOutlined />}
                >
                  {expandedParagraphs[how.id] ? "Read Less" : "Read More"}
                </Button>
              )}

              <br/>

              {how.img && (
                <img src={how.img} className="cleerly-how-img" />
              )}

              {how.video && (
                <div className="cleerly-how-video-container">
                  <ReactPlayer
                    url={how.video}
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                    controls={false}
                    width="100%"
                    height="auto"
                    className="cleerly-how-video"
                    config={{
                      vimeo: {
                        playerOptions: {
                          responsive: true,
                          background: true
                        }
                      }
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="pricing-section">
        <div className="cleerly-section">
          <div className="divider-container">
            <Text className="cleerly-h1"> 
              Pricing
            </Text>
          </div>
          <Paragraph className="cleerly-p1 pricing-p1">
            We'll help you find the most convenient and affordable testing location near you and handle the appointment scheduling. Enter your location below to search our database.
          </Paragraph>

          <div 
            className={classNames("pricing-search")}
          >
            <Row gutter={16} align="bottom">
              <Col flex="auto" onClick={focusAddressInput} className="clickable-input-col">
                <Text className="pricing-search-label">
                  <EnvironmentFilled className="pricing-search-label-icon" /> Your Address
                </Text>
                
                {isLoaded ? (
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleAddressChange}
                    onSelect={handleAddressSelect}
                    debounce={300}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="address-autocomplete-container">
                        <Input
                          {...getInputProps({
                            className: 'pricing-search-address',
                            placeholder: 'Enter your full address...',
                            onKeyDown: e => {
                              if (e.key === 'Enter' && suggestions?.length) {
                                e.preventDefault();
                                handleAddressSelect(suggestions[0].description);
                                addressInputRef.current.blur();
                              }
                            },
                          })}
                          ref={addressInputRef}
                          onClick={(e) => e.stopPropagation()}
                        />
                        
                        <div className="autocomplete-dropdown-container">
                          {loading && (
                            <div className="address-spinner">
                              <Spin size='small' /> Loading...
                            </div>
                          )}
                          {suggestions.map((suggestion, index) => {
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, { style })}
                                className="suggestion-item"
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <Input
                    className="pricing-search-address"
                    placeholder="Loading Google Places..."
                    disabled
                  />
                )}
              </Col>
              
              <Col
                className="search-address-btn-col"
              >
                <Button 
                  type="primary"
                  className="search-address-btn"
                  onClick={onFacilitySearch}
                  loading={isSearching}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>

          {facilities.map((facility) => (
            <div 
              key={facility.id}
              className="cleerly-facility-item"
            >
              <Paragraph 
                className="cleerly-p2 bold cleerly-facility-name"
                ellipsis={{
                  rows: 1,
                }}
              >
                {facility.name}
              </Paragraph>
              <Paragraph className="cleerly-facility-location">
                {LocationHelper.getLocation(facility.location)}
              </Paragraph>
              <Paragraph className="cleerly-facility-duration">
                <ClockCircleOutlined /> {Math.round(facility.duration)} min drive
              </Paragraph>
              <Text className="cleerly-p2 bold cleerly-facility-price"> 
                {facility.menu.find(item => item.productType.code === ProductTypeCode.CLEERLY)?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </Text>
              <Button 
                type="primary"
                className="schedule-facility-btn"
                onClick={() => {
                  PosthogHelper.track(currentUser, `[cleerly] clicked schedule cleerly: ${facility._id}`, true)
                  navigate(`/flow/${FlowType.CLEERLY}?facility=${facility._id}`)
                }}
              >
                Schedule <ArrowRightOutlined />
              </Button>
            </div>
          ))}
        </div>
      </div>

      <div className="faq-section">
        <div className="cleerly-section">
          <div className="divider-container">
            <Text className="cleerly-h1"> 
              FAQ
            </Text>
          </div>

          <Collapse 
            className="faq-collapse"
            bordered={false}
            expandIcon={({ isActive }) => (
              isActive ? <MinusOutlined /> : <PlusOutlined />
            )}
          >
            {faqs.map((faq, index) => (
              <Panel 
                header={faq.question} 
                key={index}
                className="faq-panel"
              >
                <div className="faq-answer">
                  {faq.answer}
                  
                  <br/>

                  <div className="faq-video-container"> 
                    <ReactPlayer
                      url={faq.video}
                      controls={true}
                      width="100%"
                      height="auto"
                      className="faq-video"
                      config={{
                        vimeo: {
                          playerOptions: {
                            responsive: true,
                            autopause: true
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
} 