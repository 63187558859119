import { useEffect, useRef, useState, useContext } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmPackage } from "./confirmPackage.component"
import { completeFlow } from "../../services/flow.service.js"
import { getProductTypeByCode, listProductTypes } from '../../services/productType.service'
import ProductHelper from "../../helpers/product.helper"
import { UserContext } from "../../contexts/user.context"
import FlowType  from "../../enums/flowType.enum"

export const Step = {
  SELECT: 'select',
  ACCOUNT: 'account',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const PackageFlow = () => {
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [searchParams] = useSearchParams()
  const { currentUser, instalabMembership } = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    fetchProductTypes()
  }, [])

  useEffect(() => {
    const urlProductTypeCode = searchParams.get('code')
    if (urlProductTypeCode && !flow?.giftProductTypeCodes?.length) {
      setFlow(prev => ({
        ...prev,
        giftProductTypeCodes: [urlProductTypeCode]
      }))
    }
  }, [flow])

  useEffect(() => {
    fetchSkeleton()
  }, [flow, hasPass, initialUser, productTypes, instalabMembership, currentUser])


  useEffect(() => {
    hasCompletedFlowRef.current = false;
    return () => { 
      fetchProductTypes();
      hasCompletedFlowRef.current = false; 
    }; // Reset on unmount
    
 }, [flow?._id]);

  const fetchProductTypes = async () => {
    const response = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.BUNDLE_ESSENTIALS,
            ProductTypeCode.BUNDLE_ESSENTIALS_JR,
          ]
        }
      }
    })
    setProductTypes(response)
  }
  
  useEffect(() => {
    const urlProductTypeCode = searchParams.get('code')
    if (urlProductTypeCode && !flow?.giftProductTypeCodes?.length) {
      setFlow(prev => ({
        ...prev,
        giftProductTypeCodes: [urlProductTypeCode]
      }))
    }
  }, [flow])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType?.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser || !productTypes) return

    const selectedProductTypes = flow?.giftProductTypeCodes?.map(code => getProductType(code)).filter(Boolean)
    const totalCost = ProductHelper.getTotalCost(selectedProductTypes, instalabMembership, currentUser)
    const selectedProductTitles = selectedProductTypes?.map(product => product?.title).join(' + ')

    setSkeleton({
      [Step.SELECT]: {
        type: StepType.PACKAGE_SELECT,
        title: `Select your package`,
        buildFlow: true,
        multiple: false,
        nextStep: Step.PAY,
      },

      [Step.PAY]: {
        type: StepType.PAY,
        addProduct: true,
        productType: flow?.giftProductTypeCodes,
        nextStep: Step.ACCOUNT,
        title: () => {
          return <>Pay <span className="true-price">${totalCost}</span> for the <span className="product-name">{selectedProductTitles}</span>.</>
        },

      },

      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `Let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.PACKAGE}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>Success! Your {selectedProductTitles} is ready to use.</>,
        description: <>You can schedule your appointments now or come back when you're ready to begin.</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmPackage flow={flow} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            try {
               hasCompletedFlowRef.current = true;
               await completeFlow(flow?._id);
            } catch (error) {
               console.error("Error completing flow:", error);
            }
         }
        }
      },
    })
  }
  return (
     <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      setHasPass={setHasPass}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      productTypeCode={flow?.giftProductTypeCodes?.[0]}
      startIndex={1}
    />
  )
}