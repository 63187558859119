import moment from 'moment-timezone'
import copy from 'copy-to-clipboard'
import PhlebType from '../enums/phlebType.enum'
import AppointmentStatus from '../enums/appointmentStatus.enum'
import ProductTypeCode  from '../enums/productTypeCode.enum'
import { Gender } from '../enums/index.enum'
const AppointmentHelper = {
  getStartTime: (appointment) => {
    return moment(appointment.start).tz(appointment.location.timeZoneId)
  },
  getLocation: (appointment) => {
    const {
      streetAddress,
      streetAddress2,
      city,
      state,
    } = appointment.location

    const { facility } = appointment
    return facility && facility.name ? `${facility.name}` : `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state}`

  },
  getPhlebotomistName: (appointment) => {
    const { phlebotomist } = appointment
    if (!phlebotomist) return
    return `${phlebotomist.firstName} ${phlebotomist.lastName}`
  },
  copyJoinLink:  async (appointmentId, message) => {
    try {
      copy(`${process.env.REACT_APP_CLIENT_URL}/appointments/${appointmentId}/join`)
      message.success('Copied join link')
    } catch (err) {
      message.error('Failed to generate join link')   
    }
  },
  getPanel: (appointment) => {
    const { orders } = appointment

    if (orders && orders.length > 0) {
      return orders[0].panel
    }
  },
  canInvite: (appointment) => {
    const { phlebType } = appointment;
    return phlebType === PhlebType.INSTALAB || phlebType === PhlebType.GETLABS
  },
  isGetLabs: (appointment) => {
    const { phlebType } = appointment;
    return phlebType === PhlebType.GETLABS
  },
  getTagColor: (status) => {
    switch (status) {
      case AppointmentStatus.COMPLETE:
        return 'default'
      case AppointmentStatus.CANCELED:
        return 'error'
      case AppointmentStatus.PENDING:
        return 'default'
      case AppointmentStatus.CONFIRMED:
        return 'success'
      case AppointmentStatus.COLLECTED:
        return 'warning'
      default:
        return 'default'
    }
  },
  getTitle: (appointment, patient) => {

    const { orders } = appointment

    const order = orders.find(order => order.patient === patient._id)

    const collectionMethods = [
      ProductTypeCode.MOBILE_BLOOD_DRAW,
      ProductTypeCode.PRO_MOBILE_BLOOD_DRAW,
      ProductTypeCode.LAB_VISIT,
    ]
    
    const products = appointment.products?.filter(product => 
      !collectionMethods.includes(product.productType?.code)
    )
    
    const mainProducts = products?.map(product => product.productType?.title).join(', ')

    const panels = order?.panels?.map(panel => {
      return {
        ...panel,
        tests: panel.hasSexSpecificTests ? patient.gender === Gender.MALE ? panel.maleTests : panel.femaleTests : panel.tests
      }
    })
    
    return {
      title: mainProducts,
      tests: order?.tests || [],
      panels: panels || []
    }
  }
}

export default AppointmentHelper