import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { UserContext } from "../../../contexts/user.context";
import BlogId from "../../../enums/blogId.enum";
import { useContext } from "react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";


const { Paragraph } = Typography;

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const LpaPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  const ethnicityData = {
    labels: ['South Asian', 'Southeast Asian', 'Latin American', 'Chinese', 'White European'],
    datasets: [
      {
        label: 'Increased % Heart Attack Risk with High Lp(a)',
        data: [114, 83, 67, 62, 36],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return ` ${context.raw}%`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Risk Multiplier'
        }
      }
    }
  };

  return (
    <div className={classNames("blog-post", "lpa-post")}>
      <div className="blog-border" /><br/><br/>

      <div className="blog-body">
        <Paragraph className="blog-title">
          Who is at Risk?
        </Paragraph>
        <Paragraph className="blog-description">
          The impact of high Lp(a) levels on heart attack risk is highly correlated by ethnicity. For example, South Asians who have elevated Lp(a) levels will more than <b>double their risk</b> of experiencing a heart attack. In contrast, White Europeans experience a much more modest increase in risk—only <b>36%</b>—when their Lp(a) levels are high.
        </Paragraph>

        <div className="blog-chart">
          <Bar data={ethnicityData} options={options} />
        </div>

        <Paragraph className="blog-title">
          Exceptions: High Lp(a) but Low Risk
        </Paragraph>
        <Paragraph className="blog-description">
          Individuals of African and Arabian descent present a unique paradox. Despite having the highest Lp(a) levels among all groups, these populations appear to be largely protected from the heart attack risks associated with elevated Lp(a). In fact, the data reveals <i>no statistically significant link</i> between high Lp(a) levels and increased heart attack risk for these groups.
        </Paragraph>
        <Paragraph className="blog-description">
          This doesn't mean that individuals from these ethnicities are somehow immune. While Lp(a) may pose less of a threat compared to other ethnicities, it's still critical to focus on optimizing other heart health biomarkers.
        </Paragraph>

        <Paragraph className="blog-title">
          If You Have High Lp(a), What Should You Do?
        </Paragraph>
        <Paragraph className="blog-description">
          Knowing your Lp(a) levels is the first step and is as simple as taking an <a onClick={() => {
            navigate('/heart-health-test')
            PosthogHelper.track(currentUser, `[research] click ${blogId} cta: request test`, true)
          }} className="blog-link">at-home blood test</a>. As indicated above, lifestyle changes like diet and exercise or even cholesterol-lowering medications are ineffective in lowering Lp(a) levels. Instead, it's critical to focus on reducing other heart health markers, such as <a onClick={() => navigate(`/research/${BlogId.APOB}`)} className="blog-link">ApoB</a>. Managing other risk factors may help offset the heart attack risk associated with elevated Lp(a) levels.
        </Paragraph>

        <Paragraph className="blog-title">
          What the Science is Saying Today
        </Paragraph>
        <Paragraph className="blog-description">
          Medications like Pelacarsen have remarkable potential, with clinical trials indicating that a weekly 20 mg dose can reduce Lp(a) levels by up to 80%. While promising, it remains undetermined whether taking this medication is correlated to a reduction in heart attack risk for ethnicities at high risk.
        </Paragraph>
        <Paragraph className="blog-description">
          The ongoing HORIZON trial is actively investigating this, with results expected later this year. If the trial confirms a causal link between Lp(a) reduction and improved heart health, our medical team at Instalab will develop guidelines for recommending this medication to those with cardiovascular risk.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">Sources</Paragraph>
          <ul className="source-list">
            <li>
              Tsimikas, S., Moriarty, P. M., & Stroes, E. S. (2021). Emerging RNA Therapeutics to Lower Blood Levels of Lp(a): JACC Focus Seminar 2/4. Journal of the American College of Cardiology, 77(12), 1576–1589. <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/33766265/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/33766265/</a>
            </li>
            <li>
              Paré, G., Çaku, A., McQueen, M., Anand, S. S., Enas, E., Clarke, R., Boffa, M. B., Koschinsky, M., Wang, X., Yusuf, S., & INTERHEART Investigators. (2019). Lipoprotein(a) Levels and the Risk of Myocardial Infarction Among 7 Ethnic Groups. Circulation, 139(12), 1472–1482. <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/30667276/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/30667276/</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
