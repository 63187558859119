const UtilHelper = {

    isNumber: (value) => {
        // Check if the value is not null or undefined
        if (value == null) return false;
        
        // Convert to string if it's not already
        const str = String(value);
        
        // Check if the string is empty
        if (str.trim() === '') return false;
        
        // Use regex to check if the string contains only digits
        return /^\d+$/.test(str);
    },

    getOrdinalSuffix: (number) => {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return 'th';
        }

        switch (lastDigit) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    },

    isEmpty: (value) => {
        return Number.isNaN(value) || value === undefined || value === null;
    },

    formatDateInput: (input) => {
        // Remove any non-numeric characters
        let numbers = input.replace(/\D/g, '');
        
        // Add slashes after MM and DD
        if (numbers.length > 4) {
            numbers = numbers.slice(0, 2) + '/' + numbers.slice(2, 4) + '/' + numbers.slice(4);
        } else if (numbers.length > 2) {
            numbers = numbers.slice(0, 2) + '/' + numbers.slice(2);
        }
        
        return numbers;
    },

    isValidDate: (dateString) => {

        if (dateString.length !== 10) return false;

        // First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
            return false;
      
        // Parse the date parts to integers
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
      
        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month === 0 || month > 12)
            return false;
      
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
      
        // Adjust for leap years
        if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
            monthLength[1] = 29;
      
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }
}

export default UtilHelper;