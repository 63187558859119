import { Typography, Popover, Button }  from 'antd'
import './memberConfirmed.scss'
import Breakpoint from "../../../../enums/breakpoint.enum";
import useWidth from '../../../../hooks/useWidth.hook';
import FlowType from '../../../../enums/flowType.enum';
import Panels from "../../../panels/panels.component";
import { CalendarOutlined } from '@ant-design/icons';

const { Paragraph } = Typography



const MemberConfirmed = ({ footer, instalabMembership }) => {
  const width = useWidth()

  const drWiniarskiContent = (
    <div style={{ padding: '15px' }}>
      <img 
        src="/img/steven.webp" 
        alt="Dr. Steven Winiarski" 
        style={{ 
          width: '120px', 
          display: 'block',
          margin: '0 auto',
          borderRadius: '50%', 
          marginBottom: '10px' 
        }} 
      />
      <h3 style={{ textAlign: 'center' }}>Dr. Steven Winiarski</h3>
      <p style={{ fontSize: '14px', lineHeight: '1.5', color: '#555' }}>
        Dr. Steven Winiarski is a seasoned preventive medicine and longevity physician with over 25 years of experience working with professional athletes, executives and entrepreneurs.
      </p>
    </div>
  );

  return (
    <div 
      className="member-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Welcome to Your Instalab Membership! 
        </Paragraph>
        
        <div className="welcome-message">
          <Paragraph>
            We're excited to start helping you live your longest, healthiest life. You can reach out anytime to{' '}
            <a style={{color: `gray`}} href="mailto:concierge@instalab.com">concierge@instalab.com</a>
            {' '}with any questions or requests.
          </Paragraph>
        </div>

        <div className="next-steps-container">
          <h2 className="next-steps-title">Here Are Your Next Steps:</h2>

          <div className="step-card">
            <div className="step-number">1</div>
            <div className="step-content">
              <h4>Complete Medical Intake</h4>
              <p>This step ensures we have the most up-to-date information to help you in the best way possible.</p>

              <Button
                type="default"
                className="step-button"
                onClick={() => window.open(`/flow/${FlowType.INTAKE}`, '_blank')}
              >
                {'Complete Intake'}
              </Button>
            </div>
          </div>
          
          <div className="step-card">
            <div className="step-number">2</div>
            <div className="step-content">
              <h4>Comprehensive Blood Work</h4>
              <p>If you haven't done this in the past 12 months, we recommend getting it done first. This step ensures we get a good baseline on where your health is now.</p>
              <Button
                type="default"
                className="step-button"
                onClick={() => window.open(`/flow/${FlowType.LONGEVITY_TEST}`, '_blank')}
              >
                {'Complete Blood Work'}
              </Button>
            </div>
          </div>


          <div className="step-card">
            <div className="step-number">3</div>
            <div className="step-content">
              <h4>Submit Your First Concierge Request</h4>
              <p>
               Have any requests for tests, appointments, referrals or anything else? You can email concierge@instalab.com anytime or submit directly to our concierge team below.
              </p>
              <Button
                type="default"
                className="step-button"
                onClick={() => window.open(`/flow/${FlowType.REQUEST}`, '_blank')}
              >
                {'Submit Request'}
              </Button>
            </div>
          </div>

          <div className="step-card">
            <div className="step-number">4</div>
            <div className="step-content">
              <h4>Meet Your Longevity Physician</h4>
              <p>
                Schedule your first session with{' '}
                <Popover content={drWiniarskiContent}>
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Dr. Steven Winiarski</span>
                </Popover>{' '}
                to develop your long-term health plan and answer any questions you have.
              </p>
              <Button
                type="default"
                className="step-button"
                icon={<CalendarOutlined />}
                onClick={() => window.open(`/flow/${FlowType.LONGEVITY_CONSULT}`, '_blank')}
              >
                {'Schedule Consult'}
              </Button>
            </div>
          </div>
        </div>

        

        <Panels />
      </div>
    </div>
  )
}

export default MemberConfirmed