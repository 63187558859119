const CollectionMethod = Object.freeze({
    BLOOD: "blood",
    URINE: "urine",
    CT_SCAN: "ct",
    MRI_SCAN: "mri",
    FDA_DEVICE: 'fda device',
    SHOT: "shot",
    SALIVA: 'saliva',
    STOOL: 'stool',
    BREATH: 'breath',
    PROVIDER: 'provider',
    WEARABLE: 'wearable',
})

export default CollectionMethod

