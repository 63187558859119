import { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { Row, Col, Typography, Button, Collapse, Input, Spin } from 'antd';
import { PlayCircleOutlined, ArrowRightOutlined, ClockCircleOutlined, DownOutlined, UpOutlined, EnvironmentFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import "./cac.scss";
import {
  setDefaults,
  fromAddress,
} from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useLoadScript } from "@react-google-maps/api";
import classNames from 'classnames';
import { CacVideos } from '../cacVideos/cacVideos.component';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { getNearbyFacilities } from '../../services/facility.service';
import LocationHelper from '../../helpers/location.helper';
import { updateMe } from '../../services/user.service';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FlowType from '../../enums/flowType.enum';
import useWidth from '../../hooks/useWidth.hook';
import Breakpoint from '../../enums/breakpoint.enum';
import PosthogHelper from '../../helpers/posthog.helper';

const { Panel } = Collapse;

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
})

const { Paragraph, Text } = Typography;

export const Cac = () => {
  const width = useWidth()
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const [expandedParagraphs, setExpandedParagraphs] = useState({});
  const [videosDrawerVisible, setVideosDrawerVisible] = useState(false);
  const [address, setAddress] = useState(currentUser?.location ? LocationHelper.getLocation(currentUser.location) : null);
  const [facilities, setFacilities] = useState([]);
  const [location, setLocation] = useState(currentUser?.location)
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    document.title = `Instalab | Coronary Calcium Scan`
    autoOpenVideos()
  }, [])

  useEffect(() => {
    if (currentUser?.location) {
      setLocation(currentUser.location)
      setAddress(LocationHelper.getLocation(currentUser.location))
      onFacilitySearch({
        defaultAddress: LocationHelper.getLocation(currentUser.location),
        defaultLocation: currentUser.location
      })
    }
  }, [currentUser])

  const autoOpenVideos = () => {
    const video = searchParams.get('video')
    if (video === 'true') {
      setVideosDrawerVisible(true)
    }
  }
  
  const showVideosDrawer = () => {
    setVideosDrawerVisible(true);
  };
  
  const closeVideosDrawer = () => {
    setVideosDrawerVisible(false);
  };
  
  // Toggle paragraph expansion
  const toggleParagraph = (id) => {
    setExpandedParagraphs(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const faqs = [{
    question: `What is a coronary calcium scan?`,
    answer: (
      <>
        A coronary calcium scan is a specific type of CT scan—computed tomography—that’s focused entirely on the heart and done very quickly. What it’s looking for is calcifications, or calcium deposits, in the coronary arteries (the vessels that supply oxygen-rich blood to your heart).
        <br/><br/>
        To understand why this matters, let’s take a step back. A CT scan uses ionizing radiation—similar to a regular X-ray—but it captures images in three dimensions. It measures differences in tissue density. Less dense areas, like the lungs, appear dark on a CT scan. Very dense structures, like bone—or calcium—show up white.
        <br/><br/>
        In a healthy coronary calcium scan, you shouldn’t see any white in the arteries. That’s because there shouldn’t be any calcification. If calcium is visible, it means there are plaques in the arteries that have begun to calcify. This is part of a process called atherosclerosis—the gradual buildup of plaque in the artery walls.
        <br/><br/>  
        Some plaques develop a thin layer of calcium over time. It’s the body’s way of trying to stabilize the plaque, but it’s also a sign that the atherosclerotic process has already begun. The scan measures the amount of calcium present and gives you a number called the Agatston score.
        <br/><br/>
        That score helps stratify risk:
        <ul>
          <li><b>0 to 100</b> suggests minimal risk</li>
          <li><b>100 to 300</b> indicates moderate risk</li>
          <li><b>Above 300</b> reflects high risk—meaning there’s likely a 15% or greater chance of a cardiovascular event (like a heart attack) over the next 10 years.</li>
        </ul>
        In short, a coronary calcium scan is a quick, predictive tool to help us understand whether you may already have underlying heart disease—even if you feel perfectly fine. It’s not about diagnosing blockages; it’s about detecting early warning signs, so we can act early and prevent problems down the line.
      </>
    ),
    video: `https://vimeo.com/1070955928`,
  }, {
    question: `How effective is the CAC scan at predicting health risks?`,
    answer: <>
      The coronary calcium scan is one of the most powerful tools we have for refining cardiovascular risk—especially in people who don’t clearly fall into high or low risk based on traditional factors like cholesterol or blood pressure.
      <br/><br/>
      Current guidelines, like those from the National Lipid Association (NLA), recommend CAC scans for people at intermediate risk—that’s defined as having a 7.5% to 20% chance of a cardiovascular event in the next 10 years. It’s also useful for those in the borderline risk range (5% to 7.5%) if they have other risk-enhancing factors, like certain ethnic backgrounds. One of the key reasons to do the scan in these cases is to help decide whether or not to start statin therapy.
      <br/><br/>
      But here’s where the data gets even more interesting: major studies—like MESA, Framingham, and HNR—have shown that CAC scoring adds predictive value beyond traditional risk factors. They found that people with higher calcium scores had a significantly higher risk of cardiovascular events, even if their cholesterol or blood pressure looked fine on paper.
      <br/><br/>
      Some studies even looked at doing a second CAC scan five years later, and found two things:
      <ol>
        <li>The second scan was more predictive than the first.</li>
        <li>The rate of change between scans gave insight into how fast the disease was progressing.</li>
      </ol>
      For example, if someone starts with a score of zero and five years later still has zero, that’s a good sign—it means the disease is likely progressing very slowly, if at all. On the other hand, going from zero to a high score in five years suggests a fast-moving process and a much higher risk going forward.
      <br/><br/>
      One way to think about it is this: if you already have calcium in your arteries, that means you already have plaque—no matter what your 10-year risk calculator says. So while short-term risk matters, CAC scans also help you understand your lifetime risk, which becomes even more important as we live longer, healthier lives.
      <br/><br/>
      It’s not just about heart attacks. Atherosclerosis also plays a role in strokes, cognitive decline, and possibly even age-related muscle loss. These longer-term risks may not show up in traditional assessments, but they matter—especially if your goal is not just to live longer, but to stay healthy while you do.
    </>,
    video: `https://vimeo.com/1070955736`,
  }, {
    question: `How often should I get a CAC scan?`,
    answer: <>
      The timing really depends on your underlying risk. Like with any test, we think about pretest probability—what’s your likelihood of having the disease we’re screening for?
      <br/><br/>
      For example, if you’re 30 years old and you get a CAC score of zero, you probably don’t need another scan the very next year. But we also know from studies that even people with a zero score can develop calcium—sometimes quickly—within 2 to 5 years. So for higher-risk patients, it makes sense to repeat the scan more frequently. In many cases, that might mean every 2 to 3 years, especially if we’re actively monitoring risk and making decisions about treatment.
      <br/><br/>
      Current guidelines often recommend a repeat scan every 5 years for certain populations. That’s a reasonable starting point, but many of us in practice believe that’s a bit too conservative—especially since CAC scans are safe, low-cost, and involve very little radiation (which is getting even lower with newer scanners).
      <br/><br/>
      To put it in perspective: we routinely check bloodwork like cholesterol every year, even though those numbers may not change much. The CAC scan gives us equally important—and sometimes more powerful—information, and yet we often hesitate to repeat it. There’s no real downside in middle-aged and older patients.
      <br/><br/>
      One important note: if you’re on a statin, you may see your CAC score rise slightly over time. That doesn’t necessarily mean your disease is getting worse. In fact, it may be a sign that your plaques are stabilizing, which is what statins are designed to do. A small increase is expected. What we don’t want to see is a large jump—say, 10% or 20%—which may suggest faster disease progression.
      <br/><br/>
      Bottom line: the ideal frequency depends on your age, your risk profile, and whether we’re actively managing known cardiovascular risks. But in many cases, repeating the scan every few years is both reasonable and useful.
    </>,
    video: `https://vimeo.com/1070962812`,
  }, {
    question: `How do you compare the CAC scan to blood testing?`,
    answer: <>
      This is a question that often confuses a lot of people—especially with so many advanced blood tests now available: lipid panels, Lp(a), hs-CRP, insulin resistance markers, and so on. These tests are incredibly useful, but they serve a different purpose than imaging like the CAC scan.
      <br/><br/>
      The best way to think about it is this: blood tests tell us about your risk environment—what’s happening in your body that might lead to atherosclerosis. The CAC scan tells us whether disease has already started.
      <br/><br/>
      Atherosclerosis—plaque buildup in the arteries—is a slow-moving disease, often developing over decades. Early on, when the process is just beginning, you may not see anything on a scan. That’s why blood tests are especially valuable if you’re younger, say under 40 and without major risk factors. These serum markers give us a sense of how likely you are to develop plaque over time. And at that stage, the good news is: the environment is changeable. Diet, exercise, and even early medications can shift the trajectory.
      <br/><br/>
      Now, if you’re over 40, or younger with significant risk factors, this is where imaging becomes more useful. The CAC scan can detect early calcium deposits in the coronary arteries, indicating that atherosclerosis has already begun—even if your blood markers look okay. That’s a key point: sometimes the CAC scan will reveal disease that bloodwork misses. In those cases, we might be looking at a genetic predisposition or something we wouldn’t have otherwise caught.
      <br/><br/>
      So, the two types of testing work hand-in-hand:
      <ul>
        <li>Blood tests help us predict risk and guide early prevention.</li>
        <li>The CAC scan shows whether plaque is already there and how far the disease has progressed.</li>
      </ul>
      Together, they give us a much more complete picture—and help personalize the approach to your cardiovascular health.
    </>,
    video: `https://vimeo.com/1070955250`,
  }, {
    question: `How do you compare the CAC scan with the coronary CT angiogram?`,
    answer: <>
      Let’s start with what each test actually does:
      <ul>
        <li><b>The CAC scan</b> measures calcium buildup in the coronary arteries. It’s a surrogate marker—it doesn’t image plaque directly, but instead picks up calcium deposits that form as some plaques stabilize. If you see calcium, you know plaque is present, but you’re not seeing the full picture.</li>
        <li><b>The CCTA (coronary CT angiogram)</b> is the next level up in detail. It involves injecting contrast dye into your bloodstream and imaging the actual lumen, or inner space, of the coronary arteries. This lets us visualize both calcified and non-calcified (soft) plaques. Soft plaques can be more unstable and potentially more dangerous, so this gives us a deeper understanding of what’s happening in the artery walls.</li>
      </ul>
      CCTA can even be combined with AI-based plaque analysis to assess things like:
      <ul>
        <li>Plaque composition</li>
        <li>The degree of narrowing inside the arteries</li>
        <li>The stage of plaque evolution</li>
      </ul>
      In that sense, CCTA gives you more information. But that added detail comes with trade-offs:
      <ul>
        <li>It’s more involved—you’ll need an IV, contrast dye, and sometimes medications to slow your heart rate.</li>
        <li>There’s a small risk of allergic reaction to the contrast or side effects from those medications.</li>
        <li>It takes longer and usually costs more—often significantly more than a CAC scan.</li>
        <li>Radiation exposure can be slightly higher, though newer scanners are getting close to CAC levels.</li>
      </ul>
      So which should you choose? It comes down to this: will the added information change your treatment?
      <br/><br/>
      If you’re trying to decide whether to start or adjust a statin, for example, a CAC score might be all you need—it’s quick, inexpensive, and gives strong predictive value for risk. If you’re already on treatment, and the CCTA won’t change the plan, it may not be necessary.
      <br/><br/>
      On the other hand, if there’s uncertainty—say, symptoms that don’t align with risk factors, or you’re trying to assess how dangerous certain plaques are—CCTA may offer more actionable insight.
      <br/><br/>
      As with any test, we ask: what will this tell us that we don’t already know, and how will it change what we do next? That’s the best way to decide which one is right for you.
    </>,
    video: `https://vimeo.com/1070955412`,
  }, {
    question: `How does a CAC scan fit into my heart health and longevity?`,
    answer: <>
      The CAC scan is one of the most underused but most powerful tools available for long-term heart health and prevention. It should be a standard screening test, much like colonoscopies, mammograms, or pap smears. We routinely screen for those conditions because they can develop silently over time. Heart disease is no different.
      <br/><br/>
      Cardiovascular disease doesn’t behave like a cold or infection where symptoms appear quickly. It’s a slow, silent process that often begins in your 20s or 30s and builds over decades before showing up as a heart attack or stroke. By the time symptoms appear, the disease is usually in an advanced stage.
      <br/><br/>
      The challenge is that our current healthcare mindset often waits until there’s a visible problem. But once a heart attack occurs, treatment is essentially trying to reverse 20 or 30 years of damage—and we simply don’t have medications that can fully turn back the clock.
      <br/><br/>
      That’s where the CAC scan plays a critical role. It shows whether atherosclerosis—plaque in the arteries—has already begun. If it has, that means the disease process is already underway, regardless of what your bloodwork shows. This moves the conversation from managing risk factors to managing the actual disease.
      <br/><br/>
      If there’s uncertainty about whether cholesterol levels require treatment, a CAC scan can be decisive. Seeing calcium in the arteries confirms the presence of plaque. And if follow-up scans show that the calcium score is rising while no treatment is started, that’s the equivalent of watching a polyp grow on a colonoscopy and waiting for it to become cancer.
      <br/><br/>
      Heart disease deserves the same proactive attention we give to cancer—not just because of heart attacks, but because atherosclerosis also contributes to stroke, dementia, and the effects of aging.
      <br/><br/>
      So how does the CAC scan fit into a longevity plan? It serves as an early warning system, detecting cardiovascular disease before it causes damage—when it’s still preventable. It allows for earlier, more precise intervention, helping extend not just lifespan, but healthspan.
    </>,
    video: `https://vimeo.com/1070955615`,
  }]
  
  // Define paragraph content with short and full versions
  const howContent = [
    {
      id: 'how-1',
      title: `The Scan: Measuring Calcified Plaque in Minutes`,
      short: "A coronary artery calcium (CAC) scan is a fast, non-invasive test that detects calcium buildup in the arteries of your heart—an early indicator of atherosclerosis and your future risk of heart disease.",
      full: <>
        A coronary artery calcium (CAC) scan is a fast, non-invasive test that detects calcium buildup in the arteries of your heart—an early indicator of atherosclerosis and your future risk of heart disease.
        <br/><br/>
        Small electrodes are placed on your chest to monitor your heart rate. You’ll lie on a motorized table that gently slides into a CT scanner. You’ll be asked to hold your breath for short intervals while the machine takes high-resolution images of your heart.
        <br/><br/>
        The scan itself takes just about 10 minutes, and you’ll be done in under 1 hour total, including prep time.
      </>,
      img: "/img/cac/procedure.jpg"
    },
    {
      id: 'how-2',
      title: "Image Analysis: Understanding Your CAC Score",
      short: "Once your scan is complete, the images are analyzed to calculate your Coronary Artery Calcium (CAC) score—a numerical value that reflects the total amount of calcified plaque in your coronary arteries.",
      full: <>
        Once your scan is complete, the images are analyzed to calculate your Coronary Artery Calcium (CAC) score—a numerical value that reflects the total amount of calcified plaque in your coronary arteries.
        <br/><br/>
        This score is a direct measure of plaque burden and a powerful predictor of your future heart attack and stroke risk. A score of zero means no detectable calcified plaque and a low short-term risk. Higher scores indicate more plaque and a greater likelihood of cardiovascular disease.
        <br/><br/>
        Your results are reviewed by a physician and delivered in a report that includes your absolute score and how it compares to others of the same age and sex. This helps determine whether lifestyle changes, medications, or further testing are recommended.
      </>,
      img: "/img/cac/results.jpg"
    },
  ];

  
  const addressInputRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "AIzaSyBiXW-QXKQreN_9hQOW4VPunykKt9T3pts",
    libraries: ['places']
  });

  const focusAddressInput = () => {
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  const handleAddressChange = address => {
    setAddress(address);
  };

  const handleAddressSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const streetNumber = getAddressValueByType(results, 'street_number')
        const route = getAddressValueByType(results, 'route')
        let formattedAddress = ''
        if (streetNumber) {
          formattedAddress = streetNumber
        }
        if (route) {
          formattedAddress = `${formattedAddress} ${route}`
        }
        
        const fetchedLocation = {
          streetAddress: formattedAddress,
          postalCode: getAddressValueByType(results, 'postal_code'),
          city: getAddressValueByType(results, 'locality') || getAddressValueByType(results, 'sublocality_level_1') || getAddressValueByType(results, 'neighborhood'),
          state: getAddressValueByType(results, 'administrative_area_level_1'),
        }
        
        setLocation(fetchedLocation)
        setAddress(address);
      })
      .catch(error => console.error('Error selecting address', error));
  };

  const getAddressValueByType = (results, type) => {
    if (!results || !results[0] || !results[0].address_components) return null;
    
    const component = results[0].address_components.filter(component => 
      component.types.includes(type)
    );
    
    if (component.length) {
      return component[0].short_name;
    }
    return null;
  };

  const onFacilitySearch = async ({ defaultAddress=null, defaultLocation=null }) => {
    if (!defaultAddress && !address) return;

    setIsSearching(true)

    const { results } = await fromAddress(defaultAddress || address);
    
    const {
      lat: latitude, 
      lng: longitude,
    } = results[0].geometry.location;


    if (!currentUser?.location?.postalCode) {
      const userLocation = defaultLocation || location
      const updatedUser = await updateMe({
        fields: {
          location: {
            ...userLocation,
            latitude,
            longitude
          }
        }
      })
      setCurrentUser(updatedUser)
    }

    const fetchedFacilities = await getNearbyFacilities({
      latitude,
      longitude,
      productTypeCode: ProductTypeCode.CTCALCIUM,
      maxDuration: 120
    });

    const facilitiesWithProduct = fetchedFacilities?.filter(facility => 
      facility.menu?.some(item => 
        item.productType.code === ProductTypeCode.CTCALCIUM && 
        item.price != null && 
        item.price > 0
      )
    ).sort((a, b) => a.duration - b.duration)

    setFacilities(facilitiesWithProduct)

    setIsSearching(false)
  };

  return (
    <div className="cac">
      <CacVideos
        visible={videosDrawerVisible} 
        onClose={closeVideosDrawer}
        width={900}
        placement="right"
        title={width <= Breakpoint.MD ? "Everything You Need to Know" : "Coronary CTA: Everything You Need to Know"}
      />

      <div className="cac-hero">
        <div className="cac-section">
          <Paragraph className="cac-h1">
            Coronary Calcium Scan
          </Paragraph>
          <Paragraph className="cac-p1">
            Scanning the arteries in your heart for calcium buildup. This calcium is a sign of plaque, which can narrow or block your arteries, increasing your risk of a heart attack.
          </Paragraph>
          <Button
            type="primary"
            className="schedule-cac-btn"
            onClick={() => {
              PosthogHelper.track(currentUser, `[cac] clicked schedule cac`, true)
              navigate(`/flow/${FlowType.CAC}`)
            }}
          >
            <span className="schedule-cac-btn-text">Schedule</span> <ArrowRightOutlined className="schedule-cac-btn-icon" />
          </Button>

          <br />

          <Paragraph className="cac-quote">
            "The CAC scan is one of the most underused but most <i>powerful</i> tools available for long-term heart health and prevention. It should be a standard screening test, much like colonoscopies, mammograms, or pap smears. We routinely screen for those conditions because they can develop silently over time. Heart disease is no different."
          </Paragraph>

          <div 
            className="cac-hero-img-container"
            onClick={showVideosDrawer}
          >
            <img 
              src="/img/cac/steven.jpg" 
              className="cac-hero-img" 
            />

            <PlayCircleOutlined className="cac-play-icon" />

            <div className="cac-video-attribute">
              <Paragraph className="cac-video-attribute-name"> Dr. Steven Winiarski </Paragraph>
              <Paragraph className="cac-video-attribute-title"> Clinical Lipidologist, Longevity Medicine </Paragraph>
            </div>
          </div>
        </div>
      </div>
      
      <div className="how-section">
        <div className="cac-section">
          <div className="divider-container">
            <Text className="cac-h1"> 
              How It Works
            </Text>
          </div>

          {howContent.map((how) => (
            <div key={how.id} className="paragraph-container">
              {how.title && (
                <Paragraph className={`cac-p1 bold`}>
                  {how.title}
                </Paragraph>
              )}
              
              <Paragraph className="cac-p2">
                {expandedParagraphs[how.id] ? how.full : how.short}
              </Paragraph>
              
              {how.short !== how.full && (
                <Button 
                  type="link" 
                  className="read-more-btn"
                  onClick={() => toggleParagraph(how.id)}
                  icon={expandedParagraphs[how.id] ? <UpOutlined /> : <DownOutlined />}
                >
                  {expandedParagraphs[how.id] ? "Read Less" : "Read More"}
                </Button>
              )}

              <br/>

              {how.img && (
                <img src={how.img} className="cac-how-img" />
              )}

              {how.video && (
                <div className="cac-how-video-container">
                  <ReactPlayer
                    url={how.video}
                    playing={true}
                    loop={true}
                    muted={true}
                    playsinline={true}
                    controls={false}
                    width="100%"
                    height="auto"
                    className="cac-how-video"
                    config={{
                      vimeo: {
                        playerOptions: {
                          responsive: true,
                          background: true
                        }
                      }
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="pricing-section">
        <div className="cac-section">
          <div className="divider-container">
            <Text className="cac-h1"> 
              Pricing
            </Text>
          </div>
          <Paragraph className="cac-p1 pricing-p1">
            We'll help you find the most convenient and affordable testing location near you and handle the appointment scheduling. Enter your location below to search our database.
          </Paragraph>

          <div 
            className={classNames("pricing-search")}
          >
            <Row gutter={16} align="bottom">
              <Col flex="auto" onClick={focusAddressInput} className="clickable-input-col">
                <Text className="pricing-search-label">
                  <EnvironmentFilled className="pricing-search-label-icon" /> Your Address
                </Text>
                
                {isLoaded ? (
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleAddressChange}
                    onSelect={handleAddressSelect}
                    debounce={300}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="address-autocomplete-container">
                        <Input
                          {...getInputProps({
                            className: 'pricing-search-address',
                            placeholder: 'Enter your full address...',
                            onKeyDown: e => {
                              if (e.key === 'Enter' && suggestions?.length) {
                                e.preventDefault();
                                handleAddressSelect(suggestions[0].description);
                                addressInputRef.current.blur();
                              }
                            },
                          })}
                          ref={addressInputRef}
                          onClick={(e) => e.stopPropagation()}
                        />
                        
                        <div className="autocomplete-dropdown-container">
                          {loading && (
                            <div className="address-spinner">
                              <Spin size='small' /> Loading...
                            </div>
                          )}
                          {suggestions.map((suggestion, index) => {
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, { style })}
                                className="suggestion-item"
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <Input
                    className="pricing-search-address"
                    placeholder="Loading Google Places..."
                    disabled
                  />
                )}
              </Col>
              
              <Col
                className="search-address-btn-col"
              >
                <Button 
                  type="primary"
                  className="search-address-btn"
                  onClick={onFacilitySearch}
                  loading={isSearching}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>

          {facilities.map((facility) => (
            <div 
              key={facility.id}
              className="cac-facility-item"
            >
              <Paragraph 
                className="cac-p2 bold cac-facility-name"
                ellipsis={{
                  rows: 1,
                }}
              >
                {facility.name}
              </Paragraph>
              <Paragraph className="cac-facility-location">
                {LocationHelper.getLocation(facility.location)}
              </Paragraph>
              <Paragraph className="cac-facility-duration">
                <ClockCircleOutlined /> {Math.round(facility.duration)} min drive
              </Paragraph>
              <Text className="cac-p2 bold cac-facility-price"> 
                {facility.menu.find(item => item.productType.code === ProductTypeCode.CTCALCIUM)?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </Text>
              <Button 
                type="primary"
                className="schedule-facility-btn"
                onClick={() => {
                  PosthogHelper.track(currentUser, `[cac] clicked schedule cac: ${facility._id}`, true)
                  navigate(`/flow/${FlowType.CAC}?facility=${facility._id}`)
                }}
              >
                Schedule <ArrowRightOutlined />
              </Button>
            </div>
          ))}
        </div>
      </div>

      <div className="faq-section">
        <div className="cac-section">
          <div className="divider-container">
            <Text className="cac-h1"> 
              FAQ
            </Text>
          </div>

          <Collapse 
            className="faq-collapse"
            bordered={false}
            expandIcon={({ isActive }) => (
              isActive ? <MinusOutlined /> : <PlusOutlined />
            )}
          >
            {faqs.map((faq, index) => (
              <Panel 
                header={faq.question} 
                key={index}
                className="faq-panel"
              >
                <div className="faq-answer">
                  <div className="faq-video-container"> 
                    <ReactPlayer
                      url={faq.video}
                      controls={true}
                      width="100%"
                      height="auto"
                      className="faq-video"
                      config={{
                        vimeo: {
                          playerOptions: {
                            responsive: true,
                            autopause: true
                          }
                        }
                      }}
                    />
                  </div>
                  <br/>
                  {faq.answer}
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
} 