import React, { useState, useEffect, useContext, useRef } from 'react'
import { Row, Col, Form, Input, Divider, Typography, Button, Select, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import "./join.scss";
import {  ArrowRightOutlined } from '@ant-design/icons'
import { UserContext } from '../../contexts/user.context';
import { getJoinableAppointment, joinAppointment } from "../../services/appointment.service";
import dayjs from 'dayjs'
import { Step as InviteStep } from '../../components/inviteFlow/inviteFlow.component'
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import FlowType from '../../enums/flowType.enum';
import Gender from '../../enums/gender.enum';
import tzLookup from 'tz-lookup';
import StorageKey from '../../enums/storageKey.enum';
import RuleHelper from '../../helpers/rule.helper';
import FormHelper from '../flow/helpers/form.helper';
import { login } from '../../services/auth.service';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);

const { Item } = Form
const { Paragraph } = Typography

const JoinPath = {
  CREATE_ACCOUNT: 'create account',
  LOGIN: 'login'
}

export const Join = () => {
  const navigate = useNavigate()
  const { appointmentId } = useParams()
  const { currentUser, token, setToken } = useContext(UserContext)
  const [appointment, setAppointment] = useState()
  const [locationTimezone, setLocationTimezone] = useState('UTC'); 
  const [joinPath, setJoinPath] = useState(undefined)

  useEffect(() => {
    fetchAppointment()
  }, [appointmentId])

  useEffect(() => {
    if (appointment && appointment.location) {
      fetchTimezoneForLocation(appointment.location);
    }
  }, [appointment]);

  const fetchAppointment = async () => {
    if (!appointmentId) return
    const response = await getJoinableAppointment(appointmentId, {  
      populate: [{
        path: 'patients',
        select: '_id firstName'
      }]
    })
    setAppointment(response)
  }
  
  const fetchTimezoneForLocation = (location) => {
    if (location && location.latitude && location.longitude) {
      try {
        const timezone = tzLookup(location.latitude, location.longitude);
        setLocationTimezone(timezone);
      } catch (error) {
        console.log('Failed to fetch timezone information.');
      }
    }
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const convertTimeZone = (time) => {
    const formattedDate = dayjs(time).tz(locationTimezone);
    return formattedDate;
  };

  const ChooseJoinPath = () => {
    return (
      <div className="choose-join-path">
        <Paragraph className="join-title">
          Accept Your Invite
        </Paragraph>
        <Paragraph className="join-description">
          {appointment.patients[0].firstName} has invited you to a blood draw appointment on <u>{convertTimeZone(appointment.start).format('MMM D, YYYY [at] h:mm a')}</u>, located at <u>{appointment.location.streetAddress}, {appointment.location.city}, {appointment.location.state} {appointment.location.postalCode}</u>. Please log in or create an Instalab account to confirm your participation.
        </Paragraph>

        <Button 
          className="login-button"
          type="default"
          onClick={() => setJoinPath(JoinPath.LOGIN)}
        >
          Log in to Existing Account
        </Button>

        <Divider plain orientation="center">or</Divider>

        <Button 
          className="register-button"
          type="primary"
          onClick={() => setJoinPath(JoinPath.CREATE_ACCOUNT)}
        >
          Create New Account
        </Button>
      </div>
    )
  }

  const LoginAndJoin = () => {
    const [loginForm] = Form.useForm()
    const [loginAndJoinAttempt, setLoginAndJoinAttempt] = useState()
    const loginAndJoinAttemptRef = useRef(null)
    loginAndJoinAttemptRef.current = loginAndJoinAttempt
    const [isSubmitting, setIsSubmitting] = useState()

    const onLoginAndJoinFail = () => {
      setLoginAndJoinAttempt(true)
      message.error('Enter valid account information')
    }

    const onLoginAndJoin = async (values) => {
      setIsSubmitting(true)
  
      try {  
        const {
          email,
          password,
        } = values
  
        const loginResponse = await login({
          email,
          password,
        })
  
        const joinResponse = await joinAppointment(appointmentId, {
          userId: loginResponse.user._id
        })
  
        localStorage.setItem(StorageKey.TOKEN, loginResponse.token)
        setToken(loginResponse.token)
        const url = `/flow/${FlowType.INVITE}/${InviteStep.CONFIRM}/${joinResponse.flow._id}`
        navigate(url)
      } catch (err) {
        let msg = 'Failed to join appointment'
        if (err.response?.data?.code === 11000) {
          msg = 'Account already exists with this email'
        }
        message.error(msg)
      }
      setIsSubmitting(false)
    }

    return (
      <div className="login-and-join">
        <Paragraph className="join-title">
          Login to Your Account
        </Paragraph>
        <Paragraph className="join-description">
          To participate in {appointment.patients[0].firstName}'s blood draw appointment on {convertTimeZone(appointment.start).format('MMM D, YYYY [at] h:mm a')}, please log in to your Instalab account.
          <br/><br/>
          Don't have an account yet? <a className="login-link" onClick={() => setJoinPath(JoinPath.CREATE_ACCOUNT)}>Create one here.</a>
        </Paragraph>

        <Form
          form={loginForm}
          layout='vertical'
          onFinish={onLoginAndJoin}
          onFinishFailed={onLoginAndJoinFail}
          className='account-form'
        >
          <Item 
            label="Email Address"
            name="email"
            rules={[
              RuleHelper.isRequired,
              RuleHelper.isEmail,
            ]}
            validateTrigger={[]}
          >
            <Input 
              placeholder="Email address" 
              onChange={() => {
                if (loginAndJoinAttemptRef.current) {
                  FormHelper.fetchHasError(loginForm)
                }
              }}
            />
          </Item>

          <Item 
            label="Password"
            name="password"
            rules={[
              RuleHelper.isRequired
            ]}
            validateTrigger={[]}
          >
            <Input.Password
              placeholder="Password" 
              type="password"
              onChange={() => {
                if (loginAndJoinAttemptRef.current) {
                  FormHelper.fetchHasError(loginForm)
                }
              }}
            />
          </Item>

          <Item>
            <Button 
              className="login-form-button"
              type='primary'
              htmlType='submit'
              loading={isSubmitting}
            >
              Join Appointment
              {!isSubmitting && <ArrowRightOutlined />}
            </Button>
          </Item>
        </Form>
      </div>
    )
  }

  const NewUserJoin = () => {
    const [newUserForm] = Form.useForm()
    const [newUserAttempt, setNewUserAttempt] = useState()
    const newUserAttemptRef = useRef(null)
    newUserAttemptRef.current = newUserAttempt
    const [isSubmitting, setIsSubmitting] = useState()

    const formatPhoneNumber = (value) => {
      const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
      const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        return `${match[1] ? '(' + match[1] : ''}${match[2] ? ') ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
      }
      return value;
    };
  
    const handlePhoneChange = (e) => {
      const value = e.target.value;
      const formattedValue = formatPhoneNumber(value);
      newUserForm.setFieldsValue({ phone: formattedValue });
      if (newUserAttemptRef.current) {
        FormHelper.fetchHasError(newUserForm)
      }
    }
  
    const formatDob = (value) => {
      const cleaned = ('' + value).replace(/\D/g, '').slice(0, 8);
      const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
      if (match) {
        return `${match[1] ? match[1] : ''}${match[2] ? '/' + match[2] : ''}${match[3] ? '/' + match[3] : ''}`;
      }
      return value;
    };
  
    const handleDobChange = (e) => {
      const value = e.target.value;
      const formattedValue = formatDob(value);
      newUserForm.setFieldsValue({ dob: formattedValue });
      if (newUserAttemptRef.current) {
        FormHelper.fetchHasError(newUserForm)
      }
    };

    const onNewUserFail = async () => {
      setNewUserAttempt(true)
      message.error('Enter valid account information')
    }
  
    const onNewUserJoin = async (values) => {
      setNewUserAttempt(true)
      setIsSubmitting(true)
  
      try {
        const {
          email,
          phone,
          firstName,
          lastName,
          password,
          gender,
          dob,
        } = values
  
        const params = {
          firstName,
          lastName,
          email,
          phone,
          password,
          gender,
          dob,
        }
        const response = await joinAppointment(appointmentId, params)
        localStorage.setItem(StorageKey.TOKEN, response.token)
        setToken(response.token)
        message.success('Appointment joined successfully')
        const url = `/flow/${FlowType.INVITE}/${InviteStep.CONFIRM}/${response.flow._id}`
        navigate(url)
      } catch (err) {
        let msg = 'Failed to join appointment'
        if (err.response?.data?.code === 11000) {
          msg = 'Account already exists with this email'
        }
        message.error(msg)
      }
      setIsSubmitting(false)
    }

    return (
      <div className="existing-user-join">
        <Paragraph className="join-title">
          Create Your Instalab Account
        </Paragraph>
        <Paragraph className="join-description">
          To join {appointment.patients[0].firstName}'s blood draw appointment scheduled for {convertTimeZone(appointment.start).format('MMM D, YYYY [at] h:mm a')}, please create your Instalab account.
          <br/><br/>
          If you already have an account, <a className="login-link" onClick={() => setJoinPath(JoinPath.LOGIN)}>please log in</a>.
        </Paragraph>

        <Form
          form={newUserForm}
          onFinish={onNewUserJoin}
          onFinishFailed={onNewUserFail}
          layout='vertical'
          className='account-form'
        >
          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="First Name"
                name="firstName"
                rules={[RuleHelper.isRequired]}
                validateTrigger={[]}
              >
                <Input 
                  placeholder="First Name" 
                  onChange={() => {
                    if (newUserAttemptRef.current) {
                      FormHelper.fetchHasError(newUserForm)
                    }
                  }}
                />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Last Name"
                name="lastName"
                rules={[RuleHelper.isRequired]}
                validateTrigger={[]}
              >
                <Input 
                  placeholder="Last Name" 
                  onChange={() => {
                    if (newUserAttemptRef.current) {
                      FormHelper.fetchHasError(newUserForm)
                    }
                  }}
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Email Address"
                name="email"
                rules={[
                  RuleHelper.isRequired,
                  RuleHelper.isEmail,
                  RuleHelper.isUniqueEmail({
                    fieldName: 'email',
                  })
                ]}
                validateTrigger={[]}
              >
                <Input 
                  placeholder="Email address" 
                  onChange={() => {
                    if (newUserAttemptRef.current) {
                      FormHelper.fetchHasError(newUserForm)
                    }
                  }}
                />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Phone Number"
                name="phone"
                rules={[
                  RuleHelper.isRequired,
                  RuleHelper.isPhone,
                ]}
                validateTrigger={[]}
              >
                <Input 
                  placeholder={'(XXX) XXX-XXXX'}
                  inputMode="decimal"
                  onChange={handlePhoneChange}
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Sex at Birth"
                name="gender"
                rules={[
                  RuleHelper.isRequired
                ]}
                validateTrigger={[]}
              >
                <Select
                  placeholder="Select Sex"
                  showSearch
                  options={Object.values(Gender).map(gender => {
                    return {
                      label: gender,
                      value: gender,
                    }
                  })}
                  onSelect={() => {
                    if (newUserAttemptRef.current) {
                      FormHelper.fetchHasError(newUserForm)
                    }
                  }}
                />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Date of Birth"
                name="dob"
                rules={[
                  RuleHelper.isRequired,
                  RuleHelper.isDate,
                ]}
                validateTrigger={[]}
              >
                <Input 
                  placeholder={'MM/DD/YYYY'}
                  inputMode="decimal"
                  onChange={handleDobChange}
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Password"
                name="password"
                rules={[
                  RuleHelper.isRequired
                ]}
                validateTrigger={[]}
              >
                <Input.Password
                  placeholder="Password" 
                  type="password"
                  onChange={() => {
                    if (newUserAttemptRef.current) {
                      FormHelper.fetchHasError(newUserForm)
                    }
                  }}
                />
              </Item>
            </Col>
          </Row>

          <Item>
            <Button 
              className="join-form-button"
              type='primary'
              htmlType='submit'
              loading={isSubmitting}
            >
              Join Appointment
              {!isSubmitting && <ArrowRightOutlined />}
            </Button>
          </Item>
        </Form>
      </div>
    )
  }

  const ExistingUserJoin = () => {
    const [isSubmitting, setIsSubmitting] = useState()

    const onExistingUserJoin = async () => {
      setIsSubmitting(true)
      try {
        const response = await joinAppointment(appointmentId, {
          userId: currentUser._id
        })
        message.success('Appointment joined successfully')
        const url = `/flow/${FlowType.INVITE}/${InviteStep.CONFIRM}/${response.flow._id}`
        navigate(url)
      } catch (err) {
        message.error('Failed to join appointment')
      }
      setIsSubmitting(false)
    }

    return (
      <div className="existing-user-path">
        <Paragraph className="join-title">
          Accept Your Invite
        </Paragraph>
        <Paragraph className="join-description">
          You are invited by {appointment.patients[0].firstName} to a blood draw appointment on <u>{convertTimeZone(appointment.start).format('MMM D, YYYY [at] h:mm a')}</u>, located at <u>{appointment.location.streetAddress}, {appointment.location.city}, {appointment.location.state} {appointment.location.postalCode}</u>. To confirm your participation, please click the button below.
          <br/><br/>
          If you have any questions, please <a onClick={onChat} className="message-link">send us a message</a>.
        </Paragraph>

        <Button 
          className="existing-user-btn"
          type="primary"
          onClick={onExistingUserJoin}
          loading={isSubmitting}
        >
          Join Appointment
          {!isSubmitting && <ArrowRightOutlined />}
        </Button>
      </div>
    )
  }
  

  return appointment && (
    <div className="join">
      {token ? (
        <ExistingUserJoin />
      ) : joinPath === JoinPath.CREATE_ACCOUNT ? (
        <NewUserJoin />
      ) : joinPath === JoinPath.LOGIN ? (
        <LoginAndJoin />
      ) : (
        <ChooseJoinPath />
      )}
    </div>
  )
}